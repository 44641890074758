import { Button, Callout, Dialog } from "@blueprintjs/core"
import gql from "graphql-tag"
import React, { Component } from "react"
import { withApollo } from "react-apollo"
import Layouts from "react-pe-layouts"
import { __ } from "react-pe-utilities/dist"
import { compose } from "recompose"

class VKAccess extends Component
{
    constructor( props )
    {
        super(props)
        this.state = {
            isVKIDOpen :true
        }
    }
    render()
    {
        const clientId = Layouts().app.vk_app_id
        //    if(!clientId)
        //    {
        //         <Dialog
        //             isOpen={this.state.isVKIDOpen}
        //             onClose={() => this.setState({ isVKIDOpen: false })}
        //         >
        //             <Callout>
        //                 {__("Error config VK Application")}
        //             </Callout>
        //         </Dialog>
        //    }
        const redirectUrl = window.location.origin + "/vk-access.html" 
        const auth_url = `https://oauth.vk.com/authorize?client_id=${clientId}&display=popup&redirect_uri=${redirectUrl}&scope=friends,wall,photos,video,email&response_type=code&v=5.131`
        const startAccessToken = () => {
            const winopened  = window.open(auth_url, "windowName", "height=100,width=100,screenY=0,screenX=0,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no");
            winopened.focus();            
            const _this = this
            window.getCode = function( code )
            {
                // console.log( code )
                // const lines = code.split("&")
                // let params = {}
                // lines.forEach(e => {
                //     const par = e.split("=")
                //     params[par[0]] = par[1]
                // })
                // const access_token = params['access_token']
                // const expires_in  = params['expires_in']
                // const SET_VKUSER_ACCESS_TOKEN = gql`
                //     query{
                //         setPEVKUserAccessToken( 
                //             access_token: "${access_token}"
                //             expires_in: "${expires_in}"
                //         )
                //     }`
                // _this.props.client.query({query: SET_VKUSER_ACCESS_TOKEN})
                //     .then(result => {
                //         console.log( result.data.setPEVKUserAccessToken )
                //         //console.log( JSON.parse( result.data.getPEVKUserAccessToken ) )
                //     })
                // console.log( params )

                setTimeout((code, _this) => {
                    const GET_VKUSER_ACCESS_TOKEN = gql`
                    query{
                        getPEVKUserAccessToken( 
                            redirect_uri: "${redirectUrl}", 
                            code: "${code}"
                        )
                    }`
                    _this.props.client.query({query: GET_VKUSER_ACCESS_TOKEN})
                        .then(result => {
                            console.log( result.data.getPEVKUserAccessToken )
                        })
                    // delete window.getCode 
                    // console.log( code ) 
                }, 200, code, _this)

            }
        }
        // return null
        return this.props.hidden
            ?
            <Button 
                onClick={ startAccessToken } 
                minimal
                small
                className=" text-nowrap position-absolute bottom left ml-2 p-0 text-light small z-index-100"
            >
                { this.props.title ? this.props.title : "VK" }
            </Button> 
            :
            null
    } 
    
}
export default compose(withApollo)(VKAccess)