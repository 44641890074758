import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { getSingleRoute } from "react-pe-layouts"
import { __ } from "react-pe-utilities"
import { getFontNameByID } from "../data/PalettePresets"

class PostFeedCard extends Component {
  render() {
    const {height} = this.props
    const color = this.props.palette
      ?
      this.props.palette.card.color
      :
      null
    const fontFamily = this.props.palette
      ?
      getFontNameByID(this.props.palette.card.fontFamilyID)
      :
      null
    const cotent = `${this.props.post_content
      .split(" ")
      .slice(0, 14)
      .join(" ")
      .replace(/(<\S([^>]+)>)/ig, "")
      }...`
    const route = getSingleRoute(this.props.data_type)
    let addr
    if (route) {
      //console.log(route.route, this.props.data_type)
      addr = <NavLink
        className="lesson-qoute-title"
        to={`/${route.route}/${this.props.id}`}
        style={{ fontFamily, color }}
      >
        {__(this.props.post_title)}
      </NavLink>
    }
    const thrumb = route
      ?
      <NavLink className="lesson-quote-first" to={`/${route.route}/${this.props.id}`}>
        <div
          className="lesson-quote-thumb-light"
          style={{ backgroundImage: `url(${this.props.thumbnail})`, minHeight: height }}
        />
      </NavLink>
      :
      <div
        className="lesson-quote-thumb-light"
        style={{ backgroundImage: `url(${this.props.thumbnail})`, minHeight: height }}
      />

    return (
      <div
        className={`lesson-quote-cont ${this.props.class_name}`}
        style={this.props.style}
      >
        {thrumb}
        <div className="lesson-quote-second">
          <div className="">
            {addr}
          </div>
          <div
            className="lesson-quote-content"
            style={{ color }}
            dangerouslySetInnerHTML={{ __html: cotent }}
          />
          <div className="d-flex flex-wrap" />
          <div className="lesson-quote-footer" />
        </div>
      </div>
    )
  }
}
export default PostFeedCard
