import MenuElementBtnGroup from "../../views/adminMenu/MenuElementBtnGroup"
import React, { Component, useEffect, useState } from "react"
import { routing, updateRoutes } from "react-pe-layouts"
import { translitterate, __ } from "react-pe-utilities/dist"
import { AppToaster } from "react-pe-useful/dist"
import { Intent } from "@blueprintjs/core" 
import { gql } from '@apollo/client';
import { withApollo } from 'react-apollo'
import { compose } from "recompose"

const UPDATE_MENUS = gql`
    mutation changeMenu($input: MenuInput) {
        changeMenu(input: $input)
    }
`;
class CurrentMenuWidget extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            menuItems : routing()[ this.props.menu ],
        }
    }
    changeCurrent = () => {}
    onChangeItems = items => { 
        this.setState({ menuItems: items })
        window.clearTimeout( window.tm ) 
        window.tm = window.setTimeout( function( _this )
        { 
            const routs = { ...routing() } 
            routs[ _this.props.menu ] = items 
            updateRoutes( routs )
            //console.log( routing() ) 
            const json = { json: JSON.stringify( routs ) }
            _this.props.client.mutate({
                mutation: UPDATE_MENUS,
                variables: { input: json },
                update: (store, { data: { changeMenu } }) => {
                    _this.setState({
                    isUpdated: false,
                  })
                  AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: 'tick',
                    message: 'Success saved menus',
                  })
                },
              })

        }, 200, this )
    }
    render()
    {
        return <MenuElementBtnGroup
            items={ this.state.menuItems }
            curBtn={""} 
            onCurBtn={this.changeCurrent}
            onItems={this.onChangeItems}
            isHideNewBtn
            isHideChildren
        />   
    }
} 
export default compose(withApollo)(CurrentMenuWidget)