import React, { Component } from "react"
import { Callout, Intent } from "@blueprintjs/core"
import {GoogleSheet, GoogleSheetsApi} from "@lourd/react-google-sheet"
import config from "config/config"
import {Loading} from "react-pe-useful"
import { __ } from "react-pe-utilities/dist"
import QRCodeFileList from "./QRCodeFileList"

class GoogleSheetQRCodesList extends Component
{
    listData = []
    constructor(props)
    {
        super(props)
        this.state={ }
    }
    setListData = listData =>
    {
        // console.log(this.props.listData)
        if(this.props.onUpdateList && Array.isArray(this.props.listData) && this.props.listData.length === 0 )
        {
            const thread = listData[0]

            if(!Array.isArray(thread))  return
            let textRowID = 0;
            thread.forEach( (row, i) => {
                if(row.toString().toLowerCase() === "text" || row.toString().toLowerCase() === "url")
                {
                    textRowID = i;
                }
            })
            let list = listData.filter((row,i) => i > 0 && row[textRowID] ).map( (row, i) => {
                const d =  {
                    id: row[textRowID],
                    text:row[textRowID],
                    post_title: (i + 1) + ". " + row[textRowID],
                    title: (i + 1) + ". " + row[textRowID]
                }
                //console.log(d)
                return d
            })
            this.props.onUpdateList( list )
        }
            
        return <div></div>
    }

    render()
    { 
        if(!this.props.sheetURL)
            return null
        return <>
            <GoogleSheetsApi 
                clientId={ config.googleClientID } 
                apiKey={ config.googleApiKey }
            >
                {({ authorize, loading: apiLoading, signout, signedIn, error }) => (
                <div>
                    {
                        apiLoading 
                        ?
                        <Loading/>
                        : 
                        error 
                            ? 
                            <Callout className="p-5" intent={Intent.DANGER}>{ JSON.stringify( error, null, 2) }</Callout>
                            : 
                            signout 
                                ?
                                <GoogleSheet 
                                    id={this.props.sheetURL} 
                                    range={"A1:I100"}
                                >
                                    {({ error, data, loading }) =>
                                        loading 
                                        ?
                                        <Loading/>
                                        : 
                                        error 
                                            ?
                                            <Callout intent={Intent.DANGER}>{ JSON.stringify( error, null, 2) }</Callout>
                                            : 
                                            data 
                                                ? 
                                                this.setListData(data)                                                             
                                                : 
                                                <Callout intent={Intent.DANGER}>{ __("No data") }</Callout>
                                    }
                                </GoogleSheet>
                                : 
                                0
                    }
                </div>
                )}
            </GoogleSheetsApi>
        </>

            
    }
}
export default GoogleSheetQRCodesList

