import React, {  useState } from 'react'; 
import WPFestAdminContext from '../api/WPFestAdminContext';

const CabinetProvider = props =>
{
    const [state, setState] = useState({});
    const[serverURI, setServerURI] = useState()
    return (
        <WPFestAdminContext.Provider value={{
            serverURI,
            setServerURI: uri => setServerURI(uri),
            cabinet: props.children,
            user: state,
            setUser: user => {
                setState(user);
            },
        }}
        >
            { props.children }
        </WPFestAdminContext.Provider>
    );
}

export default CabinetProvider;
