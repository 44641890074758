import React, { Fragment } from "react" 
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import Style from "style-it" 
import EditibleText from "./edit/EditibleText" 
import { LayoutIcon } from "react-pe-useful/dist"
import { Link } from "react-router-dom"
import { getFontNameByID } from "./data/PalettePresets"

class Header extends SectionContent 
{
    is() {
        const { title } = this.state.data
        return title
      }
    getState()
    { 
        return {
            editible : "",
            title: this.props.data.title
        }
    }
    
    onCloseEdit = ( )=>
    {
        // console.log( this.props.data.text )
        this.setState({ editible: "", data: { ...this.state.data, title: this.props.data.title } })
    }
    link = () =>
    {
        if(!this.props.data.link) return null
        let bordered = "rounded-0"
        let bcolor = "";
        let bgcolor = "";
        let lbcolor = "";
        let ltype = ""
        let lsize = ""
        switch(this.props.data.link_button_border)
        {
            case "gentle":
                bordered = "rounded"
                break
            case "capsule":
                bordered = "rounded-pill"
                break
            case "straight":
            default:
                bordered = "rounded-0"
                break
        }
        switch(this.props.data.link_button_type)
        {
            case "contrast":
                ltype   = "btn-secondary"
                bcolor  = this.props.revertColor
                bgcolor = this.props.revertColor
                break
            case "danger":
                ltype = "btn-danger"
                break
            case "bordered":
                ltype = " btn-outline-secondary"
                bcolor  = this.props.revertColor
                lbcolor  = this.props.revertColor
                break
            case "primary":
            default:
                ltype = "btn-primary"
                break
        }
        switch(this.props.data.link_size)
        {
            case "large":
                lsize = "btn-lg"
                break
            case "small":
                lsize = "btn-sm"
                break
            case "normal":
            default:
                lsize = ""
                break
        }
        return Style.it(
            `
            .btn
            {
                border-color: ${bcolor};
                background-color: ${bgcolor};
                color: ${lbcolor};
            }
            `,
            this.props.data.link_type === "inner"
                ?
                <Link
                    className={`btn px-5  ${ltype} ${bordered} ${lsize} `}
                    to={this.props.data.link}
                >
                    {this.props.data.link_label}
                </Link>
                :
                <a 
                    className={`btn px-5 ${ltype} ${bordered} ${lsize} `}
                    target="_blank"
                    href={this.props.data.link}
                >
                    {this.props.data.link_label}
                </a>
        )
    }
    renderContent(style) {
        const {palette, is_edit} = this.state;
        const { 
            uptitle,
            title,
            subtitle,
            undertitle,
            class_name,
            height, 
            width, 
            vertical_align, 
            exact_value, 
            horizontal_align,
            exact_horizontal_value,  
            text_align
        } = this.state.data
        
        let valign = vertical_align ? vertical_align : "center"
        let ve;
        let marginLeft, marginRight
        if( vertical_align === "exact_value" )
        {
            valign = "top"
            ve = exact_value + "%"
        }
        switch(horizontal_align)
        {
            case "start":
                marginLeft = 0
                marginRight = "auto"
                break
            case "end":
                marginLeft = "auto"
                marginRight = 0
                break
            default:
                marginLeft = exact_horizontal_value
                    ? 
                    exact_horizontal_value + "%"
                    :
                    "auto"
                marginRight = "auto"
                break
        }
        const icon = this.props.data.icon
            ?            
            <LayoutIcon
                src={this.props.data.icon}
                className=" landing-header-icon "
            />
            :
            null
        return <> 
        {
            Style.it(
                `
                .editible-text 
                {
                    outline-color: ${
                        this.props.revertColor  
                    }!important;
                }
                `,
                <div
                    className={ ` landing-section-core ` }
                >           
                    { icon }        
                    <EditibleText 
                        style={{
                            fontFamily: getFontNameByID(this.state.palette.h1.fontFamilyID),
                            maxWidth:width ? width : null,
                            justifyContent : valign,
                            marginTop: ve,
                            marginLeft,
                            marginRight,
                            ...style,
                            overflow: "hidden", 
                            color: this.props.data?.style
                                ?
                                this.props.data?.style?.color
                                :
                                this.props.revertColor, //palette?.main_text_color, 
                            textAlign: text_align,
                            alignItems : horizontal_align,
                            display: "flex",
                            flexDirection: "column"
                        }}
                        text={ title }
                        field_matrix={ ["data", "title"] }
                        is_edit={ is_edit }
                        className={`w-100 landing-header-title  ${class_name || ""} `}  
                        onEditContent={ this.onEditContent }
                        onEditOpen={ this.onEditOpen }
                        editible = {this.state.editible}
                        revertColor={this.props.revertColor}
                    />              
                    <EditibleText 
                        style={{
                            fontFamily: getFontNameByID(this.state.palette.subtitle.fontFamilyID),
                            maxWidth:width ? width : null,
                            justifyContent : valign,
                            marginTop: ve,
                            marginLeft,
                            marginRight,
                            ...style,
                            color: this.props.data?.style
                                ?
                                this.props.data?.style?.color
                                :
                                this.props.revertColor, //palette?.main_text_color, 
                            textAlign: text_align,
                            alignItems : horizontal_align,
                            display: "flex",
                            flexDirection: "column"
                        }}
                        text={ subtitle }
                        field_matrix={ ["data", "subtitle"] }
                        is_edit={ is_edit }
                        className={`w-100 landing-header-subtitle  ${class_name || ""} `}  
                        onEditContent={ this.onEditContent }
                        onEditOpen={ this.onEditOpen }
                        editible = {this.state.editible}
                        revertColor={this.props.revertColor}
                    />
                    <div className="d-flex w-100 justify-content-center">
                        { this.link() }
                    </div>                    
                </div>
            )
        }            
        </>
    }
}
export default Header

