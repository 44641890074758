export default function css_type() {
  return [
    {
      field: "content",
      type: "string",
    },
    {
      field: "position",
      type: "select",
      values: [
        "inherit",
        "initial",
        "relative",
        "absolute",
        "fixed",
        "revert",
        "static",
        "unset",
      ],
    },
    {
      field: "textAlign",
      type: "select",
      values: [
        "inherit",
        "initial",
        "left",
        "center",
        "right",
      ],
    },
    {
      field: "flexDirection",
      type: "select",
      values: [
        "row",
        "column",
        "row-reverse",
        "column-reverse",
        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "justifyContent",
      type: "select",
      values: [
        "center",
        "end",
        "flex-end",
        "flex-start",
        "left",
        "revert",
        "right",
        "space-around",
        "space-between",
        "space-evenly",
        "start",
        "stretch",
        "normal",
        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "justifyContent",
      type: "select",
      values: [
        "center",
        "end",
        "flex-end",
        "flex-start",
        "left",
        "revert",
        "right",
        "space-around",
        "space-between",
        "space-evenly",
        "start",
        "stretch",
        "normal",
        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "justifySelf",
      type: "select",
      values: [
        "auto",
        "baseline",
        "center",
        "end",
        "flex-end",
        "flex-start",
        "left",
        "revert",
        "right",
        "space-around",
        "space-between",
        "space-evenly",
        "start",
        "stretch",
        "normal",
        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "justifyItems",
      type: "select",
      values: [
        "baseline",
        "center",
        "end",
        "flex-end",
        "flex-start",
        "left",
        "revert",
        "right",
        "space-around",
        "space-between",
        "space-evenly",
        "start",
        "stretch",
        "normal",
        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "alignItems",
      type: "select",
      values: [
        "baseline",
        "center",
        "end",
        "flex-end",
        "flex-start",
        "left",
        "revert",
        "right",
        "space-around",
        "space-between",
        "space-evenly",
        "start",
        "stretch",
        "normal",
        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "fontWeight",
      type: "select",
      values: [
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        "thin",
        "normal",
        "bold",
      ],
    },
    {
      field: "display",
      type: "select",
      values: [
        "block",
        "inline",
        "run-in",

        "flow",
        "flow-root",
        "table",
        "flex",
        "grid",
        "ruby",

        "block flow",
        "inline table",
        "flex run-in",

        "list-item",
        "list-item block",
        "list-item inline",
        "list-item flow",
        "list-item flow-root",
        "list-item block flow",
        "list-item block flow-root",
        "flow list-item block",

        "table-row-group",
        "table-header-group",
        "table-footer-group",
        "table-row",
        "table-cell",
        "table-column-group",
        "table-column",
        "table-caption",
        "ruby-base",
        "ruby-text",
        "ruby-base-container",
        "ruby-text-container",

        "contents",
        "none",

        "inline-block",
        "inline-table",
        "inline-flex",
        "inline-grid",

        "inherit",
        "initial",
        "unset",
      ],
    },
    {
      field: "color",
      type: "color",
      distination:"color"
    },
    {
      field: "background",
      type: "color",
      distination:"background",
    },
    {
      field: "backgroundColor",
      type: "color",
      distination:"background",
    },
    {
      field: "borderColor",
      type: "color",
      distination: ["color", "background"],
    },
    {
      field: "borderTopColor",
      type: "color",
      distination:["color", "background"],
    },
    {
      field: "borderBottomColor",
      type: "color",
      distination:["color", "background"],
    },
    {
      field: "borderLeftColor",
      type: "color",
      distination:["color", "background"],
    },
    {
      field: "borderRightColor",
      type: "color",
      distination: ["color", "background"],
    },
    {
      field: "outlineColor",
      type: "color",
      distination:["color", "background"],
    },
    {
      field: "fontFamily",
      type: "google_font",
    },
    {
      field: "width",
      type: "size",
    },
    {
      field: "height",
      type: "size",
    },
    {
      field: "top",
      type: "size",
    },
    {
      field: "bottom",
      type: "size",
    },
    {
      field: "left",
      type: "size",
    },
    {
      field: "right",
      type: "size",
    },
    {
      field: "padding",
      type: "size",
    },
    {
      field: "margin",
      type: "size",
    },
    {
      field: "paddingTop",
      type: "size",
    },
    {
      field: "paddingBottom",
      type: "size",
    },
    {
      field: "paddingLeft",
      type: "size",
    },
    {
      field: "paddingRight",
      type: "size",
    },
    {
      field: "marginTop",
      type: "size",
    },
    {
      field: "marginBottom",
      type: "size",
    },
    {
      field: "marginLeft",
      type: "size",
    },
    {
      field: "marginRight",
      type: "size",
    },
    {
      field: "fontSize",
      type: "size",
    },
    {
      field: "borderRadius",
      type: "size",
    },
    {
      field: "borderTopLeftRadius",
      type: "size",
    },
    {
      field: "borderTopRightRadius",
      type: "size",
    },
    {
      field: "borderBottomLeftRadius",
      type: "size",
    },
    {
      field: "borderBottomRightRadius",
      type: "size",
    },
    {
      field: "zIndex",
      type: "int",
    },
  ]
}
