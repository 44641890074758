import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from "recompose"
import { withRouter, } from "react-router" 
import FestComponent from "./FestComponent"
import { initArea, __ } from 'react-pe-utilities/dist' 
import WPFestSettings from './utilities/WPFestSettings'
import _fetch from '.'
import User from './utilities/User'
import { AppToaster } from 'react-pe-useful/dist'
import { Intent } from '@blueprintjs/core'

class FestRegisterRole extends FestComponent  
{
    command = "register-user-role"
    header_type = "reqister-user"
    setStartState = () =>
    {
        return {
            extendFetchName: null
        }
    }
    regField = () =>
    {
        return [
            {
                id: "email",
                name: "Send your e-mail",
                description: "This will be your login username.",
                onChange: this.onEmail,
                value: this.state.email,
                type: "email",
                require: true        
            },
            {
                id: "name",
                name: "Your name",
                onChange: this.onName,
                value: this.state.name,
                type: "text",
                require: false        
            },
            {
                id: "second",
                name: "Your second name",
                onChange: this.onSecondName,
                value: this.state.second,
                type: "text" ,
                require: false       
            },
            {
                id: "password",
                name: "Password",
                onChange: this.onPassword,
                value: this.state.password,
                type: "password",
                require: true        
            }
        ]
    }
    
    onLogin = (evt) => this.setState({ login: evt.currentTarget.value })
    onName = (evt) => this.setState({ name: evt.currentTarget.value })
    onSecondName = (evt) => this.setState({ second: evt.currentTarget.value })
    onEmail = (evt) => this.setState({ email: evt.currentTarget.value })
    onPassword = (evt) => this.setState({ password: evt.currentTarget.value })
    onExtendFetchParams = data =>
    {

    }

    onRegister = () => {
        console.log( WPFestSettings.serviceURL )
        _fetch(
            "get_fest_command",
            {
                festId:     WPFestSettings.festId,
                command:    "register_user", 
                log:        this.state.email,
                eml:        this.state.email,
                psw:        this.state.password,
                role:       this.props.match.params?.role
            },
            WPFestSettings.serviceURL,
            WPFestSettings.getToken( WPFestSettings.url ),
            "wpfa",
        )
        .then((data) => {
            console.log(data)
            if(data.data.msg)
            {
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: "tick",
                    message: __( data.data.msg ),
                    duration: 10000
                })
            }
        })
    }
    content = () =>
    {
        return <div className="alert alert-danger p-5 w-100 my-2">
            {__("You must logged out")}  
        </div> 
    }
    the_none = () =>
    {
        const fields = this.regField().map(field => {
            return <Fragment key={field.id}>
                <div className="row data-list ">
                    <div className="col-md-5 col-12 text-md-right text-center d-flex flex-column justify-content-center align-items-end">              
                        <div className='title'>
                                { __( field.name ) }
                            </div>
                            <div className='descr'>
                                { __( field.description ) }
                            </div>
                    </div>
                    <input
                        type={field.type}
                        value={field.value || ""}
                        onChange={field.onChange}
                        className="form-control input dark col-md-7 col-12 pb-2 pt-3 h-auto"
                    />
                </div>
            </Fragment>
        })
        return  <>        
            { fields }
        </>
    }


    addRender = () =>
	{
        return <div className="short-container my-4">                        
            { 
            User.id && User.id > 0
                ? 
                this.content() 
                : 
                WPFestSettings.is_register
                    ? 
                    <>        
                        { this.the_none()  }  
                        <div className="col-12 justify-content-center">
                            <div className="btn btn-primary mt-5" onClick={this.onRegister}>
                            {__("Register account")}
                            </div> 
                        </div>
                        
                    </> 
                    
                    : 
                    <div className="alert alert-danger p-5 w-100 my-2">
                        {__("Registration of new participants at the Festival is not possible")}
                    </div> 
            }            
        </div>
    }
}

FestRegisterRole.propTypes = {}

export default compose(
    withRouter,
)(FestRegisterRole)