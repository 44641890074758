import React from "react" 
import SectionContent from "./SectionContent"
import Style from "style-it"
import { getFontNameByID } from "./data/PalettePresets"
//import ElleganceQuote from "./quote/ElleganceQuote"
import GridQuote from "./quote/GridQuote"

class Quote extends SectionContent {
  is() {
    return this.props.data.text
  }

  renderContent( style ) { 
    return <GridQuote
      style={style}
      {...this.props}
    />
  }
}
export default Quote
