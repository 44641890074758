import React from "react"
import BasicState from "react-pe-basic-view/dist";
import YFSettings from "./yf/YFSettings";

class YandexFormAdminView extends BasicState
{
    render()
    {
        return <YFSettings
            
        />
    }
}

export default YandexFormAdminView 