import React, {Component, Fragment} from "react"; 
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";
import { ColorPicker, MediaChooser } from "react-pe-useful/dist";
import FieldInput from "react-pe-scalars/dist";
 
export default class GanreForm extends Component
{
	state = {
		post_title 	: this.props.post_title,
		color 		: this.props.color,
		icon 		: this.props.icon,
		icon_id 	: this.props.icon_id,
		
	}
	title = title =>
	{ 
		this.setState({post_title : title});
	}
	onColor = color =>
	{
		// console.log(color);
		//this.props.onChange("color", color, this.props.ID);
		this.setState({color : color.hex});
	}
	onIcon=(url, file, ID)=>
	{
		this.setState({ icon_id : -1, icon:url, icon_name: file?.name });
	}
	onSave = () =>
	{
		this.props.onSave( this.state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	}
	render()
	{		
		//console.log(this.props);
		const del_btn = this.props.isNew ? null : <Popover
					position={Position.TOP_LEFT}
					content={
						<div className="square p-3">
							<div>
								<p>
									{__("Are you realy want delete?")}
								</p>
								<ButtonGroup className="p-2 tex-right">
									<Button
										intent={Intent.DANGER}
										text={__("Yes")}
										icon="cross"
										onClick={this.onDelete}
									/>
								</ButtonGroup>
							</div>
							
						</div>						
					}
				>
					<Button
						text={__("Delete")}
						icon="cross"
					/>
				</Popover>
				
		return <Fragment>
			<FieldInput
				title={__("Title")}
				commentary={__("insert title by any symbols")}
				value={this.state.post_title}
				onChange={ this.title}
			/>
			<FieldInput
				type="media"
				title={__("Icon")}
				commentary={__("Upload image file no more 500kB and .svg, .bmp, .jpg, .png or .gif format")}
				tab="lt"
				hideLib
				isURLHide
				width={200}
				height={200}
				value={this.state.icon}
				onChange={ this.onIcon}
			/>
			<FieldInput
				type="color"
				title={__("Icon")}
				commentary={__("Choose color")}
				value={this.state.color}
				onChange={ this.onColor}
			/> 
			<ButtonGroup className="p-2 tex-right">
				<Button
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}