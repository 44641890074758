import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types' 
import ToolPanelGroup from 'modules/pe-admin-module/views/scalars/ToolPanelGroup'
import GridLayout from './gridSectionParams/GridLayout' 

const GridSectionParams = props => {
    const [panels, setPanels] = useState({})
    const getPropsData = () =>
    {
        let d = props.props?.elData?.data || {}
        //d = { ...d, ...(props.props?.elData?.composition || {}) }
        return d
    }
    const [data, setData] = useState( getPropsData() ) 
    const [composition, setComposition] = useState( props.props?.elData?.composition || {} )
    const [leftX, setLeftX] = useState( 320 )
    // console.log( data )
    useEffect(() =>
    {
        setPanels({
            left: {
                panels: [
                    {
                        x : 0,
                        y : 0,
                        width: leftX,
                        height:220,
                        isOpen:true,
                        title: "Grid layout",
                        __children: [
                            {
                                parent : "Grids",
                                field : "sections",
                            },
                            {
                                parent : "Grids",
                                field : "grid_gap",
                                default: 0
                            }
                        ]
                    }  
                ],
                width: 290,
            }            
        })
    }, [])
    const onUpdate = (value, field) =>
    {
        if( !props.props.onUpdate ) return
        let d = {...data}
        d[field] = value 
        props.props.onUpdate( { data: d } )
    }  
    return <div className="position-relative h-100">
        <div className="flex-centered h-100">
            <GridLayout
                gap={data.grid_gap ? data.grid_gap : 0}
                sections={data.sections}
                grids={data.grids}
                onUpdate={_data => onUpdate(_data, "grids")}
                composition={composition}
            />
        </div>
        <div 
            className="tool-panel-area  light-thumb left-panel"
            style={{ width: 0 }}
        >
        { 
            panels.left?.panels?.map((panel, i) => {
                return <ToolPanelGroup
                    key={i}
                    panel={{...panel}}
                    data={data}
                    width={panels.left?.width}
                >
                
                </ToolPanelGroup>
            })            
        }            
        </div> 
    </div>
}

GridSectionParams.propTypes = {}

export default GridSectionParams