import __convict from "../../../assets/img/landing/palette/convict.svg"
import __pin from "../../../assets/img/landing/palette/pin.svg"
import __bubbles from "../../../assets/img/landing/palette/bubbles.svg"
import __clud from "../../../assets/img/landing/palette/clud.svg"
import __diagonal from "../../../assets/img/landing/palette/diagonal.svg"
import __magic_garden from "../../../assets/img/landing/palette/magic_garden.jpg"
import __cells from "../../../assets/img/landing/palette/cells.svg"
import DataContext from "../DataContext"

export default function PalettePresets() {
  return [
    {
      id: "basic",
      title: "Basic",
      background_color: "#EEE",
      cards_color: "#FEFEFE",
      color1: "#DDD",
      color2: "#CCC",
      color3: "#BBB",
      color4: "#AAA",
      color5: "#999",
      main_text_color: "#111",
      secondary_text_color: "#111",
      thirdary_text_color: "#444",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#FEFEFE",
        color: "#111",
        title: {
          fontSize: "1.4rem",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.3em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      subtitle:
      {
        size: "1.2em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: null,

    },
    {
      id: "classic",
      title: "Classic",
      background_color: "#E1E8ED",
      cards_color: "#F5F8FA",
      color1: "#5682b1",
      color2: "#2d4865",
      color3: "#159b64",
      color4: "#45652d",
      color5: "#D15240",
      main_text_color: "#343a40",
      secondary_text_color: "#1F4B99",
      thirdary_text_color: "#2965CC",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#FFF",
        color: "#111",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "1.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "art-deco",
      title: "Art Deco",
      background_color: "#454545",
      cards_color: "#FFFFFF",
      color1: "#faf7f2",
      color2: "#c1b3af",
      color3: "#a9958f",
      color4: "#9b857f",
      color5: "#cfaa67",
      main_text_color: "#f0f6ca",
      secondary_text_color: "#effaa6",
      thirdary_text_color: "#f0f6ca",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#eae4e4",
        color: "#16181a",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.6em",
        weight: "100",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
        before: {
          position: "absolute",
          bottom: 10,
          left: "100%",
          width: 225,
          height: 40,
          backgroundImage: "url(/assets/img/landing/art-deco.svg)",
          backgroundSize: "cover"
        },
        after: {
          position: "absolute",
          bottom: 10,
          right: "100%",
          width: 225,
          height: 40,
          backgroundImage: "url(/assets/img/landing/art-deco.svg)",
          backgroundSize: "cover"
        }
      },
      subtitle:
      {
        size: "1.2em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "arrival",
      title: "Arrival",
      background_color: "#efefef",
      cards_color: "#FFFFFF",
      color1: "#faf7f2",
      color2: "#c1b3af",
      color3: "#a9958f",
      color4: "#9b857f",
      color5: "#cfaa67",
      main_text_color: "#16181a",
      secondary_text_color: "#dc7322",
      thirdary_text_color: "#16181a",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#FFFFFF",
        color: "#16181a",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.2em",
        weight: "100",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
        before: {
          position: "absolute",
          bottom: "0px",
          left: "10px",
          width: "50px",
          height: 5,
          backgroundColor: "#575757",
        }
      },
      subtitle:
      {
        size: "1.2em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "snow_woolf",
      title: "Snow Woolf",
      background_color: "#e9e2d0",
      cards_color: "#575757",
      color1: "#112E47",
      color2: "#112E47",
      color3: "#112E47",
      color4: "#f4ba48",
      color5: "#dea32e",
      main_text_color: "#232323",
      secondary_text_color: "#232323",
      thirdary_text_color: "#232323",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#575757",
        color: "#FFF",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "1.8em",
        weight: "100",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      subtitle:
      {
        size: "1.2em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "mount_woolf",
      title: "Mount Woolf",
      background_color: "#575757",
      cards_color: "#e9e2d0",
      color1: "#112E47",
      color2: "#112E47",
      color3: "#112E47",
      color4: "#f4ba48",
      color5: "#dea32e",
      main_text_color: "#FFF",
      secondary_text_color: "#e9e2d0",
      thirdary_text_color: "#FFF",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#e9e2d0",
        color: "#232323",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "1.8em",
        weight: "100",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      subtitle:
      {
        size: "1.2em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "energy",
      title: "Energy",
      background_color: "#EEE",
      cards_color: "#FFF",
      color1: "#112E47",
      color2: "#112E47",
      color3: "#112E47",
      color4: "#f4ba48",
      color5: "#dea32e",
      main_text_color: "#112E47",
      secondary_text_color: "#f4ba48",
      thirdary_text_color: "#f4ba48",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#FFF",
        color: "#112E47",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
        before: {
          content: "",
          width: "calc(100% - 20px)",
          height: 4,
          backgroundColor: "#f4ba48",
          position: "absolute",
          bottom: 0,
          left: 0,
          margin:"0 10px"
        }
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "dark_energy",
      title: "Dark energy",
      background_color: "#112E47",
      cards_color: "#FFF",
      color1: "#36648c",
      color2: "#36648c",
      color3: "#112E47",
      color4: "#f4ba48",
      color5: "#dea32e",
      main_text_color: "#FFF",
      secondary_text_color: "#f4ba48",
      thirdary_text_color: "#FFF",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#FFF",
        color: "#112E47",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
        before: {
          content: "",
          width: "calc(100% - 20px)",
          height: 4,
          backgroundColor: "#f4ba48",
          position: "absolute",
          bottom: 0,
          left: 0,
          margin:"0 10px"
        }
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "musi_master_light",
      title: "Musi master. Light",
      background_color: "#e2dad1",
      cards_color: "#f9f4ef",
      color1: "#9b845c",
      color2: "#191008",
      color3: "#8f755c",
      color4: "#a77c5b",
      color5: "#ab6d50",
      main_text_color: "#0a0503",
      secondary_text_color: "#5a392b",
      thirdary_text_color: "#4a3f29",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#f9f4ef",
        color: "#1f1a18",
        title: {
          fontSize: "1.9em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "#f1eae491",
        before: {
          
        }
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#454a56",
          color: "#FFF",
        },
        secondary: {
          background: "#454a56",
          color: "#FFF",
        },
        active: {
          background: "#352614",
          color: "#FFF",
        },
        danger: {
          background: "#a4725a",
          color: "#FFF",
        },
        warning: {
          background: "#9a8b61",
          color: "#182026",
        },
        success: {
          background: "#9b845c",
          color: "#F5F8FA",
        },
      },
      boxShadow: "",

    },
    {
      id: "musi_master_dark",
      title: "Musi master. Dark",
      background_color: "#111011",
      cards_color: "#cab9a9",
      color1: "#9b845c",
      color2: "#191008",
      color3: "#8f755c",
      color4: "#a77c5b",
      color5: "#ab6d50",
      main_text_color: "#f3e1c0",
      secondary_text_color: "#e0d7cf",
      thirdary_text_color: "#dec18c",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#cab9a9",
        color: "#1f1a18",
        title: {
          fontSize: "1.9em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "#5a392b",
        before: {
          
        }
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#454a56",
          color: "#FFF",
        },
        secondary: {
          background: "#454a56",
          color: "#FFF",
        },
        active: {
          background: "#352614",
          color: "#FFF",
        },
        danger: {
          background: "#a4725a",
          color: "#FFF",
        },
        warning: {
          background: "#9a8b61",
          color: "#182026",
        },
        success: {
          background: "#9b845c",
          color: "#F5F8FA",
        },
      },
      boxShadow: "",

    },
    {
      id: "musi_master_chill",
      title: "Musi master. Chill",
      background_color: "#425567",
      cards_color: "#d2d6dc",
      color1: "#453b2b",
      color2: "#635c45",
      color3: "#486479",
      color4: "#253958",
      color5: "#9d5624",
      main_text_color: "#f3e1c0",
      secondary_text_color: "#ff720e",
      thirdary_text_color: "#dec18c",
      background: {
        tile: __cells,
        tileOpacity: 0.33,
        size: 10,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#d2d6dc",
        color: "#1f1a18",
        title: {
          fontSize: "1.9em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
        before: {
          
        }
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#454a56",
          color: "#FFF",
        },
        secondary: {
          background: "#454a56",
          color: "#FFF",
        },
        active: {
          background: "#352614",
          color: "#FFF",
        },
        danger: {
          background: "#90461b",
          color: "#FFF",
        },
        warning: {
          background: "#9a8b61",
          color: "#182026",
        },
        success: {
          background: "#2a4e45",
          color: "#F5F8FA",
        },
      },
      boxShadow: "",

    },
    {
      id: "eboni",
      title: "Eboni",
      background_color: "#000",
      cards_color: "#243136",
      color1: "#e86060",
      color2: "#cd2727",
      color3: "#ac1e1e",
      color4: "#d30505",
      color5: "#d15240",
      main_text_color: "#FFF",
      secondary_text_color: "#FFF",
      thirdary_text_color: "#e1effd",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#243136",
        color: "#e1effd",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "1.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "super_light",
      title: "Super light",
      background_color: "#FFF",
      cards_color: "#FFF",
      color1: "#FFF",
      color2: "#EEE",
      color3: "#BBB",
      color4: "#AAA",
      color5: "#999",
      main_text_color: "#343a40",
      secondary_text_color: "#1F4B99",
      thirdary_text_color: "#2965CC",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "transparent",
        color: "#111",
        title: {
          fontSize: "1.4em",
          fontWeight: "100",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "1.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "none",

    },
    {
      id: "noir",
      title: "Noir",
      background_color: "#4a4759",
      cards_color: "#e8e4f5",
      color1: "#c9bfec",
      color2: "#614bb0",
      color3: "#4b426c",
      color4: "#6b2fba",
      color5: "#8b0a63",
      main_text_color: "#dfdbf1", //  #8c889c
      secondary_text_color: "#bfb7dd",
      thirdary_text_color: "#8c889c",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#f0edfb",
        color: "#535061",
        title: {
          fontSize: "1.2em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.7em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "#4b426c",
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#2e2c35",
          color: "#c1bbd6",
        },
        secondary: {
          background: "#6b6482",
          color: "#c1bbd6",
        },
        active: {
          background: "#856dd7",
          color: "#FFF",
        },
        danger: {
          background: "#dc358e",
          color: "#FFF",
        },
        warning: {
          background: "#ccd5a9",
          color: "#3f3b4c",
        },
        success: {
          background: "#87a586",
          color: "#dbe3ec",
        },
      },
      boxShadow: "none",
    },
    {
      id: "decadence",
      title: "Decadence",
      background_color: "#8c9e92",
      cards_color: "#f5fdf8",
      color1: "#c8e3d1",
      color2: "#6faa83",
      color3: "#3d995c",
      color4: "#667040",
      color5: "#ac802f",
      main_text_color: "#FFF", // "#3c8554", //  #8c889c
      secondary_text_color: "#FFF",//"#889c8e",
      thirdary_text_color: "#4f6856",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#f5fdf8",
        color: "#4f6856",
        title: {
          fontSize: "1.2em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.8em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "#ebf0d75200", 
        spn: {
          border:"1px solid #FFFFFF88"
        }
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#2e2c35",
          color: "#c1bbd6",
        },
        secondary: {
          background: "#6b6482",
          color: "#c1bbd6",
        },
        active: {
          background: "#856dd7",
          color: "#FFF",
        },
        danger: {
          background: "#dc358e",
          color: "#FFF",
        },
        warning: {
          background: "#ccd5a9",
          color: "#3f3b4c",
        },
        success: {
          background: "#87a586",
          color: "#dbe3ec",
        },
      },
      boxShadow: "none",

    },
    {
      id: "fenimor",
      title: "Fenimor",
      background_color: "#2d413e",
      cards_color: "#EEE",
      color1: "#88d9ab",
      color2: "#7db093",
      color3: "#5a9272",
      color4: "#29961e",
      color5: "#cfa546",
      main_text_color: "#edfff2", //"#494c4c", 
      secondary_text_color: "#f5f5f5",
      thirdary_text_color: "#f5f5f5",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 10,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#ffffff",
        color: "#4f6856",
        title: {
          fontSize: "1em",
          fontWeight: "900",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.2em",
        weight: "900",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "transparent",
        textTransform: "uppercase"
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
        backgroundColor: "transparent",
        textTransform: "uppercase"
      },
      button: {
        primary: {
          background: "#2e2c35",
          color: "#c1bbd6",
        },
        secondary: {
          background: "#6b6482",
          color: "#c1bbd6",
        },
        active: {
          background: "#856dd7",
          color: "#FFF",
        },
        danger: {
          background: "#cd876d",
          color: "#f9d0c0",
        },
        warning: {
          background: "#ccd5a9",
          color: "#3f3b4c",
        },
        success: {
          background: "#87a586",
          color: "#dbe3ec",
        },
      },
      boxShadow: "none",
    },
    {
      id: "naiv",
      title: "Naiv",
      background_color: "#E1E8ED",
      cards_color: "#EBF1F5",
      color1: "#F5F8FA",
      color2: "#58829c",
      color3: "#137CBD",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#202B33",
      secondary_text_color: "#5C7080",
      thirdary_text_color: "#1F4B99",
      background: {
        tile: __convict,
        tileOpacity: 0.05,
        size: 10,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#EBF1F5",
        color: "#111",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "1px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#566670",
          color: "#a5bfd0",
        },
        secondary: {
          background: "#6f8a9b",
          color: "#cfe5f3",
        },
        active: {
          background: "#7aaac8",
          color: "#FFF",
        },
        danger: {
          background: "#e98686",
          color: "#FFF",
        },
        warning: {
          background: "#e9e186",
          color: "#92671f",
        },
        success: {
          background: "#57cac5",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "blue steel",
      title: "Blue steel",
      background_color: "rgb(96 118 135)",
      cards_color: "transparent",
      color1: "rgb(207 222 234)",
      color2: "#58829c",
      color3: "#137CBD",
      color4: "#224e73",
      color5: "#6e2273",
      main_text_color: "#FFFFFF",
      secondary_text_color: "#7ec6ff",
      thirdary_text_color: "#a8daf8",
      background: {
        tile: __convict,
        tileOpacity: 0.05,
        size: 10,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "transparent",
        color: "#FFFFFF",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.5em",
        backgroundColor: "rgb(48 83 110)",
        color: "#7ec6ff",
        weight: "100",
        letterSpace: "0",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.2em",
        weight: "500",
        letterSpace: "2px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#486072",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#7aaac8",
          color: "#FFF",
        },
        danger: {
          background: "#e98686",
          color: "#FFF",
        },
        warning: {
          background: "#e9e186",
          color: "#92671f",
        },
        success: {
          background: "#57cac5",
          color: "#F5F8FA",
        },
      },
      boxShadow: "none",

    },
    {
      id: "bubbles",
      title: "Bubbles",
      background_color: "#EBF1F5",
      cards_color: "#F5F8FA",
      color1: "#EBF1F5",
      color2: "#F5F8FA",
      color3: "#137CBD",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#202B33",
      secondary_text_color: "#728C23",
      thirdary_text_color: "#9BBF30",
      background: {
        tile: __bubbles,
        tileOpacity: 0.125,
        size: 190,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#F5F8FA",
        color: "#111",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.4em",
        weight: "500",
        letterSpace: "0",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.0em",
        weight: "500",
        letterSpace: "15px",
        fontFamilyID: 1,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#9BBF30",
          color: "#FFF",
        },
        danger: {
          background: "#B83211",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#D1F26D",
          color: "#293742",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "exigent",
      title: "Exigent",
      background_color: "#182026",
      cards_color: "#293742",
      color1: "#182026",
      color2: "#293742",
      color3: "#137CBD",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#F5F8FA",
      secondary_text_color: "#BFCCD6",
      thirdary_text_color: "#CED9EF",
      background: {
        tile: __convict,
        tileOpacity: 0.25,
        size: 25,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#293742",
        color: "#EEE",
        title: {
          fontSize: "1.4em",
          fontWeight: "500",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.2em",
        weight: "700",
        letterSpace: "1px",
        fontFamilyID: 1,
        backgroundColor: "#182026",
      },
      subtitle:
      {
        size: "1.1em",
        weight: "300",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "#182026",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#556c7b",
          color: "#bad8ea",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#da136f",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0b8383",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "exigentPiquant",
      title: "Exigent Piquant",
      background_color: "#182026",
      cards_color: "#293742",
      color1: "#182026",
      color2: "#293742",
      color3: "#137CBD",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#F5F8FA",
      secondary_text_color: "#BFCCD6",
      thirdary_text_color: "#CED9EF",
      background: {
        tile: __convict,
        tileOpacity: 0.25,
        size: 25,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#293742",
        color: "#111",
        color: "#EEE",
        title: {
          fontSize: "2.4em",
          fontWeight: "500",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "1.0em",
        weight: "100",
        letterSpace: "10px",
        fontFamilyID: 1,
        backgroundColor: "#f42f8a",
      },
      subtitle:
      {
        size: ".9em",
        weight: "300",
        letterSpace: "0",
        fontFamilyID: 1,
        backgroundColor: "#182026",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#da136f",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0b8383",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "burusa",
      title: "Birusa",
      background_color: "#0E5A8A",
      cards_color: "#48AFF0",
      color1: "#0E5A8A",
      color2: "#48AFF0",
      color3: "#2965CC",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#F5F8FA",
      secondary_text_color: "#F5F8FA",
      thirdary_text_color: "#EBF1F5",
      background: {
        tile: __diagonal,
        tileOpacity: 0.125,
        size: 125,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#48AFF0",
        color: "#EEE",
        title: {
          fontSize: "1.2em",
          fontWeight: "900",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.4em",
        weight: "900",
        letterSpace: "0px",
        fontFamilyID: 1,
        backgroundColor: "#38516b",
      },
      subtitle:
      {
        size: "0.9em",
        weight: "500",
        letterSpace: "15px",
        fontFamilyID: 1,
        backgroundColor: "transparent",
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#87A629",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#FFC940",
          color: "#182026",
        },
        success: {
          background: "#238C2C",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "pinapple",
      title: "Pinapple",
      background_color: "#D9822B",
      cards_color: "#B6D94C",
      color1: "#D9822B",
      color2: "#B6D94C",
      color3: "#A82255",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#182026",
      secondary_text_color: "#63411E",
      thirdary_text_color: "#7D5125",
      background: {
        tile: __pin,
        tileOpacity: 0.25,
        size: 75,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#B6D94C",
        color: "#444",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.8em",
        weight: "900",
        letterSpace: "0px",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "0.9em",
        weight: "500",
        letterSpace: "15px",
        fontFamilyID: 1,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#87A629",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#FFC940",
          color: "#182026",
        },
        success: {
          background: "#238C2C",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "cidra",
      title: "Cidra",
      background_color: "#ef743e",
      cards_color: "#FFC940",
      color1: "#7f0000",
      color2: "#b30000",
      color3: "#d7301f",
      color4: "#ef6548",
      color5: "#fc8d59",
      main_text_color: "#182026",
      secondary_text_color: "#63411E",
      thirdary_text_color: "#BF8C0A",
      background: {
        tile: __magic_garden,
        tileOpacity: 0.25,
        size: "cover",
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#FFC940",
        color: "#111",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.3em",
        weight: "900",
        letterSpace: "12px",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.0em",
        weight: "700",
        letterSpace: "0",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#DB2C6F",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "rob_roy",
      title: "Rob Roy",
      background_color: "#ebd7a6",
      cards_color: "#EBF1F5",
      color1: "#5C7080",
      color2: "#EBF1F5",
      color3: "#C23030",
      color4: "#2b7322",
      color5: "#6e2273",
      main_text_color: "#293742",
      secondary_text_color: "#202B33",
      thirdary_text_color: "#202B33",
      background: {
        tile: __clud,
        tileOpacity: 0.25,
        size: 170,
        repeat: "repeat",
      },
      card: {
        backgroundColor: "#EBF1F5",
        color: "#111",
        title: {
          fontSize: "1.4em",
          fontWeight: "700",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.3em",
        weight: "900",
        letterSpace: "12px",
        fontFamilyID: 1,

      },
      subtitle:
      {
        size: "1.1em",
        weight: "700",
        letterSpace: "0",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#137CBD",
          color: "#FFF",
        },
        danger: {
          background: "#DB2C6F",
          color: "#FFF",
        },
        warning: {
          background: "#F29D49",
          color: "#182026",
        },
        success: {
          background: "#0D8050",
          color: "#F5F8FA",
        },
      },
      boxShadow: "2px 6px 6px 0 rgba(00,00,00,.33)",

    },
    {
      id: "orange",
      title: "Orange",
      background_color: "#e2cba6",
      cards_color: "#cbc097",
      color1: "#D9822B",
      color2: "#cbc097",
      color3: "#e5ae7e",
      color4: "#e88936",
      color5: "#d11f27",
      main_text_color: "#5e5349",
      secondary_text_color: "#6e5c4c",
      thirdary_text_color: "#8d7864",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 0,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#cbc097",
        color: "#433b33",
        title: {
          fontSize: "1.4em",
          fontWeight: "100",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "2.8em",
        weight: "100",
        letterSpace: "0px",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.1em",
        weight: "500",
        letterSpace: "5px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#87A629",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#FFC940",
          color: "#182026",
        },
        success: {
          background: "#238C2C",
          color: "#F5F8FA",
        },
      },
      boxShadow: "rgb(0 0 0 / 13%) 2px 1px 1px 1px, rgb(0 0 0 / 13%) 1px 4px 4px 1px, rgb(255 255 255 / 40%) -2px -1px 1px 1px, rgb(255 255 255 / 27%) -2px -3px 3px 1px",

    },
    {
      id: "cheerfulCoockumber",
      title: "Cheerful Coockumber",
      background_color: "#377176",
      cards_color: "#31a086",
      color1: "#3181a0",
      color2: "#31a053",
      color3: "#7aa031",
      color4: "#db952c",
      color5: "#db4c2c",
      main_text_color: "#ecfaef",
      secondary_text_color: "#d4eeda",
      thirdary_text_color: "#b6f1a9",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 0,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "#31a086",
        color: "#ecfaef",
        title: {
          fontSize: "1.2m",
          fontWeight: "100",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.0em",
        weight: "100",
        letterSpace: "0px",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.1em",
        weight: "500",
        letterSpace: "5px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#87A629",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#FFC940",
          color: "#182026",
        },
        success: {
          background: "#238C2C",
          color: "#F5F8FA",
        },
      },
      boxShadow: "rgb(0 0 0 / 19%) 2px 5px 5px 1px, rgb(0 0 0 / 18%) 4px 4px 4px 1px, rgb(255 255 255 / 20%) -2px 0px 1px 1px, rgb(255 255 255 / 27%) -2px -3px 3px 1px",

    },
    {
      id: "slityskies",
      title: "Slity Skies",
      background_color: "linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(125,185,232,1) 53%,rgba(30,87,153,1) 100%)",
      cards_color: "linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(19,80,96,1) 100%)",
      color1: "#3181a0",
      color2: "#31a053",
      color3: "#7aa031",
      color4: "#db952c",
      color5: "#db4c2c",
      main_text_color: "#ecfaef",
      secondary_text_color: "#d4eeda",
      thirdary_text_color: "#b6f1a9",
      background: {
        tile: "",
        tileOpacity: 0,
        size: 0,
        repeat: "no-repeat",
      },
      card: {
        backgroundColor: "linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(19,80,96,1) 100%)",
        color: "#ecfaef",
        title: {
          fontSize: "1.4em",
          fontWeight: "100",
          fontFamilyID: 1,
          marginBottom: "0"
        }
      },
      h1:
      {
        size: "3.0em",
        weight: "100",
        letterSpace: "0px",
        fontFamilyID: 1,
      },
      subtitle:
      {
        size: "1.1em",
        weight: "500",
        letterSpace: "5px",
        fontFamilyID: 2,
      },
      button: {
        primary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        secondary: {
          background: "#7aaac8",
          color: "#FFF",
        },
        active: {
          background: "#87A629",
          color: "#FFF",
        },
        danger: {
          background: "#C23030",
          color: "#FFF",
        },
        warning: {
          background: "#FFC940",
          color: "#182026",
        },
        success: {
          background: "#238C2C",
          color: "#F5F8FA",
        },
      },
      boxShadow: "rgb(0 0 0 / 19%) 2px 5px 5px 1px, rgb(0 0 0 / 18%) 4px 4px 4px 1px, rgb(255 255 255 / 20%) -2px 0px 1px 1px, rgb(255 255 255 / 27%) -2px -3px 3px 1px",

    }
  ]
}

export function getDefaultMatrix() {
  return PalettePresets()[0]
}

export function getFontNameByID(fontID) {
  const fonts = DataContext.data && DataContext.data.landing && DataContext.data.landing.fonts
    ? ["Open Sans", ...DataContext.data.landing.fonts.map((e) => e ? e.title : "Open Sans")]
    : ["Open Sans"]
  // console.log( fonts[ fontID ] );
  return fonts[fontID] ? `${fonts[fontID].split(":")[0]}, Open Sans` : "Open Sans"
}
