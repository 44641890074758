import React, {Component, Fragment} from "react";
import buttons from "./buttons.json"; 
import { Icon } from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";
import { NavLink, withRouter } from "react-router-dom";
import CabinetView from "../CabinetView";
import { compose } from "recompose";

class ButtonList extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			
		};
	}
	render()
	{
		const btns = Object.keys(buttons)
			.filter( elem => !buttons[elem].disabled  )
				.map((elem, i) =>
				{
					return <div 
						className={ 
							this.props.active === elem
							? 
							"bg-light text-dark fla uabtn pointer"
							:
							"text-secondary uabtn pointer" 
						} 
						style={{ margin: "0 -15px 0 -15px", textAlign:"center" }}
						button_name={elem} 
						key={i}
						onClick={this.onClick}
						
					>
						<Icon 
							icon={buttons[elem].icon}  
							iconSize={20} 
						/> 
						<div className="small mt-1">
							{__(buttons[elem].title)}
						</div>
					</div>
				});
		return <Fragment>
			{btns}
		</Fragment>
	}
	onClick = evt =>
	{
		const active = evt.currentTarget.getAttribute("button_name");
		this.props.switchPanel(active) 
	}
}
export default compose(withRouter)(ButtonList) 