import { Button, Dialog } from "@blueprintjs/core"
import React, { useState } from "react"

const JSONRowElement = props =>
{
    const [isOpen, onOpen] = useState(false)
    return <>
        <Button onClick={() => onOpen( !isOpen )}>
            { isOpen ? "Hide" : "Show"} JSON data
        </Button>
        <Dialog className="large" isOpen={ isOpen } onClose={ () => onOpen( !isOpen ) }>
            <div className="p-4 overflow-x-auto overflow-y-auto" style={{ maxHeight: "80vh" }}>
                <pre
                    className={` ${props.col}`}
                    style={{ ...props.style, width:"100%", overflow: "unset" }}
                >
                    { props.json }
                </pre>
            </div>
        </Dialog>
    </>
}
export default JSONRowElement