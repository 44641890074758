import { Button, ButtonGroup, Icon } from "@blueprintjs/core"
import ToolPanelGroup from "modules/pe-admin-module/views/scalars/ToolPanelGroup"
import CardFieldVariations from "modules/pe-landing-module/widgets/landingObject/CardFieldVariations"
import React, { useState } from "react"
import FieldInput from "react-pe-scalars/dist"
import { LayoutIcon } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities/dist"
import { CardFieldTypes } from "../../Card"
import matrix from "../../data/matrix"
import InputForm from "../InputForm"

const CardFieldPanelEdit = props =>
{ 
    const onField = ( value, field ) =>
    { 
        props.on( value, field, props.id )
    } 
    const onVariant = (variant) => {
		const object = { object: { ...props.object, variant } }
		console.log(object)
        props.onData(object)
	}
    const getField = () =>
    {
		let field = CardFieldTypes().filter((ee) => ee.type === props.object.type)[0]
		field = field ? field : CardFieldTypes()[0]
		return field
	}

	const existsVariant = (i) =>
    { 
		return getField().variants && typeof getField().variants[i] != "undefined"
	}
    const onType = (evt) => {
		const type = evt.currentTarget.getAttribute("type")
		const object = { object: { ...props.object, type } }
		props.on( type, "type", props.id )
	} 
    const sletecter = CardFieldTypes().map((e, i) => {
        const isActive = e.type === props.type 
        //console.log(e)
        return (
            <div
                className={`l-icon ${isActive ? " active " : ""}`}
                i={i}
                key={i}
                type={e.type}
                onClick={ onType }
            >
                <LayoutIcon
                    src={e.icon}
                    className="layout-icon p-2 "
                />
                <div className="small-text">
                    {__(e.title)}
                </div>
            </div>
        )
    })

    const form = () =>
    {
        return <>
            <ToolPanelGroup 
                panel={{title: "General сard field settings"}}
            >
                <ButtonGroup className="p-2 w-100 delimiter-bottom">
                    {
                        true
                            ?
                            <Button 
                                className="hint hint--bottom mr-1 p-1"
                                data-hint={__("Remove from group")}
                                onClick={() => props.onBreakChildhood( props.object )}
                            >
                                <Icon icon="ungroup-objects" iconSize={22} className="m-1"/>
                            </Button>
                            :
                            null
                    }
                    <Button 
                        className="hint hint--bottom mr-1"
                        data-hint={__("Double")}
                    >
                        <Icon icon="duplicate" iconSize={22} className="m-1"/>
                    </Button>
                    <Button 
                        className="hint hint--bottom mr-1"
                        data-hint={__("Delete")}
                    >
                        <Icon icon="cross" iconSize={22} className="m-1"/>
                    </Button>
                </ButtonGroup>
                <InputForm
                    vertical
                    booboo
                    className="p-0"
                    field={"CardField"}
                    source="CardField"
                    id={props.id}
                    data={props.object}
                    origin={props.origin}
                    on={(value, field) => onField( value, field )}
                />
            </ToolPanelGroup>
            <ToolPanelGroup 
                panel={{title: "Card field settings by Metaphore"}}
            >       
                <div>
                    {sletecter}
                </div>         
                <FieldInput
                    title={__("Design variant")}
                    field="variant"
                    type="image_radio"
                    _id={props.id}
                    on={ value => props.on( value, "variant" ) }
                    onChange={ onVariant }
                    values={
                        matrix().CardField.variant.values
                            .filter((e, i) => existsVariant(i))
                            .map((e, i) => ({
                                ...e,
                                img: getField().variants[i].img,
                                title: getField().variants[i].title,
                                height: 30,
                                icon_opacity: getField().variants[i].icon_opacity,
                            }))
                    }
                    editable
                    value={props.object.variant}
                    vertical 
                />
            </ToolPanelGroup>
        </>
        
    }
    return props.id
        ?
        form()
        :
        null
    
}
export default CardFieldPanelEdit