import React, { Component, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button } from '@blueprintjs/core';
import { __ } from 'react-pe-utilities/dist';
 

const MyEditor = props => {
	const [text, setText] = useState( props.text )
	const [isEdit, setEdit] = useState(false)
	const changeText = evt =>
	{
		const text = evt.target.value
		setText( text )
		props.onChange( text )
	}
	const edit = () =>
	{
		return <div className='p-3'>
			<textarea
				className='form-control input dark p-4'
				style={{ minHeight : 400, resizable : "vertical" }}
				value={ text }
				onChange={ changeText }
			/>
			<div className='btn btn-danger btn-sm mt-3' onClick={() => setEdit(false)}>
			{
				__("Finish edit")
			}
			</div>
		</div>
	}
	const show = () =>
	{
		return <div className='p-3'>
			<div dangerouslySetInnerHTML={{__html:text}} />
			<div className='btn btn-primary btn-sm mt-3' onClick={() => setEdit(true)}>
			{
				__("Start edit")
			}
			</div>
		</div>
	}
	return isEdit ? edit() : show()
}

MyEditor.propTypes = {}

export default MyEditor


// export default class MyEditor extends Component 
// {
// 	constructor(props)
// 	{
// 		super(props);
// 		const html = this.props.text ? this.props.text : "";
// 		const contentBlock = htmlToDraft(html);
// 		if (contentBlock) {
// 		  	const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
// 		  	const editorState = EditorState.createWithContent(contentState);
// 			// console.log( editorState );
// 		  	this.state = {
// 				editorState,
// 				text:contentBlock
// 		  	};
// 		}
// 	}

// 	onEditorStateChange = (editorState) => 
// 	{
// 		console.log( draftToHtml(convertToRaw(editorState.getCurrentContent())) )
// 		this.setState({ editorState });
// 		this.props.onChange( draftToHtml(convertToRaw(editorState.getCurrentContent())) );
// 	};

// 	render() 
// 	{
// 		const { editorState } = this.state;
// 		return (
// 			<div>
// 				<Editor
// 					editorState={editorState}
// 					wrapperClassName="demo-wrapper"
// 					editorClassName="demo-editor"
// 					onEditorStateChange={this.onEditorStateChange}
// 				/>
// 			</div>
// 		);
// 	}
// }