/* ==================================
 * 	Форма регулировки значений элемента, имеющего зматрицу в matrix.json
 ====================================*/

import React, { Component } from "react"
import { Intent, Tabs, Tab, Callout, } from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import matrix from "../data/matrix"
import FieldInput from "react-pe-scalars"

class InputForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			data: props.data ? props.data : {},
		}
		//console.log( this.state )
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.props.source !== prevState.source && typeof this.props.source !== "undefined") 
		{
			this.setState({ source: this.props.source })
		}
		if (this.props.id !== prevState.id && typeof this.props.id !== "undefined") {
			this.setState({ id: this.props.id })
		}
		if (this.props.data !== prevState.data && typeof this.props.data !== "undefined") 
		{ 
			this.setState({ data: this.props.data })
		}
		if (this.props.palette !== prevState.palette && typeof this.props.palette !== "undefined") 
		{
			this.setState({ palette: this.props.palette })
		}
	}

	render() {
		const ttabs = {}
		const tabTitles = {}

		// определяем матрицу полей текущего типа данных
		const cellData = this.state.cellData
			? this.state.cellData
			: matrix()[this.state.source]

		//определяем список разрешённых к публикации tab-групп
		const include_tab = Array.isArray(this.state.include_tab)
			?
			this.state.include_tab
			:
			this.state.include_tab ? [this.state.include_tab] : []

		// console.log( this.state )

		// перебираем все зарегистрированные поля и отрисовываем скаляры (регуляторы)
		for (const m in cellData) 
		{
			const mtrxCell = cellData[m]
			let ttb = mtrxCell.tab
			let ttbIcon = mtrxCell.tab_icon
			let ttbCommentary
			//console.log(m, this.state.data )

			if (
				include_tab.length > 0 && 
				include_tab.filter(e => e === ttb).length === 0
				|| ttb === "Lasy"
			)			
			{
				continue
			}

			const isincl = (include_tab.length > 0 && include_tab.filter(e => e == ttb).length > 0)
			if (!ttb || isincl) {
				ttb = __(" ")
				ttbIcon = "fas fa-cog"
			}


			if (!ttabs[ttb]) {
				ttabs[ttb] = []
			}
			//
			if (!ttbCommentary && mtrxCell.tab_commentary) {
				ttbCommentary = mtrxCell.tab_commentary
				ttabs[ttb].push(
					<Callout
						className="p-5 my-3"
						i="callout"
						key={`callout${ttbCommentary}`}
						intent={Intent.WARNING}
					>
						<div dangerouslySetInnerHTML={{ __html: __(ttbCommentary) }} />
					</Callout>,
				)
			}

			if (!tabTitles[ttb]) {
				tabTitles[ttb] = ttbIcon
			}

			// скрытое поле - пропускаем
			if (mtrxCell.hidden && !isincl) continue

			
			// dividers
			const tab_divider = mtrxCell.tab_divider
				?
				<div className="landing-tab-divider " key={mtrxCell.tab_divider}>
					{__( mtrxCell.tab_divider )}
				</div>
				:
				null

			// определяем поле - визуальный заголовок
			const visibled_value = mtrxCell.landing_object
				&& matrix()[mtrxCell.landing_object]?.visible_value
					? 
					matrix()[mtrxCell.landing_object]?.visible_value?.value
					: 
					"title"

			// есть ли у этого поля разрешаемое (demand)?
			// Если есть, то его значение соответствует 
			// хотя бы одному из разрешаемых?
			const notDemand = mtrxCell.demand
				&& mtrxCell.demand.value.filter(e => {
					/*
					 *	this.state.data - значения полей данного инстанса
					 *	mtrxCell.demand - название разрешаемого поля
					 */
					return this.state.data[mtrxCell.demand.field] == e
				}).length == 0;
			// У поля емть родитель
			const hasParent = mtrxCell.parent

			// разрешаемое поле имеет одно из разрешаемых значений. Или у этого поля нет разрешаемого...  
			if (!notDemand && !hasParent && m !== "lasy_load_type" && m !== "lasy_load_delay") 
			{

				//search children
				const childCells = Object.keys(cellData)
					.filter(cell => cellData[cell].parent && cellData[cell].parent == m)
					.map((cell, icell) => { 
						return  {
							...cellData[cell],
							field : cell,
							i: icell,
							value: this.state.data ? this.state.data[ cell ] : "",							
							origin: this.state 
						}
					}) 
				
				// console.log(mtrxCell)
					
				if(tab_divider)	ttabs[ttb].push(tab_divider)

				ttabs[ttb].push(<FieldInput
					field={m}
					key={m}
					id={this.state.ID}
					section_id={this.state.id}
					on={(value, dopol) => this.on(value, m, dopol)}
					onChange={(value, dopol) => this.on(value, m, dopol)}
					visibled_value={visibled_value}
					vertical={this.state.vertical}
					editable
					{...mtrxCell}
					origin={this.state}
					sourceData={this.state.data ? this.state.data[m] : ""}
					value={this.state.data ? this.state.data[m] : ""}
					palette={this.state.palette}
					sub_menus={ childCells }
					onSubMenu={(value, dopol) => this.on(value, dopol)}

					onAdd={this.props.onAdd}
					onDownload={this.props.onDownload}
					onLoadChange={this.props.onLoadChange}
					onClearOpen={this.props.onClearOpen} 
					onApply={this.props.onApply} // CardTemplateEditor
					onTry={this.props.onTry} // CardTemplateEditor
					onSingleRemove={this.props.onSingleRemove} // CardTemplateEditor
					onUpdate={this.props.onUpdate}
					onClipboardPaste={data => {
						console.log("InputForm.onClipboardPaste")
						if(this.props.onClipboardPaste)
							this.props.onClipboardPaste(data)
					}}
					onClipboardCopy={data => {
						console.log("InputForm.onClipboardCopy", this.props.onClipboardCopy )
						if(this.props.onClipboardCopy)
							this.props.onClipboardCopy(data)
					}}
					onSelectField={this.props.onSelectField}
					selectField={this.props.selectField}
				/>)
			}
		}

		// распределяем все скаляры по группам ( поле tab в матрице )
		// если в матрице нет ни одного поля с tab - игнорируем распределение.

		const tabArray = Object.keys(ttabs)
		// console.log( tabArray )
		// console.log( ttabs[__(" ")] )
		return tabArray.length > 1
			?
			<div className="px-0" style={ this.props.style} >
				<Tabs
					onChange={this.onTab}
					animate={false}
					id={`tabs_${this.state.id}`}
					key={this.state.id}
					vertical
					className="tab-params "
				>
					{
						tabArray.map((e, i) =>
						// console.log( ttabs[ e ] );
						(
							<Tab
								key={i}
								id={e}
								title={
									tabTitles[e]
										?
										tabTitles[e].toString().indexOf(".svg") > 0
											?
											<div className="hint hint--left" data-hint={__(e)}>
												<div 
													className="landing-input-form-tab-icon"
													style={{
														backgroundImage:`url(/assets/img/landing/${tabTitles[e]})`
													}} 
												/>
												<div>{__(e)}</div>
											</div>
											:
											<div className="hint hint--left" data-hint={__(e)}>
												<i className={`${tabTitles[e]} fa-2x `} />
												<div>{__(e)}</div>
											</div>
										: 
										__(e)

								}
								panel={this.tab(ttabs[e], i, e)}
							/>
						))
					}
				</Tabs>
			</div>
			:
			<div className={ this.props.className ? this.props.className : ` px-4`}>
				{ ttabs[__(" ")] }
			</div>
	}

	/*
	 * 	Обновление всех скаляров и передача обновленных данных наверх
	 */
	on = (value, field, dopol) => {
		let val = value;
		if (typeof val == "string") {
			val = val.replace(/"([^"]+)"/g, '«$1»')
		}
		// console.log(val, field, dopol);
		if (this.props.on) this.props.on(val, field, dopol)
		const state = { ...this.state }
		state[field] = val
		this.setState(state)
	}

	/*
	 * 	Отрисовка таба для группы
	 */
	tab = (tabil, i, e) => (
		<div key={i}>
			<div className="title mb-1 text-center text-secondary">
				{__(e)}
			</div>
			<div>
				{tabil}
			</div>
		</div>
	)
}
export default InputForm
