

import React, { Component } from "react"
import {
  Position, Tooltip, Button, Dialog, Intent, ButtonGroup, Callout,
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 
import DataContext from "../../views/LandingState/DataContext"
import InputForm from "../../views/LandingState/edit/InputForm"
import { sprintf } from "react-pe-utilities"
import { components } from "../../views/LandingState/data/components"
import PalettePresets from "../../views/LandingState/data/PalettePresets"

class PaletteSingleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      isStartEdit: false,
      checked_list: Array.isArray(DataContext.data.sections)
        ?
        [...DataContext.data.sections]
        :
        []
    }
  }

  ch = true;
  componentWillUpdate(nextProps) 
  { 
    if (typeof nextProps.e.checked !== "undefined" && this.ch) 
    {
      this.setState({ e: { ...this.state.e, checked: nextProps.e.checked } })
      this.ch = false
      const _this = this
      setTimeout(() => {
        _this.ch = true
      }, 100)
    }
  }

  render() {
    const { e, i, isStartEdit } = this.state
    const { isEdit } = this.props
    const {
      background,
      h1,
      subtitle,
      button,
    } = e
    return (
      <div
        key={i}
        className="d-flex flex-column"
        style={{
          minHeight: 400,
          justifyContent: "stretch",
        }}
      >
        <div className="d-flex justify-content-between align-items-center bg-dark text-light">
          <div className="pl-2 font-weight-bold">{e.title}</div>
          <div className="d-flex ">
            <Tooltip content={__("Appoint to Sections")} position={Position.BOTTOM_RIGHT} className={!isEdit ? "" : "hidden"}>
              <Button
                minimal
                small
                className="text-light"
                icon={<i className="fas fa-thumbtack"></i>}
                onClick={this.onAppointOpen}
              />
            </Tooltip>
            <Tooltip content={__("Delete")} position={Position.BOTTOM_RIGHT} className={!isEdit ? "" : "hidden"}>
              <Button
                minimal
                small
                className="text-light"
                icon={<i className="fas fa-times"></i>}
                onClick={this.onDeleteOpen}
              />

            </Tooltip>
            <Tooltip content={__("Edit")} position={Position.BOTTOM_RIGHT} className={!isEdit ? "" : "hidden"}>
              <Button
                minimal
                small
                className="text-light"
                icon={<i className="fas fa-cog"></i>}
                onClick={this.onOpen}
              />
            </Tooltip>
            <Tooltip content={__("Add")} position={Position.BOTTOM_RIGHT} className={isEdit ? "" : "hidden"}>
              <label className="_check_ d-flex align-items-center m-0">
                <span className="small mr-2 mt-2 hidden">{__("Add")}</span>
                <input
                  type="checkbox"
                  onChange={(evt) => this.props.onCheck(evt, e)}
                  checked={e.checked}
                />
              </label>
            </Tooltip>
          </div>
        </div>
        <div
          className="flex-grow-100"
          style={{

            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: e.background_color,
            color: e.main_text_color,
            padding: "0 50px 50px 50px",

          }}
        >
          {
            isStartEdit
              ? this.editPin("background", { transform: "translateX(10px) translateY(10px)" })
              : null
          }

          <div className="mb-2">

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${background ? background.tile : ""})`,
                backgroundSize: (background ? background.size : "cover"),
                backgroundRepeat: (background ? background.repeat : "repeat"),
                opacity: (background ? background.tileOpacity : 1),
              }}
            />
            <span
              className="position-relative mx-auto"
              style={{
                color: e.secondary_text_color,
                fontSize: h1 ? h1.size : "2rem",
                fontWeight: h1 ? h1.weight : 500,
                letterSpacing: h1 ? h1.letterSpace : 0,
                fontFamily: this.getFont(h1 ? h1.fontFamilyID : 0),
                backgroundColor: h1 ? h1.backgroundColor : null,
                padding: "2px 10px",
                position: "relative"
              }}
            >
              {__("Title")}
              {
                isStartEdit
                  ? this.editPin("h1", { transform: "translateY(10px)" })
                  : null
              }
              {
                e.h1.before
                  ?
                  <span
                    style={e.h1.before}
                  >

                  </span>
                  : null
              }
            </span>
          </div>
          <div
            className="position-relative"
            style={{
              color: e.thirdary_text_color,
              fontSize: subtitle ? subtitle.size : "1.2rem",
              fontWeight: subtitle ? subtitle.weightweight : 300,
              letterSpacing: subtitle ? subtitle.letterSpace : 0,
              paddingBottom: 5,
              fontFamily: this.getFont(subtitle ? subtitle.fontFamilyID : 0),
            }}
          >
            {__("subtitle")}
            {
              isStartEdit
                ? this.editPin("subtitle", { transform: "translateX(10px) translateY(10px)" })
                : null
            }
          </div>

          <div
            className="position-relative"
            style={{
              color: e.main_text_color,
              paddingBottom: 5,
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non luctus risus.
            {
              isStartEdit
                ? this.editPin("thirdary_text_color", { transform: "translateX(10px) translateY(10px)" })
                : null
            }
          </div>
          <div
            style={{
              boxShadow: e.boxShadow,
              background: e.card ? e.card.backgroundColor : "",
              zIndex: 2,
              position: "relative",
              color: e.card ? e.card.color : "#111"
            }}
          >
            <div className="position:relative">
              <div className="d-flex">
                <div
                  className="landing-palette-color-example"
                  style={{
                    backgroundColor: e.color1,
                  }}
                >
                  {
                    isStartEdit
                      ? this.editPin("color1")
                      : null
                  }
                </div>
                <div
                  className="landing-palette-color-example"
                  style={{
                    backgroundColor: e.color2,
                  }}
                >
                  {
                    isStartEdit
                      ? this.editPin("color2")
                      : null
                  }
                </div>
                <div
                  className="landing-palette-color-example"
                  style={{
                    backgroundColor: e.color3,
                  }}
                >
                  {
                    isStartEdit
                      ? this.editPin("color3")
                      : null
                  }
                </div>
                <div
                  className="landing-palette-color-example"
                  style={{
                    backgroundColor: e.color4,
                  }}
                >
                  {
                    isStartEdit
                      ? this.editPin("color4")
                      : null
                  }
                </div>
                <div
                  className="landing-palette-color-example"
                  style={{
                    backgroundColor: e.color5,
                  }}
                >
                  {
                    isStartEdit
                      ? this.editPin("color5")
                      : null
                  }
                </div>
              </div>

              <div
                className="h-50 w-100 p-3"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="position-relative" style={e.card ? e.card.title : {}}>
                  {
                    isStartEdit
                      ? this.editPin("card.title")
                      : null
                  }
                  {__("Card title")}
                </div>
              </div>

              <div
                className="h-50 w-100 p-3"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="position-relative">
                  {
                    isStartEdit
                      ? this.editPin("text")
                      : null
                  }
                  Lorem ipsum dolor sit amet.
                </div>
              </div>
              <div
                className="h-25 w-100 btn-group"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  color: e.secondary_text_color,
                  padding: 12,
                }}
              >
                <div
                  className="btn btn-sm"
                  style={{
                    backgroundColor: button && button.primary ? button.primary.background : "",
                    color: button && button.primary ? button.primary.color : "",
                  }}
                >
                  {__("primary")}
                  {
                    isStartEdit
                      ? this.editPin("button.primary")
                      : null
                  }
                </div>
                <div
                  className="btn btn-sm"
                  style={{
                    backgroundColor: button && button.secondary ? button.secondary.background : "",
                    color: button && button.secondary ? button.secondary.color : "",
                  }}
                >
                  {__("secondary")}
                  {
                    isStartEdit
                      ? this.editPin("button.secondary")
                      : null
                  }
                </div>
                <div
                  className="btn btn-sm"
                  style={{
                    backgroundColor: button && button.active ? button.active.background : "",
                    color: button && button.active ? button.active.color : "",
                  }}
                >
                  {__("active")}
                  {
                    isStartEdit
                      ? this.editPin("button.active")
                      : null
                  }
                </div>
                <div
                  className="btn btn-sm"
                  style={{
                    backgroundColor: button && button.danger ? button.danger.background : "",
                    color: button && button.danger ? button.danger.color : "",
                  }}
                >
                  {__("danger")}
                  {
                    isStartEdit
                      ? this.editPin("button.danger")
                      : null
                  }
                </div>
                <div
                  className="btn btn-sm"
                  style={{
                    backgroundColor: button && button.success ? button.success.background : "",
                    color: button && button.success ? button.success.color : "",
                  }}
                >
                  {__("success")}
                  {
                    isStartEdit
                      ? this.editPin("button.success")
                      : null
                  }
                </div>
                <div
                  className="btn btn-sm"
                  style={{
                    backgroundColor: button && button.warning ? button.warning.background : "",
                    color: button && button.warning ? button.warning.color : "",
                  }}
                >
                  {__("warning")}
                  {
                    isStartEdit
                      ? this.editPin("button.warning")
                      : null
                  }
                </div>

              </div>
            </div>
            {
              isStartEdit
                ? this.editPin("secondary")
                : null
            }
          </div>
        </div>

        <Dialog
          isOpen={this.state.isOpen}
          onClose={this.onOpen}
          className=""
          title={`${__("Edit")}: ${this.props.e.title}`}
        >
          <div className="p-5">
            <InputForm
              source="__palette"
              id={this.state.id}
              data={this.state.e}
              sourceType="__palette"
              on={(value, field) => this.onField(value, field)}
            />
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.isDeleteOpen}
          onClose={this.onDeleteOpen}
          className="square3"
        >
          <div className="bg-light p-3">
            {__("Delete ") + this.props.e.title + __(" palette?")}
            <ButtonGroup className="p-3">
              <Button intent={Intent.DANGER} onClick={this.onDeleteOpen}>
                {__("No")}
              </Button>
              <Button intent={Intent.SUCCESS} onClick={this.onDelete}>
                {__("Yes")}
              </Button>
            </ButtonGroup>
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.isAppointOpen}
          onClose={this.onAppointOpen}
          className="little2"
          title={sprintf(__("Appoint «%s» to Sections?"), this.props.e.title)}
        >
          <div className="bg-light p-3 dialog-content overflow-y-auto">
            {this.getAllSectionsList()}
          </div>
          <div className="dialog-footer p-2 d-flex justify-content-end">
            <Button className="mr-auto" minimal onClick={this.onAppointAll}>
              {__("Select all")}
            </Button>
            <ButtonGroup large>
              <Button intent={Intent.SUCCESS} onClick={this.onAppointAccept}>
                {sprintf(__("Appoint «%s» to selected Sections"), this.props.e.title)}
              </Button>
              <Button intent={Intent.DANGER} onClick={this.onAppointOpen}>
                {__("Close")}
              </Button>
            </ButtonGroup>
          </div>
        </Dialog>
      </div>
    )
  }

  getFont = (fontID) => {
    const fonts = DataContext.data.landing.fonts
      ? ["Open Sans", ...DataContext.data.landing.fonts.map((e) => e ? e.title : "Open Sans")]
      : ["Open Sans"]
    // console.log( fonts[ fontID ] );
    return fonts[fontID] ? `${fonts[fontID].split(":")[0]}, Open Sans` : "Open Sans"
  }

  onOpen = () => {
    // this.setState({ isOpen : !this.state.isOpen })
    this.setState({ isStartEdit: !this.state.isStartEdit })
  }
  onAppointOpen = () => {
    this.setState({ isAppointOpen: !this.state.isAppointOpen })
  }
  onDeleteOpen = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen })
  }
  onAppointAll = () => {
    let checked_list = [...this.state.checked_list].map(e => {
      return {
        ...e,
        current_template_id: this.state.e.id
      }
    })
    this.setState({ checked_list })
  }
  onAppointAccept = () => {
    this.setState({ isAppointOpen: false })
    // DataContext.data.sections.forEach((section, i) => {
    //   // Если в Dialoge "Appoint" Секция назначена данному Шаблону - присваеваем её его
    //   // console.log(i, this.state.checked_list[i].current_template_id, this.state.e.id)
    //   if (this.state.checked_list[i].current_template_id === this.state.e.id) {
    //     DataContext.data.sections[i].background = { }
    //     DataContext.data.sections[i].current_template_id = this.state.e.id
    //   }
    // })
    DataContext.updatePalette( this.state.e.id, this.state.checked_list )
  }

  onDelete = () => {
    this.setState({ isDeleteOpen: false })
    if (this.props.onDelete) {
      this.props.onDelete(this.props.i)
    }
  }

  onField = (value, field) => {
    console.log(this.state.e)
    console.log({ ...value, id: this.props.e.id }, field)
    if (this.props.onEdit) {
      this.props.onEdit({ ...value, id: this.props.e.id })
    }
  }
  onChecked = (evt, index) => {
    const sections = [...this.state.checked_list]
    if (!sections || !Array.isArray(sections)) return
    const checked = evt.currentTarget.checked
    sections[index].current_template_id = checked ? this.state.e.id : PalettePresets()[0].id
    this.setState({ checked_list: sections })
  }
  // onChecked = (evt, index) => {
  //   const sections = DataContext.data.sections
  //   if (!sections) return
  //   const checked = evt.currentTarget.checked
  //   sections[index].current_template_id = checked ? this.state.e.id : PalettePresets()[0].id
  //   DataContext.data.sections = sections
  // }



  getAllSectionsList = () => {
    const checked_list = [...this.state.checked_list]
    if (!checked_list || checked_list.length === 0) return <Callout className="p-5">
      {__("No sections exists")}
    </Callout>
    return checked_list.map((section, index) => {
      const t = DataContext.data.landing.palette
        ?
        DataContext.data.landing.palette.filter(e => e.id === section.current_template_id)
        :
        []
      let template_name = t[0] ? t[0].title : "No template "
      return <div className="d-flex " key={index}>
        <label className="_check_" title={__(components()[section.type]?.title)}>
          <input
            type="checkbox"
            checked={this.state.e.id === section.current_template_id}
            onChange={evt => this.onChecked(evt, index)}
          />
          <div className="flex-grow-100 pl-2">
            {index + 1}.  <span className=" text-secondary ml-2 opacity_5" >
              <img height={15} src={components()[section.type]?.icon} />
            </span>
            <span className="title ml-2 text-uppercase" >
              {section.title.text ? section.title.text : `section #${index}`}
            </span>
            <span className=" ml-2 " >
              ({template_name})
            </span>
          </div>
        </label>
      </div>
    })
  }

  editPin = (zone, css) => (
    <div className="landing-palette-edit-pin" style={css} />
  )
}
export default PaletteSingleForm
