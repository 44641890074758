import IndexedDbRepository from "./IndexedDbRepository";


export default  class IndexDB
{      
    static verifyBrowser = () => true

    static add = (data, version) =>
    { 
      IndexDB.repository = new IndexedDbRepository( 'peLayoutData', 'Init', 'initObject' ) 
      Object.keys(data.getInit).forEach( key => {
        IndexDB.repository.save( { initObject: key, data: data.getInit[key] } ).then( tasks => {
          //console.log(tasks)
        })
      })  
    }
    static save = (data, keyName, id, tableName, baseName, version) =>
    { 
      IndexDB.repository = new IndexedDbRepository( baseName, tableName, keyName ) 
      const d =  { data, version } 
      d[keyName] = id
      IndexDB.repository.save( d ).then( tasks => {
        //console.log(tasks)
      })  
    }
    static find = (DBName, objectName, keyName, key) => 
    {
      IndexDB.repository = new IndexedDbRepository( DBName, objectName, keyName )
      return IndexDB.repository.findById( key )
    }
    static recover = (DBName, objectName, key) =>
    {
      IndexDB.repository = new IndexedDbRepository( DBName, objectName, key )
      return IndexDB.repository.findAll( key )
    }
}