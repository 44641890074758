import registerProject from "./register-project"

export default () =>
{
    return {
        "register-project": {
            component   : registerProject,
            id          : "register-project"
        }
    }
}