import React, { Component } from "react" 


class Border extends Component {
  constructor(props) {
    super(props)
    this.state = {
      borderTop: this.props.value.borderTop,
      borderRight: this.props.value.borderRight,
      borderBottom: this.props.value.borderBottom,
      borderLeft: this.props.value.borderLeft,
    }
  }

  render() {
    // console.log( this.props );
    return (
      <div className="d-flex flex-column p-5 mb-3">
        <div
          className=""
          style={{
				  width: 200,
				  position: "absolute",
				  left: "50%",
				  marginLeft: -100,
				  height: 10,
				  marginTop: 0,
          }}
        />
      </div>
    )
  }

	onTop = (evt) => {
	  const marginTop = evt.currentTarget.value
	  this.setState({ marginTop })
	}

	onLeft = (evt) => {
	  const marginLeft = evt.currentTarget.value
	  this.setState({ marginLeft })
	}

	onRight = (evt) => {
	  const marginRight = evt.currentTarget.value
	  this.setState({ marginRight })
	}

	onBottom = (evt) => {
	  const marginBottom = evt.currentTarget.value
	  this.setState({ marginBottom })
	}
}
export default Border
