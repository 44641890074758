import React, { Component } from "react" 
import QR from "../../core/QR"
import {getQRDefaultData} from "../QRCodeAdminView" 
import socials from "../../core/socials"

class QRCodeFileList extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      // listData : [],
      // selected:[]
    }
  }

  
  componentWillUpdate(nextProps)
  { 
    // console.log(nextProps.refreshMe)
    if(nextProps.refreshMe)
    {
      console.log("update QR list started")
      if(this.props.refresh)
      {
        this.setState({
          listData : [],
          selected:[]
        })
        this.props.refresh()
      }
    }
  }

  onChecked = id =>
  {
    let selected = [...this.props.selected]
    if(selected.filter(e => e === id).length >0)
    {
      selected = selected.filter( e => e !== id)
    }
    else
    {
      selected.push(id)
    } 
    //this.setState({ selected })
    if(this.props.onSelect)
      this.props.onSelect(selected)
  }
  onUpdate = (options, i) =>
  {
    // console.log(options, i) 
    if( this.props.onUpdate )
      this.props.onUpdate(options, i)
  }
  getLogo = ( qr ) =>
  {
    //if (this.props.logo ) return false
    if( !this.props.params || !this.props.params.isAutoLogo ) return false
    const template = this.props.params.template && [ "black", "white", "color", "empty"].filter( e => e === this.props.params.template).length > 0
      ?
      this.props.params.template
      :
      "black"
    const form = this.props.params.form && ["rounded", "squared"].filter(e => e === this.props.params.form).length > 0
      ?
      this.props.params.form
      :
      "squared"
    let sc
    [
      [ "YouTube", "youtu.be" ],
      [ "YouTube", "youtube.com" ],
      "WhatsApp",
      "WeChat",
      [ "VK", "vk.com" ],
      "Vimeo",
      "Viber",
      "Twitter",
      "Twitch",
      "Tumblr",
      "TikTok",
      [ "Telegram", "t.me" ],
      "Spotify",
      "SoundCloud",
      "Snapchat",
      "Skype",
      "Pinterest",
      "Ok",
      "Messenger",
      "LinkedIn",
      "Instagram",
      "Facebook",
      "Behance"
    ].forEach(e => {
      const substr = Array.isArray(e) ? e[1] : e
      const nm = Array.isArray(e) ? e[0] : e 
      if( qr.text.toLowerCase().indexOf( substr ) >= 0  )
      { 
        sc = template === "empty"
          ?
          socials().empty
          :
          socials()[form][ template ][ nm ]
      } 
      
    }) 
    if( this.props.params.isEmptyLogo && !sc )
    {
      sc = socials().empty
    }
    //console.log(sc)
    return sc ? sc : false   
  }
  render()
  {    
    const {defaults, listData, selected} = this.props 
    if( !this.props.params ) return false
    // console.log( this.props.params ) 
    if(!Array.isArray(listData)) return null
    const list = listData.map((qrData, i) => {
      const qr = {...getQRDefaultData(), ...qrData}
      const imgSrc = this.getLogo( qr ) 
      //console.log(imgSrc, qr)
      const options = {       
        ...qr, 
        title: qr.publish_text ? qr.title ? qr.title : qr.post_title : null,
        logo : imgSrc ? imgSrc : qr.thumbnail, 
        drawer: "svg",
        format:'JPG',
        binary: true,
        ...( selected.filter(e => e === qr.id).length > 0 ? defaults : {} ),
        titleBackgroundColor : "transparent" ,
        logoBackgroundTransparent: false,
        logoBackgroundColor: "#FFF",
        logoMaxWidth : qr.width ? qr.widt - 40: 160,  
        logoMaxHeight : qr.height ? qr.height - 40: 160,  
      }
      //console.log(options)
      return <QR 
        options={options}  
        key={i} 
        i={i}
        isManipulators 
        checked={ selected.filter(e => e === qr.id).length > 0}
        onChecked={this.onChecked}
        delete={id => this.props.deleteQR(id) }
        onUpdate={this.onUpdate}
      />
       
    })
    return <div className="d-flex flex-wrap">
      {list}
    </div>
  }
    
}

export default QRCodeFileList 