import React, { useRef } from "react"
import GridEditPanel from "./GridEditPanel"

const InnerGrid = props =>
{ 
    const ref = useRef(); 
    return <div className="inner-grid-container" ref={ref} >
        <GridEditPanel
            i={props.i}
            data={props.data}
            clearEditPanels={props.clearEditPanels} 
            parentRef={ref}
            onDelete={  props.onDelete }
            onEdit={ props.onEdit }
            onRaze={ props.onRaze }
        />
    </div>
}
export default InnerGrid