import React, { Component, Fragment } from "react"
import {
  Button, ButtonGroup, Intent, Popover, Position, Tabs, Tab, Menu, MenuItem, Dialog,
} from "@blueprintjs/core"
import { AppToaster } from 'react-pe-useful'
import { __ } from "react-pe-utilities"
import {LayoutIcon} from 'react-pe-useful'
import InputForm from "./InputForm"
import FlaotDettingDialog from "./FlaotDettingDialog"

import matrix from "../data/matrix"
import section_groups from "../data/section_groups.json"

import FieldInput from "react-pe-scalars"
import DataContext from "../DataContext"
import PaletteSingleForm from "../../../widgets/landingObject/PaletteSingleForm"
import PalettePresets from "../data/PalettePresets"
import { components } from "../data/components"
import {Issue} from "react-pe-useful"
import $ from "jquery"

class TypeDialog extends Component {
  car = React.createRef()
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      current_type: props.type,
      currentTypeGroup: matrix()[props.type].sparams.group,
      //navbarTabId: "prm"
    }
  }


  render() {
    // console.log( this.state );
    const hiddenButton = this.state.is_hidden
      ? (
        <Button
          className="mb-1"
          icon="eye-open"
          fill
          onClick={() => this.props.onHide(this.state.menu.id, 0)}
        >
          {__("Show section for users")}
        </Button>
      )
      : (
        <Button
          className="mb-1"
          icon="eye-off"
          fill
          onClick={() => this.props.onHide(this.state.menu.id, 1)}
        >
          {__("Hide section for users")}
        </Button>
      )
    return [
      <div 
        className="pt-0 px-0 overflow-y-auto flex-grow-100 bg-tripp position-relative" 
        key={1}
        style={{ height: "calc(100% - 90px)" }}
      >
        <Tabs
          onChange={this.onTab}
          selectedTabId={this.state.navbarTabId}
          animate={false}
          id="TabsType"
          key="horizontal"
          vertical={false}
          className=" tab-light-head "
          panelClassName=" overflow-y-auto "
        >
          <Tab
            id="prm"
            title={(
              <div className="small">
                {" "}
                {__(this.state.current_title)}
                {" "}
              </div>
            )}
            className="h-100"
            panel={(
              <div className="" style={{ height: "calc(100% - 45px)" }}> 
                <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
                  <InputForm 
                    vertical
                    {...this.state}
                    source={this.state.current_type}
                    id={this.state.id}
                    data={this.state.data}
                    sourceType="section"
                    on={(value, field, dopol) => this.onField(value, field, "data", dopol)}
                    onClipboardPaste={this.onClipboardPastePRM} 
                    onClipboardCopy={this.onClipboardCopyPRM} 
                  />
                </div>
              </div>
            )}
          />
          <Tab
            id="title"
            title={(
              <div className="small">
                {__("Title")}
              </div>
            )}
            panel={this.title()}
            className="h-100"
          />
          <Tab
            id="composition"
            title={(
              <div className="small">
                {__("Composition")}
              </div>
            )}
            panel={this.composition()}
            className="h-100"
          />

          <Tab
            className="h-100"
            id="floats"
            title={(
              <div className="small">
                {__("Floats")}
              </div>
            )}
            panel={this.floats()} 
          />

          <Tab
            id="html"
            title={(
              <div className="small">
                {__("Style")}
              </div>
            )}
            panel={this.html()}
            className="h-100"
          />

          <Tab
            id="dilimiter"
            className="tab_hidden"
            title={(
              <div className="small">
                {__("Dilimiter")}
              </div>
            )}
            panel={this.dilimiter()} 
          />

          <Tab
            id="menu"
            className="tab_hidden"
            title={(
              <div className="small">
                {__("Menu")}
              </div>
            )}
            panel={this.menu()} 
          />
          <Tab
            id="bg"
            title={(
              <div className="small">
                {__("Back")}
              </div>
            )}
            panel={
              <div className="" style={{ height: "calc(100% - 45px)" }}> 
                <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
                  <InputForm 
                    {...this.state.background}
                    source="Background"
                    id={this.state.id}
                    data={this.state.background}
                    sourceType="Background"
                    palette={this.getPalette()}
                    on={(value, field, dopol) => this.onField(value, field, "background", dopol)}
                    vertical 
                  />
                </div>
              </div>
            }
            className="h-100"
          />
          <Tab
            id="template"
            className="tab_hidden"
            title={(
              <div className="small">
                {__("Template")}
              </div>
            )}
            panel={this.template()}
          />

          <Tab
            id="types"
            className="tab_hidden"
            title={(
              <div className="small">
                {__("Type")}
              </div>
            )}
            panel={this.types()}
          />
          <Tab
            id="visible"
            className="tab_hidden"
            title={""}
            panel={(
              <div className="" style={{ height: "calc(100% - 45px)" }}> 
                <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
                  <div className="p-4">
                    <FieldInput
                      field="visible_lg"
                      key="visible_lg"
                      title="Show at large screen"
                      id={this.state.id}
                      {...matrix().section.visible_lg}
                      on={(val) => this.onVisible("lg", val)}
                      onChange={(val) => this.onVisible("lg", val)}
                      editable
                      value={this.state.visible_lg}
                      vertical
                    />
                    <FieldInput
                      field="visible_sm"
                      key="visible_sm"
                      title="Show at laptop screen"
                      id={this.state.id}
                      {...matrix().section.visible_sm}
                      on={(val) => this.onVisible("sm", val)}
                      onChange={(val) => this.onVisible("sm", val)}
                      editable
                      value={this.state.visible_sm}
                      vertical
                    />
                    <FieldInput
                      field="visible_ms"
                      key="visible_ms"
                      title="Show at tablet screen"
                      id={this.state.id}
                      {...matrix().section.visible_ms}
                      on={(val) => this.onVisible("ms", val)}
                      onChange={(val) => this.onVisible("ms", val)}
                      editable
                      value={this.state.visible_ms}
                      vertical={false}
                    />
                    <FieldInput
                      field="visible_xs"
                      key="visible_xs"
                      title="Show at mobile screen"
                      id={this.state.id}
                      {...matrix().section.visible_xs}
                      on={(val) => this.onVisible("ms", val)}
                      onChange={(val) => this.onVisible("xs", val)}
                      editable
                      value={this.state.visible_xs}
                      vertical
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </Tabs>


        <Button
          className="position-absolute right mt-5 z-index-300"
          icon="error"
          minimal
          intent={Intent.WARNING}
          title={__("Report the common error")}
          onClick={() => this.setState({ issueOpen: !this.state.issueOpen })}
        >

        </Button>
        <Dialog
          title={__("Insert new bug issue")}
          isOpen={this.state.issueOpen}
          onClose={() => this.setState({ issueOpen: false })}
        >
          <Issue
            src={this.state.current_type + " tab: " + this.state.navbarTabId}
            sectionID={this.state.id}
          />
        </Dialog>

        <Popover
          className="position-absolute right top-10" 
          position={Position.LEFT_TOP}
          content={
            <div className="p-0 square">
              <ButtonGroup vertical={true} minimal={true} className="p-0 ">
                <Button alignText={"left"} large={true} onClick={() => this.setState({ navbarTabId: "types" })}>
                  {__("Type")}
                </Button>
                <Button alignText={"left"} large={true} onClick={() => this.setState({ navbarTabId: "template" })}>
                  {__("Template")}
                </Button>
                <Button alignText={"left"} large={true} onClick={() => this.setState({ navbarTabId: "menu" })}>
                  {__("Menu")}
                </Button>
                <Button alignText={"left"} large={true} onClick={() => this.setState({ navbarTabId: "visible" })}>
                  {__("Visible")}
                </Button>
                <Button alignText={"left"} large={true} onClick={() => this.setState({ navbarTabId: "dilimiter" })}>
                  {__("Dilimiter")}
                </Button>
              </ButtonGroup>
            </div>
          }
        >
          <Button
            minimal={true}
            icon={<i className="fas fa-ellipsis-v" />} 
          />
        </Popover>

      </div>,
      <div className="layout-simple-center pt-0 px-0 pb-0 flex-row " key={2}>
        <ButtonGroup fill vertical={false}>
          <Button
            intent={Intent.SUCCESS}
            onClick={this.onClick}
            className="small-button py-3"
          >
            {__("Update section")}
          </Button>
          <Popover
            content={(
              <div className="p-3">
                <ButtonGroup vertical>
                  {hiddenButton}
                  <Button
                    className="mb-1"
                    icon={<i className="fas fa-file-import" />}
                    onClick={this.onClipboardCopy}
                  >
                    {__("Copy section to clipboard")}
                  </Button>
                  <Button
                    onClick={this.onClipboardPaste}
                    icon={<i className="fas fa-file-export" />}
                    className="mb-1"
                  >
                    {__("Paste section from clipboard")}
                  </Button>
                </ButtonGroup>
              </div>
            )}
          >
            <Button
              intent={Intent.NONE}
              large={false}
              className="ml-1 small-button py-3"
              icon="chevron-down"
            >
              {__("Added actions")}
            </Button>
          </Popover>
          <Button
            icon="trash"
            intent={Intent.DANGER}
            className="ml-1 small-button py-3"
            onClick={() => this.props.onRnv(this.state.id)} 
          >
            {__("Delete Section")}
          </Button>
          <Button
            icon="cross"
            intent={Intent.DANGER}
            onClick={() => this.props.onClose()}
            title={__("Close")}
            className="ml-1 small-button py-3"
          />
        </ButtonGroup>
      </div>,
    ]
  }

  onTab = (navbarTabId) => this.setState({ navbarTabId });

  title() {
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-4 pb-5">
            <div className="layout-simple-center p-2 lead">
              {__("Title")}
            </div>
            <InputForm
              {...this.state}
              source="title"
              id={this.state.id}
              data={this.state.title}
              sourceType="title"
              on={(value, field) => this.onField(value, field, "title")}
            />
            <div className="layout-simple-center p-2 lead">
              {__("Undertitle")}
            </div>
            <InputForm
              {...this.state}
              source="descriptions"
              id={this.state.id}
              data={this.state.descriptions}
              sourceType="descriptions"
              on={(value, field) => this.onField(value, field, "descriptions")}
            />
          </div>
        </div>
      </div>
    )
  }

  types() {
    /**/
    const btns = []
    const tabs = Object.entries(section_groups).map((e, i) => {
      const element = e[1]
      if (element.hidden) return
      return (
        <Button
          key={i}
          minimal
          small
          id={element.id}
          text={__(element.title)}
          active={this.state.currentTypeGroup == element.id}
          onClick={this.onType}
          rightIcon={matrix()[this.state.current_type].sparams.group == element.id ? "dot" : "empty"}
        />
      )
    })
    for (const c in components()) {
      if (matrix()[c].sparams && matrix()[c].sparams.group != this.state.currentTypeGroup) {
        continue
      }
      const cl = c == this.state.current_type ? "active " : " "
      const ccl = c == this.state.type ? " text-danger " : " "
      btns.push(<div
        key={c}
        type={c}
        className={`l-icon ${cl}`}
        onClick={this.onTypeSwitch}
      >
        <div>
          <LayoutIcon
            src={components()[c].icon}
            className="layout-icon"
          />
          <div className={ccl}>
            {__(components()[c].title)}
          </div>
        </div>
      </div>)
    }
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-4">
            <div className="d-flex">
              <div className="landing-type-menu">
                <ButtonGroup className="  text-right" vertical>
                  {tabs}
                </ButtonGroup>
              </div>
              <div className="landing-type-menu-cont">
                {btns}
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  html() {
    // console.log( this.state.lasy_load_behovior );
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-4">
            <Tabs
              onChange={this.onTab}
              animate={false}
              id={`tabs_${this.state.id}`}
              key={this.state.id}
              vertical
              className="tab-params "
            >
              <Tab
                id="style"
                title={(
                  <div className="hint hint--left" data-hint={__("Basic")}>
                    <i className="fas fa-palette fa-2x" />
                    <div>{__("Basic")}</div>
                  </div>
                )}
                panel={(
                  <> 
                    <FieldInput
                      vertical
                      field="class"
                      key="css-class"
                      title="css-class"
                      type="Style"
                      id={this.state.id}
                      on={(value, field) => this.onClassName(value)}
                      onChange={(value, field) => this.onClassName(value)}
                      editable
                      value={this.state.class_name} 
                    />
                    <FieldInput
                      vertical
                      field="style"
                      key="css-style"
                      title="Style CSS"
                      {...{ type: "landing_object", visualization: "landing-object", landing_object: "Style" }}
                      id={this.state.id}
                      {...matrix().Style}
                      on={this.onStyle}
                      onChange={this.onStyle}
                      editable
                      value={this.state.style} 
                    />
                    <FieldInput
                      vertical
                      field="vertical_padding"
                      key="vertical_padding"
                      title="Vertical padding of Section"
                      id={this.state.id}
                      {...matrix().section.vertical_padding}
                      on={this.onVerticalPadding}
                      onChange={this.onVerticalPadding}
                      editable
                      value={this.state.vertical_padding} 
                    />

                    {/* <FieldInput
                      vertical
                      field="lasy_load_behovior"
                      key="lasy_load_behovior"
                      title="Lasy load behovior"
                      {...matrix().section.lasy_load_behovior}
                      id={this.state.id}
                      on={(value, field) => this.onField(value, field, "lasy_load_behovior")}
                      onChange={(value, field) => this.onField(value, field, "lasy_load_behovior")}
                      editable
                      value={this.state.lasy_load_behovior} 
                    /> */}
                    { JSON.stringify(this.state.overflow) } 
                    <FieldInput
                      vertical
                      field="overflow"
                      key="overflow"
                      title="Overflow"
                      {...matrix().section.overflow}
                      id={this.state.id}
                      on={this.onOverflow}
                      onChange={this.onOverflow}
                      editable
                      value={this.state.overflow || "hidden"} 
                    />
                    {
                      this.state.lasy_load_behovior === "configure"
                        ?
                        <>
                          <FieldInput
                            field="lasy_load_type"
                            key="lasy_load_type"
                            title="Lazy load animation type"
                            {...matrix().section.lasy_load_type}
                            id={this.state.id}
                            on={(value, field) => this.onField(value, field, "lasy_load_type")}
                            onChange={(value, field) => this.onField(value, field, "lasy_load_type")}
                            editable
                            value={this.state.lasy_load_type}
                            vertical
                          />
                          <FieldInput
                            field="lasy_load_delay"
                            key="lasy_load_delay"
                            title="Lazy load delay"
                            type="number"
                            {...matrix().section.lasy_load_delay}
                            id={this.state.id}
                            on={(value, field) => this.onField(value, field)}
                            onChange={(value, field) => {                      
                                this.setState({ lasy_load_delay : value === 0 ? "" : value });
                            }}
                            editable
                            value={this.state.lasy_load_delay ? this.state.lasy_load_delay : 0 }
                            vertical
                          /> 
                        </>
                        : 
                        null
                    }
                    
                  </>
                )}
              />

              {/*<Tab
                id="lazy"
                title={(
                  <div className="hint hint--left" data-hint={__("Lazy")}>
                    <i className="fas fa-video fa-2x" />
                    <div>{__("Lazy")}</div>
                  </div>
                )}
                panel={(
                  <>
                    
                  </>
                )}
              /> */}
              
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
  dilimiter() {
    return <div className="" style={{ height: "calc(100% - 45px)" }}> 
      <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
        <div className="p-4">
          <InputForm
            {...this.state}
            vertical
            source="Dilimiter"
            id={this.state.id}
            data={this.state.bottom_dilimiter}
            sourceType="Dilimiter"
            on={(value, field) => this.onField(value, field, "bottom_dilimiter")}
          />
        </div>
      </div>
    </div>
  }

  floats() {
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-4">
            <div className="layout-simple-center p-2">
              <FlaotDettingDialog {...this.state} onUpdate={this.onUpdateFloat} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  menu() {
    const btns = Object.entries(matrix().Menu)
      .map((e, i) => {
        // console.log( e, this.state.menu, e[1].type );
        const val = e[1] ? e[1] : {}
        return e[1].hidden
          ? null
          : (
            <FieldInput
              field={e[0]}
              key={e[0]}
              title={val.title}
              type={val.type}
              id={this.state.ID}
              on={(value, field) => this.onField(value, field, "menu")}
              {...matrix().Menu[e[0]]}
              onChange={(value, field) => this.onField(value, field, "menu")}
              editable
              value={
                this.state.menu
                  ? this.state.menu[e[0]]
                  : e[0]
              }
              values={val.values}
              vertical
            />
          )
      })
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-4">
            {btns}
            <FieldInput
              field={"id"}
              title={"id"}
              type={"string"} 
              on={(value, field) => this.onId(value )} 
              onChange={(value, field) => this.onId(value )}
              editable={false}
              value={ this.state.id } 
              vertical
            />
          </div>
        </div>
      </div>
    )
  }

  composition() {
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-4">
            {
              Object.entries(matrix().Composition)
                .map((compositionField, i) => {
                  const is_demand = compositionField[1].demand
                    ? (
                      Array.isArray(compositionField[1].demand.value)
                        ? 
                        compositionField[1].demand.value
                        : 
                        [compositionField[1].demand.value]
                    )
                      .filter((ee) => {
                        /*
                        console.log(
                          Array.isArray( compositionField[1].demand.value )
                            ?
                            compositionField[1].demand.value
                            :
                            [ compositionField[1].demand.value ],
                          ee,
                          this.state.composition[ compositionField[1].demand.field ],
                          ee == this.state.composition[ compositionField[1].demand.field ]
                        );
                        */
                        return ee === this.state.composition[compositionField[1].demand.field]

                      })
                        .length == 0
                    : 
                    false
                  return compositionField[1].hidden || is_demand
                    ? 
                    null
                    : 
                    <Fragment key={compositionField[0]}> 
                      <FieldInput                        
                        vertical
                        field={compositionField[0]}
                        key={compositionField[0]}
                        title={compositionField[1].title}
                        type={compositionField[1].type}
                        id={this.state.id}
                        on={(value, field) => this.onColumnCount(value, field, "composition")}
                        {...matrix().Composition[compositionField[0]]}
                        onChange={(value, field) => this.onColumnCount(value, field, "composition")}
                        editable
                        origin={ this.state }
                        value={ this.state.composition[ compositionField[ 0 ] ] }
                        values={ compositionField[1].values } 
                      />
                    </Fragment>
                })
            }
          </div>
        </div>
      </div>
    )
  }

  template() {
    const checkeds = Array.isArray(DataContext.data.landing.palette)
      && DataContext.data.landing.palette.length > 0
      ? 
      DataContext.data.landing.palette.map((e, i) => (
        <div key={i}> 
          <PaletteSingleForm
            e={{
              ...e,
              checked: e.id == this.state.current_template_id,
            }}
            i={i}
            key={i}
            isEdit
            onCheck={(evt) => this.onTemplateCheck(evt, e)}
          />
        </div>
      ))
      :      
      <PaletteSingleForm
        e={{ ...PalettePresets()[0], checked: true }}
        i={0}
        isEdit
        onCheck={(evt) => this.onTemplateCheck(evt, PalettePresets()[0])}
      />
        
    return (
      <div className="" style={{ height: "calc(100% - 45px)" }}> 
        <div className=" pb-5 overflow-y-auto " style={{ height: "calc(100% - 20px)" }}> 
          <div className="p-2">
            {checkeds}
          </div>
        </div>
      </div>
    )
  }

  prm() {

  }

  getPalette = () =>
  {
    let palette = PalettePresets().filter(palette => palette.id == this.state.current_template_id)[0]
    if(!palette)
      palette = PalettePresets()[0]
      return palette
  }

  onTemplateCheck = (evt, e) => {
    //console.log(this.state.current_template_id, e.id);
    const newPalette = PalettePresets().filter(palette => palette.id == e.id)[0]
    if (newPalette) {
      this.setState({
        current_template_id: e.id,
        background: {
          ...this.state.background,
          opacity: 1,
          color: newPalette.background_color,
          size: newPalette.background.size,
          image: newPalette.background.tile,
          tile_opacity: newPalette.background.tileOpacity,
          tile_size_px: newPalette.background.tile_size_px,
          repeat: newPalette.background.repeat,
        },

      })
    }
    // console.log(this.state);
  }

  onType = (evt) => {
    const id = evt.currentTarget.getAttribute("id")
    // console.log(id)
    this.setState({ currentTypeGroup: id })
  }

  onStyle = (val) => {
    // console.log( val );
    this.setState({ style: val })
  }
  onVerticalPadding = val =>
  {
    this.setState({ vertical_padding: val })
  }

  onVisible(size, val) {
    const state = { ...this.state }
    state[`visible_${size}`] = val
    // console.log(state);
    this.setState(state)
  }

  onId = (value) => {
    this.setState({ id: value })
  }
  onClassName = (value) => {
    this.setState({ class_name: value })
  }
  onOverflow = (value) => {
    this.setState({ overflow: value })
  }

  onTypeSwitch = (evt) => {
    const current_type = evt.currentTarget.getAttribute("type")
    let data = {}
    if (matrix()[current_type].default) {
      data = {
        data: { ...matrix()[current_type].default },
        composition: matrix()[current_type].default.composition
      }
      delete data.data.hidden
      delete data.data.composition
    }
    // console.log( data, current_type );
    this.setState({ data, current_type, is_change_type_enbl: current_type != this.state.type })
  }

  onClick = () => {
    //console.log(this.state);
    this.props.onChange(this.state.current_type, this.state)
  }

  onClipboardCopy = (evt) => {
    console.log("TypeDialog.onClipboardCopy" )
    if (this.props.onClipboardCopy) this.props.onClipboardCopy(this.state) 
  }

  onClipboardPaste = (evt) => {    
    if (this.props.onClipboardPaste) {
      this.props.onClipboardPaste()
      // console.log("TypeDialog.onClipboardPaste")
    }
  }

  on = (value, field) => {
    // console.log( value,  field );
  }

  onColumnCount = (value, field, block) => {
    //console.log( value, field, block );
    const state = { ...this.state }
    if (!state[block]) state[block] = {}
    state[block][field] = value
    //console.log( state )
    this.setState(state)
  }

  onField = (value, field, block, dopol) => {
    console.log( value, field, block );
    const state = { ...this.state }
    if (!state[block]) state[block] = {}
    state[block][field] = value
    // console.log( state );
    if (this.state.type == "cards") {
      if (field == "fields" && dopol && Array.isArray(dopol)) {
        state.data.cards = dopol
      }
    }
    // console.log( state.data )
    this.setState(state)
  }

  onUpdateFloat = (data, float_id) => {
    this.props.onUpdateFloat(data, float_id)
  }
  onClipboardCopyPRM = ( data ) =>
  {
    $("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='myInput'>${DataContext.getSectionJSON( data )}</textarea></div>`)
		const copyText = document.getElementById("myInput")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#myInput").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Section copy to clipbord"),
		})
  }
  onClipboardPastePRM = data =>
  {
    //console.log("::onClipboardPastePRM")
    //console.log(data)
    //console.log(this.state)
    switch(this.state.type)
    {
      case "cards":
        let state = {
          ...this.state,
          data: {
            ...this.state.data,
            ...data.data,
            data:{
              ...this.state.data.data,
              ...data.data
            }
          }        
        }
        //console.log( state )
        this.setState(state)
        break
    }
  }
}
export default TypeDialog
