import React from "react" 
import axios from "axios"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"

class HTMLSourceVew extends BasicState {
	getRoute = (route) => {
	  axios.get(this.props.html_source)
	    .then((response) => {
	      let text = response.data
	      const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
	      while (SCRIPT_REGEX.test(text)) {
	        text = text.replace(SCRIPT_REGEX, "")
	      }
	      this.setState({ html: <div dangerouslySetInnerHTML={{ __html: text }} /> })
	    })
	  return this.props.route
	}
}
export default HTMLSourceVew
