import React, { Component } from "react"
import ReactDOM from "react-dom"
import { __ } from "react-pe-utilities"
import {LayoutIcon} from 'react-pe-useful'
import { NavLink } from "react-router-dom"
import { profile } from "react-pe-layouts"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router" 
import { isCapability } from "react-pe-utilities"
import { login, template } from "react-pe-layouts"
import { initArea } from  "react-pe-utilities" 

class CurrentUserPanelWidget extends Component 
{
    constructor(props)
    {
        super(props)
        this.state = {
            hover:false,
            press: false ,
            height: 0, 
            current: this.props.current 
        }
    }
    
	componentDidMount() {
        document.body.addEventListener("click", this.onMouseLeaveHandler)
    }
    componentWillUnmount() {
        document.body.removeEventListener("click", this.onMouseLeaveHandler)
    }
    onMouseLeaveHandler = (e) => {
        const domNode = ReactDOM.findDOMNode(this)
        if (!domNode || !domNode.contains(e.target)) {
          this.setState({
            press: false ,
            height:0
          })
        }
      }
    
    unlogined = () =>
    {
        return initArea(
            "unlogin_panel",
            { ...this.props },
            <NavLink 
                className={"layout-left-btn " + (this.state.press ? " active " : "" )}
                    to={login} 
            >
                <div className="layout-menu-icon">
                    <LayoutIcon
                    src={ "fas fa-sign-in-alt" }
                    className="left-menu-icon"
                    />
                </div>
                <div
                    className={`layout-menu-left-label ${this.state.hover ? "hover" : null}`}
                >
                    {__("log in")}
                </div>
            </NavLink>
        )
    }
    logined = () =>
    {
        const { user } = this.props
		const profile_routing = profile()
		let profile_menu
		if (profile_routing.length > 0) 
		{
			profile_menu = profile_routing.map((e, i) => {
				const isRole = isCapability(e.capability, this.props.user)
				if (isRole) return ""
				let children
				if(Array.isArray(e.children) && e.children.length > 0)
				{
					children = <>
						<div className="lmenu-children" >

						</div>
					</>
				}
				else
				{

				}
				return <li className="lmenu" key={i}>
					{children}
					<NavLink
						className=""
						activeClassName="active"
						to={`/${e.route}`} 
					>
						<LayoutIcon
                            isSVG
                            src={e.icon}
                            className="personal-menu__icon mr-3"
						/>
						{ __(e.title) }
					</NavLink>
				</li>
			})
		} 
		else 
		{
			profile_menu = <li className="lmenu">
				<NavLink
					className=""
					activeClassName="active"
					to="/profile"
				>
					<LayoutIcon
                        isSVG
                        src="/assets/img/user.svg"
                        className="personal-menu__icon mr-3"
					/>
					{__("edit profile")}
				</NavLink>
			</li>
		}

        const login_panel = <div className="position-relative">
                <div 
                    className={"layout-left-btn " + (this.state.press ? " active " : "" )} 
                    onMouseDown={() =>this.togglePress(!this.state.press)} 
                >
                    <div className="layout-menu-icon">
                        <LayoutIcon
                            src={"fas fa-user"}
                            className="left-menu-icon"
                        />
                    </div>
                    <div className={`layout-menu-left-label ${this.state.hover ? "hover" : null}`}>
                        {this.props.user.display_name}
                    </div>
                </div> 
                <div className="logined-menu lm-widget" style={{ height: this.state.height }} >
                    <ul id="person_menu_widget">
                        {profile_menu}
                        <li onClick={this.logout} className="lmenu exit">
                            <LayoutIcon
                                isSVG
                                src="/assets/img/logout.svg"
                                className="personal-menu__icon mr-3"
                            />
                            {__("logout")}
                        </li>
                    </ul>
                </div>            
            </div>

        return <>
            {
                initArea(
                    "login_panel",
                    { ...this.props },
                    login_panel,
                )
            }
        </> 
    }
    onToggle = (evt) => {
        // console.log( document.getElementById("person_menu").clientHeight );
        this.setState({
            
        })
    }

    logout = () => { 
        localStorage.removeItem("token")
        this.props.refetchUser()
    }



    render() 
    {
        //console.log( this.props )
        return this.props.user?.id > 0
            ?
            this.logined()
            :
            this.unlogined()
    }
    toggleHover() {
        this.setState({ hover: !this.state.hover })
    }
    togglePress = bool =>
    {
        this.setState({ 
            press: bool,
            height: bool ? document.getElementById("person_menu_widget").clientHeight : 0, 
        })
    }
}
export default compose(
    withApollo,
    withRouter,
  )( CurrentUserPanelWidget )