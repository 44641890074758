import React from "react" 
import Style from "style-it"
import { Position, Button, ButtonGroup } from "@blueprintjs/core"
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2" 
import "./assets/css/style.css" 
import { Link } from "react-router-dom" 
import {right_routing} from "react-pe-layouts"
import $ from "jquery"
import { compose} from "recompose"
import { withRouter } from "react-router"
import SectionContent from "modules/pe-landing-module/views/LandingState/SectionContent"
import { getColor, getRevertColor } from "modules/pe-landing-module/views/LandingState/data/getColor"
import { getFontNameByID } from "modules/pe-landing-module/views/LandingState/data/PalettePresets"

class KraevedTitle2 extends SectionContent
{
    is() {
        return this.props.data.number
    }
    getState() {
        return {
            isMobyle: this.props.section_width < 720,
            fullWidth : 1270
        }
    }
    componentDidMount() {
		window.addEventListener("resize", this.updateWindowDimensions)
        setTimeout(() => this.updateWindowDimensions(), 200)
        // console.log( this.props.composition.is_blocked, $(`#section-section-${this.props.id} .container`).css("max-width") )
        
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }
    updateWindowDimensions = () => {
        let w = document.body.clientWidth
        switch(this.props.composition.is_blocked )
        {
            case 1:
            case "1":
            case true:
                w = parseInt( $(`[data-id="${this.props.id}"] .container`).css("max-width"), 10 ) - 30
                break;
            case 2:
            case "2":
                w = parseInt( $(`[data-id="${this.props.id}"] .short-container`).css("max-width"), 10 ) 
                break;
            default:
                w = document.body.clientWidth

        }
        this.setState({ 
            isMobyle: document.body.clientWidth < 720,  
            fullWidth: w
        })
    }
    updateWidth(width) {
        if ((width < 720) !== this.state.isMobyle)
            this.setState({ isMobyle: width < 720 })
    }
    onTopos = () => 
    {
        const url = "https://vk.com/mytopos"
        window.open(url, '_blank')
    }
    onFDUTIK = () => 
    {
        const url = "https://vk.com/federal_centre"
        window.open(url, '_blank')
    }
    renderContent(style) {
        return Style.it(
            `
            .kraeved-button > a
            {
                color: ${ getRevertColor( this.props.palette?.background_color, this.props.palette ) };
                opacity: 0.9;
            }
            .kraeved-button > a:hover
            {
                opacity: 1;
            }
            `,
            this.state.isMobyle
                ?
                this.renderMobyle(style)
                :
                this.renderScreen(style)
        ) 
    }
    renderScreen(style) {
        const { palette } = this.props
        const { year, number, color1, color2, color3, color4, title, description, image, class_name } = this.props.data
        const clr1 = getColor(color1, palette) || "#416C57"
        const clr2 = getColor(color2, palette) || "#62997E"
        const clr3 = getColor(color3, palette) || "#E3E032"
        const clr4 = getColor(color4, palette) || "#4B485A" 

        return (
            <div
                className={`landing-kraeved-title2 ${class_name ? class_name : ""}`}
                style={{
                    color: palette.main_text_color,
                    ...style
                }}
            >
                <div
                    className="landing-kraeved-img"
                    style={{
                        backgroundImage: `url(${image})`,
                        top: 19,
                        left: 660,
                        height: 65,
                        width: "calc(100% - 720px)",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                />

                <svg
                    version="1.1"
                    id="Слой_1"
                    x="0px"
                    y="0px"
                    width={this.state.fullWidth}
                    height="120"
                    viewBox={`0 0 ${ this.state.fullWidth } 120`}
                    style={{ position: "absolute", zIndex: 3 }}
                > 
                    <g id="Слой_1">
                        <g id="light">
                        </g>
                        <g id="color2">
                            <rect x="474" y="18.3"  fill={ clr1 }  width="182.5" height="66.2"/>
                            <path  fill={ clr1 }  d="M403.8,18.7h9.4v4l-3.7,11l4.4,12.8v4H404l-2.6-12.3h-1.7v12.3h-9.9V18.9h9.9v11.6h1.8L403.8,18.7z"/>
                        </g>
                        <g id="color1">
                            <path  fill={ clr2 }  d="M222.3,50.4h-9.9V18.7h21.2v4l-1.5,4h-9.8L222.3,50.4L222.3,50.4z"/>
                            <path  fill={ clr2 }  d="M239.8,50.4c-2.3,0-4-1.7-4-4V22.6c0-2.3,1.7-4,4-4h15.8c2.3,0,4,1.7,4,4v23.8c0,2.3-1.7,4-4,4H239.8z
                                M245.7,26.6v15.8h4V26.6H245.7z"/>
                            <path  fill={ clr2 }  d="M263.2,50.4V18.7h19.8c2.3,0,4,1.7,4,4V30c0,2.3-1.9,3.9-4.1,3.9v0.8c2.2,0,4.1,1.5,4.1,3.9v8
                                c0,2.3-1.7,4-4,4h-19.8V50.4z M277.2,26.6h-4v3.6h4V26.6z M273.2,42.4h4V38h-4V42.4z"/>
                            <path  fill={ clr2 }  d="M294.2,50.4c-2.3,0-4-1.7-4-4V22.6c0-2.3,1.7-4,4-4h15.8c2.3,0,4,1.7,4,4v23.8c0,2.3-1.7,4-4,4H294.2z
                                M300.2,26.6v15.8h4V26.6H300.2z"/>
                            <path  fill={ clr2 }  d="M327.6,40.5v9.9h-9.9V18.7h19.8c2.3,0,4,1.7,4,4v13.9c0,2.3-1.7,4-4,4h-9.9V40.5z M327.6,32.5h4v-5.9h-4V32.5
                                z"/>
                            <path  fill={ clr2 }  d="M358.1,30.2v-7.6c0-2.3,1.7-4,4-4h15.4c2.3,0,4,1.7,4,4v23.7c0,2.3-1.7,4-4,4h-15.4c-2.3,0-4-1.7-4-4V38h-3.6
                                v12.3h-9.9V18.6h9.9v11.6H358.1z M368,26.6v15.8h3.6V26.6H368z"/>
                            <path  fill={ clr1 }  d="M436.8,18.7l4.4,27.8v4h-9.9l-0.6-6.3h-4.4l-0.7,6.3h-9.9v-4l4.4-27.8C420.1,18.7,436.8,18.7,436.8,18.7z
                                M429.9,36.1l-1-9.4h-1.1l-1,9.4H429.9z"/>
                            <path  fill={ clr1 }  d="M458,18.7h9.4v4l-3.7,11l4.4,12.8v4h-9.9l-2.6-12.3h-1.8v12.3h-9.9V18.9h9.9v11.6h1.8L458,18.7z"/>
                        </g>
                        <rect x={ this.state.fullWidth - 54 } y="18.2"  fill={ clr2 }  width="44.7" height="44.7"/>
                        <rect x="478" y="87.5"  fill={ clr2 }  width={this.state.fullWidth - 483} height="11.3"/>
                        <rect 
                            id="topos_btn" 
                            x="5.7" 
                            y="18.7"  
                            fill={ clr1 }  
                            width="96.1" 
                            height="96.1" 
                            onClick={ this.onTopos } 
                            className="svgBtn"
                        />
                        <rect 
                            x="109" 
                            y="18.7"  
                            fill={ clr2 }  
                            width="96.1" 
                            height="96.1"
                            onClick={ this.onFDUTIK }
                            className="svgBtn"
                        />
                        <g>
                            <rect x="5.7" y="5.1"  fill={ clr4 }  width={ this.state.fullWidth - 10} height="10.3"/>
                        </g>
                        <g>
                            <rect x="433.2" y="87.5"  fill={ clr4 }  width="223.3" height="11.3"/>
                        </g>
                        <path  fill={ clr4 }  d="M232.4,53.6h13.4v5.7l-5.2,15.6l6.3,18.3v5.7h-14.1l-3.9-17.5h-2.5v17.5h-14.2V53.6h14.2V70h2.5L232.4,53.6z"
                            />
                        <path  fill={ clr4 }  d="M264.8,84.8v14.1h-14.2V53.6h28.3c3.3,0,5.7,2.3,5.7,5.7v19.8c0,3.3-2.3,5.7-5.7,5.7H264.8z M264.8,73.5h5.7
                            v-8.4h-5.7V73.5z"/>
                        <path  fill={ clr4 }  d="M315.7,53.6l6.3,39.6v5.7h-14.2l-0.8-9.1h-6.2l-1,9.1h-14.1v-5.7l6.3-39.6C292.2,53.6,315.7,53.6,315.7,53.6z
                            M306,78.4l-1.2-13.5h-1.5L302,78.4H306z"/>
                        <path  fill={ clr4 }  d="M325.9,98.8V53.6h30.2v5.7l-2.2,5.8H340v5.1h13.9v11.2H340v6.2h16.1v11.3H325.9z"/>
                        <path  fill={ clr4 }  d="M360,98.8V53.6h28.3c3.3,0,5.7,2.3,5.7,5.7v10.3c0,3.3-2.8,5.4-5.8,5.4v1.2c3,0,5.8,2.1,5.8,5.5v11.4
                            c0,3.3-2.3,5.7-5.7,5.7C388.2,98.8,360,98.8,360,98.8z M379.8,64.9h-5.7V70h5.7V64.9z M374.2,87.5h5.7v-6.2h-5.7V87.5z"/>
                        <path  fill={ clr4 }  d="M398.4,98.8V53.6h30.2v5.7l-2.2,5.8h-13.9v5.1h13.9v11.2h-13.9v6.2h16.1v11.3H398.4z"/>
                        <path  fill={ clr4 }  d="M430.7,87.5h4.1l6.9-33.9h27.2v33.9h3.7v11.3h-42V87.5H430.7z M454.7,64.9h-2.1l-3.3,22.6h5.5V64.9H454.7z"/>
                        <g>
                            <path  fill={ clr3 }  d="M485.8,39.4v-9.9h7.4v9.9h-3.2V32h-1.2v7.4C488.9,39.4,485.8,39.4,485.8,39.4z"/>
                            <path  fill="#D2E6F8"  d="M498.9,36.2v3.2h-3.2v-9.9h6.2c0.7,0,1.2,0.6,1.2,1.2v4.4c0,0.7-0.6,1.2-1.2,1.2h-3V36.2z M498.9,33.8h1.2
                                v-1.9h-1.2V33.8z"/>
                            <path  fill="#D2E6F8"  d="M506.9,39.4c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.4
                                c0,0.7-0.6,1.2-1.2,1.2H506.9z M508.8,31.8v5h1.2v-5H508.8z"/>
                            <path  fill="#D2E6F8"  d="M515.7,39.4v-9.9h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5C522.3,39.4,515.7,39.4,515.7,39.4z"/>
                            <path  fill="#D2E6F8"  d="M529.1,29.4h2.9v1.2l-1.1,3.4l1.4,4v1.2h-3l-0.8-3.9h-0.6v3.9h-3.2v-9.9h3.2v3.7h0.6L529.1,29.4z"/>
                            <path  fill="#D2E6F8"  d="M534.1,31.8v-2.5h7v1.2l-0.6,1.2H539v7.4h-3.2v-7.4H534.1z"/>
                            <path  fill="#D2E6F8"  d="M547.6,33.1v-3.6h3.2v9.9h-3.2v-3.9h-1.2v3.9h-3.2v-9.9h3.2v3.6H547.6z"/>
                            <path  fill="#D2E6F8"  d="M554.5,39.4c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.4
                                c0,0.7-0.6,1.2-1.2,1.2H554.5z M556.4,31.8v5h1.2v-5H556.4z"/>
                            <path  fill="#D2E6F8"  d="M570,33.4v2.1h-7v-2.1H570z"/>
                            <path  fill={ clr3 }  d="M490.5,41.8h3.2v9.9h-3.2v-1.2l0.4-3.2h-0.3l-1.5,3.4v1H486v-9.9h3.2V43l-0.4,3.2h0.3l1.5-3.4V41.8z"/>
                            <path  fill="#D2E6F8"  d="M502.9,41.8V43l-0.6,1.2h-3v5h3.6v2.5h-5.4c-0.7,0-1.2-0.6-1.2-1.2V43c0-0.7,0.6-1.2,1.2-1.2
                                C497.5,41.8,502.9,41.8,502.9,41.8z"/>
                            <path  fill="#D2E6F8"  d="M511.7,41.8V43l-0.6,1.2h-3v5h3.6v2.5h-5.4c-0.7,0-1.2-0.6-1.2-1.2V43c0-0.7,0.6-1.2,1.2-1.2
                                C506.4,41.8,511.7,41.8,511.7,41.8z"/>
                            <path  fill="#D2E6F8"  d="M518.3,51.8v-7.4h-0.4l-1.1,7.4h-3.2v-1.2l1.9-8.7h5.9v9.9H518.3z"/>
                            <path  fill="#D2E6F8"  d="M524,51.8v-9.9h6.6v1.2l-0.6,1.2h-3v1.1h3V48h-3v1.4h3.6v2.5C530.6,51.8,524,51.8,524,51.8z"/>
                            <path  fill="#D2E6F8"  d="M532.5,49.2h1l1.5-7.4h5.9v7.4h0.8v2.5h-9.2V49.2z M537.9,44.2h-0.4l-0.8,5h1.2V44.2z"/>
                            <path  fill="#D2E6F8"  d="M545.2,51.8c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.4
                                c0,0.7-0.6,1.2-1.2,1.2H545.2z M547,44.2v5h1.2v-5H547z"/>
                            <path  fill="#D2E6F8"  d="M554,51.8v-9.9h6.2c0.7,0,1.2,0.6,1.2,1.2v2.3c0,0.7-0.6,1.2-1.2,1.2V47c0.7,0,1.2,0.4,1.2,1.2v2.5
                                c0,0.7-0.6,1.2-1.2,1.2H554V51.8z M558.3,44.2h-1.2v1.1h1.2V44.2z M557.1,49.2h1.2v-1.4h-1.2V49.2z"/>
                            <path  fill="#D2E6F8"  d="M570.2,41.8l1.4,8.7v1.2h-3.2l-0.1-2.1h-1.4l-0.1,2.1h-3.2v-1.2l1.4-8.7H570.2z M568,47.3l-0.3-2.9h-0.3
                                l-0.3,2.9H568z"/>
                            <path  fill="#D2E6F8"  d="M572.8,44.2v-2.5h7V43l-0.6,1.2h-1.5v7.4h-3v-7.4H572.8z"/>
                            <path  fill="#D2E6F8"  d="M581.9,51.8v-9.9h6.6v1.2l-0.6,1.2h-3v1.1h3V48h-3v1.4h3.6v2.5C588.5,51.8,581.9,51.8,581.9,51.8z"/>
                            <path  fill="#D2E6F8"  d="M595.3,51.8v-7.4h-0.4l-1.1,7.4h-3.2v-1.2l1.9-8.7h5.9v9.9H595.3z"/>
                            <path  fill="#D2E6F8"  d="M608.3,46.2v4.4c0,0.7-0.6,1.2-1.2,1.2h-6.2v-9.9h3.2v3.2h3.2C607.8,44.9,608.3,45.5,608.3,46.2z M605.2,49.2
                                v-1.9h-1.2v1.9H605.2z"/>
                            <path  fill="#D2E6F8"  d="M617.4,41.8V43l-0.6,1.2h-3v5h3.6v2.5h-5.4c-0.7,0-1.2-0.6-1.2-1.2V43c0-0.7,0.6-1.2,1.2-1.2
                                C612.1,41.8,617.4,41.8,617.4,41.8z"/>
                            <path  fill="#D2E6F8"  d="M624.2,41.8h2.9V43l-1.1,3.4l1.4,4v1.2h-3.2l-0.8-3.9h-0.6v3.9h-3.2v-9.9h3.2v3.6h0.6L624.2,41.8z"/>
                            <path  fill="#D2E6F8"  d="M635.9,41.8l1.4,8.7v1.2h-3.2l-0.1-2.1h-1.4l-0.1,2.1h-3.2v-1.2l1.4-8.7H635.9z M633.7,47.3l-0.3-2.9h-0.3
                                l-0.3,2.9H633.7z"/>
                            <path  fill="#D2E6F8"  d="M646.9,51.8h-3.2v-3.2h-0.7l-0.8,3.2h-3v-1.2l1-2.5c-0.4-0.1-0.8-0.7-0.8-1.1v-3.9c0-0.7,0.6-1.2,1.2-1.2h6.2
                                v9.9H646.9z M642.5,46.2h1.2v-1.9h-1.2V46.2z"/>
                            <path  fill={ clr3 }  d="M485.5,64.2v-9.9h7.4v9.9h-3.2v-7.4h-1.2v7.4H485.5z"/>
                            <path  fill="#D2E6F8"  d="M495.6,64.2v-9.9h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5H495.6z"/>
                            <path  fill="#D2E6F8"  d="M504,61.7h1l1.5-7.4h5.9v7.4h0.8v2.5H504V61.7z M509.4,56.8H509l-0.8,5h1.2V56.8z"/>
                            <path  fill="#D2E6F8"  d="M521.8,54.3l1.4,8.7v1.2H520l-0.1-2.1h-1.4l-0.1,2.1h-3.2V63l1.4-8.7H521.8z M519.6,59.8l-0.3-2.9H519
                                l-0.3,2.9H519.6z"/>
                            <path  fill="#D2E6F8"  d="M528.5,64.2h-3.2v-9.9h6.6v1.2l-0.6,1.2h-3v7.4H528.5z"/>
                            <path  fill="#D2E6F8"  d="M535.4,64.2c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2V63
                                c0,0.7-0.6,1.2-1.2,1.2H535.4z M537.4,56.8v5h1.2v-5H537.4z"/>
                            <path  fill="#D2E6F8"  d="M547.4,64.2h-3.2v-9.9h6.6v1.2l-0.6,1.2h-3v7.4H547.4z"/>
                            <path  fill="#D2E6F8"  d="M557.5,54.3h3.2v9.9h-3.2V63l0.4-3.2h-0.3l-1.5,3.4v1h-3.2v-9.9h3.2v1.2l-0.4,3.2h0.3l1.5-3.4V54.3z"/>
                            <path  fill="#D2E6F8"  d="M563,59.7v-5.4h3.2v4.1h1.2v-4.1h3.2v9.9h-3.2v-3.3h-3.2C563.4,60.9,563,60.4,563,59.7z"/>
                            <path  fill="#D2E6F8"  d="M572.9,64.2v-9.9h6.6v1.2l-0.6,1.2h-2.9v1.1h3v2.5h-3v1.4h3.6v2.5H572.9z"/>
                            <path  fill="#D2E6F8"  d="M588.6,54.3v1.2l-0.6,1.2h-3v5h3.6v2.5h-5.4c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2
                                C583.3,54.3,588.6,54.3,588.6,54.3z"/>
                            <path  fill="#D2E6F8"  d="M595.3,54.3h2.9v1.2L597,59l1.4,4v1.2h-3l-0.8-3.9H594v3.9h-3.2v-9.9h3.2v3.6h0.6L595.3,54.3z"/>
                            <path  fill="#D2E6F8"  d="M607,54.3l1.4,8.7v1.2h-3.2l-0.1-2.1h-1.4l-0.1,2.1h-3.2V63l1.4-8.7H607z M604.9,59.8l-0.3-2.9h-0.3l-0.3,2.9
                                H604.9z"/>
                            <path  fill="#D2E6F8"  d="M618,64.2h-3.2v-3.2h-0.7l-0.8,3.2h-3V63l1-2.5c-0.4-0.1-0.8-0.7-0.8-1.1v-3.9c0-0.7,0.6-1.2,1.2-1.2h6.2v9.9
                                H618z M613.7,58.7h1.2v-1.9h-1.2V58.7z"/>
                            <path  fill={ clr3 }  d="M489.5,77.2v-7.4h-0.4l-1.2,7.4h-3.2v-1.2l1.9-8.7h5.9v9.9H489.5z"/>
                            <path  fill="#D2E6F8"  d="M501.5,67.3l1.4,8.7v1.2h-3.2l-0.1-2.1h-1.4l-0.1,2.1h-3.2v-1.2l1.4-8.7H501.5z M499.3,72.8l-0.3-2.9h-0.3
                                l-0.3,2.9H499.3z"/>
                            <path  fill="#D2E6F8"  d="M512.6,72.1v3.9c0,0.7-0.6,1.2-1.2,1.2h-6.2v-9.9h7.4v1.2l-0.6,1.2h-3.9v1.1h3.2
                                C512.1,70.8,512.6,71.4,512.6,72.1z M509.5,74.7v-1.4h-1.2v1.4H509.5z"/>
                            <path  fill="#D2E6F8"  d="M516.3,77.2c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.4
                                c0,0.7-0.6,1.2-1.2,1.2H516.3z M518.2,69.7v5h1.2v-5H518.2z"/>
                            <path  fill="#D2E6F8"  d="M528.1,74.1v3h-3v-9.9h6.2c0.7,0,1.2,0.6,1.2,1.2v4.4c0,0.7-0.6,1.2-1.2,1.2
                                C531.3,74.1,528.1,74.1,528.1,74.1z M528.1,71.7h1.2v-1.9h-1.2V71.7z"/>
                            <path  fill="#D2E6F8"  d="M540.8,67.3l1.4,8.7v1.2H539l-0.1-2.1h-1.4l-0.1,2.1h-3.2v-1.2l1.4-8.7H540.8z M538.7,72.8l-0.3-2.9h-0.3
                                l-0.3,2.9H538.7z"/>
                            <path  fill="#D2E6F8"  d="M543.6,69.7v-2.5h7v1.2l-0.6,1.2h-1.5v7.4h-3.2v-7.4H543.6z"/>
                            <path  fill="#D2E6F8"  d="M553.9,77.2c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.4
                                c0,0.7-0.6,1.2-1.2,1.2H553.9z M555.7,69.7v5h1.2v-5H555.7z"/>
                            <path  fill="#D2E6F8"  d="M565.8,74.1v3h-3.2v-9.9h6.2c0.7,0,1.2,0.6,1.2,1.2v4.4c0,0.7-0.6,1.2-1.2,1.2
                                C568.8,74.1,565.8,74.1,565.8,74.1z M565.8,71.7h1.2v-1.9h-1.2V71.7z"/>
                            <path  fill="#D2E6F8"  d="M576.9,67.3h3.2v9.9h-3.2v-1.2l0.4-3.2h-0.3l-1.5,3.4v1h-3.2v-9.9h3.2v1.2l-0.4,3.2h0.3l1.5-3.4V67.3z"/>
                            <path  fill="#D2E6F8"  d="M589.9,77.2h-3.2V74H586l-0.8,3.2h-3v-1.2l1-2.5c-0.4-0.1-0.8-0.7-0.8-1.1v-3.9c0-0.7,0.6-1.2,1.2-1.2h6.2
                                v9.9H589.9z M585.6,71.7h1.2v-1.9h-1.2V71.7z"/>
                        </g> 
                        <g>
                            <g transform={`translate(${ this.state.fullWidth - 1015 }, 0)`} id="year">
                                <path fill={ clr4 }  d="M964.8,78.6v0.8h5.7V83h-10.1v-4.8c0-1.1,0.1-1.8,1.2-2.5l4.4-2.8v-0.7h-5l-0.7-1.8v-1.8h8.4
                                    c1.1,0,1.8,0.7,1.8,1.8v3.3c0,1.1-0.1,1.8-1.2,2.5L964.8,78.6z"/>
                                <path fill={ clr4 }  d="M973.5,82.8c-1.1,0-1.8-0.7-1.8-1.8V70.3c0-1.1,0.7-1.8,1.8-1.8h6.5c1.1,0,1.8,0.7,1.8,1.8V81
                                    c0,1.1-0.7,1.8-1.8,1.8H973.5z M976.1,72.2v7.2h1.1v-7.2H976.1z"/>
                                <path fill={ clr4 }  d="M987.6,78.6v0.8h5.7V83h-10.1v-4.8c0-1.1,0.1-1.8,1.2-2.5l4.4-2.8v-0.7h-5l-0.7-1.8v-1.8h8.4
                                    c1.1,0,1.8,0.7,1.8,1.8v3.3c0,1.1-0.1,1.8-1.2,2.5L987.6,78.6z"/>
                                <path fill={ clr4 }  d="M998.5,78.6v0.8h5.7V83H994v-4.8c0-1.1,0.1-1.8,1.2-2.5l4.4-2.8v-0.7h-5l-0.7-1.8v-1.8h8.4
                                    c1.1,0,1.8,0.7,1.8,1.8v3.3c0,1.1-0.1,1.8-1.2,2.5L998.5,78.6z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path  fill={ clr1 }  d="M212.9,112.7V103h6.2c0.7,0,1.2,0.6,1.2,1.2v2.2c0,0.7-0.6,1.2-1.2,1.2v0.3c0.7,0,1.2,0.4,1.2,1.2v2.5
                                    c0,0.7-0.6,1.2-1.2,1.2h-6.2V112.7z M217.2,105.4H216v1.1h1.2V105.4z M216.1,110.4h1.2V109h-1.2V110.4z"/>
                                <path  fill={ clr1 }  d="M226.9,103h3v9.8h-3v-1.1l0.4-3.2H227l-1.5,3.4v1h-3v-9.8h3v1.2l-0.4,3.2h0.3l1.5-3.4V103z"/>
                                <path  fill={ clr1 }  d="M231.8,110.4h1l1.5-7.4h5.9v7.4h0.8v2.5H232v-2.5H231.8z M237.1,105.4h-0.4l-0.8,5h1.2L237.1,105.4
                                    L237.1,105.4z"/>
                                <path  fill={ clr1 }  d="M243,112.7V103h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5H243V112.7z"/>
                                <path  fill={ clr1 }  d="M252.9,112.7c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.6
                                    c0,0.7-0.6,1.2-1.2,1.2h-5V112.7z M254.7,105.4v5h1.2v-5H254.7z"/>
                                <path  fill={ clr1 }  d="M269.6,103h2.9v1.2l-1.1,3.4l1.4,4v1.2h-3l-0.8-3.9h-0.4v3.9h-3V109H265l-0.8,3.9h-3v-1.2l1.4-4l-1.1-3.4
                                    V103h2.9l0.7,3.6h0.4V103h3v3.6h0.4L269.6,103z"/>
                                <path  fill={ clr1 }  d="M276.6,112.7v-1.1l0.4-1.1l-2.5-6.3V103h2.9l0.8,3.9h0.3l0.6-3.9h2.9v1.2l-2.6,8.7h-2.8V112.7z"/>
                                <path  fill={ clr1 }  d="M286.9,109.7v3h-3V103h6.2c0.7,0,1.2,0.6,1.2,1.2v4.3c0,0.7-0.6,1.2-1.2,1.2H286.9z M286.9,107.2h1.2v-1.8
                                    h-1.2V107.2z"/>
                                <path  fill={ clr1 }  d="M297.8,106.5V103h3v9.8h-3v-3.9h-1.2v3.9h-3V103h3v3.6H297.8z"/>
                                <path  fill={ clr1 }  d="M309.4,103l1.4,8.7v1.2h-3l-0.1-1.9h-1.4l-0.1,1.9h-3v-1.2l1.4-8.7H309.4z M307.3,108.3l-0.3-2.9h-0.3
                                    l-0.3,2.9H307.3z"/>
                                <path  fill={ clr1 }  d="M317.1,112.7v-7.4h-0.4l-1.1,7.4h-3v-1.1l1.8-8.7h5.9v9.8C320.3,112.7,317.1,112.7,317.1,112.7z"/>
                                <path  fill={ clr1 }  d="M330.4,110.4h1l1.5-7.4h5.9v7.4h0.8v2.5h-9.1v-2.5H330.4z M335.6,105.4h-0.4l-0.8,5h1.2L335.6,105.4
                                    L335.6,105.4z"/>
                                <path  fill={ clr1 }  d="M345.7,112.7v-7.4h-0.4l-1.1,7.4h-3v-1.1l1.8-8.7h5.9v9.8C348.8,112.7,345.7,112.7,345.7,112.7z"/>
                                <path  fill={ clr1 }  d="M358.3,112.7h-3v-3h-0.6l-0.8,3h-3v-1.1l1-2.5c-0.4-0.1-0.8-0.7-0.8-1.1v-3.9c0-0.7,0.6-1.2,1.2-1.2h6.2v9.8
                                    H358.3z M354.1,107.2h1.2v-1.8h-1.2V107.2z"/>
                                <path  fill={ clr1 }  d="M368.8,112.7V103h6.2c0.7,0,1.2,0.6,1.2,1.2v2.2c0,0.7-0.6,1.2-1.2,1.2v0.3c0.7,0,1.2,0.4,1.2,1.2v2.5
                                    c0,0.7-0.6,1.2-1.2,1.2h-6.2V112.7z M373.1,105.4h-1.2v1.1h1.2V105.4z M371.8,110.4h1.2V109h-1.2V110.4z"/>
                                <path  fill={ clr1 }  d="M384.9,103v1.2l-0.4,1.2h-3v5h3.4v2.5h-5.4c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2H384.9z"/>
                                <path  fill={ clr1 }  d="M387.1,112.7V103h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5h-6.6V112.7z"/>
                                <path  fill={ clr1 }  d="M400.1,103h2.9v1.4l-1.1,3.3l1.4,4v1.1h-3l-0.7-3.9h-0.3l-0.7,3.9h-3v-1.1l1.4-4l-1.1-3.3V103h2.9l0.7,3.6
                                    h0.3L400.1,103z"/>
                                <path  fill={ clr1 }  d="M407.3,113.8h-1.1v-1.1h-1v-2.5h2.8v2.1L407.3,113.8z"/>
                                <path  fill={ clr1 }  d="M422.7,103h2.9v1.2l-1.1,3.4l1.4,4v1.2h-3L422,109h-0.6v3.9h-3v-9.8h3v3.6h0.6L422.7,103z"/>
                                <path  fill={ clr1 }  d="M427.5,105.4V103h6.9v1.2l-0.6,1.2h-1.5v7.3h-3v-7.3H427.5z"/>
                                <path  fill={ clr1 }  d="M437.6,112.7c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.6
                                    c0,0.7-0.6,1.2-1.2,1.2h-5V112.7z M439.5,105.4v5h1.2v-5H439.5z"/>
                                <path  fill={ clr1 }  d="M458.5,106.5V103h3v9.8h-3v-3.9h-1.2v3.9h-3V103h3v3.6H458.5z"/>
                                <path  fill={ clr1 }  d="M464,112.7V103h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5H464V112.7z"/>
                                <path  fill={ clr1 }  d="M475.8,109.7v3h-3V103h6.2c0.7,0,1.2,0.6,1.2,1.2v4.3c0,0.7-0.6,1.2-1.2,1.2H475.8z M475.8,107.2h1.2v-1.8
                                    h-1.2V107.2z"/>
                                <path  fill={ clr1 }  d="M488,103l1.4,8.7v1.2h-3l-0.1-1.9h-1.4l-0.1,1.9h-3v-1.2l1.4-8.7H488z M486,108.3l-0.3-2.9h-0.3l-0.3,2.9
                                    H486z"/>
                                <path  fill={ clr1 }  d="M491.5,112.7V103h6.2c0.7,0,1.2,0.6,1.2,1.2v2.2c0,0.7-0.6,1.2-1.2,1.2v0.3c0.7,0,1.2,0.4,1.2,1.2v2.5
                                    c0,0.7-0.6,1.2-1.2,1.2h-6.2V112.7z M495.9,105.4h-1.2v1.1h1.2V105.4z M494.6,110.4h1.2V109h-1.2V110.4z"/>
                                <path  fill={ clr1 }  d="M505.4,106.5V103h3v9.8h-3v-3.9h-1.2v3.9h-3V103h3v3.6H505.4z"/>
                                <path  fill={ clr1 }  d="M512.1,112.7c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.6
                                    c0,0.7-0.6,1.2-1.2,1.2h-5V112.7z M513.9,105.4v5h1.2v-5H513.9z"/>
                                <path  fill={ clr1 }  d="M520.1,110.4h1l1.5-7.4h5.9v7.4h0.8v2.5h-9.1v-2.5H520.1z M525.4,105.4H525l-0.8,5h1.2V105.4z"/>
                                <path  fill={ clr1 }  d="M532.7,112.7v-1.1l0.4-1.1l-2.5-6.3V103h2.9l0.8,3.9h0.3l0.6-3.9h2.9v1.2l-2.6,8.7h-2.8V112.7z"/>
                                <path  fill={ clr1 }  d="M543,103v7.4h1.1V103h3v7.4h1.1V103h3v9.8h-11.4V103H543z"/>
                                <path  fill={ clr1 }  d="M553.8,112.7V103h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5h-6.6V112.7z"/>
                                <path  fill={ clr1 }  d="M566.7,106.5V103h3v9.8h-3v-3.9h-1.2v3.9h-3V103h3v3.6H566.7z"/>
                                <path  fill={ clr1 }  d="M584.6,103h2.9v1.2l-1.1,3.4l1.4,4v1.2h-3L584,109h-0.6v3.9h-3v-9.8h3v3.6h0.6L584.6,103z"/>
                                <path  fill={ clr1 }  d="M604.6,103v1.2l-0.4,1.2h-3v5h3.4v2.5h-5.4c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2H604.6z"/>
                                <path  fill={ clr1 }  d="M606.7,112.7V103h6.2c0.7,0,1.2,0.6,1.2,1.2v2.2c0,0.7-0.6,1.2-1.2,1.2v0.3c0.7,0,1.2,0.4,1.2,1.2v2.5
                                    c0,0.7-0.6,1.2-1.2,1.2h-6.2V112.7z M611,105.4h-1.2v1.1h1.2V105.4z M609.7,110.4h1.2V109h-1.2V110.4z"/>
                                <path  fill={ clr1 }  d="M617.6,112.7c-0.7,0-1.2-0.6-1.2-1.2v-7.4c0-0.7,0.6-1.2,1.2-1.2h5c0.7,0,1.2,0.6,1.2,1.2v7.6
                                    c0,0.7-0.6,1.2-1.2,1.2h-5V112.7z M619.4,105.4v5h1.2v-5H619.4z"/>
                                <path  fill={ clr1 }  d="M626,112.7V103h6.6v1.2l-0.6,1.2h-3v1.1h3v2.5h-3v1.4h3.6v2.5H626V112.7z"/>
                                <path  fill={ clr1 }  d="M637.7,108.1h-0.3v4.7h-3v-1.1l1-8.7h3.4l0.7,3.9h0.3l0.7-3.9h3.4l1.1,8.7v1.2h-3v-4.7h-0.3l-0.8,4.7h-2.6
                                    L637.7,108.1z"/>
                                <path  fill={ clr1 }  d="M648.3,112.7v-1.1l0.4-1.1l-2.5-6.3V103h2.9l0.8,3.9h0.3l0.6-3.9h2.9v1.2l-2.6,8.7h-2.8V112.7z"/>
                                <path  fill={ clr1 }  d="M668,103h2.9v1.2l-1.1,3.4l1.4,4v1.2h-3l-0.8-3.9h-0.6v3.9h-3v-9.8h3v3.6h0.6L668,103z"/>
                                <path  fill={ clr1 }  d="M676.4,109.7v3h-3V103h6.2c0.7,0,1.2,0.6,1.2,1.2v4.3c0,0.7-0.6,1.2-1.2,1.2H676.4z M676.4,107.2h1.2v-1.8
                                    h-1.2V107.2z"/>
                                <path  fill={ clr1 }  d="M688.7,103l1.4,8.7v1.2h-3l-0.1-1.9h-1.4l-0.1,1.9h-3.2v-1.2l1.4-8.7H688.7z M686.6,108.3l-0.3-2.9h-0.3
                                    l-0.3,2.9H686.6z"/>
                                <path  fill={ clr1 }  d="M696.3,106.5v-2.3c0-0.7,0.6-1.2,1.2-1.2h4.8c0.7,0,1.2,0.6,1.2,1.2v7.3c0,0.7-0.6,1.2-1.2,1.2h-4.8
                                    c-0.7,0-1.2-0.6-1.2-1.2v-2.6h-1.1v3.9h-3V103h3v3.6C695.2,106.5,696.3,106.5,696.3,106.5z M699.4,105.4v5h1.1v-5H699.4z"/>
                            </g>
                        </g>
                    </g>
                    <g id="TOPOSlogo" style={{pointerEvents:"none"}}>
                        <g>
                            <path fill="#F8F7F7" d="M53.8,36.9l1.7,1.4c0,0,3.7-1,4.5-1.2c0.8-0.3,2.2-0.4,2.6-1.1c0.4-0.6,1-2.1,1.4-3.7
                                c0.3-1.7,0.7-3.2,0.7-3.4c0-0.1,0-0.3-0.7,0s-7.4,3.2-7.7,3.3C56,32.3,53.8,36.9,53.8,36.9z"/>
                            <path fill="#F8F7F7" d="M64.8,28.7l-0.1,1.7c0,0,5.9-1.5,6.1-1.7c0.1,0,0.7-2.3,0.7-2.5c0-0.1-0.1,0-0.6,0.1
                                C70.6,26.3,65.2,28.4,64.8,28.7z"/>
                            <path fill="#F8F7F7" d="M64.6,30.6L64.4,32c0,0,3.3,0.1,3.6,0c0.4-0.1,0.8-0.1,1-0.7s0.6-1.5,0.4-1.7c-0.1-0.1-0.7,0-1.1,0.1
                                C67.8,29.9,64.6,30.6,64.6,30.6z"/>
                            <path fill="#F8F7F7" d="M64.2,32.5l-0.3,1.4c0,0,2.8,0.4,3.2,0.3c0.4-0.1,0.8-0.8,1-1.2s0.1-0.4-0.3-0.4C67,32.5,64.2,32.5,64.2,32.5
                                z"/>
                            <path fill="#F8F7F7" d="M63.7,34.3c0,0,2.3,0.6,2.5,0.6c0.1,0-0.1,1.2-0.3,1.4c-0.3,0.1-0.6,0-0.7,0c-0.1,0-1.4-0.6-1.4-0.6l1.2,1.2
                                l-1.1,0.6l-1-1l0.3,1.2h-1l-0.1-1.1c0,0,0.4-0.1,0.8-0.8C63.4,35.1,63.7,34.3,63.7,34.3z"/>
                            <rect x="68.2" y="59.3" fill="#F8F7F7" width="0.4" height="47"/>
                            <path fill="#F8F7F7" d="M70.6,49.3c-0.1,0.3-1.1,4.1-1.1,4.1s-0.6,0.4-1.4,0.7c0.1-1.1,0.3-1.9,0.4-2.2c0.8-1.2,1.2-1.9,1.2-1.9
                                s-1.5,1.5-1.7,1.8c-0.1,0.1-0.3,1.2-0.6,2.6c-0.6,0.4-1,1.7-1,1.7s0.1-1.4-0.1-1.8c-0.1-0.6-0.6-0.6-0.6-0.6l0.7,2.5
                                c0,0-0.4,1.8-0.3,3c0,1.1,0,46.9,0,46.9H67c0,0,0-45.5,0-47.5c0-1.1,0.3-2.1,0.6-2.8c-0.1,0.7-0.1,1.4-0.1,1.9
                                c-0.1,2.6,0,48.4,0,48.4h0.7c0,0-0.1-47.5,0.3-51.5c0.4-0.3,1.9-0.7,2.2-1c0.3-0.3,1.4-2.9,1.4-2.9s0.3-0.8,0.3-1.1
                                C72.1,49.3,70.7,49.1,70.6,49.3z"/>
                            <path fill="#F8F7F7" d="M70,54.2v2.1l0.4-0.7c0,0,0.7,0.8,0.8,0.8c0.1,0.1,0.7,0,0.7,0l-0.3-1.1l1.1,0.3c0,0-1.5-1.8-1.7-1.8
                                C71.1,53.9,70,54.2,70,54.2z"/>
                            <path fill="#F8F7F7" d="M71.3,53.1c0,0,0.1-1.4,1.4-1.7c1.4-0.3-0.3,0.8-0.3,0.8l1.7,0.3l-0.1,0.6l-1.4,0.4l0.3,0.7L71.3,53.1z"/>
                            <g>
                                <path fill="#F8F7F7" d="M44,56.9c-0.3-0.7-1-1.2-1.5-1.5c-0.1-0.3-0.3-0.4-0.4-0.8c-0.7-1.4-2.2-2.1-2.6-2.5s-0.8-1.7-0.8-1.7
                                    l-3-0.6l0.7,0.8l-0.7,1l1.1,1l-0.3,1.1l1.5-0.3l1.5-0.8c0,0-0.1-0.1,1.1,0.7c0.6,0.4,1.1,1.1,1.4,1.8c-0.6-0.3-1.1-0.4-1.4-0.4
                                    c-0.7-0.1-2.3,0-2.3,0l-2.3-0.8l-0.4,1.4l-2.1,1l1.9,0.4L36,58l2.3-2.9c0,0,0.8-0.3,2.2-0.3c0.6,0,1.2,0.3,1.7,0.6
                                    c0.1,0.3,0.1,0.4,0.3,0.7C42.3,56,42.2,56,42.2,56c-0.7-0.1-1.5,0-1.8,0.3c-0.3,0.3-0.1,1,0,1.1c0.1,0.1,0.6,0.1,0.8,0.1
                                    c0.1-0.1,0.6-0.6,0.6-0.6s-0.3,0.3-0.6,0.3c-0.3,0.1-0.6,0.1-0.7-0.1c-0.1-0.3-0.1-0.6,0.1-0.7c0.3-0.1,0.7-0.4,1.1-0.3
                                    c0.3,0,0.7,0.3,1,0.6c0,0.1,0,0.1,0,0.3c0,0.3,0,1.1,0,2.2c-0.3-0.4-0.6-1-1-1C40.9,58,40.7,58,40.4,58s-0.7,0.1-0.7,0.1h-1.2
                                    l-0.6,1.1l1.7,0.1l0.3-0.8c0,0,0.4-0.1,0.8-0.1c0.4,0,1.1,0,1.4,0.3c0.3,0.3,0.7,1,0.8,1.4c0,0.4,0,0.8,0,1.4
                                    c-0.4-0.4-0.8-1-1.2-1c-0.6,0-1,0-1.2,0.3c-0.3,0.1-0.6,0.6-0.7,0.6c0.1-0.1,0.4-0.3,0.8-0.6c0.6-0.3,1,0,1.2,0.3
                                    c0.3,0.1,0.8,1.1,1.1,1.5c0,1.5,0,43.6,0,43.6h0.7l0.1-44.8c0,0,0-2.8-0.3-4c-0.1-0.6-0.1-0.8-0.4-1.1c0.4,0.3,0.6,0.6,0.7,0.8
                                    c0.3,0.6,0.1,49.1,0.1,49.1h0.6l0.1-46.6C44.4,59.5,44.5,58.4,44,56.9z"/>
                                <path fill="#F8F7F7" d="M39.4,61.5L39.4,61.5L39.4,61.5z"/>
                            </g>
                            <rect x="45.9" y="59.3" fill="#F8F7F7"  width="1.2" height="36.9"/>
                            <rect x="47.4" y="59.3" fill="#F8F7F7"  width="0.7" height="36.9"/>
                            <path fill="#F8F7F7" d="M48.5,32.9c-0.3,1.5-1.5,6.9,0.3,9.4c1,1.2,2.5,2.2,4,2.8v51H54V45.6c0.1,0,0.3,0,0.4,0.1v50.4h1.2V45.9
                                c0.3,0,0.7,0,0.8,0c1.8-0.4,3.3-1.9,4.1-2.8c0.8-0.8,2.5-3.4,2.5-3.4L63,39.1c0,0-0.3,0.1-0.8,0.4c-0.6,0.3-2.3,1-2.9,1
                                c-1.1,0-2.5-0.8-3.6-1.5c-1.7-1.1-1.9-1.5-3-2.8s-1.7-5-2.8-7.3c-1.1-2.3-2.3-2.5-3-2.5c-0.6,0-4.3,0.8-4.3,0.8
                                c1.8,0.4,2.8,0.6,2.6,1.2c0,0.4-1.9,1-2.2,1.4c1.1-0.3,3-0.4,4.4,0.1C48.8,30.6,48.7,32.1,48.5,32.9z"/>
                            <rect x="56" y="59.3" fill="#F8F7F7"  width="2.3" height="36.9"/>
                            <path fill="#F8F7F7" d="M59.4,96.2V59.7c0,0,0.1-3.4,0.3-3.7c0.1-0.3,0.8-1.2,1-1.4c0.1-0.1,0.8-0.8,0.8-0.8s-0.6,0.4-1,0.6
                                c-0.3,0.1-0.8,0.8-1.2,1.4c-0.4,0.6-0.6,3.6-0.6,3.6v36.8h0.7V96.2z"/>
                            <path fill="#F8F7F7" d="M60.4,96.2c0-14.1,0-37.8,0.1-38.3c0.1-0.6,0.4-1.5,0.6-1.8c0.3-0.1,1.7-1.2,1.7-1.2s-1.7,0.8-1.9,1.1
                                c-0.1,0.3-1,1.8-1,2.1c0,0.3-0.1,0.8-0.1,1.7c0,0.6,0,22.9,0,36.5H60.4z"/>
                            <rect x="61.9" y="59.3" fill="#F8F7F7" width="1.2" height="36.9"/>
                            <polygon fill="#F8F7F7" points="65.3,96.2 65.3,59.3 63.5,59.3 63.5,96.2 		"/>
                            <path fill="#F8F7F7" d="M46.3,99.1v1c0,0,0,0.1,0.1,0.1h0.7v5.5c0,0,0,0.1,0.1,0.1s0.8,0,1,0s0.1-0.1,0.1-0.1v-5.5c0,0,0.6,0,0.7,0
                                c0.1,0,0.1,0,0.1-0.1c0-0.1,0-1,0-1s0-0.1-0.1-0.1C48.9,99,46.6,99,46.3,99.1C46.4,99,46.3,99,46.3,99.1z"/>
                            <path fill="#F8F7F7" d="M52.6,99c-0.1,0-2.3,0-2.5,0C50,99,50,99.1,50,99.1v6.5c0,0.1,0,0.1,0.1,0.1c0,0,2.3,0,2.5,0
                                c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-6.3,0-6.5S52.8,99,52.6,99z M51.5,104.3c0,0,0,0.1-0.1,0.1c-0.1,0-0.3,0-0.3-0.1s0-3.9,0-3.9
                                s0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1v3.9H51.5z"/>
                            <path fill="#F8F7F7" d="M60.4,99c-0.1,0-2.3,0-2.5,0c-0.1,0-0.1,0.1-0.1,0.1v6.5c0,0.1,0,0.1,0.1,0.1c0,0,2.3,0,2.5,0
                                c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-6.3,0-6.5C60.4,99,60.4,99,60.4,99z M59.3,104.3c0,0,0,0.1-0.1,0.1c-0.1,0-0.3,0-0.3-0.1
                                s0-3.9,0-3.9s0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1v3.9H59.3z"/>
                            <path fill="#F8F7F7" d="M53.9,105.6v-6.5L54,99c0,0,2.3,0,2.5,0c0.1,0,0.1,0.1,0.1,0.1s0,6.3,0,6.5c0,0.1-0.1,0.1-0.1,0.1s-0.8,0-1,0
                                c-0.1,0-0.1-0.1-0.1-0.1v-5.4H55c0,0,0,5.2,0,5.4c0,0.1-0.1,0.1-0.1,0.1s-0.8,0-1,0C53.9,105.7,53.9,105.6,53.9,105.6z"/>
                            <path fill="#F8F7F7" d="M63.1,103.2v1c0,0,0,0.1-0.1,0.1c-0.1,0-0.3,0-0.3-0.1c0-0.1,0-3.9,0-3.9s0-0.1,0.1-0.1
                                c0.1,0,0.1,0.1,0.1,0.1v2.2l1.2-1.1c0-1.2,0-2.3,0-2.5c0-0.1,0-0.1-0.1-0.1s-2.3,0-2.5,0c-0.1,0-0.1,0.1-0.1,0.1v6.5
                                c0,0.1,0,0.1,0.1,0.1c0.1,0,2.3,0,2.5,0s0.1-0.1,0.1-0.1s0-1.8,0-3.4L63.1,103.2z"/>
                            <path fill="#F8F7F7" d="M50.6,54.4c-0.4-1.1-1-2.1-1.1-2.2c-0.1-0.1-1-0.6-1.9-1c-0.4-0.8-0.7-1.5-0.8-1.8c-0.4-0.7-1.5-1.5-3.4-2.5
                                c-1.8-1-3.4-0.1-4,0.4c-0.7,0.6-1,2.1-0.1,2.9s1.8,0.7,2.5,0.7c0.7-0.1,1-1,1-1.2c0.1-0.3-0.1-1.1-0.4-1.2c0-0.3-0.3-0.3-0.6-0.3
                                c-0.3,0-0.4,0.1-0.4,0.1s0.3,0,0.6,0c0.3,0.1,0.3,0.4,0.4,0.7c0.1,0.4-0.3,1-0.7,1.1s-1.4,0.1-1.8-0.8c-0.4-1,0-1.9,1.7-2.3
                                c1.7-0.3,3.3,1.1,4.7,2.5c0.3,0.3,0.6,0.7,0.7,1.1C46,50.3,45.6,50,45.6,50s0.6,0.3,1.2,0.8c0.4,0.8,0.7,1.8,1,2.6l0,0
                                c-1.9,0.6-3.6,2.1-3.6,2.1s2.6-1.5,3.6-1.5c0.1,0,0.1,0,0.3,0c0.3,1,0.4,1.8,0.4,1.8v40.1h1.2V59.4c0,0-0.1-2.9-0.4-3.7
                                c0-0.1-0.3-0.7-0.4-1.2c0.6,0.3,1.1,0.6,1.4,0.8c0.1,0.7,0.3,1.4,0.3,1.4v7.7v31.7h0.1h0.8h0.1c0-9.4,0-23,0-31.7c0-4.5,0-7.7,0-8
                                C51.8,55.8,51.4,55,50.6,54.4z M48.7,53.6c-0.3-0.7-0.6-1.4-0.8-1.9c0.8,0.6,1.5,1.1,1.8,1.4c0.1,0.1,0.3,0.6,0.4,1
                                C49.6,53.8,49.1,53.6,48.7,53.6z"/>
                        </g>
                    </g>
                    <g id="FDUTIK-logo" style={{pointerEvents:"none"}} >
                        <g>
                            <path  fill="#FDFDFD"  d="M132.2,66.2c0,0.3,4.4-23.4,17-24.8l-0.8,4c0,0,3.2-0.8,5.4-1c0.1,0,4-0.7,4-0.7s3.6-0.3,7.6-0.3
                                c0.3,0,0.7-0.1,0.7-0.1l6.2-0.7l-2.6,0.1l0.6-3.2c0,0,2.1,1.2,2.2,1.7l-0.1,1.4l0,0l-1.9,8.8l-22.1,3.3l-9.9,15.2l-6.2-0.7
                                L132.2,66.2z"/>
                            <path  fill="#4C495B"  d="M135.1,70c0,0,2.3-21.8,15.2-23l-0.8,3.6c0,0,3.4-0.8,5.7-1c0.1,0,4-0.7,4-0.7s3.6-0.3,7.6-0.3
                                c0.3,0,0.7-0.1,0.7-0.1l6.2-0.7L171,48l0.8-3.4c0,0,1.9,1.5,2.1,1.9l-0.1,1.4l0,0l-1.9,8.8L149.8,60l-8.7,10.8L135.1,70z"/>
                            <path  fill="#E31C1A"  d="M139.2,71c0,0,1.1-17.2,12.7-18.7l-0.8,3.6c0,0,3.3-0.7,5.4-1c0.1,0,4-0.7,4-0.7s3.6-0.3,7.6-0.3
                                c0.3,0,0.7-0.1,0.7-0.1l6.2-0.7l-2.6,0.1l0.8-3.4c0,0,1.9,1.4,2.1,1.9l-0.1,1.4l0,0l-1.9,8.8l-22.1,3.3l-7.6,6.6L139.2,71z"/>
                            <polygon  fill="#FDFDFD"  points="158.8,67.5 158.8,22.9 151.5,61.1 		"/>
                            <polygon  fill="#4C495B"  points="158.8,67.5 158.8,22.9 165.9,61.2 		"/>
                            <polygon  fill="#FDFDFD"  points="158.6,67.4 158.6,112 151.3,73.5 		"/>
                            <polygon  fill="#4C495B"  points="158.8,67.4 158.8,112 166.1,73.5 		"/>
                            <polygon  fill="#FDFDFD"  points="158.8,67.3 119.6,67.3 152,74.7 		"/>
                            <polygon  fill="#4C495B"  points="158.8,67.3 119.6,67.3 152.8,60.1 		"/>
                            <polygon  fill="#FDFDFD"  points="158.6,67.3 197.6,67 165.4,74.7 		"/>
                            <polygon  fill="#4C495B"  points="158.6,67.4 197.6,67 164.4,60.1 		"/>
                            <polyline  fill="#FDFDFD"  points="136,71.9 136.2,80.9 140.9,79.4 		"/>
                            <path  fill="#4C495B"  d="M136.2,72.1c0,0,5,17.1,22.9,17.1S181,71.9,181,71.9l-11.9-1c0,0-1.8,7-10.8,7c-8.4,0-9.9-6.9-9.9-6.9
                                L136.2,72.1z"/>
                            
                                <ellipse transform="matrix(0.1799 -0.9837 0.9837 0.1799 63.8123 211.2867)"  fill="#FDFDFD"  cx="158.6" cy="67.4" rx="11" ry="11.2"/>
                            <g>
                                <path  fill="#E31C1A"  d="M153.4,62.3c0,0,4.1,3.2,5.9,4.4c-0.3,0.4-0.8,0.8-1.2,1.2C156.7,66.3,153.4,62.3,153.4,62.3z"/>
                                <path  fill="#FDFDFD"  d="M163.9,72.5c0,0-4.1-3.2-5.9-4.4c0.3-0.4,0.8-0.8,1.2-1.2C160.7,68.5,163.9,72.5,163.9,72.5z"/>
                            </g>
                            <g>
                                <path  fill="#E31C1A"  d="M152.3,61.3c0,0,5.8,2.9,7.9,4.4c-0.4,0.4-3,2.8-3.4,3.2C155,67,152.3,61.3,152.3,61.3z"/>
                                <path  fill="#004996"  d="M165,73.5c0,0-6.2-2.9-8.3-4.5c0.4-0.4,3-2.8,3.4-3.2C161.8,67.8,165,73.5,165,73.5z"/>
                            </g>
                            <g>
                                <path  fill="#FFFFFF"  d="M148.8,76.8l-2.6,2.9l1.2,1.1l2.6-2.9l0.8,0.7l-2.6,2.9l0.3,0.3l-1.4,1.5l-0.6-0.6l0.8-1l-2.5-2.2l3.2-3.6
                                    L148.8,76.8z"/>
                                <path  fill="#FFFFFF"  d="M155.5,80.6l-1.4,3.7l0.4,0.1l-0.8,1.9l-0.8-0.3l0.4-1.1l-2.6-1l-0.6,1.1l-0.8-0.3L150,83l0.4,0.1
                                    c0.3-0.3,0.6-0.6,0.8-0.8c0.3-0.4,0.6-0.8,0.7-1.4l0.4-1.2L155.5,80.6z M153,81.3c-0.1,0.6-0.4,1-0.8,1.4
                                    c-0.1,0.3-0.4,0.4-0.6,0.7l1.5,0.6l1.1-2.8l-1-0.4L153,81.3z"/>
                                <path  fill="#FFFFFF"  d="M157.7,81V83h0.6c0.1-1.1,1-1.9,2.1-2.1c1.4,0,2.1,1.1,2.2,2.3c0,1.5-0.8,2.5-2.1,2.6
                                    c-1.2,0-1.9-0.8-2.1-2.1h-0.6v2.1h-1.1l-0.1-4.8L157.7,81z M161.4,83.2c0-0.8-0.4-1.7-1.1-1.5c-0.7,0-1,0.8-1,1.7
                                    c0,1,0.4,1.7,1.1,1.5C161,84.9,161.4,84.2,161.4,83.2z"/>
                                <path  fill="#FFFFFF"  d="M164.3,81l-1.2,0.6l-0.4-0.8l3.4-1.4l0.4,0.8l-1.2,0.6l1.5,3.6l-1,0.4L164.3,81z"/>
                                <path  fill="#FFFFFF"  d="M167.5,78.1l1.2,1.5h0.1l-0.3-2.3l1-0.8l0.1,2.8c0.6-0.3,1.1-0.1,1.5,0.1s1,0.6,1.4,0.8l-1,0.7
                                    c-0.3-0.1-0.7-0.4-1-0.6c-0.4-0.3-1-0.6-1.4-0.1l-0.1,0.1l1.2,1.5l-0.8,0.7l-3-3.7L167.5,78.1z"/>
                            </g>
                        </g>
                    </g>
                    <text 
                        x={ this.state.fullWidth - 43 } 
                        y="55"
                        style={{
                            fontSize: 42,
                            fill: clr3,
                            fontFamily: getFontNameByID(palette.h1.fontFamilyID)
                        }}
                    >
                        { number }
                    </text>
                </svg>
                <div className="kraeved-button">
                    <Popover2
                        position={Position.BOTTOM_RIGHT} 
                        // interactionKind={Popover2InteractionKind.HOVER}
                        className={"bg-light border-light "}
                        content={
                            <ButtonGroup fill vertical className=" ">
                            {
                                right_routing()?.kraeved?.map( (menu, i) => {
                                    return  i < 3 || i === right_routing()?.kraeved?.length -1
                                    ?
                                    <Button
                                        key={menu.route}
                                        className="kraeved-link-btn"
                                        onClick={() => this.props.history.push(menu.route)}
                                    >
                                       {menu.title}         
                                    </Button>
                                    :
                                    null
                                })
                            }
                            </ButtonGroup>
                        }
                    >
                        <div className="pointer" >
                            {this.props.data.button_label}
                        </div>
                    </Popover2>
                </div>
            </div>
        )
    }
    renderMobyle(style) {

        const { palette } = this.props
        const { year, number, color1, color2, color3, color4, title, description } = this.props.data
        const clr1 = getColor(color1, palette)
        const clr2 = getColor(color2, palette)
        const clr3 = getColor(color3, palette)
        const clr4 = getColor(color4, palette)
        return <div>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 2000 600"
                style={{ marginTop: 0 }}
            >
                <g id="light">
                    <rect y="6.6" fill="#FFF" width="1991.9" height="593.4" />
                </g>
                <g id="color2">
                    <rect y="424.1" fill={clr1} width="1060.8" height="175.9" />
                    <path fill={clr1} d="M805.3,63.3h37.3V79L828,122.6l17.6,51.1v15.7h-39.3l-10.6-48.8h-7v48.8h-39.4V63.3h39.4v45.7h7L805.3,63.3z"
                    />
                </g>
                <g id="color1">
                    <path fill={clr2} d="M73.6,189.4H34.2V63.3h84.3V79l-6.1,15.8H73.6V189.4z" />
                    <path fill={clr2} d="M143.3,189.4c-9.4,0-15.8-6.5-15.8-15.7V79c0-9.2,6.5-15.7,15.8-15.7h62.8c9.4,0,15.8,6.5,15.8,15.7v94.7
		c0,9.2-6.5,15.7-15.8,15.7H143.3z M166.9,95v62.8h15.7V95H166.9z"/>
                    <path fill={clr2} d="M236.4,189.4V63.3h78.7c9.4,0,15.8,6.5,15.8,15.7v29c0,9.2-7.6,15.1-16.2,15.1v3.4c8.6,0,16.2,5.9,16.2,15.3
		v31.9c0,9.2-6.5,15.7-15.8,15.7H236.4z M291.5,95h-15.7v14h15.7V95z M275.9,157.9h15.7v-17.3h-15.7V157.9z"/>
                    <path fill={clr2} d="M359.4,189.4c-9.4,0-15.8-6.5-15.8-15.7V79c0-9.2,6.5-15.7,15.8-15.7h62.8c9.4,0,15.8,6.5,15.8,15.7v94.7
		c0,9.2-6.5,15.7-15.8,15.7H359.4z M383,95v62.8h15.7V95H383z"/>
                    <path fill={clr2} d="M492,150.1v39.3h-39.4V63.3h78.7c9.4,0,15.8,6.5,15.8,15.7v55.3c0,9.2-6.5,15.8-15.8,15.8H492z M492,118.6
		h15.7V95H492V118.6z"/>
                    <path fill={clr2} d="M613.2,109.1V79.2c0-9.2,6.5-15.8,15.7-15.8h61.4c9.4,0,15.8,6.7,15.8,15.8v94.4c0,9.2-6.5,15.8-15.8,15.8H629
		c-9.4,0-15.8-6.7-15.8-15.8v-33h-14.2v48.8h-39.3V63.3h39.3v45.7H613.2z M652.6,94.8v63h14v-63H652.6z"/>
                    <path fill={clr2} d="M936,63.3l17.6,110.4v15.7h-39.4l-2.3-25.4h-17.3l-2.5,25.4h-39.3v-15.7l17.6-110.4H936z M909,132.5L905.4,95
		h-4.3l-3.6,37.5H909z"/>
                    <path fill={clr2} d="M1020.5,63.3h37.3V79l-14.6,43.6l17.6,51.1v15.7h-39.3l-10.6-48.8h-7v48.8h-39.4V63.3h39.4v45.7h7L1020.5,63.3
		z"/>
                </g>
                <g id="dark">
                    <g>
                        <rect fill={ clr4 } width="1059.5" height="50.8" />
                    </g>
                    <g>
                        <rect y="409.3" fill={ clr4 } width="1060.8" height="50.8" />
                    </g>
                    <path fill={ clr4 } d="M124.6,213.9h52v21.9l-20.4,60.8l24.6,71.4v21.9h-54.8l-14.8-68.1h-9.8v68.1h-55V213.9h55v63.8h9.8
		L124.6,213.9z"/>
                    <path fill={ clr4 } d="M251,335.1v54.8h-55V213.9h109.8c13.1,0,22.1,9,22.1,21.9V313c0,12.8-9,22.1-22.1,22.1H251z M251,291.1h21.9
		v-32.9H251V291.1z"/>
                    <path fill={ clr4 } d="M449,213.9L473.7,368v21.9h-55l-3.3-35.4h-24.1l-3.5,35.4h-54.8V368l24.6-154.1H449z M411.3,310.5l-5-52.3h-6
		l-5,52.3H411.3z"/>
                    <path fill={ clr4 } d="M488.8,389.9V213.9h117.6v21.9l-8.8,22.4h-54v19.6h54v44h-54v24.1h62.8v44H488.8z" />
                    <path fill={ clr4 } d="M621.5,389.9V213.9h109.8c13.1,0,22.1,9,22.1,21.9v40.5c0,12.8-10.6,21.1-22.6,21.1v4.8
		c12.1,0,22.6,8.3,22.6,21.4V368c0,12.8-9,21.9-22.1,21.9H621.5z M698.4,258.2h-21.9v19.6h21.9V258.2z M676.5,345.9h21.9v-24.1
		h-21.9V345.9z"/>
                    <path fill={ clr4 } d="M771,389.9V213.9h117.6v21.9l-8.8,22.4h-54v19.6h54v44h-54v24.1h62.8v44H771z" />
                    <path fill={ clr4 } d="M896.2,345.9h16.3l26.9-131.9h105.6v131.9h14.6v44H896.2V345.9z M989.9,257.9h-7.8l-13.6,88h21.4V257.9z" />
                    <path fill={ clr3 } d="M52.1,511.9v-39.4h29.5v39.4H69.4v-29.5h-4.9v29.5H52.1z" />
                    <path fill="#D2E6F8" d="M104.1,499.6v12.3H91.8v-39.4h24.6c2.9,0,4.9,2,4.9,4.9v17.3c0,2.9-2,4.9-4.9,4.9H104.1z M104.1,489.8h4.9
		v-7.4h-4.9V489.8z"/>
                    <path fill="#D2E6F8" d="M135.8,511.9c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9V507
		c0,2.9-2,4.9-4.9,4.9H135.8z M143.2,482.4V502h4.9v-19.6H143.2z"/>
                    <path fill="#D2E6F8" d="M170.5,511.9v-39.4h26.3v4.9l-2,5h-12.1v4.4h12.1v9.8h-12.1v5.4h14.1v9.8H170.5z" />
                    <path fill="#D2E6F8" d="M223.3,472.5H235v4.9l-4.6,13.6l5.5,16v4.9h-12.3l-3.3-15.2h-2.2v15.2h-12.3v-39.4h12.3v14.3h2.2L223.3,472.5z
		"/>
                    <path fill="#D2E6F8" d="M243.2,482.4v-9.9h27.6v4.9l-2,5h-5.9v29.5h-12.3v-29.5H243.2z" />
                    <path fill="#D2E6F8" d="M296.4,486.8v-14.3h12.3v39.4h-12.3v-15.2h-4.9v15.2h-12.3v-39.4h12.3v14.3H296.4z" />
                    <path fill="#D2E6F8" d="M323.7,511.9c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9V507
		c0,2.9-2,4.9-4.9,4.9H323.7z M331.1,482.4V502h4.9v-19.6H331.1z"/>
                    <path fill="#D2E6F8" d="M385.1,488.3v7.9h-27.8v-7.9H385.1z" />
                    <path fill={ clr3 } d="M411.3,472.5h12.3v39.4h-12.3V507l1.9-12.6h-1l-5.8,13.6v3.9h-12.3v-39.4h12.3v4.9l-1.8,12.6h1l5.8-13.6V472.5
		z"/>
                    <path fill="#D2E6F8" d="M460,472.5v4.9l-1.9,5H446V502h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H460z" />
                    <path fill="#D2E6F8" d="M495.3,472.5v4.9l-1.9,5h-12.1V502h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H495.3z" />
                    <path fill="#D2E6F8" d="M521.3,511.9v-29.5h-1.7l-4.6,29.5h-12.4V507l7.4-34.5h23.6v39.4H521.3z" />
                    <path fill="#D2E6F8" d="M543.7,511.9v-39.4H570v4.9l-2,5H556v4.4h12.1v9.8H556v5.4H570v9.8H543.7z" />
                    <path fill="#D2E6F8" d="M577.3,502h3.7l6-29.5h23.6V502h3.3v9.8h-36.6V502z M598.3,482.4h-1.7l-3,19.7h4.8V482.4z" />
                    <path fill="#D2E6F8" d="M627.3,511.9c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9V507
		c0,2.9-2,4.9-4.9,4.9H627.3z M634.6,482.4V502h4.9v-19.6H634.6z"/>
                    <path fill="#D2E6F8" d="M662,511.9v-39.4h24.6c2.9,0,4.9,2,4.9,4.9v9.1c0,2.9-2.4,4.7-5.1,4.7v1.1c2.7,0,5.1,1.9,5.1,4.8v10
		c0,2.9-2,4.9-4.9,4.9H662z M679.2,482.4h-4.9v4.4h4.9V482.4z M674.3,502h4.9v-5.4h-4.9V502z"/>
                    <path fill="#D2E6F8" d="M725.9,472.5l5.5,34.5v4.9h-12.3l-0.7-7.9H713l-0.8,7.9h-12.3V507l5.5-34.5H725.9z M717.5,494.1l-1.1-11.7H715
		l-1.1,11.7H717.5z"/>
                    <path fill="#D2E6F8" d="M736.5,482.4v-9.9H764v4.9l-2,5h-5.9v29.5h-12.3v-29.5H736.5z" />
                    <path fill="#D2E6F8" d="M772.5,511.9v-39.4h26.3v4.9l-2,5h-12.1v4.4h12.1v9.8h-12.1v5.4h14.1v9.8H772.5z" />
                    <path fill="#D2E6F8" d="M824.8,511.9v-29.5h-1.7l-4.6,29.5h-12.4V507l7.4-34.5h23.6v39.4H824.8z" />
                    <path fill="#D2E6F8" d="M876.7,489.7v17.2c0,2.9-2,4.9-4.9,4.9h-24.6v-39.4h12.3v12.3h12.3C874.7,484.8,876.7,486.9,876.7,489.7z
		 M864.4,502v-7.4h-4.9v7.4H864.4z"/>
                    <path fill="#D2E6F8" d="M912.6,472.5v4.9l-1.9,5h-12.1V502h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H912.6z" />
                    <path fill="#D2E6F8" d="M939.1,472.5h11.6v4.9l-4.6,13.6l5.5,16v4.9h-12.3l-3.3-15.2h-2.2v15.2h-12.3v-39.4h12.3v14.3h2.2L939.1,472.5
		z"/>
                    <path fill="#D2E6F8" d="M985.5,472.5L991,507v4.9h-12.3l-0.7-7.9h-5.4l-0.8,7.9h-12.3V507l5.5-34.5H985.5z M977.1,494.1l-1.1-11.7
		h-1.3l-1.1,11.7H977.1z"/>
                    <path fill="#D2E6F8" d="M1029,511.9h-12.3v-12.3h-2.5l-3.3,12.3h-12V507l3.8-9.7c-1.9-0.5-3.3-2.6-3.3-4.5v-15.4c0-2.9,2-4.9,4.9-4.9
		h24.6V511.9z M1011.8,489.7h4.9v-7.4h-4.9V489.7z"/>
                    <path fill={ clr3 } d="M55,574.2v-39.4h29.5v39.4H72.2v-29.5h-4.9v29.5H55z" />
                    <path fill="#D2E6F8" d="M94.6,574.2v-39.4H121v4.9l-2,5h-12.1v4.4H119v9.8h-12.1v5.4H121v9.8H94.6z" />
                    <path fill="#D2E6F8" d="M128.3,564.4h3.7l6-29.5h23.6v29.5h3.3v9.8h-36.6V564.4z M149.3,544.7h-1.7l-3,19.7h4.8V544.7z" />
                    <path fill="#D2E6F8" d="M198.1,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9h-5.4l-0.8,7.9h-12.3v-4.9l5.5-34.5H198.1z M189.7,556.4l-1.1-11.7
		h-1.4l-1.1,11.7H189.7z"/>
                    <path fill="#D2E6F8" d="M224.9,574.2h-12.3v-39.4h26.3v4.9l-1.9,4.9h-12.1V574.2z" />
                    <path fill="#D2E6F8" d="M252.3,574.2c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9v29.6
		c0,2.9-2,4.9-4.9,4.9H252.3z M259.7,544.7v19.6h4.9v-19.6H259.7z"/>
                    <path fill="#D2E6F8" d="M299.3,574.2H287v-39.4h26.3v4.9l-1.9,4.9h-12.1V574.2z" />
                    <path fill="#D2E6F8" d="M339,534.8h12.3v39.4H339v-4.9l1.9-12.6h-1l-5.8,13.6v3.9h-12.3v-39.4H334v4.9l-1.8,12.6h1l5.8-13.6V534.8z" />
                    <path fill="#D2E6F8" d="M360.8,556v-21.1H373v16.3h4.9v-16.3h12.3v39.4H378v-13.3h-12.3C362.8,560.9,360.8,558.9,360.8,556z" />
                    <path fill="#D2E6F8" d="M400.4,574.2v-39.4h26.3v4.9l-2,5h-12.1v4.4h12.1v9.8h-12.1v5.4h14.1v9.8H400.4z" />
                    <path fill="#D2E6F8" d="M462,534.8v4.9l-1.9,5H448v19.6h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H462z" />
                    <path fill="#D2E6F8" d="M488.5,534.8h11.6v4.9l-4.6,13.6l5.5,16v4.9h-12.3l-3.3-15.2h-2.2v15.2H471v-39.4h12.3v14.3h2.2L488.5,534.8z"
                    />
                    <path fill="#D2E6F8" d="M534.9,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9H522l-0.8,7.9H509v-4.9l5.5-34.5H534.9z M526.5,556.4l-1.1-11.7H524
		l-1.1,11.7H526.5z"/>
                    <path fill="#D2E6F8" d="M578.4,574.2h-12.3v-12.3h-2.5l-3.3,12.3h-12v-4.9l3.8-9.7c-1.9-0.5-3.3-2.6-3.3-4.5v-15.4
		c0-2.9,2-4.9,4.9-4.9h24.6V574.2z M561.2,552.1h4.9v-7.4h-4.9V552.1z"/>
                    <path fill={ clr3 } d="M638.7,574.2v-29.5H637l-4.6,29.5H620v-4.9l7.4-34.5H651v39.4H638.7z" />
                    <path fill="#D2E6F8" d="M686,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9H673l-0.8,7.9H660v-4.9l5.5-34.5H686z M677.5,556.4l-1.1-11.7h-1.4
		l-1.1,11.7H677.5z"/>
                    <path fill="#D2E6F8" d="M730,554v15.2c0,2.9-2,4.9-4.9,4.9h-24.6v-39.4h29.2v4.9l-2,4.9h-15v4.4h12.3C727.9,549.1,730,551.2,730,554z
		 M717.7,564.4V559h-4.9v5.4H717.7z"/>
                    <path fill="#D2E6F8" d="M744.5,574.2c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9v29.6
		c0,2.9-2,4.9-4.9,4.9H744.5z M751.8,544.7v19.6h4.9v-19.6H751.8z"/>
                    <path fill="#D2E6F8" d="M791.5,562v12.3h-12.3v-39.4h24.6c2.9,0,4.9,2,4.9,4.9V557c0,2.9-2,4.9-4.9,4.9H791.5z M791.5,552.1h4.9v-7.4
		h-4.9V552.1z"/>
                    <path fill="#D2E6F8" d="M841.4,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9h-5.4l-0.8,7.9h-12.3v-4.9l5.5-34.5H841.4z M833,556.4l-1.1-11.7
		h-1.4l-1.1,11.7H833z"/>
                    <path fill="#D2E6F8" d="M852,544.7v-9.9h27.6v4.9l-2,5h-5.9v29.5h-12.3v-29.5H852z" />
                    <path fill="#D2E6F8" d="M892.9,574.2c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9v29.6
		c0,2.9-2,4.9-4.9,4.9H892.9z M900.3,544.7v19.6h4.9v-19.6H900.3z"/>
                    <path fill="#D2E6F8" d="M939.9,562v12.3h-12.3v-39.4h24.6c2.9,0,4.9,2,4.9,4.9V557c0,2.9-2,4.9-4.9,4.9H939.9z M939.9,552.1h4.9v-7.4
		h-4.9V552.1z"/>
                    <path fill="#D2E6F8" d="M983.9,534.8h12.3v39.4h-12.3v-4.9l1.9-12.6h-1l-5.8,13.6v3.9h-12.3v-39.4h12.3v4.9l-1.8,12.6h1l5.8-13.6
		V534.8z"/>
                    <path fill="#D2E6F8" d="M1035.2,574.2h-12.3v-12.3h-2.5l-3.3,12.3h-12v-4.9l3.8-9.7c-1.9-0.5-3.3-2.6-3.3-4.5v-15.4
		c0-2.9,2-4.9,4.9-4.9h24.6V574.2z M1018,552.1h4.9v-7.4h-4.9V552.1z"/>
                </g> 
            </svg>
            <div>
                {
                    Style.it(
                        `
                            .landing-kraeved-title-title
                            {
                               color:${palette.main_text_color};
                               position:relative;
                               top:0;
                               left:0;
                               text-align:center;
                               padding:0 10px;
                               font-size:12px;
                            }
                            `,
                        <div className="landing-kraeved-title-title">
                            <span dangerouslySetInnerHTML={{ __html: title }} />
                        </div>
                    )
                }
                {
                    Style.it(
                        `
                            .landing-kraeved-title-descr
                            {
                               color:${palette.main_text_color};
                               position:relative;
                               top:0;
                               left:0;
                               text-align:center;
                               padding:0 10px;
                            }
                            `,
                        <div className="landing-kraeved-title-descr">
                            <span dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                    )
                }
            </div>

            <div>
                {
                    Style.it(
                        `
                            .landing-kraeved-title-number
                            {
                                color: #111;
                                left: auto;
                                top: -12px;
                                right: 10px;
                                width: 40%;
                                text-align: center;
                                color:${clr2};
                               font-family:${getFontNameByID(palette.h1.fontFamilyID)};
                            }
                            `,
                        <div className="landing-kraeved-title-number">
                            <span dangerouslySetInnerHTML={{ __html: number }} />
                        </div>
                    )
                }
                {
                    Style.it(
                        `
                            .landing-kraeved-title-year
                            { 
                               color:#111;
                               left: auto;
                               right: 10px;
                               width: 40%;
                               text-align: center;
                               top: 60px;
                               color:${clr3};
                            }
                            `,
                        <div className="landing-kraeved-title-year">
                            <span dangerouslySetInnerHTML={{ __html: year }} />
                        </div>
                    )
                }
            </div>
            <div>
                {
                    this.props.data.button_label && this.props.data.button_route
                        ?
                        Style.it(
                            `
                        .kraeved-button 
                        {
                            position: absolute;
                            bottom: 65px;
                            right: 10px;
                            z-index: 10;
                        }
                        .kraeved-button > a
                        { 
                            padding: 4px 20px; 
                            opacity:.75;
                        }
                        .kraeved-button > a:hover
                        {  
                            opacity:1;
                        }
                        `,
                            <div className="kraeved-button">
                                <Link
                                    to={this.props.data.button_route}
                                >
                                    {this.props.data.button_label}
                                </Link>
                            </div>
                        )
                        :
                        null
                }
            </div>
        </div>
    }
}
export default compose( 
    withRouter,
  )(KraevedTitle2)
