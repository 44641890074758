import React, {Suspense} from 'react'
//import Select from 'react-select' // https://github.com/JedWatson/react-select
import { withApollo } from 'react-apollo'
import { compose } from "recompose"
import gql from 'graphql-tag'
import { Intent, Dialog, Button, ButtonGroup, Popover } from '@blueprintjs/core'
import BasicState from "react-pe-basic-view"
import { __ } from 'react-pe-utilities'
import { routing, default_menu } from 'react-pe-layouts'
import { initArea } from  "react-pe-utilities" 
import {translitterate} from 'react-pe-utilities' 
import {LayoutIcon} from 'react-pe-useful'
import MenuElementBtnGroup from './adminMenu/MenuElementBtnGroup'
import MenuElementForm from './adminMenu/MenuElementForm'
import { AppToaster } from 'react-pe-useful'
import { help_url } from 'react-pe-layouts'
import FieldInput from "react-pe-scalars"
import {Loading} from 'react-pe-useful'
import $ from "jquery"
//import MenuDragGroup from './adminMenu/MenuDragGroup'
import Select from "react-select" 
import CopyRouteEngine from './adminMenu/CopyRouteEngine'

class AdminMenuView extends BasicState {
  basic_state_data() {
    const menuDatas = routing()
    return {
      menuDatas,
      current: 'profile',
      curBtn: menuDatas.profile.length > 0 ? menuDatas.profile[0].route : null,
      isLeftClosed: window.innerWidth < 760,
    }
  }

  getContext(current, curBtn) {
    const { menuDatas } = this.state
    //console.log( menuDatas, menuDatas[current], current, curBtn );
    let boo
    const currentContext =
      menuDatas[this.state.current].filter(e => {
        if (Array.isArray(e.children) && e.children.length > 0) {
          const child = e.children.filter(ee => {
            if (
              ee.children &&
              Array.isArray(ee.children) &&
              ee.children.length > 0
            ) {
              const grandChild = ee.children.filter(
                eee => this.getRealRoute(eee) == curBtn
              )[0]
              if (grandChild) boo = grandChild
            }
            return this.getRealRoute(ee) == curBtn
          })[0]
          if (child) boo = child
        }
        return this.getRealRoute(e) == curBtn
      })[0] || {}
    return boo || currentContext
  }
  getRealRoute = obj => {
    if (obj.route) return obj.route
    if (obj.target_id) return obj.target_id
    return '/'
  }

  onClipboardCopy = () => {
    const copy = JSON.stringify(this.state.menuDatas)
    console.log(copy)
    console.log(this.state.menuDatas)
    $('body').append(
      `<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='SiteMapClipboard'>${copy}</textarea></div>`
    )
    const copyText = document.getElementById('SiteMapClipboard')
    copyText.select()
    copyText.setSelectionRange(0, 99999999999999999999)
    document.execCommand('copy')
    $('#SiteMapClipboard').remove()
    AppToaster.show({
      intent: Intent.SUCCESS,
      icon: 'tick',
      duration: 10000,
      message: __('Site map copy to clipbord'),
    })
  }
  onClipboardPaste = () => {
    navigator.clipboard.readText().then(clipText => {
      try {
        console.log(clipText)
        const menuDatas = JSON.parse(clipText)
        this.setState({
          menuDatas: menuDatas,
          current: 'profile',
          curBtn:
            menuDatas.profile.length > 0 ? menuDatas.profile[0].route : null,
          isUpdated: true,
        })
      } catch (e) {
        AppToaster.show({
          intent: Intent.DANGER,
          icon: 'tick',
          duration: 10000,
          message: __('Error read clipboard data'),
        })
      }
    })
  }

  render() {
    const leftClass = this.state.isLeftClosed
      ? 'tutor-left-aside-2 menu-aside closed'
      : 'tutor-left-aside-2 menu-aside'
    const mainClass = this.state.isLeftClosed
      ? 'tutor-main-2 pr-0 opened'
      : 'tutor-main-2 pr-0'
    const canClass = this.state.canUpdate ? 'btn btn-danger' : 'hidden'
    const { menuDatas, isUpdated, current, curBtn } = this.state
    const currentMenuElement = default_menu()[current]
    let i = 0
    let menus_selector = []
    let menus = []
    const menuDSelect = []
    for (var route in menuDatas) {
      const def = default_menu()[route]
      //if (route != "extended_routes")
      {
        menus_selector.push(
          <option key={i} value={route}>
            {`${__(def ? def.title : route)} | ${menuDatas[route].length}`}
          </option>
        )
        const rt = Object.keys(default_menu()).filter(e => e === route)[0]
        //console.log(default_menu()[rt])
        menus.push({
          _id: route,
          title: __(def ? def.title : route),
        })
        menuDSelect.push({
          value: route,
          label: (
            <div className='p-1 d-flex align-items-center'>
              <div className='mr-2'>
                <img
                  height={30}
                  alt='menu-icon'
                  src={
                    '/assets/img/admin/' +
                    (default_menu()[rt]
                      ? default_menu()[rt].icon
                      : 'extend-menu-icon.svg')
                  }
                />
              </div>
              <div className='flex-grow-100'>
                <div className='font-weight-bold'>
                  {__(def ? def.title : route)}
                </div>
                <div className='mt-1 small'>
                  {__(default_menu()[rt] ? default_menu()[rt].description : '')}
                </div>
              </div>
              <div className='description'>{menuDatas[route].length}</div>
            </div>
          ),
        })
      }
      i++
    }
    //console.log(menuDatas[this.state.current])
    const routeVal = {
      value: currentMenuElement ? current : -1,
      label: (
        <div className='d-flex w-100 align-items-center'>
          <div className='mr-2'>
            <img
              height={50}
              alt='menu-icon'
              src={
                '/assets/img/admin/' +
                (currentMenuElement
                  ? currentMenuElement.icon
                  : 'extend-menu-icon.svg')
              }
            />
          </div>
          <div className='flex-grow-100'>
            <div className='font-weight-bold'>
              {__(currentMenuElement ? currentMenuElement.title : route)}
            </div>
            <div className='mt-1 small'>
              {__(currentMenuElement ? currentMenuElement.description : '')}
            </div>
          </div>
        </div>
      ),
    }
    const customStyles = {
      option: base => ({
        ...base,
        minHeight: 35,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }),
      valueContainer: base => ({
        ...base,
        minHeight: 60,
        fontSize: '.8rem',
        whiteSpace: 'unset',
        backgroundColor: '#ced0d2',
        border: 'none',
        borderRadius: 0,
      }),
      singleValue: base => ({
        ...base,
        whiteSpace: 'unset',
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        backgroundColor: '#ced0d2',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#444',
      }),
    }
    const currentContext = this.getContext(
      this.state.current,
      this.state.curBtn
    )

    // console.log(this.state.current, this.state.curBtn, currentContext)

    const currentMenu = (
      <MenuElementBtnGroup
        items={[...menuDatas[this.state.current]]}
        curBtn={this.state.curBtn}
        onCurBtn={this.onCurBtn}
        onNewBtn={this.onNewBtn}
        onItems={this.onItems}
      />
      // <MenuDragGroup 
      //   items={[...menuDatas[this.state.current]]} 
      //   curBtn={this.state.curBtn}
      //   level={0}
      // />
    )
    const addMenu = (
      <Popover
        content={
          <div className='square'>
            <div>
              <small>{__('Set menu name')}</small>
              <input
                type='text'
                value={this.state.newMenuGroupName}
                onChange={evt =>
                  this.setState({ newMenuGroupName: evt.currentTarget.value })
                }
                className='form-control mt-2 mb-4'
                placeholder={__('use latin, numbers and _ ')}
              />
              <ButtonGroup>
                <Button intent={Intent.SUCCESS} onClick={this.onAddMenuGroup}>
                  {__('Insert menu group')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        }
      >
        <div className='btn btn-link  text-secondary mb-3 w-100 btn-sm opacity_75'>
          {__('Add new Menu group')}
        </div>
      </Popover>
    )
    const help = this.props.help_url ? (
      <span>
        <Button
          icon='help'
          minimal
          onClick={() =>
            window.open(help_url() + this.props.help_url, '_blank')
          }
        />
      </span>
    ) : null
    return (
      <div className='layout-state p-0'>
        <div className='tutor-row menu-row'>
          <div className={leftClass}>
            <div className='layout-state-head menu-header-22'>
              <LayoutIcon
                isSVG
                src={this.state.route.icon}
                className='layout-state-logo '
              />
              <div>
                <div
                  className=' p-1 hint hint--top text-secondary'
                  data-hint={__('copy site map')}
                  onClick={this.onClipboardCopy}
                >
                  <i className='fas fa-file-import' />
                </div>
                <div
                  className=' p-1 hint hint--top text-secondary'
                  data-hint={__('paste site map')}
                  onClick={this.onClipboardPaste}
                >
                  <i className='fas fa-file-export' />
                </div>
              </div>
              <div className='layout-state-title'>
                {help}
                {__(this.state.route.title)}
              </div>
            </div>
            <div className='small mx-3 mb-3 text-secondary'></div>
            <div className=' mb-5 z-index-100'>
              <div className='small mx-3 mb-3 '>{this.props.description}</div>
              <div className='lead mx-3 mb-3 text-secondary text-center '>
                {__('Choose menu group')}
              </div>
              <div className='input-group mb-0'>
                <Suspense fallback={<Loading />}>
                  <Select
                    value={routeVal}
                    isMulti={false}
                    isSearchable
                    options={menuDSelect}
                    styles={customStyles}
                    className='basic-multi-select '
                    classNamePrefix='select-route-'
                    onChange={this.handleCurrentMenu}
                  />
                </Suspense>
              </div>
              {addMenu}
            </div>
            <div className='tutor-menu'>
              {currentMenu}
            </div>
            <div>
              {
                Object.keys(default_menu()).filter(e => e == this.state.current).length == 0 
                  ?
                  <div
                    className='btn btn-link btn-sm text-secondary'
                    onClick={ this.onDeleteMenuGroup }
                  >
                    { __( 'Delete menu group' ) }
                  </div>
                  : 
                  null
              }
            </div>
            <div>
              <CopyRouteEngine />
            </div>
            { initArea('admin-menu-left-aside', { ...this.props }) }
          </div>


          <div className={mainClass}>
            <div
              className='clapan-left'
              onClick={() => this.setState({ isLeftClosed: !this.state.isLeftClosed }) }
            >
              <div
                className={`fas fa-caret-${ !this.state.isLeftClosed ? 'left' : 'right' }`}
              />
            </div>

            <div className='menu-header-22 flex-centered py-2 pl-5 pr-2'>
              <ButtonGroup className=' w-100 h-100' fill>
                <Button
                  className=' w-25 h-100'
                  icon='floppy-disk'
                  disabled={!isUpdated}
                  intent={!isUpdated ? Intent.NONE : Intent.SUCCESS}
                  onClick={this.onSaveMenus}
                >
                  {__('Save Site Map')}
                </Button>
                <Button
                  className=' w-75 h-100'
                  icon='plus'
                  intent={Intent.DANGER}
                  onClick={this.onAddRoute}
                >
                  {__('Add Route')}
                </Button>
              </ButtonGroup>
            </div>

            <div className=' p-4 '>
              <MenuElementForm
                {...currentContext}
                curBtnPath={this.state.curBtnPath}
                onChangeField={this.onChangeField}
                setContentType={this.setContentType}
                deleteRoute={this.deleteRoute}
                onAddChild={this.onAddChild}
              />
            </div>
          </div>
          <div className='tutor-right-aside-2'>
            {initArea('admin-menu-right-aside', { ...this.props })}
          </div>
        </div>

        <Dialog
          isOpen={this.state.isAddRoute}
          onClose={this.onAddRoute}
          title={__('Add route')}
          className='little'
        >
          <div className='p-3'>
            <div>
              <div className='p-2'>
                <FieldInput
                  title={__('Menu group')}
                  type='radio'
                  editable
                  multiple={false}
                  placeholder={__('Select Menu group')}
                  values={menus}
                  value={this.state.addRouteMenu}
                  onChange={this.onAddRouteMenu}
                />
                <FieldInput
                  title={__('Route name')}
                  type='route'
                  field='URL'
                  editable
                  value={this.state.addRouteName}
                  onChange={this.onAddRouteName}
                  placeholder={__('Put unique route name')}
                  commentary={__( "Select menu group for add new route and put new route's name"
                  )}
                />
              </div>

              <div className='bp3-dialog-footer'>
                <div className='bp3-dialog-footer-actions'>
                  <Button className='bp3-button' onClick={this.handleAddRoute}>
                    {__('Insert route')}
                  </Button>
                  <Button
                    className='bp3-button bp3-intent-danger'
                    icon='cross'
                    onClick={this.onAddRoute}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.isCreateOpen}
          onClose={this.onNewMenu}
          title={__("Set child's title")}
          className='little'
        >
          <div className='p-3'>
            <div>
              <input
                type='text'
                value={this.state.childName}
                onChange={evt =>
                  this.setState({ childName: evt.currentTarget.value })
                }
                className='form-control mt-2 mb-4'
                placeholder={__('Title')}
              />

              <div className='bp3-dialog-footer'>
                <div className='bp3-dialog-footer-actions'>
                  <Button className='bp3-button' onClick={this.onCreateChild}>
                    {__('Insert child')}
                  </Button>
                  <Button
                    className='bp3-button bp3-intent-danger'
                    icon='cross'
                    onClick={this.onNewOpen}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }

  onAddMenuGroup = () => {
    let menuDatas = { ...this.state.menuDatas }
    let newMenuGroupName = this.state.newMenuGroupName
      .replaceAll('-', '_')
      .replaceAll(' ', '_')
      .toLowerCase()
    if (newMenuGroupName && !menuDatas[newMenuGroupName]) {
      menuDatas[newMenuGroupName] = []
      this.setState({
        menuDatas,
        isUpdated: true,
        current: newMenuGroupName,
        newMenuGroupName: null,
      })
    } else {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'tick',
        message: 'Insert unique ID',
      })
    }
  }
  onDeleteMenuGroup = () => {
    console.log(this.state.current, Object.keys(default_menu()))
    if (
      Object.keys(default_menu()).filter(e => e === this.state.current).length >
      0
    ) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'tick',
        message: "This menu group cann't be remove",
      })
      return
    }
    if (window.confirm(__('Are you wand remove Menu Group?'))) {
      let menuDatas = { ...this.state.menuDatas }
      delete menuDatas[this.state.current]
      this.setState({
        menuDatas,
        isUpdated: true,
        current: 'profile',
        curBtn: 'profile',
      })
    }
  }

  onItems = items => {
    let menuDatas = { ...this.state.menuDatas }
    menuDatas[this.state.current] = items
    this.setState({ menuDatas, isUpdated: true })
    //console.log(items);
  }
  handleAddRoute = () => {
    const { addRouteMenu, addRouteName } = this.state
    let menuDatas = { ...this.state.menuDatas }
    if (!addRouteMenu || !menuDatas[addRouteMenu]) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'tick',
        message: 'Select menu group',
      })
      return
    }
    if (
      !addRouteName ||
      menuDatas[addRouteMenu].filter(e => e.route == addRouteName).length > 0
    ) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'tick',
        message: 'Put uniq not empty route',
      })
      return
    }
    menuDatas[addRouteMenu].push({
      title: addRouteName,
      route: addRouteName,
      icon: '',
      html: 'sample text',
    })
    this.setState({
      isAddRoute: !this.state.isAddRoute,
    })
  }
  onAddRouteMenu = menu_group => {
    this.setState({ addRouteMenu: menu_group })
  }
  onAddRouteName = route => {
    this.setState({ addRouteName: route })
  }

  onRouteLabel = () => {}
  onAddRoute = () => {
    this.setState({ isAddRoute: !this.state.isAddRoute })
  }
  onNewMenu = () => {
    this.setState({ isCreateOpen: !this.state.isCreateOpen })
  }
  handleCurrentMenu = data => {
    console.log(data.value)
    const { menuDatas } = this.state
    const curBtn = menuDatas[data.value][0]
    this.setState({
      current: data.value,
      curBtn: curBtn ? this.getRealRoute(curBtn) : null,
    })
  }

  onFullPathHandler = route => {
    const menuDatas = { ...this.state.menuDatas }
    let fullPath = []
    menuDatas[this.state.current].forEach((element, i) => {
      let route0 = this.getRealRoute(element)
      if (element.children) {
        element.children.forEach((element0, i0) => {
          let route1 = this.getRealRoute(element0)
          // console.log(element0.route, route, this.getRealRoute(element0) === route, i0)
          if (this.getRealRoute(element0) === route) {
            //console.log( route0, route1 )
            fullPath = [route0]
          }
          //console.log(element0)
          if (element0.children) {
            element0.children.forEach((element1, i1) => {
              let route2 = this.getRealRoute(element1)
              // console.log(element1.route, route, element1.route === route, i1)
              if (this.getRealRoute(element1) === route) {
                //console.log( route0, route1, route2 )
                fullPath = [route0, route1]
              }

              if (element1.children) {
                element.children.forEach((element2, i2) => {
                  let route3 = this.getRealRoute(element2)
                  fullPath[3] = [route0, route1, route2]
                })
              }
            })
          }
        })
      }
    })
    //console.log(fullPath)
    return fullPath
  }

  onCurBtn = (id, level, route) => {
    // console.log( menuDatas[this.state.current].filter(e => e.route === route)[0]);
    // console.log(id, level, route);
    // console.log(this.onFullPathHandler(id), id);
    this.setState({
      curBtn: id,
      curBtnPath: this.onFullPathHandler(id),
    })
  }

  onNewBtn = data => {
    const menuDatas = { ...this.state.menuDatas }
    const route = translitterate('ru').transform(data).toLowerCase()
    menuDatas[this.state.current].push({
      title: data,
      route,
      icon: '',
      html: 'sample text',
    })
    this.setState({
      menuDatas,
      curBtn: route,
      isUpdated: true,
    })
  }

  onNewOpen = () => {
    this.setState({ isCreateOpen: !this.state.isCreateOpen })
  }

  onCreateChild = () => {
    if (!this.state.childName) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'tick',
        message: 'Insert not empty title',
      })
      return
    }
    const menuDatas = { ...this.state.menuDatas }
    menuDatas[this.state.childName] = []
    this.setState({
      isCreateOpen: false,
      menuDatas,
      current: this.state.childName,
      isUpdated: true,
    })
  }
  /*
	Выбираем текущую менюху и запускаем вглубь рекурсию
	ищем route или target_id равное route.
	Чтобы когда найдем, сделать над ним handler с аргументами data
	*/
  changeMenuData(data, handler, route, currentMenu) {
    const menuDatas = { ...this.state.menuDatas }
    const current = currentMenu ? currentMenu : this.state.current
    menuDatas[current].forEach((menuElement, i) => {
      this.changeMenuElement(menuElement, route, data, handler)
    })
  }
  /*
	рекурсивно пробегаемся по всем узлам текущей менюхи, 
	если сравниваемое значение равно значению route или target_id - изменяем его handler'ом, 
	если нет - то отправляем рекурсию детям (если они есть)
	*/
  changeMenuElement(menuElement, route, data, handler) {
    //if( route === menuElement.route )
    if (route === this.getRealRoute(menuElement)) {
      //console.log( route, this.getRealRoute(menuElement), menuElement )
      handler(menuElement, data, route)
      return true
    }
    if (Array.isArray(menuElement.children)) {
      menuElement.children.forEach((child, i) => {
        this.changeMenuElement(child, route, data, handler)
        return true
      })
    }
    return false
  }

  onChangeField = (data, route) => {
    //console.log(data, route)
    const menuDatas = { ...this.state.menuDatas }
    const handler = (element, data, route) => {
      element[data.title] = data.field
      //console.log( element, data, route )
    }
    menuDatas[this.state.current].forEach((menuElement, i) => {
      //console.log( route)
      this.changeMenuData(data, handler, route, this.state.current)

      // if (this.getRealRoute(e) == this.state.curBtn) {
      // 	e[data.title] = data.field
      // }
    })
    //console.log(menuDatas);
    const state = {
      menuDatas,
      isUpdated: true,
    }
    if (data.title === 'route') state.curBtn = data.field
    this.setState(state)
  }

  setContentType = (data, route) => {
    const menuDatas = { ...this.state.menuDatas }
    const handler = (element, data, route) => {
      delete element.component
      delete element.singled_data_type
      delete element.external_settings
      delete element.single_data_type
      delete element.feed_data_type
      delete element.feed_data_type_parent_id
      delete element.feed_containerClassName
      delete element.data_type
      delete element.redirect
      delete element.html_source
      delete element.html
      delete element.icon
      delete element.help_url
      delete element.folder

      element[data] = '--'
      //console.log( element, data, route )
    }
    this.changeMenuData(data, handler, route, this.state.current)
    //console.log( data, route, menuDatas )
    const state = {
      menuDatas,
      isUpdated: true,
    }
    this.setState(state)
  }

  deleteRoute = (data, route) => {
    const menuDatas = { ...this.state.menuDatas }
    const handler = (element, data, route) => {
      if (Array.isArray(element)) {
        element.forEach((el, i) => {
          if (this.getRealRoute(el) == data) {
            element.splice(i, 1)
            return
          }
          if (Array.isArray(el.children)) {
            handler(el, data, route)
          }
        })
      }
      if (Array.isArray(element.children)) {
        handler(element.children, data, route)
      }
    }
    handler(menuDatas[this.state.current], data, route)
    const state = {
      menuDatas,
      isUpdated: true,
    }
    this.setState(state)
  }
  onAddChild = (title, route) => {
    const menuDatas = { ...this.state.menuDatas }
    const curBtn = this.state.curBtn
    console.log(title, curBtn)
    console.log(menuDatas[this.state.current])
    const handler = (element, data, route) => {
      if (!element.children) {
        element.children = []
      }
      element.children.push({
        title: data,
        route: data,
        icon: '',
        html: 'sample text',
        capability: [],
        is_left: 0,
        islogged: 0,
        description: '',
      })
    }
    this.changeMenuData(title, handler, route, this.state.current)
    this.setState({
      isCreateOpen: false,
      menuDatas,
      //current: title,
      isUpdated: true,
    })
  }

  onSaveMenus = () => {
    const changeMenu = gql`
      mutation changeMenu($input: MenuInput) {
        changeMenu(input: $input)
      }
    `
    const json = { json: JSON.stringify(this.state.menuDatas) }
    console.log(json)
    this.props.client.mutate({
      mutation: changeMenu,
      variables: { input: json },
      update: (store, { data: { changeMenu } }) => {
        this.setState({
          isUpdated: false,
        })
        AppToaster.show({
          intent: Intent.SUCCESS,
          icon: 'tick',
          message: 'Success saved menus',
        })
      },
    })
    // console.log(JSON.stringify(this.state.menuDatas))
  }
}

export default compose(withApollo)(AdminMenuView)
