import React from 'react'
import PropTypes from 'prop-types'

const Select = props => {
    const onChange = evt =>
    {
        const value = evt.currentTarget.value
        props.onChange(value)
    }
    const values = Array.isArray(props.values)
        ?
        props.values.map((value, i) =>
            {
                if(value?._id)
                {
                    return <option 
                        _key={value._id + "_" + i}  
                        key={value._id + "_" +  i} 
                        value={value._id}
                    >
                        {value.title}
                    </option>
                }
                else if( typeof value === "string")
                {
                    return <option _key={value + i}  key={value + i} value={value }>
                        { value }
                    </option>
                }
            })
        :
        []
    return (
        <select className='form-control dark input' value={ props.value || -1 } onChange={ onChange }>
            <option value="-1">---</option>
            { values }
        </select>
    )
}

Select.propTypes = {}

export default Select