import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FieldInput from 'react-pe-scalars/dist'
import { Button, ButtonGroup, Callout, Dialog, Icon, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import MailstoneAttachForm, { types } from './MailstoneAttachForm'
import { useEffect } from 'react'
import _fetch from '../wpfestREST'
import WPFestSettings from '../wpfestREST/utilities/WPFestSettings'
import YoutubeVideoPlayer from 'states/scalars/YoutubeVideoPlayer'
import VKVideoPlayer from 'states/scalars/VKVideoPlayer'

const MilestoneIncludeList = props => {
    const [attachments, setAttachments] = useState( Array.isArray(props.data?.attachments) ? props.data?.attachments : [] )
    const [trashOpen, setTrashOpen] = useState(false)
    const [trashedID, setTrashedID] = useState(-1)
    useEffect(() => {
        // получаем вложения с сервера и добавляем пока не отправленные туда поля. 
        const a = [ props.data ? props.data.attachments : [] ].concat(attachments.filter(at => {
                        return !at.ID && props.data?.ID === at.milestone_id
                    }))
        //setAttachments( a )
        setAttachments( props.data?.attachments || [] )
    }, [props.data?.attachments])
    
    const onInsertNew = type =>
    {
        const attachs = [...attachments, { type, data: "", descr:"", unixtime: Date.now(), milestone_id: props.data?.ID }] 
        setAttachments( attachs )
        // props.onEditFormToggle( true )
    }
    const onChange = (value, field, i) =>
    {        
        const attachs = [ ...attachments ]
        attachs[ i ].data = value   
        setAttachments( attachs )
        //console.log( [...attachments] )
    }
    const onAdd = i =>
    {
        const attachment = attachments[i];
        const festid = props.festid
        const member_id = props.memberId
        const milestone_id = props.data?.ID
        const type = attachment.type
        const data = attachment.data
        const descr = attachment.descr
        const unixtime = attachment.unixtime
        _fetch(
            "get_fest_command",
            {              
              festId: festid,
              command: "insert_member_milestone_data",
              member_id,
              milestone_id,
              data,
              type,
              descr,
              unixtime   
            },
            WPFestSettings.serviceURL,
            WPFestSettings.getToken(),
            "wpfa",
        )
            .then(( _data ) => {
                console.log( _data.data.data );
                const attachs = [ ...attachments ]
                attachs[ i ] = _data.data.data   
                setAttachments( attachs )
            })
    }
    const onTrash = i =>
    {     
        const attachs = [ ...attachments ]
        if(attachs[i].data)
        {
            setTrashOpen(true)
            setTrashedID( i )
        }
        else
        {
            onTrashFinnaly(i)
        }
       
    }
    const onTrashFinnaly = i =>
    {  
        const attachs = [ ...attachments ]
        const id = attachs[i].ID
        attachs.splice(i, 1)
        setAttachments( attachs ) 
        setTrashOpen(false)
        //
        _fetch(
            "get_fest_command",
            {              
              festId: props.festid,
              command: "delete_member_milestone_data",
              id   
            },
            WPFestSettings.serviceURL,
            WPFestSettings.getToken(),
            "wpfa",
        )
            .then(( _data ) => {
                //console.log( _data.data );
            })
    }
    const isNew = i =>
    {
        return !attachments[i].ID
    }
    //console.log( "attachments:", attachments )
    const _attachments = attachments.length > 0
        ?
        <div className='w-100 max-width-560'>
            {
                attachments.map((att, i) =>
                { 
                    //console.log("att:", att)
                    return att 
                        ?
                        <span 
                            style={{ maxWidth: 580, width:"100%", display:"flex" }} 
                            key={ att.ID }
                        >
                            <MilstoneFieldInput
                                {...props}
                                att={att}
                                editable={ props.isEditForms || isNew(i) }
                                onChange={(value, field) => onChange(value, field, i)}
                            />
                            {
                                isNew(i) 
                                    ? 
                                    <Button minimal intent={Intent.SUCCESS} onClick={() => onAdd(i)}> 
                                        {__("Add")}
                                    </Button>
                                    : 
                                    null 
                            }
                            {
                                props.is_owner 
                                    ?
                                    <Button 
                                        minimal 
                                        intent={Intent.DANGER}                            
                                        onClick={() => onTrash( i )}
                                    >
                                        <Icon icon="trash" className="opacity_25"/>
                                    </Button>
                                    :
                                    null
                            }
                            
                        </span>
                        :
                        null
                })
            }
            {
                props.is_owner ? <MailstoneAttachForm onInsertNew={onInsertNew} /> : null
            }
        </div>        
        :
        props.data?.is_attachments_required
            ?
            <Callout intent={Intent.DANGER} className="p-4 ">
                <div className='text-center text-uppercase'>
                    {__("Attachments must be registered to work on the stage")}
                </div>                
                {
                    props.is_owner ? <MailstoneAttachForm onInsertNew={onInsertNew} /> : null
                }
            </Callout>
            :
            props.is_owner ? <MailstoneAttachForm onInsertNew={onInsertNew} /> : null
    return (
        <div className='d-flex justify-content-center flex-column align-items-center '>
            { props.isEditForms  }
            { _attachments }
            <Dialog
                isOpen={ trashOpen }
                onClose={() => setTrashOpen(false)}
                className="little flex-centered"
            >
                <div className='p-5'>
                    <div className='pb-5'>
                        {__("Delete attachment?")}
                    </div>
                    <ButtonGroup >
                        <Button fill intent={Intent.SUCCESS} onClick={() => onTrashFinnaly( trashedID )}>
                            {__("Delete")}
                        </Button>
                        <Button intent={Intent.DANGER} onClick={() => setTrashOpen(false)}>
                            <Icon icon="cross" />
                        </Button>
                    </ButtonGroup>
                </div>
            </Dialog>
        </div>
    )
}

MilestoneIncludeList.propTypes = {}

export default MilestoneIncludeList

const MilstoneFieldInput = props =>
{
    switch( props.att.type )
    {
        case "youtube":
            return <YoutubeVideoPlayer
                width={ "100%" }
                height={ 110 } 
                video_id={ props.att.data }
                editable={ props.editable }
                onChange={ props.onChange }
            /> 
        case "vk-video":
            return <VKVideoPlayer
                width={ "100%" }
                height={ 360 } 
                editable={ props.editable }
                value={ props.att.data }
                onChange={ props.onChange }
            />
        default:
            return <FieldInput
                vertical
                width={ "100%" }
                height={ 110 }
                value={ props.att.data }
                values={ [] }
                editable={ props.editable }
                type={ props.att.type }
                on={ props.onChange }
                onChange={ props.onChange }
            /> 
    }
    
}