import React, { useEffect, useState } from 'react';  
import { Button, Dialog, Intent } from "@blueprintjs/core"
import { __ } from 'react-pe-utilities/dist'; 
import QRCodeSign from './QRCodeSign'; 
import md5 from 'md5'; 
import InputForm from './InputForm'; 

const QR = props => {
    const id = md5( props.options.text )
    const [isEnabled, changeEnabled] = useState(props.checked) 
    const [isEditOpen, onEditOpen] = useState(false)
    const [isDeleteOpen, onDeleteOpen] = useState(false)
    const [options, setOptions] = useState( props.options )
    useEffect(() => {
        let options = props.options
        options.logo = options.thumbnail
        options.logo_id = options.thumbnail_id
        options.logo_name = options.thumbnail_name
        setOptions( options )
        //console.log(options)
    }, [props] )

    const handleEnabledChange = () =>
    {
        changeEnabled(!isEnabled)
        if(props.onChecked)
        {
            props.onChecked( props.options.id )
        }
    }


    const qrcodeSign = <QRCodeSign {...props} options={options} id= {id} />
    const publish_text = props.options.publish_text 
        ?
        <div 
            className="position-absolute text-left" 
            style={{
                left:0, 
                top: props.options.height + props.options.titleTop + props.options.quietZone * 2,
                fontSize: 16, //props.options.titleHeight,
                padding:props.options.quietZone,
                width:"100%",
                wordBreak: "break-all"
            }}
        >
            { props.options.text }
        </div>
        :
        null

    const downloadSVG = () =>
    {
        const format = props.format ? props.format : "svg"
        const cont = document.getElementById( id ) 
        const svg =  cont.innerHTML 
        const blob = new Blob( [ svg.toString() ] );
        const element = document.createElement( "a" );
        element.download = props.options.post_title 
            ? 
            props.options.post_title + "." + format 
            : 
            props.options.text + "." + format;
        element.href = window.URL.createObjectURL(blob);
        element.click();
        element.remove();
    }
    const onUpdate = () =>
    {
        onEditOpen( false )
        if(props.onUpdate)
        {
            props.onUpdate(options, props.i)
        }
    }
    const onSettingChange = (val, field, dopol, dopol1, dopol2) =>
    {
        console.log( val, field, dopol, dopol1, dopol2 )
        const op = {...options || {} }
        op[field] = val
        if(field === "logo")
        {
            op.logo_id = dopol2.logo_id
            op.logo_name = dopol2.logo_name
            op.thumbnail = val
            op.thumbnail_name = dopol2.logo_name
            op.thumbnail_id = dopol2.logo_id
        }
        setOptions( op )
    }
    const onRemove = () =>
    {
        onDeleteOpen( !isDeleteOpen )
    }
    const onFinalRemove = () =>
    { 
        if(props.delete)
        {
            props.delete( props.options.id )
        }
        onDeleteOpen( !isDeleteOpen )
    }
    const onSVG = () => {

    }
    const onPNG = () => {

    }
   
    const manipulators = props.isManipulators
        ?
        <div className="d-flex py-1">
            <Button
              minimal
              className="mr-0"
              icon="edit"
              title={__("Edit")}
              onClick={() => onEditOpen( !isEditOpen )}
            />
            <Button
              minimal
              className="mr-0"
              icon="import"
              title={__("Download")}
              onClick={downloadSVG}
            />
            <Button
              minimal
              className="mr-0"
              icon="cross"
              title={__("Remove")}
              onClick={onRemove}
            />
            <Button
              minimal={false}
              intent={!isEnabled ? Intent.NONE : Intent.SUCCESS}
              className="mr-0"
              icon={!isEnabled ? "blank" : "tick"}
              title={__(isEnabled ? "Uncheck" : "Check")}
              onClick={handleEnabledChange}
            />
            <Button
              minimal
              className="mr-0 px-0 hidden" 
              onClick={onSVG}
            >
                <small>svg</small>
            </Button>
            <Button
              minimal
              className="mr-0 px-0 hidden"  
              onClick={onPNG}
            >
                <small>png</small>
            </Button>
            <Dialog
                title={__("Edit QR-code")}
                isOpen={isEditOpen}
                onClose={ () => onEditOpen( !isEditOpen )}
            >
                <div className='px-4 pt-4'>
                    <InputForm
                        value={{ 
                            ...props.options             
                        }}
                        isSetting={false}
                        on={ onSettingChange}
                        onChange={ onSettingChange}
                    /> 
                </div>
                <div className="bp3-dialog-footer p-4 m-0">
                    <Button intent={Intent.SUCCESS} onClick={onUpdate}>
                        {__("Edit")}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                title={__("Delete QR-code")}
                className="little"
                isOpen={isDeleteOpen}
                onClose={ () => onDeleteOpen( !isDeleteOpen )}
            >
                <div className='px-4 pt-4'>
                    {__("Are you realy want finally remove this QR-code?")}
                </div>
                <div className="bp3-dialog-footer p-4 m-0">
                    <Button intent={Intent.DANGER} onClick={onFinalRemove}>
                        {__("Remove QR-code")}
                    </Button>
                </div>
            </Dialog>
        </div>
        :
        null 
    // console.log(props.options)
    return <div 
        className="p-2 position-relative pe-qr-container"
        qr-id={props.options.id}
        style={{
            width: props.options && props.options.width && props.options.quietZone 
                ? 
                props.options.width + props.options.quietZone * 2 + 10 
                : 
                200,
            height: props.options && props.options.width && props.options.quietZone  && props.options.titleHeight   
                ? 
                props.options.height + props.options.quietZone * 2 + props.options.titleHeight + 40 
                : 
                300,
        }}
    > 
        {manipulators}
        <div className="position-relative"> 
            {qrcodeSign}
            {publish_text}
        </div>
    </div>
}

export default QR;
