import React, { Component } from "react"
import WebFont from "webfontloader"
import googleFontList from "../../views/LandingState/data/google_fonts.list.json"
import { __ } from "react-pe-utilities" 

class GoogleFontSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

  componentWillUpdate(newProps, nextState) {
    if (newProps.languages != this.state.languages) {
      this.setState({ languages: newProps.languages })
    }
  }

  getList() {
    const { languages } = this.state
    const list = googleFontList.items
      .filter((e) => {
        if (Array.isArray(languages) && languages.length > 0) {
          return languages.filter((ee) => e.subsets.filter((subset) => subset == ee).length > 0).length > 0
        }
        return true
      })
      .map((e, i) => (
        <option
          key={i}
          value={e.family}
        >
          { e.family }
        </option>
      ))
    const current_number = [
      "Title font",
      "Subtitle font",
      "3 font",
      "4 font",
      "5 font",
      "6 font",
      "7 font",
      "8 font",
      "9 font",
      "10 font",
    ][this.props.i]
    const current = this.state.value ? googleFontList.items.filter((e) => e.family == this.state.value.title)[0] : null
    const vars = current
      ? current.variants.map((e, i) => (
        <div key={i} className="py-0">
          <label className="_check_">
            <input type="checkbox" value={e} />
            {e}
          </label>
        </div>
      ))
      : null

    return (
      <div className="row">
        <div className="col-md-5 d-flex align-items-end">
          {__(current_number)}
        </div>
        <div className="col-md-7">
          <select
            className="form-control input dark mb-2"
            value={this.state.value ? this.state.value.title : ""}
            onChange={this.onChange}
          >
            <option value="">--</option>
            { list }
          </select>
        </div>
        <div className="col-md-12">
          <div
            style={{
              fontSize: "1.5rem",
              fontFamily: this.state.value ? this.state.value.title : null,
              opacity: this.state.value ? 1 : 0.25,
            }}
          >
            { __("Lorem ipsum") }
          </div>
        </div>
        <div className="col-md-12 d-flex flex-wrap hidden">
          {vars}
        </div>
      </div>
    )
  }

    onChange = (evt) => {
      const v = evt.currentTarget.value
      WebFont.load({
        google: {
          families: [v],
        },
      })
      this.setState({
        value: {
          ...this.state.value,
          title: v,
        },
      })
      setTimeout(() => {
        if (this.props.onChange) {
          this.props.onChange(v)
        }
      }, 100)
    }

    render() {
      return (
        <div>
          { this.getList() }
        </div>
      )
    }
}
export default GoogleFontSingle
