import React from "react"
import ReactDOM from "react-dom"
import { AppToaster } from 'react-pe-useful'
import $ from "jquery"
import { __ } from "react-pe-utilities"   
import DataContext from "./DataContext"
import Section from "./Section"
import SectionContent from "./SectionContent"
import { Intent } from "@blueprintjs/core"

class IncludeSection extends SectionContent { 
  is() {
    //const { section } = this.state.data
    return true
  }
  getState()
  {
    this.ref = React.createRef()
    this.el = document.createElement('span');
    return {}
  }
  onClipboardCopyHandle = (data) => {
    //console.log(data)
    $("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='myInput'>${DataContext.getSectionJSON(data.id)}</textarea></div>`)
		const copyText = document.getElementById("myInput")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#myInput").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Section copy to clipbord"),
		})
  }
  onClipboardPasteHandle = (i) => {
    const {section } = this.state.data
    navigator.clipboard.readText()
        .then((clipText) => {
            try 
            { 
                const sect = DataContext.setSectionJSON(clipText);
                this.setState({
                    section : [...section].push(sect)
                })
            } 
            catch (e) 
            {
                AppToaster.show({
                    intent: Intent.DANGER,
                    icon: "tick",
                    duration: 10000,
                    message: __("Error read clipboard data"),
                })
            }
        })
  }
  componentDidMount() { 
    //console.log(this.props.modal)
    //console.log( this.el )
    if(this.props.modal)
    {
        this.props.modal.appendChild(this.el)
    } 
    
  }

  componentWillUnmount() {
    if(this.props.modal)
    {
        this.props.modal.removeChild(this.el)
    } 
  }
  renderContent(style)
  {
    //console.log(style)
    const {palette} = this.props;
    const {section, text_id, width, align } = this.state.data
    const secs = Array.isArray(section)
        ?
        section.map((sec, i) =>
        {
            return <Section
                key={i}
                {...sec}
                style={{ height: "100%", ...this.getStyle(sec.style) }}
                i={i}
                user={this.props.user}
                is_edit={false}
                level={this.props.level + 1}
                onClipboardCopy={this.onClipboardCopyHandle}
                onClipboardPaste={this.onClipboardPasteHandle}
                // onEdit={this.onEdit}
                // onUp={this.onUp}
                // onDn={this.onDn}
                // onAdd={this.onAdd}
                // onRnv={this.onRnv}
                palette={palette}
                current_template_id={palette.id}
            />
        })
        :
        null

    return ReactDOM.createPortal(
        <div 
            className="landing-include-section" 
            id={"section-" + text_id}
            ref={this.ref}
            style={{
                margin: 20,
                marginLeft :   align == "left"     ? 0     : 30,
                marginRight :  align == "right"    ? 0     : 30,
                ...style,
                float: align,
                width
            }}
        >
            {secs}        
        </div>,
        this.el
        );  
  }
}
export default IncludeSection