import React from "react"
//import matrix from "./matrix"
import FieldInput from "react-pe-scalars"
import { useState } from "react"
import QR from "./QR"
import { getFields } from "react-pe-layouts"

const InputForm = props =>
{
    const matrix = getFields("PEQRCode")
    const [value, setValue] = useState( props.value )
    const on = (val, field, dopol, dopol1, dopol2) =>
    {
        if(props.on)
        {
            props.on(val, field, dopol, dopol1, dopol2)
            let _value = {...value}
            _value[field] = val;
            setValue( _value )
        }
    }
    const fields = Object.keys(matrix).map((field, i) => {
        const matrixField = matrix[field]
        if( matrixField.is_setting !== props.isSetting || matrixField.hidden )
            return null
        //console.log(field, matrixField)   
        // если имеет родителя (SubMenu)     
        if(matrixField.parent)   return null

        const childCells = Object.keys(matrix)
            .filter( cell => matrix[cell].parent && matrix[cell].parent === field )
            .map((cell, icell) => { 
                return  {
                    ...matrix[cell],
                    field : cell,
                    i: icell,
                    value: value[cell] ? value[cell] : matrix[cell].default,							
                    origin: props 
                }
            }) 

        const val = value[field]
            ?
            value[field]
            :
            matrixField.default
        return <FieldInput
            key={ i }
            value={ val }
            {...matrixField}
            field={field}
            origin={props}
            type={matrixField.type}
            title={matrixField.title} 
            vertical={false} 
            visualization={matrixField.visualization}
            visualizationForm={matrixField.visualizationForm} 
            on={ (value, dopol, dopol1, dopol2) => on(value, field, dopol, dopol1, dopol2) }
            onChange={ (value, dopol, dopol1, dopol2) => on(value, field, dopol, dopol1, dopol2) }
            sub_menus={ childCells }
            onSubMenu={(value, dopol, dopol1, dopol2) => this.on(value, dopol, dopol1, dopol2)}
        />
    })
    const example = props.isExample
        ?
        <div className="d-flex justify-content-center p-4">
            <QR
                options={value}
            />
        </div>
        :
        null
    return <>
        {fields}
        {example}
    </>
}

export default InputForm