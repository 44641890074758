import React, { Component } from "react" 
import matrix from "../data/matrix"
import FieldInput from "react-pe-scalars"

class InputPosition extends Component {
	state = {
	  ...this.props,
	}

	render() {
	  const { x } = this.state.position.mc
	  return (
  <div className="landing-position">
    <FieldInput
      field="x"
      key="x"
      {...matrix().Position}
      visualization="landing_object"
      type="landing_object"
      landing_object="Position"
      on={this.on}
      onChange={this.on}
      editable
      value={x}
      vertical={false}
    />
  </div>
	  )
	}

	on = (field, value) => {

	}
}
export default InputPosition
