import React, { Component } from "react"
import rgbHex from "rgb-hex"
import ExternalLandingColor from "../landingObject/ExternalLandingColor"

class CSSColor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

  render() {
    return <div className="d-flex w-100 ">
        <ExternalLandingColor
          value={ this.state.value }
          palette={this.state.palette}
          origin={{current_template_id : this.state.palette ? this.state.palette.id : 0}}
          distination={this.state.distination}
          onChange={this.onExternal}
        />
      </div> 
  }

  // render() {
  //   return <div className="d-flex w-100 ">
  //       <ColorPicker color={this.state.value} onChoose={this.onColor} />
  //       <input
  //         type="string"
  //         className="form-control input dark"
  //         value={this.state.value || ""}
  //         onChange={this.onChange}
  //       />
  //       { this.props.source }
  //     </div> 
  // }
  onExternal = value =>
  {
    this.setState({ value })
	  this.on( value )
  }
	onChange = (evt) => {
	  this.setState({ value: evt.currentTarget.value })
	  this.on(evt.currentTarget.value)
	}

	onColor = (color) => {
	  const value = color.rgb.a == 1.0
	    ? color.hex
	    : color.rgb.a == 0.0
	      ?				"transparent"
	      :				`#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)}`
	  this.setState({ value })
	  this.on(value)
	}

	on = (value) => {
	  this.props.on(value, this.props.field, this.props.title)
	}
}
export default CSSColor
