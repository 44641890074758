import React from "react"
import BasicState from "react-pe-basic-view/dist"

class PEVKPostView extends BasicState
{
    andRender()
    {
        return null
    }
}
export default PEVKPostView