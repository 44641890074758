import React from "react"
import BasicState from "react-pe-basic-view/dist";

class PEVKPostFeedComponent extends BasicState
{

    addRender()
    {
        return
    }
}
export default PEVKPostFeedComponent