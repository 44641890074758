import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import ScreenSizeList, { getScreenSize } from 'modules/pe-landing-module/widgets/utilities/ScreenSizeList'
import { useState } from 'react'
import { useEffect } from 'react'

const LandingResizePanel = props => 
{
    const [screenSize, setScreenSize] = useState( getScreenSize( document.body.clientWidth ) )
    const updateWindowDimensions = () => { 
        setScreenSize( getScreenSize( document.body.clientWidth ) ) 
    }
    useEffect(() => {
        window.addEventListener("resize", updateWindowDimensions)
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [])
    const doResize = size =>
    {
        setScreenSize(size)
        if(props.onResizeClick) 
            props.onResizeClick(size)
    }
    const panel = ScreenSizeList( ).map(size => {
        return <Button
            key={size.id}
            className="hint hint--bottom"
            data-hint={__(size.title)}
            disabled={false}
            minimal={screenSize != size.id}
            onClick={ () => doResize( size.id ) }
        >
            <span className={`${size.icon} fa-1x `} /> 
        </Button>
    })
    return <div className=" h-100 d-flex align-items-center ">
        {panel}
    </div>
}

LandingResizePanel.propTypes = {}

export default LandingResizePanel