import { Button } from "@blueprintjs/core"
import React, { useEffect, useState } from "react" 
import Layouts, { updateLayouts } from "react-pe-layouts"
import { __ } from "react-pe-utilities/dist"
import { useLocation, useParams } from "react-router"
import FolderSign from "./FolderSign"

const Folders = props =>
{
    const location = useLocation()  
    const [folders, setFolders] = useState( props.folders )  
    useEffect(()=>
    {
        setFolders( props.folders )

    }, [ props.folders ])
    const currentRoute = location.pathname.replace("/" + props.curRoute + "/folder/", "").split("/")[props.level]
    const onSelect = id =>
    {
        let _folders= [...folders]
        _folders = _folders.map(folder =>
        {
            return { 
                ...folder,
                isSelect:  folder.id === id
            }
        })
        setFolders( _folders )
        //setMainSelect(id === -1)
        props.onSelect(id)
    }
    const onHide = id =>
    {
        let _folders= [...folders]
        _folders = _folders.map(folder =>
        {
            return { 
                ...folder,
                is_hidden:  folder.id === id
            }
        })
        setFolders( _folders )
        props.onHide(id)
    }
    const onDelete = id =>
    {
        let _folders= [...folders]
        _folders = _folders.filter(folder =>
            {
                return folder.id !== id
            })
            setFolders( _folders )
            props.onDelete(_folders )
    }
    const onEditFolder = (id, data) =>
    {
        let _folders= [...folders]
        _folders = _folders.map(folder =>
            {
                if(folder.id === id)
                {                    
                    console.log( folder, data, {...folder, ...data} )
                    return {...folder, ...data}
                }
                else
                {
                    return folder 
                }
            })
        setFolders( _folders )
        console.log( id, data, _folders )
        props.changeFolders({ ...Layouts().folders, folders: _folders }) 
    }
    const flds = [ 
        <FolderSign 
            key={ -1 } 
            level={ 1 }
            onSelect={ onSelect }
            //isSelect={ Array.isArray(folders) ? folders.filter(f => f.isSelect).length === 0 : null }
            isSelect={ currentRoute == -1  || !currentRoute }
            isHideHide
            id={-1}
            icon="more"
            title=""
        />,
        Array.isArray(folders)
            ?
            folders.map( folder => <FolderSign 
                key={ folder.id } 
                { ...folder } 
                curRoute={ props.curRoute }
                //curRoute={ currentRoute }
                level={ 1 }
                onSelect={ onSelect }
                //isSelect={ folder.isSelect }
                isSelect={ folder.id == currentRoute }
                onHide={ onHide }
                isHide={folder.is_hidden}
                onDelete={onDelete}
                onEditFolder={onEditFolder}
            /> )
            :
            []
    ]
    const addFolder = () =>
    {
        const nextId = props.max_id + 1
        const newFolder = {
            id: nextId,
            title: `folder ${nextId}`,
            level: props.level,
            is_hidden:false
        }
        const _folders = [...folders, newFolder]
        setFolders(_folders)
        props.changeFolders({ max_id: nextId, folders: _folders }) 
    }
    const openAll = () =>
    {

    }
    const closeAll =() =>
    {

    }
    return <div>
        <div className="p-2 d-flex">
           <Button 
                minimal
                icon="plus"
                className="hint hint--top d-flex justify-content-center "
                data-hint={__("Add folder")}
                onClick={addFolder}
            /> 
           <Button 
                minimal 
                icon="folder-shared-open"
                onClick={openAll}
                className="hidden"
            >
                {__("Open all")}
            </Button> 
           <Button 
                minimal 
                icon="small-cross"
                onClick={closeAll}
                className="hidden"
            >
                {__("Close all")}
            </Button> 
        </div>
        {flds}
        
    </div>
}
export default Folders