import { Button, ButtonGroup, Dialog, Popover, Tag } from "@blueprintjs/core"
import React, { useState } from "react"
import { __ } from "react-pe-utilities"
import DataContext from "../../DataContext"
import InputForm from "../InputForm"

const CardsTagsEditor = props => {
    const [allTags, setAllTags] = useState(
        Array.isArray(DataContext.data.landing.card_tags)
            ?
            DataContext.data.landing.card_tags
            :
            []
    )
    const [tags, setTags] = useState(
        Array.isArray(props.card_tags)
            ?
            props.card_tags
            :
            []
    )
    const [isOpen, onOpen] = useState(false)
    const [newTag, onNewTag] = useState({})
    const onRemove = (evt, tag) => {

    }
    const card_tags = tags.length > 0
        ?
        tags.map((tag, i) => {
            //console.log(tag)
            return <Tag
                style={{
                    color: tag.color ? tag.color : "#444",
                    backgroundColor: "#FFF",
                    fontWeight: 900,
                    padding: "2px 10px"
                }}
                className="pointer text-uppercase"
                // round
                minimal
                //onRemove={evt => onRemove(evt, tag)}
                key={i}
            >
                {__(tag.title)}
            </Tag>
        })
        :
        <div className="p-1 w-100 small opacity_75 fst-italic ">
            {__("No cards tags")}
        </div>
    const onAddTag = (evt, i) => {
        if (tags.filter(e => e === allTags[i]).length === 0) {
            tags.push(allTags[i])
            //console.log(tags)
            // setTags(tags)
            if(props.onCardTags)
                props.onCardTags( tags )
        }

    }
    const handleShowTag = (data, foo) => {
        console.log(data, foo)
    }
    return <div className="d-flex p-1" style={{ width: 477 }}>
        <div className="p-0 w-100 ">
            {card_tags}
        </div>
        <Popover
            content={
                <div className="p-0 square overflow-y-auto" style={{ maxHeight: 400 }}>
                    <ButtonGroup className="p-0">
                        {
                            allTags.map((tag, i) => {
                                return <Button
                                    key={i}
                                    style={{ backgroundColor: tag.color }}
                                    minimal
                                    fill
                                    large
                                    onClick={evt => onAddTag(evt, i)}
                                >
                                    {tag.title}
                                </Button>
                            })
                        }
                    </ButtonGroup>
                </div>
            }
        >
            <Button minimal icon="plus" className=" ml-auto" data-hint={__("Add new card's tag")} />
        </Popover>
        <Dialog
            isOpen={isOpen}
            onClose={() => onOpen(false)}
            className="little2"
            title={__("Insert new card's tag")}
        >
            <div className="p-4">
                <InputForm
                    source="card_tag"
                    data={newTag}
                    on={handleShowTag}
                />
            </div>
        </Dialog>
    </div>
}
export default CardsTagsEditor;