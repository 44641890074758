import React, { Component } from "react" 
import { __ } from "react-pe-utilities" 
import PaletteSingleForm from "./PaletteSingleForm"  
import { sprintf } from "react-pe-utilities"
import PalettePresets from "../../views/LandingState/data/PalettePresets"
import {
  Button, Callout, Classes, Dialog, Intent, 
} from "@blueprintjs/core" 


class PaletteForm extends Component {
  constructor(props) {
    super(props)
    this.car = React.createRef() 
    this.state = {
      ...props,
      presets: PalettePresets().map((e, i) => {
        if (Array.isArray(props.value) && props.value.filter((ee) => ee.id === e.id).length > 0) {
          e.checked = true
        }
        return e
      }),
    }
  }

  render() {
    // console.log( this.state.value )
    // const options = {
    //   loop: true,
    //   dots: false,
    //   margin: 3,
    //   nav: false,
    //   items: 1,
    // }
    // const events = {}
    const __sections = this.state.presets.map((e, i) => (
      <div className="col-md-6 py-4 " key={i}>
        <PaletteSingleForm
          e={e} 
          i={i}
          id={e.id}
          isEdit
          onCheck={(evt) => this.onCheck(evt, e)}
        />
      </div>
    ))
    const value = Array.isArray(this.state.value) ? this.state.value : []
    const checkeds = value.map((e, i) => <div className="py-4" key={i}>
        <PaletteSingleForm
          e={e} 
          i={i}
          isEdit={false}
          onCheck={(evt) => this.onCheck(evt, e)}
          onEdit={this.onEdit}
          onDelete={  this.onDelete }
        />
      </div>
      )
    return (
      <div className="w-100 min-width-100">
       
        <div className=" pt-3">
          <Callout className="" intent={Intent.WARNING}>
            {__("You can select one or more style templates and edit them. Any Section can be styled for any of the working templates. This is done in the «Template» tab of the Sections pane.")}
            <Button onClick={this.onOpen} fill large className="mt-3" intent={Intent.WARNING} minimal>
              {__("Choose presets")}
            </Button>
          </Callout>
        </div>
        {checkeds}

        <Dialog
          isOpen={this.state.isOpen}
          onClose={this.onOpen}
          className="full" //"landing-outer-container"
          title={__("Choose presets")}
        >
          <div className={Classes.DIALOG_BODY + " overflow-y-auto mx-0"}>
            <div className="p-5">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-2">

                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      {__sections}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER + " p-4 align-items-center d-flex justify-content-end"}>
            <div className="title lead">
              {sprintf(__("Selected: %s Palettes"), value.length)}
            </div>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.onOpen}>
                {__("close")}
              </Button>
            </div>
          </div>
        </Dialog >
      </div >
    )
  }

  onDelete = (i) => {
    console.log(i)
    const value = [...this.state.value]
    value.splice(i, 1)
    this.setState({ value })
    setTimeout(() => {
      if (this.props.on) this.props.on(value)
    }, 100)
  }

  onEdit = (element, field) => {
    let value = [...this.state.value]
    value = value.map((e, i) => {
      if (e.id === element.id) {
        e = element
      }
      return e
    })
    console.log(element)
    console.log(value)
    this.setState({ value })
    setTimeout(() => {
      if (this.props.on) this.props.on(value)
    }, 100)
  }

  onCheck = (evt, element) => {
    //const check = evt.currentTarget.checked
    const presets = [...this.state.presets]
    const value = Array.isArray(this.state.value) ? [...this.state.value] : []
    presets.forEach((preset, i) => {
      if (preset.id === element.id) 
      {
        if ( value.filter((preset) => preset.id === element.id).length > 0 ) 
        {
          value.splice(i, 1)
        } 
        else 
        {
          value.push(element)
        }
        presets[i].checked = !presets[i].checked
      }
    })
    this.setState({ presets, value })
    setTimeout(() => {
      if (this.props.on) this.props.on(value)
    }, 100)
  }

  onOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
}
export default PaletteForm
