import React, { useState, useEffect } from "react"  
import "../../../../assets/css/day-night.css"

const DayNightSwitcher = props =>
{
    const [isDay, setDay] = useState( props.isDay )
    useEffect(() => {
        //console.log( props.isDay )
        setDay( props.isDay )
    }, [props.isDay])
    
    const switchTheme = () =>
    {
        // setDay(!isDay)
        props.switchDayNight(!isDay)
    }
    return <div 
            className={`day-night-container ${ isDay ? "day" : "night" }`} 
            onClick={switchTheme}    
        >
            <div className="dnsw"/>
        </div> 
}
export default DayNightSwitcher