import React, { Component } from "react"
import { __ } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import { components } from "./data/components"

class OuterURL extends Component {
	state = {
	  ...this.props,
	}

	componentDidUpdate(nextProps) {
	  let isUpdate = false
	  const state = { };
	  ["is_edit", "data", "class_name", "type", "title"]
	    .forEach((e, i) => {
	      if (nextProps[e] != this.state[e]) {
	        isUpdate = true
	        state[e] = nextProps[e]
	      }
	    })
	  if (isUpdate) {
	    // console.log(state);
	    this.setState(state)
	  }
	}

	render() {
	  const { title, type } = this.state
	  const {
	    route, label, is_fill, class_name, style, is_target_blank, intent, border,
	  } = this.state.data
	  // console.log( style );
	  const __intent = intent || "danger"
	  const __border = border && border !== "none" ? `border border-${border}` : null
	  return route
	    ? (
  <div className="landing-outer-link  landing-section-core ">
    <a
      href={route}
      className={`btn btn-${__intent} ${__border} ${is_fill ? " btn-block " : ""}${class_name}`}
      target={is_target_blank ? "_blank" : "_self"}
      style={{ ...style }}
      rel="noreferrer"
    >
      { label || (title && title.text ? title.text : "") }
    </a>
  </div>
	    )
	    :			(
  <div
    className={` landing-empty ${class_name}`}
    style={{ ...style }}
  >
    <LayoutIcon
      src={components()[this.state.type].icon}
      className=" layout-icon white"
    />
    <div className="lead text-white">
      { __(components()[this.state.type].title) }
    </div>
  </div>
	    )
	}
}
export default OuterURL
