import React, { Component, Fragment } from "react"
import { getColor } from "./data/getColor"
import PalettePresets from "./data/PalettePresets"
import { getStyle } from "./Section"
import { getLandingContainer} from "../LandingView"

class SectionBackground extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      scroll: 0,
    }
    this.bgRef = React.createRef()
  }

  componentDidMount() {
    // console.log(this.props.background.effect)
    if( this.props.background.effect === "none" || !this.props.background.effect ) return;
    getLandingContainer().addEventListener("scroll", this.onscrollHandler)
  }

  componentWillUnmount() {
    if( this.props.background.effect === "none" || !this.props.background.effect ) return;
    getLandingContainer().removeEventListener("scroll", this.onscrollHandler)
  }
  onscrollHandler = evt =>
  {
    try 
    {
      this.setState({ scroll: getLandingContainer().scrollTop - this.bgRef.current.getBoundingClientRect().top})
    } 
    catch (e) 
    {
      // console.log("AAAAAA")
    }
  }


  componentDidUpdate( nextProps ) {
    if (nextProps.background !== this.state.background) {
      //console.log(nextProps.background)
      this.setState({ background: nextProps.background })
    }
    if (nextProps.palette !== this.state.palette) {
      //console.log(nextProps.palette.id, this.state.palette.id)
      this.setState({ palette: nextProps.palette })
    }

  }

  render() {
    const { background, palette, level } = this.state 
    const {
      effect,
      parallax_speed,
      parallax_y_offset,
      style,
      stack,
      image,
      color
    } = background
    
    //если секция вложенная и специальных свойств не указано - игнорировать
    if( level > 0 && palette.id === PalettePresets()[0].id && !color && !image )
      return null
    const img = image ? image : palette.background.tile
    const clr = color ? getColor(color, palette) : palette.background_color 
    const opacity = background.opacity
    const bgStyle = getStyle(style)

    let poffset, backgroundAttachment
    switch (effect) {
      case "vparallax":
        backgroundAttachment = "fixed"
        poffset = this.state.scroll * parallax_speed - (parallax_y_offset || 0)
        break
      case "fixed":
        backgroundAttachment = "fixed"
        poffset = this.state.scroll - (parallax_y_offset || 0)
        break
      default:
        backgroundAttachment = null
        poffset = 0
    }
    const size = background.size
      ?
      background.size
      :
      typeof palette.background.size == "string"
        ?
        "cover"
        :
        background.tile_size_px
          ?
          `${background.tile_size_px}px`
          :
          palette.background.size
            ?
            `${palette.background.size}px`
            :
            "200px"
    const tile_opacity = background.tile_opacity ? background.tile_opacity : palette.background.tileOpacity
    const tile_size_px = background.tile_size_px

    const st = {
      backgroundAttachment,
      backgroundImage: `url(${img})`,
      backgroundSize: size,
      backgroundRepeat: palette.repeat,
      backgroundPositionY: poffset,
      opacity: tile_opacity || 1,
      ...bgStyle,
    }
    if (tile_size_px) {
      st.backgroundSize = tile_size_px
    }

    const bck = <div
      className="landing-section__bg"
      style={{
        background: clr,
        opacity: opacity || 1,
      }}
    />
    const tile = <div
      ref={this.bgRef}
      className="landing-section_img_bg"
      style={st}
    />
    return stack === "image"
      ?
      <>
        {bck}
        {tile}
        <div className="display-1 text-danger position-absolute hidden">{size}</div>
      </>
      :
      <>
        {tile}
        {bck}
        <div className="display-1 text-danger position-absolute hidden">{size}</div>
      </>
  }

}
export default SectionBackground
