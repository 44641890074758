import React, {Fragment,Component} from "react"; 
import Categories from "./Categories";
import GanreForm from "./single/GanreForm";
import { Icon, Tag, Intent, Tooltip } from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";

export default class Ganres extends Categories
{
	type = "ganre"
	constructor(props)
	{
		super(props);
		this.init({
			form_component	: GanreForm,		
			meta			: {color: "#FFF", icon:"", icon_id:-1}
		})
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'order');
		rw.splice(1,0,["icon", <Tooltip intent={Intent.DANGER} content={__("Icon")}><Icon icon="grid-view" /></Tooltip>,100]);
		return rw;
	}
	
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			case "icon":
				txt = <div className="mycro_icon" style={{backgroundImage:"url("+elem.icon+")"}}/>
				break;
		}
		return txt;
	}
}