import React, { useEffect, useState } from "react"
import { Collapse } from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import SinglePluginView from "./SinglePluginView"

export default props => {
    const [isOpen, onOpen] = useState(props.isOpen)
    const onOpenHandler = () => {
        if (props.onOpen)
            props.onOpen(props.i)
    }
    useEffect(() => {
        onOpen(props.isOpen)
    })
    return <div className="w-100 card">
        <div
            className={"p-4 " + (isOpen ? "" : " bg-light-grey ")}
            
        >
            <div className="row">
                <div className="col-md-4  text-md-right d-flex flex-column ">
                    <div className="lead font-weight-bold " dangerouslySetInnerHTML={{ __html: props.title }} />
                    <div className="mt-auto">
                        <div className="btn btn-link  btn-sm text-secondary" onClick={onOpenHandler}>
                            {__(isOpen ? "Turn aside":"Prolix")} 
                            <i className={isOpen ? "fas fa-angle-up ml-2" : "fas fa-angle-down ml-2"}></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 " dangerouslySetInnerHTML={{ __html: props.description }} />
            </div>
        </div>
        <Collapse isOpen={isOpen} >
            <div className=" p-4">
                <div className="row ">
                    <div className="col-md-4 ">
                        <div className="lead mr-3   text-md-right">
                            {__("version")}:
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="">
                            {props.version}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="lead mr-3   text-md-right">
                            {__("Author:")}
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="">
                            {props.author}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="lead mr-3 text-md-right">
                            GIT:
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="">
                            {props.git}
                        </div>
                    </div>
                    {
                        props.views && Object.keys(props.views).length > 0
                            ?
                            <>
                                <div className="col-md-4 ">
                                    <div className="lead mt-5 text-md-right">
                                        {__("Views")}
                                    </div>
                                </div>
                                <div className="col-md-8 ">
                                    <div className="mt-4">
                                        {
                                            Object.keys(props.views).map((view, i) => <SinglePluginView {...props.views[view]} type={view} key={i} i={i} />)
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            null
                    }
                    {
                        props.widgets && Object.keys(props.widgets).length > 0
                            ?
                            <>
                                <div className="col-md-4 ">
                                    <div className="lead mt-5 text-md-right">
                                        {__("Widgets")}
                                    </div>
                                </div>
                                <div className="col-md-8 ">
                                    <div className="mt-4">
                                        {
                                            Object.keys(props.widgets).map((view, i) => <SinglePluginView {...props.widgets[view]} type={view} key={i} i={i} />)
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            null
                    }
                    {
                        props.extentions && Object.keys(props.extentions).length > 0
                            ?
                            <>
                                <div className="col-md-4 ">
                                    <div className="lead mt-5 text-md-right">
                                        {__("Extentions")}
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className=" mt-4">
                                        {
                                            Object.keys(props.extentions).map((view, i) => <SinglePluginView {...props.extentions[view]} type={view} key={i} i={i} />)
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            null
                    }

                </div>
            </div>
        </Collapse>
    </div>
}