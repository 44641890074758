import React, {Component} from "react"
import { withApollo } from "react-apollo"
import { compose } from "recompose"
import gql from "graphql-tag"
import { Intent, Dialog, Button, Popover } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import { AppToaster } from 'react-pe-useful'
import { withRouter } from "react-router"
import {Loading} from 'react-pe-useful'
import FieldInput from "react-pe-scalars"
import { template } from "react-pe-layouts"

class TemplateTab extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            loading : true,
            data    : template()
        }
    }
    componentDidMount()
    {
        this.onRefresh()
    }

    onRefresh = () => {
        const query = gql`
            query getTemplate 
            {
                getTemplate 
                {
                    json
                }
            }
        `
		this.setState(
		  { loading: true },
		  () => this.props.client.query({ query })
                .then((result) => {
                console.log(result.data.getTemplate )
                try
                {
                    let data = JSON.parse( result.data.getTemplate.json )
                    data = typeof data === "object" ? data : {}
                    console.log( {...this.state.data, ...data} )
                    this.setState({
                        data : {...this.state.data, ...data},
                        loading: false,
                    })
                }
                catch(e)
                {
                    this.setState({
                        data: {...this.state.data},
                        loading: false,
                    })  
                }
                
                }),
		)
	}
    onSave = (m_change, state, id) => {
		const mutation = gql`
            mutation changeTemplate($input : TemplateInput){
                changeTemplate(input: $input)
            }
        `
        const json = {json : JSON.stringify(this.state.data) }
        this.props.client.mutate({
			mutation: mutation,
			variables: { input: json },
			update: (store, { data: { changeTemplate } }) => {
				this.setState({ 
					isUpdated: false
				})
				AppToaster.show({
					intent: Intent.SUCCESS,
					icon: "tick",
					message: "Success saved template",
				})
			},
		});
	}
    onChangeData = (field, value) =>
    {
        let data = {...this.state.data}
        data[field] = value
        this.setState({
            isUpdated:true,
            data
        })
    }

    render()
    {
		const { loading, data } = this.state
	
		if ( loading) {
		  return <Loading /> 
		}
		return (
		  <div className="w-100">
			<FieldInput
                type="icon"
                title={__("Icon")}
                value={data.icon}
                isUploadHide
                onChange={ val => this.onChangeData("icon", val) }
            />
			<FieldInput
                type="int"
                title={__("Icon width")}
                value={data.icon_width}
                onChange={ val => this.onChangeData("icon_width", val) }
            />
			<FieldInput
                type="int"
                title={__("Icon height")}
                value={data.icon_height}
                onChange={ val => this.onChangeData("icon_height", val) }
            /> 
			<FieldInput
                type="boolean"
                title={__("Header enabled")}
                value={data.header}
                onChange={ val => this.onChangeData("header", val) }
            />
			<FieldInput
                type="radio"
                title={__("Left menu pictogramm style")}
                value={data.left_menu}
                values={[
                    {
                        _id: "hierarhical",
                        title: "List"
                    },
                    {
                        _id: "pictogramm",
                        title: "Pictogramm"
                    }
                ]}
                onChange={ val => this.onChangeData("left_menu", val) }
            />
            <div className="row">
                <div className="col-md-9 offset-md-3">
                    <Button
                        onClick={this.onSave}
                    >
                        {__("Update")}
                    </Button>
                </div>
            </div>
		  </div>
		)
    }
   
}
export default  compose(
	withRouter,
  	withApollo,
)(TemplateTab)