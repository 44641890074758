import React from "react"

import {
  ApolloClient, 
  ApolloLink, 
  InMemoryCache, 
  Observable
} from '@apollo/client';

import { setContext } from 'apollo-link-context';
//import { onError } from '@apollo/client/link/error'; 

//import { ApolloClient } from "apollo-client"
//import { fromPromise, ApolloLink } from "apollo-link"
//import { RestLink } from "apollo-link-rest"
//import { HttpLink } from "apollo-link-http"
//import { InMemoryCache } from "apollo-cache-inmemory"
import gql from "graphql-tag";
//import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { createUploadLink } from "apollo-upload-client"
import { AppToaster } from "react-pe-useful"

import { Intent } from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import { REFRESH_JWT_TOKEN } from "./qraphql"
 

let apolloClient;

export function client( config ) {
  // const restLink = new RestLink({
  //   uri: config.server_url ? config.server_url : null,
  // })
  // const httpLink = new HttpLink({
  //   uri: config.server_url ? config.server_url : null,
  // })
  const uploadLink = createUploadLink({
    uri: config.server_url ? config.server_url : null,
  })
  const queryLink = uploadLink
  // const queryLink = (link_type() === "http") ? httpLink: restLink;
  const cache = new InMemoryCache()
  const authLink = setContext((_, { headers }) => {
    let new_headers
    if (localStorage.getItem("token")) 
    {
      const token = localStorage.getItem("token") ? localStorage.getItem("token") : ""
      new_headers = {
        ...headers,
        authorization: `Bearer ${token}`,
      }
    } 
    else if (localStorage.getItem("client_token")) 
    {
      const token = localStorage.getItem("client_token") ? localStorage.getItem("client_token") : ""
      new_headers = {
        ...headers,
        authorization: `Bearer ${token}`,
      }
    } 
    else 
    {
      new_headers = {
        ...headers,
      }
    }
    return { headers: new_headers }
  }) 
  const getNewToken = () => 
  {
    localStorage.removeItem("token", null)
    const refresh_token = localStorage.getItem("refresh")
    const mutation = gql`${ REFRESH_JWT_TOKEN( refresh_token ) }` 
    return apolloClient.mutate({ mutation, variables: { } })
      .then( 
          response => { 
            return response.data.refresh;
          }
      );
  };

  const errorlink = onError(({ graphQLErrors, networkError, operation, forward }) => { 
    if(networkError && networkError.statusCode === 401)
    { 
        console.log( networkError )
        // console.log( operation ) 
    }
    if (graphQLErrors) 
    {
      for (const err of graphQLErrors) 
      {
        console.log( err )
        if (err.extensions) {
          switch (err.extensions.code) 
          {            
            case "UNAUTHENTICATED": 
              if (localStorage.getItem("token")) 
              { 
                localStorage.removeItem("token", null)
                
                // TODO: move to serverside cookie httponly
                const refresh_token = localStorage.getItem("refresh")
                const mutation = gql`${ REFRESH_JWT_TOKEN( refresh_token ) }` 
               
                // eslint-disable-next-line no-loop-func
                return new Observable(observer => {
                  apolloClient.mutate({ 
                    mutation, 
                    variables: { } 
                  })
                    .then( result => {
                        // console.log( result )
                        localStorage.setItem( "token", result.data.refresh.access_token, )

                        // TODO: move to serverside cookie httponly
                        localStorage.setItem( "refresh", result.data.refresh.refresh_token, )
                        
                        const oldHeaders = operation.getContext().headers;
                        operation.setContext({
                          headers: {
                            ...oldHeaders,
                            authorization: `Bearer ${result.data.refresh.access_token}`,
                          },
                        });
                        // return forward( operation )                  
                        // return Observable.of(operation);
                        // apolloClient.query( { query: gql`${operation.query.loc.source.body}` } )
                      },
                      err => {
                        console.error( err )
                      })
                        .then(res => {
                          console.log(res)

                          //TODO: refetch last query|mutation
                          /*
                          // https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request
                          const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer)
                          };
                          //Retry last failed request
                          forward(operation).subscribe(subscriber);
                          */
                          
                          /*
                          // https://www.apollographql.com/docs/react/api/core/ApolloClient/
                          const results = apolloClient.reFetchObservableQueries({
                            query: gql`${operation.query.loc.source.body}`,
                            variables : operation.variables
                          })   
                          console.log(results)
                          */
                          
                          window.location.reload()
                        })
                })
              }
              else
              {
                //пользователь разлогинен
                localStorage.setItem( "token", '' )
                localStorage.setItem( "refresh", '' )
                AppToaster.show({
                  intent: Intent.DANGER,
                  icon: "user",
                  message: __("Your token time finished. Relogin please"),
                })
                setTimeout(() => {
                  window.location.reload()
                }, 1000);
              } 
              break;
            case "INTERNAL_SERVER_ERROR":
              console.log(err)
              break
            case "FORBIDDEN":
              console.log(err)
              break
            default:
              AppToaster.show({
                intent: Intent.DANGER,
                icon: "error",
                message: __(err.message),
              })
              break
          }
        } 
        else 
        {
          AppToaster.show({
            intent: Intent.WARNING,
            icon: "warning",
            message: __(err.message),
          })
        }
      }
    }
  })

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "network-only",
      // errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: "network-only",
      // errorPolicy: 'all',
    },
  }
  apolloClient = new ApolloClient({
    link: ApolloLink.from([
      errorlink,
      authLink,
      queryLink,

    ]),
    cache,
    defaultHttpLink: false,
    defaultOptions,
    fetchOptions: {
      credentials: "include",
      mode: "no-cors",
    },

  })
  return apolloClient
}
