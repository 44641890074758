import React from 'react'
import PropTypes from 'prop-types'
import FestComponent from './FestComponent'
import { __ } from 'react-pe-utilities/dist'
import { compose } from "recompose"
import { withRouter, } from "react-router"
import { ReactComponent as Cat404 } from "../../../../assets/img/cat-404.svg"

class Fest404 extends FestComponent
{
    command = "init"
    header_type = "reqister-user"
    onBack = () =>
    {
        this.props.history.goBack()
    }
    addRender = () => { 
        return <div className='flex-centered ' >
            <div className='flex-centered flex-column pt-2 w-100' style={{ maxWidth: 680 }}>
                <div className="display-1">
                    404
                </div>
                <div className="lead my-2">
                    {__("Page not found")}
                </div>
                <div className="small my-2">
                    {__("Something went wrong")}
                </div>
                <div className=' flex-centered' style={{ padding: 49 }}>
                    <Cat404
                        height={180}
                        wifth={180} 
                    />
                </div>
                <div className='btn btn-primary btn-sm mb-2' onClick={ this.onBack }>
                    { __( "Go back" ) }
                </div>
            </div>
        </div>
    }
}

Fest404.propTypes = {}

export default compose(withRouter)(Fest404)