import { Button, Popover, Position } from "@blueprintjs/core"
import React, { Component } from "react"
import FieldInput from "react-pe-scalars"
import { __ } from "react-pe-utilities"

export default class SourceForm extends Component
{
    onFileLoad = evt =>
    {
        if(this.props.onFileLoad)
        {
            this.props.onFileLoad(evt)
        } 
    }
    onSheetUrl = value =>
    {
        if(this.props.onSheetUrl)
        {
            this.props.onSheetUrl(value)
        }
    }
    render()
    {
        const {fileListSrc, preSheetURL, field, fileTitle} = this.props
        switch(fileListSrc)
        {
            case "sheets":
                return <FieldInput
                    type="url"
                    value={ preSheetURL }
                    title={ __("URL of Google sheets") }
                    onChange={ value => this.onSheetUrl( value ) }
                />
            case "list":
                return <div className="w-100 row">
                    <div className="col-md-3  layout-label">
                        {__("Write encoding texts by every stings")}
                    </div>
                    <div className="col-md-9 layout-data">
                        <textarea rows={10} className="w-100">

                        </textarea>
                    </div>
                    
                </div>
            case "":
            default:
                return <div className="row dat strob01 " row_data={ field }>
                    <div className="col-md-3  layout-label">
                        {__("Upload *.txt file") }
                        <Popover
                        position={Position.RIGHT}
                        interactionKind="hover"
                        content={<div 
                            className="p-4 square" 
                            dangerouslySetInnerHTML={{ __html: __("Compose a text file from lines. The content of each line will be the basis for generating the YK code. Upload this file to the form.") }} 
                        />}
                        >
                            <Button minimal icon="help" />
                        </Popover>
                    </div>
                    <div className="col-md-9 layout-data">
                        <div className="d-flex flex-grow-100 py-3">
                            <div 
                                className="pictogramm " 
                                style={{
                                    position: "relative",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: "300px",
                                    height: "70px",
                                    background:"#FFF",
                                    overflow: "hidden"
                                }}
                            >
                                <span>{ __( fileTitle ) }</span>
                                <input 
                                    type="file"
                                    accept="text/plain"
                                    style={{
                                        cursor:"pointer",
                                        opacity: 0, 
                                        width: "100%", 
                                        height: "100%", 
                                        position: "absolute", 
                                        top: 0, 
                                        left: 0,
                                    }}
                                    onChange={this.onFileLoad} 
                                /> 
                            </div> 
                        </div> 
                    </div> 
                </div>
        }
    }
}