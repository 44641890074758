import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { getSingleRoute } from "react-pe-layouts"
import { __ } from "react-pe-utilities"
import { getFontNameByID } from "../data/PalettePresets"

class CadrCard extends Component {
  render() {
    const { height, palette } = this.props
    const fontFamily = palette && palette.card
      ?
      getFontNameByID(palette.card.title.fontFamilyID)
      :
      null
    const fontSize = palette && palette.card
      ?
      palette.card.title.fontSize
      :
      null
    const cotent = `${this.props.post_content
      .split(" ")
      .slice(0, 14)
      .join(" ")
      .replace(/(<\S([^>]+)>)/ig, "")
      }...`
    const route = getSingleRoute(this.props.data_type)

    return (
      <NavLink
        to={`/${route.route}/${this.props.id}`}
        className={`lesson-quote-cont landing-feed-thumb-dark  ${this.props.class_name}`}
        style={{
          ...this.props.style,
          padding: 10,
          backgroundImage: `url(${this.props.thumbnail})`,
          minHeight: height,
          margin: 0,
          alignItems: "flex-end"
        }}
      >
        <div className="lesson-quote-second">
          <div className="">
            <div
              className="lesson-qoute-title"
              style={{ fontFamily, fontSize, color: "#FFF", margin: 0 }}
            >
              {__(this.props.post_title)}
            </div>
          </div>
          <div
            className="lesson-quote-content"
            style={{ color: "#FFF" }}
            dangerouslySetInnerHTML={{ __html: cotent }}
          />
          <div className="d-flex flex-wrap" />
          <div className="lesson-quote-footer" style={{ margin: 0 }} />
        </div>
      </NavLink>
    )
  }
}
export default CadrCard
