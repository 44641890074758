import React, { Component } from "react"
import { withRouter, } from "react-router"
import { compose } from "recompose"
import { Intent } from "@blueprintjs/core"
import _fetch from "."
import FestMemberSetting from "./FestMemberSetting" 
import { __, initArea} from "react-pe-utilities" 
import { AppToaster, MediaChooser } from 'react-pe-useful'
import WPFestSettings from "./utilities/WPFestSettings"
import FestComponent from "./FestComponent"
import User from "./utilities/User"

/*
	Экран формы редактирования Соты его владельцем (Администратор, Модератор треков)
*/
class FestNewHoneycombForm extends FestComponent { 
		
	fetchCommand  = "new_honeycombs"
	fetchArgs     = { }
	header_type   = "insert-honeycombs"  
	setStartState = () =>
	{
		return { 
			...this.props, 
			loading: true,
		}
	}
	addRender = () =>
	{ 
		return <div className="row">
		{ 
			User.id && User.roles.filter(role => role === "Track_moderator").length > 0
				? 
				this.content() 
				: 
				this.the_none() 
		}
		</div> 
	}

	the_none() {
		return (
			<div className="container-float text-center alert alert-danger p-5 w-100 my-2">
				{__("You haven't rights to use this content")}
			</div>
		)
	}
	content() {
		//console.log( this.state )
		return <>
			<div className="col-12  p-4">
				<div className="row">
					<div className="col-12">
						<div className="row data-list">
							<div className="col-md-4 col-sm-4 test-descr-title  my-auto">
							{__("Honeycombs title")}:
							</div>
							<div className="col-md-8 col-sm-8 pt-2">
							<input
								value={this.state.title || ""}
								onChange={this.onTitle}
								className="input dark form-control" 
							/>
							</div>
						</div>
						{/* <div className="row data-list">
							<div className="col-md-4 col-sm-4 test-descr-title  my-auto">
							{__("Thumbnail")}:
							</div>
							<div className="col-md-8 col-sm-8 pt-2 pb-3"> 
							<MediaChooser
								prefix="_post_thumbnail_"
								url={this.state.img}
								id=""
								ID=""
								hideLib={true}
								isURLHide={true}
								height={200}
								width={300}
								padding={6}
								tab="ld"
								onChange={this.onThumbnail}
							/>
							</div>
						</div>   */}
					</div>
				</div>
				{
					initArea(
						"fest-register-form",
						{
							...this.props,
							...this.state,
							role: "Track_moderator",
						},
					)
				} 
			</div>
			<div className="col-md-8 offset-md-4  p-4">
				<div className="btn btn-lg btn-primary" onClick={this.onInsert}>
					{__("insert new Honeycomds")}
				</div>
			</div>
		</> 
	}
	onTitle = (evt) => {
	  const title = evt.currentTarget.value
	  this.setState({ title })
	  //this.fetch("post_title", title)
	}
	onThumbnail = (value, file) => {
		this.setState({
		  img: value,
		  img_name: file.name,
		})
		if (!this.props.isNew) { 

		} 
		else if (this.props.ons) 
		{
		  this.props.ons({
			id: this.props.id,
			fields: [
			  { field: "thumbnail", value },
			  { field: "thumbnail_name", value: file.name },
			],
	
		  })
		}
	  }
	onInsert = () => {
		return
		let args = {
			post_title: this.state.post_title,
			thumbnail: this.state.thumbnail,
			thumbnail_name: this.state.thumbnail_name,
			command: "create_honeycomds_with_patams",
			festId: this.props.match.params.festid,
		}
		
		console.log( args )
		// return
		let yes = true
		if (!this.state.post_title) {
			yes = false
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: __("Insert title"),
			})
		}
		if (!yes) return

		
		_fetch(
			"get_fest_command",
			args,
			WPFestSettings.serviceURL,
			WPFestSettings.token,
			"wpfa",
		)
			.then((_data) => {
				const data = _data.data 
				//console.log(this.props.route)
				//console.log(data)
				this.props.history.push(
					`${this.props.route}/${this.props.match.params.festid}/member/${data.created_id}?help&phase=register-project`
				)
			})
	}

	on = (data) => {
		console.log(data)
		const state = {}
		state[data.field] = data.value
		this.setState(state)
	}

	ons = (data) => {
		console.log(data)
		const state = {}
		data.fields.forEach((e) => {
			state[e.field] = e.value
		})
		this.setState(state)
	}
}
export default compose(
	withRouter,
)(FestNewHoneycombForm)
