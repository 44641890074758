import React from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import { Button } from '@blueprintjs/core'
import DataContext from '../DataContext'
import LandingStructurePanel from './LandingStructurePanel'
import LandingResizePanel from './LandingResizePanel'
import ScreenSizeList from 'modules/pe-landing-module/widgets/utilities/ScreenSizeList'

const LefyEditPanel = props => {
    const onResizeClick = size =>
    {
        const resizeEvent = document.createEvent('Event');
        const sz = ScreenSizeList( ).filter(sz => sz.id === size)[0].size
        resizeEvent.initEvent('resize', true, true);
        document.body.style.width = size === "lxl" ? "100%" : sz + "px"
        document.body.style.marginLeft = document.body.style.marginRight = "auto"
        window.innerWidth =  size === "lxl" ? "100%" : sz + "px"
        //document.getElementsByTagName( 'html' )[0].style.width = sz + "px"
        window.dispatchEvent(resizeEvent)
    }
    return <div className=" h-100 d-flex align-items-center ml-5">
        <span className="landing-panel">
            <Button
                className="hint hint--bottom"
                data-hint={__("Undo")}
                disabled={ !DataContext.isMayUndo() }
                minimal
                icon="undo"
                onClick={ props.undo }
            >
                { DataContext.getCurrentUndoLevel() }
            </Button>
            {/* <Button
                className="hint hint--bottom"
                data-hint={__("Redo")}
                minimal
                disabled
                icon="redo"
                onClick={ props.redo }
            >
                
            </Button> */}
        </span> 
        <LandingStructurePanel
            setContainer_type={ props.setContainer_type }
            setCarousel_flow ={ props.setCarousel_flow }
            setLanding_flow ={ props.setLanding_flow }
        /> 
        <span className="landing-panel">
            <LandingResizePanel 
                onResizeClick={onResizeClick}
            />
        </span>
    </div>  
}

LefyEditPanel.propTypes = {}

export default LefyEditPanel