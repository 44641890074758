import React, { Suspense } from "react"
import $ from "jquery" 
import { __ } from "react-pe-utilities" 
import Testanomial from "./Testanomial" 
import SectionContent from "./SectionContent"
import {Loading} from 'react-pe-useful'  
import { Carousel as ElCarousel } from '@trendyol-js/react-carousel'; 
import { CarouselLeftArrow, CarouselRightArrow } from "./Carousel"

class Testanomials extends SectionContent {
  getState() {
    this.car = React.createRef()
    return {}
  }

  componentDidMount() {
    let h = 100
    const xx = $(`[data-id="${ this.props.id }"] .landing-testanomials .text` )
    xx.each((e, elem) => {
      // console.log(e, elem, $( elem ).height(), h);
      h = Math.max($( elem ).height(), h)
    })
    // console.log( "height=", h )
    xx.height(h)
  }

  renderContent(style) {
    const { composition, palette } = this.state
    const {
      class_name, testanomials, dots, items, nav, autoplay, loop, layout, design_type, line_width, line_color
    } = this.state.data
    // console.log(this.state.data);
    const __testanomials = testanomials
      ?
      testanomials.map((e, i) => <Testanomial
        {...e}
        i={i}
        key={i}
        sectionID={this.props.id}
        line_width={line_width}
        line_color={line_color}
        composition={ composition }
        design_type={design_type}
        palette={palette}
      />)
      : null

    const options = {
      dots: typeof dots !== "undefined" ? !!dots : false,
      items: typeof composition.columns !== "undefined" ? composition.columns : 2,
      nav: typeof nav !== "undefined" ? !!nav : false,
      rewind: true,
      autoplay: typeof autoplay !== "undefined" ? !!autoplay : false,
      loop: typeof loop !== "undefined" ? !!loop : false,
      responsive: {
        0: {
          items: 1,
        },
        760: {
          items: typeof items !== "undefined" ? items : 2,
        },
      },
    }
    return layout === "carousel"
    ?
    (
      <div className={`landing-testanomials ${class_name}`} style={style}>
        <Suspense fallback={<Loading />}>
          <ElCarousel 
            show={options.items} 
            composition={ composition }
            swiping={true} 
            swipeOn={ -50 }
            responsive
            className="landing-carousel-container"
            useArrowKeys={options.nav}
            infinite={options.loop}
            rightArrow={
              <CarouselRightArrow 
                {...this.props.data} 
              />
            }
            leftArrow={
              <CarouselLeftArrow 
                {...this.props.data} 
              />
            }
          >
            {__testanomials}
          </ElCarousel>
        </Suspense>
      </div>
    )
    :
    <div className={`landing-testanomials ${class_name}`} style={style}>
      <div className="row">
        {__testanomials}
      </div>
    </div>
  }

  is() {
    const { testanomials } = this.state.data
    return testanomials && testanomials.length > 0
  }
}
export default Testanomials
