import { Button, ButtonGroup, Icon } from "@blueprintjs/core"
import React from "react" 
import { __ } from "react-pe-utilities/dist"

const TopMenu = props =>
{
    const sz = 28
    const onAddOpen = evt =>
    {
        props.onAddOpen(evt)
    }
    const onAddListOpen = evt =>
    {
        props.onAddListOpen(evt)
    }
    const zipAllQRCodes = evt =>
    {
        props.zipAllQRCodes(evt)
    }
    const getDownloadIcon = () =>
    {
         // console.log("isStartDownload = ", this.state.isStartDownload)
         return props.isStartDownload
         ?
         <div
             style={{
                 width: sz,
                 height: sz,
                 fontSize: sz,
                 color:"red"
             }}
             className="fas fa-sync fa-spin "
         />
         :
         <Icon 
             icon="import" 
             size={ sz }
         />
    }
    const onDeleteOpen =evt =>
    {
        props.onDeleteOpen(evt)
    }
    return <ButtonGroup minimal className="mr-auto h-100">
        <Button
            className="px-4 "
            title={__("Add QR-code")}
            onClick={ onAddOpen }
        >
            <div className="d-flex flex-column justify-content-end align-items-center">
                <div className="">
                    <Icon icon="plus" size={sz}/>
                </div>
                <div className="small mt-2 mb-1">
                    {__("Add QR-code")}
                </div> 
            </div>
        </Button>
        <Button 
            className="px-4 "
            title={__("Add list")}
            onClick={onAddListOpen}
        >
            <div className="d-flex flex-column justify-content-end align-items-center">
                <div className="">
                    <Icon icon="add-to-artifact" size={sz}/>
                </div>
                <div className="small mt-2 mb-1">
                    {__("Add list")}
                </div> 
            </div>
        </Button>
        <Button
            className="px-4 "
            title={__("Download selected")}
            onClick={ zipAllQRCodes }
        >
            <div className="d-flex flex-column justify-content-end align-items-center">
                <div className="">
                    {
                       getDownloadIcon()
                    }
                </div>
                <div className="small mt-2 mb-1">
                    {__("Download selected")}
                </div>
            </div>
        </Button>
        <Button
            className="px-4 "
            title={__("Delete selected")}
            onClick={ onDeleteOpen }
        >
            <div className="d-flex flex-column justify-content-end align-items-center">
                <div className="">
                    <Icon icon="cross" size={sz}/>
                </div>
                <div className="small mt-2 mb-1">
                    {__("Delete selected")}
                </div>
            </div>
        </Button>
    </ButtonGroup>
}
export default TopMenu