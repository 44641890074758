import React, {Fragment,Component} from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { __ } from "react-pe-utilities/dist";

export default class FullExperts extends Component
{
	
	render()
	{
		return <div className="p-4"> 
			<a className="btn btn-link " href={this.props.data.full_experts_url} target="_blank">
				{__("Download CSV")}
			</a>
			<ReactHtmlTableToExcel
				id="test-table-xls-button"
				className="btn btn-link ml-4"
				table="table-to-xls"
				filename="full_expert_list"
				sheet="full_expert_list"
				buttonText={__("Download as XLS")}
			/>
			<div dangerouslySetInnerHTML={{ __html: this.props.data.full_experts_table }} />
		</div>
	}
}