import React, { useEffect, useState } from "react"
import { Callout, Intent } from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import { sprintf } from "react-pe-utilities"
import { layoutInit, moduleExists } from "react-pe-layouts"
import { initDictionary } from "react-pe-utilities" 
import {Loading} from "react-pe-useful"
// import LayoutApp from "react-pe-layout-app"
// import {LayoutProvider} from "react-pe-layout-app"  
import { ApolloProvider } from "react-apollo"
import { BrowserRouter } from "react-router-dom" 
import { client } from "settings/client"
import { initConfig } from "settings/config" 
import "./assets/css/tools-panel.css"
import LayoutApp, { LayoutProvider } from "states/layout-app"

const App = (props)=>
{
    const [isLoad, changeLoad] = useState(false)
    useEffect(() =>
    {
        initConfig( props.config )
        layoutInit( props.layoutConfig )
        initDictionary( '', props.dictionary )        
        setTimeout(() => {
            changeLoad(true)
        }, 30);  
    }, [])
    return isLoad
        ?
            props.config
            ?
                props.layoutConfig && moduleExists("pe-basic-module")
                    ?
                    <BrowserRouter forceRefresh={ false }>
                        <ApolloProvider client={ client( props.config ) } >
                            <LayoutProvider>
                                <LayoutApp {...props} />
                            </LayoutProvider>                            
                        </ApolloProvider>
                    </BrowserRouter>     
                    :
                    <Callout intent={Intent.DANGER} className="p-5">
                        { sprintf( __( "PE application not complete. Layout config not exists. see more: %s" ), "___" ) }
                    </Callout>
            :
            null
        :
        <Loading />
}
export default App
