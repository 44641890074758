import { Intent } from "@blueprintjs/core"
import { AppToaster } from 'react-pe-useful'
import { __ } from "react-pe-utilities" 

export default function _fetch(code, args, url, token, rest_route = "wpfa") {
  code = !code ? "page" : code
  let lastMessage = false
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //token : token,
    Token : token
  }
  return fetch(`${url}/wp-json/${rest_route}/${code}`,
    {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify({
        code,
        args,
      }),
    })
    .then(
      (responce) => {
        // console.log(responce);
        const res = responce.json()
        //console.log( res );
        //console.log("lastMessage", lastMessage, res.msg);
			  setTimeout(() => lastMessage = false, 1200)
        if (res.msg && res.msg !== lastMessage) 
        {
          AppToaster.show({
            intent: res.msg_type ? res.msg_type : Intent.SUCCESS,
            icon: "tick",
            message: __(res.msg),
          })
          lastMessage = res.msg
        }
        return res
      },
      (error) => {
        console.log(error)
        return { error }
      },
    )
    .then(
      (resp) => {
        
        return resp
      },
    )
}
