import React, { Component } from "react"
import { __ } from "react-pe-utilities" 

export default class GoogleDoc extends Component {
  render() {
    return (
      <div className="role_descr mr-auto">
        { __("GoogleDoc") }
      </div>
    )
  }
}
