import React from "react"
import Style from "style-it"

const SectionContainer = props =>
{
    // console.log(props.data.overflow)
    return <>
        { Style.it(
            `.landing-section
            {
              overflow: ${ props.data.overflow === "visible" ? "visible" : "hidden" };
            },`,
            props.children
        ) }
    </>
}
export default SectionContainer