import {default as UUID} from "node-uuid";

const getDefaultSection = (type = "image", data = { composition: {}, data: { height: 200 } }) =>
{ 
	const id = UUID.v4() //DataContext.getMaxSectionID(true)
	if(type === "accordeon_sections")
	{ 
		data.data = {
			type,
			height:				data.height,
			title	: 			data.title,
			cover_color : 		data.cover_color,
			cover_text_color : 	data.cover_text_color,
			title_css : 		data.title_css,
			sign_top : 			data.sign_top,
			sign_bottom : 		data.sign_bottom,
			class_name : 		data.class_name,
			style : 			data.style,
			lasy_load_type :	data.lasy_load_type,
			lasy_load_delay :	data.lasy_load_delay
		}
		data.data.section = data.section.map(sec => {  
			const s = getDefaultSection( sec.type, sec )  
            return s
        }) 
		return {
			id,
			...data.data
		}
	}
    else if( data.data.sections || data.data.section ) 
	{ 
		if(data.data.sections)
		{
			data.data.sections = data.data.sections.map(sec => {   
				return  getDefaultSection( sec.type, sec )        
			})
		}
		if(data.data.section)
		{
			data.data.section = data.data.section.map(sec => {   
				return  getDefaultSection( sec.type, sec )        
			})
		}
    } 
	console.log( data )
	console.log( data.background )
	const result =  {
		id,
		composition: {
			columns: 1,
			type: 3,
			is_blocked: "2",
			proportia: [ 100 ],
			text_before: "",
			text_after: "",
			is_expand: 0,
			is_vertical_center: 1,
			...data.composition,
		},
		background: {
			image: "",
			color: "",
			stack: "image",
			is_parallax: 0,
			parallax_speed: 0,
			...data.background,
		},
		class_name: data.class_name || "",
		style: data.style,
		title: { 
			text: "",
			text_src: "",
			lasy_load_type: "",
			lasy_load_delay: 0,
			class_name: "text-center",
			style: {},
			composition:
			{
				columns: 1,
				type: 0,
				is_blocked: 1,
				proportia: [100],
				text_before: "",
				text_after: "",
			}, 
			...data.title
		},
		descriptions: { 
			text: "",
			lasy_load_type: "",
			lasy_load_delay: 0,
			class_name: "text-center",
			style: { }, 
			...data.descriptions
		},
		menu: {
			label: "",
			id: `${id}`,
			is_enabled: 0,
		},
		type: type,
		data: {
			...data.data,
			id,
			menu: {
				...data.data.menu,
				id
			}
		} ,
		vertical_padding: data.vertical_padding,
		floats: data.floats || [],
		current_template_id: data.current_template_id ? data.current_template_id : "basic"
	} 
	return result
}
export default getDefaultSection