import { Slider } from "@blueprintjs/core"
import React, { Component } from "react"
import CSSSize from "../utilities/CSSSize"
import { __ } from "react-pe-utilities" 
import FieldInput from "react-pe-scalars"
import DataContext from "../../views/LandingState/DataContext"

class TextFormatForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      value: props.value ? props.value : {},
      example: props.example ? props.example : "Lorem ipsum",
    }
  }

  render() {
    console.log(this.state)
    let { value } = this.state
    value = value || {}
    const {
      size,
      weight,
      letterSpace,
      fontFamilyID,
      color,

    } = value
    return (
      <div className="row w-100 p-3 border-bottom  border-secondary">
        <div className="col-md-5 col-12">
          <div className="py-2">
            <label>
              { __("Font size") }
              {" "}
            </label>
            <CSSSize
              value={size}
              on={(value) => this.on(value, "size")}
            />
          </div>
          <div className="py-2">
            <label>
              { __("Font weight") }
              {" "}
            </label>
            <Slider
              min={100}
              max={900}
              stepSize={100}
              labelStepSize={1000000}
              onChange={(value) => this.on(value, "weight")}
              value={parseInt(weight)}
            />
          </div>
          <div className="py-2">
            <label>
              { __("Space between letters") }
              {" "}
            </label>
            <Slider
              min={0}
              max={35}
              stepSize={1}
              labelStepSize={1000000}
              onChange={(value) => this.on(value, "letterSpace")}
              value={parseInt(letterSpace)}
            />
          </div>
          <div className="py-2">
            <label>
              { __("Font family") }
              {" "}
            </label>
            { this.getFontSelector() }
          </div>
          <div className="py-2">
            <label>
              { __("Font color") }
              {" "}
            </label>
            <FieldInput
              type="color"
              field="color"
              on={(value) => this.on(value, "color")}
              onChange={(value) => this.on(value, "color")}
              editable
              value={color}
              vertical
            />
          </div>
        </div>
        <div className="col-md-7 col-12 d-flex align-items-center">
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontSize: this.state.value.size,
              fontWeight: weight,
              letterSpacing: letterSpace,
              fontFamily: this.getFont(fontFamilyID),
            }}
          >
            {this.state.example}
          </div>
        </div>
      </div>
    )
  }

    on = (evt, field) => {
      const state = { value: { ...this.state.value } }
      state.value[field] = evt
      this.setState(state)
      if (this.props.on) {
        this.props.on(this.props.field, state.value)
      }
    }

    onFontFamilyID = (evt) => {
      const { value } = evt.currentTarget
      this.on(value, "fontFamilyID")
    }

    getFont = (fontID) => {
      const fonts = ["Open Sans", ...DataContext.data.landing.fonts.map((e) => e.title)]
      // console.log( fonts[ fontID ] );
      return fonts[fontID] ? `${fonts[fontID].split(":")[0]}, Open Sans` : "Open Sans"
    }

    getFontSelector() {
      const fonts = ["Open Sans", ...DataContext.data.landing.fonts.map((e) => e.title.split(":")[0])]
      return (
        <select className="form-control " value={this.state.value.fontFamilyID} onChange={this.onFontFamilyID}>
          {
            fonts.map((e, i) => (
              <option
                value={i}
                key={i}
              >
                { e }
              </option>
            ))
        }
        </select>
      )
    }
}
export default TextFormatForm
