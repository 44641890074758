import {default as UUID} from "node-uuid";
/*
    Count Card field grids from card fields
*/

const getUniqGrids = fields =>
{
    if(!Array.isArray(fields)) return [{}]
    let uniq_grids= []
    fields.forEach(field => {
        if( typeof field.grid_id === "undefined")
        {
            field.grid_id = UUID.v4()
        }
    })
    fields.forEach(field =>
    {
        if( uniq_grids.filter( u => field.grid_id === u ).length === 0 )
        {
            uniq_grids.push( field.grid_id )
        }
    })
    return uniq_grids
}
export default getUniqGrids