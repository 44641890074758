import React, { Component } from "react" 
import Style from "style-it"
import { getStyle } from "./Section"
import ElegantTestanomial from "./teastanomial/ElegantTestanomial"
import StandartTestanomial from "./teastanomial/StandartTestanomial"

class Testanomial extends Component 
{
  render()
  {
    switch(this.props.design_type)
    {
      case "elegance":
        return <ElegantTestanomial {...this.props} />
      case "standart":
      default:
        return <StandartTestanomial {...this.props} />
    }
  }
}
export default Testanomial
