import React, { useState } from "react"
import $ from "jquery" 

const SingleImg = props =>
{
    const [img, setImg] = useState("<svg></svg>")
    const click = evt =>
    {
        if(props.select)
        {
            props.select(props.i, `data:image/svg+xml;base64,${img}`)
        }
    }
    $.get( props.imageSource, function(data) {
        const buff = new Buffer( new XMLSerializer().serializeToString( data ) );
        const base64data = buff.toString('base64');
        setImg( base64data )
    });

    return <div 
        title={ props.img } 
        className={ `pe-qr-social-btn ${ props.selected === img ? "selected" : "" }` }
        onClick={click}
    >
        <img src={`data:image/svg+xml;base64,${img}`} width={27} height={27} title={props.img} alt={props.img} />    
    </div>
}

export default SingleImg;