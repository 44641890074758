import React, { Component } from "react"
import {
  Intent, Button, ButtonGroup, Popover,
} from "@blueprintjs/core"
import MenuElementBtn from "./MenuElementBtn"
import { __ } from "react-pe-utilities" 
import { AppToaster } from 'react-pe-useful'

class MenuElementBtnGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.items,
      curBtn: this.props.curBtn,
      childName: ""
    }
  }

  componentWillReceiveProps(nextProps) {
    // if(nextProps.curBtn!=this.state.curBtn)
    //	this.setState({ curBtn: nextProps.curBtn })
    this.setState(nextProps)
  }

  
	onMenuElementUp = order =>
	{ 
    let items = [...this.state.items];
    const item = {...items[order]};
    items.splice(order, 1);
    items.splice(order - 1, 0, item); 
    this.setState({items});
    if(this.props.onItems) this.props.onItems(items);
	}
	 onMenuElementDown = order =>
	{
    let items = [...this.state.items];
    const item = {...items[order]};
    items.splice(order, 1);
    items.splice(order + 1, 0, item); 
    this.setState({items});
    if(this.props.onItems) this.props.onItems(items);
	}


  render() {
    //console.log(this.state.items)
    const {items} = this.state;
    const btns = items?.length > 0
      ?
      items.map((item, index) => {
        return <MenuElementBtn
          {...item} 
          i={index}
          key={index}
          route_prefix={""}
          isHideChildren={this.props.isHideChildren}
          length={items.length}
          level={0}
          current={this.state.curBtn}
          onCurBtn={(route, level) => this.props.onCurBtn(route, level, item.route)}
          onUp={() => this.onMenuElementUp(index)}
          onDown={() => this.onMenuElementDown(index)}
          onItems={ items => console.log(items) }
        /> 
      })
      :
      <div className="alert alert-secondary">
					{__("No elements exist")}
				</div>

    const newBtn = this.props.isHideNewBtn
      ?
      null
      :
      <ButtonGroup fill>
          <Popover
            content={(
              <div className="square">
                <div>
                  <small>
                    {__("Set child's title")}
                  </small>
                  <input
                    type="text"
                    value={this.state.childName}
                    onChange={(evt) => this.setState({ childName: evt.currentTarget.value })}
                    className="form-control mt-2 mb-4"
                    placeholder={__("Title")}
                  />
                  <ButtonGroup>
                    <Button intent={Intent.SUCCESS} onClick={this.onCreateChild} className=" text-center">
                      {__("Insert child Route")}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            )}
          >
            <Button onClick={this.onCreateToggle} fill minimal className="h-40px px-4">
              {__("Create child Route")}
            </Button>
          </Popover>
        </ButtonGroup>
    return (
      <div>
        { btns }
        { newBtn }
      </div>
    )
  }

  onCreateToggle = () => {
    this.setState({ isCreateOpen: !this.state.isCreateOpen })
  }

  onCreateChild = () => {
    if (!this.state.childName) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: "tick",
        message: "Insert not empty title",
      })
      return
    }
    const { childName } = this.state
    // let items = [...this.state.items];
    // items.push({title:this.state.childName, route:""})
    this.setState({
      isCreateOpen: false,
      childName: "",
    },
    () => this.props.onNewBtn(childName))
  }
}

export default MenuElementBtnGroup
