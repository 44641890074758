import React from "react"
import { LayoutIcon } from "react-pe-useful/dist"

const Icon = props =>
{
    try
    {
        let icon
        if(props.src && (props.src?.indexOf("http") === 0 || props.src?.indexOf("data:image/") === 0 ))
        {
            icon = <LayoutIcon
                style={{
                    ...props.style,
                    backgroundImage:        `url(${ props.src })`,
                    width: props.width ?    props.width   : props.style?.width,
                    height: props.height ?  props.height  : props.style?.height,
                }}
                className={props.className}
            />
        }
        else
        {        
            icon = <LayoutIcon
                style={{
                    ...props.style,
                    width: props.width,
                    height: props.height,
                }}
                className={ props.src }
            />
        }
        return <>
            {icon}
        </>
    }
    catch(err)
    {
        return null
    }
    
}
export default Icon