import React, { Component } from "react"
import { withRouter } from "react-router"
import { compose } from "recompose"
import _fetch from "."
import { __ } from "react-pe-utilities"  
import { Link } from "react-router-dom"
import { PRE_REGISTER_USER_ACTION } from "modules/pe-fest-module/data/actions"
import FestComponent from "./FestComponent"
import User from "./utilities/User"
import { withFestStore } from "modules/pe-fest-module/data/store"
import { LayoutIcon } from "react-pe-useful/dist"
import Roles from "./utilities/Roles"

class FestRegister extends FestComponent 
{
  command = PRE_REGISTER_USER_ACTION
  header_type = "reqister-user"

  addRender = () =>
  {
    return (
      <div className="short-container"> 
        <div className="row my-4">
          { User.id && User.id > 0 ? this.content() : this.the_none() }
        </div>
      </div>
    )
  }
  the_none = () =>
  {
    const roles = Array.isArray(this.props.store.options.fmru_evlbl_roles)
      ?
      this.props.store.options.fmru_evlbl_roles.map(role =>
        {
          const count = this.props.store.options.fmru_evlbl_roles.length
          let col = 12
          switch(count)
          {
            case 1:
              col = 12
              break
            case 2:
              col = 6
              break
            case 3:
              col = 4
              break
            case 4:
              col = 3
              break
            case 5:
              col = 4
              break
            case 6:
              col = 4
              break
            default:
              col = 12
          }
          const roleData = Roles()[role]
          const RoleAvatar = roleData.avatar
          console.log( roleData )
          return <div className={`col-md-${col} col-12 my-3 `} key={role}>
            <Link 
              className="p-4 btn btn-secondary d-block h-100 d-flex flex-column" 
              to={`${ this.props.location.pathname }/${ role }`} 
            >
              <div>
                <RoleAvatar height={50}/>
              </div>
              <div className="display-5 my-2 text-wrap">
                {__(role)}
              </div>
              <div className="small text-wrap mt-auto">
                {__(roleData.caps_description)}
              </div>
                
            </Link>
          </div>
        })
      :
      null
    return <div className="flex-centered flex-column container" >
      <div className="row mb-4">
        <div className="col-12 display-6 p-4 title text-center">
          {
            __("Choose the role in which you are going to participate in the Festival")
          }
        </div>
      </div>
      <div className="row mb-4">
        {roles}
      </div>      
    </div>
  }
  

  content() {
    return (
      <div className="alert alert-danger p-5 w-100 my-2">
        {__("You are logged and must log out.")} 
      </div>
    )
  } 
  
}
export default compose(
  withRouter,
  withFestStore
)(FestRegister)
