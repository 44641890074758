import React, { Component } from "react"
import { __ } from "react-pe-utilities/dist"

export default class GanreCheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
		  checked: props.ganre.check === 1 ? "checked" : "",
    } 
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      // checked: nextProps.ganre.check===1 ? "checked" : ""
    })
  }

  render() {
    const { ganre } = this.props 
    return (
      <div 
        className="mt-3" 
        style={{ overflow: "hidden", padding: 5, paddingBottom:37 }} 
        title={ ganre.name }
      >
        <input
          type="checkbox"
          name={`ganre_${this.props.name}${ganre.id}`}
          id={`ganre_${this.props.name}${ganre.id}`}
          className={`ganre_checkbox ${this.state.checked}`}
          onChange={this.check}
          defaultValue={ganre.check}
        />
        <label htmlFor={`ganre_${this.props.name}${ganre.id}`}>
          <div className="ganre-label">{ ganre.name }</div>
          <img src={ganre.icon} alt={ganre.name} />
        </label>
      </div>
    )
  }

	check = (event) => {
	  const target = event.currentTarget
	  const value = target.classList.contains("checked") ? "" : "checked"
	  this.setState({
	    checked: value,
	  })
	  this.props.onClick(this.props.ganre.id, value === "checked" ? 1 : 0)
	}
}
