import React from "react" 
import { __ } from "react-pe-utilities/dist"
import getScreenWidthMeta from "./data/getScreenWidthMeta"
import SectionContent from "./SectionContent"

class Canvas extends SectionContent 
{
    height;
    is() 
    { 
        const h = this.getMyHeight() 
        return this.props.floats.filter(e => e !== null ).length > 0
    }
    getHeight = () =>
    {
        const {section_width} = this.props
        return getScreenWidthMeta(section_width )
    }
    getCanvasSize = () =>
    {
        return this.props.data.canvas_size 
            ?
            this.props.data.canvas_size
            :
            { mc:400, lg:400, xl: 400, sm: 400 }
    }    
    getMyHeight(  ) 
    {
        this.height = this.getCanvasSize()[ this.getHeight()] + "px"  
        return this.height
    }
    renderContent(style) 
    { 
        const { class_name } = this.props.data
        const canvas_size = this.getCanvasSize()
        const st = { 
            ...style, 
            height: canvas_size[ this.getHeight()] + "px"   
        }
        
        return <div
            className={ `landing-canvas landing-section-core  ${ class_name || "" } ` }
            style={ st } 
        > 
        </div> 
    } 
}

export default Canvas
