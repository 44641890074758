import { Collapse, Icon } from "@blueprintjs/core"
import matrix from "modules/pe-landing-module/views/LandingState/data/matrix"
import React, { useEffect, useState} from "react" 
import Draggable from 'react-draggable'
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"

export const openTypes = () =>
{
    return [
        {
            id: "full"
        },
        {
            id: "half"
        },
        {
            id: "closed"
        }
    ]
}
const ToolPanelGroup = props =>
{
    const[width, setWidth]   = useState( props.width || 260 )
    const[height, setHeight] = useState( props.panel?.height || 260)
    const[isOpen, setOpen] = useState( true )
    const[opened, setOpened] = useState( "full" ) 
    const[x, setX] = useState( props.panel?.x || 0 )
    const[y, setY] = useState( props.panel?.y || 0 ) 
      
    const toShort = () =>
    {
        openTypes().forEach((key, i) =>
        {
            if( key.id === opened )
            {
                setOpened( openTypes()[ (i + 1) % openTypes().length ].id )
            }
        })
    } 
    const on = ( value, field ) =>
    {
        console.log( value, field )
    }
    const __children = Array.isArray(props.panel?.__children)
        ?
        props.panel?.__children.map((ch, i) =>
        { 
            //console.log( ch.field )
            //console.log( props.data[ ch.field ] )
            return <div className=" " key={i}>
                <FieldInput
                   {...matrix()[ ch.parent ][ ch.field ]}
                   value={props.data ? props.data[ ch.field ] : ""}
                   vertical
                   field={ ch.field }
                   on={on}
                   onChange={on}
                />
            </div>
        })
        :
        []
    return <>
        <div 
            className={`tool-panel-group  ${opened}`}
            style={{
                width,
                height: "auto",
                top: y,
                left: x   
            }}          
        >
            <div 
                className={` tool-panel-group-head`}
                onClick={() => setOpen(!isOpen)}
            >
                <div className="tool-panel-group-title">
                    {__(props.panel?.title)}
                </div>
                <div>
                    <Icon 
                        icon={`caret-${opened === "closed" ?"right" : "left"}`} 
                        onClick={ toShort } 
                        color="#FFF" 
                        iconSize={10} 
                        className="p-1 hidden"
                    />    
                </div> 
            </div>
            <div 
                className="tool-panel-group-body"
                style={{ }}
            >
                <Collapse isOpen={isOpen}>
                    {__children}
                    { props.children }
                </Collapse>
            </div>
        </div>
    </>
}
export default ToolPanelGroup