import { Button, ButtonGroup, Classes, Dialog, Intent, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import React, { useEffect, useState } from "react"
import $ from "jquery"
import { __ } from "react-pe-utilities/dist"

const FloatEditPanel = props =>
{
    
    const onDeleteFloat = (evt) =>
    { 
        // console.log(evt.keyCode)
        if( evt.keyCode === 46 )
        { 
            if(props.floatSelect)
            {
                props.floatSelect.onRemoveFloat()
            }
        }
        if(evt.keyCode === 39)
        {
            // right
        }
        if(evt.keyCode === 37)
        {
            // left
        }
        if(evt.keyCode === 38)
        {
            // up
        }
        if(evt.keyCode === 40)
        {
            // down
        }
    }
    useEffect(() =>
    {
        $("body").on( "keyup", onDeleteFloat)
        return () => $("body").off( "keyup", onDeleteFloat)
    }, 
    [ props ] )
    const openMenuEdit = tabId =>
    {
        props.floatSelect.setState({ isOpen: true,  tabId: tabId })
    }
    const onStretch = type =>
    {
        if(props.floatSelect)
        { 
            props.floatSelect.onStretchFull( type )
        }
    }
    const onAlign = type =>
    {
        if(props.floatSelect)
        { 
            props.floatSelect.onAlign( type )
        }
    }
    return <div className=" h-100 d-flex align-items-center ">
        {
            typeof props.floatSelect !== "undefined" && props.floatSelectID
                ?
                <span className="landing-panel">
                    <span style={{ paddingLeft:10, paddingRight:10, userSelect:"none" }}>{__("Float")}</span>
                    <Popover2
                        popoverClassName={` bg-light no-outline w_280`}
                        modifiers={{
                            arrow: { enabled: true },
                            flip: { enabled: true },
                            keepTogether: { enabled: true },
                            preventOverflow: { enabled: true },
                            offset: { enabled: true }
                        }}
                        position="bottom"
                        enforceFocus={false}
                        hasBackdrop={false}
                        minimal={true}
                        content={
                            <Menu className={'  bg-light text-dark '}>
                                <MenuItem   
                                    text={__("Change content type")} 
                                    icon="bookmark"
                                    onClick={ ( ) => openMenuEdit( "types" ) }
                                />
                                <MenuItem
                                    text={__("Edit content")} 
                                    icon="star-empty"
                                    onClick={ ( ) => openMenuEdit( "prm" ) }
                                />
                                <MenuItem
                                    text={__("Stylization")} 
                                    icon="style"
                                    onClick={ ( ) => openMenuEdit( "html" ) }
                                />
                                <MenuItem
                                    text={__("Link")} 
                                    icon="link"
                                    onClick={ ( ) => openMenuEdit( "link" ) }
                                />
                                <MenuItem
                                    text={__("Float size and position")}
                                    icon="move" 
                                    onClick={ ( ) => openMenuEdit( "postion" ) }
                                />
                                <MenuDivider /> 
                                <MenuItem
                                    text={__("Align to container")}
                                    icon="alignment-bottom"
                                >
                                    <div className=" p-2 bg-light ">
                                        <div className=" square2 ">
                                            <ButtonGroup minimal>
                                                <Button icon="arrow-top-left" onClick={() => onAlign("top-left")} />
                                                <Button icon="chevron-up" onClick={() => onAlign("top")} />
                                                <Button icon="arrow-top-right" onClick={() => onAlign("top-right")} />
                                            </ButtonGroup>
                                            <ButtonGroup minimal>
                                                <Button icon="chevron-left" onClick={() => onAlign("left")} />
                                                <Button icon="circle" onClick={() => onAlign("center")} />
                                                <Button icon="chevron-right" onClick={() => onAlign("right")} />
                                            </ButtonGroup>
                                            <ButtonGroup minimal>
                                                <Button icon="arrow-bottom-left" onClick={() => onAlign("bottom-left")} />
                                                <Button icon="chevron-down" onClick={() => onAlign("bottom")} />
                                                <Button icon="arrow-bottom-right" onClick={() => onAlign("bottom-right")} />
                                            </ButtonGroup>
                                        </div>
                                    </div> 
                                </MenuItem>
                                <MenuDivider /> 
                                <MenuItem
                                    text={__("Stretch to container")}
                                    icon="zoom-to-fit"
                                >
                                    <MenuItem
                                        text={__("Stretch horizontal")}
                                        icon="arrows-horizontal"
                                        className={'  bg-light text-dark '}
                                        onClick={() => onStretch("horizontal")}
                                    /> 
                                    <MenuItem
                                        text={__("Stretch vertical")}
                                        icon="arrows-vertical" 
                                        className={'  bg-light text-dark '}
                                        onClick={() => onStretch("vertical")}
                                    /> 
                                    <MenuItem
                                        text={__("Stretch full")}
                                        icon="maximize"
                                        className={' bg-light text-dark '}
                                        onClick={() => onStretch("full")}
                                    /> 
                                </MenuItem>
                                <MenuDivider />                                
                                <MenuItem
                                    text={__("Clone")} 
                                    icon="duplicate"
                                    onClick={props.floatSelect?.onClone}
                                />                              
                                <MenuItem
                                    text={__("Delete")} 
                                    icon="cross"
                                    onClick={props.floatSelect?.onDelete}
                                />
                            </Menu>
                        }
                    >
                        <Button
                            icon="caret-down"
                            intent={Intent.NONE}
                            className=" " 
                            minimal 
                        >

                        </Button>  
                    </Popover2>
                    
                    <Button
                        icon="annotation"
                        intent={Intent.NONE}
                        className=" hint hint--bottom"
                        data-hint={__("Edit Float")}
                        minimal
                        onClick={props.floatSelect?.onDailogHandler}
                    > 
                    </Button>
                    <Button
                        icon="duplicate"
                        intent={Intent.NONE}
                        className=" hint hint--bottom"
                        data-hint={__("Clone")}
                        minimal
                        onClick={props.floatSelect?.onClone}
                    > 
                    </Button>
                    <Button
                        icon="cross"
                        intent={Intent.DANGER}
                        className=" hint hint--bottom"
                        data-hint={__("Remove")}
                        minimal
                        onClick={props.floatSelect?.onDelete}
                    >
                        
                    </Button>
                    
                </span>
                
                :
                null
        }
    </div>
}
export default FloatEditPanel