import { Component } from "react";
import React from "react"
import { compose } from "recompose"
import { Query, withApollo } from "react-apollo"
import { queryUserInfo } from "react-pe-layouts"
import { AppToaster, Loading } from "react-pe-useful" 
import { initArea, __ } from "react-pe-utilities"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import { cssStyle, template } from "react-pe-layouts"   
import { UserContext} from "react-pe-layout-app"
import { Intent } from "@blueprintjs/core";
import LayoutContent from "../LayoutContent";
import LayoutFooter from "../LayoutFooter";
import LayoutHeader from "../LayotHeader";

class LayoutBodyStatic extends Component
{
	constructor(props)
	{
		super(props)
		this.state = { isLoadUser : false }
		LayoutBodyStatic.contextType = UserContext
	}
    
    random;
	user = { id:-1, roles: [] }
	refetch = () => {
		AppToaster.show( {
			intent: Intent.DANGER,
			icon: "tick",
			duration: 10000,
			message: __("In offline mode, change User is unavailable"),
		} )
	}
    componentDidMount()
    { 
    	this.recoverUser() 
    }
    recoverUser = () =>
    {
        IndexDB.recover('peLayoutUser', 'peUser', 'peUser' )
			.then(dat =>
				{
					// console.log( dat[0] )
					if(dat && dat[0] && dat[0].data)
					{
						this.user = dat[0].data 
						// console.log( this.user )
						if(this.context.setUser)
						{
							this.context.setUser(this.user)
							this.setState({ isLoadUser:true, /*isRecoverUser : true*/ })
						}
					}
					else
					{
						this.setState({isLoadUser:true})
					} 
				})
    }
    renderSubContent = (user, refetch) =>
	{
		return initArea(
			"layout-app",
			{
				...this.props,
				...this.state,
				user,
				refetchUser: refetch
			},
			<div className="layout block w-100">
				{
					!template().header 
						? 
						null
						: 
						initArea(
							"layout-header",
							{
								...this.props, 
								user,
								refetchUser: refetch,
								onCurrent: this.props.onCurrent,
							},
							<LayoutHeader
								name={this.props.name}
								current={this.props.current}
								onCurrent={this.props.onCurrent}
								user={user}
								refetchUser={refetch}
							/>,
						) 
				}
				<LayoutContent
					current={this.props.current}
					onCurrent={this.props.onCurrent}
					user={user}
					onChangeStyle2={(style) => this.props.onChangeStyle(cssStyle())}
					onChangeStyle={this.props.onChangeStyle}
					refetchUser={refetch}
				/>
				<LayoutFooter {...this.props}/>
			</div>
		)
	}
	renderContent( )
	{
		const query = queryUserInfo()
		return <Query query={query}>
		{
			({ loading, error, data, refetch, previousData }) => 
			{
				if (loading) 
				{
					return <Loading />
				}
				if ( data || true ) 
				{
					let user = null
					if (data?.userInfo) 
					{
						if (data.userInfo.user) 
						{
							user = data.userInfo.user
						} 
						else 
						{
							user = data.userInfo
						}
					}
					if (!error && typeof previousData === 'undefined') 
					{
                        //console.log( this.context )
                        if(this.context.setUser)
                        {
                            this.context.setUser(user)
							IndexDB.save( user, "peUser", "peUser", "peUser", "peLayoutUser", 1 )
                        } 
					}
					if (error) 
					{
						if (localStorage.getItem("token")) {
							localStorage.removeItem("token", null)
							window.location.reload()
						}
						console.log(error);
					}		
					//console.log(template())
					return this.renderSubContent( user, refetch)
				}
				if (error) {
					if (localStorage.getItem("token")) {
						localStorage.removeItem("token", null)
						window.location.reload()
					}
					console.log(error)
				}
				return (
					<div className="media w-100 lead  fatal">
						<div className="fatal-error " />
						<div className="fatal-text">
							AAA: {__("If you see this inscription, something wrong happened: critical errors occurred on our server. We dare to assure you that our experts have already pulled on their space suits and are already poking around in orbit. So soon everything will be OK!")}
						</div>
					</div>
				)
			}
		}
		</Query>
	} 
    render()
    { 
		if(!this.state.isLoadUser)
		{
			return <Loading />
		}
		return this.state.isRecoverUser
			?
			this.renderSubContent( this.user, this.refetch)
			:
			this.renderContent()  
    }
}

export default  compose(
	withApollo
)(LayoutBodyStatic)