import React, { useEffect } from 'react'
import { Button, ButtonGroup, Callout, Dialog, Intent } from '@blueprintjs/core'
import { useState } from 'react'
import { CardFieldTypes, CARD_SEPARATOR } from '../../views/LandingState/Card'
import CardPreview from '../../views/LandingState/edit/sectionParamsEditors/CardPrevew'
import {Pagi} from "react-pe-useful"
import { __ } from 'react-pe-utilities'
import CardsTagsEditor from '../../views/LandingState/edit/sectionParamsEditors/CardsTagsEditor'
import CardsTagsFilter from '../../views/LandingState/edit/sectionParamsEditors/CardsTagsFilter'
import { gitlab_private_token } from 'settings/config'
import {Loading} from 'react-pe-useful'
import { AppToaster } from 'react-pe-useful'
import DataContext from '../../views/LandingState/DataContext'
import $ from "jquery"

const CardTemplateLib = props => {
  const url = 'https://gitlab.com/api/v4/projects/29894618/repository/files/pe-react-assets%2Fpe-landing-module-assets%2Flibs%2Fstandart.cards.template.lib.json/raw'
  const filtersUrl = 'https://gitlab.com/api/v4/projects/29894618/repository/files/pe-react-assets%2Fpe-landing-module-assets%2Flibs%2Fstandart.cards.tags.json/raw'
  const count_per_page = 1
  const [libPage, setLibPage] = useState(0)
  const [cur, setCur] = useState(0)
  const [isOpen, onOpen] = useState(false)
  const [isTry, changeTry] = useState(false)
  const [isPresetOpen, onPresetOpen] = useState(false)
  const [values, setValues] = useState(
    Array.isArray(props.value) ? [...props.value] : []
  )
  const [standartValue, setStandartValue] = useState([])
  const [standartFilters, onStandartFilters] = useState([])
  const loadTags = () =>
  {
    fetch(filtersUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'PRIVATE-TOKEN': gitlab_private_token(),
      },
    })
      .then(response => response.json())
      .then(res => {
        // console.log( res )
        onStandartFilters(res)
      })
  }

  const [allStandartValue, setAllStandartValue] = useState([])
  const [checkeds, setCheckeds] = useState([])
  const [all, setAll] = useState(values)
  const [isLoading, onLoading] = useState(true)
  const loadList = () => {    
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'PRIVATE-TOKEN': gitlab_private_token(),
      },
    })
      .then(response => response.json())
      .then(res => {
        setStandartValue(res) 
        setAllStandartValue(res) 
        //console.log( res )
        // setAll( res )
        //setAll( values.concat(res) )
        onLoading(false)
      })
  }

  useEffect(() => {
    loadTags()
    onLoading(false)
  }, [])

  //const [filters, onFilters] = useState({ tags: [] })
  const [current, onCurrent] = useState(-1)
  const col = 10

  // console.log(Math.ceil(all.length / col) - 1)
  const pagi2 = standartValue && standartValue.length
    ?
    <Pagi 
      all={Math.ceil(standartValue.length/count_per_page) - 1} 
      current={libPage} 
      pageRangeDisplayed={3}
      marginPagesDisplayed={3}
      onChoose={setLibPage} 
    />
    :
    null
  const paging = all.length > 0
    ?
    <Pagi all={Math.ceil(all.length/col) - 1} current={cur} onChoose={setCur} />
    :
    null
  const showSign = (sign , next, count, width=800, scale='.6') =>
  {
    // console.log(sign)
    return <div
      className='position-relative '
      key={next + sign.title}
    >
      <div className='lead my-2 ml-5 '>
        {sign.title}
      </div>
      <CardsTagsEditor
        card_tags={sign.card_tags}
        onCardTags={data => onCardTags(data, next, sign)}
      />
      <CardPreview 
        div_id={next}
        {...sign}
        data={{
          is_joint: sign.is_joint,
          fields: { ...sign.fields }, // шаблон полей
          cards: { ...sign.cards }, // список данных полей
          ...sign,
        }}
        scale={scale}
        count={count}
        width={width}
        palette={props.palette}
      />
    </div>
  }
  const show = (_all , is_edit = false) =>
  {
    return Array(col)
      .fill(1)
      .map((e, i) => {
        const next = cur * col + i
        //console.log(next, typeof _all[next])
        return typeof _all[next] !== 'undefined' 
          ? 
          <div key={i} className="position-relative ml-4">
            {showSign(_all[next], next, 2, 1200, '.33')}
            <ButtonGroup className=' d-flex position-absolute bottom p-3'>
              <Button
                className=''
                onClick={() => onTry(next)}
                intent={isTry ? Intent.DANGER : Intent.NONE}
              >
                {__(isTry ? 'Cancel' : 'Try')}
              </Button>
              <Button className='' onClick={() => onApply(next)}>
                {__('Apply')}
              </Button>
              <Button className="hint hint--top" data-hint={__("copy Card template")} onClick={() => onClipboardCopy(next)}>
                <i className="fas fa-file-import" />
              </Button>
              <Button
                intent={Intent.DANGER}
                className=''
                icon='cross'
                onClick={() => onPrepairDelete(next)}
              />
            </ButtonGroup>  
          </div>
          
          : 
          <div key={next}></div>
          
      })
  }
  const onCardTags = (data, next, template) => {
    //console.log(data, next)
    all[next].card_tags = data
    const vals =  [...values]
    vals.forEach((val) =>
    {
        if(val.title === template.title)
        {
            val.card_tags = data
        }
    })
    setValues(vals)    
    setAll( values.concat(standartValue) )
    //console.log(values)
  }
  const onFilterParamsStandart = params =>
  {
    console.log( params.tags)
    //onFilters(params)
    const templates = Array.isArray(allStandartValue) ? allStandartValue : []
    let vals = templates.filter((template, index) => {
      if (params.tags.length > 0) {
        return (
          params.tags.filter(tag => {
            console.log(template.card_tags)
            if (template.card_tags) {
              return (
                Array.isArray(template.card_tags) &&
                template.card_tags.filter(
                  card_tag => card_tag.title === tag.title
                ).length > 0
              )
            }
            return false
          }).length > 0
        )
      } else {
        return true
      }
    })
    //console.log(vals)
    setStandartValue( vals )
    setCur(0)
  }
  // const onFilterParams = params => {
  //   console.log(params)
  //   onFilters(params)
  //   const templates = Array.isArray(props.value) ? props.value : []
  //   let vals = templates.filter((template, index) => {
  //     if (params.tags.length > 0) {
  //       return (
  //         params.tags.filter(tag => {
  //           // console.log(template.card_tags)
  //           if (template.card_tags) {
  //             return (
  //               Array.isArray(template.card_tags) &&
  //               template.card_tags.filter(
  //                 card_tag => card_tag.title === tag.title
  //               ).length > 0
  //             )
  //           }
  //           return false
  //         }).length > 0
  //       )
  //     } else {
  //       return true
  //     }
  //   })
  //   //console.log(vals)
  //   setValues(vals)
  //   //setAll( vals.concat(standartValue) )
  //   setCur(0)
  // }
  
  const onApply = next => {
    console.log( {...all[next]} )
    if (props.onApply) props.onApply({...all[next]})
  }
  const onTry = next => {
    if (props.onTry) {
      props.onTry(all[next])
      changeTry(!isTry)
    }
  }

  const onPrepairDelete = next => {
    onOpen(!isOpen)
    onCurrent(next)

  }
  const onSingleRemove = next => { 
    //console.log(current)
    let _all = [...all]
    _all.splice(current, 1)

    setAll( _all )
    if ( !Array.isArray(DataContext.data.landing.card_templates) )
      DataContext.data.landing.card_templates = []
    DataContext.data.landing.card_templates = _all
    onOpen(false)
    if(props.onUpdate)
      props.onUpdate()
  }
  //
  const onAddToLocal = (evt, i) =>
  {
    //console.log(standartValue[i].title)
    //console.log(checkeds)
    let _checkeds = [...checkeds]
    const checked = evt.currentTarget.checked
    if(checked)
    {
      _checkeds.push( standartValue[i] )
    }
    else
    {
      _checkeds = _checkeds.filter((e, n) => e.title !== standartValue[i].title)
    }
    setCheckeds(_checkeds)
  }
  //
	const onClipboardCopy = (next) => {
		const copy = JSON.stringify({ ...all[next], clipboardLabel : all[next].title })
		//console.log(copy)
		//console.log(next)
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='StyleClipboard'>${copy}</textarea></div>`)
		const copyText = document.getElementById("StyleClipboard")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#StyleClipboard").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Card template copy to clipbord"),
		})
	}
  const handlerPresetOpen = () =>
  {
    onPresetOpen(true)
    onLoading(true)
    loadList()
  } 
  const onAddLocals  = () =>
  {
    setAll( checkeds.concat( values ) )
    if ( !Array.isArray(DataContext.data.landing.card_templates) )
      DataContext.data.landing.card_templates = []
    DataContext.data.landing.card_templates = checkeds.concat( DataContext.data.landing.card_templates )
    onPresetOpen(false)
    //console.log(props.onUpdate)
    if(props.onUpdate)
      props.onUpdate()
  }
  const jointLib = isLoading
    ?
    <Loading classNames=" text-light" /> 
    :
    Array.isArray(standartValue) && standartValue.length > 0
      ?
      standartValue.map((jointCt, i) =>
      {
        if( i >= count_per_page * libPage && i <  count_per_page * (libPage + 1) )
        {
          return <div className="position-relative py-2 border-bottom border-dark" key={i}>
            <label className="_check_ position-absolute my-3 mx-1 z-index-100 ">
              <input 
                type="checkbox" 
                onChange={ evt => onAddToLocal(evt, i) } 
                checked={ checkeds.filter(e => jointCt.title === e.title).length > 0 }
              />
            </label>
            {showSign(jointCt, i, 2, "100%", ".33")}
          </div>
        }
        else
        {
          return null;
        }
      })
      : 
      null
  return <div className='d-flex flex-column' style={{ width: 600 }}>
      <div className='bg-light w-100 mb-4 '>
        <div className="p-3">
          <Button 
            fill
            large
            minimal
            onClick={() => handlerPresetOpen(true)}
          >
            {__("Add remote templates")}
          </Button>
        </div>
        <div className='d-flex px-3'>{paging}</div>
      </div>
      {
        Array.isArray(all) && all.length > 0 
          ? 
          <div className='d-flex flex-wrap position-relative'>
            { show(all, true) }
                        
          </div> 
          : 
          (
            <Callout>{__('No templates exists')}</Callout>
          )
      }
      <div className='d-flex py-4'>{paging}</div>
      <Dialog onClose={onPrepairDelete} isOpen={isOpen} className='little'>
        <div className='p-4'>
          <div>
            <div className='mb-3'>
              {__("Remove template from page's library?")}
            </div>
            <ButtonGroup>
              <Button intent={Intent.DANGER} onClick={() => onSingleRemove()}>
                {__('Yes')}
              </Button>
              <Button intent={Intent.NONE} onClick={() => onPrepairDelete()}>
                {__('No')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Dialog>
      <Dialog 
        isOpen={isPresetOpen} 
        onClose={()=>onPresetOpen(false)} 
        className="landing-outer-container" 
        title={<div className="d-flex align-items-center">
          <div className="mr-2">{__("Joint Card templates library")}</div>
          <CardsTagsFilter
            standartFilters={standartFilters}
            onFilterParams={onFilterParamsStandart}
            col={standartValue.length}
          />        
        </div>}
      >
        
        <div className="py-4 overflow-y-auto flex-grow-100" style={{backgroundColor : props.palette ? props.palette.background_color : null, color:"#FFF"}}>
          {jointLib}
        </div>
        <div className="p-3 d-flex">
          <Button disabled={checkeds.length === 0} onClick={onAddLocals}>
            {__("Add to local library")}
          </Button>
          <div className="p-2 flex-grow-100">
            {
              checkeds.map( (e, i) => e.title).length
            }
          </div>
          <div className="px-3 d-flex flex-grow-100 justify-content-between">
            {pagi2}
          </div>
        </div>
      </Dialog>
    </div>
}
export default CardTemplateLib
/*
 *   data - список настроек полей карточки
 *   origin - значения полей текущнго набора карточек (поле fields)
 */
export const fillCardFieldTemplate = (data, origin, count) => {
  //console.log(data, origin )
  const searchField = (type, fields, i) => {
    //console.log(type, fields, i)
    return type.type
  }
  const cards = Array(count)
    .fill(0)
    .map((iter, cardNum) => {
      let fields = data.map((e, i) => {
        const t = CardFieldTypes().filter(tp => tp.type === e.type)[0]
        const field =
          Array.isArray(origin) && origin[cardNum] && false
            ? searchField(e, origin[cardNum], i)
            : Array.isArray(t.variants[parseInt(e.variant || 0)].example)
            ? t.variants[parseInt(e.variant || 0)].example.join(
                CARD_SEPARATOR()
              )
            : t.variants[parseInt(e.variant || 0)].example

        return {
          field,
          variant: 0,
        }
      })
      //console.log(fields)
      return {
        title: 'Card ' + cardNum,
        fields,
      }
    })

  return cards
}
