import React from "react"
import { withRouter } from "react-router-dom"
import { __ } from "react-pe-utilities"
import { sprintf } from "react-pe-utilities"
import BasicState from "react-pe-basic-view"

class SearchView extends BasicState {
  addRender = () => {
    console.log(this.props)
    const sres = this.props.match && this.props.match.params && this.props.match.params.id 
      ? 
      this.props.match.params.id 
      : 
      "";
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title text-center mb-3">
              {sprintf(__("Результаты поиска по запросу: %s"), sres)}
            </div>
          </div>
          <div className="col-12" />
        </div>
      </div>
    )
  }
}

export default withRouter(SearchView)
