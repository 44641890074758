import React from "react" 
import { useState, Component } from "react"
import { Intent, Slider } from "@blueprintjs/core"
import Style from "style-it"

class ExpertSliderIndepended extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            rating : props.rating,
            id: props.id
        }
    } 
    changeRating = rating =>
    {
        this.setState({ rating }) 
    }
    releaseRating = rating =>
    { 
        this.props.onFinalPersentage(rating)
    }
    render()
    {
        const {rating} = this.state
        return Style.it(
            `
            .bp3-slider-progress.bp3-intent-success
            {
                background-color: #eee;
            }
            .bp3-slider-progress
            {
                height: 16px;
            }
            .bp3-slider-track
            {
                top: 0;
                height: 16px;
            }
            `,
            <div className="mr-4 "> 
                <Slider
                    min={ 0 }
                    max={ 100 }
                    stepSize={ 1 }
                    intent={Intent.SUCCESS}
                    labelStepSize={ 10 }
                    onChange={ this.changeRating }
                    onRelease={ this.releaseRating }
                    value={ Number(rating) || 0 }
                    //labelRenderer={ val  => `${val}%`}
                    vertical={ false }
                />
            </div>
        )
    }
    
}

export default ExpertSliderIndepended