import React, { Component } from "react" 
import FieldInput from "react-pe-scalars"
import { CardFieldTypes } from "../../views/LandingState/Card"

class CardFieldVariations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

  componentDidUpdate(newProps, newState) {
    const state = {}
    if (this.state.object.variant && this.state.object.variant != parseInt(newProps.object.variant)) {
      const cardField = CardFieldTypes().filter((e) => e.type == this.state.object.type)[0] || CardFieldTypes()[0]
      // console.log( cardField, parseInt(newProps.object.variant) );
      this.setState({
        object: {
          ...this.state.object,
          variant: parseInt(newProps.object.variant),
        },
      })
    }
  }

  render() {
    const variant = parseInt(this.state.object.variant)
    const cardField = CardFieldTypes().filter((e) => e.type == this.state.object.type)[0] || CardFieldTypes()[0]
    // console.log( this.state );
    const fields = []
    for (let i = 1; i < 12; i++) {
      if (  cardField.variants 
        &&  cardField.variants[variant] 
        && cardField.variants[variant][`variant_param_${i}`]
      ) 
      {
        const matrixData = cardField.variants[variant][`variant_param_${i}`]
        //console.log( matrixData );
        fields.push(<FieldInput
          key={i}
          field={`variant_param_${i}`}
          _id={this.state.id}
          on={(value) => this.on(value, `variant_param_${i}`)}
          onChange={(value) => this.on(value, `variant_param_${i}`)}
          origin={this.state.origin}
          // visibled_value={ visibled_value }
          {...matrixData}
          editable
          value={this.state.object[`variant_param_${i}`]}
          vertical={false}
        />)
      }
    }
    return (
      <div className="p-1">

        {fields}
      </div>
    )
  }

  on = (value, field) => {
    // console.log(field, value)
    const state = { ...this.state }
    state.object[field] = value
    console.log(state)
    //this.setState(state, field)
    setTimeout(() => {
      if (this.props.on) this.props.on(field, value)
    }, 100)
  }
}
export default CardFieldVariations
