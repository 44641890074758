import { Button, ButtonGroup, Popover, Position } from "@blueprintjs/core"
import React, { useEffect, useRef, useState } from "react"
import ReactDOM from 'react-dom' 
import { __ } from "react-pe-utilities/dist";

const GridEditPanel = props =>
{
    const ref = useRef();
    const [isOpen, onOpen] = useState(false)
    const [bound, setBound] = useState({}) 
    const [descr, setDescr] = useState("") 
    const onToggleHandle = ( bool ) =>
    {
        onOpen( bool )
    }
    useEffect(() => {
        startBound()
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [ props.data ])
    const startBound = () => { 
        if(ref.current.getBoundingClientRect() === bound) return
        document.body.removeEventListener("click", onMouseLeaveHandler)
        setBound( ref.current.getBoundingClientRect() )
        document.body.addEventListener("click", onMouseLeaveHandler)
    }

    const onMouseLeaveHandler = e =>
    {
        const domNode = ref.current;
        if (!domNode || !domNode.contains(e.target)) {
            onToggleHandle(false)
        }
    }
    const onDelete = () =>
    { 
        props.onDelete( props.data.id )
    }
    const onEdit = () =>
    {
        props.onEdit( props.data.id )
    }
    const onRaze = () =>
    {
        props.onRaze( props.data.id )
    }
    const onDescr = label =>
    { 
        setDescr(label)
    }
    return <div className="grid-edit-panel" ref={ref} >
        { /*isOpen ? close() : open()*/ }
        <div style={{ position:"relative", width:0 }}>
            <Popover
                usePortal
                position={Position.RIGHT_TOP}
                enforceFocus={false}
                boundary="viewport"
                wrapperTagName="div"
                isOpen={isOpen}
                modifiers= {{
                    arrow: { enabled: false },
                    flip: { enabled: true },
                    keepTogether: { enabled: true },
                    preventOverflow: { enabled: true },
                }}
                content={
                    <div>
                        <ButtonGroup fill minimal >
                            <Button 
                                onClick={ onEdit }
                                title= {__("Edit")}
                                onMouseOver={() => onDescr(__("Edit"))}
                                onMouseOut={() => onDescr("")}
                            >
                                <i className="fas fa-edit" />
                            </Button>
                            <Button 
                                onClick={ onRaze }
                                title={__("Raze")}
                                onMouseOver={() => onDescr(__("Raze"))}
                                onMouseOut={() => onDescr("")}
                            >
                                <i className="fas fa-cut" />
                            </Button>
                            <Button 
                                onClick={ onDelete }
                                title={__("Remove")}
                                onMouseOver={() => onDescr(__("Remove"))}
                                onMouseOut={() => onDescr("")}
                            >
                                <i className="fas fa-times" />
                            </Button>
                        </ButtonGroup>
                        <div className={ `small p-${descr ? 1 : 0} text-center ` }>
                            {descr}
                        </div>
                    </div>
                    
                }
            >
                <Button
                    icon="caret-up"
                    className="little"  
                    minimal
                    onClick={() => onToggleHandle(true)}
                />
            </Popover>
        </div>
        
    </div>
}
export default GridEditPanel