import { Button, ButtonGroup, Dialog, Intent } from "@blueprintjs/core"
import React, { useState } from "react"
import FieldInput from "react-pe-scalars/dist"
import { sprintf, __ } from "react-pe-utilities/dist" 
import DataContext from "../DataContext"
import Section from "../Section"
import SectionContainer from "../SectionContainer"

const EnterlyModal = props =>
{
    const [val, setVal] = useState(1)
    const on = (value, m, dopol) =>
    {
        //console.log(value, m, dopol)  
        setVal(value) 
    }
    let mySectionOrder = -100
    const sections = Array.isArray(DataContext.data.sections) && DataContext.data.sections.length > 0
			?
			DataContext.data.sections.filter( (section, i) =>
            {
                //console.log( mySectionOrder )
                if( section.menu.id === props.id)
                {
                    mySectionOrder = i
                    return true
                }
                if( i > mySectionOrder && i < mySectionOrder + val)
                {
                    return true
                }
                return false
            })
                .map( ( sectionData, i ) => 
                {
                    return <SectionContainer key={i}>
                        <Section
							isDummy={false}
							{...sectionData}
							section_id={sectionData.id} 
							i={i}
							section_width={props.section_width }
							user={undefined}
							is_admin={false}
							is_edit={false}
							level={0}
						/>
                    </SectionContainer>
                } )
            :
            null
    const m = "slider"
    return <Dialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={__("Enterlies Sections")}
        className="large"
    >
        <div className="p-4 ">
            <FieldInput
                title={__("How many sections be entery?")}
                type={m} 
                on={(value, dopol) => on(value, m, dopol)}
                onChange={(value, dopol) => on(value, m, dopol)} 
                vertical={false}  
                editable
                step_size={1}
                min={1}
                max={10}
                value={val}  
            />
            <div style={{ overflow: "hidden", height: "70vh" }}>
                <div 
                    className="overflow-y-auto" 
                    style={{ 
                        height: "85vh",
                        width: "120vw",
                        transform: "scale(0.7) translateY(-13%) translateX(-17%)"
                    }}
                >
                    { sections }
                </div> 
            </div> 
            <div className="d-flex justify-content-center pt-2">
                <ButtonGroup>
                    <Button icon='cross' intent={Intent.DANGER} onClick={props.onClose}>{__("Close")}</Button>
                    <Button 
                        icon='floppy-disk' 
                        intent={Intent.SUCCESS} 
                        onClick={ () => props.onEnterelyLink( props.id, val ) }
                    >
                    { sprintf(__("Copy entirely link to %s sections"), val) }
                    </Button>
                    <Button 
                        icon='floppy-disk' 
                        intent={Intent.SUCCESS} 
                        onClick={ () => props.onEmbedCode( props.id, val ) }
                    >
                    { __("Copy embed code to %s sections") }
                    </Button>
                </ButtonGroup>
            </div>          
        </div>
    </Dialog>
}

export default EnterlyModal