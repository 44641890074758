import React, { Component } from "react"
import { Button, Intent, } from "@blueprintjs/core"
import { AppToaster } from 'react-pe-useful'
import {
	getChangeName,
	getInputTypeName,
	getMutationArgs,
	mutationEdit,
	getInput,
} from "react-pe-layouts"
import { __ } from "react-pe-utilities"

class PEExporter extends Component {
	render() {
		const { data_type, data_name, data } = this.props
		if (!data_name || !data_type || !data) {
			return ( 
				<div className="small">
					{__("No exists data_name parameter")}
				</div>
			)
		}
		return (
			<Button
				intent={Intent.DANGER}
				onClick={this.onServerUpdate}
				style={{ position: "absolute", bottom: 5, right: 5 }}
			>
				{__("Save to server all of ") + data_name}
			</Button>
		)
	}

	onServerUpdate = () => {
		/*
		const { data_type, data, state_element } = this.props		
		const elements = data[state_element].forEach((e, i) => {
			// if(i > 0) return;
			const input = getInput({ ...e, post_title: e.title, post_content: e.description }, data_type)
			console.log({ ...e, post_title: e.title, post_content: e.description }, input)
			return
			const mutation_name = getChangeName(data_type)
			const input_type_name = getInputTypeName(data_type)
			const mutation_args = getMutationArgs(data_type)
			const mutation_change = mutationEdit(data_type, mutation_name, input_type_name, mutation_args)
			this.props.client.mutate({
				mutation: mutation_change,
				variables:
				{
					input,
				},
				update: (store, { data }) => {
					console.log(data, getChangeName(data_type))
					const respData = data[getChangeName(data_type)]
					if (respData) {
						console.log(respData)
					}
					AppToaster.show({
						intent: Intent.SUCCESS,
						icon: "tick",
						message: __("Element insert successful."),
					})
				},
			})
		})
		  let input = getInput(state, data_type);
		  if (this.props.custom_variables) {
			  input = {...input, ...this.props.custom_variables}
		  }
		  console.log( input );
		  const mutation_name = getChangeName( data_type );
		  const input_type_name = getInputTypeName( data_type );
		  const mutation_args = getMutationArgs( data_type );
		  const mutation_change = mutationEdit( data_type, mutation_name, input_type_name, mutation_args );
		  this.props.client.mutate({
			  mutation: mutation_change,
			  variables:
			  {
				  "input": input
			  },
			  update: (store, { data: data }) =>
			  {
				  console.log( data, getChangeName( data_type ) );
				  const respData = data[getChangeName( data_type )];
				  if(respData)
				  {
					  console.log(respData);
				  }
				  AppToaster.show({
					  intent: Intent.SUCCESS,
					  icon: "tick",
					  message: __("Element updated successful.")
				  });
			  }
		  });
		  */
	}
}
export default PEExporter
