import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import {
	Button, ButtonGroup, Intent, Popover, Dialog, Icon, Tooltip, Position, Callout,
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import Matching from "./matchings/Matching"
import ContactForm from "./ContactForm"
import { getFontNameByID } from "./data/PalettePresets"
import Style from "style-it"
import chroma from "chroma-js" 
import { scrollToElementByJQuery } from "react-pe-utilities"
import $ from "jquery"

class Matchings extends SectionContent {
	timeOut = 1;

	getState() {
		return {
			current: 0,
			curr_answer_obj: {},
			chooses: [],
		}
	}

	is() {
		const { matchings } = this.state.data
		return Array.isArray(matchings) && matchings.length > 0
	}

	renderContent(style) {
		const { composition, palette } = this.props
		const {
			class_name,
			matchings,
			is_auto_switch,
			is_replay,
			start_button_label,
			start_button_class_name,
			start_button_style,
			next_button_label,
			next_button_class_name,
			next_button_style,
			finish_button_label,
			finish_button_class_name,
			finish_button_style,
			finish_button_action,
			finish_label,
			finish_inner_link_url,
			finish_inner_link_label,
			is_show_pre_result,
			right_answer_message,
			error_answer_message,
			start_message,
			finish_message
		} = this.props.data
		const { current, pause } = this.state
		const next = is_auto_switch
			? 
			null
			: 
			current < matchings.length - 0
				?
				<div>
					{
						current > 0
							? 
							<div 
								className="landing-matching-btn mr-2" 
								style={{...this.getStyle(next_button_style)}}
								onClick={this.onPrevous}
							>
								<i className="fas fa-angle-left" /> { __( "Prevous" ) }
							</div>
							: 
							null
					}
					<div 
						className={"landing-matching-btn " + next_button_class_name} 
						style={{...this.getStyle(next_button_style)}}
						onClick={this.onNext}
					>
						{__(next_button_label)}
					</div>
				</div>
				: 
				current == matchings.length - 0
					?
					<div>
						<div className="landing-matching-btn mr-2" onClick={this.onPrevous}>
							<i className="fas fa-angle-left" />
						</div>
						<div 
							className={"landing-matching-btn " + finish_button_class_name}
							style={{...this.getStyle(finish_button_style)}}
							onClick={this.onFinish}
						>
							{__(finish_button_label)}
						</div>
					</div>
					:
					null
		let cont = ""		
		if( pause  && is_show_pre_result)
		{
			// console.log( current )
			let message = ""
			switch(true)
			{
				case current === 0: //					
					message = __(start_message || "Start")
					break
				case current < matchings.length - 0: // start
					// console.log( this.state.curr_answer_obj ) 
					message = this.state.curr_answer_obj?.is_right
						?
						__(right_answer_message ? right_answer_message : "It's right.")
						:
						__(error_answer_message || "No.")
					//message += JSON.stringify( this.state.curr_answer_obj, null, '\t' )
					break
				default:
					message = this.state.curr_answer_obj?.is_right
						?
						__(right_answer_message || "It's right.")
						:
						__(error_answer_message || "No.")
					message += "<p>" + __(finish_message || "Congratulation! That's all")
			}
			cont = <div 
				style={{height: this.singleHeight ? this.singleHeight : 400}}
				className="d-flex justify-content-center align-items-center"
			>
				<pre className='display-6' dangerouslySetInnerHTML={{__html : message}} />
			</div>
		}
		else if (current == matchings.length + 1) 
		{
			cont = (
				<div>
					{this.finish()}
					<div className="landing-matchings-next-cont">
						{
							is_auto_switch
								? null
								: (
									<div className="landing-matching-btn mr-2" onClick={this.onPrevous}>
										<i className="fas fa-angle-left" />
									</div>
								)
						}
						{
							is_replay
								? (
									<div className="landing-matching-btn mr-2" onClick={this.onReplay}>
										{__("Replay")}
									</div>
								)
								: null
						}
					</div>
				</div>
			)
		} 
		else if (current == 0) 
		{
			cont = (
				<div>
					{this.start()}
					<div className="landing-matchings-next-cont">
						<div 
							className={ "landing-matching-btn " + start_button_class_name}
							style={{
								...this.getStyle(start_button_style)
							}} 
							onClick={this.onNext}
							
						>
							{__(start_button_label)}
						</div>
					</div>
				</div>
			)			
		} 
		else 
		{
			cont = (
				<>
					<Matching
						key={current - 1}
						i={current - 1}
						section_id={this.props.section_id}
						{...matchings[current - 1]}
						columns={composition.columns}
						onChoose={this.onChoose}
						palette={palette}
					/>
					<div className="landing-matchings-next-cont">
						{next}
					</div>
				</>
			)
		}
		return Style.it(
			`
			pre,
			.reverse-text {
				color:${ this.props.revertColor };
			}
			`,
			<div
				className={
					`landing-matchings landing-section-core  ${class_name || ""}`
				}
				style={{ ...style }}
			>
				{cont}
			</div>
		)
	}

	start = () => {
		const {
			class_name,
			title,
			title_class_name,
			tytle_style,
			description,
			description_class_name,
			description_style,
			thumbnail,
			thumbnail_position_y,
			thumbnail_height,
			thumbnail_fill_type,
			is_hover,
			hover_color,
			hover_size,
			hover_duration,
			hover_delay
		} = this.props.data
		const hsl = is_hover && hover_color
			?
			chroma( hover_color ).get("hsl")
			:
			[]
		return Style.it(
			is_hover
				?
				`
				.landing-matchings-thumbnail
				{
					transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
					transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
					background-position-y: ${thumbnail_position_y};
					background-image:url( ${thumbnail});
				}
				.landing-matchings-thumbnail-cont:hover .landing-matchings-thumbnail.clr
				{ 
					filter:invert(28%) sepia(100%) hue-rotate(${-112 + hsl[0]}deg) saturate(${hsl[1]}) brightness(${hsl[2] * 75 + 50}%) contrast(120%);
					opacity: ${hsl[3]};
				}
				.landing-matchings-thumbnail-cont
				{
					height: ${thumbnail_height}px;
					display:flex;
					justify-content: center;
				}
				.landing-matchings-thumbnail-cont:hover .landing-matchings-thumbnail 
				{
					transform: scale( ${hover_size ? hover_size : null} );
				}
				`
				:
				`
				.landing-matchings-thumbnail-cont
				{
					height: ${ thumbnail_fill_type === "fill" ? thumbnail_height + "px" : "auto" };
					display:flex;
					justify-content: center;
				}
				.landing-matchings-thumbnail
				{
					background-position-y: ${thumbnail_position_y};
					background-image:url( ${thumbnail});
				}`,
			<div
				className="landing-matchings-finish"
				style={{
					color: this.state.palette.main_text_color
				}}
			>
				<div className="landing-matchings-thumbnail-cont">
					{
						thumbnail_fill_type === "fill"
							?
							<div
								className="landing-matchings-thumbnail"
								style={{ }}
							/>
							:
							<img 
								src={ thumbnail }	
								style={{
									maxWidth:"100%",
									height: "100%" 
								}}
								alt={thumbnail}
							/>
					}
					
					<div
						className="landing-matchings-thumbnail-clr"
						style={{ }}
					/> 
				</div>
				<div
					className={"landing-matchings-title " + title_class_name}
					style={{
						fontFamily: getFontNameByID(this.state.palette.card.title.fontFamilyID),
						...this.getStyle(tytle_style)
					}}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<div 
					className={"landing-matchings-description " + description_class_name}
					style={{...this.getStyle(description_style)}}
					dangerouslySetInnerHTML={{ __html: description }} 
				/>
			</div>
		)
	}

	finish = () => {
		const {
			class_name,
			matchings,
			next_button_label,
			finish_button_label,
			finish_button_action,
			finish_label,
			finish_label_class_name,
			finish_label_style,
			finish_inner_link_url,
			finish_inner_link_label,
			finish_contact_form,
		} = this.props.data
		const { palette } = this.state

		switch (finish_button_action) {
			case "label":
				return (
					<div
						className="landing-matchings-finish"
						style={{
							color: palette.main_text_color
						}}
					>
						<div 
							dangerouslySetInnerHTML={{ __html: finish_label }} 
							className={"landing-matchings-finish-label "  + finish_label_class_name }
							style={{...this.getStyle(finish_label_style)}}
						/>
						{this.showResults()}
					</div>
				)
				break
			case "innerLink":
				return (
					<div
						className="landing-matchings-finish"
						style={{
							color: palette.main_text_color
						}}
					>
						<div dangerouslySetInnerHTML={{ __html: finish_label }} className="landing-matchings-finish-label mb-4" />
						{this.showResults()}
						<Link
							to={finish_inner_link_url}
							className="landing-matching-btn"
						>
							{finish_inner_link_label}
						</Link>
					</div>
				)
				break
			case "contactForm":
			default:
				return (
					<div
						className="landing-matchings-finish"
						style={{
							color: palette.main_text_color
						}}
					>
						<ContactForm
							data={{
								...finish_contact_form,
								style: { width: "100%", padding: 0 },
							}}
							type="contact_form"
							section_id={this.props.section_id}
							user={this.props.user}
						/>
						{this.showResults()}
					</div>
				)
		}
	}

	showResults() {
		const {
			chooses,
		} = this.state
		const {
			is_show_result,
			matchings,
		} = this.props.data
		if (is_show_result) {
			const results = chooses.map((result, i) => {
				if (!result.question) return
				let right = result.question.answers.filter((answer) => answer.is_right)
				// console.log( result.question.answers)
				// console.log(right)
				right = right[0] || {}
				const right_answer = result.is_right
					?
					null
					:
					<div className="landing-matching-your-right ml-3">
						<div className="text-bold mr-2 w_100  reverse-text">
							{__("Right answer: ")}
						</div>
						{
							right.question_image
								? (
									<div
										style={{ backgroundImage: `url(${right.question_image})` }}
										className="landing-matching-result-img"
									/>
								)
								: null
						}
						{right.question_label}
						<div className="landing-matching-result-sing mt-auto">
							<Icon
								icon="tick"
								intent={Intent.SUCCESS}
							/>
						</div>
					</div>
				return result.question
					? (
						<div key={i} className="p-4 tripped">
							<div className="landing-matching-single-result-title text-light" 
								style={{  }}
							>
								<span className="py-1 px-3" style={{ backgroundColor: result.is_right ? "#0d8050" : "#c23030" }}>
									{`${__("Question")} ${i + 0}`}
								</span>								
							</div>
							<div className="landing-match-single-result ">
								{
									result.question.question_image
										? <img src={result.question.question_image} className="landing-matching-result-img" />
										: null
								}
								<div className="mx-4 flex-grow-100">
									<div className="landing-match-single-result-title reverse-text">
										{result.question.title}
									</div>
									{
										result.question.description
											? (
												<div className="landing-match-single-result-description  reverse-text">
													{result.question.description}
												</div>
											)
											: null
									}
								</div>
								<div className="landing-matching-your-results  reverse-text">
									<div className="landing-matching-your-answer">
										<div className="text-bold mr-2 w_100  reverse-text">
											{__("Your answer: ")}
										</div>
										{
											result.question_image
												? (
													<div
														style={{ backgroundImage: `url(${result.question_image})` }}
														className="landing-matching-result-img"
													/>
												)
												: null
										}
										{result.question_label}
										<div className="landing-matching-result-sing mt-auto ">
											<Icon
												icon={result.is_right ? "tick" : "cross"}
												intent={result.is_right ? Intent.SUCCESS : Intent.DANGER}
											/>
										</div>
									</div>
									{right_answer}
								</div>
							</div>

						</div>
					)
					: null
			})
			return <div className="landing-matching-result">
				<div className="landing-matching-result-title reverse-text">
					{__("Your results: ")}
					{
						chooses.filter((e) => e.is_right).length + __(" rights")
					}
				</div>
				<div className=" reverse-text ">
					{results}
				</div>
			</div>
		}
	}

	onNext = (evt) => {
		if (this.state.disable) return
		const chooses = [...this.state.chooses]
		chooses[this.state.current] = this.state.curr_answer_obj
		//console.log(chooses, this.state.current)		
		this.setState({
			chooses,
			disable: true,
			pause: true
		})
		clearTimeout(this.timeOut)
		if(this.props.data.is_show_pre_result)
		{
			this.timeOut = setTimeout(() => {
				//console.log(this.state.current)
				this.setState({
					disable: false,
					current: this.state.current + 1,
					pause: false,
				})
				const targ = `[data-id="${ this.props.id }"]`
				//console.log( $(targ) )
				scrollToElementByJQuery( targ, 500 ) 
				
			}, 1000)
		}
		else
		{
			this.setState({
				disable: false,
				current: this.state.current + 1,
				pause: false,
			})
		}
	}

	onFinish = (evt) => {
		const chooses = [...this.state.chooses]
		chooses[this.state.current] = this.state.curr_answer_obj
		this.setState({ chooses, current: this.state.current + 1 })
		
		const targ = `#section-section-${ this.props.id }`
		scrollToElementByJQuery( targ, 500 )
	}

	onPrevous = (evt) => {
		const chooses = [...this.state.chooses]
		chooses.splice(this.state.current, 1)
		this.setState({
			current: this.state.current - 1,
			chooses,
		})
		const targ = `#section-section-${ this.props.id }`
		scrollToElementByJQuery( targ, 500 )
	}

	onReplay = () => {
		this.setState({ current: 0, curr_answer_obj: {}, chooses: [] })
		const targ = `#section-section-${ this.props.id }`
		scrollToElementByJQuery( targ, 500 )
	}

	onChoose = (curr_answer_obj) => {
		const { matchings } = this.props.data
		const { current } = this.state
		const obj = { curr_answer_obj: { ...curr_answer_obj, question: matchings[current - 1] } }
		// console.log(obj, matchings, current, matchings[ current - 1 ]);
		// console.log("onChoose", $( `.landing-matching-single` ).length > 0 );
		if($( `.landing-matching-single` ).length > 0)
			this.singleHeight =  $( `.landing-matching-single` ).outerHeight() 
		this.setState(
			obj,
			() => {
				if (this.props.data.is_auto_switch) this.onNext()
			},
		)
		const targ = `#section-section-${ this.props.id }`
		scrollToElementByJQuery( targ, 500 )
	}
}
export default Matchings
