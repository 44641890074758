import React, {Component} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput'; // https://react-day-picker.js.org/docs/input
import 'react-day-picker/lib/style.css';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, {
  DateUtils 
} from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

//import 'react-day-picker/moment/locale/ru';

function parseDate(str, format, locale) 
{
	const parsed = dateFnsParse(str, format, { locale });
	if (DateUtils.isDate(parsed)) 
	{
		return parsed;
	}
	return undefined;
}
function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}
export default class DateTimeForm extends Component 
{
	constructor(props) {
		super(props);
		this.handleDayChange = this.handleDayChange.bind(this);
		this.state = {
		  selectedDay: undefined,
		  isEmpty: true,
		  isDisabled: false,
		};
	}
	render()
	{
		const FORMAT = 'dd.MM.yyyy';
		return <div>
			<DayPickerInput
				formatDate={formatDate}
				parseDate={parseDate}
				className="form-control"
				format={FORMAT}
				onDayChange={this.handleDayChange}
				selectedDays={this.state.selectedDay}
				onTodayButtonClick={(day, modifiers) => console.log(day, modifiers)}
			/>
		</div>
	}
	handleDayChange = (day) => 
	{
		this.setState({
			selectedDay: day,
		});
		this.props.onChange( Date.parse(day) / 1000 );
		return;
		/**/
	}
}