import React, { useState } from 'react'
import PropTypes from 'prop-types'
import helpIcon from "../../assets/img/help.svg"
import { __ } from 'react-pe-utilities/dist'
import { Button, Dialog, Icon } from '@blueprintjs/core'
import User from 'modules/pe-fest-module/views/wpfestREST/utilities/User'

const existedVideosByUser = videos =>
{
    if( !Array.isArray(videos) || videos.length === 0 ) return false
    if(User.id > 0)
    {
        return videos.reduce( (prevous, video) => {
            return typeof video.roles === "undefined" || video.roles.length === 0 
                ?
                [ ...prevous, ...User.roles ]
                :
                [ ...prevous, ...video.roles ]
            }, [] )
            .filter(role => User.roles.filter(ur => ur === role).length > 0 )
    }
    else
    {
        return videos
            .filter( video => typeof video.roles === "undefined" || video.roles.length === 0 )
    }
}
const HelpForm = props => {
    const exists = existedVideosByUser( props.youtube )
    const [isOpen, setOpen] = useState( false )
    const [isMenu, setMenu] = useState( exists.length > 1 )
    const [currentVideo, setCurrentVideo] = useState( 
        !Array.isArray(props.youtube) 
            ? 
            props.youtube 
            :
            props.youtube[0]
                ?
                props.youtube[0]
                : 
                {} 
    )
    const changeOpen = () => {
        setOpen( !isOpen )
        setMenu( Array.isArray(props.youtube) && props.youtube.length > 1 )
    }
    const chooseCurrentVideo = current =>
    {
        setMenu(false)
        setCurrentVideo( current )
    } 
    
    return exists.length > 0 
        ?
        <>
            <div className="indicator classic" onClick={ changeOpen }>
                <div className="n1">
                    {__("Help")}
                </div>
                <div className="iconnn">
                    <img src={helpIcon} alt="" />
                </div>
            </div>
            <Dialog
                isOpen={isOpen}
                onClose={changeOpen}
                className=""
            >
                {
                    // Если для Пользователя с его набором ролей доступно больше одного ролика - нужно меню
                    exists.length > 1
                        ?
                        isMenu
                            ?
                            <HelpMenu {...props} onClick={chooseCurrentVideo}/>
                            :
                            <HelpVideo {...props} currentVideo={currentVideo}/>
                        :
                        <HelpVideo {...props} currentVideo={currentVideo}/>
                }
                <Button
                    minimal
                    onClick={changeOpen}
                    className="position-absolute top "
                    style={{ right: -40, left : "auto" }}
                >
                    <Icon iconSize={22} color="#ffffff" icon="cross"/>    
                </Button>
            </Dialog>
        </>
        :
        null
}

HelpForm.propTypes = {}

export default HelpForm

const HelpVideo = props =>
{
    return <div className='flex-centered w-100 h-100'>
        <iframe 
            width="800" 
            height="450" 
            src={ `https://www.youtube.com/embed/${ props.currentVideo.url }?autoplay=1` }
            title={ props.currentVideo.title } 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen>

        </iframe>
    </div>
}
const HelpMenu = props =>
{
    return <div className='pe-fest-help-menu'>
    {
        props.youtube
            .filter( video => {
                return (!Array.isArray( video.roles ) || video.roles.length === 0 ) ||
                    ( typeof video.roles !== "undefined" && User.isRole( video.roles ) )
            }) 
            .map((video, i) =>
            {
                return <Button 
                    key={video.url} 
                    className="lead text-uppercase" 
                    fill 
                    minimal 
                    large 
                    onClick={() => props.onClick( props.youtube[ i ] )}
                >
                    {video.title}
                </Button>
            })
    }
    </div>
}