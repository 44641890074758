import React from 'react'
import PropTypes from 'prop-types'

const FoldThecube = props => {
    return <div className='super-preloader-body '> 
        <div className="super-preloader-preloader">
            <div className="super-preloader-status">
                <div className="cssload-thecube">
                    <div className="cssload-cube cssload-c1"></div>
                    <div className="cssload-cube cssload-c2"></div>
                    <div className="cssload-cube cssload-c4"></div>
                    <div className="cssload-cube cssload-c3"></div>
                </div> 
            </div>
        </div>   
    </div>  
}

FoldThecube.propTypes = {}

export default FoldThecube