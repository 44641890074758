import React, { Component } from "react"
import { Button, Tag } from "@blueprintjs/core"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router"
import Layouts, { schema } from "react-pe-layouts"

import BasicState from "react-pe-basic-view" 
import { importAll, __ } from "react-pe-utilities"; 
import Moment from "react-moment"
import Feed from "./core/Feed"
//import { Feed } from "react-pe-basic-view" 
import {  getSingleRoute } from "react-pe-layouts"
import { Link } from "react-router-dom"
import { data_type_link_url } from "react-pe-utilities"

const components = {};
const lazies = {};
const plViews = [];
class FeedDataTypeView extends BasicState {
    

    basic_state_data() { 
        //const { feed_data_type } = this.props
        this.imppp()
        return { 
            count : this.props.count || 10,
            offset : 0,
            feed_data_type : this.props.feed_data_type,
            feed_data_type_parent_id : this.props.feed_data_type_parent_id,
        }
    } 
    imppp() {  
        try{
            // Все Компоненты из папки states для последующей подстановки в роутинги
            importAll(require.context("states/", false, /\.js$/), [], components, lazies, "states/"); // Собираем все компоненты из папки modules (которые указаны в layouts.json, раздел modules)
            // для последующей подстановки в роутинги

            const plgns = Layouts().modules;
            Object.keys(plgns).forEach(plugin => {
                Object.keys(plgns[plugin].views).forEach(view => {
                    plViews.push(view);
                });
            });
            //console.log( plViews )
            importAll(require.context("modules/", true, /\.js$/), [], components, lazies, "modules/");
        }
        catch(e)
        {
            
        }
    
    }

    componentDidUpdate(nextProps)
    {
        if(
            nextProps.feed_data_type !== this.state.feed_data_type ||
            nextProps.feed_data_type_parent_id !== this.state.feed_data_type_parent_id            
        )
        {
            this.setState(nextProps)
        }
    }
    getCardComponent = () =>
    {

    }
    addRender() {
        // console.log(this.props)
        const { offset, count, height } = this.state
        const { feed_data_type, feed_data_type_parent_id } = this.state
        //console.log( feed_data_type, schema())
        console.log( feed_data_type, schema()[ feed_data_type ]['feed_view'] )
        console.log( components[ schema()[ feed_data_type ][ 'feed_view' ] ] )
        const paging = feed_data_type_parent_id
            ?
            ` parent: "${feed_data_type_parent_id}" `
            :
            ``
        let ElComponento = schema()[ feed_data_type ] 
            && schema()[ feed_data_type ][ 'feed_view' ]
            && components[ schema()[ feed_data_type ][ 'feed_view' ] ]
            ?
            components[ schema()[ feed_data_type ][ 'feed_view' ] ].default
            :
            _Component
        return <div className={`p-0 d-flex flex-column w-100 `}>
            {

            }
            <Feed
                component={ ElComponento }
                data_type={ feed_data_type }
                is_hide_pagi={false}
                offset={offset || 0}
                count={count}
                height={height}
                paging={paging }
                class_name={` row`}
                params={{ 
                    col: 2
                }}
                containerClassName={this.props.feed_containerClassName || "short-container"}
            />
            {

            }
        </div>
    } 
}

export default compose(
    withRouter,
    withApollo,
)(FeedDataTypeView)

export class _Component extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            ...this.props
        }
    }
    goto = (link) =>
    {
        alert(link)
    }
    render()
    {
        let tags = []
        const routeSingle = getSingleRoute(this.state.__typename)
        const data_scheme = schema()[ this.state.__typename ]
        const link = routeSingle ? routeSingle.route : null
        const visibled_value = data_scheme.visibled_value
            ?
            data_scheme.visibled_value
            :
            "id"
        
        //console.log( this.props )
        Object.keys(this.state).forEach((tax, i) =>
        {
            if(
                !Array.isArray(this.state[tax]) || 
                this.state[tax].length === 0 ||
                data_scheme.apollo_fields[tax]?.filter_type !== "taxonomies"
            ) 
                return
            //

            // ищем в схеме тип данных у всех массивов state'a
            const schemaElement = Object.keys(schema()).filter((data_type, index) =>
            {
                return data_type === this.state[tax][0].__typename
            })[0]

            // нашли Тип Данных.
            if(schemaElement)
            {
                //console.log(schemaElement)
                this.state[tax].forEach((tag, ii) =>
                {
                    const visibled_value = schema()[schemaElement].visibled_value
                        ?
                        schema()[schemaElement].visibled_value
                        :
                        tag.id
                    tags.push(<Link 
                        to={
                            "/" + data_type_link_url( schemaElement ) + 
                            "/" + tag.id
                        }
                    >
                        <Tag 
                            key={ii + Math.random()} 
                            style={{
                                backgroundColor: schema()[schemaElement].admin_data.fill 
                                    ?  
                                    schema()[schemaElement].admin_data.fill[1]
                                    : 
                                    "#444",
                                cursor: "pointer" 
                            }}
                            title={__(schema()[schemaElement].name)}
                        >
                            { tag[visibled_value] }   
                        </Tag>
                    </Link>) 
                })
            }
        })
        var regex = /(<([^>]+)>)/ig
        var postDateBlock = this.state.post_date
            ?
            <div className="post-date">
                <Moment locale="ru" format="D.MM.YYYY ">
                    {new Date(this.state.post_date)}
                </Moment>
            </div>
            :
            null
        const linkBlock = link
            ?
            <Link
                to={"/" + link + "/" + this.state.id }
                className="ml-auto"
            >
                <Button 
                    minimal
                    rightIcon="chevron-right" 
                >
                    {__("More")}
                </Button>
            </Link>
            :
            null
        let thumb = "thumbnail"
        switch(this.state.__typename)
        {
            case "User":
                thumb = "avatar"
                break
            default:
                thumb = "thumbnail"
        }
        let orderLabel
        if(this.props.order)
        {
            orderLabel=<div className="single-data-type-order position-absolute ">
                {this.props.order}
            </div>
        }
        const linkBack =  link
            ?
            <Link
                to={"/" + link + "/" + this.state.id }
                className="ml-auto"
            >
                <div 
                    className="post-thumbnail m-0" 
                    style={{

                        backgroundImage:"url("+ this.state[thumb] + ")"
                    }}
                >
                    
                </div>
            </Link>
            :
            <div 
                className="post-thumbnail m-0" 
                style={{

                    backgroundImage:"url("+ this.state[thumb] + ")"
                }}
            >
                
            </div>
        let colClass = " col-12 "
        if(this.state.col)
        {
            switch(this.state.col)
            {
                case 2:
                    colClass = " col-md-6 "
                    break;
                case 3:
                    colClass = " col-md-4 "
                    break;
                case 4:
                    colClass = " col-md-3 "
                    break;
                case 1:
                default:                
                    colClass = " col-12 "
                    break;

            }
        }
        
        return <div className={colClass + " " + this.state.cardClass }>
            <div className="m-2 p-0 d-flex flex-row h-100">
                {linkBack}
                {orderLabel}
                <div className="d-flex flex-column p-4 flex-grow-100">
                    <h5>
                        { this.state[visibled_value] }
                    </h5> 
                    <div>
                        {tags}
                    </div>
                    <div className="py-3">
                        { 
                            this.state.post_content 
                                ? 
                                this.state.post_content.replace(regex, "").substring(0, 200)
                                :
                                "" 
                        }...
                    </div>
                    <div className="d-flex mt-auto align-items-end">
                        {postDateBlock}
                        {linkBlock}
                        
                    </div>
                </div>
                
            </div>
        </div>
    }
}