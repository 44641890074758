import { Intent } from '@blueprintjs/core'
import gql from 'graphql-tag'
import { compose } from "recompose"
import { withApollo } from "react-apollo" 
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { updateApp, updateFolder, updateRoutes, updateTemplate, updateWidgets } from 'react-pe-layouts'
import { AppToaster, Loading } from 'react-pe-useful'
import { initWidgets, __ } from 'react-pe-utilities' 
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import LayoutBodyStatic from './LayoutBodyStatic'
import queryInit from './queryInit'

const layoutVersion = localStorage.getItem("pe-layout-version") || 0

class LayoutBodyExtended extends Component 
{ 
    constructor(props)
    {
        super(props)
        this.state = {
            content : null,
            isRecoverLoad: false,
            isRecover: false
        }
    }
    componentDidMount()
    {        
        const recover = IndexDB.recover( 'peLayoutData', 'Init', 'initObject' )        
        recover.then(rec => {
            if(rec.length)
            {
                updateRoutes(this.parseJSON(rec.filter(r => r.initObject === "menu")[0].data.json));
                updateFolder(this.parseJSON(rec.filter(r => r.initObject === "folder")[0].data.json));
                updateApp(this.parseJSON(rec.filter(r => r.initObject === "public_options")[0].data.json.replace(/'/g, '"')));
                updateWidgets( {} );
                initWidgets() 
                this.setState( {
                    isRecoverLoad: true,
                    isRecover:true 
                } ) 
            }
            else
            {
                this.setState( { isRecoverLoad: true } ) 
            }
                
        })
    }
    parseJSON = (json) =>
    {
        try 
        {
            return JSON.parse(json)
        }
        catch(e)
        {
            return []
        }
    }
    renderQuery = () =>
    {
        return <>
            <Query query={ queryInit() }>
            {
                (ret) => {
                    //console.log( ret )
                    if (ret.loading) {
                        return <Loading />
                    }
                    if ( ret.data ) 
                    { 
                        if( parseFloat(ret.data.getInit.version) >  parseFloat(layoutVersion) )
                        {
                            // console.log( parseFloat(ret.data.getInit.version) >  parseFloat(layoutVersion) )
                            IndexDB.add( ret.data, parseFloat(ret.data.getInit.version) )
                        }
                        try
                        {
                            updateRoutes(
                                ret.data.getInit.menu.json 
                                    ? 
                                    this.parseJSON( ret.data.getInit.menu.json )
                                    :
                                    {}
                                )
                        } 
                        catch(error)
                        {
                            console.error( error.message, ": ", ret.data.getInit.menu ) 
                        }
                        try
                        {
                            updateFolder(
                                ret.data.getInit.folder.json 
                                    ? 
                                    this.parseJSON( ret.data.getInit.folder.json )
                                    :
                                    {}
                                )
                        }							
                        catch(error)
                        {
                            console.error( error.message, ": ", ret.data.getInit.folder ) 
                        }
                        
                        try
                        {
                            updateApp(this.parseJSON( ret.data.getInit.public_options.json.replace( /'/g, '"' ) ))
                        }							
                        catch(error)
                        {
                            console.error( error.message, ": ", ret.data.getInit.public_options ) 
                        }
                        try
                        {
                            updateWidgets(
                                ret.data.getInit.widgets.json
                                    ?
                                    this.parseJSON( ret.data.getInit.widgets.json )
                                    :
                                    {}
                            )
                            initWidgets()
                        }
                        catch(e) 
                        { 
                            console.error( e.message, ": ", ret.data.getInit.widgets ) 
                        }
                        try
                        {
                            updateTemplate(this.parseJSON( ret.data.getInit.template.json ))
                        }
                        catch(e) 
                        { 
                            console.error(e.message) 
                        }
                        return <LayoutBodyStatic
                            {...this.props}
                        />
                    } 
                    if (ret.error) {
                        return (
                            <div className="media w-100 lead  fatal">
                                <div className="fatal-error" />
                                <div className="fatal-text">
                                    {__("If you see this inscription, something wrong happened: critical errors occurred on our server. We dare to assure you that our experts have already pulled on their space suits and are already poking around in orbit. So soon everything will be OK!")}
                                </div>
                            </div>
                        )
                    }
                    return <div className="media w-100 lead  fatal">
                        <div className="fatal-error" />
                        <div className="fatal-text">
                            {__("Since you are here for the first time, and currently you have problems with the Internet, we will not be able to download even an emergency configuration for you. You need to be able to communicate with your remote server in order to take advantage of the emergency configuration facilities.")}
                        </div>
                    </div>				
                }
            }
            </Query>
        </>
    }
    render()
    { 
        if( !this.state.isRecoverLoad )
        {
            return <Loading />
        }
        // TODO: сейчас неправильно. Нужно сначала отрисовать версию с ИндексДБ, а потом начать грузить ыерсию с сервера. После загрузки надо проверить версию сервера, если она свежее - отрисовать её
        if( this.state.isRecover )
        {
            return <LayoutBodyStatic
                {...this.props}
            />
        }
        else
        {
            return this.renderQuery()
        }
        
		
    }
}
export default  compose( withApollo )(LayoutBodyExtended)
