import { Button, Icon } from "@blueprintjs/core"
import React, { useState } from "react"
import ReactDOM from 'react-dom' 
import { __ } from "react-pe-utilities/dist"

const Panel = props =>
{
   const [ mode, changeMode ] = useState("closed")
   const onMode = () => {  
        document.body.parentElement.style.marginTop = mode === "closed" ? "50px" : "0px"
        changeMode( mode === "open" ? "closed" : "open" )
   }
   const getPanel = () =>
    {
        return <div className={`grid-layout-panel ${mode} `}>
            PANEL
            <div className="d-flex">
                <div className="d-flex px-4">
                    <Button
                        icon={<i className="fas fa-cut" />}
                        onClick={props.onCopyClipboard}
                    >
                        {__("Copy layouts data to clipboard")}
                    </Button>
                    <Button
                        icon={<i className="fas fa-cut" />}
                        onClick={props.onPasteFromClipboard}
                    >
                        {__("Paste layouts data from clipboard")}
                    </Button>
                </div>
                <div className="d-flex">
                    <Button onClick={props.onToggleLayout}>
                        toggle grids
                    </Button>
                </div>
            </div>
            <div
                onClick={ onMode }
                className="mode-bottom"
            >
                <Icon icon={mode !== "open" ? "caret-down" : "caret-up"} />
            </div>
        </div>
    }
    return ReactDOM.createPortal(
        getPanel(),
        document.body
    )

}

export default Panel