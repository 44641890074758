import React, { Component, Fragment } from "react" 
import Critery from "./Critery"

class CriteryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  } 
  
  render() {
    //console.log(this.props.criteries) 
    
    const criteries = Array.isArray(this.props.criteries) 
      ?
      this.props.criteries
        .filter(e => this.props.milestone && e.milestone_id[0] ? e.milestone_id[0] === this.props.milestone.ID : true)
          .map((critery, i) => {
            return <Critery
              key={critery.id + "_" + i}
              {...critery}
              category_id={this.props.category_id}
              global_max_raiting={this.props.max_raiting}
              rating_type={this.props.rating_type}
              category_rating_type={this.props.category_rating_type}
              user={this.props.user}
              color={this.props.color}
              member_id={this.props.member_id}
              my_honeycombs={this.props.my_honeycombs}
              milestone={this.props.milestone}
              is_owner={this.props.is_owner}
            /> 
         })
      :
      null
    return (
      <div className="container-float">       
        {criteries}
      </div>
    )
  }
}
export default CriteryList
