import React, { Component } from "react"
import { NumericInput, Position } from "@blueprintjs/core"

class CSSSize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value ? props.value : "1px",
      numeric: parseFloat(props.value ? props.value : "1px"),
      postfix: this.getPostfix(props.value ? props.value : "1px"),
    }
  }

  getPostfix = (string = "0") => {
    let postfix = "px"
    const str = string.toString()
    postfixes().forEach((e) => {
      if (str.indexOf(e.id) > 0) {
        postfix = e.id
      }
    })
    return postfix
  }

  onPostfix = (evt) => {
    const { value } = evt.currentTarget
    this.on("postfix", value)
  }

  onNumeric = (value) => {
    this.on("numeric", value)
  } 

  on = (field, value) => {
    const state = { ...this.state }
    state[field] = value
    state.numeric = ["px", "%", "vh", "vw"].filter((e) => e == this.state.postfix).length > 0
      ? parseInt(state.numeric)
      : state.numeric
    state.value = state.numeric + state.postfix
    this.setState(state)
    if (this.props.on) {
      setTimeout(() => this.props.on(state.value), 100)
    }
  }

  render() {
    const {
      numeric, postfix, min, max, value
    } = this.state
    // console.log(numeric);
    return <div className="d-flex css-style-for w-100">
        <NumericInput
          value={numeric}
          min={min || null}
          max={max || 10000000}
          onValueChange={this.onNumeric}
          buttonPosition={Position.LEFT}
          stepSize={["px", "%", "vh", "vw"].filter((e) => e == postfix).length > 0 ? 1 : 0.1}
          className=" "
        />
        <select className="form-control " value={postfix} onChange={this.onPostfix}>
          {
            postfixes().map((e, i) => (
              <option
                key={e.id}
                value={e.id}
              >
                {e.id}
              </option>
            ))
          }
        </select>
      </div>
  }
}
export default CSSSize
export function postfixes() {
  return [
    { id: "px" },
    { id: "em" },
    { id: "rem" },
    { id: "%" },
    { id: "vh" },
    { id: "vw" },
  ]
}
