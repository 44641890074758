import React, { Component } from "react"
import {
  Button, Intent, Icon, Dialog, Popover, PopoverInteractionKind, Position, ButtonGroup,
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 
import InputForm from "./edit/InputForm"

class EditLabel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      isOpen: false,
    }
  }
  componentDidUpdate(nextProps) {
    if (nextProps.is_edit !== this.state.is_edit) {
      this.setState({ is_edit: nextProps.is_edit })
    }
  }
  render() {
    // return null;
    return this.state.is_edit
      ? (
        <>
          {this.state.isBtn ? this.btn() : this.pic()}
          <Dialog
            isOpen={this.state.isOpen}
            onClose={this.onDailogHandler}
            className="little2"
            title={__("Edit") + ": " +__(this.props.source)}
          >
            <div className="p-4 dialog-content overflow-y-auto">
              {this.dialogContent()}
            </div>
            <ButtonGroup className="d-flex justify-content-center pt-2" fill={true} large={true}>
              <Button
                intent={Intent.SUCCESS}
                onClick={this.onEdit}
                className="flex-grow-100"
                icon="cog"
              >
                {__("Edit")}
              </Button>
              <Button
                intent={Intent.DANGER}
                onClick={this.onDailogHandler}
                className="flex-grow-1"
                icon="cross"
              >
                {__("Close")}
              </Button>
            </ButtonGroup>
          </Dialog>
        </>
      )
      : null
  }

  pic() {
    return (
      <div
        className="l-inline-edit-btn"
        onClick={this.onDailogHandler}
        style={{ width: this.props.style.width, height: this.props.style.height }}
      >
        <Icon icon="annotation" />
      </div>
    )
  }

  btn() {  
    return this.props.children 
      ?
      <Popover
        className="edit-btn-cont"
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.LEFT_TOP}
        content={
          <div className=" ">
            <ButtonGroup vertical={true} fill={true} alignText="left" large={true}>
              <Button
                minimal={true}
                onClick={this.onDailogHandler}
              >
                {__("Edit")}
              </Button>
              {
                this.props.children
              }
            </ButtonGroup>
          </div>
        }
      >
        <div
          className="edit-btn"
          style={this.props.st}
        >
          <i className="fas fa-ellipsis-v" />
        </div>
      </Popover>
      :
      <div className="edit-btn-cont">
        <div
          className="edit-btn"
          style={this.props.st}
          onClick={this.onDailogHandler}
        >
          <Icon icon="edit"/> 
        </div>
      </div>
  }

  onDailogHandler = evt => {
    evt.stopPropagation()
    this.setState({ isOpen: !this.state.isOpen })
  }

  onEdit = () => {
    this.setState({ isOpen: false })
    if (this.props.onEdit)
      this.props.onEdit(this.state.data, this.props.id)
  }

  onField = (value, field, block, dopol) => {
    // console.log( value, field, block );
    const state = { ...this.state }
    if (!state[block]) state[block] = {}
    state[block][field] = value
    // console.log( state );
    // if (this.state.type == "cards") {
    //   if (field == "fields" && dopol && Array.isArray(dopol)) {
    //     state.data.cards = dopol
    //   }
    // }
    //console.log(state.data)
    this.setState({ data: state.data })
  }

  dialogContent() {
    //console.log( matrix.Card )
    //console.log( this.state.data )

    return <div className="p-4">
      <InputForm
        {...this.state.data}
        source={this.state.source}
        id={this.state.id}
        data={this.state.data}
        sourceType={this.state.source}
        on={(value, field, dopol) => this.onField(value, field, "data", dopol)}
      />
    </div>

    /*
    let html = [];
    for(let m in matrix[this.props.source])
    {
      if(matrix[this.props.source][m].hidden) continue;
      const cntxt = DataContext.getSection( this.state.id );
      const src = cntxt && cntxt[this.props.source]
          ? DataContext.getSection( this.state.id )[this.props.source][m]
          : "";
      html.push( <FieldInput
            field={ m }
            key={ m }
            id={this.props.ID}
            on={this.on}
            onChange={this.on}
            { ...matrix[this.props.source][m] }
            editable = { true }
            value={ src }
            vertical={ false }
            visibled_value={ "title" }
          /> )
    }
    return html;
    */
  }

  on = (data, field) => {
    console.log(data, field)
  }
}
export default EditLabel
