import React, { useState } from "react";
import { LayoutIcon } from "react-pe-useful/dist";
import { components } from "../../data/components";
import { getColor, getRevertColor } from "../../data/getColor";
import Style from "style-it"
import { Button, Collapse, Icon } from "@blueprintjs/core"; 
import { __ } from "react-pe-utilities/dist"; 
import { getPalette } from "../../Section";
import DataContext from "../../DataContext";

const SectionLayerBtn = props =>
{
    const {section} = props
    const [isFloatOpen, setisFloatOpen] = useState(false)
    // console.log( section )
    const bgcolor = getColor( 
        section.background?.color 
          ? 
          section.background?.color 
          : 
          getPalette(section.current_template_id)?.background_color, 
        section.palette 
      ) 
    const color = getRevertColor( 
        section.background?.color 
        ? 
        section.background?.color 
        : 
        getPalette(section.current_template_id)?.background_color, 
        section.palette 
    ) 
    const onFloatDelete = floatId =>
    { 
        DataContext.deleteFloatId( floatId )
        DataContext.view.setState({ s: !DataContext.view.state.s, is_not_update: true })
        props.onUpdate()
    }
    const onSectionDelete = sectionId =>
    { 
        DataContext.deleteSectionId( sectionId )
        DataContext.view.setState({ s: !DataContext.view.state.s, is_not_update: true })
        props.onUpdate()
    }
    const onSectionParams = (sectionId, tabId) =>
    {
        const section = window.pe_landing.section.filter(section => section.props.id === sectionId)[0]
        console.log( window.pe_landing.section, section, sectionId )
        if(!section) return
        section.onDialogOpen( tabId, "type" )
    }
    const onSectionHide = (sectionId) =>
    {
        const section = window.pe_landing.section.filter(section => section.props.id === sectionId)[0]
        console.log( window.pe_landing.section, section, sectionId )
        if(!section) return
        section.props.onHide( sectionId, !section.props.is_hidden )
    }
    return Style.it(
        `
        .landing-section-layer
        {
            background-color:${ bgcolor };
            color: ${color};
        }
        .landing-section-layer svg
        {
           fill: ${color};
        }
        .landing-section-float-btn
        {
            display:flex;
            align-items:center;
        }`,
        <div className="landing-section-layer">
            <div className="landing-section-layer-btn" data-section-id={section.id}>
                <div 
                    className="landing-section-layer-type hint hint--right" 
                    data-hint={components()[ section.type || "html"].title}
                    onClick={ () => onSectionParams( section.id, "types" ) }
                >
                    <LayoutIcon
                        src={components()[ section.type || "html"].icon}
                        className=" layout-icon-micro px-2 dark "
                    />
                </div>
                <div className="landing-section-layer-collapse-btn " >
                {
                    section.floats?.length > 0
                    ?
                    <Button                   
                        minimal
                        className="mr-2"
                        onClick={() => setisFloatOpen(!isFloatOpen)}
                    >
                        <Icon icon={ isFloatOpen ? "caret-down" : "caret-right"} iconSize={12}/>
                    </Button> 
                    :
                    <Button                   
                        minimal 
                        disabled
                        className="unvisibled mr-2"                        
                    >
                        <Icon icon={ "blank"} iconSize={12}/>
                    </Button> 
                }                    
                </div>
                <div className="landing-section-layer-title">
                    { section.title?.text ? section.title?.text : section.id }
                </div>
                <div className="landing-section-layer-visible">
                    <Button                   
                        minimal
                        className="flex-centered hint hint--left"
                        data-hint={ __("Current pattern: ") + __( section.palette?.title) }
                        onClick={ () => onSectionParams( section.id, "template" ) }
                    >
                        <Icon icon={ "style"} iconSize={12}/>
                    </Button>
                </div>
                <div className="landing-section-layer-pattern">
                    <Button                   
                        minimal
                        className="flex-centered hint hint--left"
                        data-hint={__( section.is_hidden ? "Do show" : "Do hide")}
                        onClick={ () => onSectionHide( section.id ) }
                    >
                        <Icon icon={ section.is_hidden ? "eye-open" : "eye-off"} iconSize={12}/>
                    </Button>
                </div>
                <div className="landing-section-layer-delete">
                    <Button                   
                        minimal
                        className=""
                        onClick={ () => onSectionDelete( section.id ) } 
                    >
                        <Icon icon={ "trash" } iconSize={12}/>
                    </Button>
                </div>
                
            </div>
            <Collapse
                isOpen={isFloatOpen}
            >
                <div className="pb-0 pt-1 pl-4 pr-2 small letter-spacing-5 ">
                    floats
                </div>
                {
                    section.floats?.map((float) =>
                    {
                        if(!float) return null
                        //console.log( float )
                        return <div className="landing-section-float-btn " key={float.id}>
                            <div className="landing-section-layer-type pl-4" >
                                <LayoutIcon
                                    src={components()[ float.type || "html" ].icon}
                                    className=" layout-icon-micro pr-2 "
                                />
                            </div>
                            <div className="landing-section-layer-title small">
                                { float.id }
                            </div>
                            <div className="landing-section-layer-delete">
                                <Button                   
                                    minimal
                                    className=" " 
                                    onClick={ () => onFloatDelete( float.id ) }
                                >
                                    <Icon icon={ "trash" } iconSize={12}/>
                                </Button>
                            </div>
                        </div>
                    })
                }          
            </Collapse>
        </div>
        
    )
}
export default SectionLayerBtn