import React, { Component } from "react"
import {LayoutIcon} from 'react-pe-useful'
import EditLabel from "./EditLabel" 
import matrix from "./data/matrix"
import Style from "style-it"
import { components } from "./data/components"
import { __ } from "react-pe-utilities/dist"
import { getRevertColor } from "./data/getColor"
import { getScreenSize } from "modules/pe-landing-module/widgets/utilities/ScreenSizeList"

class SectionContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_edit: this.props.is_edit,
      ...props,
      ...this.getState(),
    }
  }

  getState(  ) {
    return {}
  }

  getCanvasWidth = () =>
  {
    const { canvas_size } = this.state.data
    if( !canvas_size ) return 220
    const w = canvas_size[ getScreenSize( document.body.clientWidth) ]
    return w
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.is_edit !== this.state.is_edit) {
      this.setState({ is_edit: nextProps.is_edit })
    }
    if (nextState.is_edit !== this.state.is_edit) {
      this.setState({ is_edit: nextState.is_edit })
    }
    if (nextProps.data !== this.state.data) {
      // console.log(nextProps.data )
      this.setState({ data: nextProps.data })
    }
    if (nextProps.composition !== this.state.composition) {
      this.setState({ composition: nextProps.composition })
    }
    if (nextProps.section_id !== this.state.section_id) {
      this.setState({ section_id: nextProps.section_id })
    }
    if (nextProps.current_template_id !== this.state.current_template_id) {
      //console.log(nextProps.current_template_id)
      this.setState({ current_template_id: nextProps.current_template_id })
    }
    if (nextProps.palette !== this.state.palette) {
      //console.log(nextProps.palette)
      this.setState({ palette: nextProps.palette })
    }
    let state = {}
    const mt = matrix()[this.state.type]
    // console.log( "nextState: ", nextState, this.state.type )
    if (!mt) return
    Object.keys({ ...mt })
      .filter((e) => !mt[e].hidden)
      .forEach((e, i) => {
        if (nextProps[e] !== this.state[e] && typeof nextProps[e] !== "undefined") {
          //console.log(e, nextProps[e]);
          state[e] = nextProps[e]
        }
        if (nextState[e] !== this.state[e] && typeof nextState[e] !== "undefined") {
          //console.log(e, nextState[e]);
          state[e] = nextState[e]
        }
      })
    if (nextProps.section_width != this.state.section_width) {
      state.section_width = nextProps.section_width
      this.updateWidth(nextProps.section_width)
    }
    state = this.didUpdate(state, nextProps, nextState)
    // console.log(this.props.type, state);
    if (Object.keys(state).length > 0) {
      this.setState(state)
    }
  }

  didUpdate(state, nextProps, nextState) {
    // console.log(state);
    return state
  }

  updateWidth(width) {

  }
  onEditContent = (text, field_matrix) =>
  {
      //console.log( text, field_matrix )
      if(this.props.onEditContent)
      this.props.onEditContent( text, field_matrix )
  }
  onEditOpen = ( field_matrix ) =>
  {
    if(this.props.onEditOpen)
      this.props.onEditOpen( field_matrix, this.props.type )
  }

  render() {
    const style = this.props.data && this.props.data.style ? this.getStyle( this.props.data.style ) : {} 
    //console.log(this.props.data.style, style, type);
    const is = this.is()
    return is
      ? this.renderContent(style)
      : this.getEmpty(style)
  }

  renderContent(style) {
    const {palette} = this.props;
    const { class_name, text, height, overflow } = this.props.data
    return Style.it(
      `.landing-section
      {
        overflow: ${ overflow === "visible" ? "visible" : "hidden" };
      },
      .landing-html
      {
        color:${ palette ? palette.main_text_color : null};
      }`,
      <div
        className={
          `landing-html ${class_name || ""
          } columns-${this.state.composition.columns}`
        }
        style={{
          ...style, height, overflowX: "hidden", overflowY: "auto",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} className="w-100" />
      </div>
    ) 
  }

  is() {
    return this.props.data.text
  }

  getStyle = (styleObj) => 
  {
    const style = []
    if (!styleObj) return style
    // console.log( styleObj );
    Object.entries(styleObj)
      .filter((e) =>
        // console.log( e );
        e[1] && e[1].field !== "")
      .forEach((e, i) => 
      {
        // console.log( e );
        if (e[1] && e[1].field) 
        {
          //const attr = {}
          style[e.field] = e.value
        } 
        else 
        {
          style[e[0]] = e[1]
        }
      })
    // console.log( style );
    return style
  }

  getPallete(fields = "") {
    const { palette } = this.state
    const flds = fields.split(".");
    let p = { ...palette }
    flds.forEach(e => {
      p = p[e] ? p[e] : p
    })
    return p;
  }

  getEmpty(style) {
    const { class_name } = this.props.data 
    const color = getRevertColor( 
      this.props.background?.color 
        ? 
        this.props.background?.color 
        : 
        this.props.palette?.background_color, 
      this.props.palette 
    ) 
    return Style.it(
      `
      .layout-icon-giant.white svg, .layout-icon.white svg
      {
        fill: ${ color };
      }`,
      <div
        className={` landing-empty p-4 ${class_name || ""}`}
        style={{ 
          height:     this.height ? this.height : "auto", 
          maxHeight:  this.height ? this.height : "auto", 
          ...style 
        }}
      >
        <LayoutIcon
          src={components()[this.props.type || "html"].icon}
          className=" layout-icon white "
        />
        <div className=" pt-3" style={{ color : color }}>
          { __(components()[this.props.type || "html"].title)}
        </div>
        <EditLabel
          {...this.props}
          source={this.props.type || "html"}
          onEdit={this.props.onEdit}
          isBtn
        />
      </div>
    )
  }
} 
export default SectionContent

export  const getStyle = (styleObj) => 
{
  const style = []
  if (!styleObj) return style
  // console.log( styleObj );
  Object.entries(styleObj)
    .filter((e) =>
      // console.log( e );
      e[1] && e[1].field !== "")
    .forEach((e, i) => 
    {
      // console.log( e );
      if (e[1] && e[1].field) 
      {
        //const attr = {}
        style[e.field] = e.value
      } 
      else 
      {
        style[e[0]] = e[1]
      }
    })
  // console.log( style );
  return style
}
