import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; 
import CardField from "../CardField";
import {default as UUID} from "node-uuid";

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  display:"flex",
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "grey" : "transparent",
  width: isDragging ? "auto" : "100%",    
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#00000012" : "transparent",
  padding: grid,
  width: "100%"
});
const setItems = cardFields =>
{
    let items = []
    cardFields.forEach((field, i) => 
    {
        let grid = items.filter(item => item.grid_id == field.grid_id)[0]
        if(!grid)
        {
            grid = { fields:[], grid_id: field.grid_id }
            items.push(grid)
        }
        grid.fields.push({...field, i: i})
    })
    // console.log(items)
    return items
}

class VerticalList extends Component {
  constructor(props) {
    super(props);
    const items = setItems(props.fields)    
    this.state = {
      items,
      fields: props.fields
    }; 
  }
  componentDidUpdate(prevProps, prevState)
  {
    if( 
      Array.isArray(this.props.fields) && 
      this.props.fields !== prevState.fields || 
      this.props.orientation !== prevProps.orientation
    )
    {
      // console.log( "VerticalList", this.props.fields )
      this.setState({ 
          items: setItems(this.props.fields), 
          fields: this.props.fields 
      })
    }
    //console.log( this.props.selectField, prevProps.selectField );
    if( this.props.selectField !== prevState.selectField)
    {
      this.setState({ selectField : this.props.selectField })
    }
  }
  onDragEnd = (result) => 
  {
    let items, fields
    if(result.combine)
    { 
        //console.log( result )
        items   = [ ...this.state.items ]
        fields  = [ ...this.state.fields ].map(field =>
            {
                return field.grid_id === result.draggableId
                    ?
                    { ...field, grid_id : result.combine.draggableId }
                    :
                    field
            })
        
        items.splice(result.source.index, 1)
        items = setItems( fields )
        // console.log( items )
        this.setState({ fields, items })
        this.props.on( fields )
        return;
    }
    if (!result.destination) {
      return;
    }
    fields = reorder(
      this.state.fields,
      result.source.index,
      result.destination.index
    );
    items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    this.setState({ items, fields });
    this.props.on( fields )
  }
  onDragUpdate  = result =>
  {
    console.log(result)
  }
  onBreakChildhood = (field ) =>
  {
    //console.log( field )
    const fields = [ ...this.state.fields ].map( fld => {
        return {
            ...fld,
            grid_id : JSON.stringify(fld) === JSON.stringify(field) 
                ? 
                `cardfieldGrid-${UUID.v4()}` 
                : 
                fld.grid_id
        }
    })
    this.props.on( fields )
  }
  selectField = field =>
  { 
    this.setState({selectField : field.id})
    if(this.props.onSelectField)
    {
      this.props.onSelectField(field)
    }
    
  }

  render() {
    // console.log( this.state.items )
    // console.log( Array.isArray(this.state.items) )
    const proportias = Array.isArray(this.props.origin?.data?.horizontal_grid_proportia)
      ?
      [ ...this.props.origin?.data?.horizontal_grid_proportia, 100 ]
      :
      this.state.items.map((e,i) => 100 / this.state.items.length )
    // console.log(this.props.origin?.data?.horizontal_grid_proportia)
    // console.log(proportias)
    let prop = 0 //proportia counter
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable 
            droppableId="droppable" 
            isCombineEnabled  
            direction={this.props.origin?.data?.orientation}
        > 
          {(provided, snapshot) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                    ...getListStyle(snapshot.isDraggingOver),
                    display:"flex",
                    flexDirection: this.props.origin?.data?.orientation !== "vertical"
                        ?
                        "row"
                        :
                        "column"
                }}
                className="shadow border border-dark"
            >
            {
                this.state.items.map((item, index) => (
                    <Draggable key={item.grid_id} draggableId={item.grid_id} index={index}>
                    {(provided, snapshot) => {
                      const thisProp = proportias[ index ] - prop
                      prop += thisProp
                      const w = this.props.origin?.data?.orientation === "horizontal"
                        ?
                        { width : `${thisProp}%` }
                        :
                        {}
                      return <div
                            ref={ provided.innerRef }
                            { ...provided.draggableProps }
                            { ...provided.dragHandleProps }
                            style={{
                                ...getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                ),
                                ...w                              
                            }}
                        >
                            <div 
                              className={` w-100 d-flex ${
                                this.props.origin?.data?.orientation !== "vertical" 
                                  ? 
                                  " flex-column " 
                                  : 
                                  " flex-row "
                              }` }
                            >                            
                            {
                                Array.isArray( item.fields ) 
                                    ?
                                    item.fields.map((field, i) => {
                                        //console.log( field )
                                        return <CardField
                                            origin={ this.props.origin }
                                            key={ field.id + i }
                                            i={ field.i }
                                            object={ field }
                                            objectId={ field.id }
                                            onClose={this.props.onCFBClose}
                                            onClone={this.props.onCFBClone}
                                            onChange={this.props.onCFForm}
                                            isChild={item.fields.length > 1 }
                                            onBreakChildhood={this.onBreakChildhood}
                                            onClick={() => this.selectField( field )}
                                            selectField={this.state.selectField}
                                            isSelect={this.setState.selectField == field.id }
                                        />
                                    })
                                    :
                                    null
                            }
                            </div>                       
                        </div>
                    }}
                    </Draggable>
                ))
            }
            {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
export default VerticalList
