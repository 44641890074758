import ArchorsMenu from "../ArchorsMenu"
import Cards from "../Cards"
import Matchings from "../Matchings"
import Carousel from "../Carousel"
import Layers from "../Layers"
import Rows from "../Rows"
import Columns from "../Columns"
import InnerLink from "../InnerLink"
import OuterURL from "../OuterURL"
import ButtonAnimateTo from "../ButtonAnimateTo"
import Image from "../Image"
import Mask from "../Mask"
import Team from "../Team"
import Motivation from "../Motivation"
import Quote from "../Quote"
import Testanomials from "../Testanomials"
import SocialLikes from "../SocialLikes"
import HTML from "../HTML"
import PostFeed from "../PostFeed"
import PostCarousel from "../PostCarousel"
import YandexMap from "../YandexMap"
import Video from "../Video"
import VideoPlayList from "../VideoPlayList"
import ContactForm from "../ContactForm"
import ZoomPanPinch from "../ZoomPanPinch"
import Accordeon from "../Accordeon"
import Haccordeon from "../Haccordeon"
import TimeLine from "../TimeLine"
import VKWidget from "../VKWidget"
import IFrame from "../IFrame"
import IncludeSection from "../IncludeSection"
import PEWidget from "../PEWidget"
import MagicGlass from "../MagicGlass"
import Tabs from "../Tabs"
import Header from "../Header"

import KraevedTitle from "../../kraeved/KraevedTitle"
import KraevedTitle2 from "../../kraeved/KraevedTitle2"
import KraevedVideoPlayList from "../../kraeved/KraevedVideoPlayList"

import __menu from "../../../assets/img/landing/archor-menu.svg"
import __html from "../../../assets/img/landing/html.svg"
import __header from "../../../assets/img/landing/header.svg"
import __canvas from "../../../assets/img/landing/canvas.svg"
import __calculator from "../../../assets/img/landing/calculator.svg"
import __carousel from "../../../assets/img/landing/carousel.svg"
import __merge from "../../../assets/img/landing/merge.svg"
import __quote from "../../../assets/img/landing/quote.svg"
import __eventsFeed from "../../../assets/img/landing/events-feed.svg"
import __image from "../../../assets/img/landing/picture.svg"
import __map from "../../../assets/img/landing/map.svg"
import __motivation from "../../../assets/img/landing/motivation.svg"
import __portfolio from "../../../assets/img/landing/portfolio.svg"
import __postFeed from "../../../assets/img/landing/post-feed.svg"
import __team from "../../../assets/img/landing/team.svg"
import __testanomials from "../../../assets/img/landing/testanomials.svg"
import __video from "../../../assets/img/landing/video.svg"
import __inner_link from "../../../assets/img/landing/inner-link.svg"
import __outer_link from "../../../assets/img/landing/outer-link.svg"
import __button_animate_to from "../../../assets/img/landing/anchor.svg"
import __likes from "../../../assets/img/landing/likes.svg"
import __design from "../../../assets/img/landing/section-growth.svg"
import __title from "../../../assets/img/landing/title.svg"
import __description from "../../../assets/img/landing/description.svg"
import __contact_form from "../../../assets/img/landing/email.svg"
import __layers from "../../../assets/img/landing/layers.svg"
import __rows from "../../../assets/img/landing/149-menu-1.svg"
import __columns from "../../../assets/img/landing/150-menu-2.svg"
import __cards from "../../../assets/img/landing/card.svg"
import __matchings from "../../../assets/img/landing/matchings.svg"
import __zoompanpinch from "../../../assets/img/landing/zoompanpinch.svg"
import __accordeon from "../../../assets/img/landing/accordeon.svg"
import __time_line from "../../../assets/img/landing/time_line.svg"
import __sm from "../../../assets/img/landing/android.svg" 
import __xs from "../../../assets/img/landing/android1.svg"
import __iframe from "../../../assets/img/landing/iframe.svg"
import __widgets from "../../../assets/img/landing/widgets.svg"
import __tabs from "../../../assets/img/landing/tabs.svg"
import __kraeved from "../../../assets/img/landing/landing.svg"
import Layouts from "react-pe-layouts"
import { importAll } from "react-pe-utilities/dist"
import Canvas from "../Canvas"


const extendsComponents = {};
const getExtendsComponents = () =>
{
	const plWidgets = [];
	const plWidgetDatas = []
	const lazies = {};

	try{
		const modules = Layouts().modules;
		Object.keys(modules).forEach(plugin => {
			Object.keys(modules[plugin].widgets).forEach(widget => {
				if( modules[plugin].widgets[widget].extended_landing )
				{
					plWidgets.push( widget );
					plWidgetDatas.push( modules[plugin].widgets[widget] )
				}
			});
		});
		importAll(require.context("modules/", true, /\.js$/), plWidgets, extendsComponents, lazies, "modules/");
		let __components = {}
		plWidgetDatas.forEach((widget, i) => {
			if( extendsComponents[ plWidgets[ i ] ] )
			{
				const comp = {
					c : 			extendsComponents[ plWidgets[ i ] ].default,
					icon : 			widget.icon,
					title:			widget.title,
					description: 	widget.description
				}
				//console.log( comp )
				__components[ plWidgets[ i ] ] = comp
			}
		})
		//console.clear()
		//console.log( plWidgets )
		//console.log( plWidgets )
		//console.log( extendsComponents )
		return __components
	}
	catch(e)
	{
		return {}
	}
}
export const components = () =>{
	return {
		canvas: { c: Canvas, icon: __canvas, title: "Empty canvas" },
		html: { c: HTML, icon: __html, title: "Simple HTML" },
		header: { c: Header, icon: __header, title: "Page's header" },
		archor_menu: { c: ArchorsMenu, icon: __menu, title: "Smart archors menu" },
		contact_form: { c: ContactForm, icon: __contact_form, title: "Constanc Form" },
		carousel: { c: Carousel, icon: __carousel, title: "Carousel" },
		layers: { c: Layers, icon: __layers, title: "Layers" },
		rows: { c: Rows, icon: __rows, title: "Rows" },
		columns: { c: Columns, icon: __columns, title: "Columns" },
		image: { c: Image, icon: __image, title: "Image" },
		//mask: { c: Mask, icon: __image, title: "Mask" },
		inner_link: { c: InnerLink, icon: __inner_link, title: "Inner Link" },
		outer_url: { c: OuterURL, icon: __outer_link, title: "Outer URL" },
		button_animate_to: { c: ButtonAnimateTo, icon: __button_animate_to, title: "Buttom to Archor" },
		team: { c: Team, icon: __team, title: "Team" },
		quote: { c: Quote, icon: __quote, title: "Quote" },
		motivation: { c: Motivation, icon: __motivation, title: "Features" },
		testanomials: { c: Testanomials, icon: __testanomials, title: "Testanomials" },
		social_likes: { c: SocialLikes, icon: __likes, title: "Social Likes" },
		yandex_map: { c: YandexMap, icon: __map, title: "Yandex Map" },
		video_play_list: { c: VideoPlayList, icon: __video, title: "Video Play List" },
		video: { c: Video, icon: __video, title: "Video" },
		post_feed: { c: PostFeed, icon: __postFeed, title: "Feed" },
		post_carousel: { c: PostCarousel, icon: __carousel, title: "Post carousel" },
		cards: { c: Cards, icon: __cards, title: "Cards" },
		matchings: { c: Matchings, icon: __matchings, title: "Matchings" },
		zoom_pan_pinch: { c: ZoomPanPinch, icon: __zoompanpinch, title: "Zoom-pan-pinch" },
		magic_grass: { c: MagicGlass, icon: __zoompanpinch, title: "Magic Glass" },
		accordeon: { c: Accordeon, icon: __accordeon, title: "Accordeon" },
		haccordeon: { c: Haccordeon, icon: __accordeon, title: "Horisontal animated accordeon" },
		time_line: { c: TimeLine, icon: __time_line, title: "Time Line" },
		vk: { c: VKWidget, icon: __time_line, title: "VKontakte" },
		iframe: { c: IFrame, icon: __iframe, title: "IFrame" },
		include_section: { c: IncludeSection, icon: __quote, title: "Include Section" },
		pe_widget: { c: PEWidget, icon: __widgets, title: "PE Widget" },
		tabs: { c: Tabs, icon: __tabs, title: "Tabs" },

		KraevedTitle: { c: KraevedTitle, icon: __kraeved, title: "Kraeved Title" },
		KraevedTitle2: { c: KraevedTitle2, icon: __kraeved, title: "Kraeved Title 2" },
		KraevedVideoPlayList: { c: KraevedVideoPlayList, icon: __video, title: "Kraeved Content Video Player" },
		
		...getExtendsComponents()
	}
}