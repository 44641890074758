import Layouts from "react-pe-layouts"

const config = require("../config/config.json")

// longitude, latitude, zoom
const default_coords = [55.76, 37.64, 10]

export function yandex_map_api_key() {
  if (config.yandex_map_api_key) return config.yandex_map_api_key
  return "NONE"
}

export function geoDefaultPosition() {
  return default_coords
}

export function geoPosition() {
  // console.log(Layouts().template.ymap );
  if (Layouts().template && Layouts().template.ymap) {
    console.log(Layouts().template.ymap.default)
    return Layouts().template.ymap.default ? [Layouts().template.ymap.default.lon, Layouts().template.ymap.default.lat, Layouts().template.ymap.default.zoom] : default_coords
  }
  return default_coords
}

export function zoom() {
  if (Layouts().template.ymap) {
    return Layouts().template.ymap.default ? Layouts().template.ymap.default.zoom : default_coords[2]
  }
  return default_coords[2]
}
