import { Dialog, Tag, Button } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"
import { __ } from "react-pe-utilities"
import DataContext from "../../DataContext"

const CardsTagsFilter = props => {
    const [isOpen, onOpen] = useState(false)
    const [allTags, setAllTags] = useState(
        Array.isArray(props.standartFilters)
            ?
            props.standartFilters
            :
            []
    )
    useEffect( ()=> {
        // console.log(props.standartFilters)
        setAllTags( props.standartFilters ? props.standartFilters : [] )
    } ) 
    const [filterTag, onFilterTag] = useState(allTags.map(e => false))
    const onChooseFilterTag = i => {
        let ft = [...filterTag]
        ft[i] = !ft[i]
        onFilterTag(ft)
        if (props.onFilterParams)
            props.onFilterParams({ tags: allTags.filter((e, i) => ft[i] ) })
    }
    const tagFilterList = allTags.map((tag, i) => {
        const filter = filterTag[i]
        return <Tag
            key={i}
            style={{
                backgroundColor: filter ? tag.color : "#CCC",
                textTransform: "uppercase",
                padding: "10px 40px",
                cursor: "pointer"
            }}
            onClick={evt => onChooseFilterTag(i)}
        >
            {tag.title}
        </Tag>
    })
    return <div className=" position-relative w-100 ">
        <Button fill large minimal onClick={evt => onOpen(true)}>
            <b>
                { __( "Filters" ) }
            </b>:
            <span className="text-secondary">
                { allTags.filter( ( e, i ) => filterTag[ i ] ).map( e => e.title ).join( ", " ) }
            </span>
            <span className="px-2 text-danger">
               ( { props.col } )
            </span>
        </Button>
        <Dialog
            isOpen={isOpen}
            onClose={() => onOpen(false)}
            title={__("Filters")}
        >
            <div className="p-4">
                <div className="pb-4">
                    <div className="title mb-2">
                        {__("Select tags")}
                    </div>
                    {tagFilterList}
                </div>
                <div>
                    <Button onClick={() => onOpen(false)}>
                        {__("Close")}
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
}
export default CardsTagsFilter