import React, { Component } from "react"
import $ from "jquery"  
import { compose } from "recompose" 
import { withRouter } from "react-router" 
import Layouts,  { cssStyle, loginPage, template } from "react-pe-layouts" 
import { __ } from "react-pe-utilities" 
import { title } from "react-pe-layouts" 
import { isLoggedPage } from "react-pe-utilities" 
import LayoutBodyLocal from "./body/LayoutBodyLocal"
import LayoutBodyStatic from "./body/LayoutBodyStatic"
import LayoutBodyExtended from "./body/LayoutBodyExtended"
 
class LayoutBody extends Component {
	random; 
	constructor(props) 
	{
		super(props)
		this.random = Math.random()
		const fl = localStorage.getItem("fluid")
		const fluid = typeof fl !== "undefined" ? fl : 1
		const style = { style: cssStyle(), fluid } 
		document.title = title()
		const token = localStorage.getItem("token")
		if (!token && isLoggedPage(props.location.pathname)) 
		{
			this.props.history.push(loginPage())
		}
		this.state = {
			current: 100,
			style,
			fluid 
		}
		//
	} 
	render() 
	{
		const cl = this.props.location.pathname.split("/")
			.splice(1)
				.map((e) => `route-${e}`).join(" ")
		const clss = this.state.style && this.state.style.fluid 
			? 
			"container-fluid  cont" 
			: 
			"container cont"
		let content = "" 
        switch( Layouts().app.init_method )
        {
            case "static":
                content = <LayoutBodyStatic
					{...this.props} 
					{...this.state} 
					onCurrent={this.onCurrent}
					onChangeStyle={this.onChangeStyle}
				/>
                break
            case "local": 
                content = <LayoutBodyLocal 
					{...this.props} 
					{...this.state} 
					onCurrent={this.onCurrent}
					onChangeStyle={this.onChangeStyle}
				/>
                break
            case "external":
            default:
                content = <LayoutBodyExtended
					{...this.props} 
					{...this.state} 
					onCurrent={this.onCurrent}
					onChangeStyle={this.onChangeStyle}
				/>
        }
		return (
			<div className={`full ${cl}`}>
				<header />
				<main>
					<div className={clss}> 
						{ content }	 
					</div>
				</main>
				<footer />
				<div id="under-footer">
					<link href={`/assets/css/style.css?${this.random}`} rel="stylesheet" />
					<link rel="stylesheet" type="text/css" href={`${cssStyle()}?${this.random}`} id="external-css" />
				</div>
			</div>
		)
	}

	onChangeStyle = (style) => {
		// console.log( style );
		localStorage.setItem("css", style.style)
		localStorage.setItem("fluid", parseInt(style.fluid) ? 1 : 0)
		$("#external-css").detach()
		if (style.style) {
			// console.log( style.style, this.state.style );
			$("#under-footer").append(`<link rel="stylesheet" type="text/css" href=${style.style}?${this.random} id="external-css"/>`)
		} else {

		}
		// this.setState( { style } );
		// console.log( localStorage.getItem("fluid"));
	}

	onCurrent = (i) => {
		this.setState({ current: i })
	}
} 

export default compose( withRouter )( LayoutBody )
