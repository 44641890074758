import React, { Component } from "react"
import { getFontNameByID } from "../data/PalettePresets"
import Answer from "./Answer"

class Matching extends Component {
  render() {
    const {  title, description, answers, question_image, style, palette } = this.props
    const _answers = answers.map((e, i) => (
      <Answer
        key={i}
        {...e}
        section_id={this.props.section_id}
        i={i}
        onChange={this.onCheck}
      />
    ))
    return (
      <div className="landing-matching-single " style={this.getStyle(style)}>
        <div
          className="landing-matching-content"
          style={{
            color: palette.main_text_color
          }}
        >
          <div className="landing-matching-media">
            <img src={question_image} className="landing-matching-image" alt="" />
          </div>
          <div
            className="landing-matching-title"
            style={{
              ...palette.card.title,
              fontFamily: getFontNameByID(palette.card.title.fontFamilyID)
            }}
          >
            {title}
          </div>
          <div className="landing-matching-descr">
            {description}
          </div>
          <div className="landing-matching-answers" style={this.getStyle(style)}>
            {_answers}
          </div>
        </div>
      </div>
    )
  }

  onCheck = (answer_obj) => {
    // console.log(answer_obj);
    this.props.onChoose(answer_obj)
  }

  getStyle = (styleObj) => {
    const style = []
    if (!styleObj) return style
    // console.log( styleObj );
    Object.entries(styleObj)
      .filter((e) =>
        // console.log( e );
        e[1] && e[1].field != "")
      .forEach((e, i) => {
        // console.log( e );
        if (e[1] && e[1].field) {
          const attr = {}
          style[e.field] = e.value
        } else {
          style[e[0]] = e[1]
        }
      })
    // console.log( style );
    return style
  }
}
export default Matching
