import { Button, ButtonGroup, Popover } from "@blueprintjs/core"
import React, { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import { __ } from "react-pe-utilities/dist"
import DataContext from "../DataContext"
import {default as UUID} from "node-uuid";

const SectionMenu = props =>
{
    const [vis, setVis] = useState(false)
    const myRef = useRef( )
    const onMouseLeaveHandler = evt =>
    {
        const domNode = myRef.current; 
        if (!domNode || !domNode.contains(evt.target)) {
            setVis( false)
        }
    } 
    useEffect(() =>
    {
        document.body.addEventListener("click", onMouseLeaveHandler)
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [])
    const onAddFloat = type =>
    {
        const floatID = UUID.v4()// DataContext.getMaxFloatID(true)
        let data = {}
        switch(type)
        {
            case "section":
            default:
                data = {
                    id: floatID,
                    float_id: floatID,
                    title: `float-${floatID}`,
                    descriptions:"",
                    composition: {
						columns: 1,
						type: 1,
						proportia: [
							50,
							50
						],
						is_expand: 0,
						is_vertical_center: 0
					},
                    style: {},
                    background: {},
                    menu: {},
                    floats: {},
                    position: {
                        mc: { 
                            x: {
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            y:{
                                value: 100,
                                "dst": "T",
                                "ei": "px" 
                            }, 
                            w:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            h:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            r:{
                                value: 0,
                                "dst": "L",
                                "ei": "deg" 
                            } 
                        },
                        lg: { 
                            x: {
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            y:{
                                value: 100,
                                "dst": "T",
                                "ei": "px" 
                            }, 
                            w:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            h:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            r:{
                                value: 0,
                                "dst": "L",
                                "ei": "deg" 
                            } 
                        },
                        xl: { 
                            x: {
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            y:{
                                value: 100,
                                "dst": "T",
                                "ei": "px" 
                            }, 
                            w:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            h:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            r:{
                                value: 0,
                                "dst": "L",
                                "ei": "deg" 
                            } 
                        },
                        sm: { 
                            x: {
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            y:{
                                value: 100,
                                "dst": "T",
                                "ei": "px" 
                            }, 
                            w:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            h:{
                                value: 100,
                                "dst": "L",
                                "ei": "px" 
                            }, 
                            r:{
                                value: 0,
                                "dst": "L",
                                "ei": "deg" 
                            } 
                        },
                    },
                    current_type: "image",
                    type: "image",
                    data: {
                        src: "/assets/img/landing/bilibin1.jpg",
                        src_type: "div",
						height: 100
                    },
                    link: {}
                }
        }
        props.onAddedFloat( data, floatID )
    }
    return <div  
        onClick={() => setVis( !vis )}
        ref={ myRef }
        className="position-relative bg-light"
    >
        <Button 
            className="w_170"
        >
            {__("Add float:")}
        </Button>
        <div className={vis ? `` : `hidden`}>
            <ButtonGroup vertical fill minimal>
                <Button 
                    icon="media" 
                    alignText="left" 
                    className="w_170 hidden"
                >
                    {__("Image")}
                </Button>
                <Button 
                    icon="new-text-box" 
                    alignText="left" 
                    className="w_170 hidden"
                >
                    {__("Text")}
                </Button>
                <Button 
                    icon="widget-header" 
                    alignText="left" 
                    className="w_170"
                    onClick={onAddFloat}
                >
                    {__("Section")}
                </Button>
            </ButtonGroup> 
        </div>       
    </div>
}
export default SectionMenu