import React from "react"
import VK, { Group } from "react-vk"
//https://vk.com/dev/widget_community
//https://vk.com/dev/Community?height=500&link=https%3A%2F%2Fvk.com%2Fmytopos&mode=4&no_cover=1&oid=-191335239&wide=1&width=500

export default ({ api_key, groupId, width, height, mode, no_cover, wide, color1, color2, color3, onJoin, onLeave }) => {

    const getGroup = () => {
        try {
            const w = "100%"
            return <VK>
                <Group
                    elementId={"vk_group_" + groupId}
                    groupId={groupId}
                    options={{ width, height, mode, no_cover, wide, color1, color2, color3 }}
                    onJoin={onJoin}
                    onLeave={onLeave}
                />
            </VK>
        }
        catch (e) {
            return ""
        }
    }
    return groupId
        ?
        <>
            <div className="landing-vk-group" id={"vk_group_" + groupId} />
            {getGroup()}
        </>
        :
        null
}