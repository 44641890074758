import React, { Component, Fragment } from "react"
import $ from "jquery"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"

import VulgarisDot from "./time_line/VulgarisDot"
import SpileDot from "./time_line/SpileDot"
import TooltipDot from "./time_line/TooltipDot"

class TimeLine extends SectionContent {
  is() {
    const { dots } = this.state.data
    return Array.isArray(dots) && dots.length > 0
  }
  getVariants() {
    // console.log(this.props.data);
    const { dots_size } = this.props.data
    return {
      vulgaris:
      {
        _component: VulgarisDot,
        _center: dots_size / 2,
        _borderHeight: 10,
      },
      tooltip:
      {
        _component: TooltipDot,
        _center: 15, // dot_diameter / 2 + 5,
        _borderHeight: 5,
      },
      spiles:
      {
        _component: SpileDot,
        _center: dots_size / 2,
        _borderHeight: 10,
      },
    }
  }
  drawStrates() {
    const { design_variant } = this.props.data
    setTimeout(_this => {
      const line = []
      // const pos = $(".landing-time-line-dots").offset()
      const allDots = $(".landing-time-line-dot-dot-dot")
      //console.log($(allDots[0]).offset())
      const pos = allDots.length == 0
        ?
        $(".landing-time-line-dots").offset()
        :
        {
          //left: $(allDots[0]).offset().left,
          left: $(".landing-time-line-dots").offset().left,
          top:  $(".landing-time-line-dots").offset().top
        }
      allDots.each((i, e) => {
        const offset = $(e).offset()

        line.push([
          offset.left - pos.left + _this.getVariants()[design_variant]._center,
          offset.top - pos.top + _this.getVariants()[design_variant]._center,
        ])
      })
      _this.setState({
        line: `M${line.join("L")}`,
      })
    }, 300, this)
  }
  updateWidth(width) {
    this.drawStrates()
  }
  componentWillMount() {
    this.drawStrates()
  }
  componentDidUpdate(prevProps, prevState) {
    const { strate_dots, dots, } = this.props.data
    if (strate_dots == "horizontal" || prevProps.data.dots != dots) {
      if (!prevState.maxH && $(".landing-time-bottom, .landing-time-top").length > 0) {
        let maxH = 0
        $(".landing-time-bottom, .landing-time-top").each((i, element) => {
          const h = $(element).height()
          return maxH = maxH < h ? h : maxH
        })
        this.setState({ maxH })
      }
    }

    if (!prevState.line || prevProps.data.dots != dots || prevProps.data.strate_dots != strate_dots) {
      this.drawStrates()
    }

  }

  renderContent(style) {
    const {palette} = this.props
    const {
      class_name,
      dots,
      dots_size,
      dot_class_name,
      dot_style,
      width,
      design_variant,
      color,
      capsule_height,
      dot_diameter,
      is_reverse,
      line_color,
      strates_width,
      border_width
    } = this.props.data
    const dv = design_variant ? design_variant : "vulgaris"
    const cards_elems = dots.map((e, i) => {
      const __Component = this.getVariants()[dv]._component
      return <__Component
        key={i}
        dots_size={dots_size}
        line_color={line_color}
        border_width={border_width}
        backgroundColor={color}
        height={capsule_height || 100}
        dot_diameter={dot_diameter}
        maxH={this.state.maxH}
        is_reverse={is_reverse}
        width={width}
        palette={palette}
        style={this.getStyle(dot_style)}
        className={dot_class_name}
        {...e}
      />
    })
    return [
      <div
        className={
          `landing-time-line-dots ${class_name || ""}`
        }
        key={1}
        style={{ ...style }}
      >
        {cards_elems}
        <svg 
          style={{ position: "absolute", top: 0, left: 0 }} 
          x="0" 
          y="0" 
          width="100%" 
          height="600" 
          viewBox="0 0 100% 600"
        >
          <path
            d={this.state.line}
            stroke={line_color ? line_color : "white"}
            strokeWidth={strates_width ? strates_width : this.getVariants()[dv]._borderHeight}
            fill="transparent"
          />
        </svg>
      </div>,
    ]
  }
}
export default TimeLine
