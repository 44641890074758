import React, { Component } from "react"
import {
  Button, ButtonGroup, Intent, Popover, Position, Drawer,
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import InputForm from "./InputForm"

class LandingEditDialog extends Component {
  constructor(props) { 
    super(props)
    this.state = { ...props }
  }

  componentWillUpdate(nextProps) {
    const state = {}
    Object.keys(nextProps).forEach((e) => {
      if (this.state[e] != nextProps[e]) state[e] = nextProps[e]
    })
    // console.log(nextProps, this.state, state);
    if (Object.keys(state).length > 0) {
      this.setState(state)
    }
  }

  render() { 
    //console.log( this.state.data );
    return (
      <Drawer
        isOpen={this.state.isOpen}
        onClose={this.props.onClose}
        title={__("Landing Settings")}
        position={Position.RIGHT}
        className="little3 drawer"
        usePortal
        backdropClassName="landing-drawer-bg"
        size={800}
      >
        <div className="pt-0 px-0 overflow-y-auto flex-grow-100 bg-tripp">
          <div className="m-4">
            <InputForm
              source="landing"
              data={this.state.data}
              on={(value, field) => this.onField(value, field, "data")}
              onAdd={ this.props.onAdd }
							onDownload={this.props.onDownload }
							onLoadChange={this.props.onLoadChange }
							onClearOpen={this.props.onClearOpen }
            />
          </div>
        </div>
        <ButtonGroup className="mx-auto py-3">
          <Button
            onClick={this.onEdit}
          >
            { __("Update") }
          </Button>
        </ButtonGroup>
      </Drawer>
    )
  }

  onField(value, field) {
    const { data } = this.state // { ...this.state.data}
    data[field] = value
    //console.log(data)
    // this.setState( { data } );
  }

	onEdit = () => {
	  //console.log(this.state.data)
	  this.props.onEdit(this.state.data)
	}
}

export default LandingEditDialog
