import React, { useState } from "react"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"

const ProjectUserForm = props =>
{
    // console.log( props )
    //  Project_author
    //  Tutor
    const [ moderator_id,  setModerator ]  = useState(Number(props.moderator_id))
    const [ moderator_id2, setModerator2 ] = useState(Number(props.moderator_id2))
    const [ leader_id, setLeader_id ] = useState(Number(props.leader_id))
    const [ tutor_id, setTutor ]      = useState(Number(props.tutor_id))
    const [ member_0, setMember_0 ] = useState(Number(props.member_0))
    const [ member_1, setMember_1 ] = useState(Number(props.member_1))
    const [ member_2, setMember_2 ] = useState(Number(props.member_2))
    const [ member_3, setMember_3 ] = useState(Number(props.member_3))
    const [ member_4, setMember_4 ] = useState(Number(props.member_4))
    const [ member_5, setMember_5 ] = useState(Number(props.member_5))
    const [ member_6, setMember_6 ] = useState(Number(props.member_6))
    const [ member_7, setMember_7 ] = useState(Number(props.member_7))
    const [ member_8, setMember_8 ] = useState(Number(props.member_8))
    const [ member_9, setMember_9 ] = useState(Number(props.member_9))
    const onAutor = ( value,  field ) =>
    {
        console.log( value,  field )
        props.on(value, field)
        setLeader_id(value)
    }
    const onTutor = ( value,  field ) =>
    {
        console.log( value,  field )
        props.on(value, field)
        setTutor(value)
    }
    const onTrack_moderator = ( value,  field ) =>
    {
        console.log( value,  field )
        props.on(value.value, field)
        setModerator(value.value)
    } 
    const onMember = ( value,  field, num ) =>
    {
        //console.log( value,  field )
        props.on(value, field)
        eval("setMember_" + num)(value)
    } 
    const getSelect = (role ) =>
    {
        let fmru_users = props.fmru_users 
        //console.log( fmru_users )
        const users =  fmru_users
            .filter(e => e.role.filter( _role => _role === role ).length > 0 )
                .map( e => {
                    return {
                        _id     : e.id,
                        title   : e.display_name
                    }
                }) 
        // users.unshift({ _id: -1, title: " - " })
        return users
    }
    const getValue = id =>
    {
        const user = props.fmru_users.filter(e => e.id === id)
        return user[0] 
            ?
            {
                _id: user[0].id,
                title: user[0].display_name
            }
            :
           null
    }
    return <>  
    {
        Array.isArray(props.fmru_evlbl_roles) && props.fmru_evlbl_roles.filter(e =>  e === "Project_author").length > 0
            ?
            <div className="py-1 ">
                <FieldInput                    
				    visualization="festFormsFields" 
                    editable
                    type="select" 
                    field="leader_id"
                    values={ getSelect( "Project_author" ) }
                    visible_value="display_name"
                    multiple={false}
                    value={ getValue(leader_id) }
                    title={ __( "Project_author" ) } 
                    on={onAutor}
                    onChange={onAutor}
                />
            </div>
            :
            null
    }
    {
        Array.isArray(props.fmru_evlbl_roles) && props.fmru_evlbl_roles.filter(e =>  e === "Tutor").length > 0
            ?
            <div className="py-1 ">
                <FieldInput
                    type="select" 
                    visualization="festFormsFields" 
                    editable
                    field="tutor_id"
                    values={ getSelect( "Tutor" ) }
                    visible_value="display_name"
                    multiple={false}
                    value={ getValue(tutor_id) }
                    title={ __( "Tutor" ) } 
                    on={ onTutor }
                    onChange={ onTutor }
                />
            </div> 
            :
            null
    }  
    {
        props.fmru_evlbl_roles?.filter(e =>  e === "Project_member").length > 0
            ?
            <>
                <div className="py-1 ">
                    <FieldInput
                        type="select"
                        visualization="festFormsFields"  
                        editable
                        field="member_0"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_0) }
                        title={ __( "Project members" ) } 
                        on={        (value, field) => onMember(value, field, 0) }
                        onChange={  (value, field) => onMember(value, field, 0) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_1"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_1) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 1) }
                        onChange={  (value, field) => onMember(value, field, 1) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_2"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_1) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 2) }
                        onChange={  (value, field) => onMember(value, field, 2) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_3"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_3) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 3) }
                        onChange={  (value, field) => onMember(value, field, 3) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_4"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_4) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 4) }
                        onChange={  (value, field) => onMember(value, field, 4) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_5"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_5) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 5) }
                        onChange={  (value, field) => onMember(value, field, 5) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_6"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_6) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 6) }
                        onChange={  (value, field) => onMember(value, field, 6) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_7"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_7) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 7) }
                        onChange={  (value, field) => onMember(value, field, 7) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields" 
                        editable
                        field="member_8"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_8) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 8) }
                        onChange={  (value, field) => onMember(value, field, 8) }
                    />
                </div>
                <div className="py-1 ">
                    <FieldInput
                        type="select" 
                        visualization="festFormsFields"
                        editable
                        field="member_9"
                        values={ getSelect( "Project_member" ) }
                        visible_value="display_name"
                        multiple={false}
                        value={ getValue(member_9) }
                        title={ __( " " ) } 
                        on={        (value, field) => onMember(value, field, 9) }
                        onChange={  (value, field) => onMember(value, field, 9) }
                    />
                </div>
            </> 
            :
            null
    }  
        
    </>
}

export default ProjectUserForm