/*
  Кнопка единичного поля в интерфейсе редактирования Карточки (Card)
*/

import React, { Component } from "react"
import { __ } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import { CardFieldTypes, CARD_SEPARATOR } from "../../views/LandingState/Card"
import { sectionDataParse } from "./CardSingleFieldType"
import SectionButton from "./SectionButton"
import __cards from "../../assets/img/landing/card.svg"
import CardFieldMetaphors from "../../views/LandingState/card/CardFieldMetaphors" 

class CardSingleField extends Component {
  constructor(props) {
    super(props)
    //console.log(props)
    this.state = {
      ...this.props,
      //__field: this.props.object.field,
      __field: this.props.object.field_type, 
    }
    this.ref = React.createRef()
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.object.field_type !== this.state.__field) {
      this.setState({ __field: nextProps.object.field_type })
    }
  }

  render() {
    const {
      card_fields, i, variant,
    } = this.props.object
    const index = i < card_fields.length ? i : 0
    //console.log( this.props );
    //console.log( { height : card_fields[index].height } );
    const rr = CardFieldTypes().filter((eee, ii) => eee.type == card_fields[index].type)[0]
    const r = i < card_fields.length
      ?
      rr
      :
      CardFieldTypes()[0]

    const tp = card_fields[index]
      ?
      card_fields[index].type
      :
      "empty"

    return (
      <div 
        className="landing-card-field-single bg-secondary " 
        style={{ height : card_fields[index].height + "px" }}
        onClick={this.onClick}
      > 
        <div className="l-icon ">
          <div>
            <LayoutIcon
              src={r.icon}
              className="layout-icon white scale-60"
            />
            <div className="mt-1 text-light hidden">
              {__(r.title)}
            </div>
          </div>
        </div>
        <div className={`landing-card-field flex-grow-100 w-100 p-0 ${tp}${variant ? variant : "0"}`}>
          {this.brt(this.props.object)}
        </div>
        <div className="landing-card-field-metaphore">
          {__( this.getCardMetafor(card_fields[index].metafor).title )}
        </div>
      </div>
    )
  }
  getCardMetafor = id =>
  {
    // console.log(id)
    const met = CardFieldMetaphors().filter( metafor => metafor._id == id )
    return met[0] ? met[0] : { title:"Dummy"}
  }
  brt = () => {
    const {
      card_fields, i,
    } = this.props.object
    const { __field } = this.state
    let datas
    if (!card_fields[i]) return ""

    switch (card_fields[i].type) {
      case "person":
        datas = typeof __field === "string"
          ?
          __field.split(CARD_SEPARATOR())
          :
          ["", "", ""]
        return (
          <div className="d-flex text-white p-2">
            <div
              style={{
                width: 100, 
                height: 100, 
                backgroundImage: "url(" + datas[2] + ")", 
                backgroundSize: "cover",
                flexGrow:1,
                height : card_fields[i].height + "px"
              }}
            >

            </div>
            <div className=" landing-card-field-single-content ">
              <div>{datas[0]}</div>
              <div>{datas[1]}</div>
            </div>
            
          </div>
        )
      case "cf":
      case "navlink":
      case "outerlink":
        datas = typeof __field === "string"
          ? __field.split(CARD_SEPARATOR())
          : ["", ""]
        return (
          <div className=" text-white p-2">
            {datas[1]}
          </div>
        )
      case "personal_links":
        datas = typeof __field === "string"
          ? __field.split(CARD_SEPARATOR())
          : []

        const btns = datas.map((ee, ii) => {
          let fa = "fas fa-link"
          if (ee.indexOf("facebook.com") >= 0) {
            fa = "fab fa-facebook-f"
          } else if (ee.indexOf("vk.com") >= 0) {
            fa = "fab fa-vk"
          } else if (ee.indexOf("youtube.com") >= 0) {
            fa = "fab fa-youtube"
          } else {
            fa = "fas fa-link"
          }
          return (
            <div key={ii} className="lcfpl">
              <span className={fa} />
            </div>
          )
        })

        return (
          <div className="d-flex text-white p-2">
            {btns}
          </div>
        )
      case "media":
        return (
          <div
            className="landing-image"
            style={{
              backgroundImage: `url(${__field})`,
              minHeight: "90px",
              minHeight : card_fields[i].height + "px"
            }}
          />
        )
      case "icon":
        datas = __field
          ? 
          __field.toString().split(CARD_SEPARATOR())
          : 
          ["", "", "", ""]
        return (
          <div
            className={" text-white p-2 " + datas[0]}
            style={{
              fontSize: "45px",
              height : card_fields[i].height + "px"
            }}
          >
          </div>
        )
      case "check":
        try {
          datas = JSON.parse(__field.replaceAll("!~!~", '"'))
          datas = Array.isArray(datas) ? datas : []
        }
        catch (e) {
          datas = []
        }
        return (
          <div className={" text-white p-2 "} >
            <div className="w-100">
              {
                datas.map((dt, index) => {
                  return <div key={index}>
                    <span className=" px-2 ">
                      {
                        dt.icon
                          ?
                          <i className="fas fa-check text-success" />
                          :
                          <i className="fas fa-times text-danger" />
                      }
                    </span>
                    <span className=" ">
                      {dt.label}
                    </span>
                  </div>
                })
              }
            </div>
          </div>
        )
      case "price":
        datas = __field
          ? 
          __field.toString().split(CARD_SEPARATOR())
          : 
          ["", "", "", ""]
        return (
          <div
            className={" text-white p-2 "}
          >
            <span className=" title ">
              {datas[0]}
            </span>.
            <span className="pr-1">
              {datas[3]}
            </span>
            <span className="px-1">
              {datas[1]}
            </span>
          </div>
        )
      case "section":
        const __object = sectionDataParse(__field)
        const button = __object.length > 0
          ? __object.map((e, i) => (
            <SectionButton
              key={i}
              object={e}
            />
          ))
          : "EMPTY"
        return (
          <div className="d-flex text-white p-2">
            {button}
          </div>
        )
      case "string":
      default:

        return (
          <div className=" d-flex text-white p-2">
            {__field ? __field.toString() : ""}
          </div>
        )
    }
  }

  on(data) {
    if (this.props.on) {
      console.log(data)
      this.props.on("fields", data)
    }
  }
  onClick = () => {
    this.props.onClick(this.props.object)


  }
}
export default CardSingleField
