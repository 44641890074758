import React, { Component } from "react"
import { __ } from "react-pe-utilities"
import _fetch from "../.."
import Category from "../../Category"
import Critery from "./Critery"
import CriteryList from "./CriteryList" 

/*
  Отображение категории оценивания Проекта (участника) с вложенными критериями. 
  Для Модератора трека - отображает инструмент коррекции оценки
  Для Эксперта - отображает инструмент выставления оценки
  Для Наставника - инструмент выставления оценки при условии "самооценка" у Этапа (Milestone)
  Для остальных - только просмотр
*/
class CategoryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  } 
  getRaitTitle = () =>
  {
      return this.props.milestone
        ?
        this.props.milestone.is_auto_rait
          ?
          <span className="text-dark-success">{__( "Auto rait by Project's owner:")}</span>
          :
          <span className="text-dark-primary">{__( "Rait by Experts:")}</span>
        :
        "--"
  }
  render() {
    //console.log( this.props.orphans )
    const categories = this.props.categories
      .filter( cat => this.props.milestone ? cat.milestone === this.props.milestone.ID : true )
        .map((e, i) => (
          <Category
            {...e}
            key={e.id + "" + i}
            max_raiting={this.props.max_raiting}
            rating_type={this.props.rating_type}
            user={this.props.user}
            member_id={this.props.member_id}
            milestone={this.props.milestone}
            my_honeycombs={this.props.my_honeycombs}
            is_owner={this.props.is_owner}
          />
      ))
    /*
      categories - отображение категорий с дочерними критериями
      CriteryList - отображение критериев, не приписанных к категориям
    */
    const orphansDescrs = Array.isArray(  this.props.orphans )
      ?
      <div className="col-md-12 critery_cell grey p-0 bl-20 descrp mt-3" style={{}}>
        <div className="critery-block p-3 lead title">
          {__("Experts's descriptions")} :
        </div>         
        <div className="critery-block critery-all-descr">
        {
          this.props.orphans
          .filter( orphan => this.props.milestone ? orphan.milestone_id[0] === this.props.milestone.ID : true )
          .map((orphan, i) => {
            return Array.isArray(orphan.full_rating_data)
              ?
              orphan.full_rating_data.map(frd => {
                return <div className="critery_cell pl-4" key={orphan.id + "_" + frd.ID}>
                  <blockquote>
                    <div className="lead">
                      { frd.description }
                    </div>
                    <div className="mt-3">
                      { frd.display_name }
                    </div>
                  </blockquote>
                </div>
              })
              :
              null
          }) 
        }
        </div>
      </div>
      :
      null    
    return (
      <div className="row">
        <div className="col-md-7">
          <div className="display-6 mb-2">
            { 
              this.props.categories?.length > 0 || this.props.orphans?.length > 0 
                ? 
                this.getRaitTitle() 
                : 
                ""
            }
          </div>
        </div> 
        {categories}
        <div className="mt-4 w-100">
          <CriteryList
            criteries={ this.props.orphans }
            rating_type={ this.props.rating_type }
            color={"#CCC"}
            max_raiting={this.props.max_raiting}
            my_honeycombs={this.props.my_honeycombs}
            user={this.props.user}
            member_id={this.props.member_id}
            milestone={this.props.milestone} 
            is_owner={this.props.is_owner}
          /> 
        </div>        
        {orphansDescrs}      
      </div>
    )
  }
}
export default CategoryList
