/*
  Редактирование свойств поля Карточки (Card)
*/

import React, { Component, Fragment } from "react"
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 
import {MediaChooser} from "react-pe-useful" 

import { CARD_SEPARATOR } from "../../views/LandingState/Card"
import InputForm from "../../views/LandingState/edit/InputForm"
import FieldInput, { Icon } from "react-pe-scalars"
import { getCurrencies } from "../../views/LandingState/card/CardFieldPrice"
import { AppToaster } from 'react-pe-useful'
import $ from "jquery"

class CardSingleFieldType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      //__field: this.props.origin.field,
      __field: props.value,
    }
  }
  form = () => {
    const { card_fields, i } = this.props.origin.object
    // console.log( this.state.__field );
    if (card_fields[i]) {
      switch (card_fields[i].type) {
        case "person":
          return this.person()
        case "check":
          return this.check()
        case "icon":
          return this.icon()
        case "cf":
        case "navlink":
        case "outerlink":
          return this.outerlink()
        case "media":
          return this.media()
        case "personal_links":
          return this.personal_links()
        case "price":
          return this.price()
        case "section":
          return this.section()
        case "string":
        default:
          return this.string()
      }
    }
    else return this.string()
  }

  render() {
    return <div className="w-100 d-flex">
      {this.form()}
      <div>
        <Button minimal data-hint={__("copy to clipboard")} onClick={this.copy} className="hint hint--left " >
          <i className="fas fa-file-import" />
        </Button>
        <Button minimal data-hint={__("Paste from clipboard")} onClick={this.paste} className="hint hint--left ">
          <i className="fas fa-file-export" />
        </Button>
      </div>
      
    </div>
  }

  section() {
    const { card_fields, i } = this.props.origin.object
    const datas = this.state.__field
      ? this.parse(this.state.__field)
      : []

    return (
      <div className=" w-100 ">
        <InputForm
          {...card_fields[i]}
          cellData={{
            sections: {
              title: "",
              type: "landing_object",
              kind: "array",
              visualization: "landing-object",
              landing_object: "section",
              hidden: 0,
            },
            visible_value: { hidden: 1, value: "class_name" },
          }}
          source="section"
          id={i}
          data={{ sections: datas }}
          sourceType="section"
          on={(value, field) => this.onSection(value, field)}
        />
      </div>
    )
  }

  string() {
    // const { card_fields, i } = this.props.origin.object
    return <div className="w-100">
      <FieldInput
        vertical
        not_clear
        type="html"
        value={this.state.__field || ""}
        onChange={this.onString}
      /> 
    </div>
  }

  person() {
    // const { card_fields, i } = this.props.origin.object
    // console.log(this.state.__field);
    const datas = typeof __field === "string"
      ?
      (this.state.__field).split(CARD_SEPARATOR())
      :
      []
    return <div className="row">
      <div className="col-md-12 title">
        {__("Name:")}
      </div>

      <input
        type="text"
        className="dark input form-control col-md-12 mb-3"
        value={datas[0]}
        onChange={(evt) => this.onDatas(evt, 0)}
      />

      <div className="col-md-12 title">
        {__("metas:")}
      </div>

      <input
        type="text"
        className="dark input form-control col-md-12 mb-3"
        value={datas[1]}
        onChange={(evt) => this.onDatas(evt, 1)}
      />

      <div className="col-md-12 title">
        {__("Icon:")}
      </div>
      
      <MediaChooser
        url={datas[2]}
        id="media"
        padding={5}
        height={140}
        onChange={ data => this.onDatasValue(data, 2) }
      />
    </div>
  }

  personal_links() {
    // const { card_fields, i } = this.props.origin.object
    // console.log(this.state.__field);
    const datas = this.state.__field
      ? (this.state.__field).split(CARD_SEPARATOR())
      : []
    const btns = datas.map((ee, ii) => (
      <div className="d-flex" key={ii}>
        <input
          type="text"
          className="dark input form-control"
          value={ee}
          onChange={(evt) => this.onDatas(evt, ii)}
          placeholder={__("put the link")}
        />
        <Button
          icon="minus"
          minimal
          className="my-2"
          onClick={() => this.onRemoveLink(ii)}
        />
        <Button
          icon="chevron-down"
          minimal
          className="my-2"
          disabled={ii == datas.length - 1}
          onClick={() => this.onDn(ii)}
        />
        <Button
          icon="chevron-up"
          minimal
          className="my-2"
          disabled={ii == 0}
          onClick={() => this.onUp(ii)}
        />
      </div>
    ))
    return (
      <div className="container">
        <div className="row p-2 my-2 bg-light border ">
          <div className="col-12 flex-column">
            {btns}
          </div>
          <div className="offset-2 col-10">
            <Button
              icon="plus"
              className="my-2"
              onClick={this.onAddLink}
            />
          </div>
        </div>
      </div>
    )
  }

  outerlink() {
    // const { card_fields, i } = this.props.origin.object
    const datas = this.state.__field
      ? 
      (this.state.__field).toString().split(CARD_SEPARATOR())
      : 
      ["", ""]
    // console.log( datas );
    return (
      <div className="row dat ">
        <div className="col-4 layout-label">
          {__("Button's outer URL")}
        </div>
        <div className="col-8 layout-data">
          <input
            type="text"
            className="dark input form-control"
            value={datas[0]}
            onChange={(evt) => this.onDatas(evt, 0)}
          />
        </div>
        <div className="col-4 layout-label">
          {__("Button label")}
        </div>
        <div className="col-8 layout-data">
          <input
            type="text"
            className="dark input form-control"
            value={datas[1]}
            onChange={(evt) => this.onDatas(evt, 1)}
          />
        </div>
        <div className="col-4 layout-label">
          {__("How open content?")}
        </div>
        <div className="col-8 layout-data flex-column">
          <label className="_check_ pt-2">
            <input
              type="radio"
              className=""
              checked={!datas[2] || datas[2] == 0}
              onChange={(evt) => this.onDatasValue(0, 2 )}
            />
            {__("switch to url")}
          </label>
          <label className="_check_ ">
            <input
              type="radio"
              className=""
              checked={datas[2]==1}
              onChange={(evt) => this.onDatasValue(1, 2 )}
            />
            {__("open in modal")}
          </label>
          <label className="_check_ ">
            <input
              type="radio"
              className=""
              checked={datas[2]==2}
              onChange={(evt) => this.onDatasValue(2, 2 )}
            />
            {__("switch to target blank")}
          </label>
        </div>
      </div>
    )
  }
  check() {
    let datas = []
    try {
      datas = JSON.parse(this.state.__field.replaceAll("!~!~", '"'))
      datas = Array.isArray(datas) ? datas : []
    }
    catch (e) {
      datas = []
    }
    return <div className="w-100">
      {this.state.__field}
      {
        datas.map((dt, index) => {
          return <div className="w-100 d-flex" key={index}>
            <label className="_check_">
              <input
                type="checkbox"
                className=" "
                checked={dt.icon || dt.icon == 1 ? true : false}
                onChange={evt => this.onDatasCheck(evt, index, "icon")}
              />
            </label>
            <input
              type="text"
              className="dark input form-control"
              value={
                dt.label
                  ?
                  dt.label
                  :
                  ""
              }
              onChange={evt => this.onDatasCheck(evt, index, "label")}
            />
            <Button icon="minus" onClick={() => this.onRemoveCheck(index)} minimal />
          </div>
        })
      }
      <Button icon="plus" className="mt-2" onClick={this.onAddCheck} >{__("add  feature")}</Button>
    </div>
  }

  icon() {
    const { card_fields, i } = this.props.origin.object
    //console.log(card_fields, i)
    const datas = this.state.__field
      ?
      (this.state.__field).toString().split(CARD_SEPARATOR())
      :
      ["", 40]
    return (
      <div className="w-100">
        <input
          type="text"
          className="dark input form-control"
          value={datas[0]}
          onChange={(evt) => this.onDatas(evt, 0)}
        />
        <Icon
          editable
          value={datas[0]}
          on={(data) => this.onDatasIcon(data, 0)}
        />
        <input
          type="number"
          className="dark input form-control"
          value={datas[1]
            ?
            datas[1]
            :
            card_fields[i].variant_param_1
              ?
              card_fields[i].variant_param_1
              :
              40
          }
          onChange={(evt) => this.onDatas(evt, 1)}
        />
      </div>
    )
  }

  price() {
    // const { card_fields, i } = this.props.origin.object
    const datas = this.state.__field
      ? (this.state.__field).split(CARD_SEPARATOR())
      : ["", "", "", ""]
    console.log( this.state );
    // const currencySelector = selected => {
    //   return getCurrencies().map((e, i) => {
    //     return <option value={e.id} selected={selected} key={i}>
    //       {__(e.title)}
    //     </option>
    //   })
    // }
    let currency = getCurrencies().filter(e => {
      //console.log(e, e.id == datas[1])
      //return e.id == datas[1]
      if(this.state.origin && this.state.origin.object)
      {
        return this.state.origin.object.card_fields[this.state.origin.object.i].variant_param_1  === e.id
      }
      return false
    })[0]
    currency = currency ? currency : getCurrencies()[0]
    let currency_label = __( currency.title )
    if(this.state.origin && this.state.origin.object)
    {
      currency_label = currency[this.state.origin.object.card_fields[this.state.origin.object.i].variant_param_2]
      if(this.state.origin.object.card_fields[this.state.origin.object.i].variant_param_2 == "icon")
      {
        currency_label = <i className={currency_label} />
      }
      else
      {
        __( currency_label )
      }
    }
    
    return (
      <div className="row dat ">
        <div className="col-md-6 layout-label">
          <div className="row dat ">
            <div className="col-12 layout-label">
              {__("Nominal")}
            </div>
            <div className="col-12 layout-data align-items-center">
              <input
                type="text"
                className="dark input form-control"
                value={datas[0]}
                onChange={(evt) => this.onDatas(evt, 0)}
              />
              { currency_label } 
            </div>
          </div>
        </div>
        <div className="col-md-6 layout-label">
          <div className="row dat ">
            <div className="col-12 layout-label">
              {__("Mini nominal")}
            </div>
            <div className="col-12 layout-data">
              <input
                type="text"
                className="dark input form-control"
                value={datas[3]}
                onChange={(evt) => this.onDatas(evt, 3)}
              />
            </div>
              {/* 
            <div className="col-12 layout-label">
              {__("Carrency label")}
            </div>
            <div className="col-12 layout-data flex-column">
                <select
                  className="form-control input dark"
                  value={datas[1]}
                  onChange={evt => this.onDatas(evt, 1)}
                >
                  {currencySelector(datas[1])}
                </select>
                <ButtonGroup fill large>
                  <Button onClick={evt => this.onDatasValue("id", 2)} minimal={datas[2] !== "id"} large>
                    {currency.id}
                  </Button>
                  <Button onClick={evt => this.onDatasValue("title", 2)} minimal={datas[2] !== "title"} large>
                    {__(currency.title)}
                  </Button>
                  <Button onClick={evt => this.onDatasValue("abbreviation", 2)} minimal={datas[2] !== "abbreviation"} large>
                    {currency.abbreviation}
                  </Button>
                  <Button onClick={evt => this.onDatasValue("icon", 2)} minimal={datas[2] !== "icon"} large>
                    <span className={currency.icon} />
                  </Button>
                </ButtonGroup>
            </div>
              */} 
          </div>
        </div>
      </div>
    )
  }

  media() {
    // const { card_fields, i } = this.props.origin.object
    return (
      <MediaChooser
        url={this.state.__field}
        id="media"
        padding={5}
        height={140}
        onChange={this.onMedia}
      />
    )
  }

  onSection(value) {
    const __field = this.stringify(value)
    this.setState({ __field })
    this.on(__field)
  }

  on(data) {
    if (this.props.on) {
      this.props.on("fields", data)
    }
  }

  onAddLink = () => {
    const __field = this.state.__field ? this.state.__field + CARD_SEPARATOR() : CARD_SEPARATOR()
    this.setState({ __field })
    this.on(__field)
  }

  onRemoveLink = (ii) => {
    let __field = this.state.__field ? this.state.__field.split(CARD_SEPARATOR()) : []
    __field.splice(ii, 1)
    __field = __field.join(CARD_SEPARATOR())
    this.setState({ __field })
    this.on(__field)
  }

  onDn = (ii) => {
    let __field = this.state.__field ? this.state.__field.toString().split(CARD_SEPARATOR()) : []
    const fl = __field.splice(ii, 1)
    __field.splice(ii + 1, 1, fl)
    __field = __field.join(CARD_SEPARATOR())
    this.setState({ __field })
    this.on(__field)
  }

  onUp = (ii) => {
    let __field = this.state.__field ? this.state.__field.toString().split(CARD_SEPARATOR()) : []
    const fl = __field.splice(ii - 1, 1)
    __field.splice(ii, 1, fl)
    __field = __field.join(CARD_SEPARATOR())
    this.setState({ __field })
    this.on(__field)
  }

  onIcon = __field => {
    this.setState({ __field })
    this.on(__field)
  }

  onString = __field => { 
    this.setState({ __field })
    this.on(__field)
  }
  onAddCheck = () => {
    let __field = this.state.__field
    try {
      __field = JSON.parse(this.state.__field.replaceAll("!~!~", '"'));
      __field = Array.isArray(__field) ? __field : []
    }
    catch (e) {
      __field = []
    }
    __field.push({
      icon: 1,
      label: ''
    })
    __field = JSON.stringify(__field)
    __field = __field.replaceAll('"', '!~!~')
    this.setState({ __field })
  }
  onRemoveCheck = (pos) => {
    let __field = this.state.__field
    try {
      __field = JSON.parse(this.state.__field.replaceAll("!~!~", '"'));
      __field = Array.isArray(__field) ? __field : []
    }
    catch (e) {
      __field = []
    }
    __field.splice(pos, 1)
    __field = JSON.stringify(__field)
    __field = __field.replaceAll('"', '!~!~')
    this.setState({ __field })
    this.on(__field)
  }
  onDatasCheck = (evt, pos, field) => {
    let __field = this.state.__field, val
    try {
      __field = JSON.parse(this.state.__field.replaceAll("!~!~", '"'));
      __field = Array.isArray(__field) ? __field : []
    }
    catch (e) {
      __field = []
    }
    switch (field) {
      case "icon":
        val = evt.currentTarget.checked ? 1 : 0
        __field[pos].icon = val
        break
      case "label":
      default:
        val = evt.currentTarget.value
        __field[pos][field] = val
        break
    }
    __field = JSON.stringify(__field)
    __field = __field.replaceAll('"', '!~!~')
    this.setState({ __field })
    this.on(__field)
  }
  onDatas = (evt, pos) => {
    let __field = this.state.__field ? [...this.state.__field.toString().split(CARD_SEPARATOR())] : []
    __field[pos] = evt.currentTarget.value
    __field = __field.join(CARD_SEPARATOR())
    console.log(__field)
    this.setState({ __field })
    this.on(__field)
  }
  onDatasValue = (value, pos) => {
    console.log(value)
    let __field = this.state.__field ? [...this.state.__field.split(CARD_SEPARATOR())] : []
    __field[pos] = value
    __field = __field.join(CARD_SEPARATOR())
    console.log(__field)
    this.setState({ __field })
    this.on(__field)
  }

  onDatasIcon = (data, pos) => {
    let __field = this.state.__field ? this.state.__field.toString().split(CARD_SEPARATOR()) : []
    __field[pos] = data
    __field = __field.join(CARD_SEPARATOR())
    this.setState({ __field })
    this.on(__field)
  }

  onMedia = (value, file) => {
    const __field = value
    this.setState({ __field })
    this.on(__field)
  }

  stringify(data) {
    return secionDataStringify(data)
  }

  parse(field) {
    return sectionDataParse(field)
  }


  copy = evt => {
    evt.stopPropagation()
    const copy = JSON.stringify(this.state.__field)
    //console.log(copy)
    console.log(this.state.__field)
    $("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='StyleClipboard'>${copy}</textarea></div>`)
    const copyText = document.getElementById("StyleClipboard")
    copyText.select()
    copyText.setSelectionRange(0, 99999999999999999999)
    document.execCommand("copy")
    $("#StyleClipboard").remove()
    AppToaster.show({
      intent: Intent.SUCCESS,
      icon: "tick",
      duration: 10000,
      message: __("Section copy to clipbord"),
    })
  }
  paste = evt => {
    evt.stopPropagation()
    navigator.clipboard.readText()
      .then((clipText) => {
        try {
          //console.log(clipText)
          const clip = JSON.parse(clipText)
          this.setState({ __field: clip })
          this.on(clip)
        } catch (e) {
          AppToaster.show({
            intent: Intent.DANGER,
            icon: "tick",
            duration: 10000,
            message: __("Error read clipboard data"),
          })
        }
      })
  }
}
export default CardSingleFieldType
export function sectionDataParse(field) {
  try {
    const __data = field.replace(/~!~!~/g, "\"").replace(/~!~!'!~!~/g, "\"\"")
    return JSON.parse(__data)
  } catch (error) {
    // console.log(field)
    return []
  }
}
export function secionDataStringify(data) {
  const __data = JSON.stringify(data)
  return __data.replace(/"/g, "~!~!~")
}
