import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Callout } from '@blueprintjs/core'
import { SubMenu } from 'react-pe-scalars/dist'
import { __ } from 'react-pe-utilities/dist'
import ScreenSizeList from 'modules/pe-landing-module/widgets/utilities/ScreenSizeList'
import { moduleExists } from 'react-pe-layouts'
import { LayoutIcon } from 'react-pe-useful/dist'

const GridEditor = props => {
    const [current, setCurrent] = useState("xl")

    if(!moduleExists("pe-landing-module")) return <Callout className="p-5">
        {
            __("Do install Landing module")
        }
    </Callout>
    const col1 = props.title || props.description
        ?
        props.vertical
            ? 
            "col-12 layout-label-vert"
            : 
            "col-12 layout-label-vert" // "col-md-3  layout-label"
        : 
        " hidden "
    const col2 = props.title || props.description
        ?
        props.vertical
            ? 
            "col-12 layout-data-vert"
            : 
            "col-12 layout-data-vert" // "col-md-9 layout-data"
        : 
        " col-12 layout-data-vert "

    const onSubMenu = () =>
    {

    }
    const onSwitch = size =>
    {
        setCurrent(size)
    }

    const onClear = () =>
    {

    } 
    const screenSizes = ScreenSizeList().map((size, i) =>
    {
        const cl = " "
        return <Button
            key={ size.id }
            type={ size.id }
            className={ `l-icon-little ${cl} m-0 p-2` }
            minimal = { size.id !== current }
            size={ size.id }
            onClick={evt => onSwitch( size.id )}
        >
            <LayoutIcon
                src={`${size.icon} fa-3x p-2`}
                className="layout-icon-little mb-1"
            />
            <div className="small">
                { __(size.title) }
            </div>            
        </Button>
    })
    return <div className="row dat strob01" row_data={props.field}>
        <div className={col1}>
            {__( props.title)}
        </div>
        <div className={col2}>
            <div className="w-100 d-flex">
                <div>
                    <ButtonGroup>
                        { screenSizes }
                    </ButtonGroup>
                </div>
                 
            </div>
            <div className="position-relative w-30px">
                <SubMenu
                    sub_menus={ props.sub_menus}
                    on={ onSubMenu}
                />
            </div>            
            {
                props.editable && !props.not_clear
                ? 
                <Button
                    className="right"
                    icon="cross"
                    minimal
                    onClick={ onClear }
                />
                :
                null
            }
        </div>
    </div>  
}

GridEditor.propTypes = {}

export default GridEditor