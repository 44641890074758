import React from 'react'
import PropTypes from 'prop-types'

/*
    Кнопка переключения этапов на панели этапов Проекта (участника)
*/
const MilestoneButton = props => {
    //console.log( props )
    let labelColor = "", labelClass = ""
    switch(props.is_auto_rait)
    {
        case true:
        case 1:
            labelColor = "text-dark-success"
            labelClass = "milestone-label-success"
            break
        case false:
        default:
            labelColor = "text-dark-primary"
            labelClass = "milestone-label-primary"
    }
    return <div 
        className={`milestone-button ${labelClass} ` + (props.currentMilestone === props.ID ? "active" : "") }
        onClick={() => props.setCurrentMilestone(props.ID)}
    >
        <span className={`milestone-button-label ${labelColor}`}>
            { props.post_title }
        </span> 
        {props.tick}
    </div> 
} 

export default MilestoneButton