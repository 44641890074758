import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AppToaster } from 'react-pe-useful/dist'
import { Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'

const VKVideoPlayer = props => {
    const [value, setValue] = useState( "" )
    const [isPlay, setPlay] = useState( true )
    const play = () =>
    {
        setPlay(true)
    }
    const stop = () =>
    {
        setPlay(false)
    }
    const onChange = evt =>
    {
        const value = evt.currentTarget.value 
        setValue( value )
        props.onChange( value )
    } 
    return props.editable
        ?
        <div className='flex-centered w-100 flex-column' >
            <input 
                type="text"
                className='form-control input dark'
                placeholder={__("VK video string")}
                value={value}
                onChange={onChange}
            />
            <div className='descr'>
                {__("У видео, которое Вы хотите опубликовать нажмите правую кнопку мыши и нажмите «Копировать код для встраивания, а потом вставьте сюда." )}
            </div>
        </div>
        :
        <VKPlayer 
            class_name=""
            value={props.value}
            height={props.height}
            width={props.width} 
        />
}

VKVideoPlayer.propTypes = {}

export default VKVideoPlayer

const VKPlayer = props =>
{ 
    return <div className="vk-video-cont" style={{ width:props.width || "100%", height: parseInt(props.height) }}>
        { getVKLink( props.value ) }
    </div>
}

export const getVKLink = (text="") =>
{
    // console.log( text )
    const tx = text.replaceAll("«", "'").replaceAll("»", "'")
    var regex = /oid=-([\d]+)&id=(\d+)&hash=(\w+)/gi
    var regex2 = /oid=([\d]+)&id=(\d+)&hash=(\w+)/i
    // console.log(tx, regex2.exec( tx ) )
    if(tx?.indexOf(`<iframe`) === 0 )
    {
        try
        {
            let ds =  regex.exec( tx )
            if(!Array.isArray(ds))
            {
                ds = regex2.exec( tx )        
                return <iframe 
                    title={ ds.join(".").toString() }
                    src={`https://vk.com/video_ext.php?oid=${ds[1]}&id=${ds[2]}&hash=${ds[3]}&autoplay=0`}
                    width="100%" 
                    height="100%" 
                    frameBorder="0" 
                    allowFullScreen="1"  
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
                />
            }
            return <iframe 
                src={`https://vk.com/video_ext.php?oid=-${ds[1]}&id=${ds[2]}&hash=${ds[3]}&autoplay=0`}
                width="100%" 
                height="100%" 
                frameBorder="0" 
                allowFullScreen="1"  
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
            />
        }
        catch(err)
        {
            console.error(err)
            AppToaster.show({
                intent: Intent.DANGER,
                icon: 'tick',
                duration: 10000,
                message: __('Uncorrect link') ,
            })
        }
    }
    else
    {
        AppToaster.show({
            intent: Intent.DANGER,
            icon: 'tick',
            duration: 10000,
            message: __('Uncorrect link. Go to VK.com and copy "Embed code"'),
        })
    }
}