import { Button, Dialog } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"
import { schema } from "react-pe-layouts"
import FieldInput from "react-pe-scalars/dist"
import { sprintf, __ } from "react-pe-utilities/dist"


const DataTypeFieldSelector = props =>
{
    const fields = Object.keys(schema()[props.data_type] ? schema()[props.data_type].apollo_fields : {})
        .filter(field => !field.hidden)
        .map((field, i) =>
        {
            return <option key={field} value={field} >
                {
                    schema()[props.data_type].apollo_fields[field].title
                }
            </option>
        })
    const on = (value, field) =>
    {
        props.on(value, field)
    }
    const data_type_displayed = props.data_type
        ?
        <>
            <div 
                className="display-7"
                dangerouslySetInnerHTML={{
                    __html: sprintf( 
                        __( "This card associated with <b>%s</b>" ), 
                        schema()[ props.data_type ]?.name 
                    )
                }}
            />
            <div 
                className="scalar-commentary" 
                dangerouslySetInnerHTML={{
                    __html: __( " Choose field of this data type to displayed content" )
                }}
            />
            <select 
                value={ props.value ?  props.value : "-1" } 
                onChange={ evt => on(evt.currentTarget.value, "data_type_field") } 
                className="form-control input dark"
            >
                <option value={"-1"}> --- </option>
                { fields }
            </select>
        </>
        :
        null
    return <div className=" w-100 " >
        {data_type_displayed} 
    </div> 
}
export default DataTypeFieldSelector

const AutoFields = props =>
{
    //console.log( props )
    let field = "";
    switch( props.data?.type )
    {
        case "cf":
        case "navlink":
        case "outerlink":
            field =  <FieldInput
                type="string"
                field="default_label"
                title={"Default label"}
                vertical
                onChange={props.on}
            />
            break
        case "check":
        case "icon":
        case "media":
        case "personal_links":
        case "price":
        case "section":
        case "empty":
        case "divider":
        case "string":
        default:
            field = props.data?.type
    }
    return <> { field } </>
}