
if ( !window.indexedDB ) window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
if ( !window.IDBTransaction ) window.IDBTransaction = window.webkitIDBTransaction || window.msIDBTransaction;
if ( !window.IDBKeyRange ) window.IDBKeyRange = window.webkitIDBKeyRange || window.msIDBKeyRange;
if ( !window.indexedDB ) throw new Error( 'IndexedDB is not awailable' );

const DB_NAME = 'peLayoutData';
const OBJECT_STORE_NAME = 'Init';

// Функция оборачивает обращение к indexedDB.open в Promise
function openDatabasePromise( DBName, ObjectStoreName, keyPath ) {
  return new Promise( ( resolve, reject ) => {
    const dbOpenRequest = window.indexedDB.open( DBName, 1 );

    dbOpenRequest.onblocked = () => {
      reject( 'Требуется обновление структуры базы данных, хранимой в вашем браузере, ' +
        'но браузер уведомил о блокировке базы данных.' );
    };

    dbOpenRequest.onerror = err => {
      console.log( 'Unable to open indexedDB ' + DBName );
      console.log( err );
      reject( 'Невозможно открыть базу данных, либо при её открытии произошла неисправимая ошибка.' +
       ( err.message ? 'Техническая информация: ' + err.message : '' ) );
    };

    dbOpenRequest.onupgradeneeded = event => {
      const db = event.target.result;
      try {
        db.deleteObjectStore( ObjectStoreName );
      } catch ( err ) { console.log( err ); }
      db.createObjectStore( ObjectStoreName, { keyPath } );
    };

    dbOpenRequest.onsuccess = () => {
      //console.info( 'Successfully open indexedDB connection to ' + DBName );
      //console.info( dbOpenRequest.result );
      resolve( dbOpenRequest.result );
    };

    dbOpenRequest.onerror = reject;
  } );
}

// Оборачиваем функции от ObjectStore, поддерживающие интерфейс IDBRequest
// в вызов с использованием Promise
function wrap( methodName ) {
  return function() {
    const [ objectStore, ...etc ] = arguments;
    // console.log(methodName, ...etc)
    return new Promise( ( resolve, reject ) => {
      const request = objectStore[ methodName ]( ...etc );
      request.onsuccess = () => resolve( request.result );
      request.onerror = reject;
    } );
  };
} 
const deletePromise = wrap( 'delete' );
const getAllPromise = wrap( 'getAll' );
const getPromise = wrap( 'get' );
const putPromise = wrap( 'put' );


export default class IndexedDbRepository {

  dbConnection 
  error 
  openDatabasePromise 
  listeners 
  stamp 

  constructor( DBName, ObjectStoreName, keyPath ) {
    this.error = null;
    this.DBName = DBName;
    this.ObjectStoreName = ObjectStoreName;
    this.keyPath = keyPath;
    this.listeners = new Set();
    this.stamp = 0;

    // конструктор нельзя объявить как async
    // поэтому вынесено в отдельную функцию
    this.openDatabasePromise = this._openDatabase( DBName, ObjectStoreName, keyPath );
  }

  async _openDatabase( DBName, ObjectStoreName, keyPath ) {
    try {
      this.dbConnection = await openDatabasePromise( DBName, ObjectStoreName, keyPath );
    } catch ( error ) {
      this.error = error;
      throw error;
    }
  }

  async _tx( txMode, callback ) {
    await this.openDatabasePromise; // await db connection
    try {
      // console.log( this.dbConnection.transaction )
      //console.log( this.ObjectStoreName )
      const transaction = this.dbConnection.transaction( [ this.ObjectStoreName ], txMode );
      const objectStore = transaction.objectStore( this.ObjectStoreName );
      return await callback( objectStore );
    } 
    catch(e)
    {
      console.log(e)
    }
    finally 
    {
      if ( txMode === 'readwrite' )
        this.onChange(); // notify listeners
    }
  }

  async findAll() {
    return this._tx( 'readonly', objectStore => getAllPromise( objectStore ) );
  }

  async findById( key ) {
    return this._tx( 'readonly', objectStore => getPromise( objectStore, key ) );
  }

  async deleteById( key ) {
    return this._tx( 'readwrite', objectStore => {
      //console.log( objectStore, key )
      deletePromise( objectStore, key )
     } );
  }
  async clear()
  {
    await this.openDatabasePromise; // await db connection
    try {
      //return await callback( objectStore );
      return new Promise( ( resolve, reject ) => {        
        const transaction = this.dbConnection.transaction( [ this.ObjectStoreName ], 'readwrite' );
        const objectStore = transaction.objectStore( this.ObjectStoreName );
        const request = objectStore.clear()
        request.onsuccess = () => resolve( request.result );
        request.onerror = reject;
      } )
    } 
    finally 
    {
        this.onChange(); // notify listeners
    }
    return this.clearPromise
  }

  async save( item ) {
    return this._tx( 'readwrite', objectStore => putPromise( objectStore, item ) );
  }

  addListener( listener ) {
    this.listeners.add( listener );
  }

  onChange() {
    this.stamp++;
    this.listeners.forEach( listener => listener( this.stamp ) );
  }

  removeListener( listener ) {
    this.listeners.delete( listener );
  }

}