 
import SectionContent from "./SectionContent"

class ExternalFeed extends SectionContent {
  is() {
    const { id } = this.state.data
    return id
  }
  renderContent(style) {

  }
}
export default ExternalFeed