import React, { useState } from "react"
import socials from "../../core/socials"
import $ from "jquery"
import SingleImg from "./SingleImg"

const ImageGroup = props =>
{
    const [selected, setSelected] = useState(props.value )
    const select = (id, img) =>
    {
        setSelected(img)
    }
    const pics = Object.keys(socials()).map((group, i) => {
        return Object.keys(socials()[group]).map((img, ii) =>
        {
            return <SingleImg
                key={ i +"_" + ii }
                i={i * 100 + ii}
                img={ img }
                imageSource={ socials()[ group ][ img ] }
                select={select}
                selected={selected}
            />
        })
    })
    return <div className="w-100 position-relative d-flex flex-wrap ">
        {pics}
    </div>
}
export default ImageGroup;