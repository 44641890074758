let config
export function initConfig(configData)
{
  config = {...config, ...configData}
}

export function google_analytics__() {
  return config.google_analytics ? config.google_analytics : "UA-000000-01"
}

export function app_url() {
  if (config.app_url) return config.app_url
}

export function gitlab_private_token() {
  if (config.gitlab_private_token) return config.gitlab_private_token
}

export function link_type__() {
  if (config.link_type) return config.link_type
}

export function server_url__() {
  if (config.server_url) return config.server_url
}

export function sourse_url__() {
  if (config.server_url) {
    const ser = [...config.server_url.split("/")]
    ser.pop()
    return ser.join("/")
  }
}
export function upload_url__() {
  if (config.upload_url) return config.upload_url
}

export function assertion_token__() {
  if (config.assertion_token) return config.assertion_token
}

export function yandex_map_api_key__() {
  if (config.yandex_map_api_key) return config.yandex_map_api_key
  return "NONE"
}

export function app_layouts__() {
  if (config.app_layouts) return config.app_layouts
}

export default config