import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import { Link, NavLink } from 'react-router-dom'
import { useFestStore } from 'modules/pe-fest-module/data/store'

const AddProjectCard = props => {
    return useFestStore.getState().options.is_register_project
        ?
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <NavLink
                className="w-100 h-100 bg-crystal border border-secondary flex-centered hover" 
                style={{ minHeight: 260 }}
                to={props.route}
            >
                <div 
                    className='d-flex flex-column align-items-center' 
                >
                    <Button minimal className='rounded-circle ' style={{width: 70, height: 70}}>
                        <Icon icon="plus" iconSize={20} size={20}/>
                    </Button> 
                    <small className='mt-2'>
                        {__("Insert new Project")}    
                    </small>
                </div>           
            </NavLink>
        </div>
        :
        null
}

AddProjectCard.propTypes = {}

export default AddProjectCard