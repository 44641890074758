import React, { Component } from "react"   
import {LayoutIcon} from 'react-pe-useful'
import DataContext from "../../views/LandingState/DataContext"
import { components } from "../../views/LandingState/data/components"

class VideoButton extends Component {
  render() {
    // console.log( this.props );
    let style = { height: 120, width: 120, margin: 1, backgroundColor: "#444" }
    if (this.props.object.video_group && DataContext.data.landing.video_group) {
      const vg = DataContext.data.landing.video_group.filter((e, i) =>
        // console.log(e, e.unique === this.props.object.video_group)
        e.unique === this.props.object.video_group)
      style.backgroundColor = Array.isArray(vg) && vg[0] ? vg[0].color : "#444"
      //console.log(this.props.object.title, vg, style.backgroundColor);
    }
    return (
      <div
        className="square2 p-3 justify-content-start mr-1 btn-item"
        style={style}
        onClick={this.onClick}
      >
        <div className="d-flex flex-column">
          <LayoutIcon
            src={components().video.icon}
            style={{ width:32, height:32,  }}
            className=" layout-icon white mx-auto "
          />
          <div className="small text-white text-center text-break">
            { this.props.object.title.substring(0,22) }...
          </div>
        </div>
      </div>
    )
  }

	onClick = () => {
	  this.props.onClick(this.props.object)
	}
}
export default VideoButton
