import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react' 
import helps from './helps'
import { Icon } from '@blueprintjs/core'


const HelpContent = props => {
    const [content, setContent] = useState("")
    useEffect(() =>
    {
        if(!helps()[props.phase]) return 
        const ElComponent = helps()[props.phase].component
        setContent(
            <ElComponent 
                festival_title={props.festival_title}
                festival_contacts={<HelpContactsDisplay {...props} />}
                project={{ title: props.title}}
            />
        )
    }, [])
    return <> {content} </>
}

HelpContent.propTypes = {}

export default HelpContent

const HelpContactsDisplay = props =>
{
    let vk, email, phone
    if( props.options.vk || props.options.vk2 )
    {
      vk = <div className="d-flex px-2">
        <Icon icon={<i className="fab fa-vk mt-2 mx-2"/>} color="#FFF"/>
        {
          props.options.vk
          ?
          <span className=" mx-2">{props.options.vk}</span>
          :
          null
        }
        {
          props.options.vk2
          ?
          <span className=" mx-2">{props.options.vk2}</span>
          :
          null
        }
      </div>
    }
    if( props.options.email || props.options.email2 )
    {
      email = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-envelope mt-2 mx-2"/>} color="#FFF"/>
        {
          props.options.email
          ?
          <span className=" mx-2">{props.options.email}</span>
          :
          null
        }
        {
          props.options.email2
          ?
          <span className=" mx-2">{props.options.email2}</span>
          :
          null
        }
      </div>
    }
    if( props.options.phone || props.options.phone2 )
    {
      phone = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-phone mt-2 mx-2" />}  color="#FFF"/>
        {
          props.options.phone
          ?
          <span className=" mx-2">{props.options.phone}</span>
          :
          null
        }
        {
          props.options.phone2
          ?
          <span className=" mx-2">{props.options.phone2}</span>
          :
          null
        }
      </div>
    } 
    return <div className='my-3'>
        {vk} {email} {phone}
    </div>
}