import React from "react"
import Style from "style-it"
import { getStyle } from "../Section"

const StandartTestanomial = props =>
{
    const { class_name, style } = props
    const {
        text, name, avatar, description, palette, composition, text_css, line_color
    } = props
     
    const getWidth = () =>
    {
        if( document.body.clientWidth <= 720 ) return 100
       if( Array.isArray( props.composition?.inner_proportia) )
       {
            return props.composition.inner_proportia[ props.i ]
       }
       else
       {
            return 100 / props.composition.columns 
       }
    }
    let col = 11;
    switch( composition.columns)
    {
      case 2:
        col = 6
        break
      case 3:
        col = 4
        break
      case 4:
        col = 3
        break
      case 5:
        col = 5
      case 6:
        col = 2
        break
      case 12:
        col = 1
        break
      case 1:
      default:
        col = 12
        break;
    }
    const myStyle = getStyle(style)
    const myTextCss = getStyle( text_css ) 
    return ( 
        <div 
            className={`landing-testanomial col-md-${col} ${class_name}`} 
            style={{ 
                ...myStyle, 
                minWidth: getWidth()        
            }}
        > 
            {
                Style.it(
                `.text::before {
                    content: "";
                    position: absolute;
                    left: 120px;
                    bottom: -20px;
                    border: 20px solid transparent;
                    border-left: 20px solid ${ 
                        myTextCss?.backgroundColor
                            ?
                            myTextCss?.backgroundColor
                            :
                            line_color 
                                ? 
                                line_color 
                                : 
                                palette?.card.backgroundColor 
                    };
                }`,
                <div
                    className="text"
                    style={{
                        ...palette?.card,
                        backgroundColor: line_color,
                        ...myTextCss
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                )
  
            }
  
            <div className="d-flex">
                {
                    avatar
                        ?
                        <div className="avatar" style={{ backgroundImage: `url(${avatar})` }} />
                        :
                        null
                }
                {
                    name || description
                        ?
                        <div
                            className="ltest-cont"
                            style={{
                                color: palette?.main_text_color
                            }}
                        >
                            <div className="name">
                                {name}
                            </div>
                            <div
                                className=""
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        </div>
                        :
                        null
                }
                
            </div>
        </div>
      ) 
}
export default StandartTestanomial