export default () => {
    return {
        Grids: {
            sparams:{
                "hidden": 1,
                "group": "layout",
                "icon" : "/assets/img/minimal/likes.svg",
                "sparam_id___for_deleting": [ 
                    {
                        "icon": "grid-view",
                        "title": "Grid layout editor",
                        "hint": "Template", 
                        "id": 1,
                        "hidden": 0,
                        "editor": "grid-landing-section-params-dialog"
                    }
                ],
                "help_url": "Cards"
            },
            grids: {                
                visualization: "grid-layout",
                landing_object: "grid",
                hidden:1,
                title: "Select screen size"
            },
            sections: {
                type: "landing_object",
                kind: "array",
                visualization: "landing-object",
                landing_object: "section",
                hidden: 0,
                title: "Sections"
            },
            grid_gap: {
                type: "number",
                hidden:0,
                title: "Grid gap"
            },
            canvas_size : {
                type: "landing_object",
                visualization: "landing-object",
                landing_object: "canvas_size",
                hidden: 0,
                title: "Canvas height"
            }, 
            vertical_align:
            {
                type:"radio",
                title : "Vertical align",
                values: [
                    {
                        "_id": "start",
                        "title":"Top"
                    },
                    {
                        "_id": "center",
                        "title":"Center"
                    },
                    {
                        "_id": "end",
                        "title":"Bottom"
                    },
                    {
                        "_id": "exact_value",
                        "title": "Exact value"
                    }
                ],
                parent: "grid_gap"
            },
            exact_value: {
                title: "Exact value",
                type: "slider",
                min: -100,
                max: 100,
                step_size: 1,
                label_step_size: 20,
                hidden: 0,
                postfix: "%",
                demand: {
                    field: "vertical_align",
                    value: [
                        "exact_value"
                    ]
                },
                parent: "grid_gap"
            },
            class_name: {
                type: "string",
                hidden: 0,
                title: "SCC-class",
                tab_commentary: "commentaries for css class",
                tab: "Style",
                tab_icon: "fas fa-palette"
            },
            style: {
                type: "landing_object",
                visualization: "landing-object",
                landing_object: "Style",
                hidden: 0,
                title: "SCC-style",
                tab: "Style",
                tab_icon: "fas fa-palette"
            },
            visible_value: {
                hidden: 1,
                value: "id"
            },
            default: {
                hidden:1,
                height:400,
                vertical_align: "center",
                style: {}
            }
        } 
    }
}