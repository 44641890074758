import React, { Component } from "react"
import Dot from "./Dot"
import Moment from "react-moment"
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment"
import "react-day-picker/lib/style.css"
import DayPicker from "react-day-picker"
import moment from "moment"
import "moment/locale/ru"
import { getFontNameByID } from "../data/PalettePresets"

class TooltipDot extends Dot {
  constructor(props) {
    super(props)
    this.state = {
      maxH: props.maxH,

    }
  }

  renderDot() {
    const e = this.props
    const time = (
      <Moment locale="ru" format="D MMMM YYYY">
        {new Date(e.date)}
      </Moment>
    )
    const { title } = e
    const { color } = e
    const { description } = e
    const { icon } = e
    const { palette } = e
    return [
      <div
        className="landing-time-top"
        style={{ minHeight: this.state.maxH }}
      >
        <div className="landing-time-line-dot-title">
          {time}
        </div>
        <div className="landing-time-line-dot-icon" style={{ backgroundImage: `url(${icon})` }} />
      </div>,

      <div
        className="landing-time-line-dot-dot"
        style={{}}
      >
        <div
          className="landing-time-line-dot-line-left hidden"
          style={{
            top: e.dots_size ? e.dots_size / 2 + 20 - 3 : 80,
            left: e.dots_size ? e.dots_size / 2 - 125 : -60,
          }}
        />
        <div
          className="landing-time-line-dot-line-right hidden"
          style={{
            top: e.dots_size ? e.dots_size / 2 + 20 - 3 : 80,
            right: e.dots_size ? e.dots_size / 2 - 125 : -60,
          }}
        />
        <div
          className="landing-time-line-dot-dot-dot"
          style={{
            width: 0,
            height: 0,
            padding: e.dot_diameter,
            backgroundColor: color,
          }}
        >
          {this.renderArrow()}
        </div>
      </div>,

      <div
        className="landing-time-bottom"
        style={{ minHeight: this.state.maxH }}
      >
        <div
          className="landing-time-line-dot-sub-title"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            color: palette.card.color,
            ...palette.card.title,
            fontFamily: getFontNameByID(palette.card.title.fontFamilyID)
          }}
        />
        <div
          className="landing-time-line-dot-description"
          dangerouslySetInnerHTML={{ __html: description }}
          style={{
            color: palette.card.color
          }}
        />
      </div>,
    ]
  }
}
export default TooltipDot
