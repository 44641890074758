import React, { Component, Fragment } from "react"
import $ from "jquery"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,

  VKShareCount,

  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon

} from "react-share";
import Style from "style-it"
import { getColor } from "./data/getColor";


const __socials = [
  {
    id: "facebook",
    component: FacebookShareButton,
    icon: FacebookIcon,
    title: "Поделиться ссылкой на Фейсбуке",
    data_via: null,
  },
  {
    id: "twitter",
    component: TwitterShareButton,
    icon:  TwitterIcon,
    title: "Поделиться ссылкой в Твиттере",
    data_via: "@Metaversitet",
  },
  {
    id: "vkontakte",
    component: VKShareButton,
    icon: VKIcon,
    title: "Поделиться ссылкой во Вконтакте",
    data_via: null,
  },
  {
    id: "odnoklassniki",
    component: OKShareButton,
    icon: OKIcon,
    title: "Поделиться ссылкой в Одноклассниках",
    data_via: null,
  },
  {
    id: "mailru",
    component: MailruShareButton,
    icon: MailruIcon,
    title: "Поделиться ссылкой в Моём мире",
    data_via: null,
  },
  {
    id: "telegramm",
    component: TelegramShareButton,
    icon: TelegramIcon,
    title: "Поделиться ссылкой в Telegramm",
    data_via: null,
  },
  {
    id: "lj",
    component: LivejournalShareButton,
    icon: LivejournalIcon,
    title: "Поделиться ссылкой в Живом Журнале",
    data_via: null,
  },
  {
    id: "viber",
    component: ViberShareButton,
    icon: ViberIcon,
    title: "Поделиться ссылкой в Viber",
    data_via: null,
  },
  {
    id: "whatsapp",
    component: WhatsappShareButton,
    icon: WhatsappIcon,
    title: "Поделиться ссылкой в Whatsapp",
    data_via: null,
  },
  {
    id: "email",
    component: EmailShareButton,
    icon: EmailIcon,
    title: "Поделиться ссылкой в E-mail",
    data_via: null,
  },
]
class SocialLikes extends SectionContent 
{
  componentDidMount() 
  {
    $(".react-share__ShareButton")
      .addClass("p-1")
        .removeClass("react-share__ShareButton")
  }

  is() 
  {
    const { data } = this.props
    return Array.isArray(data.socials) && data.socials.length > 0
  }

  renderContent(style) {
    //console.log(this.props);
    const { data, palette } = this.props
    const { 
      class_name, 
      title, 
      is_vertical, 
      thumbnail, 
      message_title, 
      message_description, 
      size, 
      type,
      fill,
      sign_fill,
      stroke,
      stroke_wight
    } = data
    // console.log( data.socials.map( e  => __socials.filter(ee => ee.id == e) ) );
    const labels = Array.isArray(data.socials)
      ? 
      data.socials.map((e, i) => {
        const dd = __socials
          .filter((ee) => ee.id == e)
          .map((social, i) => {
            const _Component =  social.component 
            const _Icon = social.icon
            return <div key={i}>
              
              <_Component 
                key={i} 
                url={window.location.href}
                title={__(message_title)} 
                subject={__(message_title)} 
                quote={__(message_description)} 
                description={__(message_description)} 
                body={__(message_description)} 
                summary={__(message_description)} 
                via={__(message_description)} 
                //imageUrl={thumbnail}
                image={thumbnail}
              > 
                <_Icon
                  size={size ? size : 32 }
                  round={type == "rounded" || typeof type == "undefined"}
                />
              </_Component>
            </div>
          })
        return dd
      })
      :			
      null

    const vertical_class = is_vertical ? " d-block " : ""
    let textStyle = { 
      color: style.color 
        ?  
        getColor(style.color, palette) 
        :  
        palette ? palette.main_text_color : null, 
      fontWeight: style.fontWeight, 
      fontSize: style.fontStyle, 
      letterSpacing: style.letterSpacing  
    }
    return Style.it(
      `
      .landing-social-likes .react-share__ShareButton > svg > rect,
      .landing-social-likes .react-share__ShareButton > svg > circle
      {
        fill: ${ getColor(fill, palette) };
        stroke-width: ${stroke_wight}px;
        stroke: ${ getColor(stroke, palette) }; 
      }
      .landing-social-likes .react-share__ShareButton > svg > path
      {
        fill: ${ getColor(sign_fill, palette) };
      }
      .react-share__ShareButton
      {
        display:block!important;
      }
      `,
      <div className={`landing-social-likes ${class_name} ${vertical_class}`} style={style}>
        <div className="my-auto " style={ textStyle }>
          { __( title ) }
        </div>
        <div
          className="landing-social-likes ml-3"
          id="share"
          data-title={title}
        >
          {labels}
        </div>
      </div>
    )
  }
}
export default SocialLikes
