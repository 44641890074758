import React, { Component } from "react"
import { Link } from "react-router-dom"
import { getFontNameByID } from "./data/PalettePresets"
import {getStyle} from "./Section"

class MotivationMember extends Component {
  render() {
    const {
      thumbnail,
      thumbnail_style,
      thumbnail_class_name,
      title,
      title_style,
      title_class_name,
      description,
      description_style,
      description_class_name,
      design_type,
      contour_type,
      form_type,
      color,
      link_type,
      link_label,
      link_route,
      palette,

      class_name,
      style,
      lasy_load_type,
      lasy_load_delay 
    } = this.props

    // console.log(this.props);
    const sr = {
      backgroundImage: `url(${thumbnail})`,
      ...getStyle(thumbnail_style, palette )
    }
    if (color) {
      switch (contour_type) {
        case "round_line_contoure":
          sr.borderColor = color
          break
        case "round_fill_contoure":
          sr.backgroundColor = color
          break
        default:
          break
      }
    }
    let link
    switch (link_type) {
      case "inner":
        link = (
          <div className="landing-motivation-link-cont">
            <Link
              className="landing-motivation-link"
              to={link_route}
            >
              {link_label}
            </Link>
          </div>
        )
        break
      case "outer":
        link = (
          <div className="landing-motivation-link-cont">
            <a
              className="landing-motivation-link"
              href={link_route}
            >
              {link_label}
            </a>
          </div>
        )
        break
      case "nothing":
      default:
        break
    }
    return thumbnail || title || description
      ? (
        <div
          className={`l-col motivation-element ${class_name}`}
          style={{
            color: palette.main_text_color,
            ...style
          }}
        >
          <div
            className={`thumbnail ${design_type} ${contour_type} ${form_type} ${thumbnail_class_name}`}
            style={sr}
          />
          <div
            className={`title ${title_class_name}`}
            style={{
              ...palette.card.title,
              fontFamily: getFontNameByID(palette.card.title.fontFamilyID),
              ...getStyle(title_style, palette)
            }}
            dangerouslySetInnerHTML={{ __html: title }} 
          />
          <div 
            className={`description ${description_class_name}`}  
            style={{ ...getStyle( description_style ) }}
            dangerouslySetInnerHTML={{ __html: description }} 
          />
          {link}
        </div>
      )
      : null
  }
}
export default MotivationMember
