import React from "react" 
import SectionContent from "./SectionContent"
import { getWidgets } from "react-pe-utilities"
import { initArea } from  "react-pe-utilities" 
import Style from "style-it"

class PEWidget extends SectionContent {
    is() {
        return this.props.data.widget_id
    }

    renderContent() { 
        const { class_name, style } = this.props.data 
        const EWidget = this.getWidgetByID();
        let prop = { user: this.props.user }   
        const mmm = [ 1, 2, 3, 4, 5 ];
        mmm.map((i) => {
            const ttl =  this.props.data['option_title_' + i]
            let val =  this.props.data['option_value_' + i]
            if(ttl && val)
            { 
                val = val.replaceAll("'", '"')
                try
                {
                    val = JSON.parse( val.replace("'", '"') )
                }
                catch (e) 
                {
                    // console.error(e)
                }
                prop[ttl] = val
            }
        })
        //console.log( prop )
        const widg = EWidget ? <EWidget {...prop} /> : null
        return <div className={`landing-pe-widget ${class_name}`} style={style}>
            { widg }
        </div> 
    }
    getWidgetByID = () =>
    {
        const { widget_id } = this.props.data
        let EWidget = null;
        const ElWidgetData = getWidgets[widget_id];
        if(ElWidgetData)
        {
            EWidget = ElWidgetData.default
        }
        return EWidget;
    }

}
export default PEWidget
