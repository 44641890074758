import gql from "graphql-tag"

const layoutVersion = localStorage.getItem("pe-layout-version") || 0
export default () => {
    return gql`
    query 
    {
      getInit( version:"${layoutVersion}" )
      {
        menu
        {
              json
        }
        folder
        {
              json
        }
        widgets
        {
            json
        }
        public_options 
        {
            json 
        }
        template 
        {
            json 
        }
        version
      }
    }`
}