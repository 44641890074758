import { Button, Dialog } from "@blueprintjs/core";
import React, {  useState } from "react";
import { __ } from "react-pe-utilities";
import PaletteSingleForm from "../../../widgets/landingObject/PaletteSingleForm";
import PalettePresets from "../data/PalettePresets";  

export default ({ isOpen, onEdit, onClose, value, onSet }) => {
    //const car = React.createRef()
    const [presets, onPresets] = useState(
        PalettePresets().map(preset => {
            if (Array.isArray(value) && value.filter((ee) => ee.id === preset.id).length > 0) {
                preset.checked = true
            }
            return preset
        })
    )
    // console.log(presets)
    const __sections = presets.map((sec, i) => (
        <div className="col-md-6" key={i}>
            <PaletteSingleForm
                e={sec}
                i={i}
                id={sec.id}
                isEdit
                onCheck={(evt) => onCheck(evt, sec)}
            />
        </div>

    ))
    const onCheck = (evt, element) => {
        const prsts = [...presets]
        const val = Array.isArray(value) ? [...value] : []
        prsts.forEach((e, i) => {
            if (e.id === element.id) {
                if (val.filter((elem) => elem.id === element.id).length > 0) {
                    val.filter((elem) => elem.id !== element.id)
                } else {
                    val.push(element)
                }
                prsts[i].checked = !prsts[i].checked
            }
        })
        onPresets(prsts)
        setTimeout(() => {
            if (onEdit) onEdit(val)
        }, 100)
    }
    // const options = {
    //     loop: true,
    //     dots: true,
    //     margin: 3,
    //     nav: true,
    //     items: 2,
    // }
    // const events = {}


    return <>
        <Dialog
            isOpen={isOpen}
            className="landing-outer-container"
            onClose={onClose}
            title={__("Choose presets")}
        >
            {
                <div className="p-5 w-100 overflow-y-auto">
                    <div className="row">
                        <div className="col-md-2">

                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                {__sections}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="p-3 d-flex justify-content-center">
                <Button onClick={
                    () => onSet(presets
                        .filter(pr => pr.checked)
                        .map(pr => {
                            delete pr.checked
                            return pr
                        })
                    )
                }
                >
                    {__("Set presets")}
                </Button>
            </div>
        </Dialog>
    </>
}