export default () =>
{
    return {
        none: {
            type:"string",
            title: "--"
        },
        post_title: {
            type:"string",
            title: "Project title"
        },
        post_content: {
            type:"string",
            title: "Content"
        },
        thumbnail:{
            type:"media",
            title: "Thumbnail"
        },
        thumbnail_name: {
            type:"string",
            title: "Thumbnail name"
        },
        fmru_group_player: {
            type:"Ganre",
            kind: "array",
            title: "Related genres"
        },
        vk_url: {
            type:"string",
            title: "VK group"
        },
        fb_url: {
            type:"string",
            title: "Facebook group"
        },
        instagramm_url: {
            type:"string",
            title: "Instagramm group"
        },
        tutor_id: {
            type:"User",
            title: "Tutor"
        },
        leader_id:  {
            type:"User",
            title: "Project leader"
        },
        member_0:  {
            type:"User",
            title: "Member 1"
        },
        member_1: {
            type:"User",
            title: "Member 2"
        },
        member_2:  {
            type:"User",
            title: "Member 3"
        },
        member_3:  {
            type:"User",
            title: "Member 4"
        },
        member_4:  {
            type:"User",
            title: "Member 5"
        },
        member_5:  {
            type:"User",
            title: "Member 6"
        },
        member_6:  {
            type:"User",
            title: "Member 7"
        },
        member_7:  {
            type:"User",
            title: "Member 8"
        },
        member_8:  {
            type:"User",
            title: "Member 9"
        },
        member_9:  {
            type:"User",
            title: "Member 10"
        }, 
    }
}