export default () => {
    return {
        KraevedTitle: {
            sparams:{
                "hidden": 1,
                "group": "kraeved",
                "icon" : "/assets/img/minimal/likes.svg"
            },
            "year": {
                "type": "string",
                "title": "Year",
                "hidden": 0
            },
            "number": {
                "type": "string",
                "title": "Number",
                "hidden": 0
            },
            "image": {
                "type": "media",
                "title": "Image",
                "hidden": 0
            },
            "title": {
                "type": "string",
                "title": "Title",
                "hidden": 0
            },
            "description": {
                "type": "string",
                "title": "Description",
                "hidden": 0
            },
            "button_label":
            {
                "type":"string",
                "hidden":0,
                "title":"Button label"
            },
            "button_route":
            {
                "type":"string",
                "hidden":0,
                "title":"Button route"
            },
            "color1": {			
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 1",
                "hidden": 0
            },
            "color2": {			
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 2",
                "hidden": 0
            },
            "color3": {
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 3",
                "hidden": 0
            },
            "color4": {
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 4",
                "hidden": 0
            },
            "class_name": {
                "type": "string",
                "hidden": 0,
                "title": "SCC-class",
                "tab_commentary": "commentaries for css class",
                "tab": "Style",
                "tab_icon": "fas fa-palette"
            },
            "style": {
                "type": "landing_object",
                "visualization": "landing-object",
                "landing_object": "Style",
                "hidden": 0,
                "title": "SCC-style",
                "tab": "Style",
                "tab_icon": "fas fa-palette"
            },
            "visible_value": {
                "hidden": 1,
                "value": "number"
            },
            "default": {
                "title": "Видеожурнал для всех, кто неравнодушен к своему краю",
                "description": "Является приложением к <a class='text-warning'  target='_blank'  href='https://rkn.gov.ru/mass-communications/reestr/media/?id=406269&page='>печатному</a> изданию «Детско-юношеский туризм и краеведение России»",
                "number": 1,
                "button_label":"Архив номеров",
                "button_route": "archive"
            }
        },
        KraevedTitle2: {
            sparams: {
                "hidden": 1,
                "group": "kraeved",
                "icon" : "/assets/img/minimal/likes.svg"    
            },
            "year": {
                "type": "string",
                "title": "Year",
                "hidden": 1
            },
            "number": {
                "type": "string",
                "title": "Number",
                "hidden": 0
            },
            "image": {
                "type": "media",
                "title": "Image",
                "hidden": 0
            },
            "title": {
                "type": "string",
                "title": "Title",
                "hidden": 1
            },
            "description": {
                "type": "string",
                "title": "Description",
                "hidden": 1
            },
            "button_label":
            {
                "type":"string",
                "hidden":0,
                "title":"Button label"
            },
            "button_route":
            {
                "type":"string",
                "hidden":0,
                "title":"Button route"
            },
            "color1": {			
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 1",
                "hidden": 0
            },
            "color2": {			
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 2",
                "hidden": 0
            },
            "color3": {
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 3",
                "hidden": 0
            },
            "color4": {
                "type": "landing_object",
                "landing_object": "ExternalLandingColor",
                "visualization": "landing-object", 
                "distination":"background",
                "title": "Color 4",
                "hidden": 0
            },
            "class_name": {
                "type": "string",
                "hidden": 0,
                "title": "SCC-class",
                "tab_commentary": "commentaries for css class",
                "tab": "Style",
                "tab_icon": "fas fa-palette"
            },
            "style": {
                "type": "landing_object",
                "visualization": "landing-object",
                "landing_object": "Style",
                "hidden": 0,
                "title": "SCC-style",
                "tab": "Style",
                "tab_icon": "fas fa-palette"
            },
            "visible_value": {
                "hidden": 1,
                "value": "number"
            },
            "default": {
                "title": "Видеожурнал для всех, кто неравнодушен к своему краю",
                "description": "Является приложением к <a class='text-warning'  target='_blank'  href='https://rkn.gov.ru/mass-communications/reestr/media/?id=406269&page='>печатному</a> изданию «Детско-юношеский туризм и краеведение России»",
                "number": 1,
                "hidden": 1,
                "button_label":"Архив номеров",
                "button_route": "archive"
            }
        },
        KraevedVideoPlayList: {
            sparams: {
                "hidden": 1,
                "group": "kraeved",
                "icon" : "/assets/img/minimal/likes.svg"
            }, 
            "title": {
                "type": "string",
                "hidden": 0,
                "title": "title"
            },
            "default_video": {
                "type": "landing_object", 
                "visualization": "landing-object",
                "landing_object": "video",
                "hidden": 0,
                "title": "Default video"
            },
            "current": {
                "type": "number",
                "title": "Current player position",
                "hidden": 0
            },
            "data_type": {
                "type": "data_type",
                "hidden": 0,
                "title": "Server enabled Data Type.",
                "demand": {
                    "field": "src_type",
                    "value": [
                        "server"
                    ]
                }
            },
            "video": {
                "type": "landing_object",
                "kind": "array",
                "visualization": "landing-object",
                "landing_object": "video",
                "hidden": 1,
                "title": "Video playlist elements",
                "demand": {
                    "field": "src_type",
                    "value": [
                        "here"
                    ]
                }
            },
            "height": {
                "type": "string",
                "hidden": 0,
                "title": "Height"
            },
            "width": {
                "type": "string",
                "hidden": 0,
                "title": "Width"
            },
            "is_sort_by_group": {
                "type": "boolean",
                "hidden": 0,
                "title": "Sort video-clips by Video Groups"
            },
            "is_sign_by_group": {
                "type": "boolean",
                "hidden": 0,
                "title": "Sign by Video Group"
            },
            "lasy_load_type": {
                "type": "landing_object",
                "visualization": "landing-object",
                "landing_object": "lasy_load_type",
                "hidden": 0,
                "tab": "Lasy",
                "tab_icon": "fas fa-video",
                "title": "Lasy Load Type"
            },
            "lasy_load_delay": {
                "type": "number",
                "tab": "Lasy",
                "tab_icon": "fas fa-video",
                "hidden": 0,
                "title": "Lasy Load delay"
            },
            "class_name": {
                "type": "string",
                "hidden": 0,
                "title": "SCC-class",
                "tab_commentary": "commentaries for css class",
                "tab": "Style",
                "tab_icon": "fas fa-palette"
            },
            "style": {
                "type": "landing_object",
                "visualization": "landing-object",
                "landing_object": "Style",
                "hidden": 0,
                "title": "SCC-style",
                "tab": "Style",
                "tab_icon": "fas fa-palette"
            },
            "visible_value": {
                "hidden": 1,
                "value": ""
            },
            "default": {
                "hidden": 1,
                "composition": {
                    "columns": 1,
                    "is_blocked": true,
                    "is_expand": false
                },
                "height": 360,
                "is_sort_by_group": true,
                "src_type": "here",
                "default_video": {
                    "title": "Johann Sebastian Bach: Prelude and Fugue in C minor, BWV 546",
                    "descriptiondescription": "The prelude is believed to be the more mature of the two movements[1] due to structural similarities between this movement and the Leipzig organ preludes of BWV 548 and BWV 544, all of which showcase a massive, intricate ritornello-concerto construction.[2] Much of the opening theme for this work was subtly arranged and transposed in G minor for the lighter opening chorus of the cantata Wer sich selbst erhöhet, der soll erniedriget werden, BWV 47,[5][6] which was performed on 13 October 1726 in Leipzig. After the stately initial dialogue, the piece is soon flanked with a flurry of running triplets, intertwined with a looming second theme, with the original one reoccurring only in fragments until its final statement.",
                    "service": "youtube",
                    "id": "cR0LEnNMiQM",
                    "modestbranding": false,
                    "autoplay": false,
                    "controls": true,
                    "mute": false,
                    "height": 320
                },
                "video": [ ],
                "is_sign_by_group": true,
                "current": 1
            }
        }
    }
}