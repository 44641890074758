import React, { useEffect, useState } from "react"
import FreeTransform from "react-free-transform";
import "../../../assets/css/tr.css";
import DataContext from "../DataContext";

const FloatTransformer = props =>
{
    const [element, setElement] = useState({ 
        ...getSizes(props), 
        x: 0,
        y: 0,
        angle: 0,
        id: "el-1", 
        classPrefix: "tr" 
    })
    const [isPress, setPress] = useState(false)
    const [x, setX] = useState(props.data.x)
    const [y] = useState(props.data.y)
    const [width, setWidth] = useState(props.data.width)
    const [height, setHeight] = useState(props.data.height)
    const [floatData, setFloatData ] = useState(DataContext.getFloatId( props.float_id ))
    useEffect(() =>
    {
        setTimeout(() =>
        {
            // console.log( props.position )
            // console.log( DataContext.getFloatId( props.float_id ) )
            setFloatData( DataContext.getFloatId( props.float_id ) ) 
            setElement( { 
                ...getSizes(props), 
                x: 0,
                y: 0,
                angle: 0,
                id: "el-1", 
                classPrefix: "tr" 
            } ) 
        }, 100)
        
    }, [props.position])
   
    const onUpdate = payload =>
    {
        const payload2 = {...payload}
        if( payload.scaleX )
            payload2.width = width * payload.scaleX
        if( payload.scaleY )
            payload2.height = height * payload.scaleY
        
        //console.log(tramsform)
        setElement({ ...element, ...payload2})
        //console.log({ ...element, ...payload2 }) 
    }
    const onMouseUp = () =>
    {
        const position = { ...floatData.position }
        const p = { } 
        p.x = { 
            value: element.x + props.data.x,
            dst: position[ props.screenSize ].x     ? position[ props.screenSize ].x.dst : position.mc.x.dst,    
            ei: position[ props.screenSize ].x      ? position[ props.screenSize ].x.ei : position.mc.x.ei,    
        
        }
        p.y = { 
            value: element.y + props.data.y,
            dst: position[ props.screenSize ].y     ? position[ props.screenSize ].y.dst : position.mc.y.dst,   
            ei: position[ props.screenSize ].y      ? position[ props.screenSize ].y.ei : position.mc.y.ei,    
        }
        p.w = { 
            value: element.width * element.scaleX,
            dst: position[ props.screenSize ].w     ? position[ props.screenSize ].w.dst : position.mc.w.dst,    
            ei: position[ props.screenSize ].w      ? position[ props.screenSize ].w.ei : position.mc.w.ei,    
            //value: 
        }
        p.h = {  
            value: element.height * element.scaleY,
            dst: position[ props.screenSize ].h     ? position[ props.screenSize ].h.dst : position.mc.h.dst,    
            ei: position[ props.screenSize ].h      ? position[ props.screenSize ].h.ei : position.mc.h.ei, 
            //value:  
        }
        p.r = {
            value : Math.floor(element.angle )
        }

        position[ props.screenSize ] = p
        const tramsform = {
            ...floatData,
            position: position
        } 
        //console.log( position )
        //console.log( tramsform )
        props.onTransform( tramsform )
        setTimeout(() =>  {
            setElement({ ...element, x:0, y:0 })
            setX(props.data.x)
        }, 40)
       
    }
    const sizes = { 
        width:element.width, 
        height: element.height,
        marginLeft: element.x, 
        marginTop: element.y, 
    }
    return <div 
        className="" 
        style={sizes} 
        onMouseDown={() => setPress(true)}
        onMouseUp={ onMouseUp }
    >
        {
            DataContext.getFloatSelectID() === props.float_id
                ?
                <>
                    <FreeTransform 
                        onUpdate={ onUpdate }
                        {...element}
                    >
                        <div 
                            style={sizes}
                        />
                    </FreeTransform>
                    <div className="position-absolute text-danger m-3 title untouchble hidden">
                        x: {element.x}          {props.data.x}<br/>
                        y: {element.y}          {props.data.y}<br/>
                        w: {element.width}      {props.data.width}<br/>
                        h: {element.height}     {props.data.height}<br/>
                        sx: {element.scaleX}    <br/>
                        sy: {element.scaleY}    <br/>
                    </div>
                </>
                :
                null
        }
            
        </div>
}
export default FloatTransformer

const getSizes = props =>
{
    const { position, screenSize } = props
    const getSz = sz =>
    {
        // console.log( position[screenSize], position[screenSize]?.[sz]?.value, screenSize )
        // console.log( position )
        const coo = position[screenSize]?.[sz]?.value
        return !isNaN( parseInt( coo ) )
            ?
            parseInt( coo )
            :
            !isNaN( parseInt( position.mc?.[sz]?.value ) ) 
                ?
                parseInt( position.mc?.[sz]?.value ) 
                :
                ["w", "h"].filter(e => sz === e).length > 0
                    ?
                    100
                    :
                    0
    }
    const sizes = {
        width:  getSz("w"),
        height: getSz("h"),
        x:      getSz("x"),
        y:      getSz("y"),
        angle:  getSz("r"),
        scaleX: 1,
        scaleY: 1,
    } 
    return sizes
}