import React, { Component } from "react"
import { Dialog } from "@blueprintjs/core"
import Moment from "react-moment"
import "react-day-picker/lib/style.css"
import "moment/locale/ru"
import {ContentByRoute} from "react-pe-useful"

class Dot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxH: props.maxH,

    }
  }

  render() {
    const e = this.props
    return <>
      <div
        className={`landing-time-line-dot ${e.is_active ? " active " : `${e.is_reverse ? " reverse " : ""}`}`}
        onClick={this.onCardClick}
        style={{ cursor: e.link_type > 0 ? "pointer" : "default", width: e.width }}
      >
        {this.renderDot()}
      </div>
      <Dialog
        title={e.dialog_title}
        isOpen={this.state.isDialogOpen}
        onClose={this.onDialogClose}
        key={2}
      >
        {
          typeof this.state.dialogContent == "string"
            ? (
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: this.state.dialogContent }}
              />
            )
            : (
              <div className="">
                {this.state.dialogContent}
              </div>
            )
        }
      </Dialog>
    </>
  }

  renderArrow() {
    const e = this.props
    if (e.link_type == "3" && e.dialog_content_type == "video") {
      return (
        <div className="lvt">
          <i className="fas fa-play fa-1x" />
        </div>
      )
    }
  }

  renderDot() {

  }

  onCardClick = (evt) => {
    const {
      link_type,
      dialog_route_content,
      dialog_content,
      dialog_content_type,
      dialog_inner_route,
      dialog_btn_label,
      dialog_youtube_id,
      dialog_vimeo_id,
      dialog_video_service,
    } = this.props
    if (link_type) {
      switch (link_type) {
        case "1":
          this.setState({
            isDialogOpen: !this.state.isDialogOpen,
            dialogContent: dialog_content,
          })

          break
        case "2":

          break
        case "3":
          let cntnt = ""
          switch (dialog_content_type) {
            case "video":
              if (dialog_video_service == "vimeo") {
                cntnt = (
                  <iframe
                    className=""
                    src={`https://player.vimeo.com/video/${dialog_vimeo_id}?autoplay=1`}
                    width="100%"
                    height={480}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                )
              } else {
                cntnt = (
                  <iframe
                    width="100%"
                    height={480}
                    className=""
                    style={{}}
                    src={`https://www.youtube.com/embed/${dialog_youtube_id}?autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )
              }

              break
            case "inner_link":
              cntnt = (
                <ContentByRoute
                  routing={dialog_route_content}
                />
              )
              break
            default:
            case "text_content":
              cntnt = <div className="p-5">{dialog_content}</div>
          }
          this.setState({
            isDialogOpen: !this.state.isDialogOpen,
            dialogContent: cntnt,
            dialog_btn_label,
            dialog_inner_route,
          })
          break
        default:
        case "0":
          break
      }
    }
  }

  onDialogClose = () => {
    this.setState({
      isDialogOpen: !this.state.isDialogOpen,
    })
  }
}
export default Dot
