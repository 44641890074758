// https://learn-reactjs.ru/core/context
import React from "react";

export const landingContextData = {
    components:{

    }
}
export const LandingContext = React.createContext({
    components:landingContextData.components,
    updateComponents: () =>
    {

    }
})