import React from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import { Button, Collapse, Icon } from '@blueprintjs/core'
import { useState } from 'react'

const AverageRatingDisplay = props => {
    const [isOpen, setOpen] = useState( false ) 
    if(!props.average_critery_rating) return null
    if(props.user.roles.filter( role => role === "Track_moderator"  || role === "administrator2"  ).length > 0 )
    {
        return <RatingListBlock
            {...props}
        />
    } 
    else
    {  
        return <>
            <Button 
                className={isOpen ? "hidden" : ""}
                minimal 
                onClick={() => setOpen(!isOpen)}                 
            >
                <Icon icon="arrow-down" color={ props.color } />
            </Button>
            <Collapse isOpen={isOpen} className="w-100">
                <RatingListBlock
                    {...props}
                />
            </Collapse>
        </> 
    }
}

AverageRatingDisplay.propTypes = {}

export default AverageRatingDisplay

const RatingListBlock = props =>
{
    if(!props.average_critery_rating) return null
    return <span className="mx-5 w-100 flex-grow-50">
        {
            Array.isArray(props.full_rating_data)
                ?
                props.full_rating_data.map( data =>
                    {
                        return <div key={data.ID} className="w-100 d-flex"> 
                            <div className="flex-grow-100 dots-after">
                                <span>{ data.display_name }</span>
                            </div>
                            <div className="">
                                { data.raiting }
                            </div>
                        </div>
                    })
                :
                null
        }
        {
            Array.isArray(props.experts)
                ?
                props.experts
                    .filter( expert => props.full_rating_data.filter(frd => {
                        return Number(frd.expert_id) === Number(expert.id) || !expert.display_name
                    }).length === 0 )
                        .map( data =>
                        {
                            return <div key={data.id} className="w-100 d-flex"> 
                                <div className="flex-grow-100 dots-after">
                                    <span>{ data.display_name }</span>
                                </div>
                                <div className="">
                                    0
                                </div>
                            </div>
                        })
                :
                null
        }
        <div className='pt-2 w-100 d-flex'>
            <span className="flex-grow-100 dots-after">{__("Average rating")}:</span> 
            <span className="ml-2 title">{(props.average_critery_rating || 0).toFixed(2)}</span>
        </div>
    </span>
}
