import React, { Component,   } from "react" 
import { Collapse } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import _fetch from "." 
import CriteryList from "./core/rating/CriteryList"
import chroma from 'chroma-js'
import Style from "style-it"

class Category extends Component {
	state = {
	  isCollapse: this.props.milestone && this.props.milestone.is_auto_rait && this.props.is_owner
      ?
      this.props.user.roles.filter((e) => e === "Tutor").length > 0
      :
      this.props.user.roles.filter((e) => e == "Expert").length > 0,
	  rating: this.props.rating,
	  d: this.props.d,
	  criteries: this.props.criteries,
	}

	// shouldComponentUpdate(nextProps, nextState) {
	//   let res = nextProps.rating !== this.state.rating
	//   res = res || nextProps.criteries !== this.state.criteries
	//   res = res || nextProps.d !== this.state.d
	//   res = res || nextState.isCollapse !== this.state.isCollapse
	//   return res
	// }

	// componentWillUpdate(nextProps, nextState) {
	//   this.setState({
	//     rating: nextProps.rating,
	//     d: nextProps.d,
	//     criteries: nextProps.criteries,
	//   })
	// }

	render() {
	  const { rating, criteries, d, id, name, category_rating_type} = this.props
	  const { } = this.state
	  const descrs = this.state.d.map((e, i) => (
      <div className="critery_cell pl-4" key={i}>
        <blockquote>
          <div className="lead">
            { e.txt }
          </div>
          <div className="mt-3">
            { e.auth }
          </div>
        </blockquote>
      </div>
	  ))
	  const descr_title = this.state.d.length > 0
	    ?	 
      <div className="p-3 lead title">
        {__("Experts's descriptions")}
        :
      </div> 
	    :			
      null
	  const style = { backgroundColor: this.props.color, color: "#f8f9fa" }
	  const style2 = { borderLeftColor: this.props.color }
	  const is_expert = this.props.user.roles.filter((e) => e == "Expert").length > 0
    const accentuated = chroma(this.props.color).get('hsl.l') > .77 ? "#6c757d" : "#FFFFFF" 
    // console.log(chroma(this.props.color).get('hsl.l') )
	  return Style.it(
      `
      .descrp,
      .critery-all-descr
      {
        background-color: ${ chroma( this.props.color ).brighten( .66 ).desaturate( .66 ).css(true)};
        color: ${accentuated};
      } 
      `,
      <div className="row w-100">
        <div className="col-md-7 col-sm-12 critery_cell first" style={style}>
          <div className="fmRuTitle">
            <span className="small pr-2 pl-4 opacity_75">
              {__("Category")}
            </span>
            <span className="font-weight-bold">
              { name}
            </span>
          </div>
        </div>
        <div className="col-md-5  col-sm-12 critery_cell d-flex" style={style}>
          <h4 className="my-auto">
            {" "}
            { rating }
            {" "}
          </h4>
          <div
            className={`fmRU_button ml-auto${this.state.isCollapse ? " open" : ""}`}
            onClick={this.onCollapseToggle}
          >
            <i className="fa fa-caret-right" style={{ color: "#f8f9fa" }} />
          </div>
        </div>
        <div className="col-md-12">
          <Collapse className="row " isOpen={this.state.isCollapse}>
            <CriteryList
              criteries={criteries}
              category_id={ id }
              color={this.props.color}
              max_raiting={this.props.max_raiting}
              rating_type={this.props.rating_type}
              category_rating_type={category_rating_type}
              user={this.props.user}
              member_id={this.props.member_id}
              is_owner={this.props.is_owner}
              milestone={this.props.milestone}
            />
          </Collapse>
        </div>
        <div className="col-md-12 grey p-0 bl-20 descrp" style={style2}>
          {descr_title}
          <div className="critery-all-descr">
            { descrs }
          </div>
        </div>
      </div>
	  )
	}

	onCollapseToggle = () => {
	  this.setState({ isCollapse: !this.state.isCollapse })
	}
}
export default Category
