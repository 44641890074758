import React, { Component } from "react"
import {
  Button, Intent, Dialog, ButtonGroup, Popover, Callout,
} from "@blueprintjs/core"
import { isRole, __ } from "react-pe-utilities"  
import Title from "./Title"
import FloatSetting from "./edit/FloatSetting"
import { components } from "./data/components"
import FloatTransformer from "./edit/FloatTransformer"
import { Waypoint } from "react-waypoint"
import Section, { getStyle } from "./Section"
import $ from "jquery"

class Float extends Component {
	state = {
	  ...this.props,
	  current_type: this.props.type,
	  dwidth: document.body.clientWidth,
	  isOpen: false,
	  isShowReg: false, 
	  isDummy: true,
	  content: "",
	  tabId: "prm"
	}  

	componentDidMount() {
	  this.updateWindowDimensions()
	  window.addEventListener("resize", this.updateWindowDimensions) 
	}

	componentWillUnmount() {
	  window.removeEventListener("resize", this.updateWindowDimensions)
	}

	updateWindowDimensions = () => {
	  this.setState({
	    dwidth: document.body.clientWidth,
	    dheight: document.body.clientHeight,

	  })
	}

	componentDidUpdate(nextProps) {
	  // console.log(nextProps);
	  let isUpdate = false
	  const state = { };
	  ["is_edit", "data", "class_name", "style", "type", "position", "floatData"]
	    .forEach((e ) => {
	      if (nextProps[e] != this.state[e]) {
	        isUpdate = true
	        state[e] = nextProps[e]
	      }
	    })
	  if (isUpdate) {
	    // console.log(state);
	    this.setState(state)
	  }
	}
	onClick = evt =>
	{
		const { class_name,  } = this.state.data 
		if( ( !this.state.link?.url && !this.state.link?.sections ) || this.state.is_edit )	return
		//console.log( this.state )
		switch( this.state.link?.link_type )
		{
			case "modal":
				const content = Array.isArray(this.state.link?.sections)
				  ?
				  this.state.link?.sections.map((section,i) =>
				  {
					// console.log(section.data)
					return <Section
						key={i}
						isDummy={this.state.isDummy}
						{...{
							...section,
							data: {
								...section.data,
								height: this.state.link?.modal_size === "large" 
									?  
									"calc(100vh - 100px)"
									:
									this.state.link?.modal_size === "full" 
										?
										"calc(100vh - 2px)"
										:
										section.data.height
							}
						}}
						section_id={section.id} 
						i={i}
						user={this.props.user}
						is_admin={ isRole("administrator", this.props.user) }
						is_edit={false}
						level={ 2 }
						onEdit={this.onEdit}
						onUp={this.onUp}
						onDn={this.onDn}
						onAdd={this.onAdd}
						onDouble={this.onDouble}
						onRnv={this.onRnv}
						onHide={this.onHide}
						onRemoveFloat={this.onRemoveFloat}
						onUpdateFloat={this.onUpdateFloat}
						onClone={this.onCloneFloat}
						// onClipboardCopy={this.onClipboardCopy}
						// onClipboardPaste={this.onClipboardPaste}
						// onWaypointEnter={this.onWaypointEnter}
						// onWaypointLeave={this.onWaypointLeave}
						// CopyArchor ={this.CopyArchor }
						// onFixedAdd={this.onFixedAdd}
						// getHelp={this.getHelp}
						// onEnterelyLink={ this.copyEnterelyLink }
						// onEmbedCode={ this.copyEmbedCode }
						// loginUser={this.loginUser}
						// onFloatSelect={this.onFloatSelect}
					/>
				  })
				  :
				  <div>
					<Callout className="p-5">
				  		{__("Empty content")}
					</Callout>
				  </div>
				this.setState({ content, isOpenContent: true })
				break;
			case "outer":
			default:
				window.open( this.state.link?.url, '_blank')
		}
		
	}
	onContentClose = () =>
	{
		this.setState({ isOpenContent: !this.state.isOpenContent })
	}
	onSelectFloat = () =>
	{
		this.props.onFloatSelect( this.props.float_id, this ) 
	}
	render()
	{
		//console.log(this.props)
		return this.state.isDummy
			?
			this.renderFloat()
			:
			this.renderDummy()
	}
	renderFloat() {
	  const { is_edit, type, isShowReg, isShowDelete, class_name, style} = this.state 
	  const z_index = this.state.data?.z_index 
	  const modal_size = this.state.link?.modal_size 
	  // console.log( this.state )
	  const ElComponent = type ? components()[type].c : Title 
	  const editble = is_edit && (isShowReg || isShowDelete)
	    ?
		<>
			<div className="float-btns tr-active ">
				<div className=" layout-centered " onClick={this.onSelectFloat}>
					<FloatTransformer
						data={{
							x: 		this.getAttr("x"),
							y: 		this.getAttr("y"),
							width: 	this.getAttr("w"),
							height: this.getAttr("h"),
							angle:	0,
							scaleX: 1,
							scaleY: 1,
						}}
						position={this.state.position}
						screenSize={ this.getScreenSize() }
						float_id={ this.state.float_id }
						onTransform={ this.onTransform }
					/>					
				</div>
			</div>
		</>
	    :			
		null
	
	const cl = `landing-fload ${ is_edit ? " edit " : "" }  ${ class_name ? class_name : "" } animated fadeIn `

	  return (
		<div
			id={`float-${this.state.float_id}`}
			className={ cl } 
			style={{
				...getStyle(style),
				position: "absolute",
				left: this.getDst("x") === "L" ? this.getAttr("x") : "auto",
				right: this.getDst("x") === "R" ? this.getAttr("x") : "auto",
				top: this.getDst("y") === "T" ? this.getAttr("y") : "auto",
				bottom: this.getDst("y") === "B" ? this.getAttr("y") : "auto",
				width: this.getAttr("w"),
				height: this.getAttr("h"),
				transform: `rotate(${this.getAttr("r")}deg)`,
				zIndex: z_index
			}}
			onMouseEnter={( ) => this.setState({ isShowReg: true })}
			onMouseOut={this.onMouseOut}
		>
			<div 
				className={`w-100 h-100 ${ this.state.link?.url || this.state.link?.sections ? ' pointer ' : ''} `} 
				onClick={ this.onClick }
			>
				<ElComponent					
					{...this.props}
					columns={0}
					is_edit={is_edit}
					level={this.props.level + 1}
				/>
			</div>
			{editble}
			<Dialog
				isOpen={this.state.isOpen}
				onClose={this.onDailogHandler}
				className="little2"
				title={__("Edit Float")}
			>
				<div className="p-0 dialog-content overflow-y-auto">
					<FloatSetting
						float_id={this.props.float_id}
						onChange={this.onUpdate}
						tabId={this.state.tabId }
					/>
				</div>
			</Dialog>
			<Dialog
				isOpen={this.state.isOpenContent}
				onClose={this.onContentClose}
				className={modal_size}
			>
				{this.state.content}
				<Button large icon="cross" className="position-absolute right top" onClick={this.onContentClose} />
			</Dialog>
			<Dialog
				className="little"
				isOpen={ this.state.isShowDelete }
				onClose={ this.onDelete }
			>
				<div className="p-5">
					<div>
						<div className="mb-2">
							{__("Delete this Float?")}
						</div>
						<ButtonGroup large className="w-100">
							<Button intent={Intent.NONE} fill onClick={this.onRemoveFloat}>
								{__("Yes")}
							</Button>
							<Button icon="cross" onClick={this.onDelete} intent={Intent.DANGER} />
						</ButtonGroup>
					</div>
				</div>
			</Dialog>
		</div>
	  )
	}
	renderDummy()
	{		
		// console.log(this.state)
		const z_index = this.state.data?.z_index
		return  <Waypoint
				topOffset={ 1 }
				bottomOffset={ 1 }
				onEnter = {({ previousPosition, currentPosition, evt }) => 
				{
					if(!this.state.isDummy) return null
					try
					{
						this.setState({ isDummy: false })
					}
					catch(error)
					{
						console.error(error)
					}
				}} 
			> 
				<div
					data-id={ this.state.id }
					id={ this.state.id }
					className={ `landing-fload landing-empty ` }
					style={{
						// ...style,
						position: "absolute",
						left: this.getDst("x") === "L" ? this.getAttr("x") : "auto",
						right: this.getDst("x") === "R" ? this.getAttr("x") : "auto",
						top: this.getDst("y") === "T" ? this.getAttr("y") : "auto",
						bottom: this.getDst("y") === "B" ? this.getAttr("y") : "auto",
						width: this.getAttr("w"),
						height: this.getAttr("h"),
						transform: `rotate(${this.getAttr("r")}deg)`,
						zIndex: z_index
					}}
				>
					
				</div>
			</Waypoint> 
	}
	getScreenSize() {
	  let p = "mc"
	  if (this.state.dwidth > 940) {
	    p = "lg"
	  } else if (this.state.dwidth > 760) {
	    p = "xl"
	  } else if (this.state.dwidth > 560) {
	    p = "sm"
	  }
	  return p
	}

	getDst(coord) {
	  const { position } = this.props
	  if (!position) {
	    return
	  }
	  const p = this.getScreenSize()
	  // console.log( coord, p,  position.mc[coord], position ); 
	  return position[p][coord] ? position[p][coord].dst : position.mc[coord].dst
	}

	getAttr(coord) {
	  const { position } = this.props
	  const p = this.getScreenSize()
	  if (!position) {
	    return
	  }
	  // console.log( coord, p, position[p], position[p][coord] );
	  const ei = position[p][coord] 
	  	? 
		position[p][coord]?.ei 
		: 
		position.mc[coord]?.ei

	  let coo = position[p][coord] 
	  	? 
		position[p][coord]?.value 
		: 
		position.mc[coord]?.value

	  coo = isNaN(parseInt(coo)) ? coo : parseInt(coo)
	  coo = ei === "%" ? `${coo}%` : coo
	  // console.log( coord,  position.mc[coord].ei, ei, position, coo );
	  return coo
	}
	// move = (right, down) =>
	// {
	// 	const { position } = this.props
	// 	const p = this.getScreenSize()
	// 	if (!position) {
	// 		return
	// 	}
	// 	const x = this.getAttr("x")
	// 	const y = this.getAttr("y")
	// 	position[p].x?.value = x + right
	// 	position[p].y?.value = x + down
	// 	this.props.onUpdate(
	// 		{
	// 			position
	// 		}, 
	// 		this.props.float_id
	// 	)
	// }
	
	onTransform = transform =>
	{ 
		this.props.onUpdate(transform, this.props.float_id) 
	}

	onDailogHandler = () => {
	  this.setState({ isOpen: !this.state.isOpen })
	}
	onClone = () =>
	{
		this.props.onClone( this.state.float_id )
	}

	onMouseOut = (evt) => {
		return
	  const rect = evt.currentTarget.getBoundingClientRect()
	  const usl = evt.clientX + window.scrollX > rect.left + window.scrollX
			&& evt.clientX + window.scrollX < rect.right + window.scrollX + 124
			&& evt.clientY + window.scrollY > rect.top + window.scrollY
			&& evt.clientY + window.scrollY < rect.bottom + window.scrollY
	  // console.log( rect.right + 124, usl );
	  if (usl) return
	  this.setState({ isShowReg: false })
	}

	onDelete =() => {
	  this.setState({ isShowDelete: !this.state.isShowDelete })
	  //
	}

	onRemoveFloat = () => {
	  this.props.onRemoveFloat(this.state.float_id)
	}

	onUpdate = (data, float_id) => {
	  //console.log(data)
	  this.props.onUpdate(data, float_id)
	  this.onDailogHandler()
	}
	onStretchFull = type =>
	{
		const section_id = this.props.section_id
		const container = $(`#${ section_id } .landing-content-container `)
		// console.log( container.height() )
		// console.log( container.width() )
		const p = this.getScreenSize()
		const position = {...this.state.position || [] }
		const pJson = JSON.stringify(position)
		
		switch(type)
		{
			case"horizontal":
				position[ p ].w.value = container.width()
				position[ p ].x.value = 0
				break;
			case"vertical":
				position[ p ].h.value = container.height()
				position[ p ].y.value = 0
				break;
			case"full":
			default:
				position[ p ].w.value = container.width()
				position[ p ].h.value = container.height()
				position[ p ].x.value = 0
				position[ p ].y.value = 0
				break;
		}
		// console.log( position[ p ] )
		if(pJson !== JSON.stringify( position ))
		{
			this.props.onUpdate(
				{
					...this.props.floatData,
					position
				}, 
				this.props.floatData.id 
			)
		}
		
	}
	onAlign = type =>
	{
		const section_id = this.props.section_id
		const container = $(`#${ section_id } .landing-content-container `) 
		const p = this.getScreenSize()
		const position = {...this.state.position || [] }
		const pJson = JSON.stringify(position) 
		switch(type)
		{
			case"left": 
				position[ p ].x.value = 0
				break;
			case"top": 
				//position[ p ].x.value = (container.width() - position[ p ].w.value)/2
				position[ p ].y.value = 0
				break;
			case"top-left":  
				position[ p ].x.value = 0
				position[ p ].y.value = 0
				break;
			case"top-right":  
				position[ p ].x.value = container.width() - position[ p ].w.value
				position[ p ].y.value = 0
				break;
			case"right": 
				position[ p ].x.value = container.width() - position[ p ].w.value
				break;
			case"bottom": 
				position[ p ].y.value = container.height() - position[ p ].h.value
				break;
			case"bottom-left":  
				position[ p ].x.value = 0
				position[ p ].y.value = container.height() - position[ p ].h.value
				break;
			case"bottom-right":  
				position[ p ].x.value = container.width() - position[ p ].w.value
				position[ p ].y.value = container.height() - position[ p ].h.value
				break;
			case"center":  
				position[ p ].x.value = (container.width() - position[ p ].w.value)/2
				position[ p ].y.value = (container.height() - position[ p ].h.value)/2
				break;
		}
		// console.log( position[ p ] )
		if(pJson !== JSON.stringify( position ))
		{
			this.props.onUpdate(
				{
					...this.props.floatData,
					position
				}, 
				this.props.floatData.id 
			)
		}
		
	}
}
export default Float
