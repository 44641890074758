import React, { Component } from "react"

import Float from "./Float"
import matrix from "./data/matrix"

class Floats extends Component { 
	state = {
	  ...this.props,
	}

	componentDidUpdate(nextProps) {
	  if (nextProps.is_edit !== this.state.is_edit) {
	    this.setState({ is_edit: nextProps.is_edit })
	  }
	  const state = { }
	  if( nextProps.floats)
	  {
		const jsonState = JSON.stringify(this.state.floats)
		const jsonNext = JSON.stringify(nextProps.floats)
		// console.log( jsonNext )
		// console.log( jsonState )
		// console.log( jsonState === jsonNext )
		if( jsonState !== jsonNext )
		{
			//console.log ( nextProps.floats );
			state.floats = nextProps.floats 
		}
	  	
	  }

	  if (Object.keys(state).length > 0) {
		//console.log( Object.keys(state).length, state );
	    this.setState({ ...state })
	  }
	}

	render() {
	  const { floats } = this.state
	  const __floats = floats
	    ?			
		floats.map((e, i) => {
			// console.log( e )
			return e
				?
				<Float
					{...e}
					floatData = { e }
					key={e.id}
					section_id={this.props.getID}
					is_edit={this.state.is_edit}
					user={this.props.user}
					onRemoveFloat={this.onRemoveFloat}
					onUpdate={this.onUpdate}
					onClone={this.onClone}
					onFloatSelect={ this.props.onFloatSelect }
				/>
				:
				null
		})
	    : 			
		[]
	  return __floats
	}

	onRemoveFloat = (float_id) => this.props.onRemoveFloat(float_id)

	onUpdate = (data, float_id) => this.props.onUpdate(data, float_id)

	onClone = float_id =>
	{
		this.props.onClone(float_id)
	}
}
export default Floats
