import { components } from "modules/pe-landing-module/views/LandingState/data/components"
import HTML from "modules/pe-landing-module/views/LandingState/HTML" 
import React from "react"

const GridCell = props =>
{
    // console.log( props.data )
    const CellComponent = props.type && components()[props.type] 
        ? 
        components()[props.type].c 
        : 
        HTML
    const getProportia = type =>
    {

    }
	const getColumns = () =>
    {

    }
    const onEdit = () =>
    {

    }
    const onSectorChange = () =>
    {

    }	
    return <div className=" " style={{width:"100%", height:"100%", pointerEvents: props.untouchble ? "none" : "auto"}}>
        <CellComponent
            {...props}
            data={{
                ...props.data,
                style: {
                    ...(props.data ? props.data.style : {}),
                    width: getProportia("content"),
                   
                },
            }}
            composition={{}}
            key={props.i + "_" + props.id}
            columns={getColumns()}
            is_edit={props.is_edit}
            level={props.level + 1}
            section_id={props.i}
            onEdit={onEdit}
            onSectionChange={onSectorChange}
            palette={{}}
        /> 
    </div>
}
export default GridCell
