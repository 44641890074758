import React, {  useState } from 'react';
import UserContext from './userContext';

const LayoutProvider = props =>
{
    const [state, setState] = useState({});
    return (
        <UserContext.Provider value={{
            user: state,
            setUser: user => {
                setState(user);
            },
        }}
        >
            { props.children }
        </UserContext.Provider>
    );
}

export default LayoutProvider;
