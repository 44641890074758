import React, { Component } from "react"
import  { parseString } from "xml2js"
import $ from "jquery"
import svg_libs from "../data/svg_lib.json"

class SVGClip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      svgClipID: props.svgClipID,
      width: props.width,
      height: props.height,

      image: props.image,
      isImageLoad: false, // typeof props.image != "undefined",
      imageWidth: "100%",
      imageHeight: "100%",
      imageProportia: 1,

      mask: props.mask,
      mask_data: "",
      isMaskLoad: false, // typeof props.mask == "undefined",
      maskWidth: "100%",
      maskHeight: "100%",
      maskProportia: 1,

    }
  }

  componentDidMount() {
    const $this = this

    // count mask size
    fetch(`/assets/img/landing/masks/${this.getSvgByID(this.state.mask).src}.xml`)
      .then((response) => response.text())
      .then((textResponse) => {
        if (textResponse) {
          // const res = textResponse
          $("body").prepend(`<div id='_id_${this.props.svgClipID}'>${textResponse}</div>`)
          // console.log( $("#_id_" + this.props.svgClipID ).find("g").html() )
          parseString(
            textResponse,
            { trim: true },
            (err, result) => {
              $this.setState({
                isMaskLoad: true,
                mask_data: $(`#_id_${this.props.svgClipID}`).find("g").html(),
                maskWidth: result.g.$.width,
                maskHeight: result.g.$.height,
                maskProportia: result.g.$.width / result.g.$.height,
              })
            },
          )

          // count image size
          const newImg = new Image()
          const state = { }
          newImg.onload = function () {
            // console.log( this.width + ' x ' + this.height );
            state.imageWidth = this.width
            state.imageHeight = this.height
            state.imageProportia = this.width / this.height
            $this.setState({ ...state, isImageLoad: true })
          }
          newImg.src = this.state.image
          $(`#_id_${this.props.svgClipID}`).detach()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getSvgByID(id) {
    const res = svg_libs.masks.filter((e) => e.id === id)[0]
	   // console.log(res);
    return res || svg_libs.masks[0]
  }

  render() {
    const {
      imageProportia,
      image,
      maskWidth,
      maskHeight,
      mask_data,
      isImageLoad,
      isMaskLoad,
      width,
      height,
    } = this.state

    if (!isImageLoad && isMaskLoad) {
      return (
        <div
          className="landing-mask-loaded"
          style={{
            width,
            height,
          }}
        >
          <div className="loader" />
        </div>
      )
    }
    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${maskWidth} ${maskHeight}`}
                // preserveAspectRatio="none"
        style={{ position: "relative" }}
      >
        <defs>
          <clipPath
            width={maskWidth}
            height={maskHeight}
            id={this.props.svgClipID}
            preserveAspectRatio="none"
            dangerouslySetInnerHTML={{ __html: mask_data }}
          />
        </defs>
        {
                    imageProportia > 1
                      ? (
                        <g
                          width={maskWidth}
                          height={maskHeight}
                          clipPath={`url(#${this.props.svgClipID})`}
                        >
                          <image
                            height="100%"
                            xlinkHref={image}
                            className="hover-transform"
                          />
                        </g>
                      )
                      : (
                        <g
                          width={maskWidth}
                          height={maskHeight}
                          clipPath={`url(#${this.props.svgClipID})`}
                        >
                          <image
                            width={maskWidth}
                            xlinkHref={image}
                            className="hover-transform"
                          />
                        </g>
                      )
                }

      </svg>
    )
    { /*
            <div
                style={{
                    backgroundImage:"url(" + image + ")",
                    backgroundSize:"cover",
                    backgroundPosition:"center",
                    width,
                    height,
                    clipPath : "url(#" + this.props.svgClipID + ")"
                }}
            />
            { maskProportia } -- { imageProportia }
            */ }
  }
}
export default SVGClip
