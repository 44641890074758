import React, { Component, useState } from "react"
import {
	Button, Intent, Dialog, ButtonGroup, Icon 
} from "@blueprintjs/core"
import { sprintf, __ } from "react-pe-utilities" 
import FieldInput from "react-pe-scalars"
import {LayoutIcon} from 'react-pe-useful'
import { CardFieldTypes } from "../../views/LandingState/Card"
import matrix from "../../views/LandingState/data/matrix"
import InputForm from "../../views/LandingState/edit/InputForm"
import CardFieldVariations from "./CardFieldVariations" 
import CardFieldMetaphors from "../../views/LandingState/card/CardFieldMetaphors"
import DataTypeFieldSelector from "modules/pe-admin-module/views/scalars/DataTypeFieldSelector"

class CardField extends Component 
{
	constructor(props) {
		super(props)
		this.state = {
			...this.props,
			grid_id: this.props.grid_id ? this.props.grid_id : this.props.i + 1,
			type: props.type || "string",
			isJoinOpen: false,
		}
		this.ref = React.createRef()
		// console.log( this.state );
	}
	componentDidUpdate(prevProps, prevState)
	{
		if(this.props.object !== prevState.object)
		{
			this.setState({object: this.props.object})
		}
	}
	render() {
		// console.log(this.state);
		let current = ""
		const sletecter = CardFieldTypes().map((e, i) => {
			const isActive = e.type === this.state.object.type
			if (isActive) {
				current = <LayoutIcon
					src={e.icon}
					className="layout-icon pr-2 w-25px "
				/>  
			}
			//console.log(e)
			return (
				<div
					className={`l-icon ${isActive ? " active " : ""}`}
					i={i}
					key={i}
					type={e.type}
					onClick={this.onType}
				>
					<LayoutIcon
						src={e.icon}
						className="layout-icon p-2 "
					/>
					<div className="smaller-text">
						{__(e.title)}
					</div>
				</div>
			)
		})
		
		return (
			<div
				className={
					"landing-card-fields-cont " +
					this.state.object.className
				}
				style={{
					//...getStyle( this.state.object.style ),
					backgroundColor: this.state.object.bgcolor,
					minHeight: `${this.state.object.height}px`,
					height:"100%",
					width:"100%",
					outlineOffset:-3,
					outline: this.props.selectField == this.props.objectId ? "3px solid red" : "0",
					zIndex: this.props.selectField == this.props.objectId ? 20 : 0
				}}
				grid-id={this.props.grid_id}
				onClick={this.props.onClick}
			>
				<div
					className={` landing-card-field ${this.getField().type}${this.state.object.variant}`}
					style={{

					}}
				>
					{this.getExample( current )}
				</div>
				<Dialog
					isOpen={this.state.isOpen}
					onClose={this.onOpen}
					title={this.state.currentType + " type!"}
					className="little2"
				>
					<div className="p-5  dialog-content overflow-y-auto">
						{ sletecter }
						<InputForm
							{...this.state}
							field={"CardField"}
							source="CardField"
							id={this.state.id}
							data={this.state.object}
							on={(value, field) => this.onField(value, field, "CardField")}
						/>
						<div className="p-1">
							{this.getSwitchVariant()}
						</div>
						<CardFieldVariations
							{...this.state}
							data={this.state.object}
							on={this.onVariation}
						/>
					</div>
					<ButtonGroup fill large>
						<Button 
							intent={Intent.SUCCESS} 
							className="flex-grow-100"
							onClick={this.onUpdate}
						>
							{__("Update")}
						</Button>
						<Button 
							intent={Intent.DANGER}
							onClick={this.onOpen}
							icon="cross"
						/>
					</ButtonGroup>
				</Dialog>
				
				<Dialog
					isOpen={this.state.isDOpen}
					onClose={this.onDClose}
					className="little"
				>
					<div className="p-5">
						<div className="text-center mb-4">
							{__("Delete card's field?")}
						</div>
						<div className="d-flex justify-content-center">
							<Button intent={Intent.DANGER} onClick={this.onClose}>
								{__("Yes")}
							</Button>
							<Button intent={Intent.NONE} onClick={this.onDClose}>
								{__("No")}
							</Button>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
	onDClose = () => {
		this.setState({ isDOpen: !this.state.isDOpen })
	}
	getSwitcher = () => {
		let current = ""
		const sletecter = CardFieldTypes().map((e, i) => {
			const isActive = e.type === this.state.object.type
			if (isActive) {
				current = <LayoutIcon
					src={e.icon}
					className="layout-icon p-1 w-30px "
				/>
			}
			//console.log(e)
			return (
				<div
					className={`l-icon ${isActive ? " active " : ""}`}
					i={i}
					key={i}
					type={e.type}
					onClick={this.onType}
				>
					<LayoutIcon
						src={e.icon}
						className="layout-icon p-2 "
					/>
					<div className="smaller-text">
						{__(e.title)}
					</div>
				</div>
			)
		})
		//console.log( this.state.origin )
		return (
			<div className="d-flex">
				{current}				
			</div>
		)
	}

	getSwitchVariant = () =>
	/*
	  return <select
		  className="form-control input dark mb-1 "
		  value={ this.state.object.variant }
		  onChange={ this.onSelectVariant }
	  >
		  {
			  matrix().CardField.variant.values.map((e, i) =>
			  {
				  if( !this.existsVariant( i ) ) return;
				  let variant = this.getField().variants[ i ];
				  return <option
					  key={i}
					  value={ e._id }
					  onClick={ this.onVariant }

				  >
					  { __( variant ? variant.title : "" ) }
				  </option>
			  })
		  }
	  </select>
	  */
	(
		<FieldInput
			title={__("Design variant")}
			field="variant"
			type="image_radio"
			_id={this.state.id}
			on={(value) => this.on(value, "variant")}
			onChange={this.onVariant}
			values={
				matrix().CardField.variant.values
					.filter((e, i) => this.existsVariant(i))
					.map((e, i) => ({
						...e,
						img: this.getField().variants[i].img,
						title: this.getField().variants[i].title,
						height: 30,
						icon_opacity: this.getField().variants[i].icon_opacity,
					}))
			}
			editable
			value={this.state.object.variant}
			vertical={false}
		/>
	)
	getMetaphorTitle(metafor) {
		return getMetaphorTitle(metafor)
	}
	getExample( current ) {
		//console.log(this.state.object.metafor)
		//const field = this.getField()
		let btns
		btns = <div className="landing-card-field-handles">
			<ButtonGroup>
				{
					this.props.isChild
						?
						<Button 
							minimal  
							className="hint hint--top mr-1"
							data-hint={__("Remove from group")}
							onClick={() => this.props.onBreakChildhood( this.props.object )}
						>
							<div className="small">
								<Icon icon="ungroup-objects" color="#FFFFFF" iconSize={12} />
							</div>
						</Button>
						:
						null
				}
				{/* */}
				<Button 
					minimal 
					className="hint hint--top mr-1"		
					data-hint={__("Edit")}
					onClick={this.onOpen}			
				>
					<div className="small">
						<Icon icon="edit" color="#FFFFFF" iconSize={12}/>
					</div>
				</Button> 
				
				<Button 
					minimal 
					className="hint hint--top mr-1"		
					data-hint={__("Double")}
					onClick={this.onClone}			
				>
					<div className="small">
						<Icon icon="duplicate" color="#FFFFFF" iconSize={12}/>
					</div>
				</Button>
				<Button 
					minimal  
					className="hint hint--top ml-0"
					data-hint={__("Delete")}
					onClick={this.onDClose}
				>
					<div className="small">
						<Icon icon="cross" color="#FFFFFF"  iconSize={12}/>
					</div>
				</Button>
			</ButtonGroup>
		</div>
		switch (this.state.object.type) {
			case "media":
				const stl = {
					backgroundImage: "url(/assets/img/employee.svg)",
					height: `${this.state.object.height}px`,
				}
				if (this.state.object.variant === 1) {
					stl.minWidth = `${this.state.object.height}px`
					stl.width = `${this.state.object.height}px`
					stl.marginLeft = -parseInt(this.state.object.height) / 2
				}
				return (
					<div
						className=" media "
						style={stl}
					>
						<div
							className="landing-card-field-example-string  d-flex align-items-center p-2 bg-light"
						>
							{current} {this.getMetaphorTitle(this.state.object.metafor)}
						</div> 
							{btns}
					</div>
				)
			case "check":
				return <div className="d-flex flex-column w-100">
					<div className="landing-card-field-example-string d-flex align-items-center" >
						{current} {this.getMetaphorTitle(this.state.object.metafor)}
					</div>  
							{btns}
				</div>
			case "personal_links":
				return <div>
					<div className="landing-card-field-example-string d-flex  align-items-center" >
						{current} {this.getMetaphorTitle(this.state.object.metafor)}
					</div>
							{btns}
					<div className=" personal_links ">
						<a className="lcard-pl" href="#">
							<i className="fab fa-vk" />
						</a>
						<a className="lcard-pl" href="#">
							<i className="fab fa-facebook-f" />
						</a>
					</div> 
				</div>
			case "outerlink":
			case "navlink":
				// console.log(this.state.object.variant)
				// console.log(this.getVariant())
				return <div>
					<div className="landing-card-field-example-string  d-flex align-items-center " >
						{current} {this.getMetaphorTitle(this.state.object.metafor)}
					</div> 
							{btns}
				</div>
			default:
				// console.log( this.state.object.variant );
				// console.log( this.getVariant() );
				return <div>
					<div className="landing-card-field-example-string d-flex  align-items-center " >
						<LayoutIcon
							src={ CardFieldTypes().filter(e => e.type === "string")[0].icon }
							className="layout-icon pr-2 w-25px "
						/>  
						{this.getMetaphorTitle(this.state.object.metafor)}
					</div> 
							{btns}
				</div>
		}
	}

	getField() {
		let field = CardFieldTypes().filter((ee) => ee.type === this.state.object.type)[0]
		field = field ? field : CardFieldTypes()[0]
		return field
	}

	existsVariant(i) {
		// console.log( i, this.getField().variants[ i ] );
		return this.getField().variants && typeof this.getField().variants[i] != "undefined"
	}

	getVariant() {
		const field = this.getField()
		//console.log( "field", field )
		try
		{
			return Array.isArray(field?.variants) && field?.variants.length > 0
						?
						field?.variants[parseInt(this.state.object.variant | 0)]
							?
							field?.variants[parseInt(this.state.object.variant | 0)]
							:
							field?.variants[0]
						:
						field?.variants[0]
		}
		catch{
			console.log( "variant", this.state.object.variant)
			return {}
		}
		
	}
	
	onField(value, field, type) {
		console.log(value, field, type);
		//const object = { object: { ...this.state.object } }
		//object.object[field] = value
		let object = { ...this.state.object }
		object[field] = value
		this.setState({ object })
		setTimeout(() => console.log( "object:", this.state.object, object ), 200)
		//this.onChange(object)
	}

	onDataTypeField = evt =>
	{
		const data_type_field = evt.currentTarget.value
		const object = { object: { ...this.state.object, data_type_field } }
		this.setState(object)
		console.log( data_type_field )
		console.log( object )
		//this.onChange(object)
	}
	onType = (evt) => {
		const type = evt.currentTarget.getAttribute("type")
		const object = { object: { ...this.state.object, type } }
		this.setState(object)
		//this.onChange(object)
	}

	onHeight = (evt) => {
		const val = evt.currentTarget.value
		const object = { object: { ...this.state.object, height: val } }
		this.setState(object)
		//this.onChange(object)
	}

	onColor = (color) => {
		const val = color.hex
		const object = { object: { ...this.state.object, color: val } }
		this.setState(object)
		//this.onChange(object)
	}

	onColorValue = (evt) => {
		const val = evt.currentTarget.value
		const object = { object: { ...this.state.object, color: val } }
		this.setState(object)
		//this.onChange(object)
	}

	onSelectVariant = (evt) => {
		const variant = evt.currentTarget.value
		const object = { object: { ...this.state.object, variant } }
		this.setState(object)
		//this.onChange(object)
	}

	onVariant = (variant) => {
		const object = { object: { ...this.state.object, variant } }
		this.setState(object)
		//this.onChange(object)
	}

	onChange = (object) => {
		setTimeout(() => {
			if (this.props.onChange) this.props.onChange(object.object, this.props.i)
		}, 100)
	}
	onUpdate = ( ) => 
	{ 
		this.onOpen()
		if (this.props.onChange) this.props.onChange(this.state.object, this.props.i) 
	}

	onClose = () => {
		this.setState({ isDOpen: !this.state.isDOpen })
		this.props.onClose(this.props.i)
	}

	on = (value) => {
		//console.log(value)
		this.props.on(value)
	}

	onOpen = (evt) => { 
		this.setState({ isOpen: !this.state.isOpen })
	}
	onClone = () =>
	{
		//console.log(this.props)
		this.props.onClone(this.props.i)
	}

	onVariation = (field, value) => {
		//console.log(field, value)
		this.onField(value, field, "CardField")
	}
}
export default CardField
export const getMetaphorTitle = (metafor) =>
{
	const m = metafor ? metafor : ""
		let title = CardFieldMetaphors().filter(e => e._id === m)[0]
		return title ? __(title.title) : ""
}
export const FieldsMetaforList = props =>
{
	const[check, setCheck] = useState( props.me + 1 )
	const on = value => 
	{
		console.log( value )
		setCheck( parseInt( value ) )
		props.on( value )
	}
	const list = ( props.fields || [] ) 
		.map( (field, i) => {
			return { _id: i + 1, title: getMetaphorTitle(field.metafor) , marked: i === props.me }
		})
	return <div className="w-100">
		<FieldInput
			vertical
			type="radio"
			value={ check }
			values={ list }
			title={ sprintf( __("Choose field for join %s "), getMetaphorTitle(props.metafor) ) }
			commentary={ sprintf( __("Поле <b>%s</b> юудет объеденено с выбранным Вами полем в одну общую секцию. Чтобы"), getMetaphorTitle(props.metafor) ) }
			on={ on }
			onChange={ on }
		/>
	</div>
}
