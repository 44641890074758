import { Button, Intent } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities/dist"
import $ from "jquery"
import { AppToaster } from "react-pe-useful/dist"
import Layouts from "react-pe-layouts"

const CopyRouteEngine = props =>
{
    const copy = evt =>
    {
        const data = JSON.stringify( Layouts() ) 
        $("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'>
            <textarea style='width:100%;' id='myInput'
                >${data}
            </textarea>
        </div>`)
		const copyText = document.getElementById("myInput")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#myInput").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Routing map copy to clipbord"),
		})
    }
    return <div className="my-4 px-3">
        <Button 
            fill 
            minimal
            large
            intent={ Intent.SUCCESS }
            className="py-3"
            onClick={ copy }
        >
            { __( "Copy routings to clipboard" ) }
        </Button>
    </div>
}
export default CopyRouteEngine