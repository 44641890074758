import React, {Component} from "react"
import ImageGroup from "./scalars/ImageGroup";
import $ from "jquery"

class QRCodeScalar extends Component
{
    render()
    {
        // console.log(this.props)
        // const {value} = this.props
        let form = <div></div>
        switch(this.props.visualizationForm)
        {
            case "imageGroup":
                form =<ImageGroup 
                    { ...this.props } 
                />
                break;
            default:    

        }
        return <div className="py-2">
           {form}
        </div>
    }
}
export default QRCodeScalar;