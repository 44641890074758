import { Icon } from "@blueprintjs/core"
import React, { useState } from "react"
import { routing, default_menu } from "react-pe-layouts"
import { ScalarField } from "react-pe-scalars"
import { __ } from "react-pe-utilities/dist"
import MenuElementBtnGroup from "../views/adminMenu/MenuElementBtnGroup"
import CurrentMenuWidget from "./menuChooser/CurrentMenuWidget"

class MenuChooseEngine extends ScalarField
{
    onChoose = evt => 
    {
        const area = evt.currentTarget.getAttribute( "area-name" )
        // console.log( area )
        this.setState({ value: area })
        this.props.onChange( area, this.props.field ) 
    }
    isEnabled() {
        return <div className="my-2 w-100 row align-items-start">  
            <div className="col-md-6 col-12">
                <div className=" s-menu-cont">
                    <div 
                        className={`s- s-header ${this.state.value === "main_menu" ? "active" : "" }`} 
                        area-name="main_menu"
                        onClick={this.onChoose}
                    /> 
                    <div 
                        className={`s- s-header-profile  ${this.state.value === "profile" ? "active" : "" }`} 
                        area-name="profile"
                        onClick={this.onChoose}
                        
                    />
                    <div 
                        className={`s- s-main-menu  ${this.state.value === "menu" ? "active" : "" }`} 
                        area-name="menu"
                        onClick={this.onChoose}

                    />
                    <div 
                        className={` s-main d-flex flex-column `}  
                        
                    >
                        <div 
                            className={`s- w-100 h-100   ${this.state.value === "extended_routes" ? "active" : "" }`} 
                            area-name="extended_routes"
                            onClick={this.onChoose}
                            
                        >
                        </div>
                        {
                            Object.keys( routing() ).filter(menu => {
                                return Object.keys(default_menu()).filter(def => def === menu).length === 0
                            })
                            .map(menu => {
                                return <div
                                    key={menu} 
                                    className={`s- p-2 w-100 mt-1  ${this.state.value === menu ? "active" : "" }`} 
                                    area-name={menu}
                                    onClick={this.onChoose}
                                >
                                    <Icon icon="menu" /> <b>{menu}</b>  
                                </div>
                            })
                        }                        
                    </div>
                    <div 
                        className={`s- s-footer  ${this.state.value === "footer" ? "active" : "" }`} 
                        area-name="footer"
                        onClick={this.onChoose}
                        
                    />
                </div>  
                <div className="display-7 pt-4">
                    { __( getCurrentMenu({ value: this.state.value })?.title || this.state.value ) }    
                </div>
                <div className=" ">
                    { __( getCurrentMenu({ value: this.state.value })?.description ) }    
                </div>          
            </div>            
            <div className=" col-md-6 col-12">
                <CurrentMenuWidget
                    menu={ this.state.value }
                />
            </div>
        </div>
    }
}
export default MenuChooseEngine

const getCurrentMenu = props =>
{
    return default_menu()[ props.value ]
}
