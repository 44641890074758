import React, { Component } from "react" 
import {LayoutIcon} from 'react-pe-useful'
import signs from "../../assets/img/landing/signs.svg"

class PlaceTypeButton extends Component {
  render() {
    //console.log(this.props.object)
    const style = { height: 120, width: 120, margin: 1 }
    style.backgroundColor = this.props.object.color ? this.props.object.color : "#777"

    return (
      <div
        className="square2 mr-1 btn-item"
        style={style}
        onClick={this.onClick}
      >
        <div>
          <LayoutIcon
            src={signs}
            className=" layout-icon white"
          />
          <div className="small text-white text-center">
            { this.props.object.title }
          </div>
        </div>
      </div>
    )
  }

	onClick = () => {
	  this.props.onClick(this.props.object)
	}
}
export default PlaceTypeButton
