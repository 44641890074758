import React from "react"
import Draggable, {DraggableCore} from 'react-draggable';

const LeftPanelDragger = props =>
{
    const onDrag = evt => {
        console.log(evt.screenX)
        props.onResize(evt.screenX)
    };
    return <Draggable axis="x" onDrag={onDrag}>
        <div className="left-panel-dragger">
            ...
        </div>
    </Draggable>
}
export default LeftPanelDragger