import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { __ } from "react-pe-utilities"
import _fetch from "."

class Contents extends Component {
  state = {
    offset: 0,
    festivals: [],
    number: 10,
  }

  render() {
    const sc = this.props.routes.map((e, i) => (
      <NavLink
        key={i}
        to={`${this.props.root}/${e.id}`}
        className=""
      >
        <div className="menu-elem">
          {__(e.id)}
        </div>
      </NavLink>
    ))
    return (
      <div className="layout-center w-100">
        <div className="admin-widgets-container">
          <div className="tutor-menu w-100">
            {sc}
          </div>
        </div>
      </div>
    )
  }
}
export default Contents
