import empty from "../assets/img/empty.svg"

import Behance_black from "../assets/img/rounded/black/Behance_black.svg"
import Facebook_black from "../assets/img/rounded/black/Facebook_black.svg"
import Instagram_black from "../assets/img/rounded/black/Instagram_black.svg"
import LinkedIn_black from "../assets/img/rounded/black/LinkedIn_black.svg"
import Mail_ru_black from "../assets/img/rounded/black/Mail.ru_black.svg"
import Messenger_black from "../assets/img/rounded/black/Messenger_black.svg"
import Ok_black from "../assets/img/rounded/black/Ok_black.svg"
import Pinterest_black from "../assets/img/rounded/black/Pinterest_black.svg"
import Skype_black from "../assets/img/rounded/black/Skype_black.svg"
import Snapchat_black from "../assets/img/rounded/black/Snapchat_black.svg"
import SoundCloud_black from "../assets/img/rounded/black/SoundCloud_black.svg"
import Spotify_black from "../assets/img/rounded/black/Spotify_black.svg"
import Telegram_black from "../assets/img/rounded/black/Telegram_black.svg"
import TikTok_black from "../assets/img/rounded/black/TikTok_black.svg"
import Tumblr_black from "../assets/img/rounded/black/Tumblr_black.svg"
import Twitch_black from "../assets/img/rounded/black/Twitch_black.svg"
import Twitter_black from "../assets/img/rounded/black/Twitter_black.svg"
import Viber_black from "../assets/img/rounded/black/Viber_black.svg"
import Vimeo_black from "../assets/img/rounded/black/Vimeo_black.svg"
import VK_black from "../assets/img/rounded/black/VK_black.svg"
import WeChat_black from "../assets/img/rounded/black/WeChat_black.svg"
import WhatsApp_black from "../assets/img/rounded/black/WhatsApp_black.svg"
import YouTube_black from "../assets/img/rounded/black/YouTube_black.svg"

import Behance_white from "../assets/img/rounded/white/Behance_white.svg"
import Facebook_white from "../assets/img/rounded/white/Facebook_white.svg"
import Instagram_white from "../assets/img/rounded/white/Instagram_white.svg"
import LinkedIn_white from "../assets/img/rounded/white/LinkedIn_white.svg"
import Mail_ru_white from "../assets/img/rounded/white/Mail.ru_white.svg"
import Messenger_white from "../assets/img/rounded/white/Messenger_white.svg"
import Ok_white from "../assets/img/rounded/white/Ok_white.svg"
import Pinterest_white from "../assets/img/rounded/white/Pinterest_white.svg"
import Skype_white from "../assets/img/rounded/white/Skype_white.svg"
import Snapchat_white from "../assets/img/rounded/white/Snapchat_white.svg"
import SoundCloud_white from "../assets/img/rounded/white/SoundCloud_white.svg"
import Spotify_white from "../assets/img/rounded/white/Spotify_white.svg"
import Telegram_white from "../assets/img/rounded/white/Telegram_white.svg"
import TikTok_white from "../assets/img/rounded/white/TikTok_white.svg"
import Tumblr_white from "../assets/img/rounded/white/Tumblr_white.svg"
import Twitch_white from "../assets/img/rounded/white/Twitch_white.svg"
import Twitter_white from "../assets/img/rounded/white/Twitter_white.svg"
import Viber_white from "../assets/img/rounded/white/Viber_white.svg"
import Vimeo_white from "../assets/img/rounded/white/Vimeo_white.svg"
import VK_white from "../assets/img/rounded/white/VK_white.svg"
import WeChat_white from "../assets/img/rounded/white/WeChat_white.svg"
import WhatsApp_white from "../assets/img/rounded/white/WhatsApp_white.svg"
import YouTube_white from "../assets/img/rounded/white/YouTube_white.svg"

import Behance_color from "../assets/img/rounded/color/Behance_color.svg"
import Facebook_color from "../assets/img/rounded/color/Facebook_color.svg"
import Instagram_color from "../assets/img/rounded/color/Instagram_color.svg"
import LinkedIn_color from "../assets/img/rounded/color/LinkedIn_color.svg"
import Mail_ru_color from "../assets/img/rounded/color/Mail.ru_color.svg"
import Messenger_color from "../assets/img/rounded/color/Messenger_color.svg"
import Ok_color from "../assets/img/rounded/color/Ok_color.svg"
import Pinterest_color from "../assets/img/rounded/color/Pinterest_color.svg"
import Skype_color from "../assets/img/rounded/color/Skype_color.svg"
import Snapchat_color from "../assets/img/rounded/color/Snapchat_color.svg"
import SoundCloud_color from "../assets/img/rounded/color/SoundCloud_color.svg"
import Spotify_color from "../assets/img/rounded/color/Spotify_color.svg"
import Telegram_color from "../assets/img/rounded/color/Telegram_color.svg"
import TikTok_color from "../assets/img/rounded/color/TikTok_color.svg"
import Tumblr_color from "../assets/img/rounded/color/Tumblr_color.svg"
import Twitch_color from "../assets/img/rounded/color/Twitch_color.svg"
import Twitter_color from "../assets/img/rounded/color/Twitter_color.svg"
import Viber_color from "../assets/img/rounded/color/Viber_color.svg"
import Vimeo_color from "../assets/img/rounded/color/Vimeo_color.svg"
import VK_color from "../assets/img/rounded/color/VK_color.svg"
import WeChat_color from "../assets/img/rounded/color/WeChat_color.svg"
import WhatsApp_color from "../assets/img/rounded/color/WhatsApp_color.svg"
import YouTube_color from "../assets/img/rounded/color/YouTube_color.svg"

import Behance_black_squared from "../assets/img/squared/black/Behance_black.svg"
import Facebook_black_squared from "../assets/img/squared/black/Facebook_black.svg"
import Instagram_black_squared from "../assets/img/squared/black/Instagram_black.svg"
import LinkedIn_black_squared from "../assets/img/squared/black/LinkedIn_black.svg"
import Mail_ru_black_squared from "../assets/img/squared/black/Mail.ru_black.svg"
import Messenger_black_squared from "../assets/img/squared/black/Messenger_black.svg"
import Ok_black_squared from "../assets/img/squared/black/Ok_black.svg"
import Pinterest_black_squared from "../assets/img/squared/black/Pinterest_black.svg"
import Skype_black_squared from "../assets/img/squared/black/Skype_black.svg"
import Snapchat_black_squared from "../assets/img/squared/black/Snapchat_black.svg"
import SoundCloud_black_squared from "../assets/img/squared/black/SoundCloud_black.svg"
import Spotify_black_squared from "../assets/img/squared/black/Spotify_black.svg"
import Telegram_black_squared from "../assets/img/squared/black/Telegram_black.svg"
import TikTok_black_squared from "../assets/img/squared/black/TikTok_black.svg"
import Tumblr_black_squared from "../assets/img/squared/black/Tumblr_black.svg"
import Twitch_black_squared from "../assets/img/squared/black/Twitch_black.svg"
import Twitter_black_squared from "../assets/img/squared/black/Twitter_black.svg"
import Viber_black_squared from "../assets/img/squared/black/Viber_black.svg"
import Vimeo_black_squared from "../assets/img/squared/black/Vimeo_black.svg"
import VK_black_squared from "../assets/img/squared/black/VK_black.svg"
import WeChat_black_squared from "../assets/img/squared/black/WeChat_black.svg"
import WhatsApp_black_squared from "../assets/img/squared/black/WhatsApp_black.svg"
import YouTube_black_squared from "../assets/img/squared/black/YouTube_black.svg"

import Behance_white_squared from "../assets/img/squared/white/Behance_white.svg"
import Facebook_white_squared from "../assets/img/squared/white/Facebook_white.svg"
import Instagram_white_squared from "../assets/img/squared/white/Instagram_white.svg"
import LinkedIn_white_squared from "../assets/img/squared/white/LinkedIn_white.svg"
import Mail_ru_white_squared from "../assets/img/squared/white/Mail.ru_white.svg"
import Messenger_white_squared from "../assets/img/squared/white/Messenger_white.svg"
import Ok_white_squared from "../assets/img/squared/white/Ok_white.svg"
import Pinterest_white_squared from "../assets/img/squared/white/Pinterest_white.svg"
import Skype_white_squared from "../assets/img/squared/white/Skype_white.svg"
import Snapchat_white_squared from "../assets/img/squared/white/Snapchat_white.svg"
import SoundCloud_white_squared from "../assets/img/squared/white/SoundCloud_white.svg"
import Spotify_white_squared from "../assets/img/squared/white/Spotify_white.svg"
import Telegram_white_squared from "../assets/img/squared/white/Telegram_white.svg"
import TikTok_white_squared from "../assets/img/squared/white/TikTok_white.svg"
import Tumblr_white_squared from "../assets/img/squared/white/Tumblr_white.svg"
import Twitch_white_squared from "../assets/img/squared/white/Twitch_white.svg"
import Twitter_white_squared from "../assets/img/squared/white/Twitter_white.svg"
import Viber_white_squared from "../assets/img/squared/white/Viber_white.svg"
import Vimeo_white_squared from "../assets/img/squared/white/Vimeo_white.svg"
import VK_white_squared from "../assets/img/squared/white/VK_white.svg"
import WeChat_white_squared from "../assets/img/squared/white/WeChat_white.svg"
import WhatsApp_white_squared from "../assets/img/squared/white/WhatsApp_white.svg"
import YouTube_white_squared from "../assets/img/squared/white/YouTube_white.svg"

import Behance_color_squared from "../assets/img/squared/color/Behance_color.svg"
import Facebook_color_squared from "../assets/img/squared/color/Facebook_color.svg"
import Instagram_color_squared from "../assets/img/squared/color/Instagram_color.svg"
import LinkedIn_color_squared from "../assets/img/squared/color/LinkedIn_color.svg"
import Mail_ru_color_squared from "../assets/img/squared/color/Mail.ru_color.svg"
import Messenger_color_squared from "../assets/img/squared/color/Messenger_color.svg"
import Ok_color_squared from "../assets/img/squared/color/Ok_color.svg"
import Pinterest_color_squared from "../assets/img/squared/color/Pinterest_color.svg"
import Skype_color_squared from "../assets/img/squared/color/Skype_color.svg"
import Snapchat_color_squared from "../assets/img/squared/color/Snapchat_color.svg"
import SoundCloud_color_squared from "../assets/img/squared/color/SoundCloud_color.svg"
import Spotify_color_squared from "../assets/img/squared/color/Spotify_color.svg"
import Telegram_color_squared from "../assets/img/squared/color/Telegram_color.svg"
import TikTok_color_squared from "../assets/img/squared/color/TikTok_color.svg"
import Tumblr_color_squared from "../assets/img/squared/color/Tumblr_color.svg"
import Twitch_color_squared from "../assets/img/squared/color/Twitch_color.svg"
import Twitter_color_squared from "../assets/img/squared/color/Twitter_color.svg"
import Viber_color_squared from "../assets/img/squared/color/Viber_color.svg"
import Vimeo_color_squared from "../assets/img/squared/color/Vimeo_color.svg"
import VK_color_squared from "../assets/img/squared/color/VK_color.svg"
import WeChat_color_squared from "../assets/img/squared/color/WeChat_color.svg"
import WhatsApp_color_squared from "../assets/img/squared/color/WhatsApp_color.svg"
import YouTube_color_squared from "../assets/img/squared/color/YouTube_color.svg"

const socials = () => {
    return {
        empty: empty,
        rounded: {
            black: {
                Behance     : Behance_black,
                Facebook    : Facebook_black,
                Instagram   : Instagram_black,
                LinkedIn    : LinkedIn_black,
                Mail_ru     : Mail_ru_black,
                Messenger   : Messenger_black,
                Ok          : Ok_black,
                Pinterest   : Pinterest_black,
                Skype       : Skype_black,
                Snapchat    : Snapchat_black,
                SoundCloud  : SoundCloud_black,
                Spotify     : Spotify_black,
                Telegram    : Telegram_black,
                TikTok      : TikTok_black,
                Tumblr      : Tumblr_black,
                Twitch      : Twitch_black,
                Twitter     : Twitter_black,
                Viber       : Viber_black,
                Vimeo       : Vimeo_black,
                VK          : VK_black,
                WeChat      : WeChat_black,
                WhatsApp    : WhatsApp_black,
                YouTube     : YouTube_black,
            },
            white: {
                Behance     : Behance_white,
                Facebook    : Facebook_white,
                Instagram   : Instagram_white,
                LinkedIn    : LinkedIn_white,
                Mail_ru     : Mail_ru_white,
                Messenger   : Messenger_white,
                Ok          : Ok_white,
                Pinterest   : Pinterest_white,
                Skype       : Skype_white,
                Snapchat    : Snapchat_white,
                SoundCloud  : SoundCloud_white,
                Spotify     : Spotify_white,
                Telegram    : Telegram_white,
                TikTok      : TikTok_white,
                Tumblr      : Tumblr_white,
                Twitch      : Twitch_white,
                Twitter     : Twitter_white,
                Viber       : Viber_white,
                Vimeo       : Vimeo_white,
                VK          : VK_white,
                WeChat      : WeChat_white,
                WhatsApp    : WhatsApp_white,
                YouTube     : YouTube_white,
            },
            color: {
                Behance     : Behance_color,
                Facebook    : Facebook_color,
                Instagram   : Instagram_color,
                LinkedIn    : LinkedIn_color,
                Mail_ru     : Mail_ru_color,
                Messenger   : Messenger_color,
                Ok          : Ok_color,
                Pinterest   : Pinterest_color,
                Skype       : Skype_color,
                Snapchat    : Snapchat_color,
                SoundCloud  : SoundCloud_color,
                Spotify     : Spotify_color,
                Telegram    : Telegram_color,
                TikTok      : TikTok_color,
                Tumblr      : Tumblr_color,
                Twitch      : Twitch_color,
                Twitter     : Twitter_color,
                Viber       : Viber_color,
                Vimeo       : Vimeo_color,
                VK          : VK_color,
                WeChat      : WeChat_color,
                WhatsApp    : WhatsApp_color,
                YouTube     : YouTube_color,
            }
        },
        squared: {
            black: {
                Behance     : Behance_black_squared,
                Facebook    : Facebook_black_squared,
                Instagram   : Instagram_black_squared,
                LinkedIn    : LinkedIn_black_squared,
                Mail_ru     : Mail_ru_black_squared,
                Messenger   : Messenger_black_squared,
                Ok          : Ok_black_squared,
                Pinterest   : Pinterest_black_squared,
                Skype       : Skype_black_squared,
                Snapchat    : Snapchat_black_squared,
                SoundCloud  : SoundCloud_black_squared,
                Spotify     : Spotify_black_squared,
                Telegram    : Telegram_black_squared,
                TikTok      : TikTok_black_squared,
                Tumblr      : Tumblr_black_squared,
                Twitch      : Twitch_black_squared,
                Twitter     : Twitter_black_squared,
                Viber       : Viber_black_squared,
                Vimeo       : Vimeo_black_squared,
                VK          : VK_black_squared,
                WeChat      : WeChat_black_squared,
                WhatsApp    : WhatsApp_black_squared,
                YouTube     : YouTube_black_squared,
            },
            white: {
                Behance     : Behance_white_squared,
                Facebook    : Facebook_white_squared,
                Instagram   : Instagram_white_squared,
                LinkedIn    : LinkedIn_white_squared,
                Mail_ru     : Mail_ru_white_squared,
                Messenger   : Messenger_white_squared,
                Ok          : Ok_white_squared,
                Pinterest   : Pinterest_white_squared,
                Skype       : Skype_white_squared,
                Snapchat    : Snapchat_white_squared,
                SoundCloud  : SoundCloud_white_squared,
                Spotify     : Spotify_white_squared,
                Telegram    : Telegram_white_squared,
                TikTok      : TikTok_white_squared,
                Tumblr      : Tumblr_white_squared,
                Twitch      : Twitch_white_squared,
                Twitter     : Twitter_white_squared,
                Viber       : Viber_white_squared,
                Vimeo       : Vimeo_white_squared,
                VK          : VK_white_squared,
                WeChat      : WeChat_white_squared,
                WhatsApp    : WhatsApp_white_squared,
                YouTube     : YouTube_white_squared,
            },
            color: {
                Behance     : Behance_color_squared,
                Facebook    : Facebook_color_squared,
                Instagram   : Instagram_color_squared,
                LinkedIn    : LinkedIn_color_squared,
                Mail_ru     : Mail_ru_color_squared,
                Messenger   : Messenger_color_squared,
                Ok          : Ok_color_squared,
                Pinterest   : Pinterest_color_squared,
                Skype       : Skype_color_squared,
                Snapchat    : Snapchat_color_squared,
                SoundCloud  : SoundCloud_color_squared,
                Spotify     : Spotify_color_squared,
                Telegram    : Telegram_color_squared,
                TikTok      : TikTok_color_squared,
                Tumblr      : Tumblr_color_squared,
                Twitch      : Twitch_color_squared,
                Twitter     : Twitter_color_squared,
                Viber       : Viber_color_squared,
                Vimeo       : Vimeo_color_squared,
                VK          : VK_color_squared,
                WeChat      : WeChat_color_squared,
                WhatsApp    : WhatsApp_color_squared,
                YouTube     : YouTube_color_squared,
            }
        }
    }
}
export default socials