import React from 'react'
import PropTypes from 'prop-types'
import DataContext from '../DataContext'
import LandingLineFlow from './LandingLineFlow'
import CarouselFlow from './CarouselFlow'

const LandingFlow = props => { 
    // if(!DataContext.data?.landing) return null
    const container_type = !DataContext.data?.landing?.container_type ? "" : DataContext.data.landing?.container_type
    switch( container_type ) 
    {
        case "carousel":
            return <CarouselFlow
                containerClassName={props.containerClassName}
                containerStyle={props.containerStyle}
                user={props.user}
            >
                {props.children}
            </CarouselFlow>
        case "landing":
        default:
            return <LandingLineFlow
                containerClassName={props.containerClassName}
                containerStyle={props.containerStyle}
                user={props.user}
            >
                {props.children}
            </LandingLineFlow>

    } 
}

LandingFlow.propTypes = {}

export default LandingFlow