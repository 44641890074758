import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import React, { Component } from "react"

class Object_Position extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      leftD: props.value && props.value.left.toString().indexOf("%") === -1 ? "px" : "%",
      topD: props.value && props.value.top.toString().indexOf("%") === -1 ? "px" : "%",
      widthD: props.value && props.value.width.toString().indexOf("%") === -1 ? "px" : "%",
      heightD: props.value && props.value.height.toString().indexOf("%") === -1 ? "px" : "%",
      leftData: props.value && props.value.left.toString() ? parseInt(props.value.left) : 0,
      topData: props.value && props.value.top.toString() ? parseInt(props.value.top) : 0,
      widthData: props.value && props.value.width.toString() ? parseInt(props.value.width) : 0,
      heightData: props.value && props.value.height.toString() ? parseInt(props.value.height) : 0,
    }
  }

  fileds() {
    return [
      {
        title: "left",
      },
      {
        title: "top",
      },
      {
        title: "width",
      },
      {
        title: "height",
      },
    ]
  }

  render() {
    // console.log( this.state );
    const dat = this.fileds().map((e, i) => (
      <div className="row dat" key={i}>
        <div className="col-md-2  layout-label">
          {e.title}
        </div>
        <div className="col-md-10 layout-data">

          <input
            type="number"
            className="input dark form-control "
            value={this.state[`${e.title}Data`]}
            style={{ width: 120 }}
            onChange={(evt) => this.onData(e.title, evt)}
          />
          <div>
            <ButtonGroup>
              <Button
                text={this.state[`${e.title}D`]}
                onClick={() => this.onD(e.title, "px")}
                style={{ width: 45, padding: 11 }}
                intent={this.state[`${e.title}D`] === "px" ? Intent.SUCCESS : Intent.WARNING}
                minimal
              />
            </ButtonGroup>
          </div>
        </div>
      </div>
    ))
    return (
      <div className="d-flex flex-column w-100 p-2">
        {dat}
      </div>
    )
  }

    onData = (data, evt) => {
      const state = { ...this.state }
      state[`${data}Data`] = evt.currentTarget.value
      this.setState(state)
      this.on()
    }

    onD = (data) => {
      const state = { ...this.state }
      state[`${data}D`] = state[`${data}D`] === "%" ? "px" : "%"
      this.setState(state)
      this.on()
    }

    on = () => {
      setTimeout(() => {
        if (this.props.on) {
          const data = {
            left: this.state.leftData + this.state.leftD,
            top: this.state.topData + this.state.topD,
            width: this.state.widthData + this.state.widthD,
            height: this.state.heightData + this.state.heightD,
          }
          this.props.on(this.props.field, data)
        }
      }, 100)
    }
}
export default Object_Position
