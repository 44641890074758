import React from "react"
import { __ } from "react-pe-utilities/dist";
import ClearSelectPanel from "./ClearSelectPanel";
import FloatEditPanel from "./currentEditPanel/FloatEditPanel";

const CurrentEditPanel = props =>
{
    return <div className="landing-current-edit-panel">  
        <FloatEditPanel
            {...props}
        />          
        <ClearSelectPanel
             {...props}
        />
    </div>
}
export default CurrentEditPanel;