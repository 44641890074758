import React, { Component } from "react"
import { __ } from "react-pe-utilities"

class PagiWidget extends Component {
  render() {
    const { count, current, full_count } = this.props
    let max = current * count + count
    max = max < full_count ? max - 1 : full_count
    const min = current * count
    return count && full_count
      ? (
        <div className="tutor-pagi-widget">
          {
            `${__("from")} ${min} ${__("to")} ${max} ${__("in")} ${full_count}`
          }
        </div>
      )
      : null
  }
}
export default PagiWidget
