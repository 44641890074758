const compareGrids = (newObj, oldObj) =>
{  
    if(!oldObj || !Array.isArray(oldObj) || oldObj.length === 0) return false
    let bool = true 
    newObj.map((e, i) => { 
        Object.keys(e).forEach(key => 
        { 
            if( oldObj[i][key] !== e[key] && ["w", "h", "x", "y"].filter(key2 => key === key2 )[0])
            {
                bool *= false
            }
        })
    }) 
    return !!bool
}
export default compareGrids