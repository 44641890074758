import { Intent } from '@blueprintjs/core';
import React, { PropTypes } from 'react';
//import styles from './vk.scss';
import { AppToaster } from "react-pe-useful/dist";
import { __ } from 'react-pe-utilities/dist';

class VKLogin extends React.Component {

  state = {
    isSdkLoaded: false,
    isProcessing: false,
  };

  componentDidMount() {
    if (document.getElementById('vk-jssdk')) {
      this.sdkLoaded();
      return;
    }
    this.setFbAsyncInit();
    this.loadSdkAsynchronously();
  }

  setFbAsyncInit() {
    const { apiId } = this.props;
    window.vkAsyncInit = () => 
	{
      window.VK.init({ apiId });
      this.setState({ isSdkLoaded: true });
    };
  }

  sdkLoaded() {
    this.setState({ isSdkLoaded: true });
  }

  loadSdkAsynchronously() 
  {
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.src = 'https://vk.com/js/api/openapi.js?139';
    el.async = true;
    el.id = 'vk-jssdk';
    document.getElementsByTagName('head')[0].appendChild(el);
  }

	checkLoginState = (response) => 
	{
		this.setState({ isProcessing: false });
		console.log( response );
		console.log( response.session === null);
    if( response.session === null ) 
    { 
      AppToaster.show({
        intent: Intent.DANGER,
        icon: "tick", 
        message: __("Sign in escaped"),
      })
      return
    }        
		window.VK.Api.call(
			"users.get",
			{uid:response.session.user.id, fields:"photo_100,contacts,email,screen_name", v:"5.95"},
			r =>
			{
				if (this.props.callback) 
				{
					r.response[0].href = response.session.user.href;
					this.props.callback(r.response);			
				}
			});
	};

	click = () => 
	{
		if (!this.state.isSdkLoaded || this.state.isProcessing || this.props.disabled) 
		{
			return;
		}
    const { apiId } = this.props;
		this.setState({ isProcessing: true });
		window.VK.Auth.login( this.checkLoginState, (+4194304) );//4194304 - email
	};

  style() 
  {
    return //<style dangerouslySetInnerHTML={{ __html: styles }}/>;
  }

  containerStyle() 
  {
    const style = { transition: 'opacity 0.5s' };
    if (this.state.isProcessing || !this.state.isSdkLoaded || this.props.disabled) {
      style.opacity = 0.6;
    }
    return Object.assign(style, this.props.containerStyle);
  }

  render() {
    const { disabled, callback, apiId, ...buttonProps } = this.props;
    return (
        <div
          {...buttonProps}
		      className={`soc vk ${this.props.className} ` }
          onClick={this.click}
		      value={this.props.value}
        >
			<i className="fab fa-vk" />
		</div>
    );
  }
}

export default VKLogin;
