import { Button, Intent } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities"

const SectionTools = ({
    sectionID, palette, level, dopEditTop,
    is_edit, is_open, is_hidden,
    onOpen, onHide, onRnv, onDialogOpen, onDouble, onAdd,
    showUndefmenu
}) => {

    return is_edit  && level === 0 && showUndefmenu
        ?
        <>
            <Button
                icon={!is_open ? "wrench" : "cross"}
                className="position-absolute right z-index-300 m-2 hidden"
                intent={Intent.NONE}
                onClick={onOpen}
                style={{
                    top: dopEditTop
                }}
            />
            <div
                className="tool-palette p-2"
                style={{
                    //right: is_open ? 0 : -50,
                    top: level * 21 + 31 + dopEditTop
                }}
            >
                <Button
                    intent={Intent.NONE}
                    icon="application" 
                    data-hint={__("Design template")}
                    className=" section-tool-btn hint hint--right"
                    tab-bar-id={"template"}
                    onClick={onDialogOpen}
                />
                <Button
                    intent={Intent.NONE}
                    icon={<i className="fas fa-coffee"></i>} 
                    data-hint={__("Change content type")} 
                    className=" section-tool-btn hint hint--right"
                    tab-bar-id={"types"}
                    onClick={onDialogOpen}
                />
                <Button
                    intent={Intent.NONE}
                    icon={<i className="fas fa-heading"></i>} 
                    data-hint={__("Title")}
                    className=" section-tool-btn hint hint--right"
                    tab-bar-id={"title"}
                    onClick={onDialogOpen}
                />
                <Button
                    intent={Intent.NONE}
                    icon="page-layout" 
                    data-hint={__("Composition")}
                    className=" section-tool-btn hint hint--right"
                    tab-bar-id={"composition"}
                    onClick={onDialogOpen}
                />
                <Button
                    intent={Intent.NONE}
                    icon={<i className="fas fa-paste"></i>}
                    data-hint={__("Smart background")}
                    className=" section-tool-btn hint hint--right"
                    tab-bar-id={"bg"}
                    onClick={onDialogOpen}
                />
                <Button
                    intent={Intent.NONE}
                    icon={<i className="fas fa-grip-lines"></i>}
                    data-hint={__("Bottom dilimiter")}
                    className=" section-tool-btn hint hint--right"
                    tab-bar-id={"dilimiter"}
                    onClick={onDialogOpen}
                />
                <Button
                    intent={Intent.NONE}
                    icon="plus"
                    onClick={ onAdd}
                    className=" section-tool-btn hint hint--right" 
                    data-hint={__("Add Secor after")}
                >                    
                </Button>
                <Button
                    intent={Intent.NONE}
                    onClick={onDouble}
                    className=" section-tool-btn hint hint--right" 
                    data-hint={__("Double this Secor")}
                >
                    <i className="far fa-clone"></i>
                </Button>
                <Button
                    icon={is_hidden ? "eye-open" : "eye-off"}
                    className=" section-tool-btn hint hint--right" 
                    data-hint={__(is_hidden ? "Show section" : "Hide section")}
                    intent={Intent.NONE}
                    onClick={onHide}
                />
                <Button
                    intent={Intent.DANGER}
                    icon="trash" 
                    data-hint={__("Remove Section")}
                    className=" section-tool-btn hint hint--right"
                    onClick={onRnv}
                />
            </div>
        </>
        :
        null
}
export default SectionTools