import React, { Component }from "react"
import SectionContent from "./SectionContent"
import DataContext from "./DataContext"
import Style from "style-it"
import ReactDOM from "react-dom"
import $ from "jquery"
import chroma from "chroma-js"
import { __ } from "react-pe-utilities" 
import { scrollToElement, scrollToElementByJQuery } from "react-pe-utilities"
import { getColor } from "./data/getColor"
import { compose} from "recompose"
import { withRouter } from "react-router"
import { LayoutIcon } from "react-pe-useful/dist"


class ArchorsMenu extends SectionContent {
	state = {
		...this.props,
		active: null,
		width: 0,
		isHumburgerOpen: false,
	}
	hh = 0;

	isDown = false
	startX
	scrollLeft

	componentDidMount() 
	{
		const domNode = ReactDOM.findDOMNode(this)
		this.hh = $(domNode).find(".landing-archor-hamburger-menu-cont > div").height()
		if (!this.state.data.is_fixed) {
			return;
		}
		// $(domNode).parents(".landing-section ").addClass("fixed");
		// const domNode = ReactDOM.findDOMNode(this);
		this.setState({
			top: $(domNode).offset().top,
			width: $(domNode).width(),
		})
		document.getElementsByClassName("landing-container")[0].addEventListener("scroll", this.scrollWindow)
		setTimeout(() =>
		{
			const scrollContainer = document.getElementById("landing-archors-menu-cont");
			if( scrollContainer )
			{
				scrollContainer.addEventListener("wheel", this.horWeel);
				scrollContainer.addEventListener('mousedown', this.mouseDown)
				scrollContainer.addEventListener('mouseup', this.mouseUp)
				scrollContainer.addEventListener('mousemove', this.mouseMove)
				this.setState({
					innerWidth : scrollContainer.scrollWidth - scrollContainer.clientWidth
				})
			}
			this.setState({isAnimated : true })	
		}, 200)
		
	}

	componentWillMount() {

	}

	componentWillUnmount() {
		const scrollContainer = document.getElementById("landing-archors-menu-cont");
		if(scrollContainer)	
		{
			scrollContainer.removeEventListener("wheel", this.horWeel)
			scrollContainer.removeEventListener('mousedown', this.mouseDown)
			scrollContainer.removeEventListener('mouseup', this.mouseUp)
			scrollContainer.removeEventListener('mousemove', this.mouseMove)
		}
			
		if (!this.state.data.is_fixed) return
		document.getElementsByClassName("landing-container")[0].removeEventListener("scroll", this.scrollWindow)
	}
	horWeel = (evt) => {
		const scrollContainer = document.getElementById("landing-archors-menu-cont");
		evt.preventDefault();
		scrollContainer.scrollLeft += evt.deltaY / 4;
	}
	mouseDown = evt =>
	{
		const scrollContainer = document.getElementById("landing-archors-menu-cont");
		this.isDown = true;
		this.startX = evt.pageX - scrollContainer.offsetLeft;
		this.scrollLeft = scrollContainer.scrollLeft;
	}
	mouseUp = evt =>
	{
		this.isDown = false;
	}
	mouseMove = evt =>
	{
		if(!this.isDown) return;
		const scrollContainer = document.getElementById("landing-archors-menu-cont");
		evt.preventDefault();
		const x = evt.pageX - scrollContainer.offsetLeft;
		const walk = (x - this.startX) * 3;
		scrollContainer.scrollLeft = this.scrollLeft - walk; 
	}
	scrollWindow = () => {
		// console.log( this.state.top, document.getElementsByClassName("landing-container")[0].scrollTop )
		if (!this.state.data.is_fixed) return
		const domNode = ReactDOM.findDOMNode(this)
		/**/
		if( this.state.top - document.getElementsByClassName("landing-container")[0].scrollTop >= 0 )
		{
			$(domNode).parents(".landing-section ").removeClass("fixed");
		}
		else
		{
			$(domNode).parents(".landing-section ").addClass("fixed");
		}
		  
		const st = { /* position: "fixed", */ zIndex: 200, width: "100%" }
		if (this.state.data.is_under_blur) {
			st.backdropFilter = `blur(${this.state.data.blur_power}px)`
			$(domNode).parents(".landing-section-container ").css(st)
			let bg = $(domNode).parents(".landing-section").css("background-color")
			bg = chroma(bg).alpha(this.state.data.opacity).css()
			$(domNode).parents(".landing-section").css({ backgroundColor: bg })
		}
	}

	updateWidth(width) {
		console.log("ArchorsMenu update...")
		const domNode = ReactDOM.findDOMNode(this)
		if (this.state.width == $(domNode).width()) return
		this.setState({
			width: $(domNode).width(),
		})
	}

	is() {
		return DataContext.data.sections
			.filter((e, i) => (e.menu && e.menu.is_enabled ? e.menu.is_enabled : false))
			.length > 0 || true
	}

	renderContent() {
		return this.state.width > 720 ? this.renderFull() : this.renderMobile()
	}

	renderMobile() {
		const { class_name, style, palette } = this.props
		const {
			is_add_header, icon, title, title_style, title_class_name, link_class_name
		} = this.props.data
		const menus = DataContext.data.sections
			.filter((e, i) => (e.menu && e.menu.is_enabled ? e.menu.is_enabled : false))
			.map((e, i) => {
				const active = this.state.active
					? this.state.active == i
						?
						" active "
						:
						""
					: ""
				return <div key={i} className="d-flex">
					{
						Style.it(
							`.menu-button.archor-menu
							{
								color:${palette.main_text_color}; 
								background-color: ${palette.card.backgroundColor};
							}`,
							<a
								i={i}
								className={`menu-button w-100 archor-menu ${link_class_name} ${active}`}
								href={`#${e.menu.id}`}
								onClick={this.onClick}
							>								
								{ this.showIcon(e.menu.icon) } 
								<span dangerouslySetInnerHTML={{ __html: e.menu.label }} />
							</a>
						)
					}
				</div>
			})
		const ttle = is_add_header
			? (
				<div 
					className="landind-arhor-menu-title " 
					onClick={() => scrollToElement(0)}
				>
					<img
						src={icon}
						className=" layout-icon "
					/>
					<div className="layout-header-title ">
						{title}
					</div>
				</div>
			)
			: null
		return (
			<div>
				<div
					className={
						`landing-archors-menu landing-section-core  ${this.props.data.class_name
						} columns-${this.props.composition.columns}`
					}
					style={{ ...this.props.data.style, height: 60 }}
				>
					{ttle}
					{
						Style.it(
							`.hamburger
							{
								color: ${palette.main_text_color}
							}`,
							<div
								className="hamburger"
								onClick={this.onHumburger}

							>
								<i className="fas fa-bars" />
							</div>
						)
					}

				</div>
				<div
					className="landing-archor-hamburger-menu-cont"
					style={this.state.isHumburgerOpen ? { height: this.hh + 60 } : { height: 0, padding: 0 }}
				>
					<div className="d-flex flex-column w-100">
						{menus}
					</div>
				</div>
			</div>
		)
	}

	renderFull() {
		const { class_name, style, type, palette } = this.props
		const {
			is_add_header, 
			icon, 
			title, 
			title_style, 
			title_class_name, 
			link_class_name, 
			align, 
			is_link_fixed, 
			icon_class_name,
			icon_style
		} = this.props.data
		//console.log( this.props.data);
		// console.log(this.state.innerWidth)
		const menus = DataContext.data.sections
			.filter((e, i) => (e.menu && e.menu.is_enabled ? e.menu.is_enabled : false))
			.map((e, i) => {
				//console.log(e.menu);
				const active = this.state.active
					? this.state.active == i
						? " active "
						: ""
					: ""
				const delay = e.menu.lasy_load_delay == null || e.menu.lasy_load_delay == 0 
					? 
					"" 
					: 
					e.menu.lasy_load_delay
				const animate = this.state.isAnimated
					?
					` animated${delay} ${e.menu.lasy_load_type ? e.menu.lasy_load_type.type : "fadeIn"} `
					:
					` animate${delay} `
				return <span key={i}>
					{
						Style.it(
							`.menu-button.archor-menu
							{
								color: ${palette.main_text_color};
							}
							.menu-button.archor-menu:hover
							{
								background-color: ${chroma(palette.color2).alpha(.666)};
								color:${palette.secondary_text_color};
							}`,
							<a
								key={i}
								i={i}
								className={`menu-button archor-menu ${link_class_name} ${active} ${animate}  `}
								href={`#${e.menu.id}`}
								onClick={this.onClick}
							>
								{ this.showIcon(e.menu.icon) } 
								<span dangerouslySetInnerHTML={{ __html: e.menu.label }} />
							</a>
						)
					}
				</span>
			})
		const ttle = is_add_header
			? (
				<div className="landind-arhor-menu-title " onClick={() => scrollToElement(0)}>
					<img
						src={icon}
						className={" layout-icon " + icon_class_name}
						style={{
							...this.getStyle(icon_style)
						}}
					/>
					<div 
						className={"layout-header-title " + title_class_name}
						style ={{
							...this.getStyle(title_style),
							color:getColor(title_style ? title_style.color : "#FFFFFF")
						}}
					>
						{title}
					</div>
				</div>
			)
			: null
		let alignCLass = "", fixClass = "";
		switch (align) {
			case "left":
				alignCLass = "justify-content-start"
				break
			case "right":
				alignCLass = "justify-content-end"
				break
			case "center":
			default:
				alignCLass = "justify-content-center"
		}
		if (is_link_fixed) {
			switch (align) {
				case "left":
					fixClass = "mr-auto"
					break
				case "right":
					fixClass = "ml-auto"
					break
				case "center":
				default:
					fixClass = "m-auto"
			}
		}
		const scrollArrow = this.state.innerWidth > 0
			?
			<div className="menu-button archor-menu untouchble fas fa-caret-right" />
			:
			null
		return (
			<div
				className={
					`landing-archors-menu landing-section-core  ${this.props.data.class_name
					} columns-${this.props.composition.columns} ` + alignCLass
				}
				style={this.props.data.style}
			>
				{ttle}
				<div className={fixClass + " landing-archors-menu-cont no-scroll "} id="landing-archors-menu-cont">
					{menus}
				</div> 
					{scrollArrow}  
				
			</div>
		)
	}
	showIcon = icon =>
	{
		if(!icon) return null
		const isImage = icon?.indexOf("http") === 0
		// console.log(icon)
		return isImage
			?
			<div className="archor-menu-icon" >
				<img src={icon} alt={icon} />
			</div>
			:
			<span className={ icon + " mr-2 "} />
	}
	onClick = (evt) => {
		evt.preventDefault()
		evt.stopPropagation()
		const targ = evt.currentTarget.getAttribute("href") 
		const i = parseInt(evt.currentTarget.getAttribute("i")) 
		const shiftY = this.state.data.is_fixed ? 60 : 0  
		// const element = document.getElementById( targ.replace("#", "") )
		// const prnt = document.getElementsByClassName("landing-container")[0]
		// console.log( element )
		// var rect = element.getBoundingClientRect();
		// console.log(rect.top, rect.right, rect.bottom, rect.left);	
		// prnt.scrollBy({
		// 	behavior : "smooth",
		// 	top: rect.top - shiftY
		// })

		scrollToElementByJQuery( targ, 1200, shiftY )
		this.setState({ isHumburgerOpen: false, active: i })
		window.history.replaceState(undefined, undefined, targ)

	}

	onHumburger = () => {
		this.setState({ isHumburgerOpen: !this.state.isHumburgerOpen })
	}
}
export default compose( 
    withRouter,
  )(ArchorsMenu)
