import React from "react";
import Section, { getStyle } from "./Section";

const Dilimiter = props => {
    const sections = Array.isArray(props.sections)
        ?
        props.sections.map((section, i) => {
            return <Section
                {...section}
                background={{}}
                section_id={section.id}
                key={i}
                i={i}
                user={props.user}
                is_edit={props.is_edit}
                level={props.level}
                // onEdit={ onEdit}
                // onUp={ onUp}
                // onDn={ onDn}
                // onAdd={ onAdd}
                // onDouble={onDouble}
                // onRnv={onRnv}
                // onHide={onHide}
                // onRemoveFloat={onRemoveFloat}
                // onUpdateFloat={onUpdateFloat}
                // onClipboardCopy={onClipboardCopy}
                // onClipboardPaste={onClipboardPaste}
                // onWaypointEnter={onWaypointEnter}
                // onWaypointLeave={onWaypointLeave}
                // onFixedAdd={onFixedAdd}
                />
        })
        :
        null

    let align = "justify-content-center"
    switch (props.align) {
        case "left":
            align = "justify-content-start"
            break
        case "right":
            align = "justify-content-end"
            break
        default:
            align = "justify-content-center"
    }
    return <div
        className={"landing-section-dilimiter " + align}
        style={getStyle(props.style)}
    >
        {sections}
    </div>
}
export default Dilimiter