import React, { useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import WPFestSettings from '../../utilities/WPFestSettings'
import TextArea from '../../utilities/TextArea'
import { Button, Dialog } from '@blueprintjs/core'

const CriteryDescriptionDisplay = props => 
{
    if( !props.is_expert ) return null
    return <div className="text-secondary font-weight-light ">
        <span className=" descr-label mr-3">
            {__("Your description:")}
        </span>
        <strong className="font-italic critery-descr">
            { props.description }
        </strong>
        <Button
            minimal
            icon="edit"
            title={__("Edit")}
            onClick={props.onOpen}
        >            
        </Button>        
    </div>
}

CriteryDescriptionDisplay.propTypes = {}

export default CriteryDescriptionDisplay