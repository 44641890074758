import React, { Component } from "react"


class Answer extends Component {
	state = {

	}

	render() {
		// console.log( this.state );
		const {
			question_label, question_image, question_descr, style, i, section_id,
		} = this.props
		return (
			<div
				className="landing-matching-answer-single "
				style={this.getStyle(style)}
			>
				<div className="landing-matching-answer-content">
					<label 
						className="_check_ d-flex flex-column " 
						htmlFor={`matchinger_${i}_${section_id}`}
					>
						<div className="landing-matching-answer-media">
							{
								question_image
									? <img alt="" src={question_image} className="landing-matching-answer-image" />
									: null
							}
						</div>
						<div className="landing-matching-answer-title ">
							<input
								name="matchinger"
								type="radio"
								className=""
								checked={this.state.checked === question_label}
								onClick={this.onChange}
								onChange={this.onChange}
								id={`matchinger_${i}_${section_id}`}
							/>
							{question_label}
						</div>
						{
							question_descr
								? (
									<div className="landing-matching-answer-descr">
										{question_descr}
									</div>
								)
								: null
						}
					</label>
				</div>
			</div>
		)
	}

	onChange = () => {
		const { question_label } = this.props
		// console.log(question_label);
		this.setState({ checked: question_label })
		setTimeout( () => { this.props.onChange(this.props) }, 500 )
	}

	getStyle = (styleObj) => {
		const style = []
		if (!styleObj) return style
		// console.log( styleObj );
		Object.entries(styleObj)
			.filter((e) =>
				// console.log( e );
				e[1] && e[1].field != "")
			.forEach((e, i) => {
				// console.log( e );
				if (e[1] && e[1].field) {
					const attr = {}
					style[e.field] = e.value
				} else {
					style[e[0]] = e[1]
				}
			})
		// console.log( style );
		return style
	}
}
export default Answer
