import React from "react" 
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import IncludeSection from "./IncludeSection"
import Style from "style-it" 
import EditibleText from "./edit/EditibleText"
import LinkContainer from "./utils/LinkContainer"
import $ from "jquery"

class HTML extends SectionContent 
{
    includes = []
    getState()
    {
        this.includes = []
        return {
            editible : "",
            text: this.props.data.text
        }
    }
    
    componentDidMount()
    {
        const {palette} = this.props
        const {sections } = this.props.data  
        // console.log( this.props )   
        // console.log(this.includes)   
        if( Array.isArray(sections) )
        {
            setTimeout(() => {
                try
                {
                    this.includes = sections.map((section, i) =>
                    {
                        // console.log( $("#" + section.text_id).length )
                        //console.log( document.getElementById( section.text_id ) )
                        return <IncludeSection
                            key={ i }
                            data={{ ...section }}
                            palette={ palette }
                            modal={ document.getElementById( section.text_id ) }
                        />
                    })
                    this.setState({ includes : this.includes }) 
                }
                catch(e)
                {
                    console.alert(e)
                }
            }, 100)
            
        }
    }
    onClick = evt =>
    {

    }
    
    onCloseEdit = ( )=>
    { 
        // console.log( this.props.data.text )
        this.setState({ editible: "", data: { ...this.state.data, text: this.props.data.text } })
    }
    renderContent(style) {
        //console.log( this.props.data, this.state.data )
        //console.log("render: ",  this.state )
        const { palette, is_edit } = this.state;
        const { 
            text,
            class_name,
            height, 
            width, 
            vertical_align, 
            exact_value, 
            horizontal_align,
            exact_horizontal_value,  
            text_align, 
            text_size,  
            scrolling,  
            link_url,
            link_target
        } = this.state.data
        
        let valign = vertical_align ? vertical_align : "center"
        let ve;
        let marginLeft, marginRight, fontSize
        if( vertical_align === "exact_value" )
        {
            valign = "top"
            ve = exact_value + "%"
        }
        switch(horizontal_align)
        {
            case "start":
                marginLeft = 0
                marginRight = "auto"
                break
            case "end":
                marginLeft = "auto"
                marginRight = 0
                break
            default:
                marginLeft = exact_horizontal_value
                    ? 
                    exact_horizontal_value + "%"
                    :
                    "auto"
                marginRight = "auto"
                break
        }
        switch(text_size)
        {
            case "small":
                fontSize = ".8"
                break
            case "large":
                fontSize = "1.4"
                break
            case "giant":
                fontSize = "2"
                break
            case "normal":
            default:
                fontSize = "1"
        }
        let overlow = "hidden auto"
        switch(scrolling)
        {
            case "auto":
                overlow = "hidden auto"
                break
            case "yes":
                overlow = "hidden scroll"
                break
            case "none":
            default:
                overlow = "hidden"
                break
        }
        return <> 
        {
            Style.it(
                `
                .landing-html
                {
                    font-size: ${fontSize}rem;
                }
                .editible-text 
                {
                    outline-color: ${
                        this.props.revertColor  
                    }!important;
                }
                .editibale-text--content
                {
                    overflow:${overlow}!important;
                }`,
                <div
                    className={ ` landing-html columns-${this.state.composition.columns} landing-section-core ` }
                >
                    <LinkContainer
                        link_url={link_url}
                        link_target={link_target}
                    >
                        <EditibleText 
                            style={{
                                maxWidth:width ? width : null,
                                justifyContent : valign,
                                marginTop: ve,
                                marginLeft,
                                marginRight,
                                height: height ? height : style?.height, 
                                color: palette?.main_text_color, 
                                overflowX: "hidden", 
                                overflowY: "auto",
                                textAlign: text_align,
                                ...style, 
                                alignItems : horizontal_align,
                                display: "flex",
                                flexDirection: "column"
                            }}
                            text={ text }
                            field_matrix={ ["data", "text"] }
                            is_edit={ is_edit }
                            className={`w-100 html-content  ${class_name || ""} `} 
                            onEditContent={ this.onEditContent }
                            onEditOpen={ this.onEditOpen }
                            editible = {this.state.editible}
                            revertColor={this.props.revertColor}
                        />
                    </LinkContainer>
                    <div className=" landing-html-includes">
                        { this.includes }
                    </div>
                </div>
            )
        }            
        </>
      }
}
export default HTML

