import React, { Component } from "react"
import { compose } from "recompose" 
import { withRouter, } from "react-router" 
import { __ } from "react-pe-utilities" 
import UserSearchForm from "./utilities/UserSearchForm"
import WPFestSettings from "./utilities/WPFestSettings"
import _fetch from "." 
import { Callout } from "@blueprintjs/core"

class FestMemberTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

  render() {
    console.log( WPFestSettings.fmru_evlbl_roles );
    if(WPFestSettings.fmru_evlbl_roles.filter(e => {
        return e == "Project_author" ||
        e == "Tutor" ||
        e == "Project_member"
      }).length === 0
    )
    {
      return <Callout className="p-5 text-center">
        {__("No project role evalble in Festival")}
      </Callout>
    }
    const team = []
    for (const i in this.state.owners) 
    {
      //if(WPFestSettings.fmru_evlbl_roles.filter(e => { return e == i }).length === 0 ) continue 
      team.push(<div key={i} className={`row data-list ${this.props.is_owner ? "" : "py-2"}`}>
        <div className="col-md-3 test-descr-title  my-auto">
          { __(i) }
        </div>
        <div className="col-md-9 test-descr-cont">
          {
						this.props.is_owner
						  ? 
              this.isOwner(this.state.owners[i], i)
						  : 
              this.isNotOwner(this.state.owners[i])
					}
        </div>
      </div>)
    }
    //const categories = this.state.categories || []
    return (
      <div className="row">
        <div className="col-12 mt-4">
          {team}
        </div>
      </div>
    )
  }

	isOwner = (owner, role) => (
    <UserSearchForm
      value={owner.display_name}
      selectedUser={owner}
      role={role}
      on={this.on}
    />
	)

	isNotOwner = (owner) => owner.display_name

	on = (user, role) => {
	  switch (role) {
	    case "tutor":
	    case "leader":
	    default:
	      role = `${role}_id`
	      break
	  }
	  // console.log(user, role);

	  if (!this.props.isNew) {
	    _fetch(
	      "get_fest_command",
	      { id: this.props.id, field: role, value: user.id, command: "update_project_field", },
	      WPFestSettings.serviceURL,
	      WPFestSettings.token,
	      "wpfa",
	    )
	      .then((_data) => {
          const data = _data.data 
	        console.log(data)
	      })
	  } 
    else if (this.props.on) 
    {
	    this.props.on({ id: this.props.id, field: role, value: user.id })
	  }
	}
}
export default compose(
  withRouter,
)(FestMemberTeam)
