import React, { Component } from "react"
import {
  Button 
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 

class LasyLoadType extends Component {
	state =
	{
	  value: this.props.value ? this.props.value : {type:""},
	}

	render() {
	  // console.log( this.props );
	  const switcher = this.getAllTypes().map((e, i) => (
		<option
			value={e._id}
			key={i}
		>
			{__(e.title)}
		</option>
	  ))
	  return (
		<div className="landing-lasy-load-type-edit-cont">
			<Button icon="caret-left" onClick={this.prev} />
			<select onChange={this.onChange} value={this.state.value.type} className="form-control input dark">
			{ switcher }
			</select>
			<Button icon="caret-right" onClick={this.next} />
		</div>
	  )
	}

	onChange = (evt) => {
	  console.log( evt.currentTarget.value, this.props )
	  this.setState({ value: {type: evt.currentTarget.value} })
	  this.props.on( this.props.field, {type: evt.currentTarget.value}  )
	}

	onClick = (evt) => {
	  this.setState({ value: {type: evt.currentTarget.value} })
	  this.props.on( this.props.field, {type: evt.currentTarget.value}  )
	}

	prev = (evt) => {
	  let ii = 0
	  const types = this.getAllTypes()
	  types.forEach((e, i) => {
	    if (e._id === this.state.value) {
	      ii = i
	    }
	  })
	  this.setState({ value: {type: types[(ii - 1 + types.length) % types.length]._id }})
	  this.props.on( this.props.field, {type: evt.currentTarget.value}  )
	}

	next = (evt) => {
	  let ii = 0
	  const types = this.getAllTypes()
	  types.forEach((e, i) => {
	    if (e._id === this.state.value) {
	      ii = i
	    }
	  })
	  this.setState({ value: {type: types[(ii + 1 + types.length) % types.length]._id }})
	  this.props.on( this.props.field, {type: evt.currentTarget.value}  )
	}

	getAllTypes() {
	  return [
	    {
	      _id: "none",
	      title: "--",
	    },
	    {
	      _id: "fadeIn",
	      title: "fadeIn",
	    },
	    {
	      _id: "fadeInUp",
	      title: "fadeInUp",
	    },
	    {
	      _id: "fadeInDown",
	      title: "fadeInDown",
	    },
	    {
	      _id: "fadeInLeft",
	      title: "fadeInLeft",
	    },
	    {
	      _id: "fadeInRight",
	      title: "fadeInRight",
	    },
	    {
	      _id: "fadeInUpBig",
	      title: "fadeInUpBig",
	    },
	    {
	      _id: "fadeInDownBig",
	      title: "fadeInDownBig",
	    },
	    {
	      _id: "fadeInLeftBig",
	      title: "fadeInLeftBig",
	    },
	    {
	      _id: "fadeInRightBig",
	      title: "fadeInRightBig",
	    },
	    {
	      _id: "fadeOut",
	      title: "fadeOut",
	    },
	    {
	      _id: "fadeOutUp",
	      title: "fadeOutUp",
	    },
	    {
	      _id: "fadeOutDown",
	      title: "fadeOutDown",
	    },
	    {
	      _id: "fadeOutLeft",
	      title: "fadeOutLeft",
	    },
	    {
	      _id: "fadeOutRight",
	      title: "fadeOutRight",
	    },
	    {
	      _id: "fadeOutUpBig",
	      title: "fadeOutUpBig",
	    },
	    {
	      _id: "fadeOutDownBig",
	      title: "fadeOutDownBig",
	    },
	    {
	      _id: "fadeOutLeftBig",
	      title: "fadeOutLeftBig",
	    },
	    {
	      _id: "fadeOutRightBig",
	      title: "fadeOutRightBig",
	    },
	    {
	      _id: "bounceIn",
	      title: "bounceIn",
	    },
	    {
	      _id: "slideInDown",
	      title: "slideInDown",
	    },
	    {
	      _id: "slideInLeft",
	      title: "slideInLeft",
	    },
	    {
	      _id: "slideInRight",
	      title: "slideInRight",
	    },
	    {
	      _id: "slideOutLeft",
	      title: "slideOutLeft",
	    },
	    {
	      _id: "slideOutRight",
	      title: "slideOutRight",
	    },
	    {
	      _id: "slideOutUp",
	      title: "slideOutUp",
	    },
	    {
	      _id: "bounceIn",
	      title: "bounceIn",
	    },
	    {
	      _id: "bounceInUp",
	      title: "bounceInUp",
	    },
	    {
	      _id: "bounceInDown",
	      title: "bounceInDown",
	    },
	    {
	      _id: "bounceInLeft",
	      title: "bounceInLeft",
	    },
	    {
	      _id: "bounceInRight",
	      title: "bounceInRight",
	    },
	    {
	      _id: "bounceOut",
	      title: "bounceOut",
	    },
	    {
	      _id: "bounceOutUp",
	      title: "bounceOutUp",
	    },
	    {
	      _id: "bounceOutDown",
	      title: "bounceOutDown",
	    },
	    {
	      _id: "bounceOutLeft",
	      title: "bounceOutLeft",
	    },
	    {
	      _id: "bounceOutRight",
	      title: "bounceOutRight",
	    },
	    {
	      _id: "rotateIn",
	      title: "rotateIn",
	    },
	    {
	      _id: "rotateInUpLeft",
	      title: "rotateInUpLeft",
	    },
	    {
	      _id: "rotateInDownLeft",
	      title: "rotateInDownLeft",
	    },
	    {
	      _id: "rotateInUpRight",
	      title: "rotateInUpRight",
	    },
	    {
	      _id: "rotateInDownRight",
	      title: "rotateInDownRight",
	    },
	    {
	      _id: "rotateOut",
	      title: "rotateOut",
	    },
	    {
	      _id: "rotateOutUpLeft",
	      title: "rotateOutUpLeft",
	    },
	    {
	      _id: "rotateOutDownLeft",
	      title: "rotateOutDownLeft",
	    },
	    {
	      _id: "rotateOutUpRight",
	      title: "rotateOutUpRight",
	    },
	    {
	      _id: "rotateOutDownRight",
	      title: "rotateOutDownRight",
	    },
	    {
	      _id: "rotateOutDownRight",
	      title: "rotateOutDownRight",
	    },
	    {
	      _id: "rollIn",
	      title: "rollIn",
	    },
	    {
	      _id: "rollOut",
	      title: "rollOut",
	    },
	    {
	      _id: "lightSpeedIn",
	      title: "lightSpeedIn",
	    },
	    {
	      _id: "lightSpeedOut",
	      title: "lightSpeedOut",
	    },
	    {
	      _id: "hinge",
	      title: "hinge",
	    },
	    {
	      _id: "flash",
	      title: "flash",
	    },
	    {
	      _id: "shake",
	      title: "shake",
	    },
	    {
	      _id: "bounce",
	      title: "bounce",
	    },
	    {
	      _id: "tada",
	      title: "tada",
	    },
	    {
	      _id: "swing",
	      title: "swing",
	    },
	    {
	      _id: "wobble",
	      title: "wobble",
	    },
	    {
	      _id: "pulse",
	      title: "pulse",
	    },
	    {
	      _id: "flip",
	      title: "flip",
	    },
	    {
	      _id: "flipInX",
	      title: "flipInX",
	    },
	    {
	      _id: "flipOutX",
	      title: "flipOutX",
	    },
	    {
	      _id: "flipInY",
	      title: "flipInY",
	    },
	  ]
	}
}
export default LasyLoadType
