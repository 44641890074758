import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import $ from "jquery"

const History = (props) => { 
  const keyPress = e => 
  {
    // use e.which
    if(e.ctrlKey && e.key === "\x1A" )
    {
      props.undo()
    }
  }
  useEffect(() => {
    $(document).on("keypress", keyPress)
    return () => {
      $(document).off("keypress", keyPress)
    }
  },[])
  return <div></div>
}

History.propTypes = {}

export default History