import React from "react"
import { Intent, Dialog, ButtonGroup, Button } from "@blueprintjs/core"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"
import { initArea } from  "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import { AppToaster } from 'react-pe-useful'
import modules from "react-pe-layouts"
import SinglePluginForm from "./adminMenu/SinglePluginForm"
import PluginSearch from "./adminMenu/PluginSearch"
import { help_url } from "react-pe-layouts"

class AdminPluginView extends BasicState {
    basic_state_data() {
        return {
            current: "installed",
            isLeftClosed: window.innerWidth < 760
        }
    }
    onCurrent = current => {
        this.setState({ current })
    }
    render() {
        const leftClass = this.state.isLeftClosed
            ? "tutor-left-aside-2 menu-aside closed"
            : "tutor-left-aside-2 menu-aside"
        const mainClass = this.state.isLeftClosed
            ? "tutor-main-2 pr-0 opened"
            : "tutor-main-2 pr-0"
        const help = this.props.help_url
			?
			<span>
				<Button 
					icon="help" 
					minimal 
					onClick={ () => window.open(help_url() + this.props.help_url, '_blank') } 
				/>
			</span> 
			:
			null
        return (
            <div className="layout-state p-0">
                <div className="tutor-row menu-row">
                    <div className={leftClass}>
                        <div className="layout-state-head menu-header-22">
                            <LayoutIcon
                                isSVG
                                src={this.state.route.icon}
                                className="layout-state-logo "
                            />
                            <div className="layout-state-title">
                                {help}
                                <span dangerouslySetInnerHTML={{ __html: __(this.state.route.title) }} /> 
                            </div>
                        </div>
                        <div className="small mx-3 mb-3 " dangerouslySetInnerHTML={{ __html: this.props.description }} />
                        <div className=" m-5 tutor-menu">
                            <ButtonGroup
                                vertical={true}
                                intent={Intent.SECONDARY}
                                minimal={false}
                                large={true}
                                fill={true}
                            >
                                <Button
                                    active={this.state.current === "installed"}
                                    onClick={evt => this.onCurrent("installed")}
                                >
                                    {__("Installed Modules")}
                                </Button>
                                <Button
                                    active={this.state.current === "npm"}
                                    onClick={evt => this.onCurrent("npm")}
                                >
                                    {__("Search in NPM")}
                                </Button>
                                <Button
                                    active={this.state.current === "gitlab"}
                                    onClick={evt => this.onCurrent("gitlab")}
                                >
                                    {__("Search in GitLab")}
                                </Button>
                            </ButtonGroup>
                        </div>
                        {
                            initArea("admin-menu-left-aside",
                                { ...this.props })
                        }
                    </div>
                    <div className={mainClass}>
                        <div
                            className="clapan-left"
                            onClick={() => this.setState({ isLeftClosed: !this.state.isLeftClosed })}
                        >
                            <div className={`fas fa-caret-${!this.state.isLeftClosed ? "left" : "right"}`} />
                        </div>
                        <div className="menu-header-22 flex-centered">

                        </div>
                        <div className=" p-4 ">
                            {this.currentNow()}
                        </div>
                    </div>
                    <div className="tutor-right-aside-2">
                        {
                            initArea(
                                "admin-menu-right-aside",
                                { ...this.props },
                            )
                        }
                    </div>
                </div>

                <Dialog
                    isOpen={this.state.isCreateOpen}
                    onClose={this.onNewMenu}
                    title={__("Set child's title")}
                >
                    <div className="p-3">
                        <div>
                            Boo-boo-boo, boo-boo!
                        </div>
                    </div>
                </Dialog>

            </div>
        )
    }
    currentNow = () => {
        switch (this.state.current) {
            case "npm":
                return <PluginSearch src="npm" />
            case "gitlab":
                return <div>
                    <PluginSearch src="gitlab" />
                </div>
            case "installed":
            default:
                const insalledPlugins = Object.keys(modules()).map((plugin, i) => {
                    return <SinglePluginForm
                        {...modules()[plugin]}
                        onOpen={this.onOpen}
                        isOpen={this.state.isOpen == i}
                        key={i}
                        i={i}
                    />
                })
                return <>
                    {insalledPlugins}
                </>
        }
    }
    onOpen = i => {
        this.setState({ isOpen: this.state.isOpen == i ? -1 : i })
    }
    onNewOpen = () => {
        this.setState({ isCreateOpen: !this.state.isCreateOpen })
    }

    onCreateChild = () => {
        if (!this.state.childName) {
            AppToaster.show({
                intent: Intent.DANGER,
                icon: "tick",
                message: "Insert not empty title",
            })
            return
        }
        const menuDatas = { ...this.state.menuDatas }
        menuDatas[this.state.childName] = []
        this.setState({
            isCreateOpen: false,
            menuDatas,
            current: this.state.childName,
        })
    }

}

export default AdminPluginView
