import { Button, ButtonGroup, Classes, Collapse, Dialog, Icon, Intent, Position, Tab, Tabs } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"
import Layouts, { roles } from "react-pe-layouts" 
import { LayoutIcon } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities/dist"
import { useHistory } from "react-router"
import EditPageDialog from "./EditPageDialog"

const CardRoute = props =>
{ 
    const [data, setData] = useState(props.data)
    const [menu, setMenu] = useState(props.menu)
    const [jsonData, setJsonData] = useState(JSON.stringify(props.data))
    const [module, setModule] = useState(props.module) 
    const [isMoveOpen, setMoveOpen] = useState(false)  
    const [isEditOpen, setEditOpen] = useState(false)  
    const [isRemoveRouteOpen, setRemoveRouteOpen] = useState(false)  
    const history = useHistory()
    const changeHide = () =>
    {
        const _data = {...data}
        _data.is_hidden = !_data.is_hidden
        setData( _data )
        props.onChangeRoute( _data, jsonData )
    }
    const onGotoTo = () => 
    {
        history.push( "/" + data.route )
    }
    const onMoveToFolderOpen = () =>
    {
        setMoveOpen( !isMoveOpen )
    }
    const moveToFolder = ( evt, folderId ) => {
        props.moveToFolder( props.data, folderId )
    }
    const onEditOpen = () =>
    {
        setEditOpen( !isEditOpen )
    }
    const onRemoveRouteOpen = () =>
    {
        setRemoveRouteOpen( !isRemoveRouteOpen )
    }
    const onRemoveRoute =() =>
    {
        setRemoveRouteOpen( false )
        props.onRemoveRoute( data )
    }
    const onField = (value, field) =>
    {
        console.log( value, field )
        let _data = { ...data }
        _data[ field ] = value
        if(field === "component")
        {
            const _module = Layouts().views[ value ]?.module 
            _data.module = _module
            setModule(Layouts().modules[_module])
        }
        setData( _data )
        console.log( _data )
        
    }
    const onSaveRote = () =>
    {
        props.onChangeRoute( data, jsonData )
        setEditOpen( false )
    }
    const onPageTypeField = value =>
    { 
        let _data = {...data}
        delete _data.component
        delete _data.html
        _data[value] = ""
        setData( _data )
    }
    return <div className="col-md-12 col-lg-4 col-sm-12 d-flex flex-column" >
        {
            data.is_hidden
                ? 
                <div className="position-absolute py-4 pl-4 pr-5 text-center lead font-weight-bold text-uppercase z-index-100">
                    <div className="bg-white2 d-flex justify-content-center align-items-center p-5 " >
                        {__("This page has been excluded from the sitemap and is not displayed to site visitors")}
                    </div>  
                    <div>
                        <Button onClick={changeHide} large minimal>{__("Cancel")}</Button>
                    </div>   
                </div>
                :
                null
        }
        <div className="card" style={{height:480, opacity: data.is_hidden || props.is_hidden ? .20 : 1}}>
            <div className="card-route-hover">
                <div 
                    className="card-image"
                    style={{
                        backgroundImage:`url(${ data.thumbnail })`
                    }}
                > 
                    <div 
                        style={{ backgroundColor: module?.color, }}
                        className="card-image-muar"
                    >

                    </div>
                    <LayoutIcon
                        isSVG
                        src={ data.icon }
                        className="card-image-icon"
                    />
                </div>
                <div className="card-body">
                    <h4 className="card-title">
                        {data.title}
                    </h4>
                </div>
                <ul className="list-group list-group-flush ">
                    <li className="list-group-item mighty">
                        <div className=" py-1 px-3 w-100 d-flex justify-content-center "> 
                            <div 
                                className="btn btn-outline-secondary rounded-pill card-btn btn-1" 
                                style={{height:44}}
                                onClick={onGotoTo}
                            >
                                { __( "Go to" ) }
                            </div> 
                        </div>
                        {
                             Layouts().app.init_method === "local"
                                ?
                                null
                                :
                                <>
                                    <div className=" py-1 px-3 w-100 d-flex justify-content-center "> 
                                        <div 
                                            className="btn btn-outline-secondary rounded-pill card-btn btn-2" 
                                            style={{height:44}}
                                            onClick={onEditOpen}
                                        >
                                            { __( "Edit"  ) }
                                        </div> 
                                    </div>
                            
                                    <div className=" py-1 px-3 w-100 d-flex justify-content-center ">                             
                                        <div className=" d-flex card-btn">                             
                                            <div className="card-pill-btn mr-2 btn-2">
                                                <Icon 
                                                    icon={ data.is_hidden ? "eye-off" : "eye-open" } 
                                                    className=" hint hint--top" 
                                                    data-hint={__("Change visible")}
                                                    onClick={changeHide} 
                                                /> 
                                            </div>                           
                                            <div className="card-pill-btn mr-2 btn-3">
                                                <Icon 
                                                    icon={`folder-shared`}  
                                                    className=" hint hint--top" 
                                                    data-hint={__("Move to folder")}
                                                    onClick={ onMoveToFolderOpen }
                                                /> 
                                            </div>                         
                                            <div className="card-pill-btn mr-2 btn-4">
                                                <Icon 
                                                    icon={`cross`}  
                                                    className=" hint hint--top" 
                                                    data-hint={__("Remove")} 
                                                    onClick={onRemoveRouteOpen}
                                                /> 
                                            </div> 
                                        </div>
                                    </div>
                                </>
                        }
                    </li>
                    <li className="list-group-item lowy">
                        <div className="p-3">
                            {__("route")}: <b>/{ data.route }</b>
                        </div>
                    </li>
                    <li className="list-group-item lowy">
                        <div className="p-3">
                            <b>{ Layouts().modules[data.module]?.title }</b>
                        </div>
                    </li>
                    <li className="list-group-item d-md-none d-block ">
                        <div className="p-0">
                            <ButtonGroup minimal className="d-flex">
                                <Button 
                                    icon="arrow-left" 
                                    className="justify-content-center px-4" 
                                    onClick={onGotoTo}
                                >
                                    {__("Go to")}
                                </Button>
                                <Button 
                                    icon="cog" 
                                    className=" ml-auto hint hint--top  justify-content-center px-4"
                                    data-hint={__("Setting")}
                                    onClick={onEditOpen}
                                />
                                <Button 
                                    icon={ data.is_hidden ? "eye-off" : "eye-open" }
                                    className=" hint hint--top  justify-content-center px-3"
                                    data-hint={__("Change visible")}
                                    onClick={changeHide}
                                />
                                <Button 
                                    icon="pivot" 
                                    className=" hint hint--top  justify-content-center px-3 hidden "
                                    data-hint={__("Change route")}
                                />
                                <Button 
                                    icon="folder-shared" 
                                    className=" hint hint--top  justify-content-center px-3"
                                    data-hint={__("Move to folder")}
                                    onClick={ onMoveToFolderOpen }
                                />                            
                                <Button 
                                    icon="cross" 
                                    className=" hint hint--top d-flex justify-content-center px-3"
                                    data-hint={__("Remove")}
                                    onClick={onRemoveRouteOpen}
                                />
                            </ButtonGroup>
                        </div>
                    </li> 
                </ul>
            </div>
            <ul className="list-group list-group-flush"> 
                
            </ul>
        </div>
        <Dialog
            isOpen={isMoveOpen}
            className="little"
            title={__("Move page to:")}
            onClose={onMoveToFolderOpen}
        >
            <div className="p-0">
            {
                [{ title:__("...to root"), id: null }, ...Layouts().folders?.folders].map(folder => {
                    return <Button 
                        fill 
                        minimal
                        large 
                        key={folder.id}
                        onClick={(evt)=> moveToFolder(evt, folder.id) }
                    >
                        { folder.title}
                    </Button>
                })
            }
            </div>
        </Dialog>
        <Dialog
            isOpen={isRemoveRouteOpen}
            className="little"
            title={__("Remove page?")}
            onClose={onRemoveRouteOpen}
        >
            <div className="p-5">
                <div className="pb-4">
                    {__("Realy remove page?")}
                </div>
                <ButtonGroup >
                    <Button intent={Intent.SUCCESS} onClick={() => onRemoveRoute() }>
                        {__("Yes")}
                    </Button>
                    <Button intent={Intent.DANGER} onClick={onRemoveRouteOpen}>
                        {__("Yes")}
                    </Button>
                </ButtonGroup>
            </div>
        </Dialog>
        <EditPageDialog
             data={data} 
             menu={ menu }
             onEditOpen={onEditOpen}
             onField={ ( value, field ) => onField( value, field )}
             onPageTypeField={onPageTypeField}
             onSaveRote={onSaveRote}
             isEditOpen={isEditOpen}
             setEditOpen={setEditOpen}
             jsonData={jsonData}
             dialogTitle="Page Settings"
             saveButtonTitle="Save"
        />
    </div>
}
export default CardRoute