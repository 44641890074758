import React, {Component, Fragment} from "react";
import GanreRangeButton from "./GanreRangeButton";
import in_array from "in_array";

export default class GanreRange extends Component
{
	constructor(props)
	{
		super(props);
		this.state={
			checked: props.checked
		}
	}
	render()
	{
		const btns = this.props.fmru_ganres.map((elem, i) =>
		{
			return <GanreRangeButton
				key = {i}
				value = {elem.id}
				id = {elem.id}
				name = {this.props.name}
				onChange = {this.onChange}
				label = {elem.post_title}
				icon={elem.icon}
				color={elem.color}
				checked = { in_array( elem.id, this.props.checked ) }
			/>
		});
		return <div> {btns} </div>;
	}
	onChange = evt =>
	{
		let checked = this.props.checked.slice(0);
		const id = evt.currentTarget.value;
		if( checked.filter(elem => elem == id).length )
		{
			checked = checked.filter(elem => elem != id);
		}
		else
		{
			checked.push(id);
		}
		//this.setState({ checked : checked });
		this.props.onChange(checked);
	}
}