import React from "react" 
import $ from "jquery"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import SectionContent from "./SectionContent"
import { __ } from "react-pe-utilities" 
import Section, { getDefault } from "./Section"

class ZoomPanPinch extends SectionContent {
  getState() {
    this.ref = React.createRef()
    return { }
  }

  is() {
    const { section } = this.state.data
    return section
  }

  renderContent() {
    const { type } = this.props
    const {
      class_name, style, section, maxScale,
    } = this.props.data
    return (
      <div
        className="landing-zoom-pan-pinch-cont "
        style={{ ...style, width: this.state.width }}
        ref={this.ref}
      >
        <div className="landing-zoom-pan-pinch" style={{ width: this.state.width, height: this.state.height }}>
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
            options={{ maxScale }}
          >
            {({
              zoomIn, zoomOut, resetTransform, ...rest
            }) => (
              <>
                <div className="tools">
                  <button onClick={zoomIn}>
                <i className="fas fa-search-plus fa-2x" />
              </button>
                  <button onClick={zoomOut}>
                <i className="fas fa-search-minus fa-2x" />
              </button>
                  <button onClick={resetTransform}>
                <i className="fas fa-times fa-2x" />
              </button>
                  <button onClick={this.up}>
                <i className="fas fa-caret-up fa-2x" />
              </button>
                  <button onClick={this.dwn}>
                <i className="fas fa-caret-down fa-2x" />
              </button>
                </div>
                <TransformComponent>
                  {
						section.map((e, i) => {
							const estyle = { ...this.getStyle(e.style), width: "100%" }
							return (
							<div style={estyle} className={e.className} key={i}>
								<Section
								{...e}
								style={{ height: "100%", ...this.getStyle(e.style) }}
								i={i}
								user={this.props.user}
								is_edit={this.state.is_edit}
								level={this.props.level + 1}
								onEdit={this.onEdit}
								onUp={this.onUp}
								onDn={this.onDn}
								onAdd={this.onAdd}
								onRnv={this.onRnv}
								/>
							</div>
							)
						})
					}
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      </div>
    )
  }

	up = (evt) => {
	  $("body,html").animate({
	    scrollTop: this.ref.current.getBoundingClientRect().top + window.scrollY - 60,
	  }, 400)
	}

	dwn = (evt) => {
	  // const h = this.ref.current.getBoundingClientRect().height;
	  $("body,html").animate({
	    scrollTop: this.ref.current.getBoundingClientRect().bottom + window.scrollY - 60,
	  }, 400)
	}

	onEdit = (data, id) => {
	  console.log("onEdit", id, data, this.state)
	  const sections = [...this.state.data.sections]
	  const secs = []
	  sections.forEach((e) => {
	    if (e.id == data.id) {
	      secs.push(data)
	    } else {
	      secs.push(e)
	    }
	  })
	  this.setState({ data: { ...this.state.data, sections: secs } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections: secs } },
	    this.props.id,
	  )
	}

	onUp = (data) => {
	  console.log("onUp", data, this.state)
	  const sections = [...this.state.data.sections]
	  const sec = { ...sections[data] }
	  sections.splice(data, 1)
	  sections.splice(data - 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onDn = (data) => {
	  console.log("onDn", data, this.state); const sections = [...this.state.data.sections]
	  const sec = { ...sections[data] }
	  sections.splice(data, 1)
	  sections.splice(data + 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onAdd = (data) => {
	  console.log("onAdd", data, this.state)
	  const sections = [...this.state.data.sections]
	  const sec = getDefault()
	  sections.splice(data + 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onRnv = (data) => {
	  console.log("onRnv", data, this.state.data.sections)
	  const sections = [...this.state.data.sections]
	  sections.splice(data, 1)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onHide = (id, is_hide) => {
	  console.log("HIDE", id, is_hide)
	}

	onRemoveFloat = (float_id) => {

	}

	onUpdateFloat = (data, float_id, section_id) => {

	}
}
export default ZoomPanPinch
