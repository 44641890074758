import React, { Component } from "react" 
import matrix from "./data/matrix"
import EditLabel from "./EditLabel"
import { getStyle } from "./Section"

class Description extends Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.is_edit !== this.state.is_edit) {
      this.setState({ is_edit: nextProps.is_edit })
    }
    const state = {}
    for (const i in matrix().descriptions) {
      if (nextProps[i] !== this.state[i]) {
        state[i] = nextProps[i]
      }
    }

    if (Object.keys(state).length > 0) {
      // console.log( Object.keys(state).length, state );
      this.setState({ ...state })
    }
  }
  onEdit = data => {
    console.log(this.props);
    if (this.props.onEdit) {
      this.props.onEdit({ descriptions: data })
    }
  }
  render() {
    const { text, text_src, class_name, style } = this.state
    const styleObj = getStyle(style)
    return text || text_src
      ? (
        <div className={`landing-description landing-section-core  ${class_name}`} style={styleObj}>
          <span>
            <span
              dangerouslySetInnerHTML={{ __html: text }}
              style={{
                backgroundColor: style.plateColor,
                padding: "3px 10px",
              }}
            />
            <EditLabel
              {...this.state}
              isBtn={true}
              st={{
                marginRight: -20
              }}
              children={null}
              source="descriptions"
              onEdit={this.onEdit}
              data={this.state}
            />
          </span>
        </div>
      )
      : null
  }
}
export default Description
