import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import "./index.css" 
import FoldThecube from './superLoader/FoldThecube'

const SuperLoading = props => 
{
    switch(props.type)
    {
        case "foldTheCube":
        default:
            return <FoldThecube />
    }
    
}

SuperLoading.propTypes = {}

export default SuperLoading