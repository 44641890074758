import React, { Component, } from "react"
// import $ from "jquery"
import { Link } from "react-router-dom" 
//import _fetch from "."
import { __ } from "react-pe-utilities"
import GanreIcon from "./GanreIcon"
import WPFestSettings from "./utilities/WPFestSettings"

class FestMemberCardBtn extends Component 
{
  componentDidMount()  
  {
    //$(".share").socialLikes()
  }

  componentDidUpdate(nextProps) 
  {
    //$(".share").socialLikes()
  }

  render() 
  {
    const { id, o, e, img, title, content, ganres, r, } = this.props

    const _ganres = ganres.map((ganre, index) => <GanreIcon ganre={ganre} key={ganre.id} />)
    const __ganres = ganres.map((ganre, index) => `<strong style='color:${ganre.color}'>${ganre.name}</strong>`)
    const _ganre = ganres[0]
    const gcolor = _ganre ? _ganre.color : "#111111"

    const url = [1, 2].filter((e) => e === WPFestSettings.status).length > 0
      ? 
      `${this.props.route}/${this.props.festid}/member/${id}`
      : 
      `${this.props.route}/${this.props.festid}/member/${id}`

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div className="card h-100">
          <div
            className="card-img"
            style={{
              backgroundImage: `url(${img})`,
              borderColor: gcolor,
            }}
            data-mid={id}
            data-fmru_type="fmru_player"
            data-args={id}
          >
            <div className="card-id">
              {o}
            </div>
          </div>
          <div className="card-icons">
            {_ganres}
          </div>
          <div
            className="card-header"
            title={title}
            style={{
              height: "61px",
              overflow: "hidden",
              padding: "0 1.25em",
              position: "relative",
              display: "table",
            }}
          >
            <h5 className="card-title" style={{ display: "table-cell", verticalAlign: "middle" }}>
              {title}
            </h5>
          </div>
          <ul className="list-group list-group-flush flex-grow-100">
            <li
              className="list-group-item"
              style={{
                height: "42px", overflow: "hidden", padding: "0 1.25em", position: "relative", display: "table",
              }}
            >
              <span
                className="data w-100"
                style={{
                  padding: "0 20px",
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <span className="hideColor">.</span>
                <span dangerouslySetInnerHTML={{ __html: __ganres.join(", ") }} />
              </span>
            </li>
            <li className="list-group-item" style={{ height: 64, overflow: "hidden", texOverflow: "ellipsis" }}>
              <span className="discr">
                {content}
              </span>
            </li>
            <li className="list-group-item">
              <span className="discr">
                {__("Raiting:")}
              </span>
              <span className="data ">
                {r}
              </span>
            </li>
            <li className="list-group-item">
              <span className="discr">
                {__("Expert count:")}
              </span>
              <span className="data selected">
                {e === 0 ? "-" : e}
              </span>
            </li>
            <li className="list-group-item">
              <div
                className="social-likes share"
                data-url={url}
                data-title={title}
                style={{ padding: "11px 20px" }}
              >
                <div className="facebook" title="Поделиться ссылкой на Фейсбуке" />
                <div className="twitter" data-via="@Metaversitet" title="Поделиться ссылкой в Твиттере" />
                <div className="mailru" title="Поделиться ссылкой в Моём мире" />
                <div className="vkontakte" title="Поделиться ссылкой во Вконтакте" />
                <div className="odnoklassniki" title="Поделиться ссылкой в Одноклассниках" />
              </div>
            </li>
          </ul>
          <div className="card-body align-self-center">
            <Link className="fmRU_button " to={url}>
              <i className="fa fa-caret-right" />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
export default FestMemberCardBtn
