import React from "react"
import { Button } from "@blueprintjs/core"
import { useState } from "react"
import { __ } from "react-pe-utilities"
import { getAllRoutes } from "react-pe-layouts"
import { sprintf } from "react-pe-utilities"

export default props => {
    const isSystem = props.type == "DataView" || props.type == "DataTableView"
    const useTime = sprintf(
        __("Use %s times"),
        getAllRoutes().filter(e => e.component == props.type).length
    )
    const useRoute = !isSystem
        ?
        <Button
            className="m-1"
            minimal={true}
        >
            {useTime}
        </Button>
        :
        null

    return <div className={"row py-3 " + (props.i != 0 ? " border-top border-secondary " : "")}>
        <div className=" col-12 ">
            <div className={"font-weight-bold "}>
                {__(props.title)}
            </div>
        </div>
        <div className=" col-12 ">
            <div className="">
                {__(props.description)}
            </div>
            <div className="hidden__">
                {useRoute}
            </div>
        </div>
    </div>
}