import React from 'react'
import PropTypes from 'prop-types'
import { LayoutIcon } from 'react-pe-useful/dist'
import { Button, Icon } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import { useHistory } from 'react-router'
import { getEditoCabinetRoute } from 'modules/pe-admin-module/views/EditorCabinet'
import Layouts, { getAllRoutes, getByRoute } from 'react-pe-layouts'

const BooPanel = props => {
    const history = useHistory()
    const route = getAllRoutes().filter( route => route.route === props.route )[0] 
    const getBreadcrambFolder = () =>
    {
        let folder = route?.folder && route?.folder > 0
            ?
            <>
                <Icon icon="chevron-right" className='opacity_5'/> 
                <span className='max_width_180'>
                    {Layouts().folders.folders[ route?.folder - 1 ].title}
                </span>
            </>
            :
            ""
        let title = route?.title || ""
        return <>
            {folder}
            <Icon icon="chevron-right" className='opacity_5'/> 
            <span className='max_width_180'>
                {title}
            </span>
        </>
    }
    const onCabinet = () =>
    {
        let folder = route?.folder && route?.folder > 0
            ? 
            `/folder/${route?.folder}`
            :
            ""
        history.push( "/" + getEditoCabinetRoute()?.route + folder )
    }
    return <div className=' mr-auto'>
        <div className="d-flex align-items-center pr-1">
            <Button
                className="hint hint--bottom p-0"
                data-hint={__("Editor cabinet")}
                minimal
                onClick={ onCabinet }
            >  
                <img
                    src="/assets/img/pe/PE_logo_large.svg"
                    className=" "
                    alt={""}
                    style={{
                        height: 45,
                        width:45,
                        top: 0
                    }}
                />
            </Button>
            { getBreadcrambFolder() }
        </div>
    </div>
}

BooPanel.propTypes = {}

export default BooPanel