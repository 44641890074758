import React from "react"
import { Link } from "react-router-dom"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"

class RobokassaSuccessState extends BasicState {
  myState = () => (
    <div className="row text-center">
      <div className="col-12 my-4">Оплата прошла успешно!</div>
      <div className="col-12 my-4">
        <Link className="btn btn-danger btn-sm" to="/">
          {__("Return to main page")}
        </Link>
      </div>
    </div>
  ); 
}
export default RobokassaSuccessState
