import Layouts from "react-pe-layouts";
import matrixData from "./matrixData.json"

export default function matrix()
{
    const modules = Layouts().modules;
    let extendedMatrixes = {}
    Object.keys(modules).forEach(plugin => {
        Object.keys(modules[plugin].widgets).forEach(widget => {
            if( modules[plugin].widgets[widget].extended_landing )
            {
                const extended = require.context( `modules`, true, /\.js$/ )
                extended.keys()
                    .forEach(key => {
                        let key1 = key.replace("./", "").split(".").slice(0, -1).join(".").split("/")
                        key1 = key1[key1.length - 1]
                        if( key1 === "extended_landing")
                        {
                            //console.log( extended(key) )
                            extendedMatrixes = { ...extendedMatrixes, ...extended(key).default() }
                            //
                        } 
                    })
            }
        });
    });
    return {
        ...matrixData, 
        ...extendedMatrixes
    }
}