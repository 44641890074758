import React, { Component } from "react"
import ReactDOM from "react-dom"
import $ from "jquery"
// import { onOpenPortal } from "../LandingView"
import { Waypoint } from "react-waypoint"
import { Button, Intent, Popover, Position, Dialog, Drawer, Tooltip, Callout, } from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 
import DataContext from "./DataContext"
import PalettePresets, { getFontNameByID, getDefaultMatrix } from "./data/PalettePresets"
import Dilimiter from "./Dilimiter"
import LandingSectionParams from "./edit/LandingSectionParams"
import SectionBackground from "./SectionBackground"
import FloatDrawer from "./edit/FloatDrawer"
import FlaotDettingDialog from "./edit/FlaotDettingDialog"
import TypeDialog from "./edit/TypeDialog"
import Title from "./Title"
import Description from "./Description"
import Floats from "./Floats"
import { components } from "./data/components"
import SectionTools from "./edit/SectionTools"
import SectionGrid from "./SectionGrid"
import {Issue} from "react-pe-useful" 
import matrix from "./data/matrix"

import __sm from "../../assets/img/landing/android.svg"
import __xs from "../../assets/img/landing/android1.svg" 
import { getColor, getRevertColor } from "./data/getColor"
import {getScreens} from "./Section"

class PureSection extends Component {
	is_visible = true;
	sectionWidth = 0;
	dopEditTop = 0;

	constructor(props) {
		super(props) 
		this.rp = React.createRef()
		this.ap = React.createRef()
		this.state = {
			composition:{},
			title:{},
			descriptions:{},
			...this.props, 
			navbarTabId: "prm",
			isDialogOpen: false,
			isRemPopover: false,
			isAddPopover: false,
			is_change_type_enbl: false,
			current_type: this.props.type,
			myX: 0,
			isDummy: typeof props.isDummy !== "undefined" ? props.isDummy : true
		}
		window.pe_landing.section.push(this)
	}

	componentDidMount() {
		document.body.addEventListener("click", this.onMouseClickHandler)
		document.body.addEventListener("mouseout", this.onMouseLeaveHandler)
		switch (this.state.type) {
			case "contact_form":
			default:
				if (this.state.data.as_button && this.props.onFixedAdd) 
				{
					this.props.onFixedAdd(
						<Tooltip
							content={this.state.title.text ? this.state.title.text : __("Contact us")}
							id={`#cgicon${this.state.id}`}
						>
							<div className="landing-cfIcon" onClick={this.toggleOpen} />
						</Tooltip>,
					)
				}
				break
		}

		const {
			visible_lg, visible_sm, visible_ms, visible_xs,
		} = this.state
		const w = document.body.clientWidth
		const visible_all = !visible_lg && !visible_sm && !visible_ms && !visible_xs
		if (w > 1200) {
			this.is_visible = visible_lg || visible_all
		} else if (w > 740) {
			this.is_visible = visible_sm || visible_all
		} else if (w > 540) {
			this.is_visible = visible_ms || visible_all
		} else {
			this.is_visible = visible_xs || visible_all
		}

		if ($(`#handler_${this.state.id}`).length == 0) return
		this.setState({ myX: $(`#titled_${this.state.id}`).offset().left - 100 })
		if(this.props.level == 0)
		{
			setTimeout(() =>
			{
				// this.setState({ isDummy : true })
				
			}, 1000)
		}
		
	}

	componentWillUnmount() {
		document.body.removeEventListener("click", this.onMouseClickHandler)
		document.body.removeEventListener("mouseout", this.onMouseLeaveHandler)
	}

	onMouseClickHandler = (e) => {
		const domNode = ReactDOM.findDOMNode(this.rp.current)
		if (!domNode || !domNode.contains(e.target)) {
			if (this.state.isRemPopover || this.state.isAddPopover || this.state.isAddFloat) {
				// console.log( "LeaveHandler" )
				this.setState({
					isRemPopover: false,
					isAddPopover: false,
					isAddFloat: false 
				})
			}
		}
	}
	onMouseLeaveHandler = (e) => {
		const domNode = ReactDOM.findDOMNode(this.rp.current)
		if (!domNode || !domNode.contains(e.target)) {
			if (this.state.isRemPopover || this.state.isAddPopover || this.state.isAddFloat) {
				console.log( "LeaveHandler" )
				this.setState({ 
					showUndefmenu: false,
				})
			}
		}
	}

	componentWillUpdate(nextProps) {
		if (nextProps.is_edit != this.state.is_edit) {
			this.setState({ is_edit: nextProps.is_edit })
		}
		if (nextProps.i != this.state.i) {
			this.setState({ i: nextProps.i })
		}
		if (nextProps.id != this.state.id && nextProps.id) {
			this.setState({ id: nextProps.id, section_id: nextProps.section_id })
		}
		if (nextProps.current_template_id != this.state.current_template_id) {
			this.setState({ current_template_id: nextProps.current_template_id })
		}
		if (nextProps.composition != this.state.composition) {
			this.setState({ composition: nextProps.composition })
		}
		if (nextProps.background != this.state.background) {
			//this.setState({ background: nextProps.background })
		}
		if (nextProps.style != this.state.style) {
			this.setState({ style: nextProps.style })
		}
		if (nextProps.class_name != this.state.class_name) {
			this.setState({ class_name: nextProps.class_name })
		}
		if (nextProps.title != this.state.title) {
			this.setState({ title: nextProps.title })
		}
		if (nextProps.descriptions != this.state.descriptions) {
			this.setState({ descriptions: nextProps.descriptions })
		}
		if (nextProps.menu != this.state.menu) {
			this.setState({ menu: nextProps.menu })
		}
		if (nextProps.type != this.state.type) {
			this.setState({ type: nextProps.type })
		}
		if (nextProps.data != this.state.data) {
			this.setState({ data: nextProps.data })
		}
		if (nextProps.floats != this.state.floats) {
			this.setState({ floats: nextProps.floats })
		}
		if (nextProps.section_width != this.state.section_width) {
			this.setState({ section_width: nextProps.section_width })
		}
	}

	getID = () => (this.state.menu ? this.state.menu.id : this.state.i)

	getPalette = () => {
		
		let palette = DataContext.data && DataContext.data.landing && DataContext.data.landing.palette
			?
			DataContext.data?.landing?.palette?.filter((e) => e.id == this.state.current_template_id)[0]
			:
			null
		palette = { ...getDefaultMatrix(), ...palette } || getDefaultMatrix()
		// console.log( DataContext.data.landing.palette, this.state.current_template_id, palette )
		// console.log(palette, this.state.id)
		return palette;
	}
	render(){
		//return this.state.type !== "archor_menu" ? this.renderDummy() : this.renderSection()
		return this.state.isDummy && this.props.level == 0 && this.props.type !== "archor_menu"
			?
			this.renderDummy()
			:
			this.renderSection()
	}
	renderDummy()
	{
		return <div className="landing-section-dummy">
			<Waypoint
				scrollableAncestor={window}
				bottomOffset={20}
				onEnter={evt => {
					if(!this.state.isDummy) return <Callout>No dummy</Callout>
					//console.log(evt)
					try
					{
						this.setState({isDummy: false, animatedStart : true})
					}
					catch(error)
					{
						console.error(error)
					}
				}} 
			> 
				<div
					data-id={this.state.id}
					id={`${this.getID()}`}
					className={`landing-section ${this.state.class_name}${this.state.isAddFloat ? " add-float " : " "}`}
				>
					<Callout>Is dummy</Callout>
				</div>
			</Waypoint>
		</div>
	}
	renderSection() {
		const {
			visible_lg, visible_sm, visible_ms, visible_xs,
		} = this.state
		const stl = { ...this.state.style }
		let visibleIcons = ""
		if (!this.is_visible) {
			if (this.state.is_edit) {
				stl.opacity = 0.25
				visibleIcons = (
					<div
						style={{
							position: "absolute",
							padding: 20,
							top: "50%",
							left: "50%",
							marginTop: -120,
							marginLeft: -200,
							display: "flex",
							transform: "scale(.33)",
						}}
					>
						{
							getScreens().map((e, i) => (
								<div
									key={i}
									className="p-4 d-flex"
									style={{
										background: "#FFF",
										border: "1px solid #00000033",
										margin: 10,
									}}
								>
									<div style={{ opacity: this.props[e.name] ? 1 : 0.25 }}>
										{e.icon}
									</div>
								</div>
							))
						}
					</div>
				)
			} else {
				return ""
			}
		}
		let containerStayle = `
		.landing-section, .landing-section-dilimiter
		{
			position:relative;
		}`;
		if (this.state.is_hidden) {
			if (this.state.is_edit) {

			}
			else {
				return null
			}
		}


		/* КОСТЫЛИ */
		// нарастить сверху, если предыдущий сектор - меню
		if (
			this.props.level === 0 && 
			this.props.i > 0 && 
			DataContext.data.sections[this.props.i - 1]?.type === "archor_menu"
		) 
		{
			// console.log( "level:", this.props.level === 0 );
			// console.log( "i:", this.props.i > 0 );
			// console.log( DataContext.data.sections[this.props.i - 1]?.type === "archor_menu"  );
			stl.paddingTop = typeof stl.paddingTop === "undefined" ? 60 : parseInt(stl.paddingTop) + 60
			this.dopEditTop = 60
		}
		/* КОНЕЦ КОСТЫЛЕЙ */

		/* TEMPLATE */
		let palette = this.getPalette()
		/* END TEMPLATE */
		// console.log(palette.id)
		const spn = palette.h1.spn ? palette.h1.spn : {}

		// основной цвет текста - контрачтный к фоновому цвету шаблона
		const revertColor = stl.color = getRevertColor(palette.background_color, palette)

		const _Component = this.state.type && components()[this.state.type] ? components()[this.state.type].c : Title
		let cont = (
			<>
				<SectionBackground
					id={this.props.id}
					background={ this.props.background }
					palette={palette} 
					level={this.props.level}
				/>
				<div
					className={this.getContainerClass() + " " + this.getAnimatedClass()}
					style={{
						marginTop: this.state.composition && this.state.composition.is_expand ? "auto" : "",
						marginBottom: this.state.composition && this.state.composition.is_expand ? "auto" : ""
					}}
				>
					<div
						className={
							this.state.title && this.state.descriptions
								&& (this.state.title.text || this.state.descriptions.text)
								? "landing-title-descr"
								: "hidden"
						}
						style={{
							minHeight: "auto",
							width: this.getProportia("title"),
						}}
						id={`titled_${this.state.id}`}
					>
						<Title
							spn={spn}
							{...this.state.title}
							palette={palette}
							style={{
								fontSize: palette.h1.size,
								fontWeight: palette.h1.weight,
								letterSpacing: palette.h1.letterSpace,
								color: palette.secondary_text_color,
								fontFamily: getFontNameByID(palette.h1.fontFamilyID),
								plateColor: palette.h1.backgroundColor,
								before: palette.h1.before,
								...(this.state.title ? this.state.title.style : {}),
							}}
							id={this.state.id}
							type={this.state.type}
							is_edit={this.state.is_edit}
							onEdit={this.onEdit}
							revertColor={revertColor}
						/>
						<Description
							{...this.state.descriptions}
							palette={palette}
							style={{
								fontSize: palette.subtitle.size,
								fontWeight: palette.subtitle.weight,
								letterSpacing: palette.subtitle.letterSpace,
								color: palette.thirdary_text_color,
								fontFamily: getFontNameByID(palette.subtitle.fontFamilyID),
								plateColor: palette.subtitle.backgroundColor,
								...(this.state.descriptions ? this.state.descriptions.style : {})
							}}
							id={this.state.id}
							type={this.state.type}
							is_edit={this.state.is_edit}
							onEdit={this.onEdit}
							revertColor={revertColor}
						/>
					</div> 
					<_Component
						{...this.state} 
						data={{
							...this.state.data,
							style: {
								color:this.props.revertColor,
								...(this.state.data ? this.state.data.style : {}),
								width: this.getProportia("content"),
							},
						}}
						key={this.props.i + "_" + this.props.id}
						columns={this.getColumns()}
						is_edit={this.state.is_edit}
						level={this.state.level + 1}
						section_id={this.props.i}
						onEdit={this.onEdit}
						onSectionChange={this.onSectorChange}
						palette={palette}
						revertColor={revertColor}
					/>
					<Floats
						{...this.state}
						is_edit={this.state.is_edit}
						section_width={this.state.section_width}
						level={this.state.level + 1}
						getID={this.getID()}
						current_template_id={this.state.current_template_id}
						onRemoveFloat={this.onRemoveFloat}
						onUpdate={this.onUpdateFloat}
						palette={palette}
						revertColor={revertColor}
					/>
				</div>
			</>
		)
		if (this.state.data && this.state.data.as_button) {
			switch (this.state.type) {
				case "contact_form":
				default:

					cont = (
						<>
							<Dialog
								isOpen={this.state.isUUOpen}
								onClose={this.toggleOpen}
								className="p-0"
							>
								<div className="py-0 px-0 position-relative ">
									{cont}
								</div>
							</Dialog>
						</>
					)
			}
		}
		if (this.state.composition && this.state.composition.is_expand ) {
			const persentage = this.state.composition.expand_persentage
				? this.state.composition.expand_persentage
				: 100
			stl.minHeight = document.body.clientHeight * persentage / 100
		}
		//console.log(this.state.current_template_id);
		let way_point
		if(!this.props.is_no_waypoint)
		{
			way_point = <> 
				<Waypoint
					bottomOffset={10}
					// topOffset={0}
					onEnter={evt => { 
						//console.log(evt);
						//console.log(this.props.type);
						this._handleWaypointEnter(evt) 
						if(!this.state.animatedStart)
							this.setState({animatedStart: true})
					}} 
					onLeave={this._handleWaypointLeave}
				> 
				</Waypoint>
			</>
		}
		return (
			<div className="landing-section-container">
				<div
					data-id={this.state.id}
					id={`${this.getID()}`}
					className={`landing-section ${this.state.class_name}${this.state.isAddFloat ? " add-float " : " "}`}
					style={{
						...stl,
						opacity: this.props.is_hidden ? 0.5 : stl.opacity
					}}
				>
					{ way_point }
					{ cont }
				</div>
				{this.is_edit( revertColor )}
				{visibleIcons}
				<Dilimiter
					{...this.state.bottom_dilimiter}
					user={this.props.user}
					section_id={this.props.i}
					id={this.state.id}
					level={this.state.level + 1}
					getID={this.getID()}
					current_template_id={this.state.current_template_id}
					palette={palette}
				/>
				{
					this.props.is_hidden
						?
						<div
							className=" position-absolute z-index-100 w-100 h-100 d-flex justify-content-center align-items-center pre-hidden "

						>
							<div
								className=" position-absolute w-100 h-100 d-flex justify-content-center align-items-center pre-hidden "
								style={{
									backgroundImage: `url(/static/media/diagonal.ac772c94.svg)`,
									backgroundSize: 12,
									backgroundRepeat: "repeat",
									opacity: .25
								}}
							>
							</div>
							<div className=" z-index-100 bg-light text-dark p-3 lead">
								{__("Hidden section")}
							</div>
						</div>
						:
						null
				}
			</div>
		)
	}

	toggleOpen = (evt) => {
		this.setState({ isUUOpen: !this.state.isUUOpen })
	}

	onProportia = (proportiaArr) => {
		if (!this.state.composition) return
		const { composition } = this.state
		switch (this.state.composition.type) {
			case 2:
			case 3:

				break
			default:
			case 0:
			case 1:
				composition.proportia = proportiaArr
				break
		}
		this.setState({ composition })
	}

	getColumns() {
		if (!this.state.composition) return
		const { columns } = this.state.composition
		return ` lacol-${parseInt(columns)}`
	}

	getContainerClass() {
		if (!this.state.composition) return
		// if(this.state.id == 2) console.log(this.state.composition.type );
		const { type, columns, is_blocked } = this.state.composition
		let cl = "container-fluid "
		switch(is_blocked)
		{
			case 1:
			case "1":
			case true:
				cl = "container "
				break;
			case 2:
			case "2" :
				cl = "short-container"
				break;
			case 0:
			default:
				cl = "container-fluid "
		}
		switch (parseInt(type)) {
			case 0:
				cl += " landing-container__horisontal "
				break
			case 1:
				cl += " landing-container__horisontal_reverse "
				break
			case 3:
				cl += " landing-container__vertical "
				break
			case 2:
			default:
				cl += " landing-container__vertical_reverse "
				break
		}
		return cl
	}
	getAnimatedClass = () =>
	{
		//console.log(this.props )
		const delay = this.props.lasy_load_delay ? this.props.lasy_load_delay : "";
		return !this.state.animatedStart && !this.props.is_no_waypoint
			?
			` animate${delay} `
			:
			` animated${delay} ` + ( this.props.lasy_load_type ? this.props.lasy_load_type.type : " fadeInUp " ) 
	}

	getProportia(targ) {
		if (!this.state.composition) return
		const { type, proportia } = this.state.composition
		// console.log( proportia[1] );
		let w
		switch (type) {
			case 0:
				w = (targ == "title" ? proportia[0] : proportia[1])
				break
			case 1:
				w = (targ == "title" ? proportia[1] : proportia[0])
				break
			case 2:
				w = 100
				break
			case 2:
				w = 100
				break
		}
		return w ? `${w}%` : "100%"
	}

	getHelp = (help_url) =>
	{
		if(this.props.getHelp)
		{
			this.props.getHelp(help_url)
		}
	}
	is_edit( revertColor ) {
		// console.log( this.state );
		// matrix()[ this.state.type ].sparams.help_url 
		const help_url = matrix()[ this.state.type ] && 
			matrix()[ this.state.type ].sparams && 
			matrix()[ this.state.type ].sparams.help_url
			? 
			<Button
				onClick={ () => this.getHelp( matrix()[ this.state.type ].sparams.help_url ) }
				icon="help"
				data-hint={__("About ") + this.state.type}
				className=" section-tool-btn hint hint--right"
				intent={Intent.DANGER}
			/>
			: 
			null


		return this.state.is_edit
			? (
				<>
					<SectionGrid
						{...this.state}
						revertColor={revertColor}
					/>
					<FloatDrawer
						is_edit={this.state.is_edit}
						getID={this.getID()}
						isAddFloat={this.state.isAddFloat}
						onUpdateFloat={this.onUpdateFloat}
					/>
					<SectionTools
						is_edit={this.state.is_edit}
						sectionID={this.getID()}
						palette={this.getPalette()}
						dopEditTop={this.dopEditTop}
						level={this.state.level}
						is_open={this.state.isToolOpen}
						onOpen={this.onToolOpen}
						is_hidden={this.props.is_hidden}
						onHide={() => this.props.onHide(this.state.id, this.props.is_hidden ? 0 : 1)}
						showUndefmenu={this.state.showUndefmenu}
						onRnv={this.onRnv}
						onDialogOpen={this.onDialogOpen}
						onDouble={this.onDouble}
						onAdd={this.onAdd}
					/>
					{
						this.state.level === 0
						?
						<div 
							className="landing-sector__edit" 
							style={{ top: this.state.level * 21 + 3 + this.dopEditTop }}
							onMouseEnter={evt => this.setState({showUndefmenu:true}) }
							onClick={evt => this.setState({showUndefmenu:false}) }
						>
							<div className="landing-sector__edit-btn">
								<Button
									intent={Intent.NONE}
									icon="settings"
									className=" section-tool-btn hint hint--right"
									tab-bar-id="prm"
									data-hint={__("Settings")}
									onClick={this.onDialogOpen}
								/>
								{ help_url }
								<Button
									intent={Intent.NONE}
									icon="caret-up"
									data-hint={__("switch up")}
									className=" section-tool-btn hint hint--right"
									onClick={() => this.props.onUp(this.state.i)}
									disabled={this.props.i == 0}
								/>
								<Button
									intent={Intent.NONE}
									icon="caret-down"
									data-hint={__("switch down")}
									className=" section-tool-btn hint hint--right"
									onClick={() => this.props.onDn(this.state.i)}
									disabled={this.props.i == DataContext.data.sections.length - 1}
								/>
								<Button
									intent={Intent.NONE}
									icon="link"
									data-hint={__("Anchor to clipboard")}
									className=" section-tool-btn hint hint--right"
									onClick={() => this.props.CopyArchor(this.state.i)}
								/>
							</div>
							<div className="hidden">
								<Button
									intent={Intent.DANGER}
									icon="cross"
									title={__("close")}
									className="my-1 rounded-circle scale-60"
									onClick={
										() => {
											if ($(".landing-container").hasClass("closed")) {
												$(".landing-container").removeClass("closed")
											} else {
												$(".landing-container").addClass("closed")
											}
										}
									}
								/>

							</div>
						</div>	
						:
						null
					}
					
					<LandingSectionParams
						{...this.state}
						palette={this.getPalette()}
						dopEditTop={this.dopEditTop}
						onEdit={() => this.onEdit({})}
						onApply={this.onApply}
						onTry={this.onTry}
						onUpdate={this.onUpdate}
					/>
					<Drawer
						isOpen={this.state.isDialogOpen}
						onClose={this.onDialogClose}
						className="little3 drawer"
						title={this.dialogTitle()}
						position={Position.LEFT}
						usePortal
						backdropClassName="landing-drawer-bg"
						size={700}
					>
						{this.dialogContent()}
					</Drawer>
					<Button
						className="position-absolute right m-3 z-index-300"
						style={{
							top: this.dopEditTop ? this.dopEditTop + 20 : 0,
							display: this.state.level ? "none" : null
						}}
						icon="error"
						minimal
						intent={Intent.WARNING}
						title={__("Report the common error")}
						onClick={() => this.setState({ issueOpen: !this.state.issueOpen })}
					>

					</Button>
					<Dialog
						title={__("Insert new bug issue")}
						isOpen={this.state.issueOpen}
						onClose={() => this.setState({ issueOpen: false })}
					>
						<Issue
							src={this.state.type}
							sectionID={this.props.id}
							level={this.state.level}
						/>
					</Dialog>
				</>
			)
			: null
	}
	onUpdate = () =>
	{
		this.props.onEdit({
			data: { ...this.state.data },
			id: this.state.id,
			type: this.state.type
		})
	}
	onApply = val => {
		console.log(val, this.state.data,this.state.type)
		if (this.state.type === "cards") {
			//delete templates's content
			let templateData = {...val}
			delete templateData.cards
			this.props.onEdit({
				data: {
					...this.state.data,
					...templateData 
				},
				id: this.state.id,
				type: this.state.type
			})
		}
	}
	onTry = val => {
		//console.log(val, this.state.data,this.state.type)
		if (this.state.type === "cards") {
			this.setState({
				data: {
					...this.state.data,
					...val
				}
			})
		}
	}
	// onSectorChange = (type, data) => {
	// 	//console.log("on Sector Change: ", data, type );
	// 	//console.log( DataContext.data );
	// 	this.setState({ isDialogOpen: false })
	// 	this.props.onEdit({ ...data, type })
	// }
	onSomeChange = () => {
		this.setState({
			is: !this.state.is
		})
	}
	onToolOpen = () => {
		this.setState({
			isToolOpen: !this.state.isToolOpen
		})
	}
	onRemvPopover = () => {
		this.setState({
			isAddPopover: false,
			isRemPopover: !this.state.isRemPopover,
		})
	}

	onAddPopover = () => {
		this.setState({
			isAddPopover: !this.state.isAddPopover,
			isRemPopover: false,
		})
	}

	onDialogOpen = (evt) => {
		// onOpenPortal();
		const dialogType = evt.currentTarget.getAttribute("type")
		const navbarTabId = evt.currentTarget.getAttribute("tab-bar-id")
		this.setState({ isDialogOpen: !this.state.isDialogOpen, dialogType, navbarTabId })
		
	}

	onDialogClose = (evt) => {
		const dialogType = evt ? evt.currentTarget.getAttribute("type") : null
		this.setState({ isDialogOpen: false, dialogType, current_type: this.state.type })
	}

	dialogContent = () => {
		switch (this.state.dialogType) {
			case "menu":
				break
			case "composition":
				break
			case "floats":
				return <FlaotDettingDialog {...this.state} />
				break
			case "title":
				break
			case "component":
				break
			default:
			case "type":
				return (
					<TypeDialog
						{...this.state}
						key={this.state.section_id + Math.random()}
						onChange={this.onSectorChange}
						onClipboardCopy={this.onClipboardCopy}
						onClipboardPaste={this.onClipboardPaste}
						is_hidden={this.props.is_hidden}
						onHide={this.props.onHide}
						onRnv={this.onRnv}
						onUpdateFloat={this.onUpdateFloat}
						onClose={this.onDialogClose}
						current_title={components()[this.state.type] ? components()[this.state.type].title : ""}
						navbarTabId={this.state.navbarTabId}
						palette={this.getPalette()}
					/>
				)
				break
		}
	}

	dialogTitle = () => {
		switch (this.state.dialogType) {
			case "menu":
				return "Main Menu button's parameters"
			case "composition":
				return "Composition of Sector"
			case "title":
				return "Title and Description of Sector"
			case "floats":
				return "Floats in Sector"
			case "type":
			default:
				return components()[this.state.type] ? __(components()[this.state.type].title) : "--"
		}
	}

	onSectorChange = (type, data) => {
		//console.log("on Sector Change: ", data, type );
		//console.log( DataContext.data );
		this.setState({ isDialogOpen: false })
		this.props.onEdit({ ...data, type })
	}

	onEdit2 = (data, id) => {
		//console.log(data, id)
	}
	//Title, Description editing
	onEdit = (data) => {
		//console.log(data);
		this.props.onEdit({ ...data, id: this.state.id })
	}

	onClipboardCopy = (data) => {
		 
		console.log("Section.onClipboardCopy", data)
		this.setState({ isDialogOpen: false })
		this.props.onClipboardCopy(this.state.i, data)
	} 

	onClipboardPaste = (data) => {
		console.log("Section.onClipboardPaste")
		this.setState({ isDialogOpen: false })
		this.props.onClipboardPaste(this.state.i)
	}

	onType = (type, data) => {
		//console.log(data, type, this.state.i)
	}

	onTypeSwitch = (evt) => {
		const current_type = evt.currentTarget.getAttribute("type")
		// console.log( this.state.data );
		this.setState({ current_type, is_change_type_enbl: current_type != this.state.type })
	}

	onRnv = (id) => {
		//console.log(id)
		this.setState({ isRemPopover: false, isDialogOpen: false })
		this.props.onRnv(this.state.i)
	}

	onUpd = () => {
		const sections = [...DataContext.data.sections]
	}

	onAdd = () => {
		if (this.props.onAdd) this.props.onAdd(this.state.i)
	}

	onDouble = () => {
		if (this.props.onDouble) this.props.onDouble(this.state.i)
	}

	onAddFloat = () => {
		this.setState({ isAddFloat: true })
	}

	onRemoveFloat = (float_id) => {
		this.props.onRemoveFloat(float_id)
	}

	onUpdateFloat = (data, float_id, section_id) => {
		this.setState({ isAddFloat: false })
		this.props.onUpdateFloat(data, float_id, section_id)
	}

	_handleWaypointEnter = () => {
		if (this.props.onWaypointEnter) {
			this.props.onWaypointEnter(this.props.id)
		}
	}

	_handleWaypointLeave = () => {
		if (this.props.onWaypointLeave) {
			this.props.onWaypointLeave(this.props.id)
		}
	}

	updateWidth(width) {
		this.setState({ section_width: width })
	}
}
export default PureSection

