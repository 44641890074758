import { Button, ButtonGroup, Position } from "@blueprintjs/core"
import { Popover } from "@blueprintjs/core"
import React from "react"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"

const SubMenu = props =>
{
    const onChangeValue = (value, field) =>
    {
        props.onChange(value, field)
    }
    const content = () =>
    {
        //console.log( props.original.hide_in_page )
        return <div className="p-4 " style={{width:450}}>
            <div className="w-100">
                <FieldInput
                    vertical
                    _id="hide_in_page"
                    title={__("Hide in Member's page")}
                    type="boolean"
                    value={props.original.hide_in_page}
                    onChange={value => onChangeValue( value, "hide_in_page" )}
                />
                <FieldInput
                    vertical
                    _id="hide_in_card"
                    title={__("Hide in cards")}
                    type="boolean"
                    value={props.original.hide_in_card}
                    onChange={value => onChangeValue( value, "hide_in_card" )}
                />
                <FieldInput
                    vertical
                    _id="hide_in_projects"
                    title={__("Hide in admin Projects")}
                    type="boolean"
                    value={props.original.hide_in_projects}
                    onChange={value => onChangeValue( value, "hide_in_projects" )}
                />
                {
                    props.form_fields.type === "number"
                        ?
                        <>
                            <FieldInput
                                vertical
                                _id="add_to_rating"
                                title={__("Add the value of this field to the result of the experts' rating")}
                                type="boolean"
                                value={props.original.add_to_rating}
                                onChange={value => onChangeValue( value, "add_to_rating" )}
                            />
                        </>
                        :
                        null
                }
            </div>
        </div>
    }
    return <Popover 
        interactionKind="hover"
        wrapperTagName="div" 
        modifiers={{
            arrow: { enabled: true },
            flip: { enabled: true },
            keepTogether: { enabled: true },
            preventOverflow: { enabled: true },
        }}
        position={Position.LEFT }
        content={content()}
    >
        <Button icon="more" minimal>

        </Button>
    </Popover>
}

export default SubMenu