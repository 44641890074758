import { Tabs as _Tabs, Tab } from "@blueprintjs/core"
import React from "react" 
import { __ } from "react-pe-utilities/dist"
import Style from "style-it"
import { getFontNameByID } from "./data/PalettePresets"
import Section from "./Section"
import SectionContent from "./SectionContent"

class Tabs extends SectionContent 
{ 
    getState(  ) {
      return {
        currTab: `tab_${ this.props.section_id }_0`
      }
    }
    is() 
    {
        return this.props?.data?.sections?.length > 0
    }
    handleTabChange = currTab =>
    {
        this.setState({ currTab });
    }
    renderContent(style) 
    { 
        const { section_id } = this.props
        const { class_name, orientation, sections, title, height } = this.props.data 
        const tabs = sections.map((section, index) =>
        {
            // console.log( section.section )
            const sec = Array.isArray(section.section)
                ?
                section.section.map((s, ii) => {
                    return <Section													
                        {...section.section[ii]}
                        style={{ 
                            color: this.props.revertColor, 
                            height: "100%", 
                            ...this.getStyle(section.style) 
                        }}
                        key={ii}
                        i={ii}
                        user={this.props.user}
                        is_edit={this.state.is_edit}
                        level={this.props.level + 1}
                        // onEdit={this.onEdit}
                        // onUp={this.onUp}
                        // onDn={this.onDn}
                        // onAdd={this.onAdd}
                        // onRnv={this.onRnv}
                        revertColor={this.props.revertColor}
                        palette={this.props.palette}
                    />
                })
                :
                []
            return <Tab 
                key={index}
                id={`tab_${ section_id }_${index}`} 
                title={section.title}
                className=" overflow-y-auto "
                panel={ sec } 
            />
        })
        const _title = <div className="tabs-title">  { title } </div>
        return Style.it(
            `
            .bp3-tab-indicator-wrapper
            {
                height:49px!important;
            }
            .bp3-tab-indicator
            {
                height: 5px!important;
                top: 40px!important;
                background-color: ${this.props.revertColor}!important;
            }
            .bp3-tab
            { 
                line-height: 49px;
                color: ${this.props.revertColor}!important;
                opacity:.8;
                padding-left: 0!important;
            }
            .bp3-tab:hover
            {
                opacity:1;
                color: ${this.props.revertColor}!important;
            }
            .tabs-title
            {
                font-family: ${ getFontNameByID( this.props.palette.h1.fontFamilyID ) };
                color: ${ this.props.palette.secondary_text_color };
                font-size: 24px;
                padding-right:10px;
            }
            .bp3-tab-panel,
            .bp3-tab-panel > .landing-section-container > .landing-section
            {
                width:100%;
                height: ${height ? height + "px!important" : "auto"};
                max-height: ${height ? height + "px!important" : "auto"};
                min-height: ${height ? height + "px!important" : "auto"};
            }`,
            <div
                className={ `landing-tabs landing-section-core  ${ class_name || "" } ` }
                style={ style } 
            >
                <_Tabs 
                    id={`tab_${ section_id }`}  
                    onChange={this.handleTabChange} 
                    selectedTabId={this.state.currTab}
                    vertical={orientation === "vertical"}
                >
                    { _title }
                    {tabs}
                </_Tabs>
            </div>
        ) 
    } 
}

export default Tabs
