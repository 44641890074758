import React, { Component, Fragment } from "react"
import { withApollo } from "react-apollo"
import { compose } from "recompose"
import { __ } from "react-pe-utilities" 
import Video, { getVideoThumbnail } from "./Video"

import SectionContent from "./SectionContent"
import DataContext from "./DataContext"
import {
  getQueryArgs, getQueryName, querySingle, queryCollection,
} from "react-pe-layouts"
import { getFontNameByID } from "./data/PalettePresets"

class VideoPlayList extends SectionContent {
  is() {
    const { video } = this.state.data
    return Array.isArray(video) && video.length > 0 || this.state.data.src_type != "here"
  }

  getState() {
    const { current, default_video, height } = this.props.data
    return {
      height : height ? height :350,
      current: default_video.id
        ? 
        -1
        : 
        typeof current === "undefined"
          ? 
          0
          : 
          current,
    }
  }

  componentDidMount() {
    if (this.props.data.src_type != "server" || !this.props.data.data_type) return
    const query_name = getQueryName(this.props.data.data_type)
    const query_args = getQueryArgs(this.props.data.data_type)
    const query = queryCollection(this.props.data.data_type, query_name, query_args, "{order:\"asc\"}")
    // console.log(query);
    this.props.client.query({
      query,
    })
      .then((result) => {
        const res = result.data.getPEVideoPoints.map((e) => ({ ...e, title: e.post_title }))
        // console.log( res );
        this.setState({ data: { ...this.state.data, video: res } })
      })
  }

  renderContent(style) {
    const { palette } = this.props;
    const {
      width, height, title, is_sort_by_group, is_sign_by_group, default_video,
    } = this.props.data
    const videos = Array.isArray(this.props.data.video) ? [...this.props.data.video] : []
    if (is_sort_by_group && DataContext.data.landing?.video_group) {
      videos.sort((a,b) =>
      {
        if(a.video_group < b.video_group || typeof a.video_group === "undefined")
          return -1
        if(a.video_group > b.video_group || typeof b.video_group === "undefined")
          return 1
        return 0 
      })
    }
    let prev_btn_style = {}
    // console.log( this.props.data )
    const buttonList = videos
      ?
      videos.map((video, i) => {
        let btn_style; let pr; let
          groupHead
        if (DataContext.data.landing.video_group) {
          const vg = DataContext.data.landing.video_group.filter((e2, i2) => e2.unique == video.video_group)
          // console.log( vg );
          pr = vg && vg[0] ? `${vg[0].color}50` : "transparent"
          if (is_sign_by_group) 
          {
            btn_style = { backgroundColor: pr }
          }

          if (is_sort_by_group && pr != prev_btn_style) 
          {
            groupHead = (
              <div
                className="landing-playlist-group-button"
                style={{ backgroundColor: vg && vg[0] ? vg[0].color : "transparent" }}
              >
                <span
                  className="landing-group-thumb"
                  style={{
                    backgroundImage: `url(${vg && vg[0] ? vg[0].thumbnail : null})`,

                  }}
                />
                <span>
                  {vg && vg[0] ? vg[0].title : "___"}
                </span>
              </div>
            )
          }
        }
        prev_btn_style = pr
        return (
          <Fragment key={i}>
            {groupHead}
            <div
              className={`landing-playlist-button ${i == this.state.current ? " current " : ""}`}
              onClick={() => this.onChoose(i)}
              style={btn_style}
            >
              {video.title}
              {
                i == this.state.current && video.description
                  ?
                  <div
                    className="landing-playlist-button-descr"
                    dangerouslySetInnerHTML={{ __html: video.description }}
                  />                 
                  : 
                  null
              }
              {
                video.author_name
                  ?
                  <div className="landing-playlist-author-name">
                    <i className="fas fa-user mr-2 small " /> 
                    { video.author_name }                 
                  </div>
                  : 
                  null
              }
            </div>
          </Fragment>
        )
      })
      :
      <div>
        {__("No video")}
      </div>
    const currentVideo = this.state.current == -1
      ?
      default_video.id 
        ?
        default_video
        : 
        videos[0]
      :
      videos[this.state.current]
    const thumb = default_video && default_video.thumbnail
      ?
      default_video.thumbnail
      :
      default_video.id 
        ? 
        getVideoThumbnail({
          source: default_video.source,
          video_id: default_video.id,
        })
        :
        null
    // console.log( videos[0] );
    return (
      <div className="landing-playlist-container">
        <div className="landing-playlist-video">
          {
            videos && videos.length > 0
              ?
              <Video
                data={{ ...currentVideo, height, width }}
                type="video"
              />
              : 
              <div className="" style={{ height: 300 }}>{__("No video")}</div>
          }
        </div>
        <div className="landing-playlist-list" style={{ height: `${height}px` }}>
          <div className="landing-playlist-button title">
            {
              default_video.id
                ?
                <div
                  className="landing-video-player-default-thumb"
                  style={{
                    backgroundImage: `url(${thumb})`
                  }}
                  onClick={() => this.onChoose(-1)}
                />
                :
                null
            }
            <div
              style={{
                fontFamily: getFontNameByID(palette.card.title.fontFamilyID)
              }}
            >
              {title}
            </div>
            <span className="comment">
              {`${buttonList.length} ${__(" videos")}`}
            </span>
          </div>
          {buttonList}
        </div>
      </div>
    )
  }

  onVimeoError = (err) => {
    console.error(err)
  };

  onChoose = (i) => {
    this.setState({ current: i })
  }
}
export default compose(
  withApollo,
)(VideoPlayList)
