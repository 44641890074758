import React, { Component } from "react"
import ReactDOM from "react-dom"
import gql from "graphql-tag"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import {LoaderLine, Pagi} from "react-pe-useful"
import { Loading } from "react-pe-useful" 
import { initArea, __ } from "react-pe-utilities"
import { getQueryArgs, schema } from "react-pe-layouts"
import { Button, ButtonGroup, Callout, Icon } from "@blueprintjs/core"
import $ from "jquery"  
import  Style from "style-it" 

class Feed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      count: this.props.count ? parseInt(this.props.count) : 5,
      full_count: -1,
      offset: 0, //parseInt(this.props.offset),
      feed : [],
      containerClassName: props.containerClassName
    }
  }
  loader5 = () =>
  {
    const {palette} = this.props
    return <LoaderLine fill={ palette?.main_text_color } />
  }
  
  componentDidMount() 
  {
    const cont = document.getElementsByClassName("layout-state")[0]
    if(!this.props.is_stop_autoload)
      cont.addEventListener("scroll", this.onscrollHandler)

    const name = `get${this.props.data_type}Count`
    const { paging } = this.props
    const query = gql`
    query ${name} 
    {
      ${name}( paging:{ ${paging}  }) 
    }`
    this.props.client.query({ query, }).then((result) => 
    {
      this.setState({ full_count: result.data[name], offset: this.state.offset + this.state.count })
    })
    this.addFeed()
  }

  componentWillUnmount()
  { 
    const cont = document.getElementsByClassName("layout-state")[0]
    cont.removeEventListener("scroll", this.onscrollHandler)
  }

  onscrollHandler = () =>
  {
    const thoushold = 1100
    const domNode = ReactDOM.findDOMNode(this)
    const y = $(domNode).offset().top + $(domNode).height() - window.screen.height - thoushold
    if(y < 0 && !this.state.loading && this.state.offset < this.state.full_count )
    {
      console.log( y )
      this.setState(
        { 
          loading : true,
          offset: this.state.offset + this.state.count
        },
        this.addFeed()
      )
    }
  }

  getShifter = () =>
  {
    const { count, full_count, offset } = this.state 
    return count && count < full_count && !this.props.is_hide_pagi
        ?
          <div className="py-1 d-flex pe-pagi ">
            <Pagi
              all={Math.ceil(full_count / parseInt(count)) - 1}
              current={parseInt(offset) / parseInt(count)}
              onChoose={this.onPagi}
            />
          </div>
        : 
        null
  }
  addNextFeed = () =>
  {
    if(  this.state.offset < this.state.full_count )
    { 
      this.setState( 
        { offset: this.state.offset + this.state.count },
        this.addFeed()
      )
    }
  }
  addFeed = () => 
  {
    if ( !this.props.data_type ) return
    const name = `get${this.props.data_type}s`
    const { paging } = this.props
    const fields = getQueryArgs(this.props.data_type)
    const { count, offset } = this.state
    const query = gql`
    query ${name} 
    {
      ${name}( paging:{ count:${count}, offset:${offset}, ${paging}  })
      {
        ${fields}
      }
    }`
    this.setState({loading:true})
    this.props.client.query({query})
        .then( result => {
          let data = result.data[ name ] 
          data = typeof data === "object" ? data : {}
          // console.log( data )
          if(name && Array.isArray( data ) && data.length > 0)
          {
            this.setState({
              feed : this.state.feed.concat( data ),
              loading: false
            })
          }
          else
          {
            this.setState({
              loading: false
            })
          }          
        })
  }

  render() { 
    if (!this.props.data_type) {
      return <Callout>
        {__("Unknown data type")}
      </Callout>
    } 
    const {palette} = this.props
    const loading = this.state.loading ? this.loader5() : ""
    const widgets = this.props.is_hide_widgets
      ?
      null
      :
      <>
        <div className="w_250 d-flex flex-column ml-3 mb-3 bg-light" >
          <ButtonGroup vertical large minimal fill>
            <div className="btn btn-light p-3 text-secondary d-flex justify-content-start align-items-center " > 
            <i className="fas fa-search" />
              <div className="ml-3">{__("Search")}</div>
            </div>
            <div className="btn btn-light p-3 d-flex justify-content-start align-items-center " > 
              <i className="fas fa-filter" />
              <div className="ml-3">{__("Filters")}</div>
            </div> 
            {
              initArea(
                "feed-widgets-area",
                {...this.props}
              )
            }
          </ButtonGroup>
        </div>
      </>
    const feed = Array.isArray(this.state.feed) && this.state.feed.length > 0
      ?
      <>
        <div className={this.props.class_name} style={this.props.style}>
        {
          this.state.feed.map((element, i) => 
          {
            const ElComponento = this.props.component 
            const key = i
            return <ElComponento
              key={ key }
              i={i}
              palette={palette} // шаблон для кастомизации дизайна
              {...element}
              elem={element}
              height={this.props.height}
              {...this.props.params}
              data_type={this.props.data_type} // Тип данных для отрисовки карточек (pe-react-landing/Card.js)
              fields={ 
                this.props.elementToFields
                  ?
                  this.props.elementToFields( element ) 
                  :
                  this.props.params.fields
              } // поля шаблона карточки, преобразованные из полей типа данных 
            />
          })
        }
        </div> 
        {
          !this.props.is_hide_widgets && false
            ?
            <div className=" w_250 ">
              { widgets }
            </div>
            :
            null
        }       
        
      </> 
      :
      <Callout className={`p-5 text-center ${this.state.containerClassName}`}>
        {__("No elements exists")}
      </Callout>
    return <div className={ ` ${this.state.containerClassName || "container"}  g-111`}>            
      { feed }       
      {loading}
      {
        this.props.is_show_more && this.state.offset < this.state.full_count
          ?
          Style.it(
              `
              .this-more-button
              {
                  color: ${ palette?.main_text_color }; 
                  opacity:.9;   
              }
              .this-more-button:hover
              {
                  color: ${ palette?.main_text_color }; 
                  opacity:1;  
                  background: transparent; 
              }
              .this-more-button:active
              {
                  color: ${ palette?.main_text_color }; 
                  opacity:1;   
              }
              `,
              <div className="mt-2 d-flex justify-content-center w-100">
                  <Button
                      minimal
                      className="this-more-button"
                      onClick={ this.addNextFeed }
                  >
                      {__("Supplement")}
                  </Button>
              </div>
          )    
          :
          null
      }
    </div>  
  }

  onPagi = (n) => {
    this.setState({ offset: n * this.state.count })
    this.addFeed()
  }

  no() {
    return (
      <div className="alert alert-secondary">
        {__("No elements exist")}
      </div>
    )
  }
}
export default compose(
  withApollo,
)(Feed)

