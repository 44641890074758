import React, { Component } from "react"
import { __ } from "react-pe-utilities"
import { widgets } from "react-pe-layouts"
import Scalars from "./Scalars"

class Widget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      ...this.props,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    const { component } = this.state.item
    const _widget = widgets()[component] || { title: component }
    const _fields = _widget.fields
      ? _widget.fields.map((e, i) => <Scalars {...this.state.item} {...e} key={i} onChange={this.onChange} />)
      : null
    return (
      <div className="admin-widget-container min-width-400">
        <div className="admin-widget-header " onClick={this.onOpen}>
          {__(_widget.title)}
          <div className="admin-widget-remove">
            <div className="btn btn-light  btn-sm mr-1">
              <i className={!this.state.isOpen ? "fas fa-caret-right" : "fas fa-caret-down"} />
            </div>

          </div>
        </div>
        <div className={this.state.isOpen ? "admin-widget-body" : "hidden"}>
          {_fields}
          <div className="d-flex mt-2">
            <div className="btn btn-light btn-sm" onClick={this.onUpdate}>
              {__("Update")}
            </div>
            <div className="btn btn-danger btn-sm" onClick={this.onRemoved}>
              {__("Remove")}
            </div>
          </div>
        </div>
        <div className="admin-widget-footer" />
      </div>
    )
  }

  onChange = (type, value) => {
    console.log(type, value)
    const item = { ...this.state.item }
    item[type] = value
    this.setState({ item })
  }

  onUpdate = () => {
    this.props.onUpdate(this.state.item, this.state.n)
  }

  onOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onRemoved = () => {
    this.props.onRemoved(this.props.item.component)
  }
}
export default Widget
