import React from "react"
import { compose } from "recompose"
import { Route, Switch, withRouter, } from "react-router"
import _fetch from "./wpfestREST"
import User from "./wpfestREST/utilities/User"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"
import {LayoutIcon} from 'react-pe-useful'

import Login from "./wpfestREST/core/login/Login"
import Contents from "./wpfestREST/Contents"
import Festivals from "./wpfestREST/Festivals"

const components = [
  { id: "festivals", component: Festivals },
]

class WPFESTAdmiView extends BasicState {
  basic_state_data() {
    return {
      routing: "Login",
      isLeftClosed: User.is_login,
    }
  }

  getRoute = () => "admin-fests"

  render() {
    console.log(this.props)
    const leftClass = this.state.isLeftClosed
      ? "tutor-left-aside-2 menu-aside closed"
      : "tutor-left-aside-2 menu-aside"
    const mainClass = this.state.isLeftClosed
      ? "tutor-main-2 pr-0 opened"
      : "tutor-main-2 pr-0"
    const _routes = components.map((e, i) => {
      const _Component = e.component
      return (
        <Route
          key={i}
          exact
          path={`/${this.getRoute()}/${e.id}`}
          component={(routeProps) => (
            <_Component
              {...this.props}
              url={e.id}
              root={this.getRoute()}
            />
          )}
        />
      )
    })

    const _switch = (
      <Switch>
        <Route
          exact
          path="/admin-fests"
          component={(routeProps) => (
            <Contents
              {...this.props}
              root={this.getRoute()}
              routes={components}
            />
          )}
        />
        {_routes}
      </Switch>
    )
    const style = { width: 290, minWidth: 290 }
    return (
      <div className="layout-state p-0">
        <div className="tutor-row menu-row" id="cont">
          <div className={leftClass}>
            <div className="layout-state-head menu-header-22" style={style}>
              <LayoutIcon
                isSVG
                src={this.state.route.icon}
                className="layout-state-logo "
              />
              <div className="layout-state-title">
                {__(this.state.route.title)}
              </div>
            </div>
            <div className="small mx-3 mb-3 text-secondary" style={style}>
              {this.props.description}
            </div>
            <div style={style}>
              <Login
                {...this.props}
                root={this.getRoute()}
              />
            </div>
          </div>
          <div className={mainClass}>
            <div className="clapan-left" onClick={() => this.setState({ isLeftClosed: !this.state.isLeftClosed })}>
              <div className={`fas fa-caret-${!this.state.isLeftClosed ? "left" : "right"}`} />
            </div>

            <div className="menu-header-22 flex-centered pointer" onClick={this.onCollapseOpen}>
              <div className="w-100 lead">
                {__(this.state.routing)}
              </div>
            </div>
            {_switch}
          </div>
          <div className="tutor-right-aside-2" />
        </div>
      </div>
    )
  }
}
export default compose(
  withRouter,
)(WPFESTAdmiView)
