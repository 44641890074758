import React from "react"
import { compose } from "recompose"
import {Button} from "@blueprintjs/core"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"
import { initArea } from  "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import { Route, Switch, withRouter } from "react-router"
import { NavLink } from "react-router-dom"
import { help_url } from "react-pe-layouts"
import AppTab from "./adminSettings/AppTab"
import TemplateTab from "./adminSettings/TemplateTab"
import SpecialTab from "./adminSettings/SpecialTab"


class AdminSettingView extends BasicState 
{	
	componentWillMount()
	{
		this.curroute = this.props.preroute + "/" + this.props.route
		//if(this.props.history.location.pathname !== this.curroute + "/app/")
		//	this.props.history.push(this.curroute + "/app/")
	}
	basic_state_data() {
		return {			
			isLeftClosed: window.innerWidth < 760,			
			data: [],
			curNavTabID : "app"
		}
	} 
	renderLeft()
	{
		return <div className="btn-group-vertical w-100" role="group" aria-label="Basic example">			
			<NavLink
				to={this.curroute + "/app/"} 
				activeClassName="w-100 btn btn-danger "
				className="w-100 btn btn-secondary "
			>
				{__("App settings")}
			</NavLink>
			<NavLink
				to={this.curroute + "/template/"}
				activeClassName="w-100 btn btn-danger "
				className="w-100 btn btn-secondary "
			>
				{__("Template settings")}
			</NavLink>
			<NavLink
				to={this.curroute + "/special/"}
				activeClassName="text-danger "
				className="w-100 btn btn-link text-secondary"
			>
				{__("Special settings")}
			</NavLink>
		</div>
	}
	renderCenter()
	{  
		return <> 
			<Switch>
				<Route
					path={this.curroute + "/template/"}
					exact={false}
					component={routeData => (
						<TemplateTab />
					)}
				/>
				<Route
					path={this.curroute + "/app/"}
					exact={false}
					component={routeData => (
						<AppTab />
					)}
				/>
				<Route
					path={this.curroute + "/special/"}
					exact={false}
					component={routeData => (
						<SpecialTab />
					)}
				/>
			</Switch>
		</>			
		
	}

	render() {
		const {  isLeftClosed } = this.state
		const leftClass = isLeftClosed
			? "tutor-left-aside-2 menu-aside closed"
			: "tutor-left-aside-2 menu-aside"
		const mainClass = isLeftClosed
			? "tutor-main-2 pr-0 opened"
			: "tutor-main-2 pr-0"
		
		const help = this.props.help_url
			?
			<span>
				<Button 
					icon="help" 
					minimal 
					onClick={ () => window.open( help_url() + this.props.help_url, '_blank' ) } 
				/>
			</span> 
			:
			null
		return (
			<div className="layout-state p-0">
				<div className="tutor-row menu-row">
					<div className={leftClass}>
						<div className="layout-state-head menu-header-22">
							<LayoutIcon
								isSVG
								src={this.state.route.icon}
								className="layout-state-logo "
							/>
							<div className="layout-state-title">
								{help}
								{__(this.state.route.title)} 
							</div>
						</div>
						<div className="small mx-3 mb-3 " dangerouslySetInnerHTML={{ __html: this.props.description }} />
						<div className="small mx-3 mb-3 ">
							{this.renderLeft()}
						</div>						
						{
							initArea("admin-menu-left-aside",
								{ ...this.props })
						}
					</div>
					<div className={mainClass}>
						<div
							className="clapan-left"
							onClick={() => this.setState({ isLeftClosed: !isLeftClosed })}
						>
							<div className={`fas fa-caret-${!isLeftClosed ? "left" : "right"}`} />
						</div>
						<div className="menu-header-22 flex-centered py-2 pl-5 pr-2">
							
						</div>
						<div className=" p-5 "> 
							{ this.renderCenter() }	
						</div>
					</div>
					<div className="tutor-right-aside-2">
						{
							initArea(
								"admin-menu-right-aside",
								{ ...this.props },
							)
						}
					</div>
				</div>
			</div>
		)
	}
}

export default compose( 
	withRouter,
)(AdminSettingView)

