import React, { Component } from "react"
import { withRouter } from "react-router"
import { compose } from "recompose"
import _fetch from "."
import { __ } from "react-pe-utilities"
import {Loading} from 'react-pe-useful'
import { initArea } from  "react-pe-utilities" 
import WPFestSettings from "./utilities/WPFestSettings"
import FestComponent from "./FestComponent"
import User from "./utilities/User"
import { Callout } from "@blueprintjs/core"

class FestRequests extends FestComponent 
{
	fetchCommand  = "get_rolereq_page"
	fetchArgs     = { }
	header_type   = "member"
	state = {
		loading: true,
		requests: [],
	} 

	addRender = () =>
	{ 
		return (
			<div className="pt-3"> 
				<div className="row">
					{
						User.id 
						&& User.roles.filter((e) => e === "administrator" || e === "Track_moderator").length > 0
							? this.the_content()
							: this.the_none()
					}
				</div>
			</div>
		)
	}

	the_none() {
		return (
			<div className="alert alert-danger p-5 w-100 my-2">
				{__("You no have rights.")}
			</div>
		)
	}

	the_content() {
		// console.log( this.state.role_requests ) 
		const requests = Array.isArray(this.state.role_requests) && this.state.role_requests?.length > 0
			? 
			this.state.role_requests
			.filter( req => {
				if(
					User.roles.filter((e) => e === "administrator").length ==  0 &&
					User.roles.filter((e) => e === "Track_moderator").length > 0
				)
				{
					//console.log("administrator:", User.roles.filter((e) => e === "administrator").length ,
					//"Track_moderator:", User.roles.filter((e) => e === "Track_moderator").length )
					//console.log(req.track?.moderator?.id, User.id)
					return req.track?.moderator?.id === User.id
				}
				else if( 
					User.roles.filter((e) => e === "administrator").length >  0 
				)
				{
					return true
				}
			})
			.map((e, i) => {
				if(!e.id) return
				console.log(e)
				return <div className="row data-list py-2" key={e.id + "_" + e.role}>
					<div className="col-md-3 col-12 text-center p-2">
						{e.name}
					</div>
					<div className="col-md-3 col-12  title text-center p-2">
						{e.role}
					</div>
					<div className="col-md-3 col-12  descr text-center p-2">
						<div className="mb-1">
						{
							e.track 
								?
								<>
									<span className="mr-2">{__("Track")}</span>
									<span className="title">{ e.track.post_title }</span>
								</>
								:
								null
						}							
						</div>
						<div>
						{
							e.member
								?
								<>
									<span className="mr-2">{__("Project")}</span>
									<span className="title">{e.member}</span>
								</>
								:
								null
						}														
						</div>
					</div>
					<div className="col-md-3 col-12 text-center p-2">
						<div className="btn-group">
							<div className="btn btn-success" user_id={e.id} role={e.role} onClick={this.onAccess}>
								{__("Access")}
							</div>
							<div className="btn btn-danger" user_id={e.id} role={e.role} onClick={this.onVeto}>
								{__("Veto")}
							</div>
						</div>
					</div>
				</div>
			})
			: 
			<div className="alert alert-secondary p-5 text-center lead">
				{__("No requests yet")}
			</div>
		return (
			<div className="container">
				{
					requests.length == 0
						?
						<Callout className="p-5 display-6 text-center">
							{
								__("No Requests exists")
							}
						</Callout>
						:
						requests
				}
			</div>
		)
	}

	onAccess = (evt) => {
		const id = evt.currentTarget.getAttribute("user_id")
		const role = evt.currentTarget.getAttribute("role") 
		_fetch(
			"get_fest_command",
			{ id, role, festId: WPFestSettings.festId, command:  "allow_role_req" },
			WPFestSettings.serviceURL,
			WPFestSettings.getToken( WPFestSettings.url ),
			"wpfa",
		)
			.then((_data) => {
				const data = _data.data 
				console.log(data)
				this.setState({
					role_requests: data.role_requests,
					loading: false,
				})
			})
	}

	onVeto = (evt) => {
		const id = evt.currentTarget.getAttribute("user_id")
		const role = evt.currentTarget.getAttribute("role") 
		_fetch(
			"get_fest_command",
			{  id, role, festId: WPFestSettings.festId, command: "veto_role_req" },  
			WPFestSettings.serviceURL,
	      	WPFestSettings.getToken(WPFestSettings.url),
			"wpfa",
		)
			.then((data) => {
				console.log(data)
				this.setState({
					role_requests: data.data.role_requests,
					loading: false,
				})
			})
	}
}
export default compose(
	withRouter,
)(FestRequests)
