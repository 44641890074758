import React, { useEffect } from "react"
import Layouts from "react-pe-layouts"
import { importAll } from "react-pe-utilities/dist"
import { getExternalCardMatrixes } from "./card_matrix"

const ExternalCardField = props =>
{
    useEffect(() => {
        
    }, [])
    
    const components = {};
    const schema = getExternalCardMatrixes().filter(ex => ex.type === props.type)[0]
    // console.log( props.type, schema )
    if(!schema?.component)
    {
        return <div className="">
            {props.type}
        </div>
    }
    // console.log( [schema.component] )
    importAll(
        require.context("modules/", true, /\.js$/), 
        [schema.component], 
        components, {}, "modules/"
    )
    const ElComponent = components[ schema.component ].default
    return <ElComponent 
        {...props }
    />
    
}
export default ExternalCardField