import { Button, Dialog, Intent } from "@blueprintjs/core"
import { CardFieldTypes } from "modules/pe-landing-module/views/LandingState/Card"
import React, { useEffect, useState } from "react" 
import { LayoutIcon } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities" 
import CardFieldMetaphors from "../../views/LandingState/card/CardFieldMetaphors" 

const CardFieldMetaphorEdit = (props) => {
    const [isOpen, setOpen] = useState(false)
    const getValue = () =>
    {
        return props.value
            ?
            {
                value: props.value,
                label: CardFieldMetaphors().filter(e => e._id == props.value)[0]
                    ?
                    CardFieldMetaphors().filter(e => e._id == props.value)[0].title
                    :
                    "---" + props.value
            }
            :
            {
                value: -1,
                label: null
            }
    }
    const [value, setValue] = useState( getValue() )
    useEffect(() =>
    {
        setValue( getValue() )
    }, [props.value])
    const onOpen = () =>
    {
        setOpen(!isOpen)
    }
    const on = value => {
        if (props.on) 
        {
            props.on(
                value
            )
        }
    }
    const onChange = value => {
        //console.log( value.value );
        setValue( value )
        on( value.value )
        setOpen( false )
    }
    const content = () => <div className="p-5 my-4 dialog-content overflow-y-auto ">        
    {
        CardFieldMetaphors()
            .filter( metaphore => !metaphore.hidden )
            .map((e, i) => {
                return <div className="row align-items-stretch " key={i}>
                    <div className={` col-md-6 ${ value.value === e._id ? " landing-border-bottom-dark " : " landing-border-bottom-none " }`}>
                        <Button
                            fill
                            small
                            intent={ value.value === e._id ? Intent.SUCCESS : Intent.NONE }
                            className={`pr-3 rounded-0 h-100 display-8 `}
                            alignText="right"
                            minimal
                            onClick={ evt => onChange({ value: e._id }) }
                        >
                        { __( e.title ) } 
                        </Button>
                    </div>
                    <div className={` col-md-6 ${ value.value === e._id ? " landing-border-bottom-dark " : " landing-border-bottom-none " }`}>
                        <div className="scalar-commentary small py-1 px-0 m-0">
                            { __( e.description ) }
                        </div>
                    </div>
                </div>
                // return <div className="col-md-12" key={i}>
                //     <label className="_check_">
                //         <input
                //             type="radio"
                //             checked={value.value == e._id}
                //             onChange={evt => onChange({ value: e._id })}
                //         />
                //         <div>
                //             <div>
                //                 {__(e.title)}
                //             </div>
                //             <small>
                //                 {__(e.description)}
                //             </small>
                //         </div>
                //     </label>
                // </div>
            })
    }
    </div>
    const currMetaphore = CardFieldMetaphors().filter( m => m._id === value.value )[0]
    return <div>
        <Button 
            minimal
            fill
            large
            onClick={onOpen}
        >
            <div className="d-flex align-items-center">
                <LayoutIcon
                    src={  getCardTypeByMetaphore( value.value ).icon }
                    className="layout-icon p-1 w-30px mr-2 "
                /> 
                <div>
                    <div className=" lead text-uppercase text-center simple-metaphor-title">
                        { __( currMetaphore?.title ) }
                    </div>
                    <div className="scalar-commentary small py-0 px-0 m-0 text-center ">
                        { __( currMetaphore?.description ) }
                    </div>
                </div>
                
            </div>
           
        </Button>
        <Dialog
            className="little2"
            onClose={onOpen}
            isOpen={isOpen}
            title={__("Select Card field's metaphore")}
        >
            { content() }
        </Dialog>
    </div>
    // const options = CardFieldMetaphors().map((e, i) => {
    //     return {
    //         value: e._id,
    //         label: e.title
    //     }
    // })
    // return <>
    //     <Select
    //         value={value}
    //         isSearchable
    //         onChange={onChange}
    //         options={options}
    //         placeholder={__("Select Field metaphor")}
    //         className="basic-multi-select w-100 flex-grow-100 "
    //         classNamePrefix="select-color-"
    //     />
    // </>
}
export default CardFieldMetaphorEdit

export const getCardTypeByMetaphore = (metaphoreID) => {
    const metaphore = CardFieldMetaphors().filter( m => m._id === metaphoreID )[0]
    if(!metaphore)  return {}
    const cardType  = CardFieldTypes().filter(t => t.type === metaphore.type)[0]
    if(!cardType)   return {}
    return cardType
}