import React, { Component } from "react"
import { __ } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import { components } from "./data/components"
import { Button, Dialog, Intent, Icon } from "@blueprintjs/core"
import {getStyle} from "./SectionContent"
import $ from "jquery"
import LinkContainer from "./utils/LinkContainer"

class Image extends Component {
	state = {
	  ...this.props,
	}
	componentDidUpdate(nextProps) {
	  let isUpdate = false
	  const state = { };
	  ["is_edit", "data", "class_name", "type", "title"]
	    .forEach((e, i) => {
	      if (nextProps[e] !== this.state[e]) {
	        isUpdate = true
	        state[e] = nextProps[e]
	      }
	    })
	  if (isUpdate) {
	    // console.log(state);
	    this.setState(state)
	  }
	}
	onClick = evt =>
	{		 
		const {is_full_open} = this.state.data;
		if(is_full_open)
		{ 
			 
			this.setState({is_full_open: !this.state.is_full_open})
			setTimeout(() => {
				const modalImg = document.getElementById("modalImg")
				const modalCont = document.getElementsByClassName("bp3-dialog")[0]
				if(modalImg)
				{					
					const realWidth = modalImg.naturalWidth;
					const realHeight = modalImg.naturalHeight;
					const sizes = getCoverImage(realWidth, realHeight)
					// console.log(sizes )
					modalImg.style.height = sizes.height 
					modalImg.style.width = sizes.width
					if(modalCont)
					{
						modalCont.style.height = sizes.height 
						modalCont.style.maxWidth = sizes.width 
						modalCont.style.overflow = "hidden"
					}
				}
			}, 100)
		}
	}
	render() {
	  if(!this.props.data) return 
	  const { title, class_name } = this.props
	  const {
	    src, 
		src_type, 
		style, 
		height, 
		original_size, 
		background_position_x, 
		width_fixed,
		description,
		description_title,
		description_style,
		description_class_name, 
		image_vertical, 
		is_contrast_muar, 
		exact_value,
		link_url,
		link_target,
		 
	  } = this.props.data
	  const {is_full_open} = this.state
	  const st = { backgroundImage: `url(${src})`, height }
	  if (original_size) {
	    st.backgroundSize = "auto"
	    st.backgroundRepeat = "no-repeat"
	  }
	  if (background_position_x) {
	    st.backgroundPositionX = background_position_x
	  }
	  	const muar = is_contrast_muar ? " muar" : ""
		let thumb_image_vert = { backgroundPosition:"center"}
		switch(image_vertical)
		{
			case "top":
			case "bottom":
			case "center":
				thumb_image_vert = { backgroundPositionY:image_vertical}
			break
			case "exact_value":
			default:
				thumb_image_vert = { backgroundPositionY:`${exact_value}%`}
			break
		}


	  // console.log( style );
	  const descr = description_title || description
	  	?
		<div className={"landing-image-descr-container landing-section-core " + description_class_name} style={description_style}>
			<div 
				className="landing-image-descr-title"
				dangerouslySetInnerHTML={{__html:__(description_title)}}
			/>
			<div 
				className="landing-image-descr"
				dangerouslySetInnerHTML={{__html:__(description)}}
			/>
		</div>
		: 
		null
	  return <>
		{
			src
				? 
				src_type === "div"
					? 
					<LinkContainer
						link_url={link_url}
						link_target={link_target}
					>
						<div
							className={`landing-image ${class_name} ${muar}` }
							style={{ 
								...getStyle(style), 
								...st, 
								cursor: is_full_open ? "pointer": null, 
								...thumb_image_vert 
							}}
							onClick={this.onClick}
						> 
							{descr}
						</div>
					</LinkContainer>
					: 
					<div className={`landing-image ${class_name}`} onClick={this.onClick}>
						<LinkContainer
							link_url={link_url}
							link_target={link_target}
							style={{
								margin:"0 auto"
							}}
						>
							<img
							src={src}
							alt={title ? title.text : ""}
							
							style={{
								height: height || (width_fixed
									? "auto"
									: "100%"),
								margin: "0 auto",
								...getStyle(style),
								width: width_fixed ? "100%" : "auto",
							}}	
							/>
							{descr}
						</LinkContainer>
					</div>
				: 
				<div
					className={` landing-empty ${class_name}`}
					style={{
								...getStyle(style),
								minHeight: height || 400,
					}}
				>
					<LayoutIcon
						src={components()[this.props.type].icon}
						className=" layout-icon white "
					/>
					<div className="lead text-white">
						{ __(components()[this.props.type].title) }
					</div>
				</div>
		}
		{
			is_full_open
				?
				<Dialog 
					isOpen={this.state.is_full_open}
					onClose={this.onClick}
					className="landing-outer-container transparent m-4" 
				>
					<img src={src} id="modalImg" alt={title ? title.text : ""}  />
					<Button 
						large 
						className=" position-absolute right top m-1" 
						onClick={this.onClick}
					>
						<Icon icon="cross" iconSize={ 30 } />
					</Button>
				</Dialog>
				:
				null
		}
		</>
	}
}
export default Image


const getCoverImage = (width, height) =>
{
	const swidth = document.body.clientWidth - 100
	const sheight = document.body.clientHeight - 100
	//console.log( "auto", sheight + "px" ) 

	//return {width: "auto", height: sheight + "px" }


	if( width / swidth > height / sheight )
	{
		return {width: swidth + "px", height: swidth / width * height + "px" }
	}
	else
	{
		return {width: sheight / height * width + "px", height: sheight + "px" }
	}
}