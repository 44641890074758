import React from "react"
import { useHistory } from "react-router"
import { useParams } from 'react-router-dom';
import { WPFEST_ADMIN_MY_ROUTE } from "../CabinetView";
import SiteSingle from "../SingleSite/SiteSingle"

const Site = props =>
{ 
    const location = useParams()
    const history = useHistory()
    const onList = () =>
    {
        history.push(props.preroute + "/" + props.route + "/" + WPFEST_ADMIN_MY_ROUTE)
    }
    return <div className="p-3">
       <SiteSingle blog_id={parseInt(location.id)} onList={ onList} />
    </div>
}
export default Site