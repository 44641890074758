import React, { useState, Suspense } from "react"
import FieldInput from "react-pe-scalars"
import { __ } from "react-pe-utilities" 
import matrix from "../../views/LandingState/data/matrix"
//import Select from "react-select" // https://github.com/JedWatson/react-select
import { getPalette } from "../../views/LandingState/Section"
import { ButtonGroup, Button, Intent, Popover } from "@blueprintjs/core"
import {Loading} from 'react-pe-useful'
import Select from 'react-select' 

const ExternalLandingColor = props => {
    const [value, setValue] = useState(
        typeof props.value !== "object"
            ?
            { source: "choose", color: props.value ? props.value.toString() : null }
            :
            props.value
    )
    const [src, onSrc] = useState(
        typeof props.value !== "object" || !props.value
            ?
            "choose"
            :
            props.value.source
    )
    const [opacity, changeOpacity] = useState(
        typeof props.value !== "object" || !props.value
            ?
            1
            :
            props.value.opacity
    )
    const [ brightness , changeBrightness] = useState(
        typeof props.value !== "object" || !props.value
            ?
            0
            :
            props.value.brightness
    )
    const [accentuated, changeAccentuated] = useState(
        typeof props.value !== "object" || !props.value
            ?
            false
            :
            props.value.accentuated

    )
    const [ saturation , changeSaturation] = useState(
        typeof props.value !== "object" || !props.value
            ?
            0
            :
            props.value.saturation
    )
    // console.log(props )
    const palette = props.palette
        ?
        props.palette
        :
        props.origin && props.origin.origin
            ?
            getPalette(props.origin.origin?.current_template_id)
            :
            getPalette(props.origin?.current_template_id)
    const popoverContent = () => {
        return <div className=" overflow-y-auto  p-4" style={{ width: 400, maxHeight: "80vh" }}>
            <FieldInput
                title={__("Accentuated color")}
                type="boolean"
                on={(value, dopol) => onAccentuated(value, dopol)}
                onChange={(value, dopol) => onAccentuated(value, dopol)}
                origin={{}}
                commentary={__("We choose the most contrasting color in relation to the selected one. If the brightness of the specified color is less than half, set it to white. Otherwise put black.")}
                editable
                min={0}
                max={1}
                step_size={0.01}
                label_step_size={0.2}
                value={accentuated}
                vertical={true}
                palette={palette}
            />
            <FieldInput
                title={__("Opacity")}
                type="slider"
                on={(value, dopol) => onOpacity(value, dopol)}
                onChange={(value, dopol) => onOpacity(value, dopol)}
                origin={{}}
                editable
                min={0}
                max={1}
                step_size={0.01}
                label_step_size={0.2}
                value={opacity}
                vertical={true}
                palette={palette}
            />
            <FieldInput
                title={__("Brightness")}
                type="slider"
                on={(value, dopol) => onBrightness(value, dopol)}
                onChange={(value, dopol) => onBrightness(value, dopol)}
                origin={{}}
                editable
                min={-3}
                max={3}
                step_size={ .1 }
                label_step_size={ 1 }
                value={brightness}
                vertical={true}
                palette={palette}
            />
            <FieldInput
                title={__("Saturation")}
                type="slider"
                on={(value, dopol) => onSaturation(value, dopol)}
                onChange={(value, dopol) => onSaturation(value, dopol)}
                origin={{}}
                editable
                min={-3}
                max={3}
                step_size={ .1 }
                label_step_size={ 1 }
                value={saturation}
                vertical={true}
                palette={palette}
            />
        </div>
    }
    const onBrightness = (brightness) => {
        console.log(brightness)
        changeBrightness(brightness)
        setValue({
            ...value,
            brightness  
        })
        on({
            ...value,
            brightness 
        })
    }
    const onSaturation = (saturation) => {
        console.log(saturation)
        changeSaturation(saturation)
        setValue({
            ...value,
            saturation  
        })
        on({
            ...value,
            saturation 
        })
    }
    const onOpacity = (opacity) => {
        console.log(opacity)
        changeOpacity(opacity)
        setValue({
            ...value,
            opacity  
        })
        on({
            ...value,
            opacity 
        })
    }
    const onAccentuated = (accentuated) => {
        console.log(accentuated)
        changeAccentuated(accentuated)
        setValue({
            ...value,
            accentuated  
        })
        on({
            ...value,
            accentuated 
        })
    }
    const on = value => {
        if (props.onChange)
            props.onChange(value)
    }
    const clear = () => {
        onSrc("choose")
        onColor(null)
    }
    const onColor = (value) => {
        console.log(value)
        on(value)

    }
    const onTemplateChange = value => {
        console.log(value.value);
        setValue({
            source: "template",
            field: value.value,
            opacity,
            brightness,
            saturation
        })
        on({
            source: "template",
            field: value.value,
            opacity,
            brightness,
            saturation
        })
    }
    const form = () => {
        switch (src) {
            case "template":
                return getTemplateSelect()
            case "landing":
                return "LANDING"
            case "choose":
            default:
                // console.log(value)
                return <FieldInput
                    type="color"
                    on={(value, dopol) => on(value, dopol)}
                    onChange={(value, dopol) => onColor(value)}
                    editable
                    value={value ? value.color : null}
                    vertical={true}
                    not_clear={false}
                />
        }
    }
    const switcher = () => {
        return <div className="d-flex align-items-center decrease">
            <span className=" title">
                {__("Color sourse: ")}
            </span>
            <div className="pl-2 align-items-center d-flex">
                <ButtonGroup minimal className="">
                    <Button
                        intent={src === "choose" || !src ? Intent.DANGER : Intent.NONE}
                        onClick={evt => onSrc("choose")}
                    >
                        {__("Choose")}
                    </Button>
                    <Button
                        intent={src === "template" ? Intent.DANGER : Intent.NONE}
                        onClick={evt => onSrc("template")}
                    >
                        {__("Style template")}
                    </Button>
                </ButtonGroup>

                <label className="_tab_ mr-3 hidden ">
                    <input
                        type="radio"
                        checked={src === "choose" || !src}
                        value="choose"
                        onChange={evt => onSrc("choose")}
                    />
                    {__("Choose")}
                </label>
                <label className="_tab_ mr-3 hidden ">
                    <input
                        type="radio"
                        checked={src === "template"}
                        value="template"
                        onChange={evt => onSrc("template")}
                    />
                    {__("Style template")}
                </label>
            </div>
        </div>
    }
    const getTemplateSelect = () => {
        const options = Object.keys(matrix().__palette)
            .filter(field => {
                const distination = Array.isArray(matrix().__palette[field].distination)
                    ?
                    matrix().__palette[field].distination
                    :
                    [matrix().__palette[field].distination]
                return !matrix().__palette[field].hidden &&
                    matrix().__palette[field].type === "color" &&
                    distination.map(e => e === props.distination).length > 0
            })
            .map(
                (field) => {
                    return {
                        value: field,
                        label: <div className="d-flex">
                            <div
                                style={{
                                    background: palette[field],
                                    width: 24,
                                    height: 24,
                                    borderRadius: 2,
                                    marginRight: 12
                                }}
                                className=""
                            />
                            {matrix().__palette[field] ? matrix().__palette[field].title : field}
                        </div>,
                        color: "#FF0000"
                    }
                })
        //console.log(props)
        const val = value ? value.field : ""
        return <div className="d-flex  my-2">
            <Suspense fallback={<Loading />}>
                <Select
                    value={{
                        value: val,
                        label: <div className="d-flex">
                            <div
                                style={{
                                    background: palette[val],
                                    width: 24,
                                    height: 24,
                                    borderRadius: 2,
                                    marginRight: 12
                                }}
                                className=""
                            />
                            {matrix().__palette[val] ? matrix().__palette[val].title : val}
                        </div>
                    }}
                    // styles={colourStyles}
                    isSearchable
                    onChange={onTemplateChange}
                    options={options}
                    placeholder={__("Select source color in style template")}
                    className="basic-multi-select w-100 flex-grow-100 "
                    classNamePrefix="select-color-"
                />
            </Suspense>
            <Popover
                content={popoverContent()}
            >
                <Button
                    icon={<i className="fas fa-ellipsis-v"></i>}
                    minimal
                />
            </Popover>
            {
                props.not_clear
                    ?
                    null
                    :
                    <Button
                        className=" "
                        icon="cross"
                        minimal
                        onClick={clear}
                    />
            }

        </div>
    }
    // console.log( props )
    return <div className="d-flex w-100 flex-column ">
        <div className="w-100" style={{ height: 45 }}>
            {form()}
        </div>
        <div className="d-flex w-100">
            {switcher()}
        </div>
    </div>
}
export default ExternalLandingColor;