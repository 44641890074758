import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { Button, Dialog } from "@blueprintjs/core"
import { compose } from "recompose"
import { withRouter } from "react-router"
import $ from "jquery"
import Style from "style-it"
import { __ } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import {ContentByRoute} from "react-pe-useful"
import CardTail from "./card/CardTail"
import Section, { getDefault, getStyle } from "./Section"
import SVGClip from "./mask/SVGClip"

import { sectionDataParse } from "../../widgets/landingObject/CardSingleFieldType"
import CardFieldString from "./card/CardFieldString"
import CardFieldMedia from "./card/CardFieldMedia"
import CardFieldOuterlink from "./card/CardFieldOuterlink"
import CardFieldNavlink from "./card/CardFieldNavlink"
import CardFieldPrice from "./card/CardFieldPrice"
//import chroma from "chroma-js"
import EditLabel from "./EditLabel"
import OuterURL from "./OuterURL"
import Outer from "./card/Outer"
import { components } from "./data/components"
import CardFieldEmpty from "./card/CardFieldEmpty"
import CardFieldIcon from "./card/CardFieldIcon"
import CardFieldCheckLabel from "./card/CardFieldCheckLabel"
import { card_matrix } from "./card/card_matrix"
import CardFieldPersonalLinks from "./card/CardFieldPersonalLinks"
import CardFieldTag from "./card/CardFieldTag"
import { getColor, getPlainColor, _chroma } from "./data/getColor"
import CardFieldPerson from "./card/CardFieldPerson"
import { Waypoint } from "react-waypoint"
import {getVKLink} from "./Video"
import {CARD_SEPARATOR} from "./Card"

class Card extends Component {
	constructor(props) {
		super(props)
		this.ref = React.createRef()
		this.state = {
			...props,
			isLoad: Array.isArray(props.cardFields) && props.cardFields.length > 0 && 
					Array.isArray(props.fields) && props.fields.length > 0
				?
				props.fields.filter((e, i) => {
					return props.cardFields[i] &&
						props.cardFields[i].type == "media" &&
						props.cardFields[i].variant == 5
				}).length == 0
				:
				false,
			scale : props.scale ? props.scale : 1
		}
	}


	rotation = this.props.random_rotaion ? parseInt(Math.random() * this.props.random_rotaion * 2) - this.props.random_rotaion : 0;

	componentDidMount() {
		const {
			fields,
			cardFields,
		} = this.state
		window.addEventListener("resize", this.updateWindowDimensions)
		this.updateWindowDimensions()
		if (!Array.isArray(cardFields) || cardFields.length == 0)
			return;
		const $this = this
		if(Array.isArray(fields))
		{
			fields
				.filter((e, i) => cardFields[i] && cardFields[i].type == "media" && cardFields[i].variant == 5)
				.forEach((e, i) => {
					const newImg = new Image()
					newImg.onload = function () {
						// console.log( this.width + ' x ' + this.height );
						$this.state.cardFields[i].imageWidth = this.width
						$this.state.cardFields[i].imageHeight = this.height
						$this.state.cardFields[i].imageProportia = this.width / this.height
						// console.log( $this.state.cardFields );
						$this.setState({ isLoad: true })
					}
					newImg.src = e.field
				})
		}
		
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions)
	}

	componentDidUpdate(nextProps, nextState) {
		/*
		if (nextProps.random_rotaion != this.props.random_rotaion) {
			this.rotation = nextProps.random_rotaion
				? parseInt(Math.random() * nextProps.random_rotaion * 2) - nextProps.random_rotaion
				: 0
			setTimeout(() => {
				this.setState({ s: !this.state.s })
			}, 100)
		}
			*/
		let state = {}, is_update = false;
		Object.keys(nextProps).forEach((e, i) => {
			if (nextProps[e] !== this.state[e] && typeof nextProps.columns !== "undefined") {
				//if(e !== "palette") console.log(e, nextProps[e])
				if ([
					"is_joint",
					"is_back_image",
					"back_image_vertical",
					"exact_value",
					"back_image",
					"is_contrast_muar"
				].filter(ff => ff === e).length > 0) {
					//console.log(e)
					is_update = true
				}
				state[e] = nextProps[e];
			}
		})
		if (Object.keys(state).length > 0) {
			this.setState(state);
			if (is_update) {
				setTimeout(() => this.updateWindowDimensions(), 100)
			}
		}
		// setTimeout(() =>
		// { 
		// 	this.setState({isAnimated : false })	
		// }, 200)

	}

	updateWindowDimensions = () => {
		const { back_image_vertical, exact_value } = this.state
		const { columns, i } = this.props;
		let bckgroundSize, bckgroundPosition = "0 " + back_image_vertical + "!important";
		
		if (this.state.is_joint) {
			const me = document.getElementById(`card_${this.state.section_id}_${this.state.i}`)
			//console.log(`card_${this.state.section_id}_${this.state.i}`)
			//console.log(me.getBoundingClientRect())
			//console.log(me)
			//console.log(me.offsetWidth) 
			//console.log(me.closest(".landing-cards").getBoundingClientRect())
			const fullWidth = me.closest(".landing-cards").getBoundingClientRect().width
			const vert = back_image_vertical == "exact_value"
				?
				exact_value + "%"
				:
				back_image_vertical
			bckgroundSize = `${fullWidth / this.state.scale }px auto!important `
			bckgroundPosition = `${fullWidth / this.state.scale  / columns * (columns - i)}px ` + vert + `!important `
		}
		//console.log("is_joint: ", bckgroundSize,", ", bckgroundPosition)
		this.setState({
			width: document.body.clientWidth,
			bckgroundSize,
			bckgroundPosition

		})
	}

	render() {
		//console.log(this.state.isCardDialogOpen)
		const {
			vertical_align,
			columns,
			class_name,
			style,
			card_inner_style,
			fields,
			decoration,
			cliping,
			tension,
			tail_style,
			tail_color_hover,
			tail_text,
			tail_type,
			is_empty_tail_hide,
			border_radius,
			border_top_right_radius,
			border_bottom_left_radius,
			border_bottom_right_radius,
			box_shadow,
			height,
			padding,
			cardFields,
			link_type,
			dialog_title,
			dialog_content_type,
			dialog_sections,
			reverse_mounting,
			color,
			palette,
			is_back_image,
			back_image,
			is_contrast_muar,
			is_joint,
			back_image_vertical,
			back_opacity,
			is_card_img_youtube_thrum,
			dialog_video_service,
			dialog_youtube_id,
			dialog_vimeo_id,
			section_id,
			is_edit,
			isOuterOpen,
			outerURL,
			hide_action_icon,
			lasy_load_delay__2,
			lasy_load_type__2
		} = this.props
		const { inner_proportia } = this.props.composition
		// if (!this.state.isLoad) return <div >
		// 	<Loading /> 
		// </div>

		const delay = lasy_load_delay__2 == null || lasy_load_delay__2 == 0 
			? 
			"" 
			: 
			lasy_load_delay__2
		const animate = this.state.isAnimated
			?
			` animated${delay} ${ lasy_load_type__2 ? lasy_load_type__2.type : "fadeIn"} `
			:
			` animate${delay} `

		// console.log(tail_type === "top_plate", is_empty_tail_hide, !tail_text)
		let icon = null 
		let is = false
		const fieldsObjs = Array.isArray(fields) && cardFields
			?
			fields.map((e, i) => {
				if (cardFields && cardFields[i] && cardFields[i].type == "media" && !is && !hide_action_icon) 
				{
					switch (link_type) 
					{  
						case "3": // dialog content
							let stl = { position: "absolute" }
							if (cardFields[i].variant == 1) // кружок
							{
								stl.bottom = -cardFields[i].height / 2 + 10
								stl.padding = "12px 16px"
								stl.borderRadius = "100%"
							}
							switch (dialog_content_type) {
								case "text_content":
									icon = <div className="lvt" style={stl}>
										<i className="fas fa-quote-right fa-1x" />
									</div>
									break
								case "inner_link":
									icon = <div className="lvt" style={stl}>
										<i className="fab fa-slideshare fa-1x" />
									</div>
									break
								case "sections":
									const sectionType = dialog_sections[0] ? dialog_sections[0].type : "image"
									icon = <div className="lvt" style={stl}>
										{/* <i className="fas fa-envelope-open-text fa-1x"/> */}
										<LayoutIcon
											src={components()[sectionType || "html"].icon}
											className=" layout-icon white "
										/>
									</div>
									break
								case "video":
								default:
									icon = <div className="lvt" style={stl}>
										<i className="fas fa-play fa-1x" />
									</div>						
									break
							}
							break
						default:
							break 
					}
					is = true
				}
				else
				{
					icon = null
				}
				return (
					<Fragment key={i}>
						{
							(e.field && e.field.toString().replaceAll(CARD_SEPARATOR(), "")) ||
								(cardFields[i] && cardFields[i].type == "empty")||
								(cardFields[i] && cardFields[i].type == "divider") ||
								(cardFields[i] && cardFields[i].type == "media" && is_card_img_youtube_thrum) 
								?
								this.field(e, i, icon)
								:
								cardFields[i] && cardFields[i].hide_empties
									?
									null
									:
									<div
										className={ this.getFieldClasses( cardFields[i] ) }
										field_id={i}
										style={
											cardFields[i] && cardFields[i].style ? getStyle(cardFields[i].style, this.state.palette) : {}
										}
										key={i}
									/>
						}
					</Fragment>
				)
			})
			:
			[]
		let aStyle = getStyle(tail_style, this.state.palette)
		let afterCard = (
			<CardTail
				tail_text={tail_text}
				palette={palette}
				{...this.state}
			/>
		)
		const aStyleHover = `.landing-card-cont:hover .landing-card-after
			{
				background-color: ${tail_color_hover}!important;
			}`
		// console.log ( aStyleHover );
		let fullHeight = height && (vertical_align == "glaringly" || !vertical_align) ? { height: `${height}px` } : {}
		let fullPadding = padding ? { padding: `${padding}px` } : {}
		const borderRadius = border_radius && (!decoration || decoration == 1) ? border_radius : 0
		const borderTopRightRadius = (!decoration || decoration == 1) ? border_top_right_radius : 0
		const borderBottomLeftRadius = (!decoration || decoration == 1) ? border_bottom_left_radius : 0
		const borderBottomRightRadius = (!decoration || decoration == 1) ? border_bottom_right_radius : 0

		const boxShadow = box_shadow && Array.isArray(box_shadow)
			? box_shadow.map((e) => (e
				? (e.inset ? "inset " : "")
				+ (e.horizontal ? `${e.horizontal}px ` : "0 ")
				+ (e.vertical ? `${e.vertical}px ` : "0 ")
				+ (e.blur ? `${e.blur}px ` : "0 ")
				+ (e.spread ? `${e.spread}px ` : "0 ")
				+ (e.color ? e.color : "")
				: "")).join(", ")
			:
			palette.boxShadow
				?
				palette.boxShadow
				:
				null
		// const clipPath = "inset(0px 0px 0px 0px round " +  borderRadius + "px " + borderRadius + "px)";
		let clipPath; let clipWidth; let clipTransform; let clipPadding
		switch (decoration) {
			case 2: // "Cliping"
			case "2":

				if (cliping == 2) {
					clipPath = `polygon(calc(100% - ${tension}px) 0, 100% 50%, calc(100% - ${tension}px) 100%, 0 100%, ${tension}px 50%, 0 0)`
					clipWidth = `calc(100% + ${tension}px)`
					clipTransform = `translateX(-${tension / 2}px) translateY(0px)`
				}
				if (cliping == 4) {
					clipPath = `polygon(${tension}px 0, 100% 0, calc(100% - ${tension}px) 100%, 0 100%)`
					clipWidth = `calc(100% + ${tension}px)`
					clipTransform = `translateX(-${tension / 2}px) translateY(0px)`
				}
				if (cliping == 5) {
					clipPath = `polygon(0 0, calc(100% - ${tension}px) 0, 100% 100%,${tension}px 100% )`
					clipWidth = `calc(100% + ${tension}px)`
					clipTransform = `translateX(-${tension / 2}px) translateY(0px)`
					// console.log(clipPath)
				}
				if (cliping == 6) // origami
				{
					aStyle = {
						...aStyle,
						position: "relative"
					}
					fullPadding = {
						...fullPadding,
						marginBottom: 180
					}
					fullHeight = height
						? {
							height: `${height + 200}px`,
							paddingBottom: 70,
						}
						: {
							paddingBottom: 70,
						}
					if (this.state.i == 3) {
						clipPath = "polygon(11px 7px, calc(100% - 0px) 0px, 100% 100%, 0px calc(100% - 40px))"
						clipWidth = "calc(100% + 35px)"
						fullHeight = {
							...fullHeight,
							transform: "translateX(-17px) translateY(0px)",
						}
						afterCard = [
							<div
								key={1}
								style={{
									width: "calc(100% + 35px)",
									height: 37,
									transform: "translateX(0px) translateY(-40px)",
									clipPath: "polygon(0px 0px, 100% 39px, calc(100% - 52px) 100%, 35px calc(100% - 13px))",
									backgroundColor: getColor(color, palette), //getColor(color, palette) || "#FFF",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										width: "100%",
										height: 37,
										backgroundColor: "#00000044",
									}}
								/>
							</div>,
							<div
								key={2}
								style={{
									width: "calc(100% + 35px)",
									height: 47,
									transform: "translateX(0px) translateY(-54px)",
									clipPath: "polygon(35px 0px, calc(100% - 50px) 14px, calc(100% - 106px) calc(100% - 14px), -1px 100%)",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							/>,
							<div
								key={3}
								style={{
									width: "calc(100% + 35px)",
									height: 135,
									transform: "translateX(0px) translateY(-105px)",
									clipPath: "polygon(0% 51px, 72% 36px, 60% calc(100% - 0px))",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										width: "calc(100% + 35px)",
										// clipPath : "polygon(23% 17px, 69% 37px, 54% calc(100% - -30px))",
										backgroundColor: "#00000044",
										height: 135,
									}}
								/>
							</div>,
						]
					}
					else if (this.state.i != 1) {
						clipPath = "polygon(15px 7px, calc(100% - 20px) 0, 100% 100%, 0 calc(100% - 25px))"
						afterCard = [
							<div
								key={1}
								style={{
									width: "100%",
									height: 50,
									transform: "translateX(0) translateY(-25px)",
									clipPath: "polygon(0 0, 100% 25px, calc(100% - 25px) 100%, 55px calc(100% - 5px))",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										width: "100%",
										height: 50,
										clipPath: "polygon(0 0, 100% 25px, calc(100% - 25px) 100%, 55px calc(100% - 5px))",
										backgroundColor: "#00000033",
									}}
								/>
							</div>,
							<div
								key={2}
								style={{
									width: "100%",
									height: 30,
									transform: "translateX(0) translateY(-30px)",
									clipPath: "polygon(55px 0px, calc(100% - 25px) 4px, calc(100% - 120px) calc(100% - 10px), 20px 100%)",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							/>,
							<div
								key={3}
								style={{
									width: "100%",
									height: 120,
									transform: "translateX(0) translateY(-40px)",
									clipPath: "polygon(20px 9px, calc(100% - 120px) 0px, calc(50%) calc(100% - 0px), 50% 100%)",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										width: "100%",
										height: 120,
										clipPath: "polygon(20px 9px, calc(100% - 120px) 0px, calc(50%) calc(100% - 0px), 50% 100%)",
										backgroundColor: "#00000044",
									}}
								/>
							</div>,
						]
					}
					else {
						clipPath = "polygon(15px 0px, 100% 5px, calc(100% - 35px) calc(100% - 25px), 0px 100%)"
						clipWidth = "calc(100% + 35px)"
						fullHeight = { 
							...fullHeight,
							transform: "translateX(-17px) translateY(0px)",
						}
						afterCard = [
							<div
								key={1}
								style={{
									width: "calc(100% + 35px)",
									height: 37,
									transform: "translateX(0) translateY(-35px)",
									clipPath: "polygon(0px 35px, calc(100% - 35px) 0px, 100% 100%, 0px calc(100% - 0px))",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										width: "100%",
										height: 37,
										backgroundColor: "#00000044",
									}}
								/>
							</div>,
							<div
								key={2}
								style={{
									width: "calc(100% + 35px)",
									height: 47,
									transform: "translateX(0px) translateY(-35px)",
									clipPath: "polygon(0px 0px, calc(100% - 2px) 0px, 69% 100%, 26% calc(100% - 19px))",
									backgroundColor: getColor(color, palette), //color || "#FFF",
									position: "relative",
								}}
							/>,
							<div
								key={3}
								style={{
									width: "calc(100% + 35px)",
									height: 135,
									transform: "translateX(0px) translateY(-73px)",
									clipPath: "polygon(28% 19px, 69% 37px, 60% calc(100% - 0px))",
									backgroundColor: getColor(color, palette) || "#FFF",
									position: "relative",
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										width: "calc(100% + 35px)",
										// clipPath : "polygon(23% 17px, 69% 37px, 54% calc(100% - -30px))",
										backgroundColor: "#00000044",
										height: 135,
									}}
								/>
							</div>,
						]
					}
				}
				if (cliping == 7) // left top crop
				{
					clipPath = `polygon(${tension * 2}px  -${tension}px , 200% 0, 200% 200%, 0 200%, -${tension}px  ${tension * 2}px )`
				}
				if (cliping == 8) // right top crop
				{
					clipPath = `polygon(calc(100% - ${tension}px) 0, 100% ${tension}px, 100% 100%, 0 100%, 0 0)`
				}
				if (cliping == 9) // bottom left crop
				{
					clipPath = `polygon(100% 0, 100% 100%, ${tension}px 100%, 0 calc(100% - ${tension}px), 0 0)`
				}
				if (cliping == 10) // bottom right crop
				{
					clipPath = `polygon(calc(100% + ${tension}px) 0, calc(100% + ${tension}px) calc(100% - ${tension * 2}px), calc(100% - ${tension * 2}px) calc(100% + ${tension}px), 0 calc(100% + ${tension}px), 0 0)`
				}
				break
			default:
				const f = undefined
		}

		const mainClass = link_type && link_type !== "0"
			? "landing-card-cont pointer"
			: "landing-card-cont"
		// console.log( this.props )
		const stl = {
			width: this.state.width > 720 
				? 
				`${ 
					Array.isArray(inner_proportia) && inner_proportia[this.props.i] 
						?
						inner_proportia[this.props.i]	
						:
						100 / columns
				}%` 
				: 
				"100%",
			...fullPadding,
			zIndex: reverse_mounting,
			transform: `rotate(${this.rotation}deg)`,
			// borderRadius,
			borderTopLeftRadius: `${borderRadius}px`,
			borderTopRightRadius: `${borderTopRightRadius}px`,
			borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
			borderBottomRightRadius: `${borderBottomRightRadius}px`,
			...getStyle(this.state.card_style, this.state.palette),
		}

		let bgImg = is_back_image ? back_image : null
		if (is_card_img_youtube_thrum && back_image && is_back_image) {
			if (dialog_video_service == "vimeo") {
				$.ajax({
					type: "GET",
					url: `http://vimeo.com/api/v2/video/${dialog_vimeo_id}.json`,
					jsonp: "callback",
					dataType: "json",
					success(data) {
						const thumbnail_src = data[0].thumbnail_large
						$(`.landing-section-container > [data-id=${section_id}] > .landing-card`).css({ backgroundImage: `url(${thumbnail_src.toString()})` })
						//$(`#media_thumbnail_${rand}`).css({ backgroundImage: `url(${thumbnail_src.toString()})` })
					},
				})
			}
			else {
				bgImg = `https://img.youtube.com/vi/${dialog_youtube_id}/0.jpg`
			}

		}

		let muar = "";
		if (is_contrast_muar) {
			const lighten = bgImg
				?
				palette && palette.card && palette.card.color && _chroma(palette.card.color).hsl()[2] < .5
					?
					["rgba(255,255,255,0.22)", "rgba(255,255,255,1)"]
					:
					["rgba(0,0,0,0.22)", "rgba(0,0,0,1)"]
				:
				[]
			muar = `
			background: -moz-linear-gradient(top, ${lighten[0]} 0%, ${lighten[0]} 24%, ${lighten[1]} 95%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, ${lighten[0]} 0%,${lighten[0]} 24%, ${lighten[1]} 95%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, ${lighten[0]} 0%,${lighten[0]} 24%, ${lighten[1]} 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			`
		}
		
		const bgSize = is_joint
			?
			this.state.bckgroundSize
			:
			"cover!important"
		const bgPosition = is_joint
			?
			this.state.bckgroundPosition
			:
			" center " + back_image_vertical + "!important"

		const bgImageStyle = bgImg
			?
			`.landing-card::after
			{
				content:"";
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%; 
				background-image: url(${bgImg})!important;
				background-size: ${bgSize};
				background-position: ${bgPosition}; 
				opacity:${back_opacity ? back_opacity : 1};
				border-top-left-radius: ${borderRadius}px;
				border-top-right-radius : ${borderTopRightRadius ? borderTopRightRadius : borderRadius}px;
				border-bottom-left-radius : ${borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius}px;
				border-bottom-right-radius : ${borderBottomRightRadius ? borderBottomRightRadius : borderRadius}px;
				z-index:1;

			}
			.landing-card::before
			{
				content:"";
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%; 
				border-top-left-radius: ${borderRadius}px;
				border-top-right-radius : ${borderTopRightRadius ? borderTopRightRadius : borderRadius}px;
				border-bottom-left-radius : ${borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius}px;
				border-bottom-right-radius : ${borderBottomRightRadius ? borderBottomRightRadius : borderRadius}px;
				z-index:2;
				${muar}
			}`
			:
			`.landing-card
			{
				position:relative;
			}
			`

		return (
			<>
				<Waypoint
					bottomOffset={30}
					topOffset={0}
					onEnter={() => {  
						if(!this.state.isAnimated)
							this.setState({isAnimated: true})
					}} 
				> 
					<div
						className={mainClass + " position-relative " + animate}
						style={stl}
						card_template_id={this.state.section_id}
						card_id={this.state.i}
						id={this.state.div_id}
					>
						{
							/* тело карточки */
							Style.it(
								bgImageStyle,
								<div
									onClick={this.onCardClick}
									className={
										`landing-card ${vertical_align == "max" ? " h-100 " : ""
										}${class_name || ""}`
									}
									ref={this.ref}
									id={`card_${this.state.section_id}_${this.state.i}`}
									style={{
										...palette.card,
										...style,
										...fullHeight,
										borderRadius,
										borderTopLeftRadius: borderRadius,
										borderTopRightRadius: borderTopRightRadius ? borderTopRightRadius : borderRadius,
										borderBottomLeftRadius: borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius,
										borderBottomRightRadius: borderBottomRightRadius ? borderBottomRightRadius : borderRadius,
										boxShadow,
										background: getColor(color, palette),
										clipPath,
										transform: clipTransform,
										width: clipWidth,
										...getStyle(card_inner_style, this.state.palette)
									}}
								>
									{fieldsObjs}
									{this.linker()}
								</div>
							)
						}
						{
							/* Показать хвост */
							tail_type === "top_plate" && is_empty_tail_hide && !tail_text // показывать только если на хвосте есть текст
								?
								null
								:
								Style.it(
									aStyleHover,
									<div
										className="landing-card-after"
										style={aStyle}
									>
										{afterCard}
									</div>,
								)
						}
						<EditLabel
							onEdit={this.onCardEdit}
							isBtn={true}
							source="Card"
							st={{
								marginTop: padding,
								marginRight: padding,
								//position:"absolute", 
							}}
							children={null}
							is_edit={is_edit}
							data={this.state}
						/>
					</div>
				</Waypoint>
				<Dialog
					title={dialog_title}
					isOpen={this.state.isCardDialogOpen}
					onClose={this.onDialogClose}
					key={`${this.props.i}2`}
				>
					{
						typeof this.state.dialogContent == "string"
							? (
								<div
									className=""
									dangerouslySetInnerHTML={{ __html: this.state.dialogContent }}
								/>
							)
							: (
								<div className="">
									{this.state.dialogContent}
								</div>
							)
					}
				</Dialog>
				<Outer
					isOuterOpen={isOuterOpen}
					outerURL={this.state.inner_link_route}
					onOpen={this.onOuterClose}
				/>
			</>
		)
	}
	field = (e, i, icon) => {
		const {
			fields,
			border_radius,
			cardFields,
			dialog_youtube_id,
			dialog_vimeo_id,
			dialog_VK_frame,
			dialog_video_service,
			is_card_img_youtube_thrum,
			palette,
			back_image,
			decoration,
			border_top_right_radius,
			border_bottom_left_radius,
			border_bottom_right_radius,
		} = this.state
		//console.log( cardFields[i] )
		if (!cardFields || !cardFields[i]) 
		{
			return null
		}
			

		const {
			type,
			height,
			variant,
			is_hover,
			hover_color,
			hover_duration,
			hover_delay,
			hover_size,
			variant_param_1,
			variant_param_2,
			variant_param_3,
			variant_param_4,
			variant_param_5,
			variant_param_6,
			variant_param_7,
			variant_param_8,
			variant_param_9,
			variant_param_10,
		} = cardFields[i]

		//
		let dt

		//
		let hoverStyle

		//
		let class_name = ""

		//
		//console.log(cardFields[i].style)
		let style = { ...this.props.style }

		//стиль карточки в целом (контейнер карточки)
		let bStyle = { 
			height: height != 0 
				? 
				`${height}px` 
				:
				cardFields[i].style &&	cardFields[i].style.height
					?
					cardFields[i].style.height
					: 
					"auto" 
			}
		const boderRadius = border_radius || 0
		let maskData = ""
		const borderTopRightRadius = (!decoration || decoration == 1) && border_top_right_radius
			?
			border_top_right_radius
			:
			boderRadius
		const borderBottomLeftRadius = (!decoration || decoration == 1) && border_bottom_left_radius
			?
			border_bottom_left_radius
			:
			boderRadius
		const borderBottomRightRadius = (!decoration || decoration == 1) && border_bottom_right_radius
			?
			border_bottom_right_radius
			:
			boderRadius

		if (i == 0) {
			style = {
				...style,
				borderTopLeftRadius: boderRadius,
				borderTopRightRadius
			}
			bStyle = {
				borderTopLeftRadius: boderRadius,
				borderTopRightRadius
			}
		}
		if (i == fields.length - 1) {
			style = {
				...style,
				borderBottomLeftRadius,
				borderBottomRightRadius
			}
			bStyle = {
				borderBottomLeftRadius,
				borderBottomRightRadius
			}
		}

		/*============================================
			*
			*	КОСТЫЛИ
			*
		===============================================*/
		/* string */
		if ((type == "string" && variant == 2) || (type == "empty" && variant == 2))// corner
		{
			// console.log( cardFields[i] );
			let pullEdge = {}
			if (variant_param_2) {
				switch (variant_param_1) {
					case "2":
					case 2:
						pullEdge = { top: "auto", bottom: 0, position: "absolute", left: "auto", right: 0 }
						break
					case "3":
					case 3:
						pullEdge = { top: "auto", bottom: 0, position: "absolute" }
						break
					case "0":
					case 0:
						pullEdge = { top: 0, bottom: "auto", position: "absolute" }
						break
					case "1":
					case 1:
					default:
						pullEdge = { top: 0, bottom: "auto", position: "absolute", left: "auto", right: 0 }
						break
				}
			}
			switch (variant_param_3) {
				case "pennon":
					bStyle = {
						...bStyle,
						overflow: "hidden",
					}
			}
			bStyle = {
				...bStyle,
				...pullEdge,
				borderLeftColor: cardFields[i].bgcolor,
				height: cardFields[i].height != 0 
					? 
					cardFields[i].height 
					: 
					"auto",
				width: cardFields[i].height != 0 ? cardFields[i].height : "auto",
				pointerEvents: "none",
				borderRadius: 0,
			}
		}
		if ((type == "string" && variant == 4) || (type == "empty" && variant == 4))// dart
		{
			const arroHeadwWidth = cardFields[i].variant_param_1
			const pinWidth = cardFields[i].variant_param_2
			const arroHeadHeight = cardFields[i].variant_param_3 || 1
			bStyle = {
				...bStyle,
				height: cardFields[i].height
				// width: "calc(100% - " + ( arroHeadwWidth ) + "px)"
			}
		}

		/* media */
		// медаль
		if (type == "media" && variant == 1) {
			style = {

			}
		}
		if (type == "media" && variant == 2) {
			const crop = cardFields[i].variant_param_1
			const sz = cardFields[i].variant_param_2
			switch (crop) {
				case "1":
					bStyle.clipPath = `polygon(100% 0, 100% 100%, 0 100%, 0 ${sz}px, ${sz}px 0)`
					break
				case "2":
					bStyle.clipPath = `polygon(calc(100% - ${sz}px) 0, 100% ${sz}px, 100% 100%, 0 100%, 0 0)`
					break
				case "3":
					bStyle.clipPath = `polygon(100% 0, 100% 100%, ${sz}px 100%, 0 calc(100% - ${sz}px), 0 0)`
					break
				case "4":
				default:
					bStyle.clipPath = `polygon(100% 0, 100% calc(100% - ${sz}px), calc(100% - ${sz}px) 100%, 0 100%, 0 0)`
					break
			}
		}
		if (type == "media" && variant == 3) // sausage
		{
			if (this.props.i == 0) {
				bStyle.borderRadius = `0 ${height}px ${height}px 0`
			} else if (this.props.i == this.props.columns - 1) {
				bStyle.borderRadius = `${height}px  0 0 ${height}px`
			} else {
				bStyle.borderRadius = `${height}px`
			}
		}
		if (
			(type == "media" && variant == 4) ||
			(type == "string" && variant == 7) ||
			(type == "empty" && variant == 7)
		) // Monolog
		{
			const tongue = parseInt(variant_param_2) || 20
			const tongue_pos = parseInt(variant_param_3) || 50
			bStyle.clipPath = `polygon(0% 0%, 100% 0%, 100% calc(100% - ${tongue}px), calc(${tongue_pos}% + ${tongue}px) calc(100% - ${tongue}px), ${tongue_pos}% 100%, calc(${tongue_pos}% - ${tongue}px) calc(100% - ${tongue}px), 0% calc(100% - ${tongue}px))`
			bStyle.padding = 0
			bStyle.marginBottom = -tongue
			bStyle.zIndex = 100
		}

		if (type == "media" && variant == 5) // SVG Mask
		{
			const mask = variant_param_1
			bStyle.padding = 0
			bStyle.zIndex = 100
			bStyle.display = "none"
			maskData = (
				<SVGClip
					svgClipID={`card_clip_mask_${this.props.section_id}_${this.props.i}_${i}`}
					i={i}
					width="100%"
					height={200}
					image={e.field}
					mask={mask}
					section_id={this.props.section_id}
				/>
			)
			/*
					maskData = <svg width="100%" height="200" viewBox={ "0 0 141 141" } style={{ top:0 }}>
						<defs>
							<clipPath id={"card_clip_mask_" + this.props.i + "_" + i } >
								<path fill="#FFF" d="M7.9,115.5C-12.3,88.3,21.4,1.9,75.5,1.9c33.3,0,90.5,87.7,51.4,119.8C85.7,155.4,23.4,136.2,7.9,115.5z"/>
							</clipPath>
						</defs>
						{
							cardFields[i].imageProportia && cardFields[i].imageProportia <  1
								?
								<image
									xlinkHref={ e.field }
									x="0"
									y="0"
									width="100%"
									style={{ clipPath: "url(#card_clip_mask_" + this.props.i + "_" + i + ")" }}
								/>
								:
								<image
									xlinkHref={ e.field }
									x="0"
									y="0"
									height="100%"
									style={{ clipPath: "url(#card_clip_mask_" + this.props.i + "_" + i + ")" }}
								/>
						}
	
					</svg>
					*/
			// console.log( this.props );
		}

		// Если последующее или предыдущее поля - медаль
		if (cardFields[i - 1] && cardFields[i - 1].type == "media" && cardFields[i - 1].variant == 1) {
			style = {
				...style,
				marginTop: parseInt(cardFields[i - 1].height / 2) + 10,
			}
		}
		if (cardFields[i + 1] && cardFields[i + 1].type == "media" && cardFields[i + 1].variant == 1) {
			style = {
				...style,
				marginBottom: parseInt(cardFields[i + 1].height / 2) + 10,
			}
		}


		/*				
		определяем цвет шрифта. 
		Если цвет карточки прозрачный - шрифт красим из pallete.main_text_color.
		Если нет - palette.card.color
		*/
		if (!bStyle.color) {
			const plainColor = getPlainColor(this.state.color, palette)
			// console.log( this.state.color, plainColor )
			if (
				this.state.color === "transparent" ||
				(plainColor ? _chroma(plainColor).alpha() < .33 : false)
			) 
			{
				bStyle.color = palette
					?
					palette.main_text_color
					:
					null
			}
			else {
				bStyle.color = palette && palette.card
					?
					palette.card.color
					:
					null
			}
		}


		/*========================================
			*
			*
			*	КОНЕЦ КОСТЫЛЕЙ
			*
		========================================== */

		if (cardFields[i].height) {
			style = { ...style, height: cardFields[i].height != 0 ? `${cardFields[i].height}px` : "auto" }
		}
		if (cardFields[i].bgcolor) {
			bStyle = { ...bStyle, backgroundColor: getColor(cardFields[i].bgcolor, palette) }
		}
		if (cardFields[i].class_name) {
			class_name = cardFields[i].class_name
		}

		if (cardFields[i].style) {
			const bst = getStyle(cardFields[i].style, palette)
			bStyle = {
				...bStyle,
				...bst,
			}
		}

		const empty = e.field ? "" : " empty "
		const hs = is_hover
			?
			`
				.landing-card-field::after  {
					transition: background-color ${hover_duration}ms ease-out;
					transition-delay:${hover_delay}ms;
				}
				.landing-card:hover .landing-card-field::after  {
					background-color: ${hover_color};
				}
			`
			:
			""
		let clr
		const vars = [
			"primary",
			"secondary",
			"active",
			"danger",
			"success",
			"warning"
		]
		switch (type) {
			case "person":
				dt = <CardFieldPerson {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					cardFields,
					bgColor: this.state.color,
					palette
				}}
				/>
				hoverStyle = hs
				break
			case "tag":
				dt = <CardFieldTag {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					cardFields,
					bgColor: this.state.color,
					palette
				}} />
				hoverStyle = hs
				break
			case "check":
				dt = <CardFieldCheckLabel {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					cardFields,
					bgColor: this.state.color,
					palette
				}} />

				const bgColor = this.state.color === "transparent" ? "#121111" : getPlainColor(this.state.color, palette)
				bStyle.borderBottomColor = bgColor && _chroma(bgColor).get('hsl.l') < .5
					?
					"#FFFFFF20"
					:
					"#00000020"

				break
			case "icon":
				hoverStyle = is_hover
					?
					`				
					.landing-card:hover .icon0 .string
					{
						color: ${hover_color ? hover_color + "!important" : null};
						border-color: ${hover_color ? hover_color : null};	
						transform:scale( ${hover_size ? hover_size : null} );
					}
					.landing-card .icon0 .string
					{
						transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
						transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
					}
					`
					:
					`
					.landing-card .icon0 .string
					{
						transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
						transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
					}
					`
				//console.log( this.props.ID )
				dt = <>
					<CardFieldIcon {...{
						e,
						i,
						cardFieldsData: cardFields[i],
						style,
						class_name,
						bStyle,
						cardFields,
						palette,
						ID : this.props.ID
					}} />
				</>
				break
			case "empty":
				dt = <CardFieldEmpty {...{
					e,
					i,
					bStyle,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					dialog_youtube_id,
					dialog_vimeo_id,
					cardFields,
					dialog_video_service,
					is_card_img_youtube_thrum: back_image ? null : is_card_img_youtube_thrum,
					palette
				}} />
				break
			case "media":
				//dt = this.media(e, i, cardFields[i], style, class_name, bStyle)

				dt = <CardFieldMedia {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					dialog_youtube_id,
					dialog_vimeo_id,
					dialog_VK_frame,
					cardFields,
					dialog_video_service,
					is_card_img_youtube_thrum: back_image ? null : is_card_img_youtube_thrum,
					palette
				}} />
				hoverStyle = is_hover
					?
					`				
				.landing-card:hover .landing-card-field .media
				{	
					transform:scale( ${hover_size ? hover_size : null} );
				}
				.landing-card .landing-card-field .media
				{
					transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
					transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
				}
				`
					:
					`
				.landing-card .landing-card-field .media
				{
					transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
					transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
				}
				`
				break
			case "cf":
				dt = this.cf(e, i, cardFields[i], style, class_name, bStyle)
				hoverStyle = hs
				break
			case "navlink":
			// 	clr = parseInt(variant) !== 4
			// 		&& palette.button[vars[parseInt(variant)]]
			// 		?
			// 		variant == 0
			// 			?
			// 			variant_param_1
			// 				?
			// 				{
			// 					background: variant_param_1,
			// 					color: "#EEE"
			// 				}
			// 				:
			// 				palette.button[vars[parseInt(variant)]]
			// 			:
			// 			palette.button[vars[parseInt(variant)]]
			// 		:
			// 		{
			// 			background: "#ffffff00",
			// 			color: palette.card.color
			// 		}
			// 	hoverStyle = `					
			// 		.landing-card-field .navv
			// 		{
			// 			background-color: ${variant_param_6 ? "#00000000" : clr.background};
			// 			border-color: ${variant_param_6 ? clr.color : _chroma(getPlainColor(clr.background, palette)).darken(1.1)};
			// 			color:${variant_param_6 ? clr.background : clr.color};
			// 			width:${variant_param_3 ? `100%` : 'auto'};
			// 			padding:${variant_param_4 ? '24px' : null};
			// 			border-radius: ${typeof variant_param_5 !== "undefined" ? variant_param_5 + "px" : null}
			// 		} 
			// 		landing-card-field. .navv:hover
			// 		{
			// 			background-color: ${variant_param_6 ? clr.color : _chroma(getPlainColor(clr.background, palette)).darken(1.1)};
			// 			border-color: ${variant_param_6 ? clr.color : _chroma(getPlainColor(clr.background, palette)).darken(1.1)};
			// 			color: ${variant_param_6 ? "#000" : clr.color};
			// 		} 					
			// 		`
			// 	hoverStyle = is_hover
			// 		?
			// 		hoverStyle + `					
			// 		.landing-card:hover .landing-card-field .navv 
			// 		{
			// 			background-color: ${hover_color};
			// 			border-color: ${hover_color};
			// 			transition: background-color ${hover_duration}ms ease-out;
			// 			transition-delay:${hover_delay}ms;	
			// 		}
			// 		`
			// 		:
			// 		null
			// 	dt = <CardFieldNavlink {...{
			// 		e,
			// 		i,
			// 		cardFieldsData: cardFields[i],
			// 		style,
			// 		class_name,
			// 		bStyle,
			// 		palette
			// 	}} />

			// 	break
			case "outerlink":
				clr = parseInt(variant) !== 4
					&& palette.button[vars[parseInt(variant)]]
					?
					variant == 0
						?
						variant_param_1
							?
							{
								background: variant_param_1,
								color: "#EEE"
							}
							:
							palette.button[vars[parseInt(variant)]]
						:
						palette.button[vars[parseInt(variant)]]
					:
					{
						background: "#ffffff00",
						color: palette.card.color
					}
				hoverStyle = `					
					.navv
					{
						background-color: ${variant_param_6 ? "#00000000" : clr.background};
						border-color: ${ _chroma(clr.background).darken(1.1) };
						color:${variant_param_6 ? clr.background : _chroma(clr.color)};
						width:${variant_param_3 ? `100%` : 'auto'};
						padding:${variant_param_4 ? '24px' : null};
						border-radius: ${typeof variant_param_5 === "undefined" ? variant_param_5 : null}
					} 
					.navv:hover
					{
						background-color: ${ _chroma(clr.background).darken(1.1) };
						border-color: ${ _chroma(clr.background).darken(1.1) };
						color: ${_chroma( clr.color )};
					} 
					.landing-card:hover .navv 
					{
						background-color: ${hover_color};
						border-color: ${hover_color};
						transition: background-color ${hover_duration}ms ease-out;
						transition-delay:${hover_delay}ms;	
					}					
					`
				hoverStyle = is_hover
					?
					hoverStyle + `				
					.landing-card:hover .landing-card-field.outerlink1 .navv
					{
						background-color: ${hover_color};
						border-color: ${hover_color};
						transition: background-color ${hover_duration}ms ease-out;
						transition-delay:${hover_delay}ms;	
					}
					`
					:
					null
				dt = <CardFieldOuterlink {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					palette
				}} />
				break
			case "price":
				hoverStyle = is_hover
					?
					`				
					.landing-card:hover .landing-card-field
					{
						background-color: ${hover_color};
						transition: background-color ${hover_duration}ms ease-out;
						transition-delay:${hover_delay}ms;
					}
					.landing-card .landing-card-field .price
					{
						transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
						transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
					}
					.landing-card:hover .landing-card-field .price
					{ 
						transform:scale( ${hover_size ? hover_size : null} );
					}
					`
					:
					`
					.landing-card .landing-card-field .price
					{
						transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
						transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
					}
					`
				dt = <CardFieldPrice {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					palette
				}} />
				break
			case "personal_links":
				//dt = this.personal_links(e, i, cardFields[i], style, class_name, bStyle)
				dt = <CardFieldPersonalLinks
					{...{ e, i, cardFieldsData: cardFields[i], style, class_name, bStyle, palette }}
				/>
				hoverStyle = is_hover
					?
					`				
					.landing-card:hover .landing-card-field.personal_links1 .lcard-pl,
					.landing-card:hover .landing-card-field.personal_links2 .lcard-pl,
					.landing-card:hover .landing-card-field.personal_links3 .lcard-pl
					{
						background-color: ${hover_color};
						transition: background-color ${hover_duration}ms ease-out;
						transition-delay:${hover_delay}ms;
					}`
					:
					"";
				break
			case "divider":
				bStyle.height = height != 0 ? bStyle.height : 2
				bStyle.width = variant_param_1 ? `${variant_param_1}%` : "100%"
				bStyle.margin = "0 auto"
				dt = this.divider(e, i, cardFields[i], style, class_name, bStyle)
				hoverStyle = is_hover
					?
					`
					.landing-card-field::after  {
						transition: background-color ${hover_duration}ms ease-out;
					}
					.landing-card:hover .divider  {
						background-color: ${hover_color};
						transition-delay:${hover_delay}ms;
					}
					`
					:
					""

				// console.log( bStyle )
				break
			case "section":
				dt = this.section(e, i, cardFields[i], style, class_name, bStyle)
				hoverStyle = hs
				break
			case "string":
			default: 
				// console.log(this.state )	

				dt = <CardFieldString {...{
					e,
					i,
					cardFieldsData: cardFields[i],
					style,
					class_name,
					bStyle,
					palette,
					id: this.props.ID
				}} />
				hoverStyle = `		
				.landing-card-field::after  
				{
					transition: background-color ${hover_duration}ms ease-out;
					transition-delay:${hover_delay}ms;
					border-radius: ${bStyle.borderRadius};
					border-top-left-radius: ${bStyle.borderTopLeftRadius};
					border-top-right-radius: ${bStyle.borderTopRightRadius};
					border-bottom-left-radius: ${bStyle.borderBottomLeftRadius};
					border-bottom-right-radius: ${bStyle.borderBottomRightRadius};
				}
				.landing-card:hover .landing-card-field::after  
				{
					background-color: ${hover_color};
				}		
				.landing-card:hover .landing-card-field .string
				{	
					transform: scale( ${hover_size ? hover_size : null} );
				}
				.landing-card .landing-card-field .string
				{
					transition: all ${hover_duration ? hover_duration : 0}ms ease-out!important;
					transition-delay: ${hover_delay ? hover_delay + "" : 0}ms!important;
				}
				`
		}

		return (
			<>
				{
					is_hover
						?
						Style.it(
							hoverStyle,
							<div
								className={ this.getFieldClasses( cardFields[i] ) + empty }
								field_id={i}
								style={bStyle}
								key={i}
							>
								{dt}
								{icon}
							</div>,
						)
						:
						<div
							className={ this.getFieldClasses( cardFields[i] ) + empty }
							style={bStyle}
							field_id={i}
							key={i}
						>

							{dt}
							{icon}
						</div>

				}
				<>
					{maskData}
				</>
			</>
		)
	}
	getFieldClasses = (cardFieldData) =>
	{
		let class_names = "landing-card-field "
		if(!cardFieldData) 	return class_names
		if(cardFieldData.class_name)
		{
			class_names += cardFieldData.class_name + " "
		}

		class_names += cardFieldData.type + cardFieldData.variant + " "

		for(var a = 1; a < 10; a++ )
		{
			if(typeof cardFieldData["variant_param_" + a] === "string")
			{
				class_names += ` landing-card-variant_param_1${cardFieldData["variant_param_" + a]}`
			}
		}
		
		return class_names
	}
	cf(e, i, cardFieldsData, style, class_name) {
		let btnClass
		// console.log(cardFieldsData.variant);
		switch (cardFieldsData.variant) {
			case "2":
				btnClass = "btn-light"
				break
			case "3":
				btnClass = "btn-dark"
				break
			case "4":
				btnClass = "btn-danger"
				break
			case "1":
			default:
				btnClass = " "
				break
		}

		// label and url
		const datas = typeof e.field !== "undefined"
			? (e.field).split(CARD_SEPARATOR())
			: ["", ""]
		return (
			<div
				className=" cf "
			>
				<div
					className="btn btn-primary"
				>
					{datas[1]}
				</div>
			</div>
		)
	}


	divider(e, i, cardFieldsData, style, class_name) {
		// console.log( cardFieldsData.variant, cardFieldsData );

		const {
			dialog_youtube_id,
			dialog_vimeo_id,
			cardFields,
			dialog_video_service,
			is_card_img_youtube_thrum,
		} = this.props

		const bg = e.field
		let st = {
			...style,
			...getStyle(cardFieldsData.variant_param_4, this.state.palette),
		}
		// если круг
		switch (cardFieldsData.variant) {
			case 1:
			case "1":
				st.top = "auto"
				st.minWidth = `${cardFieldsData.height}px`
				st.width = `${cardFieldsData.height}px`
				st.marginLeft = -parseInt(cardFieldsData.height) / 2
				break
			case 2:
			case "2":

				break
			default:
				st = st
				break
		}

		return (
			<div
				className=" divider "
				style={{ ...st, backgroundImage: `url(${bg})` }}
			/>
		)
	}

	section(e, i, cardFieldsData, style, class_name) {
		//console.log(cardFieldsData) 
		const sectionData = sectionDataParse(e.field)
		const sections = sectionData.map((section, index) => (
			<Section
				key={index}
				{...section}
				background={{
					...section.background,
					color : "#FFFFFF00"

				}}
				style={{ height: "100%", ...getStyle(section.style, this.state.palette) }}
				i={index}
				user={this.props.user}
				is_edit={false}
			/>
		))
		const classes = ` landing-card-type-section ${class_name | ""} variant_param_1_${ cardFieldsData.variant_param_1}`
		return (
			<div className={classes}>
				{sections}
			</div>
		)
	}

	personal_links(e, i, cardFieldsData, style, class_name) {
		const links = typeof e.field == "string" ? e.field.split(CARD_SEPARATOR()) : []
		const is_vertical = cardFieldsData.variant_param_1
			?
			" d-block "
			:
			""
		const hover_class = cardFieldsData.variant_param_2 || "none"
		//const 
		const btns = links.map((ee, ii) => {
			let fa = "fas fa-link"
			if (ee.indexOf("facebook.com") >= 0) {
				fa = "fab fa-facebook-f"
			} else if (ee.indexOf("vk.com") >= 0) {
				fa = "fab fa-vk"
			} else if (ee.indexOf("youtube.com") >= 0) {
				fa = "fab fa-youtube"
			} else if (ee.indexOf("vimeo.com") >= 0) {
				fa = "fab fa-vimeo-v"
			} else if (ee.indexOf("behance.net") >= 0) {
				fa = "fab fa-behance"
			} else if (ee.indexOf("blogger.com") >= 0) {
				fa = "fab fa-blogger"
			} else {
				fa = "fas fa-link"
			}
			return (
				<a
					href={ee}
					key={ii}
					className="lcard-pl"
					target="_blank"
					rel="noreferrer"
					onClick={evt => evt.stopPropagation()}
				>
					<span className={`${fa} `} />
				</a>
			)
		})
		return (
			<div className={" personal_links " + is_vertical + " pl" + hover_class}>
				{btns}
			</div>
		)
	}

	linker() {
		const { dialog_inner_route, dialog_btn_label } = this.props
		if (!dialog_inner_route) return
		return (
			<div className="card-linker">
				<Link
					to={dialog_inner_route}
					className="btn "
				>
					{__(dialog_btn_label)}
				</Link>
			</div>
		)
	}

	onCardClick = (evt) => {
		const {
			link_type,
			dialog_title,
			dialog_route_content,
			dialog_content,
			dialog_content_type,
			dialog_inner_route,
			dialog_btn_label,
			dialog_youtube_id,
			dialog_vimeo_id,
			dialog_VK_frame,
			dialog_video_service,
			dialog_sections,
			inner_link_route,
			over_link_route
		} = this.state
		console.log(this.state)
		if (link_type) {
			switch (link_type) {
				case "1": // Inner Link  
					this.onOuterClose({}, over_link_route);
					//this.props.history.push(inner_link_route)
					break
				case "2": // Over site 
					const body = document.getElementsByTagName('body')[0]
					const element = document.createElement("a");
					body.appendChild(element);
					element.href = over_link_route
					element.target = "_blank"
					element.click()
					body.removeChild(element);
					break
				case "3": // Dialog content
					let cntnt = ""
					switch (dialog_content_type) {
						case "video":
							if (dialog_video_service === "vimeo") {
								cntnt = (
									<iframe
										className=""
										src={`https://player.vimeo.com/video/${dialog_vimeo_id}?autoplay=1`}
										width="100%"
										height={480}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									/>
								)
							} 
							else if(dialog_video_service === "youtube")
							{
								cntnt = (
									<iframe
										width="100%"
										height={480}
										className=""
										style={{}}
										src={`https://www.youtube.com/embed/${dialog_youtube_id}?autoplay=1`}
										frameBorder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									/>
								)
							}
							else if( dialog_video_service === "vk" )
							{
								cntnt = (
									<div className="vk-video-cont" style={{ width: "100%", height: "480px" }}>
										{ getVKLink( dialog_VK_frame ) }
									</div>
								)

							}

							break
						case "inner_link":
							cntnt = (
								<ContentByRoute
									routing={dialog_route_content}
								/>
							)
							break
						case "sections":
							cntnt = dialog_sections.map((section, index) => (
								<Section
									key={index}
									{...section}
									background={{
										...section.background,
										color : "#FFFFFF00"

									}}
									style={{ height: "100%", ...getStyle(section.style, this.state.palette) }}
									i={index}
									user={this.props.user}
									is_edit={this.state.is_edit}
									level={this.props.level + 1}
									onEdit={this.onEdit}
									onUp={this.onUp}
									onDn={this.onDn}
									onAdd={this.onAdd}
									onRnv={this.onRnv}
									isDummy={false}
								/>
							))

							break
						default:
						case "text_content":
							cntnt = <div className="p-5" dangerouslySetInnerHTML={{ __html: dialog_content }} />
					}
					this.setState({
						isCardDialogOpen: true,
						dialogContent: cntnt,
						dialog_btn_label,
						dialog_inner_route,
					})
					break
				default:
				case "0": // Nothing

					break
			}
		}
	}
	onOuterClose = (evt) => {

		this.setState({ isOuterOpen: !this.state.isOuterOpen })
		setTimeout(() => {
			$("body").css("overflowY", this.state.isOuterOpen ? "hidden" : "auto")
		}, 100)
	}
	onDialogClose = () => {
		if (this.state.isCardDialogOpen)
			$("body").css("overflowY", "auto")
		else
			$("body").css("overflowY", "hidden")
		this.setState({
			isCardDialogOpen: !this.state.isCardDialogOpen,
		})
	}
	//Edit as is edit
	onCardEdit = data => {
		//console.log("onCardEdit", data, this.state)
		const state = {
			...this.state,
			...data,
			//isCardDialogOpen: false,
			is_edit: false
		}
		//console.log("onCardEdit result", state)
		this.setState(state)
		if (this.props.onCardEdit) {
			this.props.onCardEdit(state)
		}
	}
	// sections
	onEdit = (data, id) => {
		console.log("onEdit", id, data, this.state)
		const sections = [...this.state.data.sections]
		const secs = []
		sections.forEach((e) => {
			if (e.id == data.id) {
				secs.push(data)
			} else {
				secs.push(e)
			}
		})
		this.setState({ data: { ...this.state.data, sections: secs } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections: secs } },
			this.props.id,
		)
	}

	onUp = (data) => {
		console.log("onUp", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data - 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onDn = (data) => {
		console.log("onDn", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data + 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onAdd = (data) => {
		console.log("onAdd", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = getDefault()
		sections.splice(data + 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onRnv = (data) => {
		console.log("onRnv", data, this.state.data.sections)
		const sections = [...this.state.data.sections]
		sections.splice(data, 1)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id, 
		)
	}
}
export default compose(withRouter)(Card)

