import React from "react"
import { CARD_SEPARATOR } from "../Card"
import { getStyle } from "../Section"

const CardFieldPerson = ({ e, i, cardFieldsData, style, class_name, bStyle, cardFields, palette }) => 
{
    let handledStyle = { ...cardFieldsData.style }
    //[ display_name, description, avatar ]
    let fieldData = e.field
        ?
        e.field.split(CARD_SEPARATOR())
        :
        ["name", "meta", ""]
    
    delete handledStyle.border
    delete handledStyle.borderBottom
    // console.log(fieldData)
    const size = cardFieldsData.variant_param_2
        ?
        cardFieldsData.variant_param_2
        :
        100
    const rounded = cardFieldsData.variant_param_3
        ?
        cardFieldsData.variant_param_3
        :
        100
    const thumbStyle = cardFieldsData.variant_param_4
        ?
        getStyle(cardFieldsData.variant_param_4, palette)
        :
        {}
    const nameStyle = cardFieldsData.variant_param_5
        ?
        getStyle(cardFieldsData.variant_param_5, palette)
        :
        {}
    const metaStyle = cardFieldsData.variant_param_6
        ?
        getStyle(cardFieldsData.variant_param_6, palette)
        :
        {}
    
    //console.log("name style: ", nameStyle)

    const compactVariant = () => {
        return <div className="d-flex flex-grow-100 align-items-center m-0">
            {
                fieldData[2]
                    ?
                    <div
                        className="avatar"
                        style={{
                            width: size,
                            minWidth: size,
                            height: size,
                            borderRadius: rounded + "%",
                            backgroundPosition:"center",
                            backgroundImage: "url(" + fieldData[2] + ")",
                            backgroundSize: "cover",
                            flexGrow: 1,
                            margin: 20,
                            ...thumbStyle
                        }}
                    >
                    </div>
                    :
                    null
            }
            <div
                className="ml-3 flex-grow-1 d-flex flex-column justify-content-center align-items-start"

            >
                <div
                    className="title"
                    style={{
                        textAlign: "left",
                        ...nameStyle
                    }}
                    dangerouslySetInnerHTML={{__html: fieldData[0]}}
                >
                   
                </div>
                <div
                    className="small "
                    style={{
                        textAlign: "left",
                        ...metaStyle
                    }}
                    dangerouslySetInnerHTML={{__html: fieldData[1]}}
                >
                    
                </div>
            </div>

        </div>
    }
    const sparseVariant = () => {
        return <div className="d-flex flex-column align-items-center m-0">
            {
                fieldData[2]
                    ?
                    <div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: rounded + "%",
                            backgroundImage: "url(" + fieldData[2] + ")",
                            backgroundSize: "cover",
                            backgroundPosition:"center",
                            flexGrow: 1,
                            margin: 20,
                            ...thumbStyle
                        }}
                    >

                    </div>
                    :
                    null
            }            
            <div
                className="ml-3 flex-grow-100- d-flex flex-column justify-content-center "

            >
                <div
                    className="title"
                    style={nameStyle}
                    dangerouslySetInnerHTML={{__html: fieldData[0]}}
                >
                   
                </div>
                <div
                    className="small "
                    style={metaStyle}
                    dangerouslySetInnerHTML={{__html: fieldData[1]}}
                >
                    
                </div>
            </div>

        </div>
    }
    return cardFieldsData.variant == 1
        ?
        sparseVariant()
        :
        compactVariant()

}
export default CardFieldPerson