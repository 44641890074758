import React from "react"
import { Link } from "react-router-dom"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import Style from "style-it"
import chroma from "chroma-js"

class InnerLink extends SectionContent {
	getState() {
		return { ...this.props }
	}

	is() {
		return this.state.data.route
	}

	componentDidUpdate(nextProps) {
		let isUpdate = false
		const state = {};
		["is_edit", "data", "class_name", "type", "title"]
			.forEach((e, i) => {
				if (nextProps[e] != this.state[e]) {
					isUpdate = true
					state[e] = nextProps[e]
				}
			})
		if (isUpdate) {
			// console.log(state);
			this.setState(state)
		}
	}
	getIntent() {
		const { intent } = this.state.data
		switch (intent) {
			case "primary":
				return this.getPallete("button.active.background")
			case "secondary":
				return this.getPallete("button.success.background")
			case "warning":
				return this.getPallete("button.warning.background")
			case "danger":
				return this.getPallete("button.danger.background")
			case "white":
				return "#EEE"
			case "dark":
				return "#444"
			case "transparent":
			default:
				return "#EEEEEE00"
		}
	}
	getIntentFont() {
		const { intent } = this.state.data
		let clr = ""
		switch (intent) {
			case "primary":
				clr = this.getPallete("button.active.color")
				break
			case "secondary":
				clr = this.getPallete("button.success.color")
				break
			case "warning":
				clr = this.getPallete("button.warning.color")
				break
			case "danger":
				clr = this.getPallete("button.danger.color")
				break
			case "white":
				clr = "#111"
				break
			case "dark":
				clr = "#FFF"
				break
			default:
			case "transparent":
				clr = this.getPallete("main_text_color")
		}
		console.log( clr )
		return clr
	}
	renderContent(style) {
		const { title, type } = this.state
		const {
			route, label, is_fill, class_name, intent
		} = this.state.data
		// console.log( style );
		const intnt = `.landing-inner-btn {
			background-color: ${this.getIntent()};
			color:${chroma(this.getIntentFont()).alpha(.8)}!important;
		}
		.landing-inner-btn:hover {
			background-color: ${chroma(this.getIntent()).darken(.5)};
			color:${this.getIntentFont()}!important;
			
		}`;
		return <div
			className="landing-inner-link  landing-section-core "
			style={{ ...style }}
		>
			{
				Style.it(
					intnt,
					<Link
						to={`/${route}`}
						className={`landing-inner-btn btn ${is_fill ? " btn-block " : ""} ${class_name}`}
					>
						<div>
							{label || (title && title.text ? title.text : "")}
						</div>
					</Link>
				)
			}
		</div>
	}
}
export default InnerLink
