import React from 'react'
import PropTypes from 'prop-types'
import DataContext from '../DataContext'
import LandingMouseWeelScroll from './LandingMouseWeelScroll'

const LandingLineFlow = props => {
    switch(DataContext.data.landing.landing_flow)
    {
        case "mouse_weel_scroll":
            return <LandingMouseWeelScroll
                containerClassName={ props.containerClassName }
                containerStyle={ props.containerStyle }
                user={props.user}
            >
            {
                props.children
            }
            </LandingMouseWeelScroll> 
        case "line":
        default:
            return <div className={` ${ props.containerClassName } `}  style={props.containerStyle}>
            {
                props.children
            }
            </div>

    }
}

LandingLineFlow.propTypes = {}

export default LandingLineFlow