import React, { Component, Fragment } from "react"
import $ from "jquery"
import xml2js, { parseString, Builder } from "xml2js"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import svg_libs from "./data/svg_lib.json"

class Mask extends SectionContent {
	state = {
	  ...this.props,
	  is_edit: false,
	  loading: true,
	  isLoad: false,
	  m_size: { ...this.props.data.mask_position },
	  c_size: { ...this.props.data.content_position },
	  mask_data: "",
	}

	didUpdate(state, nextProps, nextState) {
	  if (!this.state.isLoad) {
	    if (!nextState.loading) {
	      $(`#landing-mask-${this.state.id}`).find("path[mask],circle[mask],rect[mask]").each((i, e) => {
	        $(e).attr("fill", this.state.data.fill)
	      })
	      //
	      // console.log( $("#landing-mask-" + this.state.id ).find("path,circle,rect").length )
	      // console.log( $("[data-id='" + this.state.id  + "'] .landing-mask-container").width() )
	      const dw = $(`[data-id='${this.state.id}'] .landing-mask-container`).width()
	      if (this.state.m_size.width.indexOf("%") != -1) {
	        state.m_size = {
	          ...this.state.m_size,
	          width: `${dw * parseInt(this.state.data.mask_position.width) / 100}px`,
	        }
	      } else {
	        state.m_size = {
	          ...this.state.m_size,
	          //  width : dw + "px"
	        }
	      }
	      // console.log( dw, this.state.m_size )
	      state.isLoad = true
	    }
	  }
	  return state
	}

	componentDidMount() {
	  const { height, mask_src, content } = this.state.data
	  const { m_size } = this.state
	  const { c_size } = this.state
	  if (mask_src) {
	    const _this = this
	    fetch(`/assets/img/landing/masks/${this.getSvgByID(mask_src).src}.xml`)
    			.then((response) => response.text())
   					.then((textResponse) => {
	        if (textResponse) {
	          parseString(
	            textResponse,
	            { trim: true },
	            (err, result) => {
	              _this.setState({
	                loading: false,
	                data: {
	                  ...this.state.data,
	                  mask_data: textResponse,
	                },
	                mask_width: result.g.$.width,
	                mask_height: result.g.$.height,

	              })
								    },
	          )
	        }
					   })
    					.catch((error) => {
       						 console.log(error)
    					})
	    //
	    const newImg = new Image()
	    newImg.onload = function () {
	      // console.log(`${this.width} x ${this.height}`)
	      let cw; let
	        ch
	      if (c_size.width.indexOf("%") != -1) {
	        cw = ((_this.state.section_width ? _this.state.section_width : window.innerWidth) * parseInt(c_size.width) / 100)
	      } else {
	        cw = parseInt(c_size.width)
	      }
	      cw 	= cw * parseInt(c_size.width) / this.width
	      ch = cw * this.height / this.width
	      cw += "px"
	      ch += "px"
	      // console.log(cw, this.height, this.width)
	      c_size.width = cw
	      c_size.height = ch
	      _this.setState({ c_size })
	    }
	    newImg.src = content
	    // console.log( _this.state.m_size );
	    // console.log( _this.state.section_width );
	    if (m_size.width.indexOf("%") != -1) {
	      _this.state.m_size.width = `${(_this.state.section_width ? _this.state.section_width : window.innerWidth) * parseInt(m_size.width) / 100}px`
	    } else {
	      _this.state.m_size.width = this.props.data.mask_position.width
	    }
	    // console.log( _this.state.m_size );
	  }
	}

	is() {
	  const { mask_src } = this.state.data
	  return mask_src
	}

	getSvgByID(id) {
	  const res = svg_libs.masks.filter((e) => e.id == id)[0]
	   // console.log(res);
	  return res || svg_libs.masks[0]
	}

	renderContent(style) {
	  const {
	    class_name, mask_width, mask_height, m_size, c_size,
	  } = this.state
	  const {
	    content, content_position, mask_position, height, mask_data,
	  } = this.state.data
	  // console.log( m_size );
	  if (this.state.loading) {
	    return (
			<div style={{
				width: "100%",
				height,
			}}
  			>
    			... Loading
  			</div>
	    )
	  }
	  // console.log( this.state );
	  const st = { height }
	  return (
		<div
			className={`landing-mask-container  landing-section-core  ${class_name}`}
			style={{ ...style, ...st }}
		>
			<svg
			version="1.1"
			id={`landing-mask-${this.state.id}`}
			xmlns="http://www.w3.org/2000/svg"
			width={m_size.width}
			height={m_size.height}
			preserveAspectRatio="none"

			viewBox={`0 0 ${mask_width} ${mask_height}`}
			style={{
						position: "absolute",
						left: m_size.left,
						top: m_size.top,
			}}
			>
			{/*
						<g
							dangerouslySetInnerHTML={{ __html :  mask_data }}
						/>
						*/}
			<defs>
				<mask
				id={`mask-${this.state.id}`}
				x="0"
				y="0"
				width={m_size.width}
				height={height}
				preserveAspectRatio="none"
				dangerouslySetInnerHTML={{ __html: mask_data }}
				/>
			</defs>
			<rect
				x="0"
				y="0"
				width="900"
				height="900"
				fill="#00FF00"
				mask={`url(#mask-${this.state.id})`}
			/>
			<image
				width={c_size.width}
				height={c_size.height}
				xlinkHref={content}
				mask={`url(#mask-${this.state.id})`}
			/>
			</svg>
		</div>
	  )
	}
}
export default Mask
