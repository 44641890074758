const getScreenWidthMeta = (dwidth) =>
{
    let p = "mc"
    if (dwidth > 940) 
    {
      p = "lg"
    } 
    else if (dwidth > 760) 
    {
      p = "xl"
    } 
    else if (dwidth > 560) 
    {

      p = "sm"
    }
    //console.log(p, dwidth)
    return p
}
export default getScreenWidthMeta