import React, { useState } from "react"
import $ from "jquery"
import Style from "style-it"
import { getStyle } from "../Section"
import chroma from "chroma-js"
import { Dialog } from "@blueprintjs/core"
import { getColor } from "../data/getColor"

const CardFieldMedia = ({ 
    e,
    i,
    cardFieldsData,
    style,
    class_name,
    bStyle,
    dialog_youtube_id,
    dialog_vimeo_id,
    cardFields,
    dialog_video_service,
    is_card_img_youtube_thrum, 
    palette 
}) => {

    const [isOpen, onOpen] = useState(false)

    //console.log(cardFieldsData);
    let hover = `
    .media {
        display:none;
    }
    `;

    if (cardFieldsData.is_hover && cardFieldsData.hover_color) {
        const hover_color = getColor(cardFieldsData.hover_color, palette)        
        let hsl 
        try {
             hsl = chroma(hover_color).get("hsl") //sepia = 112, h - 0, a - 3
        } catch (error) {
            console.log( hover_color )
            hsl =[]
        }
        hover = `
        .media
        {
            opacity:0;
            position:absolute;
            transition: all ${cardFieldsData.hover_duration}ms ease-out;
            transition-delay:${cardFieldsData.hover_delay}ms; 
        }
        .landing-card:hover .media {
            filter:invert(28%) sepia(100%) hue-rotate(${-112 + hsl[0]}deg) saturate(${hsl[1]}) brightness(${hsl[2] * 75 + 50}%) contrast(120%);
            opacity: ${hsl[3]};
        }  
        `
    }
    const rand = parseInt(Math.random() * 10000000000000000000)
    let bg = e.field
    if (is_card_img_youtube_thrum) {
        if (dialog_video_service == "vimeo") {
            let isMeNum = -1
            const isMe = cardFields.filter((element, numb) => {
                if (element.type == "media") {
                    if (isMeNum == -1) isMeNum = numb
                    return true
                }
                return false
            })
            // console.log( dialog_vimeo_id );
            if (Array.isArray(isMe) && isMeNum == i) {
                $.ajax({
                    type: "GET",
                    url: `http://vimeo.com/api/v2/video/${dialog_vimeo_id}.json`,
                    jsonp: "callback",
                    dataType: "json",
                    success(data) {
                        const thumbnail_src = data[0].thumbnail_large
                        $(`#media_thumbnail_${rand}`).css({ backgroundImage: `url(${thumbnail_src.toString()})` })
                    },
                })
            }
        } else {
            let isMeNum = -1
            const isMe = cardFields.filter((element, numb) => {
                if (element.type == "media") {
                    if (isMeNum == -1) isMeNum = numb
                    return true
                }
            })
            // console.log(cardFields, isMe)
            if (Array.isArray(isMe) && isMeNum == i) {
                bg = `https://img.youtube.com/vi/${dialog_youtube_id}/0.jpg`
            }
        }
    }
    let st = {
        ...style,
        ...getStyle(cardFieldsData.variant_param_4, palette),
    }
    // если круг
    switch (cardFieldsData.variant) {
        case 1:
        case "1":
            st.top = "auto"
            st.minWidth = `${cardFieldsData.height}px`
            st.width = `${cardFieldsData.height}px`
            st.marginLeft = -parseInt(cardFieldsData.height) / 2
            break
        case 2:
        case "2":

            break
        default:
            st = st
            break
    }
    const onClick = () => {
        onOpen(!isOpen)
    }
    const clrmuar = cardFieldsData.variant_param_8
        ?
        [
            chroma(cardFieldsData.variant_param_8).alpha(0.0).toString(),
            chroma(cardFieldsData.variant_param_8).alpha(1).toString(),
        ]
        :
        ["#00000000", "#00000050"]
     return <>
        < div
            className=" media "
            id={`media_thumbnail_${rand}`}
            style={{ ...st, backgroundImage: `url(${bg})`, cursor: cardFieldsData.variant_param_6 ? "pointer" : "inherit" }}
            onClick={onClick}
        />
        {
            cardFieldsData.is_hover
                ?
                Style.it(
                    hover,
                    <div
                        className=" media "
                        id={`media_thumbnail_${rand}`}
                        style={{ ...st, backgroundImage: `url(${bg})` }}
                    />
                )
                :
                null
        }
        {
            cardFieldsData.variant_param_7
                ?
                <div
                    className="after"
                    style={{
                        background: `linear-gradient(to bottom, ${clrmuar[0]} 0%, ${clrmuar[0]} 50%, ${clrmuar[1]} 100%)`
                    }}
                >

                </div>
                :
                null

        }

        {
            cardFieldsData.variant_param_6
                ?
                <Dialog
                    isOpen={isOpen}
                    onClose={() => onOpen(!isOpen)}
                >
                    <img src={bg} alt="" style={{}} />
                </Dialog>
                :
                null
        }

    </>

}
export default CardFieldMedia