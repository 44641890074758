import React from "react"
import Layouts from "react-pe-layouts"
import FieldInput, {ScalarField} from "react-pe-scalars"

class ExtendsParamsScalar extends ScalarField
{
    onField = (value, field) =>
    {
        let val = {...this.props.value}
        let v = value === "false"
            ?
            false
            :
            value === "true"
                ?
                true
                :
                value 
        val[field] = v
        this.props.on( val, this.props.field )
    }
    isEnabled() {
        const { field, title, origin, value } = this.props 
        return <div className="my-2">
            { /* JSON.stringify( Layouts().views[origin.component].fields[0]?.extend_params.title ) */ }
            <Extends_ParamsForm
                field={field}
                {...Layouts().views[origin.component]?.fields[0]?.extend_params}
                value={{...value}}
                onChange={this.onField}
            />
        </div>
    }
    
}
export default ExtendsParamsScalar
 
export const Extends_ParamsForm = props =>
{
    switch(props.type)
    {
        case "object":
            const on = (value,field) =>
            {
                props.onChange( value,field )
            }
            return Object.keys(props.fields).map( (field) => {
                return <FieldInput 
                    key={field}
                    {...props.fields[field]}
                    value={ props.value ?  props.value[field] : null}
                    editable
                    vertical={false}
                    on={ value => on( value,field ) }
                    onChange={ value => on( value,field ) }
                />
            })
        default:
            return "--"
    }
}