import React, { useState } from "react" 
import { initArea } from  "react-pe-utilities" 
import YFThreadsForm from "./single/YFThreadsForm" 
import { __ } from "react-pe-utilities/dist" 
import { moduleExists } from "react-pe-layouts"

const Extends = props =>
{ 
    return <div className=" ">
        <div className="small mb-4">
            {
                __("This page for extends settings")
            }
        </div>
        {
            moduleExists("pe-yandex-forms-module")
                ?
                <YFThreadsForm {...props} />
                :
                null
        }
        {initArea('pefest-admin-extends', { ...props })}
    </div>
}
export default Extends