import React, { useEffect, useState } from "react"
import { __ } from "react-pe-utilities/dist"
import DataContext from "../../DataContext"
import SectionLayerBtn from "./SectionLayerBtn"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const sections = result.map( e => e.content.props.section )
    DataContext.data.sections = sections
    if(DataContext.view)
        DataContext.view.setState({ s: !DataContext.view.state.s, is_not_update: true  })
    return result;
};

const grid = 1;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0`,

  // change background colour if dragging
  background: isDragging ? "grey" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : "grey",
  padding: grid,
  width: "100%"
});
const SectionLayerList = props =>
{
    const onUpdate = () =>
    {
        setItems( dataToItems() )
    }
    const dataToItems = () =>
    {
        return Array.isArray(DataContext.data?.sections)
                ?
                DataContext.data?.sections.map(section =>
                {
                    return {
                        id: section.id,
                        content: <SectionLayerBtn
                            section={ section }
                            onUpdate={ onUpdate }
                        />
                    }
                })
                :
                []
    }
    const [items, setItems] = useState( dataToItems() )
    const onDragEnd = result =>
    {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const _items = reorder(
          items,
          result.source.index,
          result.destination.index
        );
        setItems(_items)    
        
      }
    const sections = <DragDropContext onDragEnd={ onDragEnd }>
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                {
                    items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                                {item.content }
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext> 

    return <div className="p-1">
        {sections}
    </div>
}
export default SectionLayerList