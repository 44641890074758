import React, { Suspense, lazy, Component }  from "react"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import Section, { getDefault } from "./Section"
import {Loading} from 'react-pe-useful'  
import Style from "style-it"
import OwlCarousel from 'react-owl-carousel3';

class Carousel extends SectionContent {
  getState() { 
    this.car = React.createRef()
    return { }
  }  
  renderContent (style) {
    const { composition, is_edit, palette } = this.props
    const {
      class_name,
      items, 
      dots,
      dots_each,
      dots_type,
      dots_design,
      dots_color,
      dots_active_color, 
      nav,
      nav_type,
      nav_vertical_position,
      nav_design,
      nav_color,
      inner_nav,
      autoplay,
      loop,
      sections,
    } = this.props.data
    const __sections = sections && sections.length > 0
      ? 			
      sections.map((e, i) => (
        <Section
          lasy_load_type="none"
          background={{color : "#00000001"}}
          {...e}
          key={i}
          i={i}
          is_edit={is_edit}
          level={this.props.level + 1}
          onEdit={this.onEdit}
          onUp={this.onUp}
          onDn={this.onDn}
          onAdd={this.onAdd}
          onRnv={this.onRnv}
          onHide={this.onHide}
          onRemoveFloat={this.onRemoveFloat}
          onUpdateFloat={this.onUpdateFloat}
          is_no_waypoint={true}
          section_width={this.props.section_width}
          palette={this.props.palette}
        />
      ))
      : 			
      null

    
    const options = {
      //center: true,
      dots: typeof dots != "undefined" ? (!!dots) : false,
      dotsEach : typeof dots_each !== "undefined" ? dots_each : 1, 
      navText:[],     
      //animateOut: 'slideOutDown',
      //animateIn: 'flipInX',  
      items: typeof composition.columns !== "undefined" 
        ?         
        document.body.clientWidth > 720
          ?
          composition.columns 
          :
          document.body.clientWidth > 560 && parseInt(composition.columns) > 1
            ?
            2
            :
            1
        : 
        1,
      nav: typeof nav != "undefined" ? !!nav : false,
      rewind: true,
      autoplay: typeof autoplay != "undefined" ? autoplay ? !is_edit : false : !is_edit,
      loop: typeof loop != "undefined" ? !!loop : false,
      mouseDrag: !is_edit,
      touchDrag: !is_edit,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: typeof composition.columns != "undefined" ? composition.columns : 1,
        },
      },

    }
    // console.log( options )
    // const events = { }
    return Style.it(
      ` 
      .owl-theme .owl-nav [class*=owl-] {
        background-color:transparent;
      }
      ${ 
        options.nav 
          ? 
          `.owl-carousel
          {
            width: calc(100% - 120px);
            margin-left: 60px;
          }
          .inner_nav .owl-carousel
          {
            width: 100%;
            margin-left: 0;
          }
          .owl-nav
          {
            top: ${nav_vertical_position}%;
          }
           .owl-prev
          {
            left:-60px;
          }
           .owl-next
          {
            right:-60px;
          }
          .inner_nav .owl-prev
          {
            left:0px;
          }
          .inner_nav .owl-next
          {
            right:0px;
          }
          `
          :
        ``
      }
      ${
        options.dots
        ?
        `
        .owl-carousel{
          padding-bottom: 35px;
        }
        .owl-dot:after {
          background-color: ${ dots_color };
        }
        .owl-dot.active:after {
          background-color: ${ dots_active_color };
        }
        `
        :
        ``        
      }
      `,
      <div
        className={`landing-carousel landing-section-core  landing-element ${class_name} ${nav_type} ${nav_design} ${nav_color} ${dots_type} ${dots_design} ${inner_nav ? " inner_nav " : ""}`}
        style={this.getStyle(style)}
      >
        <Suspense fallback={<Loading/>}>
          <OwlCarousel 
            className="owl-theme"
            {...options}
          >
            {  __sections }
          </OwlCarousel>
        </Suspense>
      </div>
    )
  }

  is() {
    const { sections } = this.state.data
    return sections && sections.length > 0
  }

	onEdit = (data, id) => {
	  console.log("onEdit", id, data, this.state)
	  const sections = [...this.state.data.sections]
	  const secs = []
	  sections.forEach((e) => {
	    if (e.id == data.id) {
	      secs.push(data)
	    } else {
	      secs.push(e)
	    }
	  })
	  this.setState({ data: { ...this.state.data, sections: secs } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections: secs } },
	    this.props.id,
	  )
	}

	onUp = (data) => {
	  console.log("onUp", data, this.state)
	  const sections = [...this.state.data.sections]
	  const sec = { ...sections[data] }
	  sections.splice(data, 1)
	  sections.splice(data - 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onDn = (data) => {
	  console.log("onDn", data, this.state); const sections = [...this.state.data.sections]
	  const sec = { ...sections[data] }
	  sections.splice(data, 1)
	  sections.splice(data + 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onAdd = (data) => {
	  console.log("onAdd", data, this.state)
	  const sections = [...this.state.data.sections]
	  const sec = getDefault()
	  sections.splice(data + 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onRnv = (data) => {
	  console.log("onRnv", data, this.state.data.sections)
	  const sections = [...this.state.data.sections]
	  sections.splice(data, 1)
	  console.log(sections)
	  this.setState({ data: { ...this.state.data, sections } })
	  this.props.onEdit(
	    { ...this.state, data: { ...this.state.data, sections } },
	    this.props.id,
	  )
	}

	onHide = (id, is_hide) => {
	  console.log("HIDE", id, is_hide)
	}

	onRemoveFloat = (float_id) => {

	}

	onUpdateFloat = (data, float_id, section_id) => {

	}
}
export default Carousel

export const CarouselRightArrow = props =>
{
  return <CarouselArrow {...props} isLeft={false} />
}
export const CarouselLeftArrow = props =>
{
  return <CarouselArrow {...props} isLeft />
}
export const CarouselArrow = props =>
{
  if(!props.nav) return null
  let dot = ""
  switch(props.nav_color)
  {
    case "owllight":
      dot += `
        background-color: #FFF;
        color: #111;`
      break 
    case "owldark":
    default:
      dot += `
        background-color: #111;
        color: #EEE;`
      break
  }
  switch(props.nav_type)
  {
    case "rounded":
      dot += `
        border-Radius:100%;`
      break
    case "squared":
      dot += `
      border-Radius:0%;`
      break
    case "half_rounded":
      dot += props.isLeft
        ?
        `
        border-Radius:0% 100% 100% 0;`
        :
        `
        border-Radius:100% 0 0 100%;`
      break
    case "transparent":
      dot += `
        background-color: transparent;` 
      break
    default:
        dot += "";
  }

  return <>
  { 
    Style.it(
      `.landing-carousel-arrow
      {
        position: absolute;
        z-Index:  1000;
        top:      calc(50% - 20px);
        left:     ${props.isLeft ? 0 : "auto"};
        right :   ${props.isLeft ? "auto" : 0};
        height:   55px;
        width:    55px;
        font-size:20px;
        display:  flex;
        justify-content: center;
        align-items: center;
        cursor: 'pointer';
        ${dot}
      }`,
      <div className="landing-carousel-arrow">
        < CarouselArrowLabel {...props}/>
      </div>
    ) 
  }
  </>
}
const CarouselArrowLabel = props =>
{
  let arr
  switch(props.nav_design)
  {
    case "arrowsed":  
      arr = props.isLeft
      ?
      <i className="fas fa-arrow-left"></i>
      :
      <i className="fas fa-arrow-right"></i>
      break;
    case "angle":
      arr = props.isLeft
      ?
      <i className="fas fa-angle-left"></i>
      :
      <i className="fas fa-angle-right"></i>
      break;
    case "double-angle": 
      arr = props.isLeft
      ?
      <i className="fas fa-angle-double-left"></i>
      :
      <i className="fas fa-angle-double-right"></i>
      break;
    case "hand": 
      arr = props.isLeft
      ?
      <i className="fas fa-hand-point-left"></i>
      :
      <i className="fas fa-hand-point-right"></i>
      break;
    case "caret": 
    default:
      arr = props.isLeft
      ?
      <i className="fas fa-caret-left mr-1"></i>
      :
      <i className="fas fa-caret-right ml-1"></i>
      break;
    
  }
  return <>
    { arr }
  </>
}
