import React, { Component } from "react"
import { Route, Switch } from "react-router"
import Layouts from "react-pe-layouts"
import { __, importAll} from "react-pe-utilities"  
import NoMatchView from "../views/NoMatchView"

const components = {}
//importAll(require.context("../states/wpfestREST", false, /\.js$/))

class FreeRouteWidget extends Component {
  render() {
    // console.log( this.props )
    // console.log(`${this.props.preroute}/${this.props.link_route}`)
    const link_routes = Array.isArray(this.props.link_route) ? this.props.link_route : [this.props.link_route]
    const labels = Array.isArray(this.props.label) ? this.props.label : [this.props.label]
    const __components = Array.isArray(this.props.components)
      ? 
      this.props.components
      : 
      []
    const routes =  link_routes.map((route, i) => {
      const MyComponent = components[__components[i]] 
        ? 
        components[__components[i]] && components[__components[i]].default 
          ?
          components[__components[i]].default
          :
          <NoMatchView />
        : 
        this.props.component_names[i]
          ?
          searchComponent( this.props.component_names[i] )
          :
          <NoMatchView />
      
      const targ_route = this.props.preroute
        ?
        `/${this.props.preroute}/${route}`
        :
        `/${route}`
      const singleProps = Array.isArray(this.props.props) ? this.props.props[i] : this.props.props
      //console.log( targ_route )
      //console.log( MyComponent )
      //console.log( singleProps )
      return (
        <Route
          key={ i }
          exact
          path={ targ_route }
          component={ routeProps => (            
             MyComponent
                ?
                <MyComponent
                  onChangeStyle={ (style)=> {console.log(style)} }
                  route={{
                    icon:"--",
                    title:"---"
                  }}
                  {...this.props} 
                  {...singleProps}
                  title=" "
                  icon=" "
                />
                :
                <NoMatchView 
                  onChangeStyle={(style) => console.log(style)}
                />
            )
        }
        />
      )
    })
    return <Switch>
      {routes}
    </Switch>
  }
}
export default FreeRouteWidget

export const searchComponent = componentName =>
{
  const components = {}
  const lazies = {}
  const plViews = [] 

  // Все Компоненты из папки states для последующей подстановки в роутинги
  importAll(require.context("states/", false, /\.js$/), [], components, lazies, "states/")

  // Собираем все компоненты из папки modules (которые указаны в layouts.json, раздел modules)
  // для последующей подстановки в роутинги
  const plgns = Layouts().modules 
  Object.keys(plgns).forEach((plugin) => {
    //
    if(plgns[plugin] && plgns[plugin].views)
    {
      //console.log(plugin, plgns[plugin], plgns[plugin].views)
      Object.keys(plgns[plugin].views).forEach((view) => {
        plViews.push(view)
      })
    }
    
  })
  importAll(require.context("modules/", true, /\.js$/), plViews, components, lazies, "modules/")
  //console.log(componentName)
  //console.log(components[componentName])
  return components[componentName] ? components[componentName].default : null
  const Module = components[componentName] ? components[componentName].default : null
  return Module 
    ? 
    <div>
      <div className="p-3">
        {componentName}
      </div>
      <Module />
    </div> 
    : 
    null
}
