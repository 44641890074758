import React, { Component } from "react"
import { getStyle } from "../Section"

class CardTail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

    top_plate = () => {
      const { tail_class, tail_style, tail_text } = this.props
      return (
        <div
          className={"landing-card-tail " } 
          dangerouslySetInnerHTML={{ __html: tail_text }}
        />
      )
    }

    render() {
      const { tail_type } = this.props
      switch (tail_type) {
        case "origamu":

          return ""
        case "top_plate":

          return this.top_plate()
        case "none":
        default:
          return ""
      }
    }
}
export default CardTail
