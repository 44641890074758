import { ReactComponent as ExpertAvatar } from '../../../../../assets/img/Expert_avatar.svg';
import { ReactComponent as ProjectAuthorAvatar } from '../../../../../assets/img/Project_author_avatar.svg';
import { ReactComponent as TutorAvatar } from '../../../../../assets/img/Tutor_avatar.svg';
import { ReactComponent as TrackModeratorAvatar } from '../../../../../assets/img/Track_moderator_avatar.svg';
import { ReactComponent as ProjectMemberAvatar } from '../../../../../assets/img/Project_member_avatar.svg';
import { ReactComponent as AdministratorAvatar } from '../../../../../assets/img/Administrator_avatar.svg';

const Roles = () => {
    return {
        "Expert" : {
            "_id": "Expert",
            "title": "Expert",
            "caps_description" : "You may expert rating the Projects",
            avatar: ExpertAvatar
        },
        "Project_author" : {
            "_id": "Project_author",
            "title": "Project_author",
            "caps_description" : "You may register own project",
            avatar: ProjectAuthorAvatar
        },
        "Tutor" : {
            "_id": "Tutor",
            "title": "Tutor",
            "caps_description" : "You may register own project and rate partners",
            avatar: TutorAvatar
        },
        "Project_member" : {
            "_id": "Project_member",
            "title": "Project_member",
            "caps_description" : "You may edit your Project's parameters",
            avatar: ProjectMemberAvatar
        },
        "Track_moderator" : {
            "_id": "Track_moderator",
            "title": "Track_moderator",
            "caps_description" : "You may rule Track and correct Project's rating",
            avatar: TrackModeratorAvatar
        },
        "administrator" : {
            "_id": "administrator",
            "title": "administrator",
            "caps_description" : "You manage the Festival and its properties",
            avatar: AdministratorAvatar
        }
    }
    
}
export default Roles
