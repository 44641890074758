import { setCookie } from "./utilities/utils";

export default class User
{
	static display_name = "";
	static roles = [];
	static setData(data)
	{
		User.display_name	= data.display_name;
		User.roles			= data.roles; 
		User.is_super		= data.is_super
	}
	static deleteData()
	{
		User.display_name	= "";
		User.roles			= [];
	}
}