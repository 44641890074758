import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import FieldInput from 'react-pe-scalars/dist'
import User from '../utilities/User'
import { AppToaster } from 'react-pe-useful/dist'
import _fetch from '..'
import WPFestSettings from '../utilities/WPFestSettings'
import { useFestStore } from 'modules/pe-fest-module/data/store'

const CorrectHoneycombs = props => {
    const [isEdit, changeEdit] = useState(false)
    if( User?.roles.filter(role => role === "Track_moderator" || role === "administrator" ).length === 0 )
    {
        return <div className="lead text-md-left text-center w-100">
            <i className='fas fa-users mr-2 opacity_5' /> { props.honeycombs?.post_title }
        </div>
    }
       
    return <div className='d-flex justify-content-between'>
        {
            isEdit
                ?
                <EditHoneycombsDisplay {...props} changeEdit={() => changeEdit(false)}/>
                :
                <>
                    <div className="lead text-md-left text-center w-100">
                        <i className='fas fa-users mr-2 opacity_5' /> { props.honeycombs?.post_title }
                    </div>
                    <Button 
                        minimal 
                        fill
                        className="text-nowrap px-4"
                        onClick={() => changeEdit( !isEdit )}
                    >
                        {__("Change honeycombs")}
                    </Button>
                </>
        }
    </div> 
}

CorrectHoneycombs.propTypes = {}

export default CorrectHoneycombs

const EditHoneycombsDisplay = props =>
{
    const [id, setId] = useState(props.honeycombs.ID)
    const [change, setChange] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [newTitle, setNewTitle] = useState("") 
    const allHoneyCombs = useFestStore.getState().honeycombs
    const changeValue = id =>
    { 
        if(id === "new")
        {
            setOpen(true)
        }
        else
        {
            setId(id) 
            setChange(true)
        }
        
    } 
    const doInsert = () =>
    {
        if(!newTitle)
        {
            AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: __("Insert title"),
			})
            return;
        }
        const args = {
            post_title: newTitle,
            post_content : "",
            member_id: props.member_id
        }
        _fetch(
            "get_fest_command",
            { 
                ...args, 
                command: "insert_honeycombs", 
                festId: props.festid
            },
            WPFestSettings.serviceURL,
            WPFestSettings.getToken( WPFestSettings.url ),
            "wpfa",
        )
        .then ( (_data) => {
            console.log( _data.data );
            setNewTitle("")
            setOpen(false) 
        } )
    }
    const doSetHoneycombs = () =>
    {
        const args = {
            honeycombs_id: id,
            member_id: props.member_id
        }
        _fetch(
            "get_fest_command",
            { 
                ...args, 
                command: "set_member_honeycombs", 
                festId: props.festid
            },
            WPFestSettings.serviceURL,
            WPFestSettings.getToken( WPFestSettings.url ),
            "wpfa",
        )
        .then ( (_data) => {
            console.log( _data.data ); 
            setOpen(false)
            props.changeEdit(-1)
        } )
    }
    const all =  [
        ...allHoneyCombs.map(h => ({ _id:h.ID, title:h.post_title })),
        { _id: "new", title: __("insert new Honeycomds"), intent: Intent.PRIMARY }
    ]
    return <>
        <div>
            <FieldInput  
                visualization="festFormsFields"           
                vertical
                editable={ true }
                type={ "tags" }
                value={ id }
                values={ all }
                onChange={ changeValue }
              />
        </div>
        <ButtonGroup className='d-flex align-items-center'>
            <Button 
                icon="tick" 
                intent={Intent.SUCCESS} 
                data-hint={__("Save")} 
                className="hint hint--top"
                onClick={ doSetHoneycombs }
                minimal={!change}
                disabled={!change}
            />
            <Button 
                icon="cross" 
                intent={Intent.DANGER} 
                data-hint={__("Cancel")} 
                className="hint hint--top"
                onClick={props.changeEdit}
            />
        </ButtonGroup>
        <Dialog
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            title={__("insert new Honeycomds")}
        >
            <div className='p-0'>
                <div className='p-5'>
                    <FieldInput
                        title={__("Honeycombs title")}
                        type="string"
                        value={newTitle}
                        onChange={value => setNewTitle(value)}
                        editable
                    /> 
                </div>
                <ButtonGroup fill large>
                    <Button 
                        className='' 
                        fill 
                        intent={Intent.SUCCESS}
                        onClick={doInsert}
                    >
                        {__("insert new Honeycomds")}
                    </Button>
                    <Button 
                        className=' ' 
                        fill={false} 
                        intent={Intent.DANGER} 
                        icon="cross" 
                        onClick={() => setOpen(!isOpen)}
                    />
                </ButtonGroup>
            </div>

        </Dialog>
    </>
}