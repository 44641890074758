import React, {Component} from "react"

import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router" 
import FieldInput from "react-pe-scalars"
import { Button, Intent } from "@blueprintjs/core"
import { __ } from "react-pe-utilities/dist"

class QRCodeTemplatesSelector extends Component
{
    constructor(props)
    {
      super(props)
      this.state = { 
        selected:null
      }
    }  
    onChange = value =>
    {
        console.log( value )
        if(this.props.onChange)
        {
            this.props.onChange( value )
        }
    }
    render()
    { 
        return <div className="w-100 d-flex align-items-strech">
            <div className="flex-grow-100">
               <FieldInput
                    title={ __("Templates") }
                    component="PEQRCodeTemplate" 
                    external_fields={["post_title", "id"]}
                    kind="type"
                    type="external"
                    description={ __("Bla-bla-bla-bla") }
                    editable={true}
                    value={ this.props.value }
                    field="template_id"
                    onChange={ this.onChange }
                />  
            </div>            
            <div className="ml-2">
                <Button icon="plus" className="h-100" intent={Intent.SUCCESS}></Button>
            </div>
        </div>
    }
}

export default compose(
    withApollo,
    withRouter,
  )(QRCodeTemplatesSelector)