import React, { useEffect, useRef, useState } from 'react'
import { getColor } from '../data/getColor'
import { getFontNameByID } from '../data/PalettePresets'
import { getStyle } from '../Section'
import $ from 'jquery'
import { __ } from 'react-pe-utilities/dist' 

const CardFieldString = ({ 
  e,
  i,
  cardFieldsData,
  style,
  class_name,
  bStyle,
  palette,
  id,
  onHeight
}) => {
  const isCropState = cardFieldsData.variant == "0" && cardFieldsData.variant_param_1 == "1" && cardFieldsData.height_type  == "directly"
  const [isCrop, setCrop] = useState ( isCropState )
  const[innerHeight, setInnerHeight] = useState( 0 )
  const[outerHeight, setOuterHeight] = useState( cardFieldsData.height )
  const nameField = useRef( null )   
  const iner      = useRef( null )

  // устанавливаем значение высоты внутреннего содержания для CROP
  useEffect(() => {
    let st = window.setTimeout(() => {
      const h = $(iner.current).innerHeight() 
      setInnerHeight(h)
    }, 100)  
    return () => window.clearTimeout( st )  
  }, []) 

  const changeCrop = is =>
  {
    if( !isCropState ) return
    setCrop(is)
    if(is)
    {
      if(onHeight) onHeight( i, 0  )
      $(nameField.current).innerHeight( outerHeight )  
    }
    else
    {
      const h = $(iner.current).innerHeight() 
      $(nameField.current).innerHeight( h ) 
      if(onHeight && h > outerHeight ) 
        onHeight( i, h - outerHeight + 20 ) 
    }
  }

  let handledStyle = { ...cardFieldsData.style }
  delete handledStyle.border
  delete handledStyle.borderBottom
  delete handledStyle.borderRight
  delete handledStyle.borderLeft
  delete handledStyle.top
  delete handledStyle.left
  delete handledStyle.bottom
  delete handledStyle.right
  delete handledStyle.position
  delete handledStyle.marginTop
  delete handledStyle.marginBottom
  delete handledStyle.margin
  delete handledStyle.marginLeft
  delete handledStyle.marginRight
  delete handledStyle.transform
  delete handledStyle.height
  delete handledStyle.width
  delete handledStyle.clipPath
  
  //console.log( palette );
  const fontStyle =
    cardFieldsData.variant_param_5 &&
    typeof cardFieldsData.variant_param_5 === 'object'
      ? getStyle(cardFieldsData.variant_param_5, palette)
      : {}
  let st = {
    ...style,
    //...handledStyle,
    color: bStyle.color,
    fontSize: bStyle.fontSize,
    fontWeight: bStyle.fontWeight,
    fontFamily: bStyle.fontFamily,
    height: outerHeight, 
    ...fontStyle,
    zIndex: 10,
  }

  //console.log(cardFieldsData)
  let beforeBefore = `
        .before::before {
            content:"";
            position:absolute;
            top:0;
            left:0;
        }
        `
  
  let beforeStyle = { zIndex: 5 }
  let beforeStyle2 = {}
  const beforeStyle3 = {}
  let afterStyle = {}
  let afterStyle2 = {}
  const afterStyle3 = {}

  let afterText = ""
  switch (cardFieldsData.variant) 
  {
    case 0: // simple
    case '0':
      //st.height = "auto"
      delete st.border
      delete st.borderBottom
      delete st.borderTop
      delete st.borderLeft
      delete st.borderRight
      switch( cardFieldsData.variant_param_1 )
      {
        case 0:
        case "0":
          break
        case 1:
        case "1":
          if( isCropState )
          {
            if( isCrop )
            {
              st.height         = cardFieldsData.height
              st.overflow       = "hidden" 
              st.justifyContent = "start" 
              afterText = outerHeight < innerHeight
                ?
                <div className='my-2'>
                  <div 
                    className='bg-transparent px-2 pointer hover' 
                    onClick={ () => changeCrop (!isCrop) }
                    style={{color: st.color , paddingBottom:4, paddingTop:2 }}
                  >
                    <i className='fas fa-caret-down' />
                  </div>
                </div>
                :
                null
            }
            else
            {
              st.justifyContent = "start"
              st.overflow       = "hidden"
            }
          }
          break;
        default:
          break
      }
      
      break
    case '1': // title
    case 1:
      if (palette && palette.card && palette.card.title) {
        //let handledStyle = { ...cardFieldsData.style }
        //delete handledStyle.border
        //delete handledStyle.borderBottom
        st = {
          ...st,
          ...palette.card.title,
          fontFamily: getFontNameByID(palette.card.title.fontFamilyID),
          // ...handledStyle,
          textAlign: handledStyle.textAlign,
          width: handledStyle.width,
        }
      }
      // console.log(st, cardFieldsData.style, e)
      break
    case '2': // corner
    case 2: // corner
      st = {
        ...st,
        width: cardFieldsData.height * 1.0,
        height: cardFieldsData.height * 1.0,
      }
      switch (cardFieldsData.variant_param_3) {
        case 'pennon':
          /*
                    0		r: -31
                            b: 82
                    20		r: -43
                            b: 68
                    40		r: -49
                            b: 50
                    60		r: -57
                            b: 33
                    80		r: -65
                            b: 16
                    100		r: -72
                            b: 0
                    120		r: -80
                            b: -18
                    */
          const rot = -45
          const bottom0 = 83
          const right0 = -34
          const height0 = cardFieldsData.variant_param_4
            ? parseInt(cardFieldsData.variant_param_4)
            : 30
          const shift = Math.sqrt((height0 * height0) / 6)

          st = {
            width: Math.sqrt(cardFieldsData.height * cardFieldsData.height * 2),
            transform: `rotate(${rot}deg)`,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'absolute',
            top: 'auto',
            bottom: bottom0 - shift * 2 + 10,
            right: right0 - shift - 5,
            backgroundColor: getColor(bStyle.backgroundColor, palette),
            height: height0,
          }
          // corner as left
          if (
            cardFieldsData.variant_param_1 == 0 ||
            cardFieldsData.variant_param_1 == 2
          ) {
            st.left = st.right
            st.right = 'auto'
          }
          break
        case 'corner':
        default:
          beforeStyle = {
            borderWidth: cardFieldsData.height ? cardFieldsData.height / 2 : 0,
            borderTopColor:
              cardFieldsData.variant_param_1 == 0 ||
              cardFieldsData.variant_param_1 == 1
                ? bStyle.backgroundColor
                : null,
            borderBottomColor:
              cardFieldsData.variant_param_1 == 2 ||
              cardFieldsData.variant_param_1 == 3
                ? bStyle.backgroundColor
                : null,
            borderLeftColor:
              cardFieldsData.variant_param_1 == 0 ||
              cardFieldsData.variant_param_1 == 2
                ? bStyle.backgroundColor
                : null,
            borderRightColor:
              cardFieldsData.variant_param_1 == 1 ||
              cardFieldsData.variant_param_1 == 3
                ? bStyle.backgroundColor
                : null,
          }
          st = {
            ...st,
          }
          break
        // default:
        //	st = st;
      }
      return (
        <>
          <div className='before' style={beforeStyle} />
          <div className='before2' style={beforeStyle2} />
          <div className='before3' style={beforeStyle3} />
          <div
            className={` string ${class_name}`}
            style={st}
            dangerouslySetInnerHTML={{ __html: e.field }}
          />
          <div className='after' style={afterStyle} />
          <div className='after2' style={afterStyle2} />
          <div className='after3' style={afterStyle3} />
        </>
      )
    case '3': // pennon
    case 3: // pennon
      beforeStyle = {
        borderTopColor: bStyle.backgroundColor,
        borderRightColor: bStyle.backgroundColor,
      }
      break
    case '4': // dart
    case 4: // dart
      const arroHeadwWidth = cardFieldsData.variant_param_1
      const pinWidth = cardFieldsData.variant_param_2
      const arroHeadHeight = cardFieldsData.variant_param_3 || 0.1
      const clipPath = `polygon(0 ${
        arroHeadHeight / 2
      }px, calc(100% - ${arroHeadwWidth}px) ${
        arroHeadHeight / 2
      }px, calc(100% - ${arroHeadwWidth}px) 0, 100% 50%, calc(100% - ${arroHeadwWidth}px) 100%, calc(100% - ${arroHeadwWidth}px) calc(100% - ${
        arroHeadHeight / 2
      }px), 0% calc(100% - ${arroHeadHeight / 2}px))`

      // console.log( cardFieldsData );
      // console.log( clipPath );

      beforeStyle = {
        ...beforeStyle,
        backgroundColor: getColor(bStyle.backgroundColor, palette),
        width: `calc(100% + ${pinWidth}px)`,
        height: `calc(100% + ${arroHeadHeight}px)`,
        clipPath,
        //top:-20,
        left: 0,
        position: 'absolute',
      }
      // beforeStyle = {
      //     ...beforeStyle,
      //     backgroundColor: "#00000020",
      //     filter: "blur(1.2px)",
      //     width: `calc(100% + ${pinWidth}px)`,
      //     height: `calc(100% + ${arroHeadHeight}px)`,
      //     clipPath
      // }
      beforeBefore = `
                .before::before:{
                    content:"";
                    position:absolute;
                    top:10px;
                    left:0;
                    width:calc(100% + ${pinWidth}px);
                    height: calc(100% + ${arroHeadHeight}px);
                    clip-path: ${clipPath};
                }
            `
      afterStyle = {
        ...afterStyle,
        width: `calc(100% + ${pinWidth}px)`,
        height: `calc(100% + ${arroHeadHeight}px)`,
        clipPath,
        background: pinWidth < 0 ? '#00000000' : null,
      }
      break
    case '6': // kernel
    case 6: // kernel
      var outer = cardFieldsData.height + 20
      var inner = cardFieldsData.height - 40
      var aftrW
      var aftrW2
      /*
            cut width:
                60  - 0
                70  - 5
                80  - 10
                90	- 15
                100 - 20
                110 - 25
                120 - 30
                140 - 40 - 65
                160 - 50 - 75
                170 - 55
                180 - 60 - 85
                200 - 70
                210 - 75
                220 - 80
                230 - 85
                240 - 90
                260 - 100
                270 - 105
                280 - 110 - 135
            */

      st = {
        ...st,
        display: 'flex',
        alignItems: 'center',
      }
      // console.log( outer, cardFieldsData.variant_param_2 )
      const bw2 = outer + cardFieldsData.variant_param_2 * 2

      switch (cardFieldsData.variant_param_1) {
        case '1': // simple
          aftrW2 = (cardFieldsData.height - 60) / 2
          aftrW = aftrW2 + 25
          beforeStyle = {
            backgroundColor: getColor(bStyle.backgroundColor, palette),
            width: cardFieldsData.height - 0,
            height: cardFieldsData.height - 0,
          }
          break
        case '2': // wave
          aftrW2 = (cardFieldsData.height - 60) / 2
          aftrW = aftrW2 + 25
          beforeStyle = {
            backgroundColor: getColor(bStyle.backgroundColor, palette),
            width: cardFieldsData.height - 0,
            height: cardFieldsData.height - 0,
          }
          beforeStyle2 = {
            borderWidth: cardFieldsData.variant_param_2,
            width: bw2 || '100%',
            height: bw2 || '100%',
            borderRightColor: getColor(bStyle.backgroundColor, palette),
          }
          afterStyle = {
            height: cardFieldsData.variant_param_2,
            width: aftrW2 ? `calc(50% - ${aftrW2}px + 6px)` : null,
            backgroundColor: getColor(bStyle.backgroundColor, palette),
            left: `calc(50% + ${
              (outer + cardFieldsData.variant_param_2) / 2
            }px)`,
          }
          afterStyle2 = {
            height: cardFieldsData.variant_param_2,
            width: `calc(50% - ${0}px + 6px)`,
            backgroundColor: getColor(bStyle.backgroundColor, palette),
          }
          break
        case '3': // laurel
          aftrW2 = (cardFieldsData.height - 60) / 2
          aftrW = aftrW2 + 45
          beforeStyle = {
            borderWidth: cardFieldsData.variant_param_2,
            borderColor: getColor(bStyle.backgroundColor, palette),
            width: inner,
            height: inner,
            backgroundColor: cardFieldsData.variant_param_3,
          }
          beforeStyle2 = {
            borderWidth: cardFieldsData.variant_param_2,
            width: bw2 || '100%',
            height: outer + cardFieldsData.variant_param_2 * 2,
            borderTopColor: getColor(bStyle.backgroundColor, palette),
          }
          afterStyle = {
            height: cardFieldsData.variant_param_2,
            width: aftrW ? `calc(50% - ${aftrW}px + 6px)` : null,
            backgroundColor: getColor(bStyle.backgroundColor, palette),
          }
          afterStyle2 = {
            height: cardFieldsData.variant_param_2,
            width: `calc(50% - ${aftrW2}px + 6px)`,
            backgroundColor: getColor(bStyle.backgroundColor, palette),
          }
          break
        default:
          afterStyle2 = afterStyle2
          break
      }
      break
  }
  const textHtml = e.field_type ? e.field_type : e.field
  const text = textHtml.toString().length > 1500
    ?
    `${textHtml.toString().substring( 0, 1500)} ...`
    :
    textHtml.toString()
    
  return (
    <>
      <div className='before' style={beforeStyle} />
      <div className='before2' style={beforeStyle2} />
      <div className='before3' style={beforeStyle3} />
      <div
        className={` string ${class_name}`}
        style={st}        
        ref={nameField}
      >
        <div 
          dangerouslySetInnerHTML={{ __html: text }} 
          ref={iner}
          onClick={ () => changeCrop (!isCrop) }
        />
      </div>
      { afterText }
      <div className='after' style={afterStyle} />
      <div className='after2' style={afterStyle2} />
      <div className='after3' style={afterStyle3} />
    </>
  )
}
export default CardFieldString
