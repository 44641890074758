import { Button } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities/dist"

const ClearSelectPanel = props =>
{
    return props.floatSelectID
        ?
        <div className=" h-100 d-flex align-items-center ">
            <span className="landing-panel">
                <Button
                    className="hint hint--bottom"
                    data-hint={__("Clear select")}
                    minimal
                    icon="clean"
                    onClick={ () => props.onFloatSelect(undefined, undefined) }
                >
                    
                </Button>
            </span>
        </div>  
        :
        null
}
export default ClearSelectPanel