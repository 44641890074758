import React, { Component, Suspense } from "react"
 

import {Loading} from 'react-pe-useful'
import {LayoutIcon} from 'react-pe-useful'
import PostFeedCard from "./postFeed/PostFeedCard" 
import gql from "graphql-tag"
import { compose } from "recompose"
import { Query, withApollo } from "react-apollo"
import { getQueryArgs } from "react-pe-layouts"
import EditLabel from "./EditLabel"
import { components } from "./data/components" 
const OwlCarousel = React.lazy(() => import( 'react-owl-carousel3' ))

class PostCarousel extends Component {
  constructor(props) {
    super(props)
    this.car = React.createRef()
  }

  render() {
    const {
      class_name, style, composition, 
    } = this.props
    const {
      dots, nav, autoplay, loop, data_type, offset, count,
    } = this.props.data

    let html
    if (data_type) {
      const options = {
        dots: typeof dots !== "undefined" ? !!dots : true,
        items: typeof composition.columns !== "undefined" ? composition.columns : 1,
        nav: typeof nav !== "undefined" ? !!nav : false,
        rewind: true,
        autoplay: typeof autoplay !== "undefined" ? !!autoplay : true,
        loop: typeof loop !== "undefined" ? !!loop : true,
        responsive:
        {
          0: {
            items: 1,
          },
          760: {
            items: typeof composition.columns !== "undefined" ? composition.columns : 1,
          },
        },
      }

      const name = `get${data_type}s`
      
      const fields = getQueryArgs(data_type)
      const query = gql`
				query ${name} 
				{
					${name}( paging:{ count:${count}, offset:${offset} })
					{
						${fields}
					}
				}
			`
      html = (
        <Query query={query}>
          {
            ({
              loading, error, data, client,
            }) => {
              if (loading) {
                return <Loading />
              }
              if (data) {
                // console.log(data[name]);
                const __sliders = data[name].map((e, i) => <PostFeedCard {...e} key={i} i={i} />)
                return (
                  <div className={`landing-post-carousel landing-section-core landing-element${class_name}`} style={style}>
                    <Suspense fallback={<Loading />}>
                      <OwlCarousel
                        ref={this.car}
                        options={options}
                      >
                        {__sliders}
                      </OwlCarousel>
                    </Suspense>
                  </div>
                )
              }
            }
          }
        </Query>
      )
    } else {
      html = this.no()
    }
    return html
  }

  no() {
    const {
      class_name, style, type,
    } = this.props
    return (
      <div
        className={` landing-empty ${class_name}`}
        style={{ ...style, height: 300 }}
      >
        <LayoutIcon
          src={components()[this.props.type].icon}
          className=" layout-icon-giant "
        />
        <div className="lead text-white">
          {components()[this.props.type].title}
        </div>
        <EditLabel
          {...this.props}
          source={type}
          onEdit={this.props.onEdit}
          isBtn
        />
      </div>
    )
  }
}
export default compose(
  withApollo,
)(PostCarousel)
