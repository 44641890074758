import React, { Component } from "react" 
import _fetch from "." 
import { compose } from "recompose"
import { withRouter, } from "react-router"

import { __ } from "react-pe-utilities" 
import {Loading} from 'react-pe-useful' 
import WPFestSettings from "./utilities/WPFestSettings"

class FestResults extends Component {
  state = {
    fest_members: [],
    mtype: "box",
    ganres: [],
    ganreFilterIDs: [],
    loading: true,
  }

  componentDidMount() 
  {
    console.log(this.props)
		_fetch(
			"get_site_url",
			{ id: this.props.match.params.festid },
			WPFestSettings.serviceURL,
			"",
			"wpfa", 
		  )
			.then((data) => {
			  //console.log(data)
			  this.getFestData(
				data.url,
				this.props.match.params.festid,
				WPFestSettings.token
			  )
			})
  }
  getFestData = (url, id, token) =>
    {
    _fetch(
      "get_fest_command",
      { id, command: "get_members_results", },
      WPFestSettings.serviceURL,
      token,
      "wpfa",
    )
      .then(( _data ) => {
				const data = _data.data 
        console.log(data)
				WPFestSettings.set({...data, url})  
        this.setState({
          festival_title: data.festival_title,
          table: data.table,
          link: data.link,
          loading: false,
        })
      })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="layout-state p-0 m-0">
          <Loading />
        </div>
      )
    }

    return (
      <div className="layout-state p-0 m-0">
        <div className="container-fluid">
          <h1 className="p-3">
            Cводная таблица фестиваля «{ this.state.festival_title }»
          </h1>
          <div dangerouslySetInnerHTML={{ __html: this.state.link }} />
          <div 
            dangerouslySetInnerHTML={{ __html: this.state.table }} 
            style={{ overflowX: "auto" }} 
          />
        </div>
      </div>
    )
  }
  
}
export default  compose(
	withRouter,
  )(FestResults)
