import React from "react"
import { useState } from "react"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"


const PageTypeSettings = props =>
{ 
    const [data, setData] = useState(props.data)
    const onField = (value, field) =>
    {
        
        props.onField( value, field )
        let _data = { ...data }
        _data[ field ] = value
        setData( _data )
    }
    return <>  
        <FieldInput
            field="pageType"
            title={__("Page Type")}
            visualization="basic-page-setting"
            visualisationType="pageType"
            id={props.id} 
            on={ onField }
            onChange={ onField }
            editable
            value={ data.component }
            vertical
        />
        <FieldInput
            field="pageType"
            title={__("Extended parameters")}
            visualization="basic-page-setting"
            visualisationType="pageParams"
            id={props.id} 
            on={ onField}
            onChange={ onField }
            editable
            origin={data}
            value={ data.extend_params }
            vertical={false}
        />
    </>
}
export default PageTypeSettings