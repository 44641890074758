import { Callout } from "@blueprintjs/core";
import React from "react"
import Layouts,{ getAllRoutes } from "react-pe-layouts"
import { __ } from "react-pe-utilities/dist"
import { useParams } from 'react-router-dom';
import CardRoute from "./CardRoute"

const Folder = props =>
{
    const params = useParams()   
    const currentFolderId = typeof params.folderId == "undefined"
        ?
        "-1"
        :
        params.folderId.toString()

    const onChangeRoute = (data, routeJson ) =>
    {
        props.onChangeRoute( data, routeJson )
    }
    const routes = getAllRoutes()  
    // console.log( currentFolderId ) 
    const views = Object.keys( routes )
        .filter( view => routes[view].is_cabinet )
        .filter( view => {  
            // console.log( routes[view].folder, currentFolderId, typeof params.folderId, routes[view].folder == currentFolderId )          
            return routes[view].folder == currentFolderId  
                || ( routes[view].folder === null && currentFolderId == -1 ) 
                || ( routes[view].folder === -1   && currentFolderId == -1 ) 
                || ( typeof routes[view].folder === "undefined" && currentFolderId == -1 ) 
        })
        .map(( view, i ) => { 
            const module = Layouts().modules[ routes[view].module ] 
            return <CardRoute
                key={ view }
                module={ module }
                menu={ routes[view].menu }
                data={ routes[view] }
                is_hidden={ props.folder?.is_hidden }
                moveToFolder={props.moveToFolder}
                onChangeRoute={ onChangeRoute }
                onRemoveRoute={ route => props.onRemoveRoute( route ) }
            />
        })
    return <div className=" container ">
        {
            props.folder?.is_hidden 
                ?
                <div className="position-absolute bg-light display-5 w-100 h-100 z-index-100 opacity_5 p-5 d-flex justify-content-center">
                    {__("This folder's pages has been excluded from the sitemap and is not displayed to site visitors")}
                </div> 
                :
                null
        }
        {/* FOLDER {params.folderId } */}
        <div className="row">
            { 
                views.length > 0 
                    ?
                    views
                    :
                    <Callout className="p-5 text-center">
                        {__("Elements no exists")}
                    </Callout>
            }
        </div>
        
    </div>
}
export default Folder