import React, { Component } from "react"   
import _fetch from "." 

class Festivals extends Component {
	state = {
	  offset: 0,
	  festivals: [],
	  number: 10,
	}

	componentDidMount() {
	  this.onPagi(0)
	}

	render() {
	  const { festivals } = this.state
	  const siteList = festivals.map((elem) => (
  <li className="list-group-item" key={elem.domain}>
    <div
      className="row pointer tex-sm-left text-center"
      domain={elem.domain}
      blog_id={elem.blog_id}
      onClick={this.onSite}
    >
      <div className="col-md-2 col-12">{elem.blog_id}</div>
      <div
        className="col-md-5 col-12"
      >
        {elem.domain}
      </div>
      <div className="col-md-5">
        { elem.domain_type ? <span className="member_span">{elem.domain_type}</span> : null }
      </div>
    </div>
  </li>
	  ))
	  return (
  <div className="layout-center w-100">

    <div className="w-100">
      <ul className="list-group">
        {siteList}
      </ul>
    </div>
  </div>
	  )
	}

	onPagi = (offset) => {
	  _fetch("get_all_sites", { offset, number: this.state.number })
	    .then((data) => {
	      console.log(data)
	      this.setState({
	        festivals: data.sites || [], count: data.count, offset: data.offset, number: data.number,
	      })
	    })
	}
}
export default Festivals
