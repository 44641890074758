import React, { Component } from "react" 

class FreeHTMLWidget extends Component {
  render() {
    return (
      <div className={this.props.container_class} dangerouslySetInnerHTML={{ __html: this.props.html }} />
    )
  }
}
export default FreeHTMLWidget
