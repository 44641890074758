
import React from "react" 
import { __ } from "react-pe-utilities"     
import SectionContent from "modules/pe-landing-module/views/LandingState/SectionContent"
import { GRID_HEIGHT_COEF, GRID_HEIGHT_FACTOR } from "../views/GridLayoutView"
import GridLayout from "./gridSectionParams/GridLayout" 
import { getScreenSize } from "modules/pe-landing-module/widgets/utilities/ScreenSizeList"
import getNeighbourGrid from "./utils/getNeighbourGrid"
import compareGrids from "./utils/compareGrids"

class Grids extends SectionContent
{ 
  getState(  ) {
    return {
      screenSize : getScreenSize( document.body.clientWidth )
    }
  }
  is() {
    const { sections } = this.state.data
    return Array.isArray(sections) && sections.length > 0 
  }  
  getHeight = () =>
  {
    const { grids } = this.state.data
    let y=0, h=0
    if(grids)
    { 
      const currentGrid = getNeighbourGrid(grids, this.state.screenSize) 
      if(!currentGrid) return 0
      currentGrid.forEach( element => { 
        y = Math.min( element.y, y )
        h = Math.max( h, element.y + element.h )
      }); 
    }    
    return h - y
  }
  onUpdate = ( value, field, extend_params = {} ) =>
  {    
    if(this.state.is_edit && this.props.onEdit)
    { 
      let data = {
        ...this.state.data
      }
      data[ field ] = value
      console.log( extend_params.screenSize )
      console.log( value, this.state.data.grids )
      console.log( data )
      if(field === "grids" && compareGrids(value[ extend_params.screenSize ], this.state.data.grids[ extend_params.screenSize ]) )
      {
        return
      }
      this.props.onEdit( {data} )
    }
  }
  renderContent(style) {
    const { grids, sections, grid_gap, vertical_align, exact_value } = this.state.data
    // console.log( grids )
    let vertical_align_style = {}
    switch ( vertical_align )
    {
      case "exact_value":
        vertical_align_style.alignItems = `${exact_value}%`
        break
      case "center":   
      case "start":  
      case "end":
      default:
        vertical_align_style.alignItems = vertical_align
        break
    } 

    return <div className="landing-grids  landing-section-core " 
      style={{
        ...vertical_align_style,
        ...style, 
        minHeight : this.getCanvasWidth() + "px"
      }}
    > 
      <div className="landing-grid-inner " style={{ height: this.getHeight() * GRID_HEIGHT_COEF() * GRID_HEIGHT_FACTOR() }}>
        
        <GridLayout
            gap={ grid_gap }
            sections={sections}
            grids={ grids }
            onUpdate={ this.onUpdate }
            composition={this.state.composition}
            is_edit={this.state.is_edit}
            section_width={this.props.section_width}
        />
      </div>
    </div>
  }  
}

export default Grids