import React from "react" 
import { isRole, __ } from "react-pe-utilities" 
import PreBasicState from "./PreBasicState" 
import LandingEdit from "./LandingEdit"
import LandingSnapshot from "./LandingSnapshot"
import "../assets/css/style.css"
import "../assets/css/animate.css"
import "../assets/css/panels.css"

class LandingView extends PreBasicState {
	static isLockScroll = false
	alternateRender()
	{
		return this.is_admin() 
			? 
			<LandingEdit 
				{...this.props}
				loginUser={this.loginUser}
			/>
			:			
			<LandingSnapshot 
				{...this.props} 
				loginUser={this.loginUser}
			/>
	}

	is_admin() 
	{
		return this.props.extend_params.local 
			?
			this.props.extend_params.isEdit
			:
			isRole("administrator", this.props.user)
	}
		
}
export default LandingView


export const getLandingContainer = () =>
{
	const prnt = document.getElementsByClassName("landing-container")[0] 
	return prnt
}
