import React, { Component } from "react" 

class Columns extends Component {
	constructor(props) {
		super(props)
		this.state = {
			...props,
			value: props.value ? props.value : 0,
		}
	}

	count = 13;

	render() {
		// console.log(this.state);

		const { value } = this.state
		const c = []
		for (let i = 0; i <= this.count; i++) {
			const cl = i < value + 1
				?
				i === 0 ? " bg-secondary opacity_5 " : " bg-danger "
				:
				" bg-dark "
			c.push(<div className={` w-25 h-100 mr-2${cl}`} n={i} onClick={this.onCount} key={i} />)
		}
		return (
			<div className="w-100 d-flex">
				<div className=" pr-4 title">
					{value}
				</div>
				{c}
			</div>
		)
	}

	onCount = (evt) => {
		const count = evt.currentTarget.getAttribute("n")
		this.setState({ value: parseInt(count) })
		this.props.on(this.props.field, parseInt(count))
	}
}
export default Columns
