import React, { Component } from "react"
import { initWidgets } from "react-pe-utilities" 
import { initArea } from "react-pe-utilities"
import { cssStyle, template } from "react-pe-layouts"  
import LayoutHeader from "../LayotHeader"
import LayoutContent from "../LayoutContent"
import LayoutFooter from "../LayoutFooter"

class LayoutBodyLocal extends Component
{
    state = { }
    random;
	user = { id:-1, roles: [] }
	refetch = () => {}
	componentDidMount()
    {
		initWidgets() 
    }
    render()
    {
        const user = { id: -1, roles: [] }  
		return <> 
		{
			initArea(
				"layout-app",
				{
					...this.props,
					user,
					refetchUser: this.refetch
				},
				<div className="layout block w-100">
					{
						!template().header 
							? 
							null
							: 
							initArea(
								"layout-header",
								{
									...this.props,
									user,
									refetchUser: this.refetch,
									onCurrent: this.onCurrent,
								},
								<LayoutHeader
									name={this.props.name}
									current={this.state.current}
									onCurrent={this.onCurrent}
									user={user}
									refetchUser={this.refetch}
								/>,
							) 
					}
					<LayoutContent
						current={this.state.current}
						onCurrent={this.onCurrent}
						user={user}
						onChangeStyle2={(style) => this.props.onChangeStyle(cssStyle())}
						onChangeStyle={this.props.onChangeStyle}
						refetchUser={this.refetch}
					/>
					<LayoutFooter {...this.props}/>
				</div>
			)
		}
		</>
    }
}
export default LayoutBodyLocal