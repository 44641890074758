
import React from "react"
import { template } from "react-pe-layouts"
import { initArea } from "react-pe-utilities"

class LayoutFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() { 
    return template().footer
      ?
      <footer className="layout-footer-container">
        <div className="layout-footer-menu">
        {
		      initArea("layout-footer-menu", { ...this.props })
	      }
        </div>
        <div className="layout-footer">
        {
		      initArea("layout-footer", { ...this.props })
	      }
        </div>
        <div className="layout-under-footer">
        {
		      initArea("layout-under-footer", { ...this.props })
	      }
        </div>
      </footer>
      :
      null
  }
}

export default LayoutFooter
