import React, { Component } from "react" 
import {LayoutIcon} from 'react-pe-useful'
import MotivationMember from "./MotivationMember"
import EditLabel from "./EditLabel" 
import { components } from "./data/components"

class Motivation extends Component {
  render() {
    const { columns, type, palette } = this.props
    const {
      motivation, class_name, style, design_type, contour_type, form_type, color 
    } = this.props.data
    return motivation && Array.isArray(motivation)
      ? (
        <div className={`${columns} landing-motivation  landing-section-core  ${class_name}`} style={style}>
          {
            motivation.map((e, i) => (
              <MotivationMember 
                {...e}
                key={i}
                design_type={design_type}
                form_type={form_type}
                contour_type={contour_type}
                color={color}
                palette={palette}
              />
            ))
          }
        </div>
      )
      : (
        <div
          className={` landing-empty ${class_name}`}
          style={{ ...style, height: 300 }}
        >
          <LayoutIcon
            src={components()[this.props.type].icon}
            className=" layout-icon-giant "
          />
          <div className="lead text-white">
            {components()[this.props.type].title}
          </div>
          <EditLabel
            {...this.props}
            source={type}
            onEdit={this.props.onEdit}
            isBtn
          />
        </div>
      )
  }
}
export default Motivation
