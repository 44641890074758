
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities" ;

const LandingParameters = ({
    onAdd, onDownload, onLoadChange, onClearOpen
}) => { 
    return <div
        className="py-4 w-100"
    >
        <ButtonGroup
            fill
            vertical
            large
        >
            <ButtonGroup vertical>
                <Button
                    intent={Intent.NONE}
                    onClick={onAdd}
                    large={false}
                    fill
                    className="mr-0 pe-room-btn my-1"
                    icon="plus"
                >
                    {__("Add section")}
                </Button>
                <Button
                    intent={Intent.NONE}
                    onClick={onDownload}
                    fill
                    className="mr-1 pe-room-btn my-1"
                    icon="download"
                >
                    {__("Download source json")}
                </Button>
                <div className="position-relative pe-room-btn my-1">
                    <input
                        type="file"
                        style={{
                            width: "100%",
                            height: 38,
                        }}
                        onChange={onLoadChange}
                    />
                    <Button
                        intent={Intent.NONE}
                        onClick={onDownload}
                        className="position-absolute z-index-100 untouchble pe-room-btn"
                        icon="upload"
                        fill
                        title={__("Upload Landing json")}
                    >
                        {__("Upload Landing json")}
                    </Button>
                </div>
                <Button
                    intent={Intent.DANGER}
                    onClick={onClearOpen}
                    fill
                    className="mr-1 pe-room-btn my-1"
                    icon="clear"
                >
                    {__("Clear all")}
                </Button>

            </ButtonGroup>
        </ButtonGroup>
    </div>
}
export default LandingParameters;