import React from "react"
import Dot from "./Dot"
import Moment from "react-moment"
import "react-day-picker/lib/style.css"
import "moment/locale/ru"
import $ from "jquery"
import { getFontNameByID } from "../data/PalettePresets"

class VulgarisDot extends Dot {
  constructor(props) {
    super(props)
    this.state = {
      maxH: props.maxH,

    }
  }

  renderDot() {
    const e = this.props
    const {style, className} = this.props
    const time = e.date
      ? (
        <Moment locale="ru" format="D MMMM YYYY">
          {new Date(e.date)}
        </Moment>
      )
      : ""
    const { title } = e
    const { color } = e
    const { description } = e
    const { icon } = e
    const { line_color } = e
    const { border_width } = e
    const { palette } = e

    const rand = parseInt(Math.random() * 10000000000000000000)
    let bg = e.thumbnail
    if (e.is_card_img_youtube_thrum) {
      if (e.dialog_video_service == "vimeo") {
        $.ajax({
          type: "GET",
          url: `http://vimeo.com/api/v2/video/${e.dialog_vimeo_id}.json`,
          jsonp: "callback",
          dataType: "json",
          success(data) {
            const thumbnail_src = data[0].thumbnail_large
            $(`#media_thumbnail_${rand}`).css({ backgroundImage: `url(${thumbnail_src.toString()})` })
          },
        })
      } else {
        bg = `https://img.youtube.com/vi/${e.dialog_youtube_id}/0.jpg`
      }
    }

    return <>
      {
        e.date
          ?
          <div
            className="landing-time-top"
            style={{
              minHeight: this.state.maxH,
              color: palette.card.color
            }}
          >
            <div className="landing-time-line-dot-sub-title">
              {time}
            </div>
            <div className="landing-time-line-dot-icon" style={{ backgroundImage: `url(${icon})` }} />
          </div>
          :
          null
      }
      <div
        className="landing-time-line-dot-dot"
        style={style}
      >
        <div
          className="landing-time-line-dot-line-left hidden"
          style={{
            top: e.dots_size ? e.dots_size / 2 + 20 - 3 : 80,
            left: e.dots_size ? e.dots_size / 2 - 125 : -60,
          }}
        />
        <div
          className="landing-time-line-dot-line-right hidden"
          style={{
            top: e.dots_size ? e.dots_size / 2 + 20 - 3 : 80,
            right: e.dots_size ? e.dots_size / 2 - 125 : -60,
          }}
        />
        <div
          className="landing-time-line-dot-dot-dot"
          style={{
            backgroundImage: `url(${bg})`,
            width: e.dots_size ? e.dots_size : 120,
            height: e.dots_size ? e.dots_size : 120,
            backgroundColor: color,
            borderColor: line_color,
            borderWidth: border_width
          }}
        >
          {this.renderArrow()}
        </div>
      </div>
      <div
        className="landing-time-bottom"
        style={{ minHeight: this.state.maxH }}
      >
        <div
          className="landing-time-line-dot-title"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            color: palette.card.color,
            ...palette.card.title,
            fontFamily: getFontNameByID(palette.card.title.fontFamilyID),
            ...style
          }}
        />
        <div
          className="landing-time-line-dot-description"
          dangerouslySetInnerHTML={{ __html: description }}
          style={{
            color: palette.card.color,
            ...style
          }}
        />
      </div>
    </>
  }
}
export default VulgarisDot
