import React from "react"
import { Collapse } from "@blueprintjs/core"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import Section, { getDefault } from "./Section"
import Style from "style-it"
import { getFontNameByID } from "./data/PalettePresets"
import { scrollToElementByJQuery } from "react-pe-utilities"

class Accordeon extends SectionContent {
	state = {
		...this.props,
		is_edit: false,
		is_opens: [],
	}

	componentWillUpdate(nextProps) {
		if (nextProps.is_edit !== this.state.is_edit) {
			this.setState({ is_edit: nextProps.is_edit })
		}
	}

	is() {
		const { accordeon_sections } = this.state.data
		return Array.isArray(accordeon_sections) && accordeon_sections.length > 0
	}

	renderContent(style) {
		const { palette } = this.props
		const { accordeon_sections, design_type, sign_type,
		} = this.props.data
		let __sign_type
		switch (sign_type) {
			case "caret":
				__sign_type = (
					<div className="landing-accordeon-sign">
						<i className="fas fa-caret-right" />
					</div>
				)
				break
			case "chevron":
				__sign_type = (
					<div className="landing-accordeon-sign">
						<i className="fas fa-chevron-right" />
					</div>
				)
				break
			case "crest":
				__sign_type = (
					<div className="landing-accordeon-sign">
						<i className="fas fa-plus" />
					</div>
				)
				break
			case "hothing":
			default:
				break
		}
		return (
			<div
				className={
					`landing-accordeon landing-section-core  ${this.props.data.class_name}`
				}
				style={this.getStyle(style)}
			>
				{
					accordeon_sections.map((e, i) => {
						const estyle = { ...this.getStyle(e.style), width: "100%" }
						return (
							<div
								style={estyle}
								className={
									`landing-accordeon-section ${e.className
									} ${design_type
									} ${this.state.is_opens[i] ? " open " : " "}`
								}
								id={`lacoordeon-${this.props.id}-${i}`}
								key={i}
							>
								{
									Style.it(
										`.landing-accordeon-button {
											font-family:${ getFontNameByID( palette.card.title.fontFamilyID ) };
											font-weight:${ getFontNameByID( palette.card.title.fontWeight ) };
											font-size:${ getFontNameByID( palette.card.title.fontSize ) };
										}`,
										<div
											className={`${"landing-accordeon-button " } ${design_type}`}
											i={i}
											onClick={() => this.onOpen(i)}
										>
											<span dangerouslySetInnerHTML={{__html:__(e.title)}} />
											{__sign_type}
										</div>
									)
								}

								<Collapse isOpen={this.state.is_opens[i]}>
									{
										Array.isArray(e.section)
											?
											e.section.map((section, index) => (
												<div className="position-relative w-100" key={index}>
													<Section													
														{...section}
														style={{ 
															color: this.props.revertColor, 
															height: "100%", 
															...this.getStyle(section.style) 
														}}
														i={index}
														user={this.props.user}
														is_edit={this.state.is_edit}
														level={this.props.level + 1}
														onEdit={this.onEdit}
														onUp={this.onUp}
														onDn={this.onDn}
														onAdd={this.onAdd}
														onRnv={this.onRnv}
														revertColor={this.props.revertColor}
														palette={this.props.palette}
													/>
													<div 
														className="landing-accordeon-close-btn"
														onClick={(() => this.clodeAcc( i ))}
													>
														<div className="pl-4">{ __sign_type }</div>
													</div>
												</div>
											))
											:
											<div />
									}
								</Collapse>
							</div>
						)
					})
				}
			</div>
		)
	}
	clodeAcc = i =>
	{
		let is_opens = [...this.state.is_opens]
		is_opens[i] = false
		scrollToElementByJQuery( `#lacoordeon-${this.props.id}-${i}`, 1600, 60 )
		this.setState({is_opens})
	}
	onOpen = (i) => {
		const is_opens = [...this.state.is_opens]
		is_opens[i] = !is_opens[i]
		this.setState({ is_opens })
	}

	onEdit = (data, id) => {
		console.log("onEdit", id, data, this.state)
		const sections = Array.isArray(this.state.data.sections)
			?
			[...this.state.data.sections]
			:
			[]
		const secs = []
		sections.forEach((e) => {
			if (e.id === data.id) {
				secs.push(data)
			} else {
				secs.push(e)
			}
		})
		this.setState({ data: { ...this.state.data, sections: secs } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections: secs } },
			this.props.id,
		)
	}

	onUp = (data) => {
		console.log("onUp", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data - 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onDn = (data) => {
		console.log("onDn", data, this.state); const sections = [...this.state.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data + 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onAdd = (data) => {
		console.log("onAdd", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = getDefault()
		sections.splice(data + 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onRnv = (data) => {
		console.log("onRnv", data, this.state.data.sections)
		const sections = [...this.state.data.sections]
		sections.splice(data, 1)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onHide = (id, is_hide) => {
		console.log("HIDE", id, is_hide)
	}

	onRemoveFloat = (float_id) => {

	}

	onUpdateFloat = (data, float_id, section_id) => {

	}
}
export default Accordeon
