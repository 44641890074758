import React from "react"
import { CARD_SEPARATOR } from "../Card"
import { getColor } from "../data/getColor"
import { getStyle } from "../Section"


const CardFieldIcon = ({ e, i, cardFieldsData, style, class_name, bStyle, cardFields, palette, ID }) => {
    let handledStyle = { ...cardFieldsData.style }
    let fieldData = e.field
        ?
        e.field.split(CARD_SEPARATOR())
        :
        ["", 40]
    delete handledStyle.border
    delete handledStyle.borderBottom
    
    
    let st = {
        color: palette && palette.card ? palette.card.color : null,
        ...style,
        ...handledStyle,
        fontFamily: "Font Awesome 5 Free",
        height: cardFieldsData.height,
        zIndex: 10
    }
    let beforeStyle = {}
    let beforeStyle2 = {}
    const beforeStyle3 = {}
    let afterStyle = {}
    let afterStyle2 = {}
    const afterStyle3 = {}
    switch (cardFieldsData.variant) {
        case 0:
        case "0":
            //st.height = "auto"
            delete st.border
            delete st.borderBottom
            delete st.borderTop
            delete st.borderLeft
            delete st.borderRight
            break
        case "1":
        case 1:
            if (palette && palette.card && palette.card.title) {
                let handledStyle = { ...cardFieldsData.style }
                delete handledStyle.border
                delete handledStyle.borderBottom
                st = {
                    ...st,
                    ...palette.card.title,
                    ...handledStyle,
                }
            }
            // console.log(st, cardFieldsData.style, e)
            break
        case "2": // corner
        case 2: // corner
            st = {
                ...st,
                width: cardFieldsData.height * 1.33,
                height: cardFieldsData.height * 1.33,
            }
            switch (cardFieldsData.variant_param_3) {
                case "pennon":
                    /*
                    0		r: -31
                            b: 82
                    20		r: -43
                            b: 68
                    40		r: -49
                            b: 50
                    60		r: -57
                            b: 33
                    80		r: -65
                            b: 16
                    100		r: -72
                            b: 0
                    120		r: -80
                            b: -18
                    */
                    const rot = -45
                    const bottom0 = 83
                    const right0 = -34
                    const height0 = parseInt(cardFieldsData.variant_param_4)
                    const shift = Math.sqrt(height0 * height0 / 6)

                    st = {
                        width: Math.sqrt(cardFieldsData.height * cardFieldsData.height * 2),
                        transform: `rotate(${rot}deg)`,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        position: "absolute",
                        top: "auto",
                        bottom: bottom0 - shift * 2 + 10,
                        right: right0 - shift,
                        backgroundColor: bStyle.backgroundColor,
                        height: height0,
                    }
                    break
                case "corner":
                default:
                    beforeStyle = {
                        borderWidth: cardFieldsData.height ? cardFieldsData.height / 2 : 0,
                        borderTopColor: cardFieldsData.variant_param_1 == 0 || cardFieldsData.variant_param_1 == 1
                            ? bStyle.backgroundColor
                            : null,
                        borderBottomColor: cardFieldsData.variant_param_1 == 2 || cardFieldsData.variant_param_1 == 3
                            ? bStyle.backgroundColor
                            : null,
                        borderLeftColor: cardFieldsData.variant_param_1 == 0 || cardFieldsData.variant_param_1 == 2
                            ? bStyle.backgroundColor
                            : null,
                        borderRightColor: cardFieldsData.variant_param_1 == 1 || cardFieldsData.variant_param_1 == 3
                            ? bStyle.backgroundColor
                            : null,
                    }
                    break
                // default:
                //	st = st;
            }
            break
        case "3": // pennon
        case 3: // pennon
            beforeStyle = {
                borderTopColor: bStyle.backgroundColor,
                borderRightColor: bStyle.backgroundColor,
            }
            break
        case "4": // dart
        case 4: // dart
            const arroHeadwWidth = cardFieldsData.variant_param_1
            const pinWidth = cardFieldsData.variant_param_2
            const arroHeadHeight = cardFieldsData.variant_param_3 || 0.1
            const clipPath = `polygon(0 ${arroHeadHeight / 2}px, calc(100% - ${arroHeadwWidth}px) ${arroHeadHeight / 2}px, calc(100% - ${arroHeadwWidth}px) 0, 100% 50%, calc(100% - ${arroHeadwWidth}px) 100%, calc(100% - ${arroHeadwWidth}px) calc(100% - ${arroHeadHeight / 2}px), 0% calc(100% - ${arroHeadHeight / 2}px))`

            // console.log( cardFieldsData );
            // console.log( clipPath );

            beforeStyle = {
                ...beforeStyle,
                backgroundColor: bStyle.backgroundColor,
                width: `calc(100% + ${pinWidth}px)`,
                height: `calc(100% + ${arroHeadHeight}px)`,
                clipPath,

            }
            afterStyle = {
                ...afterStyle,
                width: `calc(100% + ${pinWidth}px)`,
                height: `calc(100% + ${arroHeadHeight}px)`,
                clipPath,
            }
            break
        case "6": // kernel
        case 6: // kernel
            var outer = cardFieldsData.height + 20
            var inner = cardFieldsData.height - 40
            var aftrW
            var aftrW2
            /*
            cut width:
                60  - 0
                70  - 5
                80  - 10
                90	- 15
                100 - 20
                110 - 25
                120 - 30
                140 - 40 - 65
                160 - 50 - 75
                170 - 55
                180 - 60 - 85
                200 - 70
                210 - 75
                220 - 80
                230 - 85
                240 - 90
                260 - 100
                270 - 105
                280 - 110 - 135
            */

            st = {
                ...st,
                display: "flex",
                alignItems: "center",
            }
            // console.log( outer, cardFieldsData.variant_param_2 )
            const bw2 = outer + cardFieldsData.variant_param_2 * 2

            switch (cardFieldsData.variant_param_1) {
                case "2": // wave
                    aftrW2 = (cardFieldsData.height - 60) / 2
                    aftrW = aftrW2 + 25
                    beforeStyle = {
                        backgroundColor: bStyle.backgroundColor,
                        width: cardFieldsData.height - 0,
                        height: cardFieldsData.height - 0,
                    }
                    beforeStyle2 = {
                        borderWidth: cardFieldsData.variant_param_2,
                        width: bw2 || "100%",
                        height: bw2 || "100%",
                        borderRightColor: bStyle.backgroundColor,
                    }
                    afterStyle = {
                        height: cardFieldsData.variant_param_2,
                        width: aftrW2 ? `calc(50% - ${aftrW2}px + 6px)` : null,
                        backgroundColor: bStyle.backgroundColor,
                        left: `calc(50% + ${(outer + cardFieldsData.variant_param_2) / 2}px)`,
                    }
                    afterStyle2 = {
                        height: cardFieldsData.variant_param_2,
                        width: `calc(50% - ${0}px + 6px)`,
                        backgroundColor: bStyle.backgroundColor,
                    }
                    break
                case "3": // laurel
                    aftrW2 = (cardFieldsData.height - 60) / 2
                    aftrW = aftrW2 + 45
                    beforeStyle = {
                        borderWidth: cardFieldsData.variant_param_2,
                        borderColor: bStyle.backgroundColor,
                        width: inner,
                        height: inner,
                        backgroundColor: cardFieldsData.variant_param_3,
                    }
                    beforeStyle2 = {
                        borderWidth: cardFieldsData.variant_param_2,
                        width: bw2 || "100%",
                        height: outer + cardFieldsData.variant_param_2 * 2,
                        borderTopColor: bStyle.backgroundColor,
                    }
                    afterStyle = {
                        height: cardFieldsData.variant_param_2,
                        width: aftrW ? `calc(50% - ${aftrW}px + 6px)` : null,
                        backgroundColor: bStyle.backgroundColor,
                    }
                    afterStyle2 = {
                        height: cardFieldsData.variant_param_2,
                        width: `calc(50% - ${aftrW2}px + 6px)`,
                        backgroundColor: bStyle.backgroundColor,
                    }
                    break
                default:
                    afterStyle2 = afterStyle2
                    break
            }
            break
    }
     
    return (
        <>
            <div
                className="before"
                style={beforeStyle}
            />
            <div
                className="before2"
                style={beforeStyle2}
            />
            <div
                className="before3"
                style={beforeStyle3}
            />
            <div
                className={` string ${class_name} ${fieldData[0]}`}
                style={{
                    //...st, 
                    fontSize: fieldData[1]
                        ?
                        parseInt(fieldData[1])
                        :
                        cardFieldsData.variant_param_6,
                    ...getStyle(cardFieldsData.variant_param_10),
                    borderRadius:"100%",
                    borderStyle: "solid",
                    borderWidth: cardFieldsData.variant_param_9 ? cardFieldsData.variant_param_9 : 0,
                    padding: cardFieldsData.variant_param_8,
                    //borderColor: palette && palette.card ? palette.card.color : null,
                    backgroundColor: getColor( cardFieldsData.variant_param_5, palette ),
                }}
            /> 
            <div className="after" style={afterStyle} />
            <div className="after2" style={afterStyle2} />
            <div className="after3" style={afterStyle3} />
        </>
    )
}
export default CardFieldIcon