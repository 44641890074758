import React from "react"
import Dot from "./Dot"
import Moment from "react-moment" 
import "react-day-picker/lib/style.css" 
import "moment/locale/ru" 
import { getFontNameByID } from "../data/PalettePresets"

class SpileDot extends Dot {
  constructor(props) {
    super(props)
    this.state = {
      height: 100,
      maxH: props.maxH,
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.maxH !== this.state.maxH) {
      // console.log( nextProps );
      this.setState({ maxH: nextProps.maxH })
    }
  }

  renderDot() {
    const e = this.props
    const time = e.date
      ?
      <Moment locale="ru" format="D MMMM YYYY">
        {new Date(e.date)}
      </Moment>
      :
      null
    const { title } = e
    const { description } = e
    const { palette } = e
    return <>
      <div
        className="landing-time-top"
        style={{ minHeight: this.state.maxH }}
      >
        <div className="landing-time-line-dot-title" />

      </div>
      <div
        className="landing-time-line-dot-dot"
        style={{}}
      >
        <div
          className="landing-time-line-dot-dot-dot"
          style={{
            width: e.dots_size ? e.dots_size : 120,
            height: e.dots_size ? e.dots_size : 120,
            backgroundColor: e.backgroundColor,
            alignItems: "flex-start",
            borderWidth: 10,
            marginTop: e.height,
          }}
        >
          {this.splie()}

        </div>
      </div>
      <div
        className="landing-time-bottom p-2"
        style={{ minHeight: this.state.maxH }}
      >
        <div 
          className="landing-time-line-dot-sub-title" 
          dangerouslySetInnerHTML={{__html: title}}
          style={{
            color: palette.card.color,
            ...palette.card.title,
            fontFamily: getFontNameByID(palette.card.title.fontFamilyID)
          }} 
        />
        <div 
          className="landing-time-line-dot-description" 
          dangerouslySetInnerHTML={{__html:description}}
          style={{
            color: palette.card.color
          }}
         />
      </div>
    </>
  }

  splie() {
    const {
      color, dots_size, height, thumbnail, icon, is_shadow, palette
    } = this.props
    const st0 = { fill: "url(#SVGID_1_)" }
    const st3 = { fill: "url(#SVGID_3_)" }
    const st1 = { fill: color || "grey" }
    const st2 = { opacity: 0.18 }

    const fw = dots_size - 40
    const prpr = fw / 215
    const yy = 107.7 * prpr
    const hh = height ? height * prpr : 100
    const fh = 768 * prpr
    const fh2 = 107 * prpr
    const shadow = is_shadow && false
      ?
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={fw}
        height={500 * prpr}
        viewBox="0 0 215 768"
        style={{
          top: 0,
          position: "absolute",
        }}
      >
        <g id="shadow">
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="107.7939" y1="611.5692" x2="107.7939" y2="280.6064">
            <stop offset="3.276967e-02" style={{ stopColor: "#00000000" }} />
            <stop offset="0.848" style={{ stopColor: "#00000042" }} />
          </linearGradient>
          <rect style={st0} x="0.6" y={hh + yy} width="214.4" height="631" />
        </g>
      </svg>
      : 
      null
    return (
      <div
        className="position-relative"
        style={{
          marginLeft: -fw + 0,
          marginTop: -10 - height,
        }}
      >
        {shadow}
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={fw}
          height={fh2}
          viewBox="0 0 215 107"
          style={{
            top: height + yy - 1,
            position: "absolute",
          }}
        >
          <g id="bottom_1_">
            <path style={st1} d="M 107.5, 107.5 C 166.9, 107.5, 215, 59.4, 215, 0 H 0 C 0, 59.4, 48.1, 107.5, 107.5, 107.5 z" />
          </g>
          <g id="bottom_1_D">
            <path style={st2} d="M 107.5, 107.5 C 166.9, 107.5, 215, 59.4, 215, 0 H 0 C 0, 59.4, 48.1, 107.5, 107.5, 107.5 z" />
          </g>
        </svg>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={fw}
          height={fh}
          viewBox="0 0 215 768"
          style={{
            top: 0,
            position: "absolute",
          }}
        >
          <g id="bevel_1_">
            <rect y="107.7" style={st1} width="215" height={height / prpr} />
          </g>
          <g id="bevel_x5F_dark">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="107.5" y1="280.6064" x2="107.5" y2="107.5">
              <stop offset="6.830000e-02" style={{ stopColor: "#000000" }} />
              <stop offset="1" style={{ stopColor: "#000000" }} />
            </linearGradient>
            <rect y="107.7" style={st2} width="215" height={height / prpr} />
          </g>
          <g id="round">
            <circle style={st1} cx="107.5" cy="107.5" r="107.5" />
          </g>
        </svg>
        <div
          className="landing-time-line-dot-thumb"
          style={{
            backgroundImage: `url(${thumbnail})`,
            top: 25,
            left: 25,
            width: fh2 * 2 - 50,
            height: fh2 * 2 - 50,

          }}
        >
          {this.renderArrow()}
        </div>
        <div
          className="landing-time-line-dot-icon"
          style={{
            backgroundImage: `url(${icon})`,
            position: "absolute",
            top: -48,
          }}
        />
      </div>
    )
  }
}
export default SpileDot
