import { Callout } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities/dist"
import VK, {Poll as VKPoll} from "react-vk"

export default ({ api_key, poll_id }) => {
    try
    {
        console.log( api_key, poll_id );
         return api_key && poll_id  
         ?
         <>
            <div className="landing-vp-poll" id="vp_pol"> </div>
            <VK apiId={api_key} onApiAvailable={() => console.log( "VK ready" )}>
                <VKPoll elementId="vp_pol" pollId={ poll_id } options={{ heigth : 280 }} />
            </VK>
        </>
        :
        <Callout>{__("Error data")}</Callout>
    }
    catch(e)
    {
        return <Callout>{__("No poll exists")}</Callout>
    }
   
}