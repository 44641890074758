import React, { useState } from 'react'
import PropTypes from 'prop-types'
import User from "../../utilities/User"
import WPFestSettings from '../../utilities/WPFestSettings'
import { __ } from 'react-pe-utilities/dist'
import { Icon, Intent } from '@blueprintjs/core'
import _fetch from '../..'
import Roles from "../../utilities/Roles"


const RequestRoleForm = props => {
    const all_roles = [...WPFestSettings.fmru_evlbl_roles, "administrator"]
    const uRoles = User.roles
   
    const [reqRoles, setReqRoles] = useState(props.reqRoles || [])
    //console.log(reqRoles)
    const onRequire = evt =>
    {
        const role = evt.currentTarget.getAttribute("role")
        _fetch(
            "get_fest_command",
            {festId: props.festid, role: role, command: "req_role",},
            WPFestSettings.serviceURL,
            WPFestSettings.getToken(WPFestSettings.url),
            "wpfa",
        )
            .then((_data) => {
                const data = _data.data 
                //console.log( data )
                setReqRoles( data.reqRoles ) 
            })
    }
    return  all_roles.map( aRole => {
        
        const disabled = uRoles && Array.isArray(uRoles)
	        ? 
            uRoles.filter(uRole => uRole == aRole).length > 0
				|| reqRoles.filter(reqRole => reqRole === aRole).length > 0
	        :				
            false 
        const icon = disabled
            ?		
            reqRoles.filter(reqRole => reqRole === aRole ).length > 0
                ?	
                <div className=" p-3 text-success border border-success">
                {__("Wait answer")}
                </div>
                :						
                <Icon intent={Intent.SUCCESS} icon="tick" className=" m-3 h-100" />
            : 	
            <div className="p-3 bg-danger text-light">
                { __("Do request") }
            </div>
        return <button
            className="btn btn-block btn-light px-3 d-flex transparent"
            key={aRole}
            disabled={disabled}
            role={aRole}
            onClick={disabled ? null : onRequire}
        >
            <div className=" flex-grow-1 mr-3 w-100">
                <div className="lead text-left">
                    { __(aRole) }
                </div>
                <div className="small text-left text-wrap">
                    { __( Roles()[aRole]?.caps_description ) }
                </div>
            </div>
            { icon }
        </button>  
    })
     /**/
    return null
}

RequestRoleForm.propTypes = {}

export default RequestRoleForm