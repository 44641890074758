import React, { Component, Suspense, lazy} from "react"
import {  Button, Popover, } from "@blueprintjs/core"

import { __ } from "react-pe-utilities"
import {getWidgets} from "react-pe-utilities"
//const getWidgets =lazy(() => import("react-pe-utilities"))
import { widgets } from "react-pe-layouts"
import Widget from "./Widget"
import {Loading} from 'react-pe-useful'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
 

class Widgets extends Component {
	constructor(props) {
		super(props)
		//console.log(this.props.area)
		this.state = {
			area: [...this.props.area],
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps)
	}

	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	};

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return
		}
		const area = this.reorder(
			this.state.area,
			result.source.index,
			result.destination.index,
		)

		this.setState({
			area,
		})
	}

	render() {
		const { area } = this.state
		const _widgets = area.length > 0
			? area.map((item, index) => (
				<Draggable key={index} draggableId={item.component} index={index}>
					{
						(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
							>
								<Widget item={item} n={index} onUpdate={this.onUpdate} onRemoved={this.onRemoved} />
							</div>
						)
					}
				</Draggable>
			))
			: (
				<div className="alert alert-secondary m-2  min-width-400">
					{__("No elements exist")}
				</div>
			)
		//console.log(area, currArea)
		const wList = []
		let i = 0
		const eList = []
		for (const w in getWidgets) {
			const _www = widgets()[w]
			const _title = _www ? _www.title : w
			if (_www && _www.areas && _www.areas.filter((e) => e === this.state.currArea).length === 0) continue
			wList.push(
				<Button fill key={i} onClick={this.onAdd} w={w}>
					{__(_title)}
				</Button>,
			)
			i++
		}
		return (
			<div className="admin-widgets-container-main position-relative">
				<div className="admin-widgets-container">
					<Suspense fallback={<Loading />}>
						<DragDropContext onDragEnd={this.onDragEnd}>
							<Droppable droppableId="droppable">
								{
									(provided, snapshot) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{_widgets}
										</div>
									)
								}
							</Droppable>
						</DragDropContext>
					</Suspense>
				</div>
				<div className="admin-widgets-add">

					<Popover 
						content={(
							<div className="p-0">
								<div className="p-0 max-height-250 overflow-y-auto">
									<div style={{ overflowX: "hidden", overflowY: "auto", marginBottom: 10 }}>
										{wList}
									</div>
								</div>
							</div>
						)}
					>
						<div className="btn btn-link text-secondary btn-sm mt-2" >
							{__("Add widgets")}
						</div>
					</Popover>
					<Popover 
						content={(
							<div className="p-0">
								<div className="p-0 max-height-250 overflow-y-auto">
									<div style={{ overflowX: "hidden", overflowY: "auto", marginBottom: 10 }}>
										{eList}
									</div>
								</div>
							</div>
						)}
					>
						<div className="btn btn-link text-secondary btn-sm mt-2" >
							{__("Add extentions")}
						</div>
					</Popover>
				</div>
			</div>
		)
	} 

	onAdd = (evt) => {
		const w = evt.currentTarget.getAttribute("w")
		const area = [...this.state.area]
		area.push({
			component: w,
		})
		this.setState({
			area,
			isCreateOpen: false,
			canSave: true,
		})
		this.props.onUpdate(area)
	}

	onUpdate = (data, n) => {
		const area = [...this.state.area]
		area[n] = data
		this.setState({
			area,
			isCreateOpen: false,
			canSave: true,
		})
		console.log(data, n)
		this.props.onUpdate(area)
	}

	onRemoved = (component) => {
		const area = [...this.state.area]; let
			n
		area.forEach((e, i) => {
			if (e.component === component) {
				n = i
			}
		})
		area.splice(n, 1)
		console.log(n, component, area)
		this.setState({
			area,
			canSave: true,
		})
		this.props.onUpdate(area)
	}
}
export default Widgets
