import React from "react" 
import { __ } from "react-pe-utilities"   
import SectionContent from "./SectionContent"

class IFrame extends SectionContent { 
  is() {
    const { src } = this.state.data
    return src.toString().indexOf("http") === 0
  }
  getState()
  {
    this.ref = React.createRef()
    return {}
  }
  componentDidMount()
  {
    //this.ref.current.contentWindow.console.error = function() { /* nop */ }
  }
  renderContent(style) {
    const {src, height, } = this.props.data;
    const margin_top = this.props.data.margin_top ? this.props.data.margin_top : 0
    return <div className="landing-iframe  landing-section-core " style={{...style, height}}>
        <iframe
            title={ src } 
            src={src} 
            style={{height: height + margin_top, marginTop: -margin_top }} 
            ref={this.ref} 
            loading = "lazy"
            sandbox="allow-scripts allow-presentation allow-same-origin allow-forms allow-orientation-lock "
            allow="camera 'none'; microphone 'none'"
            seamless 
        >
          <p>{__("Old browser for show iframe")}</p>      
        </iframe>
    </div>
  }
}

export default IFrame