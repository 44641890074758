import React from "react"
import { Callout, Icon,  Tabs, Tab, Tag } from "@blueprintjs/core"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router"
import {
    getQueryArgs,
    querySingleName,
    querySingle,
} from "react-pe-layouts"
 
import BasicState from "react-pe-basic-view"
import { schema } from "react-pe-layouts";
import { __ } from "react-pe-utilities";
import {Loading} from 'react-pe-useful'
import Moment from "react-moment"
//import Feed from "../../../layouts/BasicState/Feed"
import { Feed } from "react-pe-basic-view"
import { _Component } from "./FeedDataTypeView"
import { Link } from "react-router-dom"
import { data_type_link_url, data_type_feed_url } from "react-pe-utilities"

export class SingledDataTypeView extends BasicState {
    basic_state_data()
    {
        return {
            loading: true,
            data: {},
            curNavId : "tab0",
            containerClassName: "container"
        }
    }
    getTitle() {
        const singled_data_type = schema()[this.props.singled_data_type]
        if (singled_data_type) {
            return __(singled_data_type.name)
        }
        return this.props.singled_data_type
    }
    updateUpdate()
    {
        this.setState({ loading: true, curNavId : "tab0" })
        const id = parseInt(this.props.match.params.id)
        const { singled_data_type } = this.props
        const query_name = querySingleName(singled_data_type)
        const query_args = getQueryArgs(singled_data_type)
        const query = querySingle(singled_data_type, query_name, query_args, id)
        this.props.client.query({ query, variables: { id: id } })
            .then((result) => {
                //console.log( result, query_name );
                const visibled_value = schema()[singled_data_type].visibled_value
                this.setState({
                    data: result.data[query_name],
                    loading: false,
                    route: {
                        ...this.state.route
                    }
                })
            })
    }
    stateDidMount() {
        this.updateUpdate()
    }
    componentDidUpdate(nextProps)
    {
        if(this.props.match.params.id == nextProps.match.params.id) return
        this.updateUpdate()
    }
    renderTitle()
    {
        return null
    }
    onFeedTab = curNavId =>
    {
        this.setState({curNavId})
    }
    getThumbnail = () =>
    {
        return {}

    }
    getOrderLayout = () =>
    {
        const { loading, data } = this.state
        const order = data.order
            ? 
            <div className="single-data-type-order">
                {data.order}
            </div>
            :
            null
        return order
    }
    getPostTypeLayout = () =>
    {
        const { singled_data_type } = this.props
        return <span className="single-data-type-type mr-2">
           { __(schema()[singled_data_type].name)} 
        </span>
    }
    getTitleLayout = () =>
    {
        const { singled_data_type } = this.props
        const data_scheme = schema()[singled_data_type] 
        let title = data_scheme.visibled_value ? data_scheme.visibled_value : "post_title"
        return <span className="single-data-type-title">
            {this.state.data[title].toString()}
        </span>
    }
    getLeftLayout = () =>
    {
        const _tabs = Array.isArray(this.props.external_settings.feeds)
            ?
            this.props.external_settings.feeds.map((feed, i) =>
            {
                return <Tab  
                    key={i} 
                    title={ <span> {__(feed.name[1])} </span> }
                    id={"tab"+i}
                    large

                    panel={
                        <div> 
                            <Feed 
                                component={_Component}
                                data_type={feed.component}
                                offset={0}
                                count={310}
                                is_hide_pagi={false}
                                class_name={` row `}
                                paging={this.getPaging(feed)}
                                params={{ 
                                    cardClass: " py-3 "
                                }}
                            />
                        </div>
                    }
                />
            })       
            :
            null

        const tabs = <Tabs selectedTabId={this.state.curNavId} onChange={this.onFeedTab}>
            {_tabs}
        </Tabs>
        return tabs
    }
    getRightLayout = () =>
    {
        const { singled_data_type } = this.props
        const data_scheme = schema()[singled_data_type]
        const fields = Object.keys(data_scheme.apollo_fields)
            .filter(field => field !== "__typename" && 
                field !== "post_title" &&
                field !== "post_content" &&
                !data_scheme.apollo_fields[field].hidden
            )
                .filter(field => {
                    //console.log(this.props.external_settings.show_fields.filter(e => e === field), field)
                    return this.props.external_settings.show_fields.filter(e => e === field).length > 0
                })
                    .map((field, i) => {
                        return <div className="row" key={i}>
                            {this.renderType(field)}
                        </div>
                    })
        return fields
    }
    getThumbnailLayout = () =>
    {
        const { data } = this.state
        const { singled_data_type } = this.props
        const data_scheme = schema()[singled_data_type] 
        let thumbnail;
        Object.keys(data_scheme.apollo_fields).forEach(field => {
            if (
                field === "thumbnail" && 
                data[field] && 
                data[field] !== "false" &&
                this.props.external_settings.show_fields.filter(e => e === field).length > 0
            ) 
            {                
                thumbnail = <div
                    className=" col-12 single-data-type-thumbnail "
                    style={{ backgroundImage: "url(" + data[field] + ")" }}
                >                    
                    <div className=" container ">
                        {this.getOrderLayout()}
                    </div>
                    <div className="single-data-type-thumbnail-titles container">
                       
                        { this.getPostTypeLayout() } 
                        { this.getTitleLayout() } 
                        <Link className="single-data-type-btn ml-auto" to={ "/" + data_type_feed_url( singled_data_type ) }>
                            { __( "all" ) } { __( schema()[singled_data_type].plural ) }
                        </Link> 
                    </div>
                </div>
            }
        })
        thumbnail = thumbnail 
            ?
            thumbnail 
            :
            <div 
                className=" col-12 single-data-type-thumbnail"                 
                style={ this.getThumbnail() }
            >
                <div className=" container ">
                    {this.getOrderLayout()}
                </div>
                <div className="single-data-type-thumbnail-titles container "> 
                    { this.getPostTypeLayout() } 
                    { this.getTitleLayout() } 
                    <Link className="single-data-type-btn ml-auto" to={ "/" + data_type_feed_url( singled_data_type ) }>
                        { __( "all" ) } { __( schema()[singled_data_type].plural ) }
                    </Link> 
                </div>
            </div>
        return thumbnail
    }
    getColumnProportia = () =>
    {
        return [8, 4]
    }
    addRender() {
        //console.log(this.props);
        const { loading, data } = this.state
        const { singled_data_type } = this.props
        if (loading) {
            return <Loading />
        }
        
        if (!data.id) {
            return <Callout className="p-5">
                {__(schema()[singled_data_type].name) + " " + __("not exists")}
            </Callout>
        }
        
        return <div className="p-0 " style={{marginLeft:-20, marginRight:-20, marginTop:-20}}>
            <div className="row m-0" >
                { this.getThumbnailLayout() }
                <div className={ `boo ${this.state.containerClassName}` }>
                    <div className="row">
                        <div className={`px-3 col-md-${this.getColumnProportia()[0]}`}>
                            <div className="row">
                                {this.renderType("post_content")}
                            </div>
                            <div>
                                { this.getLeftLayout() }  
                            </div> 
                           
                        </div>
                        <div className={`px-3 col-md-${this.getColumnProportia()[1]}`}>
                            { this.getRightLayout()}
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    }
    renderType(field) {
        const { data } = this.state
        const { singled_data_type } = this.props
        const data_scheme = schema()[singled_data_type]
        const visibled_value = schema()[singled_data_type].visibled_value
        if (!data[field] || field === "thumbnail_name" || field === "id" || field === "order") return null;
        if (visibled_value === field) {
            return <div className="col-12 my-4">
                <div className="lead title mb-2">
                    {data[field].toString()}
                </div>
            </div>
        }
        else {
            switch (data_scheme.apollo_fields[field].type) {
                case "media":
                    return field === "thumbnail"
                        ?
                        null
                        :
                        <img
                            src={data[field]}
                            alt={data_scheme.apollo_fields[field].title}
                            style={{
                                maxWidth: "100%",
                                height: 350
                            }}
                        />
                case "external":
                    console.log(data[field])
                    let extFields
                    if (data_scheme.apollo_fields[field].kind === "type") {
                        extFields = Object.keys(data[field]).map((ef, i) => {
                            const visibled_value = schema()[data_scheme.apollo_fields[field].component].visibled_value
                            return ef === visibled_value
                                ?
                                <Link 
                                    to={
                                        "/" + data_type_link_url( data_scheme.apollo_fields[field].component) + 
                                        "/" + data[field].id
                                    }
                                >
                                    <Tag className="px-2 title" title={data[field][ef]} key={i}>{data[field][ef]}</Tag>
                                </Link>                                
                                :
                                null
                        })
                    }
                    else {
                        extFields = "data[field].toString()";

                    }
                    return <div className="col-12 my-2 ">
                        {__(data_scheme.apollo_fields[field].title)}: <span className="">{extFields}</span>
                    </div>
                case "array":
                    return <div className="col-12 my-2">
                        {__(data_scheme.apollo_fields[field].title)}: <span className="px-2">
                            {
                                Array.isArray(data[field]) && data[field].length > 0
                                    ?
                                    data[field].map((e, i) => {
                                        return <Tag key={i}>
                                            {
                                                typeof e == "string" || typeof e === "number"
                                                    ?
                                                    e.toString()
                                                    :
                                                    e.post_title
                                            }
                                        </Tag>
                                    })
                                    :
                                    <span className="px-2">{__("not exists")}</span>
                            }
                        </span>
                    </div>
                case "text":
                    return <div className="col-12 my-2" dangerouslySetInnerHTML={{ __html: data[field].toString() }} />
                case "int":
                    return <div className="col-12 my-2 ">
                        {__(data_scheme.apollo_fields[field].title)}: <span className="title">{data[field].toString()}</span>
                    </div>
                case "date":
                    return <div className="col-12 my-2 ">
                        {__(data_scheme.apollo_fields[field].title)}:
                        <span>
                            <Moment locale="ru" format="D MMMM YYYY HH:mm" className="title pl-2">
                                {new Date(data[field])}
                            </Moment>
                        </span>
                    </div>
                case "boolean":
                    return data[field]
                        ?
                        <div className="col-12 my-2">
                            {__(data_scheme.apollo_fields[field].title)}
                            <Icon icon="tick" className="text-success ml-2" />
                        </div>
                        :
                        <div className="col-12 my-2">
                            {__(data_scheme.apollo_fields[field].title)}
                            <Icon icon="cross" className="text-danger ml-2" />
                        </div>

                default:
                    return <div className="col-12 my-2">
                        {__(data_scheme.apollo_fields[field].title)}: <span className="title">{data[field].toString()}</span>
                    </div>

            }
        }

    }
    getPaging = ( feed ) =>
    {
        // console.log(this.props)
        console.log(feed)
        switch(feed.filter_type)
        {
            case "taxonomies":
                return `taxonomies :{ tax_name : "${feed.field}", term_ids : [ ${this.props.match.params.id} ] }`
            case "meta":
                return `metas :[{key :"${feed.field}", value :[ "${this.props.match.params.id}" ] }]`
            default:
                console.log(feed)
                switch(feed.field)
                {
                    case "children":
                        return `parent: "${this.props.match.params.id}"`
                    default:
                        return ` ${feed.field}: "${this.props.match.params.id}" ` 
                }
        }
    }
}

const composed = compose(
    withRouter,
    withApollo,
)(SingledDataTypeView) 
export default composed