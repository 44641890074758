import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import InnerGrid from "./InnerGrid"; 
import GridCell from "../sections";
import { GRID_HEIGHT_COEF, GRID_HEIGHT_FACTOR } from "../GridLayoutView";
import { SizeMe } from 'react-sizeme'
import Style from "style-it";
import getNeighbourGrid from "modules/pe-grid-layout-module/widgets/utils/getNeighbourGrid";
import { getScreenSizeWidth, ScreenSizeShortObject } from "modules/pe-landing-module/widgets/utilities/ScreenSizeList";

class GridLayoutContainer extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            mounted: false,
            layouts: props.layouts
        };
    }
    componentDidMount() {
        this.setState({ mounted: true }); 
    }
    componentDidUpdate(prevProps, prevState) 
    {
      //  console.log( prevProps.layouts !== this.props.layouts )
      //  console.log( prevProps.layouts[this.props.screenSize][0].h, prevState.layouts[this.props.screenSize][0].h, this.props.layouts[this.props.screenSize][0].h, this.state.layouts[this.props.screenSize][0].h )
      if(prevProps.layouts !== this.props.layouts)
      {
        this.setState({ layouts: this.props.layouts })
      }
    } 

    onHide = (id, is_hide) => {
      
    }
    onRemoveFloat = (float_id) => {
      
    }
    onUpdateFloat = (data, float_id, section_id) => {
      
    }
    onClipboardCopy = (i, data) => 
    {

    }
    onClipboardPaste = (i) => { 

    }
    onWaypointEnter = (section_id) => {
      
    }  
    onWaypointLeave = (section_id) => {
      
    }  
    onFixedAdd = (data) => {
      
    } 
    generateDOM() {      
      const currentGrid = getNeighbourGrid(this.props.layouts, this.props.screenSize) 
      // console.log( this.props.screenSize ) 
      // console.log( currentGrid ) 
      // console.log( this.props.layouts ) 
      return currentGrid?.map( (elem, i) =>
      {
        const el = {
          ...elem,
            data : {
              ...elem.data,
              height: elem.h * GRID_HEIGHT_COEF() * GRID_HEIGHT_FACTOR()
          }          
        }
        return (
          <div key={ elem.id } className={` ${elem.heightType} `}>
            <GridCell 
              {...el}
              type={elem.current_type}
              background={{}} 
              section_id={elem.id} 
              i={i}
              user={this.props.user}
              is_admin={false}
              is_edit={false}
              level={0}
              onHide={this.onHide}
              onRemoveFloat={this.onRemoveFloat}
              onUpdateFloat={this.onUpdateFloat}
              onClipboardCopy={this.onClipboardCopy}
              onClipboardPaste={this.onClipboardPaste}
              onWaypointEnter={this.onWaypointEnter}
              onWaypointLeave={this.onWaypointLeave}
              onFixedAdd={this.onFixedAdd}
              getHelp={this.getHelp}
              untouchble
            />
            {/* <div className="position-absolute bottom left bg-light p-2" >
              {elem.h}
            </div>
            <InnerGrid 
              i={ i } 
              data={ elem } 
              onDelete={ this.props.onDelete }
              onEdit={ this.props.onEdit }
              onRaze={ this.props.onRaze }
            /> */}
          </div>
        );
      });
    }
    onBreakpointChange = (breakpoint)  =>
    {
        // console.log("onBreakpointChange:", breakpoint)
        this.setState({
          currentBreakpoint: breakpoint
        });
    }
    onCompactTypeChange = () =>
    {
        const { compactType: oldCompactType } = this.state;
        const compactType =
          oldCompactType === "horizontal"
            ? "vertical"
            : oldCompactType === "vertical"
              ? null
              : "horizontal";
        this.setState({ compactType });
    }
    onLayoutChange = (layout, layouts) => 
    {
      // console.log(layout, layouts)
      //if(!layout.data) return
      this.props.onLayoutChange(layout, layouts);
    } 
    
    render() {
      const currentGrid = getNeighbourGrid(this.props.layouts, this.props.screenSize)
      return Style.it(
        `
          .landing-section-grid
          {
            pointer-events: auto;
          }
          .react-grid-item
          {
             background-color:transparent;
             border: 1px dotted transparent;
          }
          .landing-section-container:hover .react-grid-item
          {           
            border: ${ this.props.is_edit ? '1px dotted red' : '1px dotted transparent'};
          }
          .landing-section-grid
          {
            border: ${ this.props.is_edit ? '2px dotted #FF000099' : '2px dotted transparent' };
            display: block!important;
          } 
        `,
        <div className="landing-section-grid "> 
          <SizeMe>
          {
            ( size ) =>  { 
              return <Responsive
                {...this.props}
                layouts={this.props.layouts}
                onBreakpointChange={this.onBreakpointChange}
                onLayoutChange={this.onLayoutChange}
                // WidthProvider option
                measureBeforeMount={true}
                breakpoints={ ScreenSizeShortObject() } 
                allowOverlap={false}
                verticalCompact={false}
                cols={{ lxl:48, xl:48, lg: 48, md: 48, sm: 48, xs:  48, mc: 48 }}
                // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                // and set `measureBeforeMount={true}` 
                compactType={this.state.compactType}
                preventCollision={!this.state.compactType}
                margin={[ this.props.gap, this.props.gap ]} 
                rowHeight={ GRID_HEIGHT_COEF() }
                width={  getScreenSizeWidth( this.props.screenSize ) || document.body.clientWidth }
                isDroppable={this.props.is_edit}
                isResizable={this.props.is_edit}
                isDraggable={this.props.is_edit}
                isBounded={this.props.is_edit}
              >
                {this.generateDOM()}
              </Responsive>
            }
          }
          </SizeMe>                
          {/* <div className="position-absolute bg-light text-danger p-1 ">
              { this.props.screenSize }
          </div> */}
        </div>
      );
    }
}

// GridLayoutContainer.propTypes = {
//     onLayoutChange: PropTypes.func.isRequired
// };

// GridLayoutContainer.defaultProps = {
//     className: "layout",
//     rowHeight: 60,
//     onLayoutChange: function() {},
//     cols: { lg: 24, md: 12, sm: 6, xs: 4, xxs: 2 },
//     initialLayout: GridLayoutContainer.props.toggleLayout()
// };

export default GridLayoutContainer