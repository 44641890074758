import React from 'react'
import PropTypes from 'prop-types'
import ExpertSlider from '../../ExpertSlider'
import ExpertSliderIndepended from '../../ExpertSliderIndepended'
import ExpertCheckList from '../../ExpertCheckList'
import WPFestSettings from '../../utilities/WPFestSettings'

const CriteryRatingExpertDisplay = props => {

    const onPersentage = () =>
    {

    }
    const onFinalPersentage = () =>
    {

    }
    const onCheck = data =>
    {
        //console.log(data)
        if(WPFestSettings.is_comment) 
        {
            props.onOpen(true, props.max_raiting === Number(data.c), data)
        }
        else
        {
            if(props.max_raiting === Number(data.c))
            {
                props.onOpen(true, true, data)
            }
            else
            {
                props.onCheck(data)
            }
        }
    }
    const onOpen = () =>
    { 
        props.onOpen(true)
    }
    switch(props.rating_type)
		{
			case "distribution_procentage": 
				return <ExpertSlider
					id={props.id}
					rating={props.rating}
					onPersentage={ onPersentage }
					onFinalPersentage={ onFinalPersentage}
					member_id={props.member_id}
                    max_raiting={props.max_raiting}
				/> 
			case "each_category":
				switch(props.category_rating_type)
				{
					case "distribution_procentage":
						return <ExpertSlider
							id={props.id}
							rating={ props.critery_rating }
							category_id={props.category_id}
							color={ props.color}
							onPersentage={ onPersentage }
							onFinalPersentage={ onFinalPersentage }
							member_id={props.member_id}
							max_raiting={props.max_raiting}
						/> 
					case "procentage":
						return <ExpertSliderIndepended
							id={props.id}
							rating={ props.critery_rating }
							onPersentage={ onPersentage }
							onFinalPersentage={ onFinalPersentage }
							member_id={props.member_id}
							max_raiting={props.max_raiting}
						/> 
					case "independent_grade":
						return <ExpertCheckList
							data={{ 
                                title : props.title, 
                                rating : props.critery_rating, 
                                id : props.id 
                            }}
							member_id={props.member_id}
							max_raiting={props.max_raiting}
							onEdit={ onOpen }
							onCheck={ onCheck }
						/> 
				}
				break
			case "independent_grade":
			default:
				return <ExpertCheckList
					data={{ 
                        title : props.title, 
                        rating : props.critery_rating, 
                        id : props.id 
                    }}
					member_id={props.member_id}
                    max_raiting={props.max_raiting}
					onEdit={ onOpen }
					onCheck={ onCheck } 
				/>

		}
}

CriteryRatingExpertDisplay.propTypes = {}

export default CriteryRatingExpertDisplay