import React from 'react'
import PropTypes from 'prop-types'

const HoneycombsLabel = props => { 
    return props.honeycombs
        ?
        <div className={props.className ? props.className : `honeycombs-label`}>
            { props.honeycombs?.post_title }
        </div> 
        :
        null 
    
}

HoneycombsLabel.propTypes = {}

export default HoneycombsLabel