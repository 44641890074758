import React, { Component } from "react"
import {
  Button, ButtonGroup, Intent, Popover, Position, Drawer, Dialog, Icon
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import {LayoutIcon} from 'react-pe-useful'
import InputForm from "./InputForm"
import section_groups from "../data/section_groups.json"
import matrix from "../data/matrix" 
import { components } from "../data/components"
const MINIMAL_WIDTH_DRAWER = 160
const MAXIMAL_WIDTH_DRAWER = 440

class NewSectionDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      current_type: "html",
      currentTypeGroup: matrix().html.sparams.group,
      drawerSize: MINIMAL_WIDTH_DRAWER
    }
  }

  componentWillUpdate(nextProps) {
    const state = {}
    Object.keys(nextProps).forEach((e) => {
      if (this.state[e] != nextProps[e]) state[e] = nextProps[e]
    })
    if (Object.keys(state).length > 0) {
      this.setState(state)
    }
  }
  onType = (evt) => {
    const id = evt.currentTarget.getAttribute("id")
    //console.log(id)
    this.setState({ currentTypeGroup: id, drawerSize : MINIMAL_WIDTH_DRAWER })
  }
  onTypeSwitch = (evt) => {
    const current_type = evt.currentTarget.getAttribute("type")
    let data = {}
    //console.log(current_type)
    if (matrix()[current_type].default) {
      data = { ...matrix()[current_type].default, sectionType: current_type }
      delete data.hidden
    }
    this.setState({  
      data, 
      current_type, 
      is_change_type_enbl: current_type != this.state.type 
    })
    if( matrix()[current_type].defaults?.variants && this.state.drawerSize === MINIMAL_WIDTH_DRAWER)
    {
      this.setState({ 
        drawerSize : MAXIMAL_WIDTH_DRAWER 
      })  
    }
    else if( this.state.drawerSize === MAXIMAL_WIDTH_DRAWER )
    {
      setTimeout(() => {
        if(!matrix()[ this.state.current_type ].defaults?.variants) 
        {
          this.setState({ drawerSize : MINIMAL_WIDTH_DRAWER })
        }
      }, 400)
    }
  }
  onTypeInsert = current_type =>
  {
    let data = {}
    //console.log(current_type)
    if (matrix()[current_type].default) {
      data = { ...matrix()[current_type].default, sectionType: current_type }
      delete data.hidden
    }
    // console.log( data, current_type );
    // this.setState({ 
    //   data, 
    //   current_type, 
    //   is_change_type_enbl: current_type != this.state.type 
    // })
    this.props.onEdit( data, current_type )
  }

  tabs = () => 
  {
    const btns = []
    const tabs = Object.entries(section_groups).map((e, i) => {
      const element = e[1]
      if (element.hidden) return
      return (
        <Button
          key={i}
          minimal
          small
          id={element.id}
          text={__(element.title)}
          active={this.state.currentTypeGroup == element.id}
          onClick={this.onType}
          rightIcon={matrix()[this.state.current_type].sparams.group == element.id ? "dot" : "empty"}
        />
      )
    })
    let ic = 0;
    for (const c in components()) 
    {
      //console.log( c, matrix()[c] )
      if (!matrix()[c].sparams || (matrix()[c].sparams && matrix()[c].sparams.group != this.state.currentTypeGroup)) 
      {
        //continue
      }
      const cl = c == this.state.current_type ? "active " : " "
      const ccl = c == this.state.type ? " text-danger " : " "
      btns.push(<div
        key={c}
        type={c}
        className={`l-icon-small ${cl} ${ic === 0 ? ' mt-3' : ''} `}
        onMouseEnter={this.onTypeSwitch}
        onClick={() => this.onTypeInsert( c )}
      >
        <div>
          <LayoutIcon
            src={components()[c].icon}
            className="layout-icon"
          />
          <div className={ccl}>
            {__(components()[c].title)}
          </div>
        </div>
        {
          matrix()[ c ].defaults?.variants.length > 0
            ?
            <span className="l-icon-more"/>
            :
            null
        }
      </div>)
      ic++
    }
    return (
      <div className="py-1 pl-2">
        <div className="d-flex" style={{ height: "calc(100vh - 10px)" }}>
          <div className="landing-type-menu  overflow-y-auto-thin hidden">
            <ButtonGroup className="  text-right" vertical>
              {tabs}
            </ButtonGroup>
          </div>
          <div className="landing-type-menu-cont  overflow-y-auto-thin ">
            {btns}
          </div>
        </div>      
      </div>
    )

  }
  getVariants = () =>
  {
    if( 
      !matrix()[ this.state.current_type ] || 
      !matrix()[ this.state.current_type ].defaults?.variants || 
      matrix()[ this.state.current_type ].defaults?.variants.length === 0
    )
    {
      return null
    }  
    return matrix()[ this.state.current_type ].defaults?.variants.map((variant, i) =>
    {
      return <div 
        className="w_280 landing_type_variant_label" 
        key={i} 
        onClick={ () => this.onEditVariant( i ) }
      >
        <img 
          className="thumbnail"
          src={ variant.thumbnail }
          alt={variant.title}
        />
        <div className=" title ">
          { variant.title }
        </div>
        <div className="description p-1 " dangerouslySetInnerHTML={{__html:variant.description}} />
      </div>
    })
  }

  render() {
    //console.log( this.state.data );
    return (
      <Drawer
        isOpen={this.state.isOpen}
        onClose={this.props.onClose}
        //title={__("Inserting new. Choose type")}
        //className="landing-outer-container"
        usePortal
        size={this.state.drawerSize}
        hasBackdrop={true}
        canOutsideClickClose={true}
        backdropClassName="landing-drawer-bg"
        position={Position.LEFT}
        className=" "
      >
        <div className=" flex-grow-100 d-flex align-items-stretch ">
          <div className="pt-0 px-0 overflow-y-auto bg-tripp w_160">
            <div className="m-0">
              {this.tabs()}
            </div>
          </div>
          <div className="w_280 overflow-y-auto-thin" style={{ height: "calc(100vh - 10px)" }}>
            <div className="m-0">
              {this.getVariants()}
            </div>
          </div>
        </div>
        {/* <ButtonGroup className="mx-auto py-3">
          <Button
            onClick={this.onEdit}
          >
            {__("Insert")}
          </Button>
        </ButtonGroup> */}
        <Button 
          className="bp3-drawer-lose-btn-right"
          minimal
          onClick={this.props.onClose}
        >
          <Icon icon="cross" size={24} color="#FFF"/>
        </Button>
      </Drawer>
    )
  }

  onField(value, field) {
    const { data } = this.state // { ...this.state.data}
    data[field] = value
    //console.log(data)
    // this.setState( { data } );
  }

  onEdit = () => {
    //console.log(this.state.data)
    this.props.onEdit( this.state.data, this.state.current_type )
  }
  onEditVariant = variantId =>
  {
    this.props.onEdit( this.state.data, this.state.current_type, variantId )
  }
}

export default NewSectionDialog
