import { Button, ButtonGroup, Intent, Popover, Tab, Tabs } from "@blueprintjs/core"
import InputForm from "modules/pe-landing-module/views/LandingState/edit/InputForm"
import matrix from "modules/pe-landing-module/views/LandingState/data/matrix"
import React, { useState } from "react" 
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"
import section_groups from "modules/pe-landing-module/views/LandingState/data/section_groups.json"
import { components } from "modules/pe-landing-module/views/LandingState/data/components"
import { LayoutIcon } from "react-pe-useful/dist"

const GridEditDrawer = props =>
{
    const [ navbarTabId, onTab ] = useState("prm")
    const [ class_name, onClassName ] = useState(props.class_name)
    const [ style, onStyle ] = useState(props.style)
    const [ currentTypeGroup, onCurrentTypeGroup ] = useState(props.currentTypeGroup ? props.currentTypeGroup : "layout")
    
    const onField = (value, field, dataType, dopol) =>
    {

    }
    const getPalette = () =>
    {
        return {}
        // let palette = PalettePresets().filter(palette => palette.id == this.state.current_template_id)[0]
        // if(!palette)
        // palette = PalettePresets()[0]
        // return palette
    }
    const onClipboardPastePRM = () =>
    {

    }
    const onClipboardCopyPRM =() =>
    {

    } 
    const onClick = evt =>
    {

    }   
    const onClipboardCopy = data =>
    {

    }
    const onClipboardPaste = edatavt =>
    {

    }
    const type = () => {
        return <div>
            <InputForm
                {...props }
                source={props.current_type}
                id={props.id}
                data={props.data}
                sourceType="section"
                on={(value, field, dopol) => onField(value, field, "data", dopol)}
                onClipboardPaste={ onClipboardPastePRM} 
                onClipboardCopy={ onClipboardCopyPRM} 
            />
        </div>
    }

    const onType = (evt) => {
        const id = evt.currentTarget.getAttribute("id")
        console.log(id)
        //this.setState({ currentTypeGroup: id })
        onCurrentTypeGroup(id)
    }
    const onTypeSwitch = (evt) => {
        const current_type = evt.currentTarget.getAttribute("type")
        let data = {}
        if (matrix()[current_type].default) {
          data = {
            data: { ...matrix()[current_type].default },
            composition: matrix()[current_type].default.composition
          }
          delete data.data.hidden
          delete data.data.composition
        }
        // console.log( data, current_type );
        
        //this.setState({ data, current_type  })
      }
    const types = () => {
        /**/
        const btns = []
        const tabs = Object.entries(section_groups).map((e, i) => {
          const element = e[1]
          if (element.hidden) return
          return (
            <Button
              key={i}
              minimal
              small
              id={element.id}
              text={__(element.title)}
              active={ currentTypeGroup == element.id}
              onClick={ onType }
              rightIcon={ props.current_type && matrix()[props.current_type].sparams.group == element.id ? "dot" : "empty"}
            />
          )
        })
        for (const c in components()) {
          if (matrix()[c].sparams ) 
          {
            if ( matrix()[c].sparams.group !=  currentTypeGroup) 
            {
                continue
            }
          }
          const cl = props.current_type && c == props.current_type ? "active " : " "
          const ccl = c == props.type ? " text-danger " : " "
          btns.push(<div
            key={c}
            type={c}
            className={`l-icon ${cl}`}
            onClick={ onTypeSwitch }
          >
            <div>
              <LayoutIcon
                src={components()[c].icon}
                className="layout-icon"
              />
              <div className={ccl}>
                {__(components()[c].title)}
              </div>
            </div>
          </div>)
        }
        return (
          <div className="pr-4">
            <div className="d-flex">
              <div className="landing-type-menu">
                <ButtonGroup className="  text-right" vertical>
                  {tabs}
                </ButtonGroup>
              </div>
              <div className="landing-type-menu-cont">
                {btns}
              </div>
            </div>
    
          </div>
        )
      }

    const html = () =>
    { 
        // console.log( this.state );
        return (
            <div className="p-4">
                <FieldInput
                    field="class"
                    key="css-class"
                    title="css-class"
                    type="Style"
                    id={props.id}
                    on={(value, field) => onClassName(value)}
                    onChange={(value, field) => onClassName(value)}
                    editable
                    value={ class_name }
                    vertical={false}
                />
                <FieldInput
                    field="style"
                    key="css-style"
                    title="css-style"
                    {...{ type: "landing_object", visualization: "landing-object", landing_object: "Style" }}
                    id={props.id} 
                    on={ onStyle}
                    onChange={ onStyle}
                    editable
                    value={ style}
                    vertical={false}
                />
        
                <FieldInput
                    field="lasy_load_type"
                    key="lasy_load_type"
                    title="Lazy load animation type"
                    {...matrix().section.lasy_load_type}
                    id={props.id}
                    on={(value, field) => this.onField(value, field, "lasy_load_type")}
                    onChange={(value, field) => this.onField(value, field, "lasy_load_type")}
                    editable
                    value={props.lasy_load_type}
                    vertical={false}
                />
                <FieldInput
                    field="lasy_load_delay"
                    key="lasy_load_delay"
                    title="Lazy load delay"
                    type="number" 
                    id={props.id}
                    on={(value, field) => this.onField(value, field)}
                    onChange={(value, field) => {                      
                        this.setState({ lasy_load_delay : value === 0 ? "" : value });
                    }}
                    editable
                    value={props.lasy_load_delay ? props.lasy_load_delay : 0 }
                    vertical={false}
                /> 
            </div>
        ) 
    }

    const hiddenButton = props.is_hidden
        ? 
        <Button
            className="mb-1"
            icon="eye-open"
            fill
            onClick={() => props.onHide(props.menu.id, 0)}
        >
            {__("Show section for users")}
        </Button> 
        : 
        <Button
          className="mb-1"
          icon="eye-off"
          fill
          onClick={() => props.onHide(props.menu.id, 1)}
        >
          {__("Hide section for users")}
        </Button> 

    console.log(props )
    return <>
        <div className="pt-0 px-0 overflow-y-auto flex-grow-100 bg-tripp position-relative " >
            <Tabs
                onChange={ onTab }
                selectedTabId={ navbarTabId}
                animate={false}
                id="TabsType"
                key="horizontal"
                vertical={false}
                className="tab-light-head"
            >
                <Tab
                    id="prm"
                    title={(
                        <div className="small"> 
                            {__( 
                              props.current_type  && matrix()[props.current_type]
                                ? 
                                matrix()[props.current_type].sparams.title 
                                : 
                                "unknown"
                            )} 
                        </div>
                    )}
                    panel={(
                    props.current_type ? type() : types()
                    )}
                /> 
                <Tab
                id="html"
                title={(
                    <div className="small">
                    {__("Style")}
                    </div>
                )}
                panel={ html() }
                />
                <Tab
                    id="bg"
                    title={(
                    <div className="small">
                        {__("Back")}
                    </div>
                    )}
                    panel={
                        <InputForm 
                        {...props.background}
                        source="Background"
                        id={props.id}
                        data={props.background}
                        sourceType="Background"
                        palette={ getPalette() }
                        on={(value, field, dopol) => onField(value, field, "background", dopol)} 
                        />
                    }
                />
            </Tabs>
        </div>
        <div className="layout-simple-center pt-0 px-0 pb-0 flex-row " >
            <ButtonGroup fill vertical={false} large>
                <Button
                    intent={Intent.SUCCESS}
                    onClick={ onClick}
                >
                    {__("Update section")}
                </Button>
                <Popover
                    content={(
                    <div className="p-3">
                        <ButtonGroup vertical>
                        {hiddenButton}
                        <Button
                            className="mb-1"
                            icon={<i className="fas fa-file-import" />}
                            onClick={ onClipboardCopy}
                        >
                            {__("Copy section to clipboard")}
                        </Button>
                        <Button
                            onClick={ onClipboardPaste}
                            icon={<i className="fas fa-file-export" />}
                            className="mb-1"
                        >
                            {__("Paste section from clipboard")}
                        </Button>
                        </ButtonGroup>
                    </div>
                    )}
                >
                    <Button
                    intent={Intent.NONE}
                    large={false}
                    className="ml-1"
                    icon="chevron-down"
                    >
                    {__("Added actions")}
                    </Button>
                </Popover>
                <Button
                    icon="trash"
                    intent={Intent.DANGER}
                    className="ml-1"
                    onClick={() =>  props.onRnv(props.id)} 
                >
                    {__("Delete Section")}
                </Button>
                <Button
                    icon="cross"
                    intent={Intent.DANGER}
                    onClick={() => props.onClose()}
                    title={__("Close")}
                    className="ml-1"
                />
            </ButtonGroup>
        </div>        
    </>
}
export default GridEditDrawer