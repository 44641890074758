import chroma from 'chroma-js'

export function _chroma(color)
{
  let clr = color;
  if(clr === "transparent") clr = "#00000000"
  //
  //  console.log( clr, typeof clr )
  try
  {
    return chroma(typeof clr === "string" ? clr : "#FFF")
  }
  catch(error)
  {
    return "#FFF"
  }
}
export function getPlainColor(colorObj, palette) {
  let color = colorObj
  if (typeof color === 'string') {
    if (color === 'transparent') return '#00000000'
    let arr = typeof color === 'string' ? color.split('==color==') : []
    if (arr.length > 2) {
      color = arr[0]
    }
    return color
  }
  if (typeof color === 'object' && color ) {
    switch (color?.source) {
      case 'landing':
      case 'template':
      case 'choose':
        return getLandingColor(color, palette)
      default:
        return getSimple(color)
    }
  }
}

export function getColor(colorObj, palette) 
{
    let color = colorObj
    try {
        const source = typeof color === 'object' ? color.source : 'simple'
        switch (source) 
        {
            case 'landing':
            case 'template':
            case 'choose':
                color = getLandingColor(color, palette)
                break
            default:
                color = getSimple(color)
                break
        }
        return color
    } 
    catch (error) 
    {
        return color
    } 
}
const getLandingColor = (clr, palette) => 
{
  if (!clr || typeof clr !== 'object') return clr 
  if(clr.accentuated)
  {
    // console.log( clr )
    let not_accentuated
    switch (clr.source) 
    {
      case 'landing':
      case 'template':
        // console.log( clr )
        let field = 'background_color'
        if (clr.field) field = clr.field
        if (!palette) return '#EEE'
        not_accentuated =  getSimple(
          palette[clr.field],
          clr.opacity,
          clr.brightness,
          clr.saturation
        )   
        break     
      case 'choose':
      default:
        not_accentuated = getSimple(clr.color)    
    }
    const accentuated = _chroma(not_accentuated).get('hsl.l') > .66 ? "#000" : "#FFF" 
    return getSimple(
        accentuated,
        clr.opacity,
        clr.brightness,
        clr.saturation
      )   
  }
  switch (clr.source) 
  {
    case 'landing':
    case 'template':
      // console.log( clr )
      let field = 'background_color'
      if (clr.field) field = clr.field
      if (!palette) return '#EEE'
      return getSimple(
        palette[clr.field],
        clr.opacity,
        clr.brightness,
        clr.saturation
      )
    case 'choose':
    default:
      return getSimple(clr.color)
  }
}
const getSimple = (clr, opacity, brightness, saturation) => {
  let oldColor = clr === 'transparent' ? '#00000000' : clr
    try{
      let arr =
      oldColor && typeof oldColor === 'string' ? oldColor.split('==color==') : []

      if (arr.length > 2) {
          switch (arr[2]) {
          case 'up':
              oldColor = `linear-gradient(to bottom, ${arr[1]} 0%, ${arr[0]} 100%)`
              break
          case 'left':
              oldColor = `linear-gradient(to left, ${arr[0]} 0%, ${arr[1]} 100%)`
              break
          case 'right':
              oldColor = `linear-gradient(to left, ${arr[1]} 0%, ${arr[0]} 100%)`
              break
          case 'down':
          default:
              oldColor = `linear-gradient(to bottom, ${arr[0]} 0%, ${arr[1]} 100%)`
              break
          }
      } else {
          if (
            typeof oldColor !== 'undefined' &&
            typeof opacity !== 'undefined' &&
            opacity
          ) 
          {
            //console.log(oldColor)
            oldColor = _chroma(oldColor).alpha(opacity).css(true)
          }
          if (
            typeof oldColor !== 'undefined' &&
            typeof brightness !== 'undefined' &&
            brightness
          ) 
          {
            oldColor =
              brightness > 0
              ? _chroma(oldColor).brighten(brightness).css(true)
              : _chroma(oldColor).darken(-brightness).css(true)
          }
          if (
            typeof oldColor !== 'undefined' &&
            typeof saturation !== 'undefined' &&
            saturation
          ) {
            oldColor =
              saturation > 0
              ? _chroma(oldColor).saturate(saturation).css(true)
              : _chroma(oldColor).desaturate(-saturation).css(true)
        }
      }
    return oldColor
  }
  catch(e)
  {
    return "#FF0044"
  }
  
  
}

export const getRevertColor = (color, palette) =>
{
  try
  {
    const simpleColor = getLandingColor(color, palette)
    //console.log(color, simpleColor)
    const accentuated = _chroma(simpleColor).get('hsl.l') > .66 ? "#000000" : "#FFFFFF" 
    return accentuated
  }
  catch(err)
  {
    return "#EEE"
  }
  
}
