import React, { Component } from "react"
import { NavLink, withRouter } from "react-router-dom"
import { menu } from "react-pe-layouts" 
import { template } from "react-pe-layouts" 
import { isCapability } from "react-pe-utilities"
import PictogrammMenu from "./menuLeft/PictogrammMenu"
import HierarhicalMenu from "./menuLeft/HierarhicalMenu"
import { initArea } from "react-pe-utilities" 

class LayoutMenuLeft extends Component {
	state =
	{
	  current: this.props.current,
	  hover: false,
	  show: true,
	};

	toggle = () => {
	  this.setState({ show: !this.state.show })
	};

	componentWillReceiveProps(nextProps) {
	  this.setState({
	    current: nextProps.current,
	  })
	}

	render() {
	  const icons = menu().menu
      .filter(m => !m.is_hidden )
        .map((e, i) => {
          const islogged    = (e.islogged === true || e.islogged === 1) && !this.props.user
          if ( islogged )   return "";
          const isRole = isCapability(e.capability, this.props.user)
          if (isRole) return "" 
          switch (template().left_menu) {
            case "pictogramm":
              return <PictogrammMenu {...e} current={this.state.current} i={i} key={i} /> 
            case "hierarhical":
            default:
              return (
                <div key={i}>
                  <div className={ " left-menu-group " + ( this.state.show ? "d-block" : "hidden" )}>
                    <HierarhicalMenu
                      {...e}
                      parent_route=""
                      razdel={e.children ? e.children : [e]}
                      i={i}
                      level={1}
                      key={i}
                    />
                  </div>
                </div>
              )
          }
	      })
	return (
      <div className={ "layout-menu-left " + template().left_menu }>
        
        <button
          className="admin-menu-toggle"
          onClick={this.toggle}
        />
        {
          initArea(
            "layout-before-left-menu",
            {
              ...this.props,
              ...this.state
            }
          )
        }
        
        {icons}
        {
          initArea(
            "layout-after-left-menu",
            {
              ...this.props,
              ...this.state
            }
          )
        }
        <div className="layout-settings-btn ">
          <NavLink
            to="/cog"
            className="layout-left-btn "
            activeClassName="active"
          >
            <div className="layout-menu-icon">
              <i className="fas fa-cog" />
            </div>
          </NavLink>
        </div>
      </div>
	  )
	}

  onSwitch = (evt) => {
    // this.setState({ current:evt.currentTarget.getAttribute("i") });
    this.props.onCurrent(evt.currentTarget.getAttribute("i"))
  };
}
export default withRouter(LayoutMenuLeft)
