import React, {Component, Fragment} from "react"; 
import buttons from "./buttons.json"; 
import Settings from "./cabinet/Settings";
import AboutPageDesign from "./cabinet/AboutPageDesign";
import Content from "./cabinet/Content";
import ExtendFields from "./cabinet/ExtendFields";
import Extends from "./cabinet/Extends";
import Inheritance from "./cabinet/Inheritance";
import Statistics from "./cabinet/Statistics";
import Projects from "./cabinet/Projects";
import Honeycombs from "./cabinet/Honeycombs";
import Tracks from "./cabinet/Tracks";
import Milestones from "./cabinet/Milestones";
import Criteries from "./cabinet/Criteries";
import Categories from "./cabinet/Categories";
import Ganres from "./cabinet/Ganres";
import Users from "./cabinet/Users";
import ShortExperts from "./cabinet/ShortExperts";
import ShortMembers from "./cabinet/ShortMembers";
import FullExperts from "./cabinet/FullExperts";
import FullMembers from "./cabinet/FullMembers";
import CreatedUsers from "./cabinet/CreatedUsers";
import CreateMemberLogs from "./cabinet/CreateMemberLogs";
import getFestivalRoute from "../../utilities/getFestivalRoute";

import { Icon } from "@blueprintjs/core"; 
import { __ } from "react-pe-utilities/dist"; 
import LettersAndMeggages from "./cabinet/LettersAndMeggages";
import GotoFestivalPanel from "./GotoFestivalPanel";

export default class Panel extends Component
{
	panels()
	{
		return {
			"Settings" 		: Settings,
			"AboutPageDesign" : AboutPageDesign,
			"Content" 		: Content,
			"LettersAndMeggages" : LettersAndMeggages,
			"ExtendFields" 	: ExtendFields,
			"Extends" 		: Extends,
			"Inheritance" 	: Inheritance,
			"Statistics"	: Statistics,
			"Projects"		: Projects,
			"Tracks"		: Tracks,
			"Honeycombs"	: Honeycombs,
			"Milestones"	: Milestones,
			"Users"			: Users,
			"Criteries"		: Criteries,
			"Categories"	: Categories,
			"Ganres"		: Ganres,
			"ShortMembers"	: ShortMembers,
			"ShortExperts"	: ShortExperts,
			"FullExperts"	: FullExperts,
			"FullMembers"	: FullMembers,
			"CreatedUsers"	: CreatedUsers,
			"CreateMemberLogs" : CreateMemberLogs
		}
	}
	render()
	{ 
		const { currentButton, blog_id, domain, url, data, options } = this.props
		const { component, title2, title, color, icon } = currentButton
		const MyPanel = this.panels()[ component ] 
		return <div className="w-100">			
			<div className="display-5 px-2 pt-5 pb-4 d-flex align-items-center">
				<Icon icon={ icon } iconSize={25} />  
				<span className="ml-3 text-center">
					{ __( title2 || title ) }
				</span>
			</div>
			<GotoFestivalPanel
				blog_id={ blog_id }
				domain={ domain }
				url={ url }
			/>
			<MyPanel 
				data={ data } 
				options={ options } 
				format={ data.format }  // data type
				termin={ data.termin }  // post, term or user 
				blog_id={ blog_id}
				theadColor={ color || "#0E5A8A" }
			/> 
			{/*
			// неудачная попытка подключить роутер
			<Switch>
			{ 
				Object.keys(buttons).map(button => {
					const baseRoute = `/${CabinetView.WPFESTAdminRoute}/site/${blog_id}`
					const ElComponento = this.panels()[ buttons[button].component ]
					return <Route
						exact  
						key={button}
						path={`${baseRoute}/${buttons[button].route}`}
						render={(routeProps) => { 
							// console.log( data )
							return <ElComponento 
								data={data} 
								options={options} 
								format={data.format}  // data type
								termin={data.termin}  // post, term or user 
								blog_id={blog_id}
								theadColor={ color || "#0E5A8A" }	
							/>
						}} 
					/>  
				})				
			}
			</Switch>
			*/}
		</div>
	}
}