import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
const config = require("./config/config.json")
const layoutConfig = require("./config/layouts.json")
const dictionary = require("./config/ru-RU.json")

ReactDOM.render( 
  <App 
    config={config}
    layoutConfig={layoutConfig}
    dictionary={dictionary}
  />,
  document.getElementById("root"),
)
window.addEventListener('load', async () => {
   if('serviceWorker' in navigator)
   {
      try
      {
        const reg = await navigator.serviceWorker.register('/sw.js')
        //console.log("Service worker register success", reg)
      }
      catch(err) 
      {
        console.log("Service worker register fail", err)
      }
      
   }
})
