import React from "react"
import { Link } from "react-router-dom"

const LinkContainer = props =>
{
    if( !props.link_url )
    {
        return props.children
    }
    else if(props.link_url.indexOf("http") !== -1 || props.link_target === "_blank")
    {
        return <URLLink {...props } />
    }
    else
    {
        return <LinkLink {...props} />
    }
}
const URLLink = props =>
{
    return <a  className={`hover ${props.className}`} style={props.style} href={props.link_url} target={props.link_target} >
        {props.children}
    </a>
}
const LinkLink = props =>
{
    return <Link className={`hover ${props.className}`} style={props.style} to={props.link_url}>
        {props.children}
    </Link>
}

export default LinkContainer

