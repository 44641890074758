import Layouts from "react-pe-layouts";

export default function () {
    const modules = Layouts().modules;
    let extendedCardMetaphore = []
    //search special widgets in modules 
    Object.keys(modules).forEach(plugin => {
        Object.keys(modules[plugin].widgets).forEach(widget => {
            if( modules[plugin].widgets[widget].extended_landing )
            {
                const extended = require.context( `modules`, true, /\.json$/ )
                extended.keys()
                    .forEach(key => {
                        let key1 = key.replace("./", "").split(".").slice(0, -1).join(".").split("/")
                        key1 = key1[key1.length - 1]
                        if( key1 === "extended_card_field_metaphore")
                        {
                            //console.log( key1 )
                            extendedCardMetaphore = [ ...extendedCardMetaphore, ...extended(key) ]
                        } 
                    })
            }
        });
    });
    return [
        {
            _id: "title",
            title: "Title",
            type: "string",
            description: "Обычно - короткий текст, записанный крпным кегелем с использованием шрифта, назначенного на странице заголовочным",
            fill:true
        },
        {
            _id: "description",
            title: "Description",
            type: "string",
            fill:true,
            description: "Длинный текст. Можно использовать html-разметку",
            edit_field: "html"
        },
        {
            _id: "order",
            title: "Order",
            type: "string",
            description: "Число, порядковый номер карточки в ряду",
            fill:true
        },
        {
            _id: "date",
            title: "Date",
            type: "string",
            description: "Выбираем формат даты",
            fill:true
        },
        {
            _id: "commentary",
            title: "Commentary",
            type: "string",
            fill:true,
            description: "Длинный текст. Можно использовать html-разметку",
            edit_field: "html"
        },
        {
            _id: "author",
            title: "Author",
            type: "string",
            description: "Фамилия, имя и отчество ",
            hidden:true,
            fill:true
        },
        {
            _id: "author_meta",
            title: "Author's meta",
            type: "string",
            description: "Можно редактировать аватар, ФИО, и несколько дополнительных полей (например — должность)",
            fill:true
        },
        {
            _id: "offer_status",
            title: "Offer's status",
            description: "For example: «standart», «comfort», «premium»",
            type: "string",
            fill:true
        },
        {
            _id: "offer_definition",
            title: "Offer's definition",
            description: "For example: «new­», «special», «discont»",
            type: "string",
            fill:true
        },
        {
            _id: "thumbnail",
            title: "Thumbnail",
            type: "media",
            description: "Изображение, вписанное и обрезанное по размерам поля",
            fill:true
        },
        {
            _id: "price",
            title: "Price",
            type: "price",
            description: "Устанавливаем тип валюты, тип её отображения для всех карточек группы. Номинал устанавливаем при редактировании каждой карточки.",
            fill:true
        },
        {
            _id: "old_price",
            title: "Old price",
            type: "price",
            description: "Устанавливаем тип валюты, тип её отображения для всех карточек группы. Номинал устанавливаем при редактировании каждой карточки.",
            fill:true
        },
        {
            _id: "features",
            title: "List of features",
            type: "check_label",
            description: "Список любой длины. При редактировании каждой карточки определяем сам список, лейблы каждого элемента и пикторгамму «есть/нет»",
            fill:true
        },
        {
            _id: "personal_links",
            title: "Social links",
            type: "personal_links",
            description: "",
            fill:true,
            hidden:true
        },
        {
            _id: "icon",
            title: "Icon",
            type: "icon",
            description: "Стиль иконки. Сами иконки назначаются для карточки",
            fill:true
        },
        {
            _id: "divider",
            title: "Divider",
            type: "divider",
            description: "Графически оформленная разбивка между соседними полями",
            fill:false
        },
        {
            _id: "empty",
            title: "Dummy",
            type: "empty",
            description: "Пустое поле, обычно используется для создания цветовых актцентов или фиксированных сдвигов последующих полей",
            fill:false
        },
        {
            _id: "tag",
            title: "Tag",
            type: "navlink",
            description: "",
            fill:true,
            hidden:true
        },
        {
            _id: "cart",
            title: "Cart button",
            type: "navlink",
            description: "",
            fill:true,
            hidden:true
        },
        {
            _id: "navlink",
            title: "Inner link",
            type: "navlink",
            description: "Кнопки для перехода на другую страницу этого сайта",
            fill:true
        },
        {
            _id: "outerlink",
            title: "Outer link",
            type: "outerlink",
            description: "Кнопки для перехода на другой сайт",
            fill:true
        },
        ...extendedCardMetaphore
    ]
}