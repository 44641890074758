import { Button } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"
import { __ } from "react-pe-utilities/dist"
import matrix from "../data/matrix"
import DataContext from "../DataContext"
const LandingStructurePanel = props =>
{ 
    const onContainer_type = evt =>
    {
        const v = evt.currentTarget.getAttribute("v")
        props.setContainer_type(v)
    }
    const onCarousel_flow = evt =>
    {
        const v = evt.currentTarget.getAttribute("v")
        props.setCarousel_flow(v)
    }
    const onLanding_flow = evt =>
    {
        const v = evt.currentTarget.getAttribute("v")
        props.setLanding_flow(v)
    }
    let flow = ""
    switch(DataContext.data.landing?.container_type)
    {
        case "carousel":
            flow = <span className="landing-panel">
                <div style={{ paddingLeft: 10, paddingRight: 10, userSelect: "none", whiteSpace: "nowrap" }}>
                    { __("Carousel page flow") }
                </div>
                {
                    matrix().landing?.carousel_flow?.type === "image_radio"
                        ?
                        matrix().landing?.carousel_flow?.values
                            .filter(val => !val.hidden)
                            .map(val => {
                                return <Button
                                    key={val._id}
                                    className="hint hint--bottom"
                                    data-hint={__(val.title)}
                                    onClick={ onCarousel_flow }
                                    v={val._id}
                                    minimal={val._id != DataContext.data.landing?.carousel_flow}
                                >  
                                    <img
                                        src={val.img}
                                        className=" "
                                        alt={""}
                                        style={{
                                            height: 16,
                                            width:16,
                                            top: 0,
                                            opacity: val._id == DataContext.data.landing?.carousel_flow
                                            ? 
                                            val.icon_opacity
                                                ? 
                                                val.icon_opacity
                                                : 
                                                1
                                            : 
                                            0.5,
                                        }}
                                    />
                                </Button> 
                            })
                        :
                        []
                }
            </span>
            break;
        case "landing":
        default:
            flow = <span className="landing-panel">
                <div style={{ paddingLeft: 10, paddingRight: 10, userSelect: "none", whiteSpace: "nowrap" }}>
                    { __("Landing page flow") }
                </div>
                {
                    matrix().landing?.landing_flow?.type === "image_radio"
                        ?
                        matrix().landing?.landing_flow?.values
                            .filter(val => !val.hidden)
                            .map(val => {
                                return <Button
                                    key={val._id}
                                    className="hint hint--bottom"
                                    data-hint={__(val.title)}
                                    onClick={ onLanding_flow }
                                    v={val._id}
                                    minimal={val._id != DataContext.data.landing?.landing_flow}
                                >  
                                    <img
                                        src={val.img}
                                        className=" "
                                        alt={""}
                                        style={{
                                            height: 16,
                                            width:16,
                                            top: 0,
                                            opacity: val._id == DataContext.data.landing?.landing_flow
                                            ? 
                                            val.icon_opacity
                                                ? 
                                                val.icon_opacity
                                                : 
                                                1
                                            : 
                                            0.5,
                                        }}
                                    />
                                </Button> 
                            })
                        :
                        []
                }
        </span>
    }
    return <>
        <span className="landing-panel">            
            <div style={{ paddingLeft: 10, paddingRight: 10, userSelect: "none", whiteSpace: "nowrap" }}>
                {__("Frame's kind")}
            </div>
            {
                matrix().landing?.container_type?.type === "image_radio"
                    ? 
                    matrix().landing?.container_type?.values
                        .filter(val => !val.hidden)
                        .map(val =>
                        {
                           return  <Button
                                key={val._id}
                                className="hint hint--bottom"
                                data-hint={ __(val.title) }
                                onClick={ onContainer_type }
                                v={val._id}
                                minimal={val._id != DataContext.data.landing?.container_type}
                            > 
                                <img
                                    src={val.img}
                                    className=" "
                                    alt={""}
                                    style={{
                                        height: 16,
                                        width:16,
                                        top: 0,
                                        opacity: val._id == DataContext.data.landing?.container_type
                                        ? 
                                        val.icon_opacity
                                            ? 
                                            val.icon_opacity
                                            : 
                                            1
                                        : 
                                        0.5,
                                    }}
                                />
                            </Button>   
                        })
                    :
                    []
            }
        </span>
        { flow }
    </>
}
export default LandingStructurePanel