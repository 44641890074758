import { setCookie, getCookie, deleteCookie } from "./utils"
import WPFestSettings from "./WPFestSettings";

export default class User { 
	static is_login = getCookie(`wpfest_token${WPFestSettings.url}`) ? 1 : 0
	static token = getCookie(`wpfest_display_name${WPFestSettings.url}`)
	static display_name = getCookie("wpfest_display_name")
	static roles = getCookie(`wpfest_roles${WPFestSettings.url}`) 
		? 
		getCookie(`wpfest_roles${WPFestSettings.url}`).split(",") 
		: 
		[];
	static update = () =>
	{
		User.is_login = getCookie(`wpfest_token${WPFestSettings.url}`) ? 1 : 0
		User.display_name = getCookie("wpfest_display_name")
		User.roles = getCookie(`wpfest_roles${WPFestSettings.url}`) 
			? 
			getCookie(`wpfest_roles${WPFestSettings.url}`).split(",") 
			: 
			[];
	}
	static setData(data) {
		User.display_name = data?.user?.display_name
		User.is_login = data?.is_login || false
		User.roles = data?.user?.roles || []
		User.avatar = data?.user?.avatar
		User.id = data?.user?.ID || -1
	}

	static deleteData() {
		User.display_name = ""
		User.roles = []
		User.token = null
		User.is_login = 0
		// deleteCookie( "wpfest_token" );
		deleteCookie("wpfest_display_name")
		deleteCookie("wpfest_roles")
		setCookie("wpfest_token", null)
	}
	static isRole = role =>{
		if(Array.isArray(role))
		{
			return User.roles.filter(r => role.filter(rr => rr === r).length > 0).length > 0
		}
		else
		{
			return User.roles.filter(r => r === role).length > 0
		}
		return false
	}
	
}
