import React from "react"
import { useEffect } from "react"
import Card from "../../Card"
import $ from "jquery"
import { useState } from "react"
import PalettePresets from "../../data/PalettePresets"

const CardPreview = props => {
    // console.log(props )
    const [height, setHeight] = useState(0)
    const scale = props.scale ? props.scale : .75
    const id = "card-preview-" + props.div_id
    const count = props.count ? props.count : 2

    useEffect(() => {
        setTimeout(() => {
            const card = $("#" + id);
            setHeight(card.height() * scale + 100)
            //setWidth(card.width() * count)
        }, 200, id)
    }, [])

    return <div style={{
        height,
        overflowY: "hidden",
        overflowX: "visible",
        transition: "height 400ms ease-out"
    }}>
        <div
            id={id}
            style={{
                // maxWidth: 350,
                width: props.width ? props.width : 800,
                transform: `scale(${scale})`,
                transformOrigin: "0 0",
                display: "flex",
                padding: 50,
                paddingBottom: 200,
                backgroundColor: props.palette
                    ?
                    props.palette.background_color
                    :
                    PalettePresets()[0].background_color
            }}
            className=" landing-cards  flex-nowrap "
        >
            {
                Array(count).fill(0).map((card, i) => {
                    //console.log( props.card_style )
                    return <Card
                        i={i} 
                        key={i}
                        section_id={0}
                        composition={props.composition}
                        palette={props.palette ? props.palette : PalettePresets()[0]}
                        reverse_mounting={false}
                        cardFields={props.data.fields}
                        card_fields={props.data.fields} // дубль для формы редактирования
                        height={props.height}
                        vertical_align={props.vertical_align}
                        padding={props.padding} 
                        decoration={props.decoration}
                        cliping={props.cliping}
                        card_style={props.card_style}
                        card_inner_style={props.card_inner_style}
                        tail_type={props.tail_type}
                        tail_text={props.tail_text}
                        tail_color_hover={props.tail_color_hover}
                        is_empty_tail_hide={props.is_empty_tail_hide}
                        tail_style={props.tail_style}
                        tail_color_hover={props.tail_color_hover}
                        tension={props.tension}
                        border_radius={props.border_radius}
                        box_shadow={props.box_shadow}
                        random_rotaion={props.random_rotaion}
                        border_top_right_radius={props.border_top_right_radius}
                        border_bottom_left_radius={props.border_bottom_left_radius}
                        border_bottom_right_radius={props.border_bottom_right_radius}
                        back_image={props.is_back_image ? "/assets/img/landing/wave_full.svg" : ""}
                        is_back_image={props.is_back_image}
                        is_joint={props.is_joint}
                        is_contrast_muar={props.is_contrast_muar}
                        back_image_vertical={props.back_image_vertical}
                        exact_value={props.exact_value}
                        {...props.data}
                        color={
                            props.color
                                ?
                                props.color
                                :
                                props.palette && props.palette.card
                                    ?
                                    props.palette.card.backgroundColor
                                    :
                                    PalettePresets()[0].card.backgroundColor
                        }
                        back_image={
                            props.cards && props.cards[i] && props.cards[i].back_image
                            ?
                            props.cards[i].back_image
                            :
                            props.data.back_image
                        }
                        columns={count}
                        section_id={1}
                        fields={
                            props.data.cards && props.data.cards[i]
                                ?
                                props.data.cards[i].fields
                                :
                                ""
                        }
                        scale={scale}
                    />
                })
            }
        </div>
    </div>
}
export default CardPreview