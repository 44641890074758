const { default: ScreenSizeList } = require("modules/pe-landing-module/widgets/utilities/ScreenSizeList")

/*
Search and return nearest ready grid in grids list. If all grids are not enabled return null
enabled: lxl, lx, xl, md, sm, mc
*/
const getNeighbourGrid = (gridsList, currentGridId) =>
{
    if(gridsList[currentGridId])
    {
        return gridsList[currentGridId]
    }
    const current = ScreenSizeList().filter((size, i) => size.id === currentGridId )[0]
    if(!current) return null

    const neighbour = ScreenSizeList().filter( (size, i) => Array.isArray(gridsList[size.id]) )[0]
    return neighbour ? gridsList[neighbour.id] : null
}
export default getNeighbourGrid