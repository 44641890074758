import React, { Component } from "react" 
import { Dialog, } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import _fetch from "." 
import GanreCheckBoxList from "./GanreCheckBoxList" 
import kabuki from "../../assets/img/kabuki.svg" 

class GanreForm extends Component {
	state = {
	  isDialogOpen: false,
	  is: false,
	  width: 0,
	  ganres: this.props.ganres || [],
	}

	componentDidUpdate(prevProps)
	{
		if(prevProps.ganres !== this.state.ganres)
		{
			this.setState({ganres: prevProps.ganres})
		}
	}
	componentDidMount() {
	  // this.tick
	}

	componentWillUnmount() {
	  clearTimeout(this.tick)
	}

	render() {
	  const style = { width: 50 }
	  // console.log( this.props.ganres)
	  return this.props.is_ganres
		?
		<>
			<div className="d-flex mt-auto">
				<div className="overflow-hidden transition-500" style={{ width: this.state.width, height: 90 }}>
					<GanreCheckBoxList
					ganres={this.state.ganres}
					ignore={[]}
					isLine
					name="ganres"
					onGanre={this.onGanre}
					/>
				</div>
				<div className="indicator classic mt-auto" onClick={this.onCreateToggle}>
					<div className="n1">
						{ __("Ganres") }
					</div>
					<div className="iconnn">
						<img src={kabuki} alt="" style={style} />
					</div>
				</div>
			</div>
			<Dialog
				isOpen={this.state.isDialogOpen}
				title={__("Filter ganres")}
				onClose={this.onDialogToggle}
				className="little"
				>
				<div className="p-4">
					<GanreCheckBoxList
					ganres={this.state.ganres}
					ignore={[]}
					name="ganres"
					onGanre={this.onGanre}
					/>
				</div>
			</Dialog>
		</>
	  	:
		null
	}

	onGanre = (ganres) => {
	  this.props.onGanre(ganres)
	  clearTimeout(this.tick)
	  this.tick = setTimeout(() => {
	    this.setState({ width: 0 })
	  }, 5000)
	}

	onSwitchToggle() {
	  let width
	  clearTimeout(this.tick)
	  if (this.state.width > 0) {
		if(this.props.onOpen) this.props.onOpen( false )
	    width = 0
	  } else {
	    width = this.props.ganres.length * 92
	    this.tick = setTimeout(() => {
	      this.setState({ width: 0 })
		  if(this.props.onOpen) this.props.onOpen( false )
	    }, 7000)
	  }
	  
	  this.setState({ width })
	}

	onDialogToggle=() => 
	{ 
	  this.setState({ isDialogOpen: !this.state.isDialogOpen })
	}

	onCreateToggle =() => {
		if(this.props.onOpen) this.props.onOpen( true )
		if (window.innerWidth < 540) {
			this.onDialogToggle()
		} else {
			this.onSwitchToggle()
		}
	}
}
export default GanreForm
