import { Button, Collapse, Alignment, Intent, ButtonGroup } from "@blueprintjs/core"
import InputForm from "../../../views/LandingState/edit/InputForm"
import React, { useState } from "react"
import { __ } from "react-pe-utilities/dist"

const MatchingCard = props =>
{
    const [isOpen, onOpen] = useState( props.isOpen )
    const [ data, onData] = useState( props.data )
    //console.log(props)
    const onField = (value, field, type, dopol) =>
    {
        //console.log( value, field, type, dopol )
        let data = { ...props.data }
        data[ field ] = value
        onData( data )
        props.onEditCard( data, props.i )
    }
    return <div className={`p-0 mb-1 ${ props.isSingle ? "" : 'border border-dark' } w-100`} >
        <ButtonGroup vertical={false} className="d-flex parent-hover">
            {
                props.isSingle
                    ?
                    null
                    :
                    <>
                        <Button 
                            icon="caret-down"
                            alignText={ Alignment.LEFT } 
                            className="title p-2 mr-1 flex-grow-100" 
                            minimal 
                            title={__("Open to edit")}
                            onClick={ () => onOpen( !isOpen ) } 
                            fill
                        >
                        { 
                            props.visible_value && data[props.visible_value] 
                                ? 
                                data[props.visible_value] 
                                : 
                                __( props.default_title ) + " " + ( props.i + 1 ) 
                        }
                        </Button>
                        <Button
                            icon="plus"
                            intent={Intent.SUCCESS} 
                            title={__("Add new")}
                            className="mr-1 child-hover "
                            onClick={() => props.onAddNew( props.landing_object, props.i )}  
                            minimal            
                        />
                        <Button
                            icon="trash"
                            intent={Intent.DANGER} 
                            title={__("Delete")}
                            className="mr-1 child-hover delay1" 
                            onClick={() => props.onRemove( props.i )} 
                            minimal            
                        />
                        <Button
                            icon={<i className="fas fa-angle-double-up" />} 
                            disabled={ props.i === 0} 
                            title={__("Switch up")}
                            minimal
                            className=" child-hover delay2" 
                            onClick={() => props.onSwitch( props.i )}              
                        />
                    </>
            }
            
        </ButtonGroup>
        <Collapse isOpen={isOpen}>
            <InputForm 
                vertical
                { ...data }
                source={ props.landing_object }
                sourceType={ props.landing_object }
                data={ data }
                on={(value, field, dopol) => onField(value, field, "matchings", dopol)} 
            />
        </Collapse>
    </div>
}
export default MatchingCard