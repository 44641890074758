import React from "react"

const CardTemplate = props =>
{
    return {
        fields: [
            {
                type            : props.type,           // media, string, inner, outer, price, personal_links, cf, divider, section
                variant         : props.variant,        // int 0 - 7
                variant_param_1 : props.variant_param_1,
                variant_param_2 : props.variant_param_2,
                variant_param_3 : props.variant_param_3,
                variant_param_4 : props.variant_param_4,
                variant_param_5 : props.variant_param_5,
                variant_param_6 : props.variant_param_6,
                variant_param_7 : props.variant_param_7,
                variant_param_8 : props.variant_param_8,
                variant_param_9 : props.variant_param_9,
                variant_param_10: props.variant_param_10,
                height          : props.height,         // int
                bgcolor         : props.bgcolor,        // color
                class_name      : props.class_name,     // string
                style           : props.style,          // style object
                is_hover        : props.is_hover,       // boolean
                hover_color     : props.hover_color,    // color
                hover_duration  : props.hover_duration, // int
                hover_delay     : props.hover_delay,    // int

            }
        ],
        vertical_align          : props.vertical_align,
        height                  : props.height,
        color                   : props.color,
        padding                 : props.padding,
        reverse_mounting        : props.reverse_mounting,
        decoration              : props.decoration,
        cliping                 : props.cliping,
        tension                 : props.tension,
        box_shadow              : props.box_shadow,
        border_radius           : props.border_radius,
        border_top_right_radius : props.border_top_right_radius,
        border_bottom_left_radius:props.border_bottom_left_radius,
        border_bottom_right_radius:props.border_bottom_right_radius,
        random_rotaion          : props.random_rotaion,
        card_style              : props.card_style,
        tail_type               : props.tail_type,
        tail_style              : props.tail_style,
        tail_color_hover        : props.tail_color_hover,
        class_name              : props.class_name,
        style                   : props.style,
        composition             : {
            columns     : props.columns,
            is_blocked  : props.is_blocked
        }
        
    }
}

export default CardTemplate