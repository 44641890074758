import React, { Component } from "react"
import {  Link } from "react-router-dom" 
import _fetch from "../../"
import { __ } from "react-pe-utilities" 
import empty from "../../../../assets/img/empty.png"  
import WPFestSettings from "../../utilities/WPFestSettings"

class FestMemberBtn extends Component {
  render() {
    const { 
      id, o, e, img, cl,
    } = this.props
    const url = [1, 2].filter((e) => e == WPFestSettings.status).length > 0
      ?			
      `${this.props.route}/member/${id}`
      :			
      `${this.props.route}/member/${id}`
    return (
      <div className="col-xl-1_8 col-lg-2 col-md-3 col-sm-4 col-6" key={id}>
        <Link to={url}>
          <div className={`member ${cl}`}>
            <img src={empty} className="empty" alt="" />
            <div className="member_title">
              {o}
            </div>
            <div className="xperts " data-cl={e === 0 ? "hidden" : ""}>
              {e}
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
export default FestMemberBtn
