import React from "react" 
import SectionContent from "./SectionContent"
import { getDefault } from "./Section"
import Card from "./Card"
import $ from "jquery"
import chroma from "chroma-js"
import { getRevertColor, _chroma } from "./data/getColor"

class Cards extends SectionContent {
  is() {
    const { cards } = this.state.data
    return Array.isArray(cards) && cards.length > 0
  }

  getState (   )
  {
    const {
      fields,
      cards
    } = this.props.data
    const {
      section_id,
      menu
    } = this.props
    const {id} = menu
    setTimeout((__this) => {
      if(Array.isArray(fields))
      {
        fields.forEach((field, i) => {
          if( !Array.isArray(cards) ) return
          if( !field ) return
          if( field.height_type !== "align" ) return
          let max = 0;  
          cards.forEach((card, ii) => {
            const fld = $( "#section-" + id + " .landing-cards > [card_id='" + ii +"'] > .landing-card > [field_id='" + i + "']")
            const iH = fld.innerHeight()
            max = Math.max(max, iH)
            //console.log($( "#section-" + id + " .landing-cards > [card_id='" + ii +"'] > .landing-card > [field_id='" + i + "']" ) )
            //console.log("#section-" + id + " .landing-cards > [card_id='" + ii +"'] > .landing-card > [field_id='" + i + "']" )
            //console.log( max, iH, fld.length, i )
          })
          // console.log( i, field.type, max)
          $( "#section-" + id + " .landing-cards > .landing-card-cont > .landing-card > [field_id='" + i + "']").innerHeight(max)
        })
        
      }
      
    }, 300, this)
    //console.log( this.props )
    return super.getState()
  }

  renderContent(style) {
    const { composition, section_id, palette, is_edit } = this.state
    //console.log(this.props);
    const {
      class_name,
      padding,
      hide_action_icon,
      data_type,
      orientation,
      cards,
      cards_align,
      vertical_align,
      height,
      decoration,
      cliping,
      card_inner_style,
      card_style,
      tail_type,
      tail_style,
      is_empty_tail_hide,
      tail_color_hover,
      tension,
      border_radius,
      box_shadow,
      random_rotaion,
      border_top_right_radius,
      border_bottom_left_radius,
      border_bottom_right_radius,
      fields,
      horizontal_grid_proportia,
      reverse_mounting,
      is_back_image,
      is_contrast_muar,
      back_image_vertical,
      exact_value,
      back_image,
      is_joint,
      back_opacity,
      color,
      lasy_load_behavior
    } = this.props.data

    // console.log(palette)
  
    const cards_elems = Array.isArray(cards)
      ?
      cards.map((e, i) => {
        // console.log(e)        
        const bgColor =  e.color
          ?
          e.color
          :
          color
            ?
            color
            :
            palette.card ? palette.card.backgroundColor : null 
        return <Card
          key={i + Math.random()}
          i={i}
          section_id={section_id} 
          composition={composition}
          orientation={orientation} 
          palette={palette}
          data_type={data_type}
          cards_count={cards.length}
          reverse_mounting={reverse_mounting ? cards.length - i : i}
          cardFields={fields}
          card_fields={fields} // дубль для формы редактирования
          height={height}
          vertical_align={vertical_align}
          padding={padding}
          hide_action_icon={hide_action_icon}
          decoration={decoration}
          cliping={cliping}
          card_style={card_style}
          card_inner_style={card_inner_style}
          tail_type={tail_type}
          tail_style={tail_style}
          tail_color_hover={tail_color_hover}
          is_empty_tail_hide={is_empty_tail_hide}
          tension={tension}
          border_radius={border_radius}
          box_shadow={box_shadow}
          random_rotaion={random_rotaion}
          border_top_right_radius={border_top_right_radius}
          border_bottom_left_radius={border_bottom_left_radius}
          border_bottom_right_radius={border_bottom_right_radius}
          is_back_image={is_back_image}
          is_joint={is_joint}
          is_contrast_muar={is_contrast_muar}
          back_image_vertical={back_image_vertical}
          exact_value={exact_value}
          back_opacity={back_opacity}
          {...e}
          back_image={
            e.back_image
              ?
              e.back_image
              :
              back_image
          }
          color={ bgColor } 
          lasy_load_behavior={ lasy_load_behavior }
          order={ this.getCardOrder( i ) }
          columns={ composition.columns } 
          horizontal_grid_proportia={ horizontal_grid_proportia } 
          ID={ this.props.id }
          is_edit={ is_edit }
          onCardEdit={ this.onCardEdit }
        />
      })
      :
      []
    return (
      <div
        className={
          `landing-cards  landing-section-core ${class_name || ""}`
        }
        style={{ 
          justifyContent: cards_align, 
          ...style 
        }}
      >
        {cards_elems}
      </div>
    )
  }
  getCardOrder = i =>
  {
    if(!this.randomOrder)
      this.randomOrder = this.getRandomOrder()
    return this.randomOrder[i]
  }
  getRandomOrder = () =>
  {
    const or = Array(this.props.data.cards.length+1 ).fill(1).map((e,i) => i)
    let newOr = []
    while(or.length >= newOr.length)
    {
      newOr = newOr.concat( or.splice( Math.floor( Math.random() * or.length ) ) )
    }
    // console.log( newOr )
    return newOr
  }
  onCardEdit = (data) => {
    let dat = {
      ...data
    }
    const i = dat.i
    delete dat.location
    delete dat.match
    delete dat.is_edit
    delete dat.columns
    delete dat.onCardEdit
    delete dat.palette
    delete dat.width
    delete dat.height
    delete dat.composition
    delete dat.cardFields
    delete dat.card_fields
    delete dat.section_id
    delete dat.reverse_mounting
    delete dat.vertical_align
    delete dat.padding
    delete dat.decoration
    delete dat.cliping
    delete dat.card_style
    delete dat.tail_type
    delete dat.tail_style
    delete dat.tail_color_hover
    delete dat.tension
    delete dat.tension
    delete dat.border_radius
    delete dat.random_rotaion
    delete dat.box_shadow
    delete dat.border_top_right_radius
    delete dat.border_bottom_left_radius
    delete dat.border_bottom_right_radius
    delete dat.i
    dat.field = [...dat.fields] // не понятно, откуда дубль? TODO
    let cards = [...this.state.data.cards]
    cards[i] = dat
    const state = {
      ...this.state.data,
      cards
    }
    if (this.props.onSectionChange) {
      this.props.onSectionChange(
        "cards",
        {
          data: state,
          id: this.state.id,
        }
      )
    }
  }

  onEdit = (data, id) => {
    console.log("onEdit", id, data, this.state)
    const cards = [...this.state.data.cards]
    const secs = []
    cards.forEach((e) => {
      if (e.id === data.id) {
        secs.push(data)
      } else {
        secs.push(e)
      }
    })
    this.setState({ data: { ...this.state.data, cards: secs } })
    this.props.onEdit(
      { ...this.state, data: { ...this.state.data, cards: secs } },
      this.props.id,
    )
  }

  onUp = (data) => {
    console.log("onUp", data, this.state)
    const cards = [...this.state.data.cards]
    const sec = { ...cards[data] }
    cards.splice(data, 1)
    cards.splice(data - 1, 0, sec)
    console.log(cards)
    this.setState({ data: { ...this.state.data, cards } })
    this.props.onEdit(
      { ...this.state, data: { ...this.state.data, cards } },
      this.props.id,
    )
  }

  onDn = (data) => {
    console.log("onDn", data, this.state)
    const cards = [...this.state.data.cards]
    const sec = { ...cards[data] }
    cards.splice(data, 1)
    cards.splice(data + 1, 0, sec)
    console.log(cards)
    this.setState({ data: { ...this.state.data, cards } })
    this.props.onEdit(
      { ...this.state, data: { ...this.state.data, cards } },
      this.props.id,
    )
  }

  onAdd = (data) => {
    console.log("onAdd", data, this.state)
    const cards = [...this.state.data.cards]
    const sec = getDefault()
    cards.splice(data + 1, 0, sec)
    console.log(cards)
    this.setState({ data: { ...this.state.data, cards } })
    this.props.onEdit(
      { ...this.state, data: { ...this.state.data, cards } },
      this.props.id,
    )
  }

  onRnv = (data) => {
    console.log("onRnv", data, this.state.data.cards)
    const cards = [...this.state.data.cards]
    cards.splice(data, 1)
    console.log(cards)
    this.setState({ data: { ...this.state.data, cards } })
    this.props.onEdit(
      { ...this.state, data: { ...this.state.data, cards } },
      this.props.id,
    )
  }
}
export default Cards
