import React from "react" 
import SectionContent from "./SectionContent"
import Section, { getDefault } from "./Section"

class Columns extends SectionContent {
  is() {
    const { sections } = this.props.data
    return Array.isArray(sections) && sections.length > 0
  }

  renderContent(style) { 
	const {composition} = this.props
    const {
      class_name, sections, proportia, column_gap,
    } = this.props.data
    return (
      <div
        className={
				`landing-columns ${
				 class_name || ""
				} columns-${ composition.columns}`
			}
        style={{ ...style, columnGap: `${column_gap}px` }}
      > 
        {
			sections.map((e, i) => {
				const gap = column_gap ? (column_gap / sections.length): .1
			  	const width = composition.inner_proportia && composition.inner_proportia[i] && sections.length > 0
			  		? 
					"calc(" + composition.inner_proportia[i] +"% - " + gap + "px)"
					: 
					"calc(" + (100 / sections.length) +"% - " + gap + "px)"
			  	const estyle = { ...e.style, width: width }
			  	return (
					<div style={estyle} className={` landing-single-column landing-element ${e.className}`} key={i}>
						<Section
							{...e}
							style={{ color: this.props.revertColor, height: "100%", ...this.getStyle(e.style) }}
							i={i}
							user={this.props.user}
							is_edit={this.props.is_edit}
							level={this.props.level + 1}
							onEdit={this.onEdit}
							onUp={this.onUp}
							onDn={this.onDn}
							onAdd={this.onAdd}
							onRnv={this.onRnv}
							revertColor={this.props.revertColor}
							palette={this.props.palette} 
						/>
					</div>
			  	)
			})
		}
      </div>
    )
  }

	onEdit = (data, id) => {
	  console.log("onEdit", id, data, this.props)
	  const sections = [...this.props.data.sections]
	  const secs = []
	  sections.forEach((e) => {
	    if (e.id === data.id) {
	      secs.push(data)
	    } else {
	      secs.push(e)
	    }
	  })
	  this.setState({ data: { ...this.props.data, sections: secs } })
	  this.props.onEdit(
	    { ...this.props, data: { ...this.props.data, sections: secs } },
	    this.props.id,
	  )
	}

	onUp = (data) => {
	  //console.log("onUp", data, this.props)
	  const sections = [...this.props.data.sections]
	  const sec = { ...sections[data] }
	  sections.splice(data, 1)
	  sections.splice(data - 1, 0, sec)
	  //console.log(sections)
	  this.setState({ data: { ...this.props.data, sections } })
	  this.props.onEdit(
	    { ...this.props, data: { ...this.props.data, sections } },
	    this.props.id,
	  )
	}

	onDn = (data) => {
	  console.log("onDn", data, this.props); const sections = [...this.props.data.sections]
	  const sec = { ...sections[data] }
	  sections.splice(data, 1)
	  sections.splice(data + 1, 0, sec)
	  console.log(sections)
	  this.setState({ data: { ...this.props.data, sections } })
	  this.props.onEdit(
	    { ...this.props, data: { ...this.props.data, sections } },
	    this.props.id,
	  )
	}

	onAdd = (data) => {
	  console.log("onAdd", data, this.props)
	  const sections = [...this.props.data.sections]
	  const sec = getDefault()
	  sections.splice(data + 1, 0, sec)
	  //console.log(sections)
	  this.setState({ data: { ...this.props.data, sections } })
	  this.props.onEdit(
	    { ...this.props, data: { ...this.props.data, sections } },
	    this.props.id,
	  )
	}

	onRnv = (data) => {
	  console.log("onRnv", data, this.props.data.sections)
	  const sections = [...this.props.data.sections]
	  sections.splice(data, 1)
	  console.log(sections)
	  this.setState({ data: { ...this.props.data, sections } })
	  this.props.onEdit(
	    { ...this.props, data: { ...this.props.data, sections } },
	    this.props.id,
	  )
	}

	onHide = (id, is_hide) => {
	  console.log("HIDE", id, is_hide)
	}

	onRemoveFloat = (float_id) => {

	}

	onUpdateFloat = (data, float_id, section_id) => {

	}
}
export default Columns
