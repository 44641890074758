import React, { Component } from "react" 
import matrix from "./data/matrix"
import EditLabel from "./EditLabel"
import { getStyle } from "./Section"
import EditibleText from "./edit/EditibleText"

class Title extends Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props, editible: "" }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.is_edit !== this.state.is_edit) {
      this.setState({ is_edit: nextProps.is_edit })
    }
    const state = {}
    for (const i in matrix().title) {
      if (nextProps[i] !== this.state[i]) {
        state[i] = nextProps[i]
      }
    }

    if (Object.keys(state).length > 0) {
      // console.log( Object.keys(state).length, state );
      this.setState({ ...state })
    }
  }
  onEdit = data => {
    if (this.props.onEdit) {
      this.props.onEdit({ title: data })
    }
  }

  onEditContent = (text, field_matrix) =>
  {
      console.log( text, field_matrix )
      if(this.props.onEditContent)
        this.props.onEditContent( text, field_matrix )
  }
  onEditOpen = ( field_matrix ) =>
  {
    if(this.props.onEditOpen)
      this.props.onEditOpen( field_matrix, this.props.type )
  }

  render() {
    const {
      text, text_src, class_name, style, spn, is_edit
    } = this.state

    const styleObj = getStyle(style)
    //console.log( spn )
    return text || text_src
      ? (
        <div className={`landing-title ${class_name}`} style={styleObj}>
          <span
            style={{
              ...getStyle( spn ),
              backgroundColor: style.plateColor,
              padding: "3px 10px",
              position:"relative"
            }}
          >
            <EditibleText 
                type={ "span" }
                text={ text }
                field_matrix={ ["title", "text"] }
                is_edit={ is_edit }
                className={` `}  
                onEditContent={ this.onEditContent }
                onEditOpen={ this.onEditOpen }
                editible = {this.state.editible}
                revertColor={this.props.revertColor}
            />
            {/* 
            <span dangerouslySetInnerHTML={{ __html : text }} />
            <EditLabel
              {...this.state}
              isBtn={true}
              st={{
                marginRight: -20
              }}
              children={null}
              source="title"
              onEdit={this.onEdit}
              data={this.state}
            /> 
            */}
            {
              style.before || true
                ?
                <span 
                  className="before"
                  style={ getStyle(style.before) }
                >

                </span>
                :
                null
            }

          </span>
        </div>
      )
      : null
  }
}
export default Title
