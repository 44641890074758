import React from "react"
import SectionContent from "./SectionContent"
import $ from "jquery"
import Style from "style-it"

class MagicGlass extends SectionContent 
{
    is() 
    {
		return this.state.data.src || this.state.data.section
	}
    componentDidMount() 
    {
        const 
        { 
            zoom,
            src 
        } = this.props.data
        const _this = this
        setTimeout(() => { 
            var native_width = 0;
            var native_height = 0;
            var width = 0 
            var height = 0
            $(".magnifier").mousemove(function(e) 
            { 
                const pathPrefix = `[data-id="${ _this.props.id }"] .landing-magic-glass-container `
                if (!native_width && !native_height) 
                {
                    width = $(`${pathPrefix} .mglass-small`).width() * zoom 
                    height = $(`${pathPrefix} .mglass-small`).height() * zoom 
                    var image_object = new Image();
                    image_object.src = src;
                    image_object.width = width;
                    image_object.height = height;
                    native_width  = width //image_object.width;
                    native_height = height //image_object.height;
                    console.log( native_width, native_height, image_object.width, image_object.height)
                    $(`${pathPrefix} .mglass-large`).css({ backgroundSize:`${width}px ${height}px` });
                } 
                else 
                {
                    var magnify_offset = $(this).offset();
                    var mx = e.pageX - magnify_offset.left;
                    var my = e.pageY - magnify_offset.top;  
                    if (mx < $(this).width() && my < $(this).height() && mx > 0 && my > 0) 
                    {
                        $(`${pathPrefix} .mglass-large`).fadeIn( 100 )//.appendTo("#modal-root");
                    } 
                    else 
                    {
                        $(`${pathPrefix} .mglass-large`).fadeOut( 100 );
                    }
                    if ($(`${pathPrefix} .mglass-large`).is( ":visible" )) 
                    {
                        var rx =  Math.round( mx / $(`${pathPrefix} img.mglass-small`).width() * native_width - $(`${pathPrefix}  .mglass-large`).width() / 2 ) * -1;
                        var ry = Math.round( my / $(`${pathPrefix} img.mglass-small`).height() * native_height - $(`${pathPrefix}  .mglass-large`).height() / 2 ) * -1;
                        var bgp = rx + "px " + ry + "px";      
                        var px = mx - $(`${pathPrefix}  .mglass-large`).width() / 2;
                        var py = my - $(`${pathPrefix}  .mglass-large`).height() / 2;
                        $(`${pathPrefix}  .mglass-large`).css({ left: px, top: py, backgroundPosition: bgp });
                    }
                }
            });
        }, 1200)
        
    }
    componentDidUpdate()
    {
        
    }
    renderContent(style) 
    {
        const 
        {
            magnifier_width,
            magnifier_height,
            rounded,
            section,
            src
        } = this.props.data

        return Style.it(
            `
            .mglass-large
            {
                width:${ magnifier_width}px;
                height:${ magnifier_height}px;
                border-radius: ${ rounded }%;
                background: url(${src}) no-repeat;
            }
            `,
            <div className="landing-magic-glass-container  landing-section-core ">
                <div className="magnifier">
                    <div className="mglass-large" />
                    <img 
                        className="mglass-small" 
                        src={src} 
                        width="100%"
                    />
                </div>
            </div>
        )
    }
}

export default MagicGlass