import React from "react"
import { withApollo } from "react-apollo" 
import { compose } from "recompose"
import { Button } from "@blueprintjs/core"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"
import { initArea } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'

class AdminView extends BasicState {
	basic_state_data() { 
		return {			
			isLeftClosed: window.innerWidth < 760,
			
		}
	} 
	renderCenter()
	{
		return "CENTER SECTION"
	}

	
	render() {
		const {  isLeftClosed } = this.state
		const leftClass = isLeftClosed
			? "tutor-left-aside-2 menu-aside closed"
			: "tutor-left-aside-2 menu-aside"
		const mainClass = isLeftClosed
			? "tutor-main-2 pr-0 opened"
			: "tutor-main-2 pr-0"
		
		const help = this.props.help_url
			?
			<span>
				<Button 
					icon="help" 
					minimal 
					onClick={ () => window.open( this.props.help_url, '_blank' ) } 
				/>
			</span> 
			:
			null
		return (
			<div className="layout-state p-0">
				<div className="tutor-row menu-row">
					<div className={leftClass}>
						<div className="layout-state-head menu-header-22">
							<LayoutIcon
								isSVG
								src={this.state.route.icon}
								className="layout-state-logo "
							/>
							<div className="layout-state-title">
								{help}
								{__(this.state.route.title)} 
							</div>
						</div>
						<div className="small mx-3 mb-3 " dangerouslySetInnerHTML={{ __html: this.props.description }} />
						<div className="small mx-3 mb-3 text-secondary">

						</div>						
						{
							initArea("admin-menu-left-aside",
								{ ...this.props })
						}
					</div>
					<div className={mainClass}>
						<div
							className="clapan-left"
							onClick={() => this.setState({ isLeftClosed: !isLeftClosed })}
						>
							<div className={`fas fa-caret-${!isLeftClosed ? "left" : "right"}`} />
						</div>
						<div className="menu-header-22 flex-centered py-2 pl-5 pr-2">
							
						</div>
						<div className=" p-4 "> 
							{ this.renderCenter() }	
						</div>
					</div>
					<div className="tutor-right-aside-2">
						{
							initArea(
								"admin-menu-right-aside",
								{ ...this.props },
							)
						}
					</div>
				</div>
			</div>
		)
	}
}

export default  compose(
	withApollo, 
)(AdminView)

