import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import $ from "jquery"
import { useState } from 'react' 
import Style from 'style-it'
import DataContext from '../DataContext'
import { getRevertColor } from "../data/getColor"
import LandingView from '../../LandingView'
import { getFontNameByID } from '../data/PalettePresets' 

let crr = 0
let isLock = false
const LandingMouseWeelScroll = props => {
    const [current, setCurrent] = useState(0) 
    
    //const [isLock, setLock] = useState(false) 
    const mousewheel = evt =>
    {
        if( LandingView.isLockScroll ) return
        if( isLock ) return
        isLock = true 
		const sm = props.user && props.user.roles.filter(role => role === "administrator").length > 0 ? 50 : 0
        // console.log( props.user )
        const windowInnerHeight = $(".landing-container").innerHeight() - sm
        if(evt.deltaY > 0)
        {             
            let cur = crr + 1 
            $(".landing-container").animate(
                {scrollTop : cur * windowInnerHeight}, 
                600, 
                "swing",
                () => {
                    isLock = false
                }) 
            crr = cur < $(".container-float > .landing-section-container > .landing-section").length ? cur :  crr
            setCurrent( crr )             
                  
        }            
        else
        {
            let cur = crr - 1
            $(".landing-container").animate(
                {scrollTop : cur * windowInnerHeight}, 
                600, 
                "swing",
                () => {
                    isLock = false
                })
            crr = cur >= 0 ? cur : crr
            setCurrent( crr )
           
        }
    }
    useEffect(() =>
    {
        setTimeout(() => { 
            setCurrent( parseInt($(".landing-container").scrollTop() / window.innerHeight))
            window.addEventListener("mousewheel", mousewheel)
        }, 200);
        return () => {
            window.removeEventListener("mousewheel", mousewheel)
        }
    }, []) 

    const changeCurrent = i =>
    {
        const windowInnerHeight = $(".landing-container").innerHeight() 
        $(".landing-container").animate({scrollTop : i * windowInnerHeight}, 300, "linear") 
        setCurrent( i )  
        crr = i  
        console.log(crr)        
    }

    const section = DataContext.data.sections[ current ]
    const clr = section?.background?.color 
        ?
        section?.background?.color
        :
        section?.palette?.background_color             

    let ulLi = []
    $(".container-float > .landing-section-container > .landing-section").each( (i, el) => {
        ulLi.push( <li 
            aria-hidden="true" 
            role="presentation" 
            aria-selected="true"
            className={i === current ? "slick-active" : ""}
            key={i}
        >
            <button type="button" data-role="none" tabIndex="0" onClick={() => changeCurrent(i) }>
            </button>
            <div className='slick-label'>
            {
                DataContext.data.sections[i] && DataContext.data.sections[i].menu.label
                    ?
                    DataContext.data.sections[i].menu.label
                    :
                    (i + 1) 
            }    
            </div>               
        </li> ) 
    }) 
    return Style.it(
        `.landing-slick-marks li.slick-active button,
        .landing-slick-marks li .slick-label, 
        .landing-slick-marks li button:hover
        {
            color : ${ getRevertColor(clr, section?.palette) };
        }
        .landing-slick-marks li .slick-label
        {
            font-family: ${ getFontNameByID(section?.palette?.card.title.fontFamilyID)};
        }
        .landing-slick-nicks li button:before
        {
            background : ${ getRevertColor(clr, section?.palette) };
        }
        .landing-slick-dots li button:before,
        .landing-slick-bricks li button:before
        {
            border-color : ${ getRevertColor(clr, section?.palette) };
        }
        .landing-slick-dots li.slick-active button:before, 
        .landing-slick-marks li button:before,
        .landing-slick-bricks li.slick-active button:before 
        {
            background : ${ getRevertColor(clr, section?.palette) };
            border-color : ${ getRevertColor(clr, section?.palette) };
        }`,
        <div className={` ${ props.containerClassName } `}  style={props.containerStyle}>
            { props.children }
            <ul 
                className={`landing-slick-${DataContext.data.landing.landing_flow_menu_type || "dots"}`} 
                role="tablist"
            >
                { ulLi}
            </ul>
        </div>
    )
}

LandingMouseWeelScroll.propTypes = {}

export default LandingMouseWeelScroll