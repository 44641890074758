import React, { useState, useRef, useEffect } from "react"
import { Button, ButtonGroup, Icon, Intent } from "@blueprintjs/core"
import { __ } from "react-pe-utilities"  

const EditibleText = props =>
{
    let timerClick = 0;
    const ref = useRef( null )
    const [ text, changeText ] = useState( props.text )
    const [ editibleClassName, changeeditible] = useState(props.editible ? props.editible : "" )
    const [ isEdit, changeIsEdit ] = useState( props.is_edit )
    useEffect(() => changeText(props.text), [props.text])
    useEffect(() => 
    {
        const pasted = (event) => { 
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            paste = paste.replace(/<\/?[^>]+(>|$)/g, "").replace('"', "'");
            const selection = window.getSelection();
            if (!selection.rangeCount) return false;
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(document.createTextNode(paste));
            event.preventDefault();
        }
        const content = ref.current.getElementsByClassName("editibale-text--content")[0] 
        content.addEventListener('paste', pasted);
        // return content.removeEventListener('paste', pasted);
    }, [])
    useEffect(() => {
        changeeditible( props.is_edit ? editibleClassName : "" )
        //changeIsEdit( props.is_edit )
        const content = ref.current.getElementsByClassName("editibale-text--content")[0]
        content.innerHTML = text
    }, [props.is_edit])
    const onDoubleClick = evt =>
    {
        clearTimeout( timerClick );
        if (evt.detail === 1) 
        {
            timerClick = setTimeout(() => null, 200)
        } 
        else if (evt.detail === 2) 
        { 
            if(!props.is_edit) return  
            if (window.getSelection) {window.getSelection().removeAllRanges();}
            else if (document.selection) {document.selection.empty();}
            changeIsEdit(true)
            changeeditible( " editible-text " )
            
            const content = ref.current.getElementsByClassName("editibale-text--content")[0]
            content.focus()
            
        }        
    }
    const onChange = evt =>
    {
        console.log( evt ) 
    }
    const onCloseEdit = () =>
    { 
        const content = ref.current.getElementsByClassName("editibale-text--content")[0] 
        content.innerHTML = text
        changeeditible( "" )
        changeIsEdit( false )
        // changeText( props.text )
    }
    const onUpdate = () =>
    {
        const content = ref.current.getElementsByClassName("editibale-text--content")[0]
        const t = content.innerHTML.replaceAll("<br>","").replaceAll('"', "'")
        changeText( t )        
        changeeditible( "" )
        changeIsEdit( false )
        if(props.onEditContent)
            props.onEditContent( t, props.field_matrix )
    }
    const onEditOpen = () =>
    {
        if(props.onEditOpen)
            props.onEditOpen( props.field_matrix )

    }

    const editor = isEdit && false
        ?
        <div
            className="editor_text-container" 
        >
            <ButtonGroup vertical={ false }>
                <Button 
                    icon={ <i className="fas fa-pen" /> } 
                    intent={Intent.NONE} 
                    onClick={ onEditOpen } 
                    title={ __( "Extended editing" ) }
                /> 
                <Button 
                    icon={ <i className="fas fa-save" />} 
                    intent={Intent.SUCCESS} onClick={ onUpdate } 
                    title={ __( "Update text" ) }
                />  
                <Button 
                    icon={ <i className="fas fa-minus" /> } 
                    intent={Intent.DANGER} 
                    onClick={ onCloseEdit } 
                    title={ __( "Close" ) }
                /> 
            </ButtonGroup>
        </div>
        :
        null
    //console.log(props.style)
    return  props.type === "span"
            ?
            <span className={editibleClassName} ref={ref}> 
                <span 
                    dangerouslySetInnerHTML={{ __html: text }}
                    style={props.style}
                    className={`editibale-text--content ${props.className}`} 
                    onClick={onDoubleClick}
                    onChange={ onChange }
                />
                {editor}
            </span>
            :   
            <div className={editibleClassName + " w-100 "} ref={ref}>                  
                <div 
                    dangerouslySetInnerHTML={{ __html: text }}
                    style={props.style}
                    className={`editibale-text--content ${props.className}`} 
                    onClick={onDoubleClick}
                    onChange={ onChange }
                /> 
                {editor}
            </div> 
}
export default EditibleText