import React, { Component } from "react"

import BasicState from "react-pe-basic-view" 
import GridLayoutContainer from "./gridLayout"
import "../assets/css/style.css"
import Panel from "./gridLayout/Panel"
import _ from "lodash"; 
import { Drawer, Intent, Position } from "@blueprintjs/core"
import GridEditDrawer from "./gridLayout/GridEditDrawer"
import { AppToaster, Loading } from "react-pe-useful/dist"
import $ from "jquery"
import { __ } from "react-pe-utilities/dist"
import { getScreenSize } from "modules/pe-landing-module/widgets/utilities/ScreenSizeList"
import getNeighbourGrid from "../widgets/utils/getNeighbourGrid"

class GridLayoutView extends BasicState
{
    basic_state_data() { 
        return {
            layouts: getGridData(),
            currentGridID:-1,
            screenSize : getScreenSize( document.body.clientWidth )
        }
    }
    componentWillMount() 
    { 
		window.bgs = []
		window.pe_landing = { section: [] } 
        document.addEventListener("resize", this.onResize)
    }
    componentWillUnmount()
    {
        document.addEventListener("resize", this.onResize)
    }
    onResize = evt =>
    {
        const w = document.body.clientWidth
        let size = "lg"
        if(w < 540 )
            size = "xs"
        else if(w < 720)
            size = "md"
        else
            size = "lg"
        this.setState({ size })
    }
    local = this.props.extend_params && this.props.extend_params.local;
    getId() {
		// console.log( this.props.extend_params );
		return this.props.extend_params && this.props.extend_params.id ? this.props.extend_params.id : "grid_layout_page"
	}

    onLayoutChange = (dataArr, oldArr, notOnlyLg) => 
    { 
        //console.log( dataArr, this.state.layout, notOnlyLg )
        if(notOnlyLg)
        {
            if(
                Array.isArray(dataArr.lg) && 
                dataArr.lg[0] && 
                dataArr.lg[0].data && 
                this.state.layouts !== dataArr 
            )
            {
                //console.log( dataArr,  this.state.layouts )
                this.setState({ layouts : dataArr })
            }
                
        }
        else
        {
            //console.log( dataArr, this.state.layouts.lg )
            //console.log(!this.compare(this.state.layouts.lg, dataArr))
            const currentGrid = getNeighbourGrid(this.state.layouts, this.state.screenSize) 
            if( !currentGrid && !this.compare(currentGrid, dataArr) )
            {
                let layouts = !currentGrid ? getGridData() : currentGrid
                layouts.lg = dataArr.map((e, i) => { 
                    // console.log( e, layouts.lg[i] )
                    // console.log( layouts.lg[i].data, e.h )
                    return {
                        ...layouts.lg[i], 
                        data: {
                            ...layouts.lg[i].data,
                            height: e.h * GRID_HEIGHT_COEF() 
                        },
                        ...e
                    }
                })
                //console.log( layouts.lg )
                this.setState({ layouts })
            }                 
        }
        
    }
    
    compare = (newObj, oldObj) =>
    {
        //return newObj === oldObj
        let bool = true 
        newObj.map((e, i) => { 
            Object.keys(e).forEach(key => 
            {
                if( oldObj[i][key] !== e[key] )
                {
                    //console.log( i, key, oldObj[i][key] )
                    bool *= false
                }
            })
        })

        // console.log( bool )
        return bool
    }
    onToggleLayout = () => 
    { 
        this.setState({
            layouts : getGridData()
        })
    } 
    onDelete = id => 
    {
        let layouts = {...this.state.layouts}
        layouts.lg = layouts.lg.filter(e => e.id !== id)
        //console.log( this.state.layouts, layouts )
        this.setState({layouts})
    }
    onEdit = id => 
    {
        //console.log(id)
        this.setState({
            isEditOpen : true,
            currentGridID : id
        })
    }
    onEditClose = () =>
    {
        this.setState({
            isEditOpen:false
        })

    }
    onCopyClipboardAllLayout = data => 
    {
        const json = JSON.stringify( this.state.layouts )
        //console.log( this.state.layouts )
        // console.log( json )
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='myInput'>${ json }</textarea></div>`)
		const copyText = document.getElementById("myInput")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#myInput").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Grid-layouts data copy to clipbord"),
		})
    }
    onPasteFromClipboardAllLayout = () =>
    {
        navigator.clipboard.readText()
			.then((clipText) => {
				try 
				{ 
                    const json = JSON.parse(clipText) 
					//console.log( json, {}, true )
					this.onLayoutChange( json, {}, true )
				} 
				catch (e) 
				{ 
                    console.error(e)
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("Error read clipboard data"),
					})
				}
			})
    }
    onStartRaze = bool =>
    {

    } 
    getGridLayoutByID = id =>
    {
        //console.log(this.state.layouts, id)
        let grid = this.state.layouts && Array.isArray(this.state.layouts.lg)
            ?
            this.state.layouts.lg.filter(e => e.id === id)
            :
            null
        //console.log(grid )
        return grid[0] ? grid[0] : {}
    }
    render()
    {
		if (this.state.loading) {
			return (
				<div className="layout-state layout-center">
					<Loading />
				</div>
			)
		}
        
        return <div className={` layout-state p-0 `}>
            <div className="container">
                <GridLayoutContainer 
                    layouts={ this.state.layouts }
                    onLayoutChange={this.onLayoutChange}
                    onToggleLayout={this.onToggleLayout} 
                    onDelete={ this.onDelete }
                    onEdit={ this.onEdit }
                    onRaze={ this.onRaze }
                    width={1300}
                    gap={2}
                />
            </div>
            <Panel
                onToggleLayout={this.onToggleLayout}
                onCopyClipboard={this.onCopyClipboardAllLayout}
                onPasteFromClipboard={this.onPasteFromClipboardAllLayout}
            />
            <Drawer
                isOpen={this.state.isEditOpen}
                onClose={this.onEditClose}
                title={<span dangerouslySetInnerHTML={{ __html: "Edit "  }} />}
                position={Position.LEFT}
                usePortal
                backdropClassName="landing-drawer-bg"
                size={700}
            >                
                <GridEditDrawer 
                    data={this.getGridLayoutByID( this.state.currentGridID ).data }
                    current_type={ this.getGridLayoutByID( this.state.currentGridID ).current_type }
                    id={this.state.currentGridID}
                /> 
            </Drawer>
        </div>
    }
}

export default GridLayoutView


export function getGridData()
{
    const lg =  [ 0,1,2 ].map((item, i) =>
        {
            var y = Math.ceil(Math.random() * 12) + 1;
            const arr = {
                x: Math.floor((_.random(0, 5) * 2) % 12),
                y: Math.floor(i / 6) * y,
                w: 2,
                h: y,
                i: i.toString(),
                id: i,
                heightType: "strong", // strong, percents, fill, auto
                static: false, //Math.random() < 0.05,
                data: { text: i + ":" + y, height: y * GRID_HEIGHT_COEF(), text_align: "center", text_size: "large" },
                background:{},
                current_type:"html"
            }; 
            return arr
        }) 
    const layouts = { lg : lg }
    //console.log(lg )
    //console.log(layouts)
    return layouts
}
export const GRID_HEIGHT_COEF = () => 15
export const GRID_HEIGHT_FACTOR = () => 1.373
