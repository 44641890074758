import { Button } from "@blueprintjs/core"
import React, { Component } from "react"
import xml2js, { parseString, Builder } from "xml2js"
import { __ } from "react-pe-utilities" 
import svg_libs from "../../views/LandingState/data/svg_lib.json"

class SVGLabrary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      loading: true,
      mask_src: "",
      group: {},
    }
  }

  componentDidMount() {
    const { value } = this.state
    const mask = this.getSvgByID(value)
    console.log(value, mask)
    if (mask) {
      this.setState({ group: this.getGroupByID(mask.groups[0]) })
      this.loadXML(`/assets/img/landing/masks/${mask.src}.xml`)
    }
  }

  getSvgByID(id) {
    const res = svg_libs.masks.filter((e) => e.id == id)[0]
    return res || svg_libs.masks[0]
  }

  getSvgByGroup(id) {
    return svg_libs.masks.filter((e) => e.groups.filter((group) => group == id).length > 0)
  }

  getSvgOrderInGroup(svgId, groupId) {
    const group = this.getSvgByGroup(groupId)
    let n = -1
    group.forEach((svg, index) => {
      // console.log(svg, index, svgId, svg.id == svgId );
      if (svg.id == svgId) {
        n = index
      }
    })
    return n
  }

  getSvgByOrder(order, groupId) {
    const group = this.getSvgByGroup(groupId)
    return group[order % group.length]
  }

  getGroupByID(id) {
    const res = svg_libs.groups.filter((e) => e.id == id)[0]
    return res || svg_libs.groups[0]
  }

  loadXML(url) {
    const _this = this
    // console.log(url);
    fetch(url)
      .then((response) => response.text())
      .then((textResponse) => {
        if (textResponse) {
          parseString(
            textResponse,
            { trim: true },
            (err, result) => {
              // console.log( result);
              _this.setState({
                loading: false,
                mask_src: textResponse,
                mask_width: result.g.$.width,
                mask_height: result.g.$.height,

              })
            },
          )
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { mask_src, mask_width, mask_height } = this.state
    // console.log( this.props );
    const current = this.state.value ? this.state.value : 0
    return (
      <div className="landing-svg-lib-container ">
        <div
          className="landing-svg-lib-example "
          style={{
            backgroundImage: "url(/assets/img/landing/masks/romb1.xml)",
          }}
          onClick={this.onNext}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${mask_width} ${mask_height}`}
            dangerouslySetInnerHTML={{ __html: mask_src }}
          />
        </div>
        <Button
          className="px-4"
          text={this.state.group.title}
          onClick={this.onNextGroup}
        />
      </div>
    )
  }

    onNext = (evt) => {
      const { value } = this.state
      const mask = this.getSvgByID(value)
      // console.log(mask.id, this.state.group.id);
      const order = this.getSvgOrderInGroup(mask.id, this.state.group.id)
      const nxt = this.getSvgByOrder(order + 1, this.state.group.id)
      // console.log(order, nxt );
      this.setState({ value: nxt.id })
      setTimeout(() => {
        this.loadXML(`/assets/img/landing/masks/${nxt.src}.xml`)
        if (this.props.on) {
          this.props.on(this.props.field, nxt.id)
        }
      }, 100)
    }

    onNextGroup = (evt) => {
      const groupID = this.state.group.id
      let groupOrder = -1
      svg_libs.groups.forEach((e, i) => {
        if (e.id == groupID) {
          groupOrder = i
        }
      })
      const nextGroupOrder = (groupOrder + 1) % svg_libs.groups.length
      const nextGroup = svg_libs.groups[nextGroupOrder]
      const value = this.getSvgByOrder(0, nextGroup.id)
      this.setState({
        group: nextGroup,
        value: value.id,
      })
      setTimeout(() => {
        this.loadXML(`/assets/img/landing/masks/${value.src}.xml`)
        if (this.props.on) {
          this.props.on(this.props.field, value.id)
        }
      }, 100)
    }
}
export default SVGLabrary
