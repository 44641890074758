import matrix from "./data/matrix"

const historyDepth = 60

export default class DataContext {
	static type= "DataContext"
	static history = []
	static addHistory = () =>
	{
		const dbl = JSON.stringify({...DataContext.data})
		
		DataContext.history.push( dbl )
		if(DataContext.history.length > historyDepth)
		{
			DataContext.history.shift()
		} 
	}
	static isMayUndo()
	{
		return DataContext.history.length > 0
	}
	static getCurrentUndoLevel = () =>
	{
		return DataContext.history.length
	}
	static undo = () =>
	{
		if(DataContext.history.length > 0)
			DataContext.data = JSON.parse(DataContext.history.pop())
	}
	static redo = () =>
	{

	}
	static containerTypes = [
		"DataContext",
		"carousel", 
		"columns", 
		"rows", 
		//"accordeon",
		//"haccordeon",
		"accordeon_sections",
		//"cards",
		//"Card",
		//"CardField", 
		"zoom_pan_pinch",
		"Dilimiter",
		"include_section", 
	]
	static subContainerTypes = [
		{
			type: "accordeon",
			containerType: ["accordeon_sections"],
			field:"accordeon_sections"
		},
		{
			type: "haccordeon",
			containerType: ["accordeon_sections"],
			field:"accordeon_sections"
		},
		{
			type: "section",
			containerType: ["Dilimiter"],
			field:"bottom_dilimiter"
		},
		{
			type: "floats",
			containerType: ["link"],
			field:"sections"
		}

	]

	static data = {}

	static globals = []

	static upd(data, addToHistory= true) {
		// console.log( {...data} )
		if( addToHistory ) DataContext.addHistory()
		DataContext.data = { ...data }
	}

	static clear() {
		DataContext.addHistory()
		DataContext.data.maxSectionID = 0
		DataContext.data.maxFloatID = 0
		DataContext.data.sections = []
		DataContext.data.landing = matrix().landing.default
	}

	static setLanding(data) {
		DataContext.addHistory()
		// console.log( data );
		DataContext.data.landing = data
	}

	static updateGlobal(field, value) {
		DataContext.addHistory()
		if (!DataContext.data.globals) DataContext.data.globals = {}
		if (!DataContext.data.globals[field]) DataContext.data.globals[field] = []
		const obj = [...DataContext.data.globals[field], ...value]
		DataContext.data.globals[field] = value
		return DataContext.data.globals[field]
	}

	static getMaxSectionID(is_attept) {
		if (is_attept) {
			if(typeof DataContext.data.maxSectionID == "undefined")
				DataContext.data.maxSectionID = 0
			++DataContext.data.maxSectionID
		}
		return DataContext.data.maxSectionID
	}

	static getMaxFloatID(is_attept) {
		let id = !DataContext.data.maxFloatID ? 0 : DataContext.data.maxFloatID
		if (is_attept) {
			id++
			// console.log(id)
		}
		return id
	}
	static setFloatSelectID = float_id =>
	{
		DataContext.floatSelectID = float_id
	}
	static getFloatSelectID = () =>
	{
		return DataContext.floatSelectID
	}

	static getSection(id) {
		console.log(id)
		const sec = DataContext.searchSectionById(DataContext.data.sections, id )
		return sec ? sec : {}
	}

	static getSectionJSON(id) {
		let section = typeof id == "number" ? DataContext.getSection(id) : id
		section = { ...section, id: DataContext.getMaxSectionID(true) }
		return JSON.stringify(section)
	}

	static updatePalette = (template_id, checked_list) => {
		DataContext.addHistory()
		DataContext.data.sections.forEach((section, i) => {
			if ( checked_list[i].current_template_id === template_id) {
				DataContext.updatePaletteLevel( section, template_id )
			}
		})
	}
	static updatePaletteLevel = ( section, template_id ) =>
	{
		DataContext.addHistory()
		section.background = {}
		section.current_template_id = template_id
		if(Array.isArray(section.data.section))
		{
			section.data.section.forEach(sec => {
				DataContext.updatePaletteLevel(sec, template_id)
			})
		}
		if(Array.isArray(section.data.sections))
		{
			section.data.sections.forEach(sec => {
				DataContext.updatePaletteLevel(sec, template_id)
			})
		}
	}

	static setSectionJSON(data) {
		return JSON.parse(data)
	}
	static searchSectionById(sections, id) {
		console.log( id )
		if(!Array.isArray(sections)) return 
		let res = null;
		sections.forEach(section => {
			if (section.id == id) {
				res = section
				return
			}
			else {
				let child = null
				
				if (section.bottom_dilimiter && Array.isArray(section.bottom_dilimiter.sections)) {
					child = DataContext.searchSectionById(section.bottom_dilimiter.sections, id)
					if (child)
					res = child
					return
				}
				if (section.data && Array.isArray(section.data.sections)) {
					let ss = section.data.sections
					//console.log(ss)
					child = DataContext.searchSectionById(ss, id)
					if (child)
					res = child
					return
				}
				//}
			}
		})
		return res;
	}

	static updateSection(id, data) {
		if (Array.isArray(DataContext.data.sections)) 
		{		
			DataContext.addHistory()	
			DataContext.data.sections.forEach((e, i) => { 
				if (e.id == id) {
					//console.log( i, e.id )
					//console.log(DataContext.data.sections[i])
					DataContext.data.sections[i] = DataContext.clearSectionData({
						...DataContext.data.sections[i],
						...data
					})
					return
				}
				const containerType = DataContext.containerTypes.filter((u) => u == e.type)
				if (containerType.length > 0) {
					if (Array.isArray(e.data.sections)) {
						e.data.sections.map((ee, ii) => {
							if (ee && ee.id == id) {
								DataContext.data.sections[i].data.sections[ii] = DataContext.clearSectionData({
									...DataContext.data.sections[i].data.sections[ii],
									...data
								}) 
							}
						})
					}
				}
			})
		}
	}
	static clearSectionData(data)
	{ 
		DataContext.addHistory()
		const dataKeys =  Object.keys(data)
		for( var i = 0; i < dataKeys.length; i++ )
		{
			const field = data[ dataKeys[i] ] 
			if( typeof field === "function" )
			{
				delete data[ dataKeys[i] ]
			}
			
			if( dataKeys[i] === "data")
			{
				const fieldKeys = Object.keys( field ) 
				for( var ii = 0; ii < fieldKeys.length; ii++ )
				{
					if( typeof field[ fieldKeys[ii] ] === "function" )
					{ 
						delete data[ dataKeys[i] ][ fieldKeys[ii] ]
						//field[ fieldKeys[ii] ] = null 
					}
				}		
				delete field.user;
				delete field.section_id
				delete field.tab
				delete field.navbarTabId
				delete field.myX
				delete field.section_height
				delete field.section_width
				delete field.isDay
				delete field.isDialogOpen
				delete field.isDummy
				delete field.isParamsVisibled
				delete field.is_admin
				delete field.is_change_type_enbl
				delete field.is_contrast_muar
				delete field.is_edit
				delete field.isRemPopover
				delete field.isDialogOpen
				delete field.isAddPopover
				delete field.matrixSrc
				delete field.children
				delete field.floatSelect
				// console.log( field )
			}
		}
		delete data.user		
		delete data.children		
		return data
	}

	static hideSection(id, is_hide) {
		DataContext.addHistory()
		if (Array.isArray(DataContext.data.sections)) {
			DataContext.data.sections.forEach((e, i) => {
				if (e.id == id) {
					DataContext.data.sections[i].is_hidden = is_hide
				}
				const containerType = DataContext.containerTypes.filter((u) => u == e.type)
				if (containerType.length > 0) {
					if (Array.isArray(e.data.sections)) {
						e.data.sections.map((ee, ii) => {
							if (ee && ee.id == id) {
								DataContext.data.sections[i][containerType[0]].sections[ii].is_hidden = is_hide
							}
						})
					}
				}
			})
		}
	}

	static deleteSectionId( id ) {

		if (Array.isArray(DataContext.data.sections)) {
			DataContext.addHistory()
			DataContext.data.sections.forEach((e, i) => {
				if (e.id == id) { 
					DataContext.data.sections.splice( i, 1 )
				}
				const containerType = DataContext.containerTypes.filter((u) => u == e.type)
				if (containerType.length > 0) {
					if (Array.isArray(e.data.sections)) {
						e.data.sections.map((ee, ii) => {
							if (ee && ee.id == id) { 
								DataContext.data.sections[i][containerType[0]].sections.splice( ii, 1 )
							}
						})
					}
				}
			})
		}
	}

	static getFloatId(floatId) {
		if (Array.isArray(DataContext.data.sections)) {
			let _float
			DataContext.data.sections.forEach((e, i) => {
				if (Array.isArray(e.floats)) {
					e.floats.forEach((ee) => {
						if (ee && ee.float_id == floatId) {
							_float = ee
						}
						const containerType = DataContext.containerTypes.filter((u) => u == e.type)
						if (containerType.length > 0) {
							if (Array.isArray(e.data.sections)) {
								e.data.sections.map((ee, ii) => {
									if (Array.isArray(ee.floats)) {
										ee.floats.forEach((eee) => {
											if (eee.float_id == floatId) {
												_float = eee
											}
										})
									}
								})
							} else {
								return {}
							}
						}
					})
				} else {
					return {}
				}
			})
			return _float || {}
		}

		return {}
	}

	static updateFloat(floatObject, float_id, section_id) {
		DataContext.addHistory()		
		if (Array.isArray(DataContext.data.sections)) {
			// console.log(floatObject)
			const height = floatObject.position.lg.h.value
			const floatObj = {
				...floatObject,
					data: {
					...floatObject.data, 
					height
				}
			}
			DataContext.data.sections.forEach((e, i) => {
				if (e.menu.id == section_id) {
					//console.log(section_id)
					if (!Array.isArray(e.floats)) DataContext.data.sections[i].floats = []
					DataContext.data.sections[i].floats.push(floatObj)
				} else if (Array.isArray(e.floats)) {
					e.floats.forEach((ee, ii) => {
						if (ee && ee.float_id == float_id) {
							// _float = ee;
							DataContext.data.sections[i].floats[ii] = floatObj
						}
						const containerType = DataContext.containerTypes.filter((u) => u == e.type)
						if (containerType.length > 0) {
							if (Array.isArray(e.data.sections)) {
								e.data.sections.map((ee, ii) => {
									if (ee && ee.menu.id == section_id) {
										if (!Array.isArray(ee.floats)) DataContext.data.sections[i][containerType[0].type].sections[ii].floats = []
										DataContext.data.sections[i][containerType[0].type].sections[ii].floats.push(floatObj)
									} else if (Array.isArray(ee.floats)) {
										ee.floats.forEach((eee, iii) => {
											if (eee.float_id == float_id) {
												// _float = eee;
												DataContext.data.sections[i].data.sections[ii].floats[iii] = floatObj
											}
										})
									}
								})
							}
						}
					})
				}
			}) 
		} else {
			return {}
		}
		
	}

	static getFloatBySection( float_id, section_id ) 
	{
		if (Array.isArray(DataContext.data.sections)) 
		{
			let float = {}
			DataContext.data.sections.forEach((e, i) => 
			{
				if (e.menu.id == section_id && Array.isArray(e.floats))
				{
					e.floats.forEach((ee, ii) => 
					{
						if (ee && ee.float_id == float_id) 
						{
							float = ee
						}
					})
				}
			})
			return float
		} else {
			return {}
		}
	}

	static deleteFloatId(floatId) {
		if (Array.isArray(DataContext.data.sections)) {
			DataContext.addHistory()
			DataContext.data.sections.forEach((e, i) => {
				if (Array.isArray(e.floats)) {
					e.floats.forEach((ee, ii) => {
						if (ee && ee.float_id == floatId) {  
							DataContext.data.sections[i].floats.splice(ii, 1)
						}
						const containerType = DataContext.containerTypes.filter((u) => u == e.type)
						if (containerType.length > 0) {
							if (Array.isArray(e.data.sections)) {
								e.data.sections.map((ee, ii) => {
									if (Array.isArray(ee.floats)) {
										ee.floats.forEach((eee, iii) => {
											if (eee.float_id == floatId) {
												DataContext.data.sections[i].data.sections[ii].floats.splice(iii, 1)
											}
										})
									}
								})
							}
						}
					})
				}
			})
		}
	}
}
