import React from "react"
import { compose } from "recompose"
import { NavLink } from "react-router-dom"
import { withRouter } from "react-router"
import { footer } from "react-pe-layouts"
import { __ } from "react-pe-utilities"

class LayoutFooterMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const footer_menu = footer()
    const menus = footer_menu.map((e, i) => (
      <div className="col-12 py-2 d-flex justify-content-center" key={i}>
        <NavLink
          to={`/${e.route}`}
        >
          {__(e.title)}
        </NavLink>
      </div>
    ))

    return (
      <>
        {menus}
      </>
    )
  }
}

export default compose(
  withRouter,
)(LayoutFooterMenu)
