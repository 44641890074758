import matrix from "./matrix"

const getVisibleValue = (value, type) =>
{
    if(!value)
        return "title"
    if(!type)
        return "title"
    const visibled_value = matrix()[type]?.visible_value?.value || "title" 
    const v = visibled_value?.split(".")
    // console.log( v, type )
    switch(v.length)
    {
        case 1:
            return  matrix()[type] ? matrix()[type][v[0]] : "title"
        case 2:
            return  matrix()[type] ? matrix()[type][v[0]][v[1]] : "title"
        case 3:
            return  matrix()[type] ? matrix()[type][v[0]][v[1]][v[2]] : "title"
        case 4:
            return  matrix()[type] ? matrix()[type][v[0]][v[1]][v[2]][v[4]] : "title"
        case 0:
        default:
            return "title"
    }
}
export default getVisibleValue