import React, {Fragment,Component} from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { __ } from "react-pe-utilities/dist";

export default class ShortExperts extends Component
{
	
	render()
	{	
		//console.log( this.props.data );	 
		return <div className="p-4"> 			
			<a className="btn btn-link " href={this.props.data.short_expert_url} target="_blank">
				{__("Download CSV")}
			</a>
			<ReactHtmlTableToExcel
				id="test-table-xls-button"
				className="btn btn-link ml-4"
				table="table-to-xls"
				filename="experts_results"
				sheet="experts_results"
				buttonText={__("Download as XLS")}
			/>
			<div dangerouslySetInnerHTML={{ __html: this.props.data.short_expert_table }} />
		</div>
	}
}