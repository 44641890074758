import React, { useEffect } from "react"
import Style from "style-it"
import { getStyle } from "../Section"
import $ from "jquery"

const ElegantTestanomial = props =>
{
    const getWidth = () =>
    {
        // console.log( document.body.clientWidth )
        if( document.body.clientWidth <= 720 ) return 100
        if( Array.isArray( props.composition?.inner_proportia) )
        {
            return props.composition.inner_proportia[ props.i ]
        }
        else
        {
            return 100 / props.composition.columns
        }
    }
    const updateWindowDimensions = evt => {
        setProz( getWidth() )
    }
    const [width, setWidth] = React.useState( 500 )
    const [proz, setProz] = React.useState( getWidth() )
    useEffect(() => {
        setWidth( $( `[data-id="${ props.sectionID }"] .landing-testanomials .landing-testanomial-elegant-cont` )
            .eq( props.i )
                .width() 
        )        
        window.addEventListener("resize", updateWindowDimensions)
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []) 
    const { class_name, style, line_color } = props
    const {
        text, name, avatar, description, palette, composition, i, line_width
    } = props

    
    let col = 11;
    switch( composition.columns)
    {
      case 2:
        col = 6
        break
      case 3:
        col = 4
        break
      case 4:
        col = 3
        break
      case 5:
        col = 5
      case 6:
        col = 2
        break
      case 12:
        col = 1
        break
      case 1:
      default:
        col = 12
        break;
    }

    return Style.it(
        `
        .landing-testanomial-elegant-cont
        {
            padding:15px;  
            width:${proz}%;
            overflow: hidden;
        }
        .landing-testanomial-elegant
        {
            border-top: ${ line_width }px solid ${ line_color ? line_color : palette.main_text_color};
            padding-top:15px;
            padding-bottom:0;

        }
        .text
        {            
            display: flex;
            align-items: center;
            position: relative;
        }
        .text::before
        {
            content: "";
            position: absolute;
            bottom: -18px;
            height: 10px;
            background: #83abc9;
            width: calc(100% - 280px);
            margin-left: 280px;
        }
        .avatar
        {
            width: 80px;
            height:80px;
            border-radius:120px;
            margin-right:20px;
        }
        `,
        <div 
            className={`landing-testanomial-elegant-cont col-md-${col} ${class_name} col-12`}
            style={{ 
                ...getStyle( style ), 
                minWidth: proz + "%"       
            }}
        >
            <div className="landing-testanomial-elegant">
               <div
                    className="text"
                    style={{ color: palette.main_text_color }}
                >
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                <svg 
                    version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" 
                    y="0px"
                    width={ width }
                    height="60"
                    viewBox={`0 0 ${width} 35`} 
                    xmlSpace="no-preserve"
                    style={{ marginBottom:-10 }}
                >
                    <polyline 
                        fill="#00000000" 
                        stroke={  line_color ? line_color : palette.main_text_color }
                        strokeWidth={ line_width }
                        points={ `0, 0 80, 0 80, 25 105, 0 ${width}, 0 `}
                    />
                </svg>
                <div className="d-flex">
                    {
                        !avatar ? null : <div className="avatar" style={{ backgroundImage: `url(${avatar})` }} />
                    }
                    {
                        name && description
                            ?
                            <div
                                className="ltest-cont"
                                style={{
                                    color: palette.main_text_color
                                }}
                            >
                                <div className="name">
                                    {name}
                                </div>
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: description }}
                                />
                            </div>
                            :
                            null
                    }
                    
                </div>     
            </div>            
        </div>
    )
}
export default ElegantTestanomial