import React from "react"
import { getStyle } from "../Section"
import Style from "style-it"
import { getFontNameByID } from "../data/PalettePresets"
import { ReactComponent as QuoteStart } from '../../../assets/img/quote-start.svg';
import { ReactComponent as QuoteFinish } from '../../../assets/img/quote-finish.svg'; 
import { ReactComponent as Quote1 } from '../../../assets/img/quotes1.svg'; 
import { ReactComponent as Quote2 } from '../../../assets/img/quotes2.svg'; 
import { ReactComponent as CommaElleganceStart } from '../../../assets/img/comma-ellegance_finish.svg'; 
import { ReactComponent as CommaElleganceFinish } from '../../../assets/img/comma-ellegance_start.svg'; 
import { LayoutIcon } from "react-pe-useful/dist";

import { ReactComponent as CommaBoldStart } from '../../../assets/img/comma-bold_finish.svg'; 
import { ReactComponent as CommaBoldFinish } from '../../../assets/img/comma-bold_start.svg';  
import { ReactComponent as CommaExtravaganceStart } from '../../../assets/img/comma-extravagance_start.svg'; 
import { ReactComponent as CommaExtravaganceFinish } from '../../../assets/img/comma-extravagance_finish.svg';
import { ReactComponent as CommaStreightStart } from '../../../assets/img/comma_streight_finish.svg'; 
import { ReactComponent as CommaStreightFinish } from '../../../assets/img/comma_streight_start.svg'; 
import { ReactComponent as CommaStreight2Start } from '../../../assets/img/comma-2_finish.svg'; 
import { ReactComponent as CommaStreight2Finish } from '../../../assets/img/comma-2_start.svg'; 
import { ReactComponent as CommaStreight3Start } from '../../../assets/img/comma-3_finish.svg'; 
import { ReactComponent as CommaStreight3Finish } from '../../../assets/img/comma-3_start.svg';

import { ReactComponent as CommaElleganceContourStart } from '../../../assets/img/comma-ellegance-contour-start.svg'; 
import { ReactComponent as CommaElleganceContourFinish } from '../../../assets/img/comma-ellegance-contour-finish.svg'; 
import { ReactComponent as CommaEExtraElleganceStart } from '../../../assets/img/comma-extra-ellegance-start.svg'; 
import { ReactComponent as CommaEExtraElleganceFinish } from '../../../assets/img/comma-extra-ellegance-finish.svg'; 
import { ReactComponent as CommaExtrudeBreakStart } from '../../../assets/img/comma-extrude-break-start.svg'; 
import { ReactComponent as CommaExtrudeBreakFinish } from '../../../assets/img/comma-extrude-break-finish.svg'; 
import { ReactComponent as CommaPeapleStart } from '../../../assets/img/comma-peaple-finish.svg'; 
import { ReactComponent as CommaPeapleFinish } from '../../../assets/img/comma-peaple-finish.svg'; 
import { ReactComponent as CommaAvantguardStart } from '../../../assets/img/comma-avanguard-start.svg'; 
import { ReactComponent as CommaAvantguardFinish } from '../../../assets/img/comma-avanguard-finish.svg';

import { ReactComponent as Comma10Start } from '../../../assets/img/comma_10_start.svg'; 
import { ReactComponent as Comma10Finish } from '../../../assets/img/comma_10_finish.svg'; 
import { ReactComponent as Comma11Start } from '../../../assets/img/comma_11_start.svg'; 
import { ReactComponent as Comma11Finish } from '../../../assets/img/comma_11_finish.svg'; 
import { ReactComponent as Comma12Start } from '../../../assets/img/comma_12_start.svg'; 
import { ReactComponent as Comma12Finish } from '../../../assets/img/comma_12_finish.svg'; 
import { ReactComponent as Comma13Start } from '../../../assets/img/comma_13_start.svg'; 
import { ReactComponent as Comma13Finish } from '../../../assets/img/comma_13_finish.svg'; 
import { ReactComponent as Comma14Start } from '../../../assets/img/comma_14_start.svg'; 
import { ReactComponent as Comma14Finish } from '../../../assets/img/comma_14_finish.svg'; 
import { ReactComponent as Comma15Start } from '../../../assets/img/comma_15_start.svg'; 
import { ReactComponent as Comma15Finish } from '../../../assets/img/comma_15_finish.svg'; 
import { ReactComponent as Comma16Start } from '../../../assets/img/comma_16_start.svg'; 
import { ReactComponent as Comma16Finish } from '../../../assets/img/comma_16_finish.svg'; 
import { ReactComponent as Comma17Start } from '../../../assets/img/comma_17_start.svg'; 
import { ReactComponent as Comma17Finish } from '../../../assets/img/comma_17_finish.svg'; 
import { ReactComponent as Comma18Start } from '../../../assets/img/comma_18_start.svg'; 
import { ReactComponent as Comma18Finish } from '../../../assets/img/comma_18_finish.svg'; 
import { ReactComponent as Comma19Start } from '../../../assets/img/comma_19_start.svg'; 
import { ReactComponent as Comma19Finish } from '../../../assets/img/comma_19_finish.svg'; 
import { ReactComponent as Comma20Start } from '../../../assets/img/comma_20_start.svg'; 
import { ReactComponent as Comma20Finish } from '../../../assets/img/comma_20_finish.svg'; 
import { ReactComponent as Comma21Start } from '../../../assets/img/comma_21_start.svg'; 
import { ReactComponent as Comma21Finish } from '../../../assets/img/comma_21_finish.svg'; 
import { ReactComponent as Comma22Start } from '../../../assets/img/comma_22_start.svg'; 
import { ReactComponent as Comma22Finish } from '../../../assets/img/comma_22_finish.svg'; 
import { ReactComponent as Comma23Start } from '../../../assets/img/comma_23_start.svg'; 
import { ReactComponent as Comma23Finish } from '../../../assets/img/comma_23_finish.svg';  
import { ReactComponent as Comma24Start } from '../../../assets/img/comma_24_start.svg'; 
import { ReactComponent as Comma24Finish } from '../../../assets/img/comma_24_finish.svg';  
import { ReactComponent as Comma25Start } from '../../../assets/img/comma_25_start.svg'; 
import { ReactComponent as Comma25Finish } from '../../../assets/img/comma_25_finish.svg';  
import { ReactComponent as Comma26Start } from '../../../assets/img/comma_26_start.svg'; 
import { ReactComponent as Comma26Finish } from '../../../assets/img/comma_26_finish.svg'; 
import { ReactComponent as Comma27Start } from '../../../assets/img/comma_27_start.svg'; 
import { ReactComponent as Comma27Finish } from '../../../assets/img/comma_27_finish.svg'; 
import { ReactComponent as Comma28Start } from '../../../assets/img/comma_28_start.svg'; 
import { ReactComponent as Comma28Finish } from '../../../assets/img/comma_28_finish.svg'; 
import { ReactComponent as Comma29Start } from '../../../assets/img/comma_29_start.svg'; 
import { ReactComponent as Comma29Finish } from '../../../assets/img/comma_29_finish.svg'; 
import { ReactComponent as Comma30Start } from '../../../assets/img/comma_30_start.svg'; 
import { ReactComponent as Comma30Finish } from '../../../assets/img/comma_30_finish.svg'; 
import { ReactComponent as Comma31Start } from '../../../assets/img/comma_31_start.svg'; 
import { ReactComponent as Comma31Finish } from '../../../assets/img/comma_31_finish.svg'; 
import { ReactComponent as Comma32Start } from '../../../assets/img/comma_32_start.svg'; 
import { ReactComponent as Comma32Finish } from '../../../assets/img/comma_32_finish.svg'; 
import { ReactComponent as Comma33Start } from '../../../assets/img/comma_33_start.svg'; 
import { ReactComponent as Comma33Finish } from '../../../assets/img/comma_33_finish.svg';  
import { ReactComponent as Comma34Start } from '../../../assets/img/comma_34_start.svg'; 
import { ReactComponent as Comma34Finish } from '../../../assets/img/comma_34_finish.svg';  


const GridQuote = props =>
{
    const { palette, revertColor, data } = props
    const {
        class_name, 
        style, 
        quotes_size, quote_design,
        text, text_order, text_css,
        name, name_order, name_css,
        description, description_order, description_css,
        thumbnail, thumbnail_order, thumbnail_css,
        is_top_quotation_mark,
        is_top_quotation_icon,
        top_icon,
        is_bottom_quotation_mark,
        is_bottom_quotation_icon,
        bottom_icon,
        quotation_mark_position,
        quotes_offset,
        quote_line_design,
        quote_bg_style
    } = data
    const media = thumbnail
        ?
        <div 
        className="landing-quote-thumbnail" 
        style={{
            order: thumbnail_order,
            ...getStyle(thumbnail_css)
        }}
        />
        :
        null
    const getIcon = icon =>
    {
        return icon
            ?            
            <LayoutIcon
                src={ icon}
                className=" landing-header-icon "
            />
            :
            null
    }
    const getStartQuote = () =>
    {
        if(!is_top_quotation_mark) return <div className="quotes in" />
        if(is_top_quotation_icon)
        {
            return <div className="quotes in" style={{ }}>
            {
                getIcon( top_icon )
            }
            </div>
        }
        switch(quote_design)
        {
            case "elegance_hooks":
                return <div className="quotes in" style={{ transform: "rotate(180deg) translateY(23%)" }}>
                    <Quote1 width={quotes_size } height={quotes_size * 2} />
                </div>
            case "conservative_hooks":
                return <div className="quotes in" style={{ transform: "rotate(180deg) translateY(23%)" }}>
                    <Quote2 width={quotes_size / 2} height={quotes_size } />
                </div>
            case "ellegance_comma":
                return <div className="quotes in" >
                    <CommaElleganceStart width={quotes_size } height={quotes_size } />
                </div>
            case "comma-ellegance-bold":
                return <div className="quotes in" >
                    <CommaBoldStart width={quotes_size } height={quotes_size } />
                </div>
            case "extravagance_comma":
                return <div className="quotes in" >
                    <CommaExtravaganceStart width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma":
                return <div className="quotes in" >
                    <CommaStreightStart width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma_2":
                return <div className="quotes in" >
                    <CommaStreight2Start width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma_3":
                return <div className="quotes in" >
                    <CommaStreight3Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma-ellegance-contour":
                return <div className="quotes in" >
                    <CommaElleganceContourStart width={quotes_size } height={quotes_size } />
                </div>
            case "comma-extra-ellegance":
                return <div className="quotes in" >
                    <CommaEExtraElleganceStart width={quotes_size } height={quotes_size } />
                </div>
            case "comma-extrude-break":
                return <div className="quotes in" >
                    <CommaExtrudeBreakStart width={quotes_size } height={quotes_size } />
                </div>
            case "comma-peaple":
                return <div className="quotes in" >
                    <CommaPeapleStart width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma_avanguard":
                return <div className="quotes in" >
                    <CommaAvantguardStart width={quotes_size } height={quotes_size } />
                </div>
            case "comma_10":
                return <div className="quotes in" >
                    <Comma10Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_11":
                return <div className="quotes in" >
                    <Comma11Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_12":
                return <div className="quotes in" >
                    <Comma12Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_13":
                return <div className="quotes in" >
                    <Comma13Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_14":
                return <div className="quotes in" >
                    <Comma14Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_15":
                return <div className="quotes in" >
                    <Comma15Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_16":
                return <div className="quotes in" >
                    <Comma16Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_17":
                return <div className="quotes in" >
                    <Comma17Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_18":
                return <div className="quotes in" >
                    <Comma18Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_19":
                return <div className="quotes in" >
                    <Comma19Start width={quotes_size } height={quotes_size } />
                </div>
            case "comma_20":
                return <div className="quotes in" >
                    <Comma20Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_21":
                return <div className="quotes in" >
                    <Comma21Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_22":
                return <div className="quotes in" >
                    <Comma22Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_23":
                return <div className="quotes in" >
                    <Comma23Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_24":
                return <div className="quotes in" >
                    <Comma24Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_25":
                return <div className="quotes in" >
                    <Comma25Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_26":
                return <div className="quotes in" >
                    <Comma26Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_27":
                return <div className="quotes in" >
                    <Comma27Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_28":
                return <div className="quotes in" >
                    <Comma28Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_29":
                return <div className="quotes in" >
                    <Comma29Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_30":
                return <div className="quotes in" >
                    <Comma30Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_31":
                return <div className="quotes in" >
                    <Comma31Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_32":
                return <div className="quotes in" >
                    <Comma32Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_33":
                return <div className="quotes in" >
                    <Comma33Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma_34":
                return <div className="quotes in" >
                    <Comma34Start width={quotes_size } height={quotes_size } />
                </div> 
            case "comma":
            default:
                return <div className="quotes in" >
                    <QuoteStart width={quotes_size} height={quotes_size}/>
                </div>
        }        
    }
    const getFinishQuote = () =>
    {
        if(!is_bottom_quotation_mark) return <div className="quotes out" />
        if(is_bottom_quotation_icon)
        {
            return <div className="quotes in" style={{ }}>
            {
                getIcon( bottom_icon )
            }
            </div>
        }
        switch(quote_design)
        {
            case "elegance_hooks":
                return <div className="quotes out" >
                    <Quote1 width={quotes_size } height={quotes_size * 2} />
                </div>
            case "conservative_hooks":
                return <div className="quotes out" >
                    <Quote2 width={quotes_size /2 } height={quotes_size } />
                </div>
            case "ellegance_comma":
                return <div className="quotes out" >
                    <CommaElleganceFinish width={ quotes_size } height={ quotes_size } />
                </div>
            case "comma-ellegance-bold":
                return <div className="quotes out" >
                    <CommaBoldFinish width={quotes_size } height={quotes_size } />
                </div>
            case "extravagance_comma":
                return <div className="quotes out" >
                    <CommaExtravaganceFinish width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma":
                return <div className="quotes out" >
                    <CommaStreightFinish width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma_2":
                return <div className="quotes out" >
                    <CommaStreight2Finish width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma_3":
                return <div className="quotes out" >
                    <CommaStreight3Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma-ellegance-contour":
                return <div className="quotes out" >
                    <CommaElleganceContourFinish width={quotes_size } height={quotes_size } />
                </div>
            case "comma-extra-ellegance":
                return <div className="quotes out" >
                    <CommaEExtraElleganceFinish width={quotes_size } height={quotes_size } />
                </div> 
            case "comma-extrude-break":
                return <div className="quotes out" >
                    <CommaExtrudeBreakFinish width={quotes_size } height={quotes_size } />
                </div>
            case "comma-peaple":
                return <div className="quotes out" >
                    <CommaPeapleFinish width={quotes_size } height={quotes_size } />
                </div>
            case "streight_comma_avanguard":
                return <div className="quotes out" >
                    <CommaAvantguardFinish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_10":
                return <div className="quotes out" >
                    <Comma10Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_11":
                return <div className="quotes out" >
                    <Comma11Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_12":
                return <div className="quotes out" >
                    <Comma12Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_13":
                return <div className="quotes out" >
                    <Comma13Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_14":
                return <div className="quotes out" >
                    <Comma14Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_15":
                return <div className="quotes out" >
                    <Comma15Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_16":
                return <div className="quotes out" >
                    <Comma16Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_17":
                return <div className="quotes out" >
                    <Comma17Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_18":
                return <div className="quotes out" >
                    <Comma18Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_19":
                return <div className="quotes out" >
                    <Comma19Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_20":
                return <div className="quotes out" >
                    <Comma20Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_21":
                return <div className="quotes out" >
                    <Comma21Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_22":
                return <div className="quotes out" >
                    <Comma22Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_23":
                return <div className="quotes out" >
                    <Comma23Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_24":
                return <div className="quotes out" >
                    <Comma24Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_25":
                return <div className="quotes out" >
                    <Comma25Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_26":
                return <div className="quotes out" >
                    <Comma26Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_27":
                return <div className="quotes out" >
                    <Comma27Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_28":
                return <div className="quotes out" >
                    <Comma28Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_29":
                return <div className="quotes out" >
                    <Comma29Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_30":
                return <div className="quotes out" >
                    <Comma30Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_31":
                return <div className="quotes out" >
                    <Comma31Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_32":
                return <div className="quotes out" >
                    <Comma32Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_33":
                return <div className="quotes out" >
                    <Comma33Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma_34":
                return <div className="quotes out" >
                    <Comma34Finish width={quotes_size } height={quotes_size } />
                </div>
            case "comma":
            default:
                return <div className="quotes out" >
                    <QuoteFinish  width={quotes_size} height={quotes_size}/>
                </div>
        }        
    }
    let start_quote_style = `margin-top: ${quotes_offset}%; `, 
        finish_quote_style = `margin-bottom: ${quotes_offset}%; `, 
        title_style = "", 
        name_style = "", 
        description_style = "",
        core_style = ""
    
    switch( quotation_mark_position )
    {
        case "vertical":
            start_quote_style += `
            
            `
            break
        case "diagonal":
            core_style += ` grid-template-rows: 3fr 2fr 1fr 1fr; grid-template-columns: 1fr 3fr 1fr; `
            start_quote_style += `grid-row-start: 1; grid-column-start: 1; grid-row-end: 5; grid-column-end: 2; align-self: flex-start; `
            finish_quote_style += ` grid-row-start: 1; grid-column-start: 3; grid-row-end: 5; grid-column-end: 4; align-self: flex-end;`
            break
        case "horizontal":
        default:
            core_style += ` grid-template-rows: 3fr 2fr 1fr 1fr; grid-template-columns: 1fr 3fr 1fr; `
            start_quote_style += ` grid-row-start: 1; grid-column-start: 1; grid-row-end: 5; grid-column-end: 2; `
            finish_quote_style += ` grid-row-start: 1; grid-column-start: 3; grid-row-end: 5; grid-column-end: 4; `
            break
    }
    
    return Style.it( `
        .landing-quote-thumbnail 
        {
            background-image:url( ${ thumbnail } );
        } 
        .quotes
        {
            padding:15px;
            position:relative;
            width:100%;
        }
        .quotes svg path, 
        .quotes svg rect 
        {
            fill: ${revertColor};
        }
        .landing-header-icon
        {
            font-size:50px;
        }
        .quotes:before
        {
            content: "";
            position: absolute;
            top: calc(50% - ${quotes_size * .1}px);
            left: 0; 
            border-top: ${quotes_size * .2}px ${quote_line_design}  ${quotation_mark_position !== "horizontal" ? revertColor : "transparent"};
            width: calc(50% - ${quotes_size * 1.0}px); 
        }
        .quotes:after
        {
            content: "";
            position: absolute;
            top: calc(50% - ${quotes_size * .1}px);
            right: 0; 
            width: calc(50% - ${quotes_size * 1.0}px);
            border-top: ${quotes_size * .2}px ${quote_line_design}  ${quotation_mark_position !== "horizontal" ? revertColor : "transparent"}; 
        }
        .landing-quote 
        {
            display: grid; 
            gap: 0px;
            height: 100%;
            width: 100%;
            align-items: center;
            ${core_style}
        }
        .landing-quote-before
        {
            content: "";

        }
        .landing-quote > .text 
        {
            font-family: ${getFontNameByID( palette.card.title.fontFamilyID )};
            font-size: ${palette.card.title.fontSize};
            order: ${text_order};
            display:flex;
            flex-direction: ${
              quotation_mark_position === "horizontal" 
                ?
                `row`
                :
                `column`
            };   
            justify-content: center;         
            align-items: center;         
        }        
        .landing-quote > .text > .quotes > svg path
        {
            
        }
        .quotes.in
        {
            ${start_quote_style}
        }
        .quotes.out
        {
            order: 5;
            ${finish_quote_style}
        }
        `,
        <div 
            className={`landing-quote ellegance  landing-section-core ${class_name}`}
            style={{
                color: palette.main_text_color,
                ...style
            }}
        >
            <div className="landing-quote-before" style={ getStyle( quote_bg_style ) } >

            </div>
            {getStartQuote()}
            { media }
            <div 
                className="text" 
                style={{ 
                
                    ...getStyle( text_css )
                }}          
            >
                <span dangerouslySetInnerHTML={{ __html: text }} />                
            </div> 
            
            <div 
                className="name"
                style={{
                    order: name_order,
                    ...getStyle( name_css )
                }}
            >
                <span className="name">
                    { name }
                </span>
            </div>
            <div 
                className="description"
                style={{
                    order: description_order,
                    ...getStyle( description_css )
                }}
            >
                <span>
                    { description }
                </span>
            </div>
            {getFinishQuote()}             
        </div>
    )
}

export default GridQuote