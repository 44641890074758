const CATEGORY_RATING_TYPE = () => {
    return [
        {
            _id : "independent_grade",
            title: "Independent grade",
            commentary: "The expert assigns a score by pressing the button with the desired numerical value"
        },
        {
            _id : "distribution_procentage",
            title: "Distribution procentage",
            commentary: "The Expert assesses by manipulating the sliders, the sum of the values of which does not exceed 100"
        },
        {
            _id : "procentage",
            title: "Procentage",
            commentary: "Expert uses a slider to rate in percentages"
        }
    ]
} 
export default CATEGORY_RATING_TYPE