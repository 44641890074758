import React from "react"
//import Feed from "../../../../layouts/BasicState/Feed"
import { Feed } from "react-pe-basic-view"
import PostFeedCard from "./postFeed/PostFeedCard"
import SectionContent from "./SectionContent"
import VerticalCard from "./postFeed/VerticalCard"
import CadrCard from "./postFeed/CadrCard"

class PostFeed extends SectionContent {
  getState() {
    this.car = React.createRef()
    return { dwidth: 1200 }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({
      dwidth: document.body.clientWidth,
      dheight: document.body.clientHeight,
    })
  }
  getDesignTypes = () => {
    return {
      hcard: {
        component: PostFeedCard
      },
      vcard: {
        component: VerticalCard
      },
      cadr: {
        component: CadrCard
      }
    }
  }
  renderContent(style) {
    const { palette } = this.props
    const {
      class_name, data_type, offset, count, paging, is_show_paginamtion, design_type, height
    } = this.props.data
    const { columns } = this.props.composition
    const Component = this.getDesignTypes()[design_type].component
    return (
      <div className={`landing-post-feed landing-section-core  ${class_name}`} style={style}>
        <Feed
          component={Component}
          data_type={data_type}
          is_hide_pagi={!is_show_paginamtion}
          offset={offset || 0}
          count={count}
          height={height}
          paging={paging || ""}
          class_name={` d-flex flex-wrap justify-content-around `}
          params={{
            style: { width: this.state.dwidth > 760 ? `${100 / columns}%` : "100%" },
            palette: palette
          }}
        />
      </div>
    )
  }

  is() {
    const { data_type } = this.state.data
    return data_type
  }
}
export default PostFeed
