import React, { useEffect, useState } from "react"
import FieldInput from "react-pe-scalars/dist"
import { LayoutIcon } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities/dist"
import ScreenSizeList from "../utilities/ScreenSizeList"

const getScreenSize = (dwidth) => 
{
  let p = "mc"
  if (dwidth > 940) 
  {
    p = "lg"
  } 
  else if (dwidth > 760) 
  {
    p = "xl"
  } 
  else if (dwidth > 560) 
  {
    p = "sm"
  }
  return p
}

const CanvasSize = props =>
{
    const[current, onCurrent] = useState( props.current )
    const[value, setValue] = useState( 
      () =>
      {
        if(props.value && props.value.mc )
        {
          return props.value
        }
        else
        {
          let pars = {}
          ScreenSizeList().forEach(e => {
            pars[e.id] = null
          }) 
          console.log(pars)
          return pars
        }
      }
      
    )
    const[currentValue, setCurrentValue] = useState( 0 )
    const[dwidth, onDwidth] = useState( 0 )
    const[dheight, onDheight] = useState( 0 )
    const updateWindowDimensions = () => { 
        onDwidth(document.body.clientWidth) 
        onDheight(document.body.clientHeight)
        onCurrent( getScreenSize(document.body.clientWidth) )
    }
    useEffect(() => {
        window.addEventListener("resize", updateWindowDimensions)
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [])
    const onSwitch = (evt) => {
        const type = evt.currentTarget.getAttribute("type")
        onCurrent( type )
      }
    const on = (evt, size_id) =>
    {
      const val = evt.currentTarget.value
      let v = {...value}
      console.log(val, size_id)
      v[size_id] = val 
      setValue( v )
      props.on(v)
    }
    const onBlur = evt =>
    {
      const val = evt.currentTarget.value
      let v = {...value}
      ScreenSizeList().map( e => {
        if(!v[e.id])
        {
          v[e.id] = val
        }
      })
      setValue( v )
      props.on(v)
    }
    return <div className="w-100">
      {
        ScreenSizeList().map((size => {
          const cl = size.id == current ? "active " : " "
          return <div
            key={size.id}
            type={size.id}
            className={`l-icon ${cl} w-25 m-0`}
            // onClick={onSwitch}
          >
            <LayoutIcon
              src={`${size.icon} fa-3x pt-3`}
              className="layout-icon mb-1"
            />
            <div className="">
              { __(size.title) }
            </div>
            <div className="d-flex align-items-end">
              <input 
                type="number"               
                className="form-control input dark" 
                value={ value[ size.id ] || 0 }
                onChange={ evt => on(evt, size.id) }
                onBlur={onBlur}
              />
              <span className="p-2">px</span>
            </div>            
          </div>
        }))
      }
    </div>
}
export default CanvasSize