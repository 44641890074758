import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GridLayoutContainer from 'modules/pe-grid-layout-module/views/gridLayout'
import { GRID_HEIGHT_COEF, GRID_HEIGHT_FACTOR } from 'modules/pe-grid-layout-module/views/GridLayoutView' 
import _ from "lodash"; 
import { getContainerClass, getContainerClassWidth } from 'modules/pe-landing-module/views/LandingState/Section';
import ScreenSizeList, { getScreenSize, getScreenSizeWidth } from 'modules/pe-landing-module/widgets/utilities/ScreenSizeList';
import getNeighbourGrid from '../utils/getNeighbourGrid';
import { useEffect } from 'react';
import compareGrids from '../utils/compareGrids';



const GridLayout = props => 
{
    const [layouts, setLayouts] = useState( {} )
    const [screenSize, setScreenSize] = useState( getScreenSize( document.body.clientWidth ) )
    useEffect( () => {
        const gridData = getGridData( props.sections, props.grids )
        setLayouts( gridData)
    }, [])
    useEffect( () => {
        const gridData = getGridData( props.sections, props.grids )
        setLayouts( gridData)
        setScreenSize( getScreenSize( document.body.clientWidth ) )
    }, [ props.sections, props.grids, props.section_width ] )
    const onLayoutChange = ( dataArr, oldArr ) =>
    {
        //console.log( "dataArr:", dataArr )
        const currentGrid = getNeighbourGrid(layouts, screenSize)  
        if( !compareGrids(currentGrid, dataArr) )
        {
            let grid = !currentGrid ? getGridData( props.sections, props.grids, screenSize ) : currentGrid
            let _layouts = {...layouts} 
            _layouts[ screenSize ] = grid  
            setLayouts( _layouts ) 
            _layouts[screenSize] = dataArr.map((e, i) => {  
                return {
                    ...layouts[screenSize][i], 
                    data: {
                        ...layouts[screenSize][i].data,
                        height: e.h * GRID_HEIGHT_COEF() * GRID_HEIGHT_FACTOR()
                    },
                    i: e.i,
                    x: e.x,
                    y: e.y,
                    w: e.w,
                    h: e.h,
                    static: e.static,
                    moved:  e.moved
                }
            })  
            props.onUpdate( _layouts, "grids", {screenSize} )
        }  
    }
    
    const onToggleLayout = () =>
    {

    }
    const onDelete = id => 
    {
        
    }
    const onEdit = id => 
    {
        
    }
    const getContainer = () =>
    { 
        const cl = getContainerClass( props.composition?.is_blocked )
        return cl 
    }
    const getContainerWidth = () =>
    {
        const cl = getContainer() 
        return getContainerClassWidth(cl)
    }
    const dotsStyle = () => {
        let st = {}
        st.width = Math.min( getScreenSizeWidth( screenSize ), getContainerWidth() )
        return st
    }
    const onResizeClick = size =>
    {
        setScreenSize( size )
    }
    const farsh = props.is_edit
        ?
        <>
            <div 
                className={` ${ getContainer() } h-100 d-flex position-absolute ` }  
                style={{...dotsStyle(), left:"auto"}} 
            />
        </>
        :
        null
    
    return <>
        { farsh }        
        <div className={ getContainer() } style={dotsStyle()}>
            <GridLayoutContainer 
                layouts={ layouts }
                screenSize={screenSize}
                onLayoutChange={ onLayoutChange }
                onToggleLayout={ onToggleLayout } 
                onDelete={ onDelete }
                onEdit={ onEdit } 
                width={ dotsStyle().width }
                gap={props.gap}
                is_edit={props.is_edit}
            />
        </div>  
    </>
}
GridLayout.propTypes = {}
export default GridLayout

export const getGridData = (sections, grids, screenSize ) =>
{
    const elSections = Array.isArray(sections) ? sections : []
    let layouts = {}
    ScreenSizeList().map((size, i) =>
    {
        layouts[size.id] = []        
        const isArray = grids && Array.isArray(grids[size.id])
        const neighbour = getNeighbourGrid(grids, size.id) 
        
        elSections.forEach((section, i) =>
        { 
            var y = Math.ceil( Math.random() * 8 ) + 2;
            const _gridData = {
                x: isArray && grids[size.id][i] ? grids[size.id][i].x : getDemention(i, "x", neighbour, Math.floor((_.random(0, 5) * 2) % 12)),
                y: isArray && grids[size.id][i] ? grids[size.id][i].y : getDemention(i, "y", neighbour, Math.floor(i / 6) * y),
                w: isArray && grids[size.id][i] ? grids[size.id][i].w : getDemention(i, "w", neighbour, 2),
                h: isArray && grids[size.id][i] ? grids[size.id][i].h : getDemention(i, "h", neighbour, y),
                i: i.toString(),
                id: i,
                heightType: "strong", // strong, percents, fill, auto // not work
                static: false,
                data: {
                    ...section.data,
                    height: y * GRID_HEIGHT_COEF()
                },
                background: {},
                current_type: section.type // "html"
            } 
            layouts[size.id].push( _gridData )
        })
    })  
    return layouts
}
const getDemention = (i, demention, neighbour, random) =>
{
    if(!neighbour || !neighbour[i] || typeof neighbour[i][demention] === "undefined")  return random 
    return neighbour[i][demention]
}

