import React from "react"
import { useEffect } from "react"
import { __ } from "react-pe-utilities"
import { CARD_SEPARATOR } from "../Card"
import $ from "jquery" 
import {getColor} from "../data/getColor"

const CardFieldPersonalLinks = ({ e, i, cardFieldsData, style, class_name, palette }) => {
    const links = typeof e.field == "string" ? e.field.split(CARD_SEPARATOR()) : []
    const is_vertical = cardFieldsData.variant_param_1
        ?
        " d-block "
        :
        ""
    const hover_class = cardFieldsData.variant_param_2 || "none"
    useEffect(() => { 
         $(".landing-card-field.personal_links1,.landing-card-field.personal_links2,.landing-card-field.personal_links3").each((index, elem) =>
         {
            $(elem).height( $(elem).find(".personal_links").height() )
            $(elem).width( $(elem).find(".personal_links").width() )
         })
    }, [])
    //const 
    const btns = links.map((ee, ii) => {
        let fa = "fas fa-link"
        if (ee.indexOf("facebook.com") >= 0) {
            fa = "fab fa-facebook-f"
        } else if (ee.indexOf("vk.com") >= 0) {
            fa = "fab fa-vk"
        } else if (ee.indexOf("youtube.com") >= 0) {
            fa = "fab fa-youtube"
        } else if (ee.indexOf("vimeo.com") >= 0) {
            fa = "fab fa-vimeo-v"
        } else if (ee.indexOf("behance.net") >= 0) {
            fa = "fab fa-behance"
        } else if (ee.indexOf("blogger.com") >= 0) {
            fa = "fab fa-blogger"
        } else if (ee.indexOf("dropbox.com") >= 0) {
            fa = "fab fa-dropbox"
        } else if (ee.indexOf("deskpro.com") >= 0) {
            fa = "fab fa-deskpro"
        } else if (ee.indexOf("dribbble.com") >= 0) {
            fa = "fab fa-dribbble"
        } else if (ee.indexOf("linkedin-in.com") >= 0) {
            fa = "fab fa-linkedin-in"
        } else if (ee.indexOf("mixcloud.com") >= 0) {
            fa = "fab fa-mixcloud"
        } else if (ee.indexOf("pinterest.com") >= 0) {
            fa = "fab fa-pinterest-p"
        } else if (ee.indexOf("reddit.com") >= 0) {
            fa = "fab fa-reddit-alien"
        } else if (ee.indexOf("google.com") >= 0) {
            fa = "fab fa-google"
        } else if (ee.indexOf("npm.com") >= 0) {
            fa = "fab fa-npm"
        } else if (ee.indexOf("rocket.chat") >= 0) {
            fa = "fab fa-rocket"
        } else if (ee.indexOf("sellsy.com") >= 0) {
            fa = "fab fa-sellsy"
        } else if (ee.indexOf("telegram.com") >= 0) {
            fa = "fab fa-telegram-plane"
        } else if (ee.indexOf("ok.ru") >= 0) {
            fa = "fab fa-odnoklassniki"
        } else if (ee.indexOf("whatsapp.com") >= 0) {
            fa = "fab fa-whatsapp"
        } else if (ee.indexOf("twitter.com") >= 0) {
            fa = "fab fa-twitter"
        } else if (ee.indexOf("viber.com") >= 0) {
            fa = "fab fa-viber"
        } else {
            fa = "fas fa-link"
        }
        //console.log(cardFieldsData.variant_param_4)
        //console.log(palette)
        // console.log( getColor(cardFieldsData.variant_param_5, palette ) )
        return (
            <a
                href={ee}
                key={ii}
                className="lcard-pl"
                target="_blank"
                rel="noreferrer"
                style={{ 
                    transition: cardFieldsData.variant_param_3 ? `all ${200 * ii}ms ease ${100 * ii}ms`: `none`,
                    backgroundColor: getColor(cardFieldsData.variant_param_4, palette ),
                    color: getColor(cardFieldsData.variant_param_5, palette ),
                    width: cardFieldsData.variant_param_6,
                    height: cardFieldsData.variant_param_6,
                }}
                onClick={evt => evt.stopPropagation()}
            >
                <span className={`${fa} `} />  
            </a>
        )
    })
    return (
        <div className={" personal_links " + is_vertical + " pl" + hover_class}>
            { btns } 
        </div>
    )
}
export default CardFieldPersonalLinks