import { Button } from "@blueprintjs/core"
import React, { useEffect, useState} from "react" 

const RightPanel = props =>
{
    const [isOpen, setOpen] = useState(props.isOpen)
    useEffect(() => {
      setOpen(props.isOpen) 
    }, [props.isOpen])
    
    return <div
        className=" h-100"
        style={{ 
            width: isOpen 
                ? 
                props.width 
                    ? 
                    props.width 
                    : 
                    220 
                : 
                0,
            transition: "width 400ms ease-out"
        }}
    >
        <div className="panel-area-head" style={{ width: props.width || 220 }}>
            <Button 
                icon="cross" 
                minimal
                onClick={props.deselect} 
                className={ !isOpen ? 'hidden' : `position-absolute top left--30 light-svg`} 
            />
            <div>
                { props.children }
            </div>
        </div>
    </div>
}
export default RightPanel