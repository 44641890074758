import chroma from "chroma-js"
import React, { useState } from "react"
import Outer from "./Outer"
import Style from "style-it"
import { CARD_SEPARATOR } from "../Card"
import { getStyle } from "../Section"
import { getColor, getPlainColor } from "../data/getColor"
import { useHistory } from "react-router-dom";
import { __ } from "react-pe-utilities" 


const CardFieldOuterlink = ({
    e,
    i,
    cardFieldsData,
    style,
    class_name,
    bStyle,
    palette
}) => {
	let history = useHistory();
    // label and url 
    const datas = typeof e.field_type !== "undefined" || typeof e.field !== "undefined"
        ? 
        (e.field_type || "" ).toString().split(CARD_SEPARATOR())
        : 
        ["", "", ""]
	const [isOpen, onOpen] = useState(false)
    const onClick = (evt) => {
        //console.log( datas ) 
		evt.preventDefault()
		evt.stopPropagation()
		if(datas[2] === "1" || datas[2] === 1)
		{
			onOpen(!isOpen)	
		}
		else if(typeof datas[0] !== "undefined")
		{
			if( datas[0].indexOf("http") >= 0 )
			{
				window.open( datas[0], '_blank' )
			}       		
			else
			{
				history.push( "/" + datas[0].replace(/^\/*/gi, "") )
			}
		}
        else
        {
            alert(__("No exist link"))
        }
    }
    const vars = [
        "primary",
        "secondary",
        "active",
        "danger",
        "success",
        "warning",
        'link'
    ]
    const clr = parseInt(cardFieldsData.variant) !== 4
        && palette.button[vars[parseInt(cardFieldsData.variant)]]
        ?
        cardFieldsData.variant == 0
            ?
            cardFieldsData.variant_param_1
                ?
                {
                    background: cardFieldsData.variant_param_1,
                    color: "#EEE"
                }
                :
                palette.button[vars[parseInt(cardFieldsData.variant)]]
            :
            palette.button[vars[parseInt(cardFieldsData.variant)]]
        :
        { background: "#ffffff00", color: palette.card.color }

    const getLabel = (label) =>
    {
        if(label)
        {
            return label
        }
        else
        {
            return cardFieldsData.default_label
        }
    }
    
    return datas[0]
        ?
        <>
            <div
                className={" navlink " + cardFieldsData.variant_param_2}
                style={{
                    justifyContent: cardFieldsData.variant_param_2
                }}
            >
                {
                    Style.it(
                        `
                        .navv
                        {
                            background : ${cardFieldsData.variant_param_6 ? "#00000000" : getColor(clr.background, palette)};
                            border-color: ${chroma(getPlainColor(clr.background, palette)).darken(1.1)};
                            color:${cardFieldsData.variant_param_6 ? clr.background : chroma(clr.color)};
                            width:${cardFieldsData.variant_param_3 ? `100%` : 'auto'};
                            height:${cardFieldsData.variant_param_4 ? parseInt(cardFieldsData.variant_param_4) + `px` : 'auto'};
                            border-radius: ${typeof cardFieldsData.variant_param_5 !== "undefined" ? cardFieldsData.variant_param_5 + "px" : null}
                        } 
                        .navv:hover
                        {
                            background-color: ${chroma(getPlainColor(clr.background, palette)).darken(1.1)};
                            border-color: ${chroma(getPlainColor(clr.background, palette)).darken(1.01)};
                            color: ${chroma(clr.color)};
                        } 
                        .landing-card:hover .navv 
                        {
                            background-color: ${cardFieldsData.hover_color};
                            border-color: ${cardFieldsData.hover_color};
                            transition: background-color ${cardFieldsData.hover_duration}ms ease-out;
                            transition-delay:${cardFieldsData.hover_delay}ms;	
                        }
                        `,
                        <div
                            className="btn navv text-wrap"
                            style={{
                                ...getStyle(cardFieldsData.variant_param_7, palette)
                            }}
                            onClick={onClick}
                        >
                            {
                                cardFieldsData.variant_param_8
                                    ?
                                    <i className={cardFieldsData.variant_param_8 + " mr-1 mt-1"} />
                                    :
                                    ""
                            }
                            <span dangerouslySetInnerHTML={{ __html: getLabel( datas[ 1 ] ) }} />
                            {
                                cardFieldsData.variant_param_9
                                    ?
                                    <i className={cardFieldsData.variant_param_9 + " ml-1 mt-1"} />
                                    :
                                    ""
                            }
                        </div>
                    )
                }

            </div>
            <Outer
                isOuterOpen={isOpen}
                outerURL={datas[0]}
                onOpen={() => onOpen(!isOpen)}
            />
        </>
        :
        null
}
export default CardFieldOuterlink





// import chroma from "chroma-js"
// import React from "react"
// import Style from "style-it"
// import { CARD_SEPARATOR } from "../Card"


// const CardFieldOuterlink = ({
// 	e,
// 	i,
// 	cardFieldsData,
// 	style,
// 	class_name,
// 	bStyle,
// 	palette
// }) => { 
// 	// label and url
// 	const datas = e.field_type
// 		? (e.field_type).split(CARD_SEPARATOR())
// 		: ["", ""]
// 		const vars = [
// 			"primary",
// 			"secondary",
// 			"active",
// 			"danger",
// 			"success",
// 			"warning",
// 			'link'
// 		]
// 		const clr = parseInt(cardFieldsData.variant) !== 4
// 		&& palette.button[vars[parseInt(cardFieldsData.variant)]]
// 			?
// 			palette.button[vars[parseInt(cardFieldsData.variant)]]
// 			:
// 			{ background: "#ffffff00", color: palette.card.color }
// 	return datas[0]
// 		? 
// 		Style.it(
// 			`
// 			.navv
// 			{
// 				background-color: ${clr.background};
// 				border-color: ${chroma(clr.background).darken(1.1)};
// 				color:${chroma(clr.color)};
// 			}
// 			.navv::hover
// 			{
// 				color:${chroma(clr.color)};
// 				background-color: ${chroma(clr.background).darken(2)};
// 			}
// 			`,
// 			<div
// 				className=" outerlink "
// 			>
// 				<a
// 					className={`navv btn text-wrap ${class_name} `}
// 					href={datas[0]}
// 					target="_blank"
// 					rel="noreferrer"
// 					onClick={evt => evt.stopPropagation()}
// 				>
// 					{datas[1]}
// 				</a>
// 			</div>
// 		)
// 		: 
// 		null
// }
// export default CardFieldOuterlink