import React, { Fragment } from "react"
import $, { css } from "jquery"
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import Section, { getDefault } from "./Section"
import Style from "style-it"
import { getFontNameByID } from "./data/PalettePresets"
import Icon from "../core/Icon" 

export const HACCORDEON_BUTTON_WIDTH = 110

class Haccordeon extends SectionContent 
{	
    state = {
        ...this.props,
        is_edit: false,
        is_opens: [],
        height: "100%",
        openOrder : -1
    }
    componentDidMount() 
    {
        if(this.props.data.height)
        {
            this.setState({ height : this.props.data.height })
        }
        else
        {
            const h = parseInt( $(`.landing-section[data-id=${ this.props.id }]`).css("min-height"), 10)
            console.log(`.landing-section[data-id=${ this.props.id }]`, h) 
            $(`.landing-section[data-id=${ this.props.id }] .landing-haccordeon`).height( h - 100 ) 
            this.setState({ height : h - 100 })
        }
        
    }	
    componentWillUpdate(nextProps) {
		if (nextProps.is_edit != this.state.is_edit) {
			this.setState({ is_edit: nextProps.is_edit })
		}
	}
    is() 
    {
        const { accordeon_sections } = this.props.data
        return accordeon_sections?.length > 0
    }	
    getWidth = () =>
    {
        const haccordeon = $(`[data-id=${ this.props.id }] .landing-haccordeon`)
        return haccordeon.width()
    }
    getWidthOpen = () =>
    {
        const { accordeon_sections, cover_width } = this.props.data
        const haccordeon = $(`[data-id=${ this.props.id }] .landing-haccordeon`)
        return haccordeon.length > 0 
            ?  
            haccordeon.width() - (cover_width | 70) * (  document.body.clientWidth > 800 ? accordeon_sections?.length : 1 ) 
            :
            (cover_width | 70)
    }
    renderContent(style) {
		const { palette } = this.props
		const {
			class_name, accordeon_sections, proportia, design_type, cover_width
		} = this.props.data   
        
        //console.log(this.props)
        const accSections = accordeon_sections.map((e, i) => {
            const wdth = this.state.openOrder === -1 
                ?
                this.getWidth() / accordeon_sections.length  
                :
                this.state.openOrder === i 
                    ? 
                    this.getWidthOpen() + (cover_width | 70) 
                    : 
                    document.body.clientWidth < 800 && 
                    (   this.state.openOrder - 0 > i || 
                        this.state.openOrder + 0 < i )
                        ?
                        0
                        :
                        `${ (cover_width | 70) }px`; 
            const estyle = { 
                ...this.getStyle(e.style), 
                minWidth: wdth ? wdth : 200,
                maxWidth: wdth ? wdth : 200,
                backgroundColor: e.cover_color,
                color: e.cover_text_color 
            }
            return <div
                style={estyle}
                className={
                    `landing-haccordeon-section ${e.className
                    } ${design_type
                    } ${this.state.openOrder === i 
                        ? 
                        " open " 
                        : 
                        this.state.openOrder == -1 ? " " : " closed "
                    }`
                }
                key={i}
            >
            {
                Style.it(
                    `.landing-haccordeon-button {
                        font-family:${ getFontNameByID( palette.card.title.fontFamilyID ) };
                        font-weight:${ getFontNameByID( palette.card.title.fontWeight ) };
                        font-size:${ getFontNameByID( palette.card.title.fontSize ) };
                        background-color: ${ e.cover_color };
                        min-width: ${this.state.openOrder !== -1 ? (cover_width | 70) : "--"}px;
                        max-width: ${this.state.openOrder !== -1 ? (cover_width | 70) : "--"}px;
                    }`,
                    <div
                        className={
                            `${"landing-haccordeon-button " + " "}
                            ${design_type}
                            ${this.state.openOrder !== -1 ? " open " : " "}`
                        }
                        i={i}
                        onClick={() => this.onOpen(i, "click")}
                        onMouseEnter={() => this.onOpen(i, "hover")}
                        
                    >
                        <div 
                            className="landing-haccordeon-button-label"
                            style={{
                                color: e.cover_text_color,
                                minWidth: typeof this.state.height === "number" ? this.state.height - 100 : 200,
                                ...this.getStyle(e.title_css)
                            }}
                            dangerouslySetInnerHTML={{__html : __(e.title)}}
                        />
                        <Icon
                            src={ e.sign_top }
                            style={{
                                width: 30,
                                height: 30,
                                position: "absolute",
                                top: 30,
                                fontSize: 30
                            }}
                        />    
                        <Icon
                            src={ e.sign_bottom }
                            style={{
                                width: 30,
                                height: 30,
                                position: "absolute",
                                bottom: 30,
                                fontSize: 30
                            }}
                        />                                    

                        { /*__sign_type*/ }
                    </div>  
                )                            
            }
            {
                Style.it(
                    `
                    .lading-haccordeon-inner-block > .landing-section-container > .landing-section
                    {
                        height: ${this.state.height}px!important;
                        min-height: ${this.state.height}px!important;
                        max-height: ${this.state.height}px!important;
                    }`,
                    <div 
                        className="lading-haccordeon-inner-container" 
                    >
                        <div 
                            className="lading-haccordeon-inner-block"
                            style={{
                                    maxWidth: this.getWidthOpen(),
                                    minWidth: this.getWidthOpen(),
                            }}
                        >
                        {
                            Array.isArray(e.section)
                                ?
                                e.section.map((section, index) => (
                                    <Section
                                        key={index}
                                        {...section}
                                        style={{
                                            ...this.getStyle(section.style),
                                            height: this.state.height
                                        }}
                                        data={{
                                            ...(section ? section.data : {}),
                                            style : {
                                                ...( 
                                                    section && section.data 
                                                        ? 
                                                        section.data.style 
                                                        : 
                                                        {} 
                                                    ),
                                                height: this.state.height,
                                                color: ( e.cover_text_color ) + "!important",
                                                width:`${ this.getWidthOpen( ) + 70 }px!important`
                                            },
                                            height: this.state.height
                                        }}
                                        i={index}
                                        user={this.props.user}
                                        is_edit={this.state.is_edit}
                                        level={this.props.level + 1}
                                        onEdit={this.onEdit}
                                        onUp={this.onUp}
                                        onDn={this.onDn}
                                        onAdd={this.onAdd}
                                        onRnv={this.onRnv}
                                    />
                                ))
                                :
                                <div />
                            }
                        </div>
                    </div>  
                )
            }
            </div>             
        })
		return <div
            className={ `landing-haccordeon landing-section-core  ${this.props.data.class_name}` }
            style={{ height: this.state.height, ...this.getStyle(style) }}
        >
            {accSections}
        </div> 
	}

	onOpen = (openOrder, handle) => { 
        const hnd = this.props.data.handle_type ? this.props.data.handle_type : "click"
		if( hnd === handle )
            this.setState({ openOrder : this.state.openOrder === openOrder ? -1 : openOrder })
	}

	onEdit = (data, id) => {
		console.log("onEdit", id, data, this.state)
		const sections = Array.isArray(this.state.data.sections)
			?
			[...this.state.data.sections]
			:
			[]
		const secs = []
		sections.forEach((e) => {
			if (e.id == data.id) {
				secs.push(data)
			} else {
				secs.push(e)
			}
		})
		this.setState({ data: { ...this.state.data, sections: secs } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections: secs } },
			this.props.id,
		)
	}

	onUp = (data) => {
		console.log("onUp", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data - 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onDn = (data) => {
		console.log("onDn", data, this.state); const sections = [...this.state.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data + 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onAdd = (data) => {
		console.log("onAdd", data, this.state)
		const sections = [...this.state.data.sections]
		const sec = getDefault()
		sections.splice(data + 1, 0, sec)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onRnv = (data) => {
		console.log("onRnv", data, this.state.data.sections)
		const sections = [...this.state.data.sections]
		sections.splice(data, 1)
		console.log(sections)
		this.setState({ data: { ...this.state.data, sections } })
		this.props.onEdit(
			{ ...this.state, data: { ...this.state.data, sections } },
			this.props.id,
		)
	}

	onHide = (id, is_hide) => {
		console.log("HIDE", id, is_hide)
	}

	onRemoveFloat = (float_id) => {

	}

	onUpdateFloat = (data, float_id, section_id) => {

	}
}
export default Haccordeon;