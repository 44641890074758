import React, { Component,   } from "react"
import { NavLink,   } from "react-router-dom" 
import _fetch from "."
import { __ } from "react-pe-utilities"  

class MemberAdmiMenu extends Component {
  render() {
    const {
      is_owner,  preroute, 
    } = this.props
    return is_owner
      ?			(
        <NavLink
          to={`${preroute}/settings`}
          className="btn btn-light btn-block"
          activeClassName="btn-dark rounded-0"
        >
          {__("Project Setting")}
        </NavLink>
      )
      :			null
  }
}
export default MemberAdmiMenu
