import React, {Component, Fragment} from "react";

export default class GanreRangeButton extends Component
{
	render()
	{
		return <Fragment>
			<input
				type="checkbox"
				className="ganre_checkbox"
				checked={ this.props.checked }
				id={this.props.id}
				name={this.props.name}
				value={this.props.value}
				onChange={this.onChange}
			/>
			<label htmlFor={this.props.id}>
				<img src={this.props.icon} alt={this.props.label} /> 
				{this.props.label}
			</label>
		</Fragment>
	}
	onChange = evt =>
	{
		//console.log(evt.currentTarget.value);
		this.props.onChange(evt)
	}
}
