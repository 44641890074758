import React, { Component } from "react"
import { __ } from "react-pe-utilities"
import { concatRouting } from "react-pe-layouts"
import {getWidgets} from "react-pe-utilities"

class Aside extends Component {
  render() {
    const aside = this.props.route
    ?
    concatRouting().filter((e) => this.props.route.split("/")[1] == e.route)[0].aside 
    :
    []
    // console.log( aside );
    const __widgets = aside.map((e, i) => {
      if (getWidgets[e.component]) {
        const _Widget = getWidgets[e.component].default
        return (
          <div className="aside-widget col-12" key={i}>
            <div className="aside-widget-title">
              {__(e.title)}
            </div>
            <_Widget {...this.props} {...e.args} />
          </div>
        )
      }

      return (
        <small className="m-5 text-secondary text-center">
          {__("No widget exists: ") + e.component}
        </small>
      )
    })
    return (
      <div className="row">
        {__widgets}
      </div>
    )
  }
}
export default Aside
