import { Card } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities/dist"

const YF_PEFetAdminWidget = props =>
{
    return <Card className="p-4"> 
        <div className="lead">
            {__("Yandex Forms integrations")}
        </div>
    </Card>
}
export default YF_PEFetAdminWidget 