import React, { Component } from "react"
import {Loading} from 'react-pe-useful'
import {
  getQueryName, 
  getQueryArgs,
  queryCollection 
} from "react-pe-layouts"
import QR from "../../core/QR"
import {getQRDefaultData} from "../QRCodeAdminView"

import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router" 

class QRCodeList extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      listData : [],
      selected:[]
    }
  }
  componentWillUpdate(nextProps)
  { 
    if(nextProps.refreshMe)
    {
      console.log("update QR list started")
      if(this.props.refresh)
      {
        this.props.refresh()
        this.load()
      }
    }
  }
  load = () => {
    const {client} = this.props
    const data_type = "PEQRCode";
    const query_name = getQueryName(data_type)
    const query_args = getQueryArgs(data_type)
    const query = queryCollection(data_type, query_name, query_args)
    client.query({
      query
    }).then(result => {
      // console.log( result.data.getPEQRCodes )
      const selected = result.data.getPEQRCodes.map( qr => qr.id)
      this.setState({listData: result.data.getPEQRCodes, selected})
    })
  }
  componentDidMount()
  {
    this.load()
  }
  onChecked = id =>
  {
    let selected = [...this.state.selected]
    if(selected.filter(e => e === id).length >0)
    {
      selected = selected.filter( e => e !== id)
    }
    else
    {
      selected.push(id)
    }
    console.log( selected )
    this.setState({ selected })
  }
  render()
  {
    
    const {loading, defaults } = this.props
    const {listData} = this.state
    if(loading)    return <Loading/>
    const list = listData.map((qrData, i) => {
      const qr = {...getQRDefaultData(), ...qrData}
      const options = {       
        ...qr, 
        title: qr.publish_text ? qr.title ? qr.title : qr.post_title : null,
        logo : qr.thumbnail, 
        drawer: "svg",
        //logoWidth :  qr.thumbnail ? qr.logoWidth : 0,
        //logoHeight : qr.thumbnail ? qr.logoHeight : 0,
        ...( this.state.selected.filter(e => e === qr.id).length > 0 ? defaults : {} ),
        titleBackgroundColor : "transparent" ,
        logoBackgroundColor: "transparent",
      }
      // console.log( options)
      return <QR 
        options={options} 
        key={i} 
        isManipulators 
        checked={this.state.selected.filter(e => e === qr.id).length > 0}
        onChecked={this.onChecked}
        delete={id => this.props.deleteQR(id) }
      /> 
    })
    return <div className="d-flex flex-wrap">
      {list}
    </div>
  }
    
}

export default  compose(
  withApollo,
  withRouter,
)(QRCodeList)