import React, { Component, Suspense } from "react" 
import { Tag } from "@blueprintjs/core"
import chroma from "chroma-js"
import { __ } from "react-pe-utilities"
import { Loading } from "react-pe-useful"  
import Select from "react-select" // https://github.com/JedWatson/react-select
import { getIdName } from "react-pe-layouts" 
 
export default class MultiSelect extends Component 
{
    constructor(props) {
        super(props)
        const default_value = {}
        default_value[ getIdName() ] = -1
        this.state = {
        value: this.props.multiple	
            ?
            this.props.value		
                ? 
                this.props.value?.value 
                : 
                [default_value]
            :
            this.props.value		
                ? 
                this.getElementByValue( this.props.value )
                : 
                default_value,
        }
    }
    getElementByValue = element =>
    {
        const visible_value = "title"
        if(typeof element === "array")
        {
            return element.map( e => this.getElementByValue( e ) )
        }
        else if( element._id )
        {
            return {
                value: element._id,
                label : element.title,
                color: "#000"
            }
        }
        else if( element[ getIdName() ] )
        {
            return {
                value :	element[ getIdName() ],
                label : element[ visible_value ],
                color: "#000"
            }
        }
        else if( typeof element === "string" || typeof element === "number"  )
        {
            return {
                value: element,
                label: element,
                color: "#000"
            }
        }
    }
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.data != this.props.data) 
    //     {
    //         this.setState({ data: [...nextProps.data] })
    //     }
    //     if (nextProps.value != this.state.value) 
    //     {
    //         this.setState({ value: nextProps.value })
    //     }
    // }

	handleChange = (newValue, actionMeta) => {
		if(this.props.multiple)
		{
			const sel = newValue || []
			const selected = sel.map((e) => {
				const value = {}
				value[getIdName()] = e.value

				const label = Array.isArray(this.props.visibled_value)
					?				
					this.props.visibled_value.map((ee) => e[ee]).join(" ")
					:				
					e[this.props.visibled_value]

				value[this.props.visibled_value] = e.label
				return value
			})
			this.setState({ value: selected })
			this.on(selected)
		}
		else
		{
			//console.log( newValue )
			this.setState({ value: newValue })
			this.on( newValue.value )
		}
	} 
	onChange = (evt) => {
	  const selected = evt.currentTarget.selectedOptions
	  const value = []
	  for (const i in selected) {
	    if (!selected[i].value) continue
	    const v = { _id: selected[i].value, color:"#00FF00" }
	    v[this.props.visibled_value] = selected[i].label
	    value.push(v)
	  }
	  //console.log(value)
	  // this.setState({value: [selected]});
	  // this.on( [selected] );
	}

	on = (value) => {
	  this.props.on(value, this.props.field, this.props.title)
	}

    isEnabled() 
    {
        const { field, title, data, multiple, values, placeholder } = this.props
        const { value } = this.state
        const d = data && data.length > 0
            ? 			
            this.props.data.map((e, i) => {
                const label = Array.isArray(this.props.visibled_value)
                ?					
                this.props.visibled_value.map((visibled_value) => e[visibled_value]).join(" ")
                :					
                e[this.props.visibled_value]
                return { value: e[getIdName()], label, color: e[this.props.color] }
            })
            : 			
            Array.isArray(values)
                ? values.map((e, i) => {
                const label = e.title
                return { value: e._id, label, color: e[this.props.color] }
                })
                : 				
                []
        // console.log(field, data, d, this.props.visibled_value);
        const options = multiple
            ?
            (value || []).map((e, i) => {
                const label = Array.isArray(this.props.visibled_value)
                    ?				
                    this.props.visibled_value.map((visibled_value) => e[visibled_value]).join(" ")
                    :				
                    e[this.props.visibled_value]
                const dat = { value: e[getIdName()], label, color: e.color }
                return dat
            })
            :
            value?.value
                ?
                value
                :
                { value:value, id:value, color: "#0000FF" }

        const colourStyles = {
            control: (styles) => ({ ...styles, backgroundColor: "#EFEFEF", color: data.color ? data.color : "#333" }),
            option: (styles, {
                data, 
                isDisabled, 
                isFocused, 
                isSelected,
            }) => {
                const clr = data.color
                const color = chroma(clr || "#111")
                return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? null
                        : isSelected
                        ? clr
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                    color: isDisabled
                        ? "#ccc"
                        : isSelected
                        ? chroma.contrast(color, "white") > 2
                        ? "white"
                        : "black"
                        : clr,
                    cursor: isDisabled ? "not-allowed" : "default",

                    ":active": {
                        ...styles[":active"],
                        backgroundColor: !isDisabled && (isSelected ? clr : color.alpha(0.3).css()),
                    },
                }
            },
            multiValue: (styles, { data }) => {
                const clr = data.color ? data.color : "#333"
                const color = chroma(clr || "#111")
                return {
                    ...styles,
                    backgroundColor: color.alpha(0.1).css(),
                }
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: data.color ? data.color : "#111",
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: data.color ? data.color : "#333",
                ":hover": {
                    backgroundColor: data.color ? data.color : "#333",
                    color: "white",
                },
            }),
        }

        return <Suspense fallback={<Loading />}>
            <Select
                value={options}
                styles={colourStyles}
                isMulti={multiple}
                isSearchable
                onChange={this.handleChange}
                options={d}
                placeholder={placeholder || __("Select...")}
                className="basic-multi-select w-100"
                classNamePrefix="select"
            />
            </Suspense>
    }

    isDesabled() 
    { 
        const { value } = this.state
        return <div className="px-0 mb-1">
        {
            ( Array.isArray(value) ? value : [ value ] ).map((e, i) => 
            {
                const txt = typeof e === "string"
                    ?
                    e
                    :
                    e[this.props.visibled_value] 
                        ? 
                        e[this.props.visibled_value] 
                        : 
                        __("Untitled")
                return <Tag minimal className="m-1" key={i}>{ txt }</Tag>
            })
        }
        </div> 
    }
    render()
    {
        return this.props.editable ?  this.isEnabled() : this.isDesabled()
    }
}
