import React from "react"
import { useEffect } from "react"
import Style from "style-it"
import { gsap } from "gsap";
import $ from "jquery"
import "../../../assets/css/slider.css"
import trim1 from "../../../assets/img/children_trim1.jpg"
import trim2 from "../../../assets/img/children_trim2.jpg"
import trim3 from "../../../assets/img/children_trim3.jpg"
import trim4 from "../../../assets/img/children_trim4.jpg"

const Slider = props =>
{
    var i = 0
    var interval = 1
    useEffect(() => {
        let panels = document.querySelectorAll(".panel");
        let fronts = document.querySelectorAll(".front");
        let backs = document.querySelectorAll(".back");
        let titleObj    = document.querySelector(".layer .pefest-title"); 
        let titleCont   = document.querySelector(".layer .pefest-title-cont"); 
        let title2Obj    = document.querySelector(".layer2 .pefest-commentary"); 
        let title2Cont   = document.querySelector(".layer2 .pefest-title-cont"); 
       
        const shft = 2.8
        const mirrorTL = gsap.timeline(); 
        const titleTL = gsap.timeline(); 
        const titleTL2 = gsap.timeline(); 

        // gsap.set(replay_btn, { opacity: 0 });
        const playplay = ( ) => {
            i++            
            if( i % 4 == 3 )
            { 
                $(".back").css('background-image', `url(${trim4 })`)
                $(".front").css('background-image', `url(${trim1})`)
            }        
            else if( i % 4 == 2 )
            { 
                $(".back").css('background-image', `url(${trim1})`)
                $(".front").css('background-image', `url(${trim2 })`) 
            }       
            else if( i % 4 == 1 )
            { 
                $(".back").css('background-image', `url(${trim2})`)
                $(".front").css('background-image', `url(${trim3 })`) 
            }
            else
            {
                $(".back").css('background-image', `url(${trim3})`)
                $(".front").css('background-image', `url(${trim4 })`) 
            }
            // $(".pefest-title").css('clip-path', `polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)`) 
            mirrorTL.restart(); 
            titleTL.restart(); 
            titleTL2.restart(); 
        } 

        mirrorTL  
            .to(fronts, shft,{ backgroundPosition: "30px 0px", ease: "expo.inOut" }, `-=${shft}`)  
            .to(panels, shft,{ z: 0, rotationY: 180, ease: "expo.inOut" }, `-=${shft}`)  
            .from(
                backs,
                shft,
                {
                    backgroundPosition: `-30px 0px`,
                    ease: "expo.inOut",
                    onComplete: () => {
                        //gsap.to(replay_btn, 1, { opacity: 1 });
                    }
                },
                `-=${ shft }`
            );
        titleTL
            .to  (titleObj, { translateY: "0px", opacity: 1} ) 
            .to  (titleCont, { translateY: "0px", ease: "expo.inOut"}, '-=2')
        titleTL
            .to  (title2Obj, { translateY: "0px", opacity: 1} ) 
            .to  (title2Cont, { translateY: "0px", ease: "expo.inOut"}, '-=2')
         
        interval = setInterval(() => playplay(), 8000 )
        return () =>  {
            clearInterval( interval )
        }   
    }, [])
    const shiftX = 216
    const fronts = Array(7).fill(1)
        .map((e, i) => {
            return `
            .panel:nth-child(${i}) .front {
                background-position: -${ shiftX * i + 0 }px 0;
            } 
            .panel:nth-child(${i}) .back {
                background-position: -${  shiftX * i + 0 }px 0;
            }
            `
        })
    return Style.it(
        `                
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        .body .front
        {
            background-image:url(${ trim3 });
        }
        .body .back
        {
            background-image:url(${ trim4 });
        }
        ${fronts}
        `,
        <div className="body">
            <div className="container22">
                <div className="panel">
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
                <div className="panel">
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
                <div className="panel">
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
                <div className="panel">
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
                <div className="panel">
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
                <div className="panel">
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
            </div> 
            <div className="layer">
                <h1 className="pefest-title">
                    <div className="pefest-title-cont">
                        <span>PE</span><span className="pe-fest-admin-logo" />ФЕСТИВАЛЬ
                    </div>
                </h1> 
            </div> 
            <div className="layer2">
                <div className="pefest-commentary">
                Для организации многоуровневой архитектуры необходимо разделить сервисы приложения на базовые и сервисы бизнес-логики. 
                </div> 
            </div>
        </div>
    )
}
export default Slider