import { menu } from "react-pe-layouts/dist/routing"
import { getCookie, setCookie } from "./utils"

function getDataField(field, value)
{
  if(typeof value !== "undefined")
  {
    WPFestSettings[field] = value
  }
}
class WPFestSettings 
{
  static getToken(url)
  {
    getDataField.token = getCookie( `wpfest_token${ url }` )
    // console.log(getDataField.token)
    return getDataField.token
  }
  static updateToken(url, token)
  {
    setCookie(`wpfest_token${ url }`, token)
    WPFestSettings.setToken(token)
  }
  static setToken(token)
  {
    getDataField("token",                 token)
  }
  static getFestURL = () =>
  {
    return WPFestSettings.festURL
  }
  static set(data) {
    getDataField("festURL",               data.festURL)
    getDataField("festId",                data.festId)
    getDataField("festival_title",        data.festival_title)
    getDataField("fmru_evlbl_roles",      data.fmru_evlbl_roles)
    getDataField("url",                   data.url)
    getDataField("is_comment",            data.is_comment)
    getDataField("is_register",           data.is_register)
    getDataField("is_diaries",            data.is_diaries)
    getDataField("is_expert",             data.is_expert)
    getDataField("is_experts_criteries",  data.is_experts_criteries)
    getDataField("is_owner_rate",         data.is_owner_rate )
    getDataField("is_independent_grade_criteries",  data.is_independent_grade_criteries )
    getDataField("is_ganres",             data.is_ganres) 
    getDataField("ganres",                Array.isArray(data.ganres) ? data.ganres : [])
    getDataField("is_roles",              data.is_roles) 
    getDataField("logo",                  data.logo) 
    getDataField("lurl",                  data.lurl) 
    getDataField("max_raiting",           data.max_raiting) 
    getDataField("mtype",                 data.mtype) 
    getDataField("status",                data.status) 
    getDataField("base_prefix",           data.base_prefix) 
    getDataField("count_juri",            data.count_juri) 
    getDataField("enabled_rules",         data.enabled_rules) 
    getDataField("serviceURL",            data.serviceURL) 
  }

  static set_is_comment(bool) {
    WPFestSettings.is_comment = bool
  }

  static add_critery = data =>
  {
    if(!Array.isArray(WPFestSettings.criteriyObjects))
      WPFestSettings.criteriyObjects = []
    if(WPFestSettings.criteriyObjects.filter(cr => cr.id === data.id).length > 0 ) return
    WPFestSettings.criteriyObjects.push( data )
  }
  static addCriteryComponent = (component, critery_id) =>
  {
    if(!Array.isArray(WPFestSettings.criteryComponents))
    {
      WPFestSettings.criteryComponents = []
    }
    if( !WPFestSettings.criteryComponents[critery_id] )
    {
      WPFestSettings.criteryComponents[critery_id] = []
    }

    if(WPFestSettings.criteryComponents[critery_id].filter(c => c.state.id === component.state.id ).length > 0) 
    {
      return
    }
    WPFestSettings.criteryComponents[critery_id].push(component)
  }
}
export default WPFestSettings
