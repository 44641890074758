import { Alignment, Button } from "@blueprintjs/core"
import React, { Fragment, useState } from "react"
import matrix from ".././../views/LandingState/data/matrix"
import DataContext from ".././../views/LandingState/DataContext"
import { getDefault } from ".././../views/LandingState/Section"
import { __ } from "react-pe-utilities/dist"
import Style from "style-it"
import MatchingCard from "./matching/MatchingCard"

const Matchings = props =>
{
    //console.log(props)
    //console.log( matrix()[ props.landing_object ]?.visible_value.value )
    const [value, onValue] = useState(props.value)
    const setValue = val =>
    {
        onValue(val)
        props.on(val)
    }
    const onSwitch = i =>
    {
        setValue( 
            [ ...value ]
                .map(( e, n ) =>
                {
                    return n === i
                        ?
                        value[n - 1]
                        :
                        n === i - 1
                            ?
                            value[n + 1]
                            :
                            e
                })
        ) 
    }
    const onAddNew = ( landing_object, order = -1 ) =>
    {
        console.log(landing_object)
        let obj = {}
        if(landing_object === "section")
        {
            obj = getDefault()
        }
        else
        {
            const mt = matrix()[ landing_object ]
            const dflt = mt.default 
                ? 
                mt.default 
                : 
                {}
            Object.keys(mt)
                .filter((e) => !mt[e].hidden)
                    .forEach((e, i) => {;
                        let el
                        switch (mt[e].type) {
                            case "int":
                            case "number":
                            case "slider":
                                el = dflt[e] ? dflt[e] : 0
                                break
                            case "boolean":
                                el = dflt[e] ? dflt[e] : false
                                break
                            case "geo":
                            case "array":
                                el = dflt[e] ? dflt[e] : []
                                break
                            case "checkbox":
                            case "string":
                            case "media":
                            case "color":
                            case "url":
                            case "text":
                            case "date":
                                el = typeof dflt[e] !== "undefined" ? __( dflt[e] ) : ""
                                if (e === "id" && landing_object === "section") {
                                    el = DataContext.getMaxSectionID(true)
                                }
                                break
                            case "radio":

                                break
                            default:
                            case "landing_object":
                                const mtt = matrix()[mt[e].landing_object] 
                                if ( !mtt ) break
                                el = Array.isArray(dflt[ e ]) 
                                    ? 
                                    dflt[ e ].map((defaultElement, dfi) => {
                                        if( typeof defaultElement === "string" )
                                            return __(defaultElement) 
                                        return defaultElement 
                                    }) 
                                    : 
                                    mt[e].type === "html" || mt[e].type === "text" ? "" : {} 
                                // console.log( mtt, e, el, mt[e] );
                                if ( dflt[e] ) break
                                Object.keys(mtt)
                                    .filter((ee) => !mtt[ee].hidden)
                                    .forEach((ee, i) => {
                                        // console.log( ee, mtt[ee] );
                                        let ell
                                        switch (mtt[ee].type) {
                                            case "int":
                                            case "number":
                                            case "slider":
                                                ell = 0
                                                break
                                            case "boolean":
                                                ell = false
                                                break
                                            case "checkbox":
                                            case "array":
                                            case "string":
                                            case "media":
                                            case "url":
                                            case "text":
                                            case "date":
                                                ell = ""
                                                break
                                            case "color":
                                                ell = null
                                                break
                                            case "radio":
                                            

                                                break
                                            default:
                                            case "landing_object":
                                                // console.log( ee, mtt[ee].landing_object, matrix()[ mtt[ee].landing_object ] );
                                                ell = mtt[ee].kind === "array"
                                                    ? []
                                                    : {}
                                                break
                                        }
                                        el[ee] = ell
                                    })

                                break
                        }
                        obj[e] = el
                    })
        }
        if(Array.isArray(value))
        {
            const v = [...value]
            order < 0 ? v.push( obj ) : v.splice( order + 1, 0, obj )
            setValue( v )
        }
        else
        {
            setValue(  [ obj ] )
        } 
        
    }
    const onRemove = order =>
    {
        if(!Array.isArray(value))  return 
        const v = [...value]
        //console.log(order, v)
        v.splice( order , 1 )
        setValue( v )  
    }
    const onEditCard = (data, order) =>
    {
        if(!Array.isArray(value))  return 
        const v = [...value]
        v[order] = data
        setValue( v ) 
    }
    const cards = Array.isArray(value)
        ?
        value.map((data, i) =>
        {
            const switcher = i 
                ? 
                <div className="vert-switcher">
                    <Button
                        icon={<i className="fas fa-sort" />}
                        onClick={ () => onSwitch(i) }
                    />
                </div>
                :
                null
            return <Fragment key={i}> 
                <MatchingCard                    
                    i={i}
                    data={ data }
                    default_title={ matrix()[props.landing_object]?.sparams?.title }
                    visible_value={ matrix()[ props.landing_object ]?.visible_value.value }
                    landing_object={ props.landing_object }
                    onSwitch={ onSwitch }
                    onAddNew={ onAddNew }
                    onRemove={ onRemove }
                    onEditCard={onEditCard}
                />
            </Fragment>
        })
        :
        null
        
    return Style.it(
        `
        .vert-switcher
        {
            display: flex;
            justify-content: end;
            padding-right:20px;
            align-items: center;
            height: 1px;
        }`,
        <div className="w-100 ">
            { cards }
            <Button
                icon="plus"
                alignText={ Alignment.LEFT } 
                className="" 
                minimal 
                fill
                onClick={ () => onAddNew( props.landing_object ) }
            >
                {__("Add new")}
            </Button>
        </div>
    )
}
export default Matchings;