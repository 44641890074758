import React from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"

class NoMatchView extends BasicState {
  myState = () =>
  // console.log(this.props);
  (
    <div className="row text-center">
      <div className="col-12 my-4">
        <div className="_404" />
      </div>
      <div className="col-12 lead">
        {__("this page no searched")}
      </div>
      <div className="col-12 my-4">
        <Link
          className="btn btn-danger btn-sm"
          to="/"
        >
          {__("Return to main page")}
        </Link>
      </div>
    </div>
  )
}

export default withRouter(NoMatchView)
