import React from "react" 
import SectionContent from "./SectionContent"
import Group from "./vk/Group";
import Playlist from "./vk/Playlist";
import Poll from "./vk/Poll";

class VKWidget extends SectionContent {
  is() {
    const { api_key } = this.state.data
    return !!api_key
  }
  onJoin = () => {

  }
  onLeave = () => {

  }
  renderContent(style) {
    const {
      vk_widget_type,
      api_key,
      poll_id,
      owner_id,
      playlist_id,
      play_list_hash,
      groupId, width, height, mode, no_cover, wide, color1, color2, color3, 
      // onJoin, onLeave
    } = this.state.data
    let widget = null;
    switch (vk_widget_type) {
      case "playlist":
        widget = <Playlist
          owner_id={owner_id}
          playlist_id={playlist_id}
          hash={play_list_hash}
        />
        break
      case "group":
        widget = <Group
          api_key={api_key}
          groupId={groupId}
          width={width}
          height={height}
          mode={mode}
          no_cover={no_cover}
          wide={wide}
          color1={color1}
          color2={color2}
          color3={color3}
          onJoin={this.onJoin}
          onLeave={this.onLeave}
        />
        break
      case "poll":
      default:
        widget = <Poll
          api_key={api_key}
          poll_id={poll_id}
        />
    }
    return <>
      {widget}
    </>
  }
}
export default VKWidget