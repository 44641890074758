import { Button, ButtonGroup, Card, Collapse, Popover } from "@blueprintjs/core" 
import React from "react"
import { useState } from "react"
import { moduleExists } from "react-pe-layouts"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"

const SingleFormField = props =>
{
    const [type, setType] = useState(props.type)
    const [ require, setRequire] = useState( props.require )
    const [values, setValues] = useState(props.values || "")
    const [projectField, setProjectField] = useState(props.projectField)
    const [yandex_form_field, setyandex_form_field] = useState(props.yandex_form_field)
    const [count, setCount] = useState(props.count || 1)
    const [name, setName] = useState(props.name)
    const [description, setDescription] = useState(props.description) 
    const [isOpen, onOpen] = useState( false )
    const ChangeCount= value =>
    {
        setCount(value)
        const data = {
            require,
            name,
            description,
            count:value,
            type,
            values,
            projectField,
            yandex_form_field
        }
        props.onChange(props.i, data)
    } 
    const ChangeRequire = value =>
    {
        console.log( value )
        setRequire( value )
        const data = {
            require:value,
            name,
            description,
            count,
            type,
            values,
            projectField,
            yandex_form_field
        }
        props.onChange(props.i, data)
    } 
    const ChangeType= value =>
    {
        setType(value)
        const data = {
            require,
            name,
            description,
            count,
            type:value,
            values,
            projectField,
            yandex_form_field
        }
        props.onChange(props.i, data)
    } 
    const ChangeValues = value =>
    {
        setValues(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values:value,
            projectField,
            yandex_form_field
        }
        props.onChange(props.i, data)
    } 
    const ChangeProjectField= value =>
    {
        setProjectField(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values,
            projectField:value,
            yandex_form_field
        }
        props.onChange(props.i, data)
    } 
    const changeYandex_form_field = value =>
    {
        //console.log(value)
        setyandex_form_field(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values,
            projectField,
            yandex_form_field:value
        }
        props.onChange(props.i, data)
    } 
    const changeYFSubmenu = (value, field) =>
    {
        //console.log(value, field)
        props.OnSubMenu(props.i, value, field)

    }
    const ChangeName = value =>
    {
        setName(value)
        const data = {
            require,
            name:value,
            description,
            count,
            type,
            values,
            yandex_form_field
        }
        props.onChange(props.i, data)
    }
    const ChangeDescription = value =>
    {
        setDescription(value)
        const data = {
            require,
            name,
            description:value,
            count,
            type,
            values,
            yandex_form_field
        }
        props.onChange(props.i, data)
    }
    const getTypeSelect = () =>
    {
        return <div>
            {
                getFormFieldTypes().map((form, i) => {
                    return <div>
                        <label className="_check_blue_" >
                            <input 
                                type="radio"
                                id="type-form-fieild-member"  
                                value={form._id}
                                checked={type === form._id}
                            />
                            { __(form.title) }
                        </label>   
                    </div>
                })
            }
        </div>
    }
    return <Card  interactive className="my-2">
        <FieldInput
            value={name}
            type="string"
            title={__( "Field's name" )}
            onChange={ ChangeName }
            on={ ChangeName }
        />
        <FieldInput
            value={ require }
            _id={`require_${ props.i }`} 
            type="boolean"
            title={__( "Required field" )}
            onChange={ ChangeRequire }
            on={ ChangeRequire }
        />
        <Button fill icon="more" onClick={() =>onOpen(!isOpen)} className={ isOpen ? "hidden" : ""} minimal/>
        <Collapse isOpen={isOpen}>
            <FieldInput
                value={description}
                type="string"
                title={__( "Field's description" )}
                onChange={ ChangeDescription }
                on={ ChangeDescription }
            />
            <FieldInput
                value={count}
                type="slider"
                title={__( "Count of fields" )}
                min={1}
                max={10}
                step_size={1}
                onChange={ ChangeCount }
                on={ ChangeCount }
            />
            <FieldInput
                value={type}
                visualization="festFormsFields" 
                type="tags"
                values={ getFormFieldTypes().map( t => ({ ...t, title: __(t.title) }) ) }
                editable={true} 
                className={` form-control dark input  ${props.className} `}
                title={__( "Field's type" )}
                onChange={ ChangeType }
                on={ ChangeType }
            />
            {
                ["select", "radio", "checkbox", "tags"].filter(typ => { 
                    return typ.toString() === type.toString()
                }).length > 0
                    ?
                    <FieldInput
                        value={values}
                        type="string" 
                        title={__( "Variants of answers" )}
                        commentary={__("per slash")}
                        onChange={ ChangeValues }
                        on={ ChangeValues }
                    /> 
                    :
                    null
            }
            {
                moduleExists("pe-yandex-forms-module")
                    ?
                    <FieldInput 
                        value={yandex_form_field}
                        type="yandexFormsFields"
                        form_fields={{
                            type,
                            count,
                            description,
                            name
                        }}
                        kind= "array" 
                        editable={true}
                        original={props}
                        visualization="yandexFormsFields"
                        title={__( "Signature of correspondence of additional fields of Festival Participants and API Yandex Forms" )}
                        description={__("In the fields, you must insert the exact and complete wording of the question from the Yandex form, which corresponds to the specified field of the command. Be careful: the wording of the question must be copied and pasted without unnecessary characters and without forgetting more than one existing one.")}
                        onChange={ changeYandex_form_field }
                        on={ changeYandex_form_field }
                        onSubmenu={changeYFSubmenu}
                    />
                    :
                    null
            }            
        </Collapse>
        
        <div className="d-100 d-flex justify-content-end pt-2">
            <ButtonGroup small="true" minimal>
                <Button onClick={() => props.onSwitchUp(props.i)}      disabled={props.disabledUp}     icon="caret-up" />
                <Button onClick={() => props.onSwitchDown(props.i)}    disabled={props.disabledDown}   icon="caret-down" />
                <Button onClick={() => props.onRemove(props.i)}        icon="minus"/>
            </ButtonGroup>
        </div>
        
        
    </Card>
}
export default SingleFormField
export const getFormFieldTypes = () =>
{
    return [
        {
            _id:"string",
            title:"String",
            type: "text"
        },
        {
            _id:"number",
            title:"Number",
            type: "number"
        }, 
        {
            _id:"url",
            title:"Link",
            type: "text"
        },
        {
            _id:"email",
            title:"E-mail",
            type: "email"
        }, 
        {
            _id:"media",
            title:"Media",
            type: "media"
        }, 
        {
            _id:"tags",
            title:"Tags",
            type: "tags"
        }, 
        {
            _id:"radio",
            title:"Radio",
            type: "radio"
        }, 
        {
            _id:"checkbox",
            title:"Check boxes",
            type: "checkbox"
        }, 
        {
            _id:"select",
            title:"Select",
            type: "select"
        }, 
        {
            _id:"geo",
            title:"Geo position",
            type: "geo"
        }, 
        {
            _id:"phone",
            title:"Phone",
            type: "phone"
        }, 
        {
            _id:"boolean",
            title:"Yes|No",
            type: "boolean"
        }, 
        {
            _id:"color",
            title:"Color",
            type: "color"
        }, 
        {
            _id:"upload",
            title:"Upload",
            type: "upload"
        }, 
        {
            _id:"slider",
            title:"Slider",
            type: "slider"
        }, 
        {
            _id:"RussianRegions",
            title:"Russian regions",
            type: "RussianRegions"
        }
    ]
}
