import React, { Component } from "react"
import { Link } from "react-router-dom"
import { __ } from "react-pe-utilities"

class FreeNavLinkWidget extends Component {
  render() {
    const url = this.props.preroute
      ?
      `${this.props.preroute}/${this.props.link_route}`
      :
      this.props.link_route
        ?
        `${this.props.link_route}`
        :
        "/"
    return (
      <Link
        to={url} 
        className={this.props.className}
        activeClassName={this.props.activeClassName || "active"}
        exact
        strict
      >
        {this.props.content}
        {__(this.props.label)}
      </Link>
    )
  }
}
export default FreeNavLinkWidget
