import React from "react"
import ReactDOM from "react-dom"
import $ from "jquery" 
import { __ } from "react-pe-utilities" 
import SectionContent from "./SectionContent"
import { AppToaster } from 'react-pe-useful'
import { Intent } from "@blueprintjs/core" 

class Video extends SectionContent {
  is() {
    const { id, vk_video_iframe} = this.state.data
    return id || vk_video_iframe
  }

  componentDidMount() {     
	  //document.body.addEventListener("click", this.onMouseLeaveHandler)
	}

	// componentWillUnmount() {
	//   document.body.removeEventListener("click", this.onMouseLeaveHandler)
	// }

  // onMouseLeaveHandler = (e) => {
	//   const domNode = ReactDOM.findDOMNode(this)
	//   if (!domNode || !domNode.contains(e.target)) {
	//     this.setState({
	//       is_play: false,
	//       height: 0,
	//     })
	//   }
	// }

  didUpdate(state, nextProps, nextState) 
  {
    //console.log( nextProps );
    const st = { ...state }
    const { id, thumbnail } = this.state.data
    const video_id = id !== nextProps.data.id
      ?
      nextProps.data.id
      :
      id
    const src = `https://i1.ytimg.com/vi/${video_id}/maxresdefault.jpg`
    //console.log( this.state.url )
    if (src === this.state.url) {
      return st
    }
    const img = new Image()
    img.onload = (evt) => {
      //console.log( img, img.height )
      if ( img.height < 100 && !thumbnail) 
      {
        // console.log( img, img.height )
        // this.setState({url: `https://img.youtube.com/vi/${video_id}/0.jpg`})
        const domNode = ReactDOM.findDOMNode(this) 
        this.hh = $(domNode).find(".landing-video-thumbnail")
        this.hh.css({ backgroundImage: `url(https://img.youtube.com/vi/${video_id}/0.jpg)` })
      }
    }
    img.src = src
    st.url = src
    st.is_play = nextProps.data.autoplay
    return st
  }

  getState() {
    const {
      id, action_youtube_id, autoplay,
    } = this.props.data
    const video_id = action_youtube_id || id
    const img = new Image()
    let src = `https://i1.ytimg.com/vi/${video_id}/maxresdefault.jpg`
    img.src = src
    img.onload = () => {
      //console.log( img )
      if (img.height < 100) 
      {
        //this.state.url = `https://img.youtube.com/vi/${video_id}/0.jpg`
        // src = `https://img.youtube.com/vi/${video_id}/0.jpg`
        this.setState({url: `https://img.youtube.com/vi/${video_id}/0.jpg`}) 
      }
    }
    return {
      url: src,
      is_play: autoplay,
    }
  }

  renderContent(style) {
    // console.log( this.state.data );
    const {
      class_name,
      id,
      action_youtube_id,
      vk_video_iframe,
      service, 
      width,
      height,
      border_radius,
      box_shadow,
      thumbnail,
      thumbnail_image_vertical,
      exact_value,
      is_contrast_muar

    } = this.props.data
    let video
    const video_id = action_youtube_id || id
    // console.log( this.props.data) 
    const borderRadius = border_radius || 0
    const boxShadow = box_shadow && Array.isArray(box_shadow)
      ? box_shadow.map((e) => (e
        ? (e.inset ? "inset " : "")
        + (e.horizontal ? `${e.horizontal}px ` : "0 ")
        + (e.vertical ? `${e.vertical}px ` : "0 ")
        + (e.blur ? `${e.blur}px ` : "0 ")
        + (e.spread ? `${e.spread}px ` : "0 ")
        + (e.color ? e.color : "")
        : "")).join(", ")
      : "mone"
    const clipPath = `inset(0px 0px 0px 0px round ${borderRadius}px)`

    switch (service) {
      case "vimeo":
        video = (
          <iframe
            src={`https://player.vimeo.com/video/${video_id}`}
            width={width || "100%"}
            height={ parseInt(height) || "100%" }
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{ ...style, clipPath }}
            className={class_name}
          />
        )
        break
      case "vk":
        const thumbVK = thumbnail ? thumbnail : this.state.url
        const muarVK = is_contrast_muar ? " muar" : ""
        let thumb_image_vertVK = { backgroundPosition:"center"}
        switch(thumbnail_image_vertical)
        {
          case "top":
          case "bottom":
          case "center":
            thumb_image_vertVK = { backgroundPositionY:thumbnail_image_vertical}
            break
          default:
          case "exact_value":
            thumb_image_vertVK = { backgroundPositionY:`${exact_value}%`}
            break
        }
        video = this.state.is_play
          ?
          <div className="vk-video-cont" style={{ width:width || "100%", height: parseInt(height) }}>
            { getVKLink( vk_video_iframe ) }
          </div>
          :
          <div
            className={"landing-video-thumbnail " + muarVK}
            onClick={this.play}
            style={{
              backgroundImage: `url(${thumbVK})`,
              width: parseInt(width) ? parseInt(width) : width,
              height: parseInt(height),
              clipPath,
              ...thumb_image_vertVK
            }}
          >
            <div className="lvt-title mt-auto">
              <span className="fas fa-play fa-1x p-3" />
            </div>
            <div className="landing-video-title">
              <div>
                {this.props.data.title}
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.description }} />
            </div>
          </div>
        break
      default:
      case "youtube" :
        const pars = [];
        ["controls", "showinfo", "modestbranding", "mute"].forEach((e, i) => {
          pars.push(`${e}=${this.props.data[e] ? 1 : 0}`)
        })
        const params = pars.length > 0
          ?
          `?${pars.join("&")}&autoplay=1`
          :
          "?autoplay=1"
        const thumb = thumbnail ? thumbnail : this.state.url
        const muar = is_contrast_muar ? " muar" : ""
        let thumb_image_vert = { backgroundPosition:"center"}
        switch(thumbnail_image_vertical)
        {
          case "top":
          case "bottom":
          case "center":
            thumb_image_vert = { backgroundPositionY:thumbnail_image_vertical}
            break
          default:
          case "exact_value":
            thumb_image_vert = { backgroundPositionY:`${exact_value}%`}
            break
        }
        // console.log( thumb, this.state.is_play);
        video = this.state.is_play
          ?
          <iframe
            title={params}
            width={ width || "100%" }
            height={ !isNaN( parseInt( height ) ) ? parseInt( height ) : "100%" }
            className={ class_name }
            style={{ ...style, clipPath }}
            src={ `https://www.youtube.com/embed/${video_id}${params}` }
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          :
          <div
            className={"landing-video-thumbnail " + muar}
            onClick={this.play}
            style={{
              backgroundImage: `url(${thumb})`,
              width: parseInt(width) ? parseInt(width) : width,
              height: parseInt(height),
              clipPath,
              ...thumb_image_vert
            }}
          >
            <div className="lvt-title mt-auto">
              <span className="fas fa-play fa-1x p-3" />
            </div>
            <div className="landing-video-title">
              <div>
                {this.props.data.title}
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.description }} />
            </div>
          </div>
        break
    }
    return <div
      className={`landing-video ${this.props.data.class_name}`}
      style={{
        ...this.props.data.style, height, width, borderRadius, boxShadow,
      }}
    >
      {video}
    </div>
  }

  onVimeoError = (err) => {
    console.error(err)
  };

  play = () => {
    this.setState({ is_play: true })
  }
}
export default Video

export function getVideoThumbnail(props) {
  switch (props.source) {
    case "youtube":
    default:
      return `https://img.youtube.com/vi/${props.video_id}/0.jpg`
     // return `https://i1.ytimg.com/vi/${props.video_id}/maxresdefault.jpg`
  }
}

export const getVKLink = (text="") =>
{
  // console.log( text )
  const tx = text.replaceAll("«", "'").replaceAll("»", "'")
  var regex = /oid=-([\d]+)&id=(\d+)&hash=(\w+)/gi
  var regex2 = /oid=([\d]+)&id=(\d+)&hash=(\w+)/i
  // console.log(tx, regex2.exec( tx ) )
  if(tx?.indexOf(`<iframe`) === 0 )
  {
    try
    {
      let ds =  regex.exec( tx )
      if(!Array.isArray(ds))
      {
        ds = regex2.exec( tx )
        
      return <iframe 
      title={ ds.join(".").toString() }
      src={`https://vk.com/video_ext.php?oid=${ds[1]}&id=${ds[2]}&hash=${ds[3]}&autoplay=1`}
      width="100%" 
      height="100%" 
      frameBorder="0" 
      allowFullScreen="1"  
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
    />
      }
      return <iframe 
        src={`https://vk.com/video_ext.php?oid=-${ds[1]}&id=${ds[2]}&hash=${ds[3]}&autoplay=1`}
        width="100%" 
        height="100%" 
        frameBorder="0" 
        allowFullScreen="1"  
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
      />
    }
    catch(err)
    {
      console.error(err)
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'tick',
        duration: 10000,
        message: __('Uncorrect link') ,
      })
    }
  }
  else{
    AppToaster.show({
      intent: Intent.DANGER,
      icon: 'tick',
      duration: 10000,
      message: __('Uncorrect link. Go to VK.com and copy "Embed code"'),
    })
  }
}
