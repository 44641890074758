import React from "react" 
import VideoPlayList from "../LandingState/VideoPlayList"
import "./assets/css/style.css"
import SectionContent from "../LandingState/SectionContent"
import DataContext from "../LandingState/DataContext" 
import { compose} from "recompose"
import { withRouter } from "react-router"

class KraevedVideoPlayList extends SectionContent 
{
    is()
    {
        return true
    }
    getAllVideos = () => {
        let getAllVideos = this.getAllByLevel(null, "video")
        // console.log(getAllVideos) 
        return getAllVideos
    }
    getAllByLevel = (nestSection = null, sectionType = "video") =>
    {
        let ret = [];
        const searchSection = nestSection ? nestSection : DataContext
        if( searchSection.type === sectionType )
        {
            // console.log( searchSection )
            ret.push({
                service:        searchSection.data.service,
                id:             searchSection.data.id,
                vk_video_iframe: searchSection.data.vk_video_iframe,
                title:          searchSection.data.title,
                description:    searchSection.data.description,
                video_group:    searchSection.data.video_group,
                author_name:    searchSection.data.author_name,
                controls:       true,
                autoplay:       true
            })
        }

        // поиск по полям суб-контейнеров
        if( Array.isArray( searchSection.section ))
        {
            searchSection.section.forEach( section => {
                ret = [...ret, ...this.getAllByLevel(section, sectionType) ]
            })
        }

        // если тип может веючать в себя секции непосредственно
        if( DataContext.containerTypes.filter(t => t === searchSection.type || searchSection === DataContext ).length > 0 )
        {
            //console.log( searchSection.type, searchSection )
            // поиск по контейнерам по имени поял secttions
            if( Array.isArray( searchSection.data.sections ))
            {
                searchSection.data.sections.forEach( section => {
                    ret = [...ret, ...this.getAllByLevel(section, sectionType) ]
                })
            }
            // поиск по контейнерам по имени поля secttion
            if( Array.isArray( searchSection.data.section ))
            {
                searchSection.data.section.forEach( section => {
                    ret = [...ret, ...this.getAllByLevel(section, sectionType) ]
                })
            }            
        }

        // если тип может велючать в себя типы-контейнеры
        let dt = {}
        if( 
            DataContext.subContainerTypes.filter(t => {
                if(t.type === searchSection.type) dt = t  
                return t.type === searchSection.type 
            }).length > 0 )
        {
            searchSection.data[ dt.field ].forEach( section => {
                
                ret = [...ret, ...this.getAllByLevel(section, sectionType) ]
            })
        }

        // отдельная мстория - Карточки
        if( searchSection.type  === "cards")
        {
            if( Array.isArray(searchSection.data.cards ))
            {
                // console.log( searchSection.data )
                // console.log( searchSection.data.cards.filter( card => card.dialog_content_type === "video" ) )
                searchSection.data.cards
                    .filter( card => card.dialog_content_type === "video" )
                        .map( card => {

                            // ищем в шаблоне Карточки первое поле картинок (тип - media)
                            const thums = card.fields.filter((field, i) => 
                            {
                                return searchSection.data?.fields[i]?.type === "media"
                            })[0]
                            
                            // Thumbnail   
                            const thumbnail = thums
                                ?
                                thums.field
                                :
                                null
                            
                            ret = [
                                ...ret, 
                                {
                                    service:        card.dialog_video_service,
                                    id:             card.dialog_youtube_id,
                                    vk_video_iframe: card.dialog_VK_frame,
                                    title:          card.dialog_title ? card.dialog_title : searchSection.title.text ,
                                    description:    card.dialog_description,
                                    author_name:    card.video_author_name,
                                    video_group:    card.video_group,
                                    controls:       true, 
                                    thumbnail:      thumbnail
                                }
                            ]
                            return
                        } )
                
            }  
        }
        return ret
    } 
    renderContent(style) 
    { 
        const video = this.getAllVideos()
        return <VideoPlayList
            {...this.props}
            data={{ ...this.props.data, video:video }}
            
        />
    }
}
export default compose(withRouter)(KraevedVideoPlayList)