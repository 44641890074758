import React, { Component, Fragment } from "react"
import { __ } from "react-pe-utilities" 
import {
  Button, ButtonGroup, Intent, Popover, Dialog, Icon, Tooltip, Position, Callout,
} from "@blueprintjs/core"
import { withRouter } from "react-router"
import gql from "graphql-tag"
import { withApollo } from "react-apollo"
import { compose } from "recompose"
import $ from "jquery"
import {
  DateInput, IDateFormatProps, TimePrecision, DateTimePicker,
} from "@blueprintjs/datetime"
import Moment from "react-moment"
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment"
import "react-day-picker/lib/style.css"
import DayPicker from "react-day-picker"
import moment from "moment"
import { AppToaster } from 'react-pe-useful'
import {MediaChooser} from "react-pe-useful" 
import SectionContent from "./SectionContent"
import "moment/locale/ru"
import Style from "style-it"

class ContactForm extends SectionContent {
  getState() {
    this.ref = React.createRef()
    return {
      values: [],
      message: "",
      height: 200,
      isOpen: false,
    }
  }

  is() {
    const { forms } = this.state.data
    return Array.isArray(forms) && forms.length > 0
  }

  renderContent() { 
    return this.getAsContent()
  } 

  getAsContent() { 
    const {
      class_name, style, forms, label, text_under_form, thumbnail
    } = this.props.data

    const _thumbnail = thumbnail
        ? 
        <div className="thumbnail" data-type="thumbnail" style={{backgroundImage: `url(${thumbnail})`}}/> 
        :	
        <div className="" data-type="thumbnail"></div>	

    return this.state.message
      ?	 
      <div className="w-100 " style={{ ...style }}>
        <div className="landing-contact-form  landing-section-core " style={{ minHeight: this.state.height }}>
          <span className=" cf-message ">
            { __(this.state.message) }
          </span>
        </div>
      </div> 
      :
      Style.it(
        `
        .landing-contact-form
        {
          grid-template-columns: ${thumbnail ? "1" : "0.0"}fr 1fr;
        }
        
        `,
        <div className="w-100" style={{ ...style }}>
          <div className="landing-contact-form landing-section-core " style={{ minHeight: this.state.height }} ref={this.ref}>
            {_thumbnail}
            <div className="landing-cf-forms">
              {this.getForms()}
            </div>
            
            <div className="landing-cf-send-btn-cont">
              <div
                className="send-button"
                onClick={this.onSend}
              >
                {__(label)}
              </div>
            </div>
            <div className="text-under-form">
              {text_under_form}
            </div>
          </div>
        </div>
      )
      

  }

  getForms() {
    const { forms } = this.props.data
    const jsDateFormatter = {
      // note that the native implementation of Date functions differs between browsers
      formatDate: (date) => moment(date).format("D.MM.YYYY HH:mm"),
      // parseDate: str => new Date(str),
      parseDate: (str) => new Date(Date.parse(str)),
      placeholder: "M/D/YYYY",
    }
    // console.log(this.props.data.forms);
    
    return forms.map((e, i) => {
      
      const label = e.label
        ?
        <div className="title" data-type="title">
          {__(e.label)}
        </div>
        :				
        <div className="" data-type="title"></div>
      const description = e.description
        ?
        <div className="description" data-type="description">
          {__(e.description)}
        </div>
        :				
        <div className="" data-type="description"></div>
      let input
      // console.log( e.type );
      switch (e.type) {
        case "email":
          input = (
            <div>
              <input
                type="email"
                className="form-control my-2"
                name={e.label}
                placeholder={e.placeholder}
                value={this.state.values[i]}
                onChange={(evt) => this.onValue(evt, i)}
              />
            </div>
          )
          break 
        case "text":
          const rows = this.props.data.forms[i].rows
          input = <div>
              <textarea 
                className="form-control my-2 w-100"
                name={e.label}
                placeholder={e.placeholder}
                value={this.state.values[i]}
                onChange={(evt) => this.onValue(evt, i)}
                rows={rows ? rows : 6}
              >
              </textarea>
            </div>    
          break
        case "phone":
          input = (
            <div>
              <input
                type="phone"
                className="form-control my-2"
                name={e.label}
                placeholder={e.placeholder}
                value={this.state.values[i]}
                onChange={(evt) => this.onValue(evt, i)}
              />
            </div>
          )
          break
        case "file_loader":
          input = (
            <div>
              <MediaChooser
                prefix={`_${e.label}`}
                url={e.value}
                id={`mc_${i}`}
                ID={`mc_${i}`}
                padding={5}
                height={140}
                placeholder={e.placeholder}
                onChange={(value, file) => this.onMediaChange(value, file, i)}
              />
            </div>
          )
          break
        case "time":
          input = (
            <div>
              <DateInput
                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                {...jsDateFormatter}
                className={" " + ""}
                closeOnSelection
                date={this.state.values[i]}
                defaultValue={new Date()}
                onChange={(value) => this.handleStartChange(value, i)}
                invalidDateMessage={__("Invalid date")}
                timePrecision={TimePrecision.MINUTE}
                timePickerProps={{ showArrowButtons: true }}
                placeholder={e.placeholder}
              />
            </div>
          )
          break
        case "calendar":
          input = (
            <div>
              <DateTimePicker
                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                {...jsDateFormatter}
                className={" " + ""}
                closeOnSelection
                date={this.state.values[i]}
                defaultValue={new Date()}
                onChange={(value) => this.handleStartChange(value, i)}
                invalidDateMessage={__("Invalid date")}
                timePrecision={TimePrecision.MINUTE}
                timePickerProps={{ showArrowButtons: true }}
                placeholder={e.placeholder}
              />
            </div>
          )
          break
        case "radio":
          // console.log(this.state.values[i], e.label);
          const variants = this.props.data.forms[i].data
            .map((e, ii) => (
              <div key={ii}>
                <label htmlFor={`cf-radio${this.props.section_id}_${ii}`} className="landing-ch-radio-label">
                  <input
                    type="radio"
                    className=""
                    ii={ii}
                    i={i}
                    id={`cf-radio${this.props.section_id}_${ii}`}
                    checked={this.state.values[i] == e.label}
                    onChange={this.onRadioChange}
                  />
                  <span>{e.label}</span>
                </label>
              </div>
            ))
          input = (
            <div className="landing-ch-radio-container">
              {variants}
            </div>
          )
          break
        case "string":
        default:
          input = (
            <div>
              <input
                type="text"
                className="form-control my-2"
                name={e.label}
                placeholder={e.placeholder}
                onChange={(evt) => this.onValue(evt, i)}
              />
            </div>
          )
      }
      return (
        <div key={i} className="landing-cf-single-form">
          { label } 
          <div className={`req_input ${e.is_required ? "required" : ""}`}>
            { input }
            {
						e.is_required
						  ? 
              <span className="req">
                { __("required field") }
              </span> 
						  :							
              null
					}
          </div>
          { description }
        </div>
      )
    })
  }

	onRadioChange = (evt) => {
	  const i = evt.currentTarget.getAttribute("i")
	  const ii = evt.currentTarget.getAttribute("ii")
	  const cheched = evt.currentTarget.checked
	  const value = this.props.data.forms[i].data[ii].label
	  const values = [...this.state.values]
	  values[i] = value
	  console.log(value, i, values)
	  this.setState({ values })
	}

	onValue(evt, i) {
	  const { value } = evt.currentTarget
	  const values = [...this.state.values]
	  values[i] = value
	  // console.log( value, i , values );
	  this.setState({ values })
	}

	onMediaChange = (value, file, i) => {
	  const values = [...this.state.values]
	  values[i] = value
	  this.setState({ values })
	}

	handleStartChange = (value, i) => {
	  const values = [...this.state.values]
	  values[i] = value
	  this.setState({ values })
	  // const state = { ...this.state, values };
	  // this.on( moment( value ).toISOString() );
	}

	onSend = () => {
	  const values = [...this.state.values]
	  // console.log( values );
	  const { forms, toast_text } = this.state.data
	  if (values.filter((e) => e != "" || typeof e != "undefined").length == 0) {
	    AppToaster.show({
	      intent: Intent.DANGER,
	      icon: "tick",
	      message: __("Form is empty."),
	    })
	    return
	  }
	  const req = forms
	    .filter((e, i) => e.is_required && (values[i] == "" || typeof values[i] == "undefined"))
	      .map((e) => e.label)
	  // console.log( values, req );
	  if (req.length > 0) 
    {
	    AppToaster.show({
	      intent: Intent.DANGER,
	      icon: "tick",
	      duration: 10000,
	      message: __("Some required are empty: ") + req.join(", "),
	    })
	    return
	  }
	  // sending
	  const message = []
	  forms.forEach((e, i) => {
	    message.push({
	      ...e,
	      i,
	      value: values[i],
	    })
	  })
	  const message_json 	= (JSON.stringify(message)).replace(/"/g, "'")
	  const matrix_json 	= (JSON.stringify(forms)).replace(/"/g, "'")

	  const mutation = gql`
			mutation sendPELandingContactFormMessage
			{
				sendPELandingContactFormMessage( input: 
					{
						message_json 	: "${message_json}",
						matrix_json 	: "${matrix_json}"
					} 
				)
			}`
	  this.props.client.mutate({
	    mutation,
	    update: (store, { data }) => {
	      this.setState({
	        values: [],
	        message: __(toast_text),
	        height: this.ref.current.getBoundingClientRect().height,
	      })
        AppToaster.show({
          intent: Intent.SUCCESS,
          icon: "tick",
          duration: 10000,
          message: __("Your message sent successfully") ,
        })
	    },
	  })
	}
}
export default compose(
  withApollo,
  withRouter,
)(ContactForm)
