const CONTAINER_TYPES = () => {
    return [
        {
            title: "Full width",
            className: "container-fluid"
        },
        {
            title: "Long block",
            className: "container"
        },
        {
            title: "Short block",
            className: "short-container"
        },
    ]
}
export default CONTAINER_TYPES