import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DataContext from '../DataContext'
import OwlCarousel from 'react-owl-carousel3';  
import Style from "style-it"
import { useRef } from 'react';
import { Button } from '@blueprintjs/core'; 
import LandingView from '../../LandingView';

const CarouselFlow = props => 
{
    const onKeyUp = evt =>
	{
        // console.log(evt.code)
		if(evt.code === "Space" || evt.code === "ArrowRight" || evt.code === "ArrowDown")
        {
            next()
        }
        if(evt.code === "ArrowLeft" || evt.code === "ArrowUp")
        {
            prev()
        }
	}
    const mousewheel = evt =>
    { 
        if(evt.deltaY > 0)
        {             
            next()         
        }            
        else
        {
            prev()
        }
    }
    const [ currentSlide,  setCurrentSlide]  = useState(0)
    const [ animateIn,  setAnimateIn]  = useState(null)
    const [ animateOut, setAnimateOut] = useState(null)
    useEffect(() => {
        document.body.addEventListener("keyup", onKeyUp)
        window.addEventListener("mousewheel", mousewheel)      
      return () => {
        document.body.removeEventListener("keyup", onKeyUp)
        window.removeEventListener("mousewheel", mousewheel)
      }
    }, [])
    
    const owlRef = useRef()
    const options = {
        //center: true,
        dots: true,
        dotsEach : 1, 
        navText:[],     
        animateIn: animateIn,   //'slideInUp',
        animateOut: animateOut, //'slideOutUp',
        items: 1,
        nav: false,
        rewind: false,
        autoplay: false,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
  
      }
    const onAnimatePrev = ( inClassName, outClassName ) =>
    {
        setAnimateIn( inClassName )
        setAnimateOut( outClassName )
    }
    const onAnimateNext = ( inClassName, outClassName ) =>
    {
        setAnimateIn( inClassName )
        setAnimateOut( outClassName )
    }
    const next = () =>
    {
        if( LandingView.isLockScroll ) return
        //if( currentSlide > props.children.length - 1 ) return        
        //const page = currentSlide + 1
        // console.log( page, owlRef.current )
        //setCurrentSlide( page )
        owlRef.current.next( 300 )
        //owlRef.current.to( page, 300 )
        // onAnimatePrev('slideInUp', 'fadeOutDown')
    }
    const prev = () =>
    {
        if( LandingView.isLockScroll ) return
        //if( currentSlide < 1 ) return 
        //const page = currentSlide - 1
        //console.log( page )
        //setCurrentSlide( page, owlRef.current )
        owlRef.current.prev( 300 )
        //owlRef.current.to( page, 300 )
        // onAnimateNext('slideInDown', 'fadeOutUp') 
    }
    switch(DataContext.data.landing.carousel_flow)
    { 
        case "vertical_slide":
            return "VerticalCarousel" 
        case "horizontal_slide":
        default:
            return Style.it(
                ``,
                <div className={` ${ props.containerClassName } `}  style={props.containerStyle}>
                    <div className='p-3 m-5 bg-danger text-light lead title position-absolute top left'>
                        {currentSlide}
                    </div>
                    <OwlCarousel 
                        className="owl-theme"
                        ref={ owlRef }
                        {...options}
                    >
                    { 
                        props.children.map((child, i) => {
                            return <div className='flex-centerd w-100 h-100' key={i}>
                                { child }
                            </div>
                        }) 
                    }
                    </OwlCarousel>
                    <div className="position-absolute top right z-index-100 d-flex m-3">
                        <Button
                            className='p-2 mr-1'
                            icon="caret-left"
                            onClick={prev}
                        > 
                        </Button>
                        <Button
                            className='p-2'
                            icon="caret-right"
                            onClick={next}
                        > 
                        </Button>
                    </div>
                </div>
            )
    }
}

CarouselFlow.propTypes = {}

export default CarouselFlow