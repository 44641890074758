import React from "react"
import { Dialog, Intent } from "@blueprintjs/core"
import gql from "graphql-tag"
import { withApollo, Mutation } from "react-apollo"
import { compose } from "recompose"
import { withRouter } from "react-router" 
import { AppToaster } from 'react-pe-useful'
import FieldInput from "react-pe-scalars"
import BasicState from "react-pe-basic-view"
import { __, initArea} from "react-pe-utilities"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import {LayoutIcon} from 'react-pe-useful'

class ProfileView extends BasicState {
  basic_state_data() {
    return { form: this.props.user ? this.props.user : {} }
  }

  addRender() {
    const mutation = gql`
      mutation changeCurrentUser($input: UserInput) {
        changeCurrentUser(input: $input)
      }
    `
    return (
      <>
        <Mutation mutation={mutation}>
          {(m_change) => {
            // console.log(this.props.user);
            console.log(this.state)
            return (
              <div className=" mt-5">
                <div className="tutor-row">
                  <div className="tutor-left-aside mobile-relatived">
                    {
                      initArea(
                        "user-favorites-left-aside",
                        { ...this.props },
                        <CircularProgressbarWithChildren
                          value={81}
                          styles={{
                            // Customize the root svg element
                            root: {},
                            // Customize the path, i.e. the "completed progress"
                            path: {
                              // Path color
                              stroke: "#175586",
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: "butt",
                              // Customize transition animation
                              transition: "stroke-dashoffset 0.5s ease 0s",
                              // Rotate the path
                              transform: "rotate(0.25turn)",
                              transformOrigin: "center center",
                            },
                            // Customize the circle behind the path, i.e. the "total progress"
                            trail: {
                              // Trail color
                              stroke: "#17558600",
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: "butt",
                              // Rotate the trail
                              transform: "rotate(0.25turn)",
                              transformOrigin: "center center",
                            },
                            // Customize the text
                            text: {
                              // Text color
                              fill: "#f88",
                              // Text size
                              fontSize: "16px",
                            },
                            // Customize background - only used when the `background` prop is true
                            background: {
                              fill: "#3e98c700",
                            },
                          }}
                        >
                          <LayoutIcon
                            src="assets/img/user1.svg"
                            className="profile-ava"
                          />
                        </CircularProgressbarWithChildren>
                      )
                    }
                  </div>
                  <div className="tutor-main">
                    <div className=" p-0">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault()
                          this.onChange(m_change)
                        }}
                      >
                        <FieldInput
                          title="Имя"
                          type="string"
                          field="first_name"
                          editable
                          value={this.state.form.first_name}
                          onChange={this.onFirstName}
                        />
                        <FieldInput
                          title="Фамилия"
                          type="string"
                          field="last_name"
                          editable
                          value={this.state.form.last_name}
                          onChange={this.SecondName}
                        />
                        <FieldInput
                          title="E-mail"
                          type="email"
                          field="user_email"
                          editable
                          value={this.state.form.user_email}
                          onChange={this.onEmail}
                        />
                        <FieldInput
                          title="Телефон для связи"
                          type="phone"
                          field="phone"
                          editable
                          value={this.state.form.phone}
                          onChange={this.onPhone}
                        />
                        <FieldInput
                          title="Новый пароль"
                          type="password"
                          field="password"
                          editable
                          value={this.state.form.password}
                          onChange={this.onPassword}
                        />

                        <div className="row">
                          <div className="col-md-7 offset-md-3 mt-5 text-center">
                            <input
                              type="submit"
                              className="btn btn-secondary my-3"
                              value={__("Сохранить")}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tutor-right-aside">
                  {initArea("user-favorites-right-aside",
                    { ...this.props })}
                </div>
              </div>
            )
          }}
        </Mutation>
        <Dialog
          isOpen={this.state.isOpen}
          onClose={this.handleToggle}
          title={__("Attention")}
        >
          <div className="px-5 pb-5">
            {__("Instructions have been sent to your old email address for changing the «email address» field. Changes will take effect only after the verification procedure is completed.")}
          </div>
        </Dialog>
      </>
    )
  }

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onFirstName = (e) => {
    this.onField(e, "first_name")
    console.log(e)
  };

  SecondName = (e) => {
    this.onField(e, "last_name")
  };

  onEmail = (e) => {
    this.onField(e, "user_email")
  };

  onPhone = (e) => {
    this.onField(e, "phone")
  };

  onPassword = (e) => {
    this.onField(e, "password")
  };

  onField = (e, name) => {
    this.state.form[name] = e
    this.setState({ form: { ...this.state.form } })
  };

  onChange = (m_change) => {
    const form = { ...this.state.form }
    delete form.current_course
    delete form.payments
    delete form.display_name
    delete form.id
    delete form.__typename
    delete form.roles
    // delete form.user_email;
    m_change({
      variables: {
        /*
      input: {
        user_email: this.state.form.user_login,
        display_name: this.state.form.display_name + " " + this.state.form.second_name,
        password: this.state.form.password,
      }
      */
        input: form,
      },
      update: (store, { data: { changeCurrentUser } }) => {
        switch (changeCurrentUser) {
          case 2:
            this.setState({ isOpen: true })
            break
          case 1:
          default:
            AppToaster.show({
              intent: Intent.SUCCESS,
              icon: "tick",
              message: __("Profile was updated"),
            })
            break
        }
      },
    })
  };
}
export default compose(withApollo, withRouter)(ProfileView)
