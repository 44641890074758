import { Button, Intent } from "@blueprintjs/core"
import React from "react"

const AlignPosition = props =>
{
    const [value, setValue] = React.useState(props.value)
    const onClick = position =>
    {
        setValue(position)
        props.on( position )
    }
    return <div className="">
        <div className="">
            <Button large intent={value === "top-left" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("top-left")} />
            <Button large intent={value === "top-center" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("top-center")} />
            <Button large intent={value === "top-right" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("top-right")} />
        </div>
        <div className="">
            <Button large intent={value === "center-left" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("center-left")} />
            <Button large minimal disabled />
            <Button large intent={value === "center-right" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("center-right")} />
        </div>
        <div className="">
            <Button large intent={value === "bottom-left" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("bottom-left")} />
            <Button large intent={value === "bottom-center" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("bottom-center")} />
            <Button large intent={value === "bottom-right" ? Intent.SUCCESS : Intent.NONE} onClick={ () => onClick("bottom-right")} />
        </div>
    </div>
}
export default AlignPosition