import React from "react";
import VK, { Playlist } from "react-vk";

const _PlayList = ({ api_key, owner_id, playlist_id, hash }) => {
    try {
        return <>
            <div className="landing-vp-poll" id={"vk_playlist_" + owner_id + playlist_id} />
            <VK apiId={api_key} >
                <Playlist
                    elementId={"vk_playlist_" + owner_id + playlist_id}
                    ownerId={owner_id}
                    playlistId={playlist_id}
                    hash={hash}
                />
            </VK>
        </>
    } catch (e) {
        return e.message
    }
}
export default _PlayList