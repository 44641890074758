import { Button, Drawer, Intent, Position } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"
import { __ } from "react-pe-utilities/dist"
import SectionLayerList from "./section-handler/SectionLayerList"

const SectionHandler = props =>
{
    const [isDialogOpen, setDialogOpen] = useState(false)

    const onDialogOpen = () =>
    {
        setDialogOpen(!isDialogOpen)
    } 
    return <>
        <Button
            intent={Intent.NONE}
            onClick={ onDialogOpen }
            large={false}
            icon="layers"
            className="mr-1 hint hint--bottom"
            data-hint={__("Sections")} 
            minimal
        > 
        </Button>
        <Drawer
            isOpen={ isDialogOpen}
            onClose={ onDialogOpen}
            className="little3 drawer landing-section-drawer"
            title={__("Sections")}
            position={Position.RIGHT}
            usePortal
            size={320}
            hasBackdrop={false}
            canOutsideClickClose={true}
            backdropClassName="landing-drawer-bg"
        >
            <SectionLayerList

            />
        </Drawer>
    </>
}
export default SectionHandler