import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'

const YoutubeVideoPlayer = props => {
    const [value, setValue] = useState( "" )
    const [isPlay, setPlay] = useState( true )
    const play = () =>
    {
        setPlay(true)
    }
    const stop = () =>
    {
        setPlay(false)
    }
    const onChange = evt =>
    {
        const value = evt.currentTarget.value
        const id = youtubeURLParse( value )
        setValue( id )
        props.onChange( id )
    }
    const getThumbnail = () =>
    {
        return `https://img.youtube.com/vi/${props.video_id}/0.jpg`
    }
    return props.editable
        ?
        <div className='flex-centered w-100' >
            <input 
                type="text"
                className='form-control input dark'
                placeholder={__("Youtube video URL")}
                value={value}
                onChange={onChange}
            />
        </div>
        :
        isPlay
            ?
            <YoutubePlayer 
                class_name=""
                video_id={props.video_id}
                height={props.height}
                width={props.width}
                params={"controls=0&showinfo=0&autoplay=1"}
            />
            :
            <YoutubeCover
                play={play}
                video_id={props.video_id}
                height={props.height}
                width={props.width}
                thumb={ getThumbnail() }
            />
}

YoutubeVideoPlayer.propTypes = {}

export default YoutubeVideoPlayer

const YoutubePlayer = props =>
{ 
    return <iframe 
        width="100%" 
        height="315" 
        src={ `https://www.youtube.com/embed/${props.video_id}` }
        title="YouTube video player" 
        frameBorder="0" 
        allow="autoplay=1" 
        allowFullScreen
    />
}

const YoutubeCover = props =>
{
    return <div
        className={"landing-video-thumbnail " + props.muar}
        onClick={props.play}
        style={{
            backgroundImage: `url(${props.thumb})`,
            width: props.width ? props.width : "100%",
            height: parseInt(props.height),
            clipPath : props.clipPath 
        }}
    > 
        <div className="lvt-title mt-auto">
            <span className="fas fa-play fa-1x p-3" />
        </div> 
    </div>
}

const youtubeURLParse = (url) =>
{
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}