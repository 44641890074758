import React, { useEffect } from "react" 
import { __ } from "react-pe-utilities/dist"
import Style from "style-it"
import "../../assets/css/carousel3d.css"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import pe_fest_logo  from "../../assets/img/pe_FEST_logo.svg"
import Slider from "./home/Slider";

const Home = props => 
{ 
    useEffect(() => {
        
    }, [])
    return Style.it(
    `
    .head
    {
        background-image: url(/assets/pe-wpfest-admin-module/img/children3.jpg);
        background-size: cover;
        background-position:center;
        height:400px;
        padding:0;
        border: 0;
    }
    .feature-1 
    { 
        background-color: #9ec4a9;
    } 
    .feature-1 .thumbn
    {
        background-image: url(/assets/pe-wpfest-admin-module/img/026-art.svg); 
    } 
    .feature-2 
    { 
        background-color:  #ba6fa8;
    } 
    .feature-2 .thumbn
    {
        background-image: url(/assets/pe-wpfest-admin-module/img/009-award.svg);
    } 
    .feature-3 
    { 
        background-color: #93a7d4;
    }  
    .feature-3 .thumbn
    {
        background-image: url(/assets/pe-wpfest-admin-module/img/035-microscope.svg); 
    } 
    .feature-4 
    { 
        background-color: #a39e2f;
    } 
    .feature-4 .thumbn
    {
        background-image: url(/assets/pe-wpfest-admin-module/img/020-rocket.svg); 
    } 
    .feature-5 
    { 
        background-color: #799681;
    } 
    .feature-6 
    { 
        background-color: #c28fb6;
    } 
    .feature-7
    { 
        background-color: #62749e;
    } 
    .feature-8
    { 
        background-color: #c6c6b0;
    } 
    .feature-9
    { 
        background-color: #c9d493;
    } 
    .feature-9-11
    { 
        padding-top:40px;
        background-color: #e5e8da;
        position:relative;
    } 
    .feature-9-11:before
    {
        content: "";
        position: absolute;
        top: 20px;
        left: -4px;
        height: calc(100% - 20px);
        width: 8px;
        background-color: #ffffff;
        border-radius:4px;
    }
    .pe-fest-admin-logo
    {         
        background-image: url(${pe_fest_logo}); 
    }
    `, 
    <div className="py-0"> 
        { /*getSlider()*/ }
        <Slider />
        <div className="wpfest-admin-section row ">
            <div className="col-md-2 col-sm-6 p-0">
                <div className="feature  feature-4">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="thumbn" />
                </div>
                <div className="feature  feature-9">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="position-absolute p-4 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__("Быстро")}
                        </div>
                        <div className="pt-3 reveal-text">
                            {__("Готовый фестиваль за несколько минут. Выбираем готовый шаблон, а потом редактируем по своим нуждам.")}
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-md-2 col-sm-6 p-0">
                <div className="feature  feature-2">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="thumbn" />
                </div>
                <div className="feature  feature-6">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="position-absolute p-4 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__("Статистика")}
                        </div>
                        <div className="pt-3 reveal-text">
                            {__("Свободная система оценки по категориям, которые Вы сами настраиваете. Могофакторная тсатистика по активности участников, экспертов. Выгрузка в один клик.")}
                        </div>
                    </div>
                </div>
            </div> 
            <div className=" col-md-2 col-sm-6  p-0">
                <div className="feature  feature-3">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="thumbn" />
                </div>
                <div className="feature  feature-7">
                    <img src="/assets/img/empty.png" className="pp" /> 
                    <div className="position-absolute p-4 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__("Жанры")}
                        </div>
                        <div className="pt-3 reveal-text">
                            {__("Можно устраивать соревнование проектов в разных жанрах и стилях. Эксперты могут выбирать и фильтровать работы нужного жанра.")}
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-md-2 col-sm-6 p-0">
                <div className="feature  feature-4">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="thumbn" />
                </div>
                <div className="feature  feature-9 ">
                    <div className="w-100">
                        <img src="/assets/img/empty.png" className="pp position-relative" /> 
                    </div>
                                        
                    <div className="position-absolute pt-4 pb-3 px-3 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__("Удобство")}
                        </div>
                        <div className="pt-2 reveal-text">
                            {__("Эксперты в миг оценят проекты участников. Удобный интерфейс позволит быстро  оценить каждую работу. Возможность комментирования позволит организовать связь эксперта с участниками в передаче бесценного опыта.")}
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-md-2 col-sm-6 p-0">
                <div className="feature  feature-1">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="thumbn" />
                </div>
                <div className="feature  feature-5">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="position-absolute p-4 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__("Статистика")}
                        </div>
                        <div className="pt-3 reveal-text">
                            {__("Свободная система оценки по категориям, которые Вы сами настраиваете. Могофакторная тсатистика по активности участников, экспертов. Выгрузка в один клик.")}
                        </div>
                    </div>
                </div>
            </div> 
            <div className="col-md-2 col-sm-6 p-0">
                <div className="feature  feature-2">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="thumbn" />
                </div>
                <div className="feature  feature-6">
                    <img src="/assets/img/empty.png" className="pp" />
                    <div className="position-absolute p-4 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__("Скорость")}
                        </div>
                        <div className="pt-3 reveal-text">
                            {__("Готовый фестиваль за несколько минут. Выбираем готовый шаблон, а потом редактируем по своим нуждам.")}
                        </div>
                    </div>
                </div>
            </div>

            <div className=" col-md-2 col-sm-6 feature-9"> 
                
            </div>
            <div className=" col-md-8 col-sm-6 feature-9-11 "> 
                <div className="my-4 mx-5 display-5">
                    {__("Создать Фестиваль очень просто")}
                </div>
                <div className="pefest-admin-step">
                    <div className="pefest-admin-step-label">
                        1
                    </div>
                    <div className="pefest-admin-step-content">
                        <div className="lead text-bold">
                            {__("Перво-наперво — учётная запись")}
                        </div>
                        <div dangerouslySetInnerHTML={{__html : __("Выбираете: войти на сайт при помощи ВКонтакте или своей учётки в «Яндексе».<p>Всё</p>")}} />
                    </div>
                </div> 
                <div className="pefest-admin-step">
                    <div className="pefest-admin-step-label">
                        2
                    </div>
                    <div className="pefest-admin-step-content">
                        <div className="lead text-bold">
                            {__("Далее все манипуляции будем производить из Кабинета")}
                        </div>    
                        <div dangerouslySetInnerHTML={{__html : __("Да-да! Теперь у Вас есть свой Кабинет. Из него Вы будете создавать свои новые Фестивали, а также управлять старыми")}} />
                    
                    </div>
                </div>
                <div className="pefest-admin-step">
                    <div className="pefest-admin-step-label">
                        3
                    </div>
                    <div className="pefest-admin-step-content">
                        <div className="lead text-bold">
                            {__("Всё предельно лаконично: раздел «Мои Фестивали»")}
                        </div>    
                        <div dangerouslySetInnerHTML={{__html : __("Сейчас здесь пустовато - только одна кнопка")}} />
                    </div>
                </div>
                <div className="pefest-admin-step">
                    <div className="pefest-admin-step-label">
                        4
                    </div>
                    <div className="pefest-admin-step-content">
                        <div className="lead text-bold">
                            {__("Кнопка «Создать новый Фестиваль»")}
                        </div>    
                        <div dangerouslySetInnerHTML={{__html : __("Жмём смело")}} />
                    </div>    
                </div>
                <div className="pefest-admin-step">
                    <div className="pefest-admin-step-label">
                        5
                    </div>
                    <div className="pefest-admin-step-content">
                        <div className="lead text-bold">
                            {__("Параметры нового Фестиваля")}
                        </div>   
                        <div dangerouslySetInnerHTML={{__html : __("Самое важное: <ul> <li>Заголовок фестиваля <li>Уникальный URL-адрес фестиваля <li>Шаблон. Пользователи PE-FEST создают библиотеку наиболее удачных конструкций Фестивалей. Эта библиотека постоянно пополняется.</ul>")}} />
                    </div>
                </div>
                <div className="pefest-admin-step">
                    <div className="pefest-admin-step-label">
                        6
                    </div>
                    <div className="pefest-admin-step-content">
                        <div className="lead text-bold">
                            {__("Всё. Ваш фестиваль готов.")}
                        </div>   
                        <div dangerouslySetInnerHTML={{__html : __("Можете отредактировать систему оценивания экспертами и их учётные записи (логин и пароль им даже придумывать не надо), сгенерировать страницы участников. И включить Фестиваль на нужную фызу.")}} />
                    </div>
                </div>
            </div>
            <div className=" col-md-2 col-sm-6 p-0">
                <div className="feature feature-5">
                    <div className="w-100">
                        <img src="/assets/img/empty.png" className="pp position-relative" /> 
                    </div>
                                        
                    <div className="position-absolute pt-4 pb-3 px-3 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__(" ")}
                        </div>
                        <div className="pt-2 reveal-text">
                            {__("")}
                        </div>
                    </div>
                </div>
                <div className="feature feature-1">
                    <div className="w-100">
                        <img src="/assets/img/empty.png" className="pp position-relative" /> 
                    </div>
                                        
                    <div className="position-absolute pt-4 pb-3 px-3 text-center">
                        <div className="lead font-weight-bold text-light reveal-text">
                            {__(" ")}
                        </div>
                        <div className="pt-2 reveal-text">
                            {__("")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    )
}
export default Home

const getSlider = () =>
{
    return <div className="wpfest-admin-section head row">
        <div className="head-title">
            <div className="mb-1 head-title-title">
                <div className="pe-fest-admin-logo" />
                <div className="reveal-text-2">
                    PE-Fest
                </div> 
            </div>
            
            <div className="head-title-descr reveal-text">
                {__("Инструмент создания «в один клик» и сопровождения мероприятий соревновательного типа в образовательных учреждениях или мероприятий с образовательной направленностью (фестиваль науки или конкурс талантов в школе, игровой-соревновательный формат для урочной группы, курсы, обучающие семинары и тп.")}
            </div>
        </div>
    </div>
}