import React from "react"
import { __ } from "react-pe-utilities"
import { CARD_SEPARATOR } from "../Card"


const CardFieldPrice = ({
	e,
	i,
	cardFieldsData,
	style,
	class_name,
	bStyle
}) => {
	const datas = e.field
		? (e.field).split(CARD_SEPARATOR())
		: ["", "", "", ""]
	const nominal = datas[0]
	const miniNominal = datas[3] ? datas[3] : ""
	const currencyType = datas[1]
		?
		datas[1]
		:
		cardFieldsData.variant_param_1
			?
			cardFieldsData.variant_param_1
			:
			getCurrencies()[0]
	const currencyVisualisation = datas[2]
		?
		datas[2]
		:
		cardFieldsData.variant_param_2
			?
			cardFieldsData.variant_param_2
			:
			"icon"
	let currency = getCurrencies().filter(e => e.id === currencyType)[0]
	currency = currency ? currency : getCurrencies()[0]
	let curlabel = ""
	switch (currencyVisualisation) {
		case "id":
		case "title":
			curlabel = currency[currencyVisualisation]
			break;
		case "abbreviation":
			curlabel = __(currency[currencyVisualisation])
			break
		case "icon":
		default:
			curlabel = <span className={currency[currencyVisualisation]} />
	}
	//console.log(currency, datas)
	const old = cardFieldsData.variant_param_3 
		? 
		" old-price "
		:
		""
	return (
		<div className={" price price" + cardFieldsData.variant + old}>
			<div className="nominal">
				{nominal}
			</div>
			<div className="mini-nominal">
				{miniNominal}
			</div>
			<div className="currency_label">
				{curlabel}
			</div>
		</div>
	)
}
export default CardFieldPrice

export const getCurrencies = () => {
	return [
		{
			"id": "bitcoin",
			"title": "bitcoin",
			"abbreviation": "bt",
			"icon": "fab fa-btc"
		},
		{
			"id": "dollar",
			"title": "dollar",
			"abbreviation": "$",
			"icon": "fas fa-dollar-sign"
		},
		{
			"id": "euro",
			"title": "euro",
			"abbreviation": "Eu",
			"icon": "fas fa-euro-sign"
		},
		{
			"id": "gg",
			"title": "gg",
			"abbreviation": "gg",
			"icon": "fab fa-gg"
		},
		{
			"id": "hryvnia",
			"title": "hryvnia",
			"abbreviation": "гр.",
			"icon": "fas fa-hryvnia"
		},
		{
			"id": "lira",
			"title": "lira",
			"abbreviation": "l.",
			"icon": "fas fa-lira-sign"
		},
		{
			"id": "money",
			"title": "money",
			"abbreviation": "money",
			"icon": "fas fa-money-bill-wave"
		},
		{
			"id": "pound",
			"title": "pound",
			"abbreviation": "pnd",
			"icon": "fas fa-pound-sign"
		},
		{
			"id": "ruble",
			"title": "ruble",
			"abbreviation": "руб.",
			"icon": "fas fa-ruble-sign"
		},
		{
			"id": "rupee",
			"title": "rupee",
			"abbreviation": "rupee",
			"icon": "fas fa-rupee-sign"
		},
		{
			"id": "shekel",
			"title": "shekel",
			"abbreviation": "shekel",
			"icon": "fas fa-shekel-sign"
		},
		{
			"id": "tenge",
			"title": "tenge",
			"abbreviation": "tng",
			"icon": "fas fa-tenge"
		},
		{
			"id": "won",
			"title": "won",
			"abbreviation": "won",
			"icon": "fas fa-won-sign"
		},
		{
			"id": "yen",
			"title": "yen",
			"abbreviation": "yen",
			"icon": "fas fa-yen-sign"
		},
	]
}