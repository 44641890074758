import { Button, Callout, Dialog, Intent } from "@blueprintjs/core"
import React from "react"
import BasicState from "react-pe-basic-view"
import { AppToaster, LayoutIcon } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities/dist"
import InputForm from "../core/InputForm"
import logo from "../assets/img/aptop.svg" 
import $ from "jquery"
import { 
    getChangeName,
    getInputTypeName,
    getMutationArgs,
    mutationEdit, 
    getInput, 
    querySingleName, 
    getQueryArgs,
    querySingle 
} from "react-pe-layouts"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router" 
import gql from "graphql-tag"
import matrix from "../core/matrix.json"
import "../assets/css/style.css"
import QRCodeFileList from "./_qrcodeadmin_view/QRCodeFileList" 
import GoogleSheetQRCodesList from "./_qrcodeadmin_view/GoogleSheetQRCodesList" 
import AddListForm from "./_qrcodeadmin_view/AddListForm"
import TopMenu from "./_qrcodeadmin_view/TopMenu"
// import QRCodeList from "./_qrcodeadmin_view/QRCodeList"
// import QRCodeTemplatesSelector from "./_qrcodeadmin_view/QRCodeTemplatesSelector"

class QRCodeAdminView  extends BasicState { 
    basic_state_data()
    {
        this.getDefaultData = this.getDefaultData.bind(this);
        this.sz = 28
        return { 
            settingData: { width:200, logoWidth:70, height:200, logoHeight:70, },
            preSettingData: {}, 
            isLeftClosed: window.innerWidth < 760,
            newData: this.getDefaultData(),
            refreshList:false,
            preListData:[],
            listData:[], // only for list from loaded File.txt
            selected:[],
            filesParams: { },
            sheetURL: "",
            fileTitle:"Upload",
            isFromServer:false,
            loadedFile:null
        }
    }
    renderLeft = () => {
        return <>
            <div className="title mb-4 hidden ">
                {__("Design settings")}
            </div>            
            <div className="p-0">
                <InputForm
                    value={{
                        text: "https://www.npmjs.com/package/easyqrcodejs",
                        logo,
                        ...this.state.settingData              
                    }}
                    isExample
                    isSetting={true}
                    on={this.onSettingChange}
                /> 
            </div>
            <div className="m-4 d-flex justify-content-center">
                <Button onClick={this.onSettingUpdate}>
                    {__("Update")}
                </Button>
            </div>
        </>
    }
    onSettingChange = (val, field, dopol) =>
    {
        console.log(val, field, dopol)
        let preSettingData = {...this.state.preSettingData}
        preSettingData[field] = val;
        console.log(preSettingData)
        this.setState({preSettingData })
    } 
    onSettingUpdate = () =>
    { 
        this.setState({settingData: this.state.preSettingData })
    } 
    getShortDefaults = () =>
    {
        const settingData = {...this.state.settingData}
        delete settingData.text
        delete settingData.logo
        return settingData
    }
    getDefaultData()
    {
        return getQRDefaultData()
    }
    
    zipAllQRCodes = () =>
    {        
        this.setState({isStartDownload : true})
        let svgs = [];
        $(".clapan-center .pe-qr-cont ").each((i, svg) => {
            if($(`.clapan-center .pe-qr-container:eq(${ i }) .bp3-intent-success`).length > 0 )
                svgs.push( [ $(svg).attr("name"), $(svg).html() ] )
        })
        //console.log(svgs) 
        const svgData = svgs.map(svgList => {
            const url = svgList[0].replaceAll(/"/g, "~~~")
            const svg = svgList[1].replaceAll(/"/g, "~~~")
            const str = '["' + url + '","' + svg + '"]'
            return str
        })
        const svgString =  '[' + svgData.join(', ') + ']' 
        //console.log( svgString )
        const _mutation = `
        query{
            getZippedQR(input: { list: ${svgString} })
            {
                json
            }
        }
        ` 
        const query = gql`${_mutation}`
        
        this.props.client.query( { query, variables: {list:svgs } } )
            .then( 
                response => { 
                    // console.log( response.data.getZippedQR.json )
                    // const blob = new Blob( [ response.data.getZippedQR.json.toString() ] );
                    this.setState({isStartDownload : false})
                    const element = document.createElement( "a" );
                    element.download = "qr-codes.zip"
                    element.href = response.data.getZippedQR.json.toString()
                    element.click();
                    element.remove();
                }
            )
        /**/
    }
    onDeleteOpen = () => {
        this.setState({ isDeleteOpen: !this.state.isDeleteOpen })
    }
    onDelete = () =>
    {  
        let svgs = [];
        $(".clapan-center .pe-qr-container ").each((i, svg) => {
            if($(`.clapan-center .pe-qr-container:eq(${ i }) .bp3-intent-success`).length > 0 )
                svgs.push( $(svg).attr("qr-id") )
        })
        // console.log(svgs)
        if( this.state.isFromServer  )
        {
            svgs.forEach( svg => {      
                this.onDeleteServer(svg) 
            })            
        }
        else
        {
            this.listData = [...this.state.listData]
            svgs.forEach( svg => { 
                this.onDeleteLocal(svg)
            })
            setTimeout(() =>  {
                console.log(this.listData)
                this.setState( { 
                    listData : this.listData, 
                    refreshList:true, 
                    isDeleteOpen: false,
                    filesParams : {...this.state.filesParams, sheetURL: ""},
                } )
            }, 100 )
        }
        
    }
    onAddOpen = () =>
    {
        this.setState({isAddOpen : !this.state.isAddOpen})
    }
    onAddListOpen = () =>
    {
        this.setState({isListOpen : !this.state.isListOpen ,  fileTitle: "Upload"})
    }
    onNewChange = (val, field, dopol, dopol1, dopol2) =>
    {
        console.log(val, field, dopol, dopol1, dopol2)
        let newData = { ...this.getDefaultData(), ...this.state.newData, refreshList:true }
        newData[field] = val;
        if(field === "logo")
        {
            newData.thumbnail = val
            newData.logo_id = dopol2.logo_id
            newData.logo_name = dopol2.logo_name
            newData.thumbnail_id = dopol2.logo_id
            newData.thumbnail_name = dopol2.logo_name
        }
        console.log(newData)
        this.setState({newData })
    } 
    onAddQR = () =>
    {
        this.state.isFromServer 
            ? 
            this.onAddServer()
            :
            this.onAddLocal()

    }
    onAddLocal = () =>
    {
        this.setState({
            listData : [...this.state.listData, this.state.newData], 
            isAddOpen : false
        })
    }
    onAddServer = () =>
    {
        //console.log(this.state.newData)
        const data_type         = "PEQRCode";
        const input             = getInput({ ...this.state.newData, post_title: this.state.newData.title }, data_type)
        const mutation_name     = getChangeName(data_type)
        const input_type_name   = getInputTypeName(data_type)
        const mutation_args     = getMutationArgs(data_type)
        const mutation_change   = mutationEdit(data_type, mutation_name, input_type_name, mutation_args)

        // console.log(this.state.newData)
        // console.log(input)
        // return;
        this.props.client.mutate({
            mutation: mutation_change,
            variables:
            { 
                id: "-1",
                input,
            },
            update: (store, { data }) => {
              console.log( data, getChangeName( data_type ) )
              const respData = data[ getChangeName( data_type ) ]
              if (respData) 
              {
                // console.log(respData)
                this.setState({ refreshList : true, isAddOpen : false })
              }
    
              AppToaster.show({
                intent: Intent.SUCCESS,
                icon: "tick",
                message: __("QR added successful."),
              })
            },
            //refetchQueries: [ { query: this.props.query, variables: {}}]
        })

    }
    deleteQR = id =>
    { 
        this.state.isFromServer 
            ? 
            this.onDeleteServer( id )
            :
            this.onDeleteLocal( id)
    } 
    onDeleteLocal = id =>
    { 
        this.listData = this.listData.filter( e => e.id !== id)
    }
    onDeleteServer = id =>
    {
        const mutation = gql`
        mutation
        {
            deletePEQRCode(id:"${id}")
        }
        `
        this.props.client.mutate({
            mutation,
            update: response => {
                console.log( response.data.deletePEQRCode )
                this.setState({refreshList : true})
            }
        })
    }
    refresh = () => {
        this.setState({refreshList:false})
    }
    getQRCodeTemplate = data =>
    {
        const {client} = this.props
        const data_type = "PEQRCodeTemplate";        
		const query_name = querySingleName(data_type)
		const query_args = getQueryArgs(data_type)
		const query = querySingle(data_type, query_name, query_args, data.id)
		client.query({ query, variables: { id: data.id } })
            .then((result) => {
                // console.log(result.data[query_name], query_name) 
                this.setState({
                    preSettingData : {...this.getDefaultData(), ...result.data[query_name]},
                    curIemplateId: data.id 
                })
            })
    }
    onFileLoad = evt =>
    {
        //console.log(evt.target.files)
        const file = evt.target.files[0] || this.state.loadedFile
        if (file) {
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            const _this = this
            reader.onload = function (loadEvent) {
                const pld = loadEvent.target.result.split( /\r?\n/ )
                const preListData = pld.map((e, i) => {
                        const d =  {
                            id:e,
                            text:e,
                            post_title: (i + 1) + ". " + e,
                            title: (i + 1) + ". " + e
                        }
                        //console.log(d)
                        return d
                    })          
                // console.log( preListData )     
                _this.setState({
                    loadedFile:file, 
                    preListData : preListData,
                    fileTitle: file.name
                });
            }
            reader.onerror = function (errorEvent) {
                console.log(errorEvent.target.result)
            }
        }
    }
    onListDataUpdate = evt =>
    {
        let state = { fileTitle: "Upload", refreshList: true }
        if(this.state.filesParams && this.state.filesParams.fileListSrc === "sheets") 
        {
            if(!this.state.filesParams.preSheetURL)
            {
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: "tick",
                    message: __("Set URL of Google Sheets"),
                })
                return
            }
            state.filesParams = { ...this.state.filesParams }
            state.filesParams.sheetURL =  this.state.filesParams.preSheetURL
            state.isListOpen = false
        }            
        else
        {
            state.listData = this.state.preListData 
            state.selected = this.state.preListData.map(e => e.id)
            state.preListData = []
            state.isListOpen = false
        }            
        // console.log( state )
        this.setState({
            listData:[],
            selected:[]
        })
        setTimeout( () => this.setState( state ), 100 )
    }
    onSelect = selected =>
    {
        this.setState({selected})
    }
    onUpdate = ( options, i) =>
    {
        // console.log( options, i)        
        this.state.isFromServer 
            ? 
            this.onUpdateServer( options, i )
            :
            this.onUpdateLocal( options, i )
    }
    onUpdateLocal = ( options, i) =>
    {
        let listData = [ ...this.state.listData ]
        let op = {
            ...options,
            thumbnail: options.logo,
            thumbnail_id: options.logo_id,
            thumbnail_name: options.logo_name,
            post_title: options.text
        }
        listData[i]  = { ...listData[i], ...op }
        this.setState({ listData })
        // console.log( listData)
    }
    onUpdateServer = ( options, i) =>
    {
        console.log("update bt server")
    }
    onFilesParams = (  value, field, dopol, dopol1, media_data ) =>
    {
        // console.log(  value, field, dopol, dopol1, media_data )
        if( "fileSheet" === field )
        { 
            var request = new XMLHttpRequest();
            request.open('GET', value, true);
            request.responseType = 'blob';
            request.onload = function() {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload =  function(e){
                    console.log('DataURL:', e.target.result);
                };
            };
            request.onerror = function (errorEvent) {
                console.log(errorEvent.target.result)
            }
            request.send(); 
            return
        }
        const filesParams = { ...this.state.filesParams }
        filesParams[field] = value
        console.log(  filesParams )
        this.setState({filesParams})
    }
    onSheetUrl = value => 
    { 
        if(!value)  return
        if( value.indexOf("https://docs.google.com/spreadsheets/d/") === -1 )
        {
            AppToaster.show({
                intent: Intent.SUCCESS,
                icon: "tick",
                message: __("Set URL of Google Sheets"),
            })
            return
        }
        const filesParams = { ...this.state.filesParams }
        filesParams.preSheetURL = value.split( "/" )[ 5 ]
        this.setState({ filesParams })
    }
    onUpdateList = listData => {
        console.log( listData );
        if( !Array.isArray(listData) ) return        
        setTimeout(() => {  
            this.setState({
                listData,
                selected: listData.map(e => e.id),
                preListData: [],
                isListOpen: false
            })
        }, 50)
        //    
    } 
    getContent = () =>
    {
        return this.state.listData.length > 0
        ?
        <>
            <GoogleSheetQRCodesList
                listData={this.state.listData} 
                sheetURL={ this.state.filesParams ? this.state.filesParams.sheetURL : "" }
                onUpdateList={this.onUpdateList}
            /> 
            {/* 
            <QRCodeList 
                defaults={this.getShortDefaults()}
                deleteQR={this.deleteQR}
                refresh={this.refresh}
                refreshMe={this.state.refreshList}
            />
            */}   
            <QRCodeFileList
                listData={this.state.listData} 
                defaults={this.getShortDefaults()}
                onSelect={this.onSelect}
                selected={this.state.selected}
                deleteQR={this.deleteQR}
                refresh={this.refresh}
                refreshMe={this.state.refreshList}
                onUpdate={this.onUpdate}
                params={this.state.filesParams}
            />
        </>
        :
        <div className="p-2">
            <Callout intent={Intent.DANGER} className="p-5 d-flex justify-content-center">
                {__("No QR-code exists. Add QR-code data or QR-code data list.")}
            </Callout>
        </div>
    }
    render() 
    { 
        const {  isLeftClosed } = this.state
		const leftClass = isLeftClosed
			? "tutor-left-aside-2 menu-aside closed"
			: "tutor-left-aside-2 menu-aside"
		const mainClass = isLeftClosed
			? "tutor-main-2 pr-0 opened"
			: "tutor-main-2 pr-0"

        //console.log( this.state )
        return (    
            <div className="layout-state p-0">
                <div className="tutor-row menu-row">
                    <div className={leftClass}>
                        <div className="layout-state-head menu-header-22 m-0">
                            {/*
                            <QRCodeTemplatesSelector 
                                value={this.state.preSettingData}
                                onChange={this.getQRCodeTemplate}
                            />
                            */}
                        </div>
                        <div className="small mx-3 mb-3 p-0">
                            {this.renderLeft()}
                        </div> 
                    </div>
                    <div className={mainClass}>
                        <div
                            className="clapan-left"
                            onClick={() => this.setState({ isLeftClosed: !isLeftClosed })}
                        >
                            <div className={`fas fa-caret-${!isLeftClosed ? "left" : "right"}`} />
                        </div>
                        <div className="menu-header-22 flex-centered py-0 pl-5 pr-2">
                            <LayoutIcon
                                isSVG
                                src={this.state.route.icon}
                                className="layout-state-logo "
                            />
                            <div className="layout-state-title mr-auto"> 
                                {__(this.state.route.title)} 
                            </div>
                            <TopMenu
                                onAddOpen={this.onAddOpen}
                                onAddListOpen={this.onAddListOpen}
                                zipAllQRCodes={this.zipAllQRCodes}
                                getDownloadIcon={this.getDownloadIcon}
                                onDeleteOpen={this.onDeleteOpen}
                                isStartDownload={this.state.isStartDownload}
                            />
                            <Dialog
                                isOpen={this.state.isDeleteOpen}
                                onClose={this.onDeleteOpen}
                                className="little"
                            >
                                <div className="p-4">
                                    <div className="pb-4">
                                        {__("Remove selected QR-code?")}
                                    </div>   
                                    <div>
                                        <Button onClick={this.onDeleteOpen}>
                                            {__("Close")}    
                                        </Button> 
                                        <Button onClick={this.onDelete} intent={Intent.DANGER}>
                                            {__("Remove all selected")}    
                                        </Button>    
                                    </div> 
                                </div>
                            </Dialog>
                        </div>
                        <div className=" p-5 clapan-center "> 
                            {this.getContent()}
                        </div>
                    </div>
                    <div className="tutor-right-aside-2">
                        
                    </div>
                </div> 
                <Dialog
                    title={__("Add QR-code")}
                    isOpen={this.state.isAddOpen}
                    onClose={ this.onAddOpen }
                >
                    <div className='px-4 pt-4'>
                        <InputForm
                            value={{ 
                                ...this.getDefaultData()            
                            }}
                            isSetting={false}
                            on={ this.onNewChange}
                        /> 
                    </div>
                    <div className="bp3-dialog-footer p-4 m-0">
                        <Button intent={Intent.SUCCESS} onClick={this.onAddQR}>
                            {__("Edit")}
                        </Button>
                    </div>
                </Dialog>
                <Dialog
                    title={__("Add list")}
                    isOpen={this.state.isListOpen}
                    onClose={ this.onAddListOpen }
                >
                    <AddListForm
                        {...this.state.filesParams}
                        fileTitle={this.state.fileTitle}
                        field={this.props.field}
                        onSheetUrl={this.onSheetUrl}
                        onFileLoad={this.onFileLoad}
                        onFilesParams={this.onFilesParams}
                        onListDataUpdate={this.onListDataUpdate}
                    />
                </Dialog>
            </div> 
        )
    }
}

export default compose(
    withApollo,
    withRouter,
  )(QRCodeAdminView)

export const getQRDefaultData = () => {
    let def = {};
    Object.keys(matrix).forEach( field =>
        {
            def[field] = matrix[field].default  
        }) 
    return def
}
 
