import React from 'react'
import { __ } from 'react-pe-utilities/dist'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import FestComponent from './FestComponent'

class FestReglament extends FestComponent 
{
    fetchCommand  = "get_reglament"
    header_type = "reglaments"
     
   
    addRender = () => 
    {
        return <div className='short-container'>
            <div className='display-6 mb-5 mt-4'>
                { this.state.festival_reglament_title }
            </div>
            <div className='row'>
                <div className='col-md-4'>

                </div>
                <div className='col-md-8'>
                    <div 
                        className=" " 
                        dangerouslySetInnerHTML={{ __html: this.state.festival_reglament}}
                    /> 
                </div>
            </div>
            
        </div>
    }
}
export default compose(
    withRouter,
  )(FestReglament)

