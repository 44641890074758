import React from 'react';

const WPFestAdminContext = React.createContext({
    user: {},
    setUser: () => {},
    cabinet:{},
    uri: "",
    setServerURI : () => {}
});

export default WPFestAdminContext;
