import React, { Component } from "react"
import $ from "jquery"
import { withRouter } from "react-router"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import axios from "axios"
import { Intent,  Callout } from "@blueprintjs/core"
import gql from "graphql-tag"
import WebFont from "webfontloader"
import { __ } from "react-pe-utilities" 
import SectionContainer from "./LandingState/SectionContainer"
import ToUp from "./LandingState/ToUp" 
import {Loading} from 'react-pe-useful' 
import Section, {  getStyle } from "./LandingState/Section"
import DataContext from "./LandingState/DataContext" 

import { AppToaster } from 'react-pe-useful' 
import { getLandingContainer } from "./LandingView"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB" 
import LandingFlow from "./LandingState/flow/LandingFlow"

class LandingSnapshot extends Component
{
	constructor(props)
	{
		super(props)
		this.state={ 
			...this.basic_state_data()
		}
	}
	basic_state_data() 
	{
		const el = document.createElement("script")
		el.type = "text/javascript"
		el.src = "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes.min.js"
		el.async = true
		el.id = "landing"

		const stl = document.createElement("link")
		stl.type = "text/css"
		stl.src = "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes_birman.css"
		stl.async = true

		return {
			loading: true,
			isDummy: true,
			is_edit: false,
			isHelpOpen: false,
			s: false,
			fixedChildren: [],
			newSectionType: "html"
		}
	}
	local = this.props.extend_params && this.props.extend_params.local;

	getId() {
		// console.log( this.props.extend_params );
		return this.props.extend_params && this.props.extend_params.id ? this.props.extend_params.id : "landing_page"
	}

	setID(lid) {
		this.loadLanding(lid)
	}

	localLoad(lid) {
		axios.get( "/assets/data/" + lid + ".json" )
		.then(
			(response) => {
				const parsed2 = response.data
				//console.log( parsed2 );	
				DataContext.upd(parsed2)
				//console.log( DataContext.data );
				// console.log( ["Open Sans", ...DataContext.data.landing.fonts.map(e => e.title )] )
				WebFont.load({
					google: {
						families: [
							"Open Sans", 
							...( DataContext.data.landing && DataContext.data.landing.fonts
									?
									DataContext.data.landing.fonts?.map((e) => e.title)
									:
									[]
								)
						],
					},
				})
				this.setState({ 
					loading: false,
					is_edit: false, 
					version: this.props.extend_params.version
				}) 
				this.scrollToElement();
			},
			error => console.log(error)
		)
	}

	serverLoad(lid) 
	{		
		console.log("serverLoad")  
		const query = gql`
			query
			{
				getPE_Landing( id : "${lid}" )
				{
					json
				}
			}
		`
		IndexDB.find( "peLanding", "peLanding", "id", lid )
			.then( dat => {
				if(dat)
				{
					this.draw(dat.data)
					this.setState({ version: dat.version })
				}
				else
				{
					this.setState({ version: -1 })
				}				
			})
		this.props.client.query({ query })
			.then((result) => {
				const { json } = result.data.getPE_Landing
				const replaced_json = json.replace(/'/gi, "\"")
				let replaced2_json = replaced_json.replace(/\n/gi, " ").replace(/\s/gi, " ").replace(/\t/gi, " ").replace(/~~/gi, "'")
				// console.log(replaced2_json)
				//replaced2_json = replaced2_json
				replaced2_json = replaced2_json
				replaced2_json = replaced2_json
				let version = 0
				let parsed
				try
				{
					parsed = replaced2_json ? JSON.parse(replaced2_json) : { sections: [] }
					version = parsed.version || 1
				}
				catch(error)
				{
					parsed = { sections: [] }
					console.log(error)
					console.log(replaced2_json)

				}
				parsed = {
					...parsed,
					sections: parsed.sections.filter( section => section && section.type )
				}
				console.log("json:", parsed)
				if( version > this.state.version )
				{
					IndexDB.save( parsed, "id", lid, "peLanding", "peLanding", version )
					this.draw(parsed)
				} 
				
				
			})
				.catch(error => {
					//this.state.recoverData
					console.log(error)
				})
	}
	draw = parsed =>
	{ 
		DataContext.upd(parsed)
		// console.log(parsed );
		if (parsed && parsed.landing) 
		{
			if (parsed.landing.css) 
			{
				$("head").append(`<style id='landing-css'>${parsed.landing.css}</style>`)
			}
			if (parsed.landing.is_hide_header) 
			{
				$(".layout-header").hide()
			}
		}
		// console.log( parsed );
		// console.log( DataContext.data );
		if (!parsed.sections || parsed.sections.length === 0) 
		{
			//this.onLandingClear()
		}
		if(!DataContext.data && !DataContext.data?.landing)
		{
			// return
		}
		const fonts = DataContext.data?.landing?.fonts
			? 
			DataContext.data.landing.fonts.map((e) => e ? e.title : "Open Sans")
			: 
			[]
		WebFont.load({
			google: {
				families: ["Open Sans", ...fonts],
			},
		})
		this.scrollToElement()
		this.setState({
			loading: false, 
			is_edit: false
		})
	}

	loadLanding(lid) 
	{
		this.local ? this.localLoad(lid) : this.serverLoad(lid)
		
		
	}


	componentDidMount() 
	{
		window.pe_landing = { section: [] }
		// $(".layout-header").hide();
		document.body.addEventListener("mousemove", this.onMove)
		window.addEventListener("resize", this.updateWindowDimensions)
		this.updateWindowDimensions()
		const lid = this.getId()
		// console.log(lid)
		IndexDB.find( "peLanding", "peLanding", "id", lid )
			.then(dat => {
				if( dat && dat.data )
				{
					try
					{						
						this.draw(dat.data)
						this.setState({ version: dat.version, recoverData: dat })
					}
					catch(err) {
						console.log(err)
					}
				}
			})
			.catch( error => console.log(error))
		if(this.local)
		{ 
			this.setID( lid )
		}
		else
		{
			const query = gql`
				query
				{
					getPE_LandingID( id : "${lid}" ) 
				}
			`
			this.props.client.query({ query })
				.catch(error => {
					//this.state.recoverData 
				})
				.then((result) => 
				{
					if( !result )
					{
						return null
					}
					const json = result.data.getPE_LandingID 
					this.setID(json)
				})	
		}
		
		/**/ 
	}
	componentDidUpdate()
	{
		if(this.state.is_not_update)
		{
			window.onbeforeunload = function() {
				return "NO! NO! NO!";
			}
		}
		else
		{
			window.onbeforeunload = function() {
				 
			}
		}
	}

	componentWillMount() 
	{

	}
	
	loginUser = () =>
	{
		if(this.props.loginUser)
		{
			this.props.loginUser()
		}
	}

	componentWillUnmount() {
		$("html").css({marginTop : 0, height:"auto"})
		$(".layout-header").show()
		document.body.removeEventListener("mousemove", this.onMove)
		//window.removeEventListener("scroll", this.onscrollHandler)
		window.removeEventListener("resize", this.updateWindowDimensions)
		delete window.pe_landing
	}

	updateWindowDimensions = () => {
		if (Array.isArray(window.pe_landing.section) && window.pe_landing.section.length > 0) {
			window.pe_landing.section.forEach((e, i) => {
				// console.log( e.state );
				const {
					visible_lg, visible_sm, visible_ms, visible_xs,
				} = e.state
				const w = document.body.clientWidth

				const state = {
					section_width: w,
					section_height: document.body.clientHeight,
				}
				// const old = e.is_visible
				// hide all = show all
				const visible_all = !visible_lg && !visible_sm && !visible_ms && !visible_xs
				if (w > 1200) {
					e.is_visible = visible_lg || visible_all
				} else if (w > 740) {
					e.is_visible = visible_sm || visible_all
				} else if (w > 540) {
					e.is_visible = visible_ms || visible_all
				} else {
					e.is_visible = visible_xs || visible_all
				}
				// if( old !== e.is_visible )
				// console.log("state=",state)
				e.setState(state)
				e.updateWidth(w)
			})
		}
		this.setState({section_width : document.body.clientWidth})
	}

	onMove = (evt) => {
		window.mouseX = evt.offsetX
		window.mouseY = evt.offsetY
	}
	onFloatSelect = floatId =>
	{
		console.log( floatId )
	}

	render()
	{
		if (this.state.loading) 
		{
			return (
				<div className="layout-state layout-center">
					<Loading />
				</div>
			)
		}
		if(!DataContext.data && !DataContext.data.landing )  
		{
			// return null;
		}
		//console.log(DataContext.data.sections)
		// console.log( this.state.entery, this.state.enteryCount )
		const containerClassName = DataContext.data.landing?.class_name
			?
			DataContext.data.landing?.class_name
			:
			""
		const containerStyle = DataContext.data.landing?.style
			?
			getStyle(DataContext.data.landing?.style)
			:
			{ }
		const landings = Array.isArray(DataContext.data.sections) && DataContext.data.sections.length > 0
			?
			DataContext.data.sections.filter((e, i) => {
				if(typeof this.state.entery === "undefined")
				{
					return true
				}					
				else
				{
					return i >= this.state.entery && i < this.state.entery + this.state.enteryCount
				}
			})
			.map((e, i) => 
			{
				return <SectionContainer key={i} data={e}>
					<Section
						isDummy={this.state.isDummy}
						{...e}
						section_id={e.id} 
						i={i}
						section_width={ this.state.section_width }
						user={this.props.user} 
						level={0}
						loginUser={this.loginUser}
					/>
				</SectionContainer> 
			})
			:
			<div className="p-5">
				<Callout className="p-5 text-center">
					{__("No landing data")} 
				</Callout>
			</div>
		let c 
		let row
		if ( DataContext.data.landing?.is_blocked ) 
		{
			c = <>
				<div className="landing-container-left-field">
					<div
						style={{ backgroundImage: `url(${DataContext.data.landing.left_image})` }}
					/>
					<div
						style={{
							backgroundColor: DataContext.data.landing.left_color,
							opacity: DataContext.data.landing.left_color_opacity,
						}}
					/>
				</div>
				<LandingFlow
					containerClassName={containerClassName}
					containerStyle={containerStyle}
				>
					{landings}	
				</LandingFlow>
				<div className="landing-container-right-field">
					<div
						style={{ backgroundImage: `url(${DataContext.data.landing.right_image})` }}
					/>
					<div
						style={{
							backgroundColor: DataContext.data.landing.right_color,
							opacity: DataContext.data.landing.right_color_opacity,
						}}
					/>
				</div>
			</> 
			row = " flex-row "
		} 
		else 
		{
			c = <LandingFlow 
				containerClassName={`container-float ${ containerClassName }`}
				containerStyle={containerStyle}
			>
				{landings}	
			</LandingFlow>
			row = " flex-column "
		}

		const toTop = DataContext.data && DataContext.data.landing && DataContext.data.landing.is_up_to_top_button
			? 
			<ToUp  
				up_to_top_size={DataContext.data.landing.up_to_top_size}
				up_to_top_padding={DataContext.data.landing.up_to_top_padding}
				up_to_top_background={DataContext.data.landing.up_to_top_background}
				up_to_top_position={DataContext.data.landing.up_to_top_position}
			/>
			: 
			null
		
		const fixedChildren = this.state.fixedChildren.map((child, ii) => ({ ...child, key: ii }))
		return (
			<div
				className={`landing-container layout-state p-0 ${row} ${this.state.is_edit ? "is_edit" : ""}` }
			>
				<div
					className="landing-cfIcon-conttainer"
					id="landing-main-float"
					children={[
						...fixedChildren,						
					]}
				/>
				{ toTop } 
				{ c }
				{
					typeof this.state.entery !== "undefined"
						?
						<div className="bg-dark p-2 d-flex justify-content-center ">
							<a 
								className="btn btn-primary" 
								href={`${ window.location.origin }${ window.location.pathname }`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{__( DataContext.data.landing?.title || window.location.origin + window.location.pathname)}
							</a>
						</div>
						:
						null
				}
				{
					DataContext.data.landing?.title
						?
						<div className="position-fixed bottom right hover">
							<a 
								className="small bg-dark text-light px-2 py-1 mx-2"
								href={`${ window.location.origin }${ window.location.pathname }`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{ __( DataContext.data.landing?.title ) }
							</a>
						</div>
						:
						null
				}
				
			</div>
		)
	}
	getHelp = (help_url) =>
	{
		this.setState({ isHelpOpen: true, help_url:help_url })
	}
	onHelp = () => {
		this.setState({ isHelpOpen: !this.state.isHelpOpen })
	}
	onLandingEdit = (data) => {
		DataContext.setLanding(data)
		this.setState({ isLandingEditOpen: false, is_not_update: true })
		$("#landing-css")
			.empty()
			.append(data.css)
	}
 

	onLoadChange = (evt) => {
		// console.log(evt.target.files);
		if (evt.target.files[0].type === "application/json") {
			const context = this
			const reader = new FileReader()
			reader.readAsText(evt.target.files[0])
			reader.onload = () => {
				const data = JSON.parse(reader.result)
				//console.log(data)
				if (
					typeof data.sections != "undefined"
						&& typeof data.maxSectionID != "undefined"
						&& typeof data.maxFloatID != "undefined"
						&& typeof data.landing != "undefined"
				) 
				{
					DataContext.upd(data)
					context.setState({ s: !context.state.s })
				} 
				else 
				{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("File is not Landing format"),
					})
				}
			}
		} 
		else 
		{
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				duration: 10000,
				message: __("Choose JSON file."),
			})
		}
	}  
	scrollToElement = () => 
	{	
		if( this.props.location.hash ) 
		{ 
			const hash = this.props.location.hash.split("?")
			const anchor = hash[0]
			let getParams = {}
			const get = hash[1]
				?
				hash[1].split("&").map(e => e.split("=")).forEach(e => {
					getParams[e[0]] = typeof e[1] !== "undefined" ? e[1] : ""
				})
				:
				null 
			let archorMenu = -1
			let mySection = -1 
			DataContext.data.sections.forEach((section, i) => {
				if( DataContext.data.sections[ i ]?.type === "archor_menu" )
				{
					archorMenu = i
				}
				if(`#${section?.menu?.id}` === anchor )
				{
					mySection = i
				}
			})
			const shiftY = archorMenu >= 0 && mySection >= 0 && mySection > archorMenu
				?
				60
				: 
				0 
			DataContext.data.sections = DataContext.data.sections.map((section, i) => {
				return {
					...section,
					isDummy : i < mySection
				}
			})
			//const targ = document.getElementById( anchor.replace("#", "") ) 
			// if(!targ) return
			if( getParams.entery )
			{
				this.setState({ entery: mySection, enteryCount: parseInt( getParams.entery) || 1 })
			}
			else
			{
				const timer = setInterval(() => { 	
					// console.log( $( anchor ).offset().top - shiftY )
					if( $( anchor ).offset().top - shiftY < 1 )
					{
						clearInterval(timer)
					}
					getLandingContainer().scrollBy({
						behavior : "auto",
						top: $(anchor).offset().top - shiftY
					}) 
				}, 200 )
			}
			
		}
	}    
  

	onWaypointEnter = (section_id) => {
		// console.log("WaypointEnter", section_id)
	}

	onWaypointLeave = (section_id) => {
		// console.log("WaypointLeave", section_id);
	}

	onFixedAdd = (data) => {
		this.setState({ fixedChildren: [...this.state.fixedChildren, data] })
	}
}
export default compose(
	withRouter,
	withApollo,
)(LandingSnapshot)