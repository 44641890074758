import React, { Component } from "react";
import { __ } from "react-pe-utilities/dist";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default class FullMembers extends Component
{
	
	render()
	{
		console.log(this.props)
		return <div className="p-4"> 
			<a className="btn btn-link " href={this.props.data.full_members_url} target="_blank">
				{__("Download CSV")}
			</a>
			<ReactHTMLTableToExcel
				id="test-table-xls-button"
				className="btn btn-link ml-4"
				table="table-to-xls"
				filename="full_project_list"
				sheet="full_project_list"
				buttonText={__("Download as XLS")}
			/>
			<div dangerouslySetInnerHTML={{ __html: this.props.data.full_members_table }} />
		</div>
	}
}