import { Intent } from "@blueprintjs/core";
import React, { useContext } from "react"
import { AppToaster } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist";
import { getCookie, setCookie } from "../utilities/utils";
import CabinetView, { TOKEN_COOKIE_NAME, TOKEN_RAFRESH_COOKIE_NAME } from "../views/CabinetView"; 

export const FAILED_TO_FETCH ="Failed to fetch"
export default function _fetch( code, args, signal )
{ 
	const url = CabinetView.serverURL 				
	return fetching( code, args, signal, url )
}

async function fetching (code, args, signal, url)
{
	let rejected = null
	let promise = new Promise(( resolve, reject ) => {
		code = !code ? "page" : code;
		var headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};
		if(typeof args != 'object') args = {};
		const token = getCookie(TOKEN_COOKIE_NAME)
		headers.token = token
		// console.log(headers, code, args)
		fetch( url + '/wp-json/wpfa/' + code,
		{
			method: 'POST',
			credentials: 'include',
			headers: headers,
			body: JSON.stringify({
				code: code,
				args: args
			}),
			signal
		}) 
			.then( r => 
			{ 
				const res = r.json()				
				return res;
			})
				.then( data => {
					//console.log(data)
					if(data.msg)
					{
						AppToaster.show({
							intent:	data.msg_type ? data.msg_type : Intent.SUCCESS,
							icon: 	data.msg_icon ? data.msg_icon : "tick", 
							message: __(data.msg),
						})
					}
					if(data.is_unlogin && !!token)
					{
						setCookie( TOKEN_COOKIE_NAME, "") 
						//window.location.replace( '/' + CabinetView.WPFESTAdminRoute )
						fetch( url + '/wp-json/wpfa/refresh', {
							method: 'POST',
							credentials: 'include',
							headers: headers,
							body: JSON.stringify({
								code: "refresh",
								args: {refresh_token: getCookie(TOKEN_RAFRESH_COOKIE_NAME)}
							}),
							signal
						})
							.then( r => {
								const res = r.json()				
								return res;
							}) 
								.then( data => {
									//console.log( data )
									// setCookie( TOKEN_COOKIE_NAME, data.token, { expires : 10 * 3600 } ); 
									_fetch( code, args, signal, url )
										.then(data => resolve(data))
								})
					}
					else if( data.is_unlogin )
					{
						resolve ({ 
							...data,
							is_unlogin:true,
						})
					}
					else
					{
						resolve(data)	
					}
					
				}) 
			.catch(error => 
				{
					console.log(error.message)
					resolve ({ 
						isError:true, 
						msg_type: "danger",
						msg: error.message,
						msg_icon: "issue",
						error 					
					})
				}) 
	})
	let result = await promise; 
	return result
}