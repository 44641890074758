import { Button, Intent } from "@blueprintjs/core"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { __ } from "react-pe-utilities/dist"
import SingleFormField from "./single/SingleFormField"

const FormFields = props =>
{
    const [fields, changeFields] = useState( props.data )
    useEffect(() => {
        changeFields( props.data )
    }, [props.data])
    //console.log( fields )
    const onSwitchUp = (i) =>
    {
        let _fields = [...fields];
        const fld = _fields[i]
        _fields.splice(i, 1)
        _fields.splice(i - 1, 0, fld)
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onSwitchDown = (i) =>
    {
        let _fields = [...fields];
        const fld = _fields[i]
        _fields.splice(i, 1)
        _fields.splice(i + 1, 0, fld)
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onRemove = (i) =>
    {
        let _fields = [...fields];
        _fields.splice(i,1)
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onChange = (i, data) =>
    {
        let _fields = [...fields];
        _fields[i] = {..._fields[i], ...data}
        changeFields(_fields)
        props.onChange(_fields )
    }
    const OnSubMenu = (i, value, field) =>
    {
        //console.log(i, value, field)
        let _fields = [...fields];
        _fields[i][field] = value
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onAdd = () =>
    {
        let _fields = [...fields];
        _fields.push({
            count:1,
            name:"",
            description:"",
            type:"string"
        })
        changeFields(_fields)
        props.onChange(_fields )
    }
   
    //console.log( fields )
    const forms = fields.map((form, i) =>
    {
        return <SingleFormField
            key={i}
            i={i}
            {...form} // value, name, description, type
            disabledUp={ i === 0 }
            disabledDown={ i === fields.length - 1 }
            onSwitchUp={ onSwitchUp }
            onSwitchDown={ onSwitchDown }
            onRemove={ onRemove }
            onChange={ onChange } 
            OnSubMenu={ OnSubMenu } 
        />
    })
    return <div>
        {forms}
        <div>
            <Button intent={Intent.DANGER} icon="plus" onClick={onAdd} >
                {__("Add new field")}
            </Button>
        </div>
    </div>
}
export default FormFields