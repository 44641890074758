import React, { Component } from "react" 
import _fetch from "." 
import {  getCookie } from "./utilities/utils"
import { __ } from "react-pe-utilities"  

class Token extends Component {
  render() {
    return (
      <div className="row data-list">
        <div className="col-1">
          {this.props.i}
        </div>
        <div className="col-7">
          {
					`${getCookie(`wpfest_url${this.props.i - 1}`)}: ${
					  getCookie(`wpfest_display_name${this.props.i - 1}`)}`
				}
        </div>
        <div className="col-3 m-1">
          <div className="btn btn-danger px-4 py-0 btn-sm" onClick={this.onLogout}>
            {__("Log out")}
          </div>
        </div>
      </div>
    )
  }

	onLogout = () => {
	  this.props.onLogout(this.props.i)
	}
}
export default Token
