import React, { Component } from "react" 
import {LayoutIcon} from 'react-pe-useful'
import __cards from "../../assets/img/landing/card.svg"

class CardButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
    this.ref = React.createRef()
  }

  render() {
    // console.log( this.props.object );
    return (
      <div className=" square2 bg-secondary m-1 btn-item" onClick={this.onClick}>
        <div>
          <LayoutIcon
            src={__cards}
            className=" layout-icon white"
          />
          <div className="small text-white ">
            { this.props.object.title }
          </div>
        </div>
      </div>
    )
  }

	onClick = () => {
	  this.props.onClick(this.props.object)
	}
}
export default CardButton
