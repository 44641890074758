import RightPanel from "modules/pe-admin-module/views/scalars/RightPanel"
import LeftPanelDragger from "modules/pe-admin-module/views/scalars/tool-panel/LeftPanelDragger"
import ToolPanelGroup from "modules/pe-admin-module/views/scalars/ToolPanelGroup"
import React, { useState } from "react"
import { useEffect } from "react"
import FieldInput from "react-pe-scalars/dist"
import InputForm from "../InputForm"
import CardFieldPanelEdit from "./CardFieldPanelEdit"
import CardFieldsDnDEngine from "./CardFieldsDnDEngine"

const CurrentCardTemplateEditor = props =>
{ 
    const [panels, setPanels] = useState({})
    const [data, setData] = useState( props.data.data || {} ) 
    const [selectField, setSelectField] = useState( null )
    const [leftX, setLeftX] = useState( 320 )
    useEffect(() =>
    {
        setPanels({
            left: {
                panels: [
                {
                    x : 0,
                    y : 0,
                    width: leftX,
                    height:220,
                    isOpen:true,
                    title: "Cards group design",
                    __children: [
                        {
                            parent :  "cards",
                            field :   "cards_align",

                        },
                        {
                            parent :  "cards",
                            field :   "vertical_align",

                        },
                        {
                            parent :  "cards",
                            field :   "height",

                        },
                        {
                            parent :  "cards",
                            field :   "color",

                        },
                        {
                            parent :  "cards",
                            field :   "is_back_image",

                        },
                        {
                            parent :  "cards",
                            field :   "back_image",

                        },
                        {
                            parent :  "cards",
                            field :   "back_image_vertical",

                        },
                        {
                            parent :  "cards",
                            field :   "exact_value",

                        },
                        {
                            parent :  "cards",
                            field :   "back_opacity",

                        },
                        {
                            parent :  "cards",
                            field :   "is_contrast_muar",

                        },
                        {
                            parent :  "cards",
                            field :   "is_joint",

                        },
                        {
                            parent :  "cards",
                            field :   "padding",

                        },

                    ]
                },
                {
                    x : 0,
                    y : 0,
                    width: 330,
                    height:220,
                    isOpen:true,
                    title: "Every card design",
                    __children: [
                        {
                            parent :  "cards",
                            field :   "hide_action_icon",

                        },

                    ]
                },
                {
                    x : 0,
                    y : 0,
                    width: 330,
                    height:220,
                    isOpen:true,
                    title: "Card's conteiner decoration",
                    __children: [
                        {
                            parent :  "cards",
                            field :   "hide_action_icon",

                        },

                    ]
                }, 
                ],
                width: 430,
            },
            right: {
                width:430,
                panels:[

                ]
            }
            
        })
    }, [])    
    const onChange = (value, field, ) =>
    {
        //console.log( value, field )
        //console.log( data )
        let _data ={ ...data }
        //console.log( _data )
        _data[ field ] = value
        setData( _data ) 
        props.onUpdate( _data )
    }
    const onSelectField = _field =>
    {
        //console.log( _field )
        setSelectField( _field )
    }
    const onResizeLeft = width =>
    {
        setLeftX(width)
        setPanels({
            ...panels,
            left: {
                ...panels.left,
                width
            }
        })
    }
    const onField = (value, field, id ) =>
    { 
        let _selectField = {...selectField}
        _selectField[field] = value 
        setSelectField( _selectField )
        let _data = {...data}
        _data.fields.map((field, i) => {
            if( field.id === id)
            {
                _data.fields[i] = _selectField    
            }
            return _data.fields[i]
        })
        setData(_data) 
        props.onUpdate( _data )
    }
    const onData = _data =>
    {
        setData(_data) 
        props.onUpdate( _data )
    }
    console.log(data)
    return <div className="position-relative h-100">
        <div className="flex-centered h-100">
            <CardFieldsDnDEngine 
                value={ data }
                on={onChange}
                onSelectField={onSelectField}
                selectField={ selectField?.id }
            />
        </div>
        <div 
            className="tool-panel-area overflow-y-auto light-thumb left-panel"
            style={{ width: panels.left?.width }}
        >
        { 
            panels.left?.panels?.map((panel, i) => {
                return <ToolPanelGroup
                    key={i}
                    panel={{...panel}}

                >
                
                </ToolPanelGroup>
            })            
        }            
        </div>
        <div className="tool-panel-area  right-panel">
            <div className="overflow-y-auto light-thumb h-100" >
                <RightPanel
                    {...panels.right}
                    isOpen={!!selectField}
                    deselect={ () => onSelectField( null ) }
                >
                    <CardFieldPanelEdit
                        origin={props.data}
                        object={selectField}
                        type={selectField?.type}
                        id={selectField?.id}
                        on={onField}
                        onData={onData}
                    />
                </RightPanel>
            </div>
            
        </div>
        <LeftPanelDragger onResize={onResizeLeft} />
    </div>
}
export default CurrentCardTemplateEditor