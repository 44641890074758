import chroma from "chroma-js"
import React, { useState } from "react"
import Outer from "./Outer"
import Style from "style-it"
import { CARD_SEPARATOR } from "../Card"
import { getStyle } from "../Section"
import { getColor, getPlainColor } from "../data/getColor"


const CardFieldNavlink = ({
    e,
    i,
    cardFieldsData,
    style,
    class_name,
    bStyle,
    palette
}) => {
    const [isOpen, onOpen] = useState(false)
    const onClick = () => {
        onOpen(!isOpen)
    }
    // label and url 
    const datas = typeof e.field !== "undefined"
        ? (e.field).split(CARD_SEPARATOR())
        : ["", ""]
    const vars = [
        "primary",
        "secondary",
        "active",
        "danger",
        "success",
        "warning",
        'link'
    ]
    const clr = parseInt(cardFieldsData.variant) !== 4
        && palette.button[vars[parseInt(cardFieldsData.variant)]]
        ?
        cardFieldsData.variant == 0
            ?
            cardFieldsData.variant_param_1
                ?
                {
                    background: cardFieldsData.variant_param_1,
                    color: "#EEE"
                }
                :
                palette.button[vars[parseInt(cardFieldsData.variant)]]
            :
            palette.button[vars[parseInt(cardFieldsData.variant)]]
        :
        { background: "#ffffff00", color: palette.card.color }
    return datas[0]
        ?
        <>
            <div
                className={" navlink " + cardFieldsData.variant_param_2}
                style={{
                    justifyContent: cardFieldsData.variant_param_2
                }}
            >
                {
                    Style.it(
                        `
                        .navv
                        {
                            background : ${cardFieldsData.variant_param_6 ? "#00000000" : getColor(clr.background, palette)};
                            border-color: ${chroma(getPlainColor(clr.background, palette)).darken(1.1)};
                            color:${cardFieldsData.variant_param_6 ? clr.background : chroma(clr.color)};
                            width:${cardFieldsData.variant_param_3 ? `100%` : 'auto'};
                            height:${cardFieldsData.variant_param_4 ? parseInt(cardFieldsData.variant_param_4) + `px` : 'auto'};
                            border-radius: ${typeof cardFieldsData.variant_param_5 !== "undefined" ? cardFieldsData.variant_param_5 + "px" : null}
                        } 
                        .navv:hover
                        {
                            background-color: ${chroma(getPlainColor(clr.background, palette)).darken(1.1)};
                            border-color: ${chroma(getPlainColor(clr.background, palette)).darken(1.01)};
                            color: ${chroma(clr.color)};
                        } 
                        .landing-card:hover .navv 
                        {
                            background-color: ${cardFieldsData.hover_color};
                            border-color: ${cardFieldsData.hover_color};
                            transition: background-color ${cardFieldsData.hover_duration}ms ease-out;
                            transition-delay:${cardFieldsData.hover_delay}ms;	
                        }
                        `,
                        <div
                            className="btn navv text-wrap"
                            style={{
                                ...getStyle(cardFieldsData.variant_param_7, palette)
                            }}
                            onClick={onClick}
                        >
                            {
                                cardFieldsData.variant_param_8
                                    ?
                                    <i className={cardFieldsData.variant_param_8 + " mr-1 mt-1"} />
                                    :
                                    ""
                            }
                            <span dangerouslySetInnerHTML={{ __html: datas[1] }} />
                            {
                                cardFieldsData.variant_param_9
                                    ?
                                    <i className={cardFieldsData.variant_param_9 + " ml-1 mt-1"} />
                                    :
                                    ""
                            }
                        </div>
                    )

                }

            </div>
            <Outer
                isOuterOpen={isOpen}
                outerURL={datas[0]}
                onOpen={() => onOpen(!isOpen)}
            />
        </>
        :
        null
}
export default CardFieldNavlink