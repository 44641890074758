import React, { Component } from "react"

import { __ } from "react-pe-utilities"


class PlaceDialogContent extends Component {
  render() {
    const {
      action_type,
      height,
      class_name,
      style,
      action_youtube_id,
      action_simple_text_dialog,
    } = this.props
    switch (action_type) {
      case "youtube_dialog":
        return (
          <iframe
            width="100%"
            height={height}
            className={class_name}
            style={style}
            src={`https://www.youtube.com/embed/${action_youtube_id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )
      case "inner_link":
      case "outer_url":
      case "simple_text_dialog":
      default:
        return <div dangerouslySetInnerHTML={{ __html: action_simple_text_dialog }} />
    }
  }
}
export default PlaceDialogContent
