import React from "react"
import { Button, Intent } from "@blueprintjs/core"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"
import {LayoutIcon} from 'react-pe-useful'
import { initArea, widgetAreas } from  "react-pe-utilities" 
import { AppToaster } from 'react-pe-useful'
import Widgets from "./adminWidget/Widgets"
import { help_url } from "react-pe-layouts"
import { withApollo } from "react-apollo"
import { compose } from "recompose"
import gql from "graphql-tag"

class AdminWidgetView extends BasicState {
  basic_state_data() {
    return {
      widgetAreas: widgetAreas(),
      currArea: "layout-left",
      curBtn: null,
      isLeftClosed: window.innerWidth < 760,
      isUpdate:false
    }
  }

  getRoute = () => "admin-widgets"

  onCurrArea = (evt) => {
    const currArea = evt.currentTarget.getAttribute("area")
    this.setState({ currArea, collapseIsOpen: false })
  }

  onUpdate = (area) => {
    const widgetAreas = { ...this.state.widgetAreas }
    widgetAreas[this.state.currArea].area = area
    this.setState({ widgetAreas, isUpdate : true } ) 
  }
  onSaveWidgets = () => {
		
		const changeWidgetArea = gql`mutation changeWidgetArea( $input: WidgetAreaInput ) 
		{
			changeWidgetArea( input: $input )
		}`;
		const json = {json : JSON.stringify( this.state.widgetAreas ) }
		console.log(json);
		this.props.client.mutate({
			mutation: changeWidgetArea,
			variables: { input: json },
			update: (store, { data: { changeWidgetArea } }) => {
				this.setState({ 
					isUpdate:false
				})
				AppToaster.show({
					intent: Intent.SUCCESS,
					icon: "tick",
					message: "Success saved widgets area. Reload application to view changes ",
				})
			},
		});
  }

  render() {
    let i = 0;
    const menus_selector = []
    const curWidgets = this.state.widgetAreas[this.state.currArea]
    for (const widget in this.state.widgetAreas) {
      const className = widget === this.state.currArea ? "menu-elem active" : "menu-elem"
      menus_selector.push(
        <div key={i} area={widget} onClick={this.onCurrArea} className={className}>
          <span className="font-weight-bold">
            {__(this.state.widgetAreas[widget].title)}
          </span>
          <span className="small ml-4 opacity_75">
            {__(this.state.widgetAreas[widget].description)}
          </span>
          <div className="members-col hint hint--left" data-hint={__("Widgets length")}>
            {this.state.widgetAreas[widget].area.length}
          </div>
        </div>,
      )
      i++
    }
    const leftClass = this.state.isLeftClosed
      ? "tutor-left-aside-2 menu-aside closed"
      : "tutor-left-aside-2 menu-aside"
    const mainClass = this.state.isLeftClosed
      ? "tutor-main-2 pr-0 opened"
      : "tutor-main-2 pr-0"
    // const canClass = this.state.canUpdate
    //   ? "btn btn-danger"
    //   : "hidden"
    const help = this.props.help_url
			?
			<span>
				<Button 
					icon="help" 
					minimal 
					onClick={ () => window.open( help_url() + this.props.help_url, '_blank') } 
				/>
			</span> 
			:
			null
    return (
      <div className="layout-state p-0">
        <div className="tutor-row menu-row">
          <div className={leftClass}>
            <div>
              <div className="layout-state-head menu-header-22 m-0">
                <LayoutIcon
                  isSVG
                  src={this.state.route.icon}
                  className="layout-state-logo "
                />
                <div className="layout-state-title">
                  {help} 
                  {__(this.state.route.title)} 
                </div>
              </div>

              <div className="tutor-menu m-3 text-center small ">
                {__("Select widget area and push widgets to it")}
              </div>
              <div>
                {menus_selector}
              </div>
              {
                initArea("admin-widget-left-aside", { ...this.props })
              }

            </div>
          </div>
          <div className={mainClass}>
            <div className="clapan-left" onClick={() => this.setState({ isLeftClosed: !this.state.isLeftClosed })}>
              <div className={`fas fa-caret-${!this.state.isLeftClosed ? "left" : "right"}`} />
            </div>
            <div className="menu-header-22 flex-centered p-3">
              <Button
                className="w-100 h-100 ml-4"
                minimal={ !this.state.isUpdate }
                intent={ this.state.isUpdate ? Intent.DANGER : Intent.NONE }
                disabled={ !this.state.isUpdate }
                onClick={this.onSaveWidgets}
              >
                {__("Update")}
              </Button>
            </div>
            <div className="p-5 w-100">
              <div className="w-100">
                <div className="title lead text-center ">
                  <span>
                    {__(this.state.widgetAreas[this.state.currArea].title)}
                  </span>
                </div>
                <div className=" text-center small my-2 ">
                  {__(this.state.widgetAreas[this.state.currArea].description)}
                </div>
              </div>
              <Widgets
                {...curWidgets}
                currArea={this.state.currArea}
                onUpdate={this.onUpdate}
              />
            </div>
          </div>
          <div className="tutor-right-aside-2">
            {
              initArea(
                "admin-menu-widget-aside",
                { ...this.props },
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
export default compose(
	withApollo, 
)(AdminWidgetView)
