
import Layouts from "react-pe-layouts";
import __string from "../../../assets/img/landing/string.svg"
import __icon from "../../../assets/img/landing/card/icon.svg"
import __image from "../../../assets/img/landing/picture.svg"
import __button from "../../../assets/img/landing/button.svg"
import __price from "../../../assets/img/landing/price.svg"
import __divider from "../../../assets/img/landing/divider.svg"
import __section from "../../../assets/img/landing/carousel.svg"
import __personal_links from "../../../assets/img/landing/personal_links.svg"
import __contact_form from "../../../assets/img/landing/email.svg"
import __simple_icon from "../../../assets/img/landing/card/simple_icon.svg"
import __wave_icon from "../../../assets/img/landing/card/wave_icon.svg"
import __laurel_icon from "../../../assets/img/landing/card/laurel_icon.svg"
import __serpantine_icon from "../../../assets/img/landing/card/serpantine_icon.svg"
import __check_label from "../../../assets/img/landing/check_label.svg"
import __tag from "../../../assets/img/landing/card/tag.svg"
import __person from "../../../assets/img/landing/card/person.svg"

import __corner1 from "../../../assets/img/landing/card/corner1.svg"
import __corner2 from "../../../assets/img/landing/card/corner2.svg"
import __corner3 from "../../../assets/img/landing/card/corner3.svg"
import __corner4 from "../../../assets/img/landing/card/corner4.svg"

import __plainText from "../../../assets/img/landing/card/plainText.svg"
import __title from "../../../assets/img/landing/card/title.svg"
import __corner from "../../../assets/img/landing/card/corner.svg"
import __pennon from "../../../assets/img/landing/card/pennon.svg"
import __pendant from "../../../assets/img/landing/card/pendant.svg"
import __kernel from "../../../assets/img/landing/card/kernel.svg"
import __brunch from "../../../assets/img/landing/card/brunch.svg"
import __description from "../../../assets/img/landing/card/description.svg"
import __corner_pennon_4 from "../../../assets/img/landing/card/corner_pennon_4.svg"

import __none_over from "../../../assets/img/landing/card/none_over.svg"
import __approximation_over from "../../../assets/img/landing/card/approximation_over.svg"
import __approximation_rotation_over from "../../../assets/img/landing/card/approximation_rotation_over.svg"

import __crop_top_left from "../../../assets/img/landing/card/crop_top_left.svg"
import __crop_top_right from "../../../assets/img/landing/card/crop_top_right.svg"
import __crop_bottom_left from "../../../assets/img/landing/card/crop_bottom_left.svg"
import __crop_bottom_right from "../../../assets/img/landing/card/crop_bottom_right.svg"

export const getExternalCardMatrixes = () => {
    const modules = Layouts().modules;
    let extendedCardMatrix = []
    //search special widgets in modules 
    Object.keys(modules).forEach(plugin => {
        Object.keys(modules[plugin].widgets).forEach(widget => {
            if( modules[plugin].widgets[widget].extended_landing )
            {
                const extended = require.context( `modules`, true, /\.json$/ )
                extended.keys()
                    .forEach(key => {
                        let key1 = key.replace("./", "").split(".").slice(0, -1).join(".").split("/")
                        key1 = key1[key1.length - 1]
                        if( key1 === "extended_card_matrix")
                        {
                            //console.log( key, extended(key) )
                            extendedCardMatrix = [ ...extendedCardMatrix, ...extended(key) ]
                        } 
                    })
            }
        });
    }); 
    return extendedCardMatrix
}
export function card_matrix() {
    return [
        card_matrix_empty(),
        card_matrix_icon(),
        card_matrix_string(),
        card_matric_check(),
        card_matrix_media(),
        card_matrix_navlink(),
        card_matrix_outerlink(),
        card_matrix_price(),
        card_matrix_personal_links(),
        //card_matrix_cf(),
        card_matrix_divider(),
        card_matrix_section(),
        //card_matrix_tag(),
        card_matrix_person(),
        ...getExternalCardMatrixes()
    ]
}
export const card_matrix_person = () =>
{
    return {
        type: "person",
        title: "Person",
        icon: __person,
        variants: [
            {
                title: "compact",
                example: "Элеонора Кукуй-Заоконская=====cardSeparator=======author=====cardSeparator=======https://preview.treethemes.com/hazel/demo1/wp-content/uploads/sites/3/2021/04/t4.jpg",
                variant_param_2: {
                    type: "slider",
                    min: 50,
                    max:300,
                    step_size:10,
                    label_step_size:50,
                    hidden: 0,
                    title: "Thumbnail size"
                },
                variant_param_3: {
                    type: "slider",
                    min: 0,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Thumbnail rounded"
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Thumbnail's css style"
                },
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Name css style"
                },
                variant_param_6: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Meta css style"
                },
            }, 
            {
                title: "sparse",
                example: "Элеонора Кукуй-Заоконская=====cardSeparator=======author=====cardSeparator=======https://preview.treethemes.com/hazel/demo1/wp-content/uploads/sites/3/2021/04/t4.jpg",
                variant_param_2: {
                    type: "slider",
                    min: 50,
                    max:300,
                    step_size:10,
                    label_step_size:50,
                    hidden: 0,
                    title: "Text object's style"
                },
                variant_param_3: {
                    type: "slider",
                    min: 0,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Thumbnail rounded"
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Thumbnail's css style"
                },
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Name css style"
                },
                variant_param_6: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Meta css style"
                },
            }, 
        ],
    }
}
export const card_matrix_tag = () =>
{
    return {
        type: "tag",
        title: "Tag",
        icon: __tag,
        variants: [
            {
                title: "Simple tag",
                example: "Tag",
            }, 
        ],
    }
}

export const card_matrix_empty = () => {
    return {
        type: "empty",
        title: "Empty",
        icon: "",
        variants: [
            {
                title: "plain empty",
                img: "",
                icon_opacity: 0.5,
                example: " ",
            },
            {
                title: "title",
                example: " ",
                icon_opacity: 0.5,
                img: __title,
            },
            {
                title: "corner",
                example: " ",
                icon_opacity: 0.5,
                img: __corner,
                variant_param_1: {
                    type: "image_radio",
                    hidden: 1,
                    values: [
                        {
                            _id: 0,
                            title: "Top left",
                            img: __corner1,
                            height: 40,
                        },
                        {
                            _id: 1,
                            title: "Top right",
                            img: __corner2,
                            height: 40,
                        },
                        {
                            _id: 2,
                            title: "Bottom left",
                            img: __corner3,
                            height: 40,
                        },
                        {
                            _id: 3,
                            title: "Bottom right",
                            img: __corner4,
                            height: 40,
                        },
                    ],
                    default: "0",
                    title: "Position",
                },
                variant_param_2: {
                    type: "boolean",
                    hidden: 0,
                    title: "Pull to Card's edge",
                },
                variant_param_3: {
                    type: "image_radio",
                    hidden: 0,
                    title: "Corner type",
                    values: [
                        {
                            _id: "corner",
                            title: "Corner",
                            img: __corner4,
                            height: 70,
                        },
                        {
                            _id: "pennon",
                            title: "Pennon",
                            img: __corner_pennon_4,
                            height: 70,
                        },
                    ],
                },
                variant_param_4: {
                    type: "slider",
                    hidden: 0,
                    title: "pennon heigth",
                    min: 30,
                    max: 120,
                    step_size: 1,
                    label_step_size: 5,
                    demand:
                    {
                        field: "variant_param_3",
                        value: ["pennon"],
                    },
                },
            },
            {
                title: "Pennon",
                example: " ",
                icon_opacity: 0.5,
                img: __pennon,
            },
            {
                title: "Dart",
                example: " ",
                icon_opacity: 0.5,
                img: __pendant,
                variant_param_1: {
                    type: "number",
                    hidden: 1,
                    default: 80,
                    title: "Arrowhead width",
                },
                variant_param_2: {
                    type: "number",
                    hidden: 1,
                    default: 94,
                    title: "Pin end",
                },
                variant_param_3: {
                    type: "number",
                    hidden: 1,
                    default: 60,
                    title: "Arrowhead span",
                },

            },
            {
                title: "brunch",
                example: " ",
                icon_opacity: 0.5,
                img: __brunch,
            },
            {
                title: "kernel",
                icon_opacity: 0.5,
                img: __kernel,
                example: " ",
                variant_param_1: {
                    type: "image_radio",
                    hidden: 1,
                    title: "Arabesques",
                    values:
                        [
                            {
                                _id: 1,
                                title: "simple line",
                                img: __simple_icon,
                                height: 90,
                            },
                            {
                                _id: 2,
                                title: "wave",
                                img: __wave_icon,
                                height: 90,
                            },
                            {
                                _id: 3,
                                title: "Laurel",
                                img: __laurel_icon,
                                height: 90,
                            },
                            {
                                _id: 4,
                                title: "serpantine",
                                img: __serpantine_icon,
                                height: 90,
                            },

                        ],
                },
                variant_param_2: {
                    type: "slider",
                    hidden: 1,
                    title: "Fatness",
                    min: 1,
                    max: 60,
                    step_size: 1,
                    label_step_size: 10,
                    demand:
                    {
                        field: "variant_param_1",
                        value: [3],
                    },
                },
                variant_param_3: {
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 1,
                    title: "Center color",
                    demand:
                    {
                        field: "variant_param_1",
                        value: [3],
                    },
                },
            },
            {
                title: "Monolog",
                description: "Comix style bubble",
                icon_opacity: 0.5,
                example: "",
                variant_param_2: {
                    type: "slider",
                    hidden: 1,
                    min: 0,
                    max: 35,
                    step_size: 1,
                    label_step_size: 10,
                    title: "Bubble's tongue power",
                },
                variant_param_3: {
                    type: "slider",
                    hidden: 1,
                    min: 0,
                    max: 100,
                    step_size: 1,
                    label_step_size: 10,
                    title: "Bubble's tongue position",
                    default: 50
                },
            },
            {
                title: "description",
                icon_opacity: 0.5,
                img: __description,
                example: "",

            },

        ]
    }
}
export const card_matrix_icon = () => {
    return {
        type: "icon",
        title: "Icon",
        icon: __icon,
        variants: [
            {
                title: "simple",
                img: "",
                icon_opacity: 0.5,
                example: `fa fa-home fa-1x`,
                variant_param_5:
                { 
                    type: "landing_object",
                    hidden: 0,
                    title: "Icon's back color",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                },
                variant_param_6:
                {
                    type: "slider",
                    hidden: 0,
                    title: "Icon size",
                    min: 15,
                    max: 120,
                    step_size: 1,
                    label_step_size: 5,
                },
                variant_param_9:
                {
                    type: "slider",
                    hidden: 0,
                    title: "Around icon border width",
                    min: 0,
                    max: 50,
                    step_size: 1,
                    label_step_size: 5,
                },
                variant_param_8:
                {
                    type: "slider",
                    hidden: 0,
                    title: "Around icon border padding",
                    min: 0,
                    max: 120,
                    step_size: 1,
                    label_step_size: 5,
                },
                variant_param_10:
                {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Icon style"
                },
            }
        ],
    }
}
export const card_matrix_string = () => {
    return {
        type: "string",
        title: "String",
        icon: __string,
        variants: [
            {
                title: "plain text",
                img: __plainText,
                icon_opacity: 0.5,
                example: "Lorem ipsum dolor sit amet ",                
                variant_param_1: {
                    type: "radio",
                    hidden: 0,
                    values: [
                        {
                            _id: "0",
                            title: "Full demonstration"
                        },
                        {
                            _id: "1",
                            title: "Crop by «more»" 
                        }, 
                    ],
                    default: "0",
                    title: "Position and crop",
                },
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },
            },
            {
                title: "title",
                example: "Lorem ipsum",
                icon_opacity: 0.5,
                img: __title,
                
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },
            },
            {
                title: "corner",
                example: "01",
                icon_opacity: 0.5,
                img: __corner,
                variant_param_1: {
                    type: "image_radio",
                    hidden: 1,
                    values: [
                        {
                            _id: 0,
                            title: "Top left",
                            img: __corner1,
                            height: 40,
                        },
                        {
                            _id: 1,
                            title: "Top right",
                            img: __corner2,
                            height: 40,
                        },
                        {
                            _id: 2,
                            title: "Bottom left",
                            img: __corner3,
                            height: 40,
                        },
                        {
                            _id: 3,
                            title: "Bottom right",
                            img: __corner4,
                            height: 40,
                        },
                    ],
                    default: "0",
                    title: "Position",
                },
                variant_param_2: {
                    type: "boolean",
                    hidden: 0,
                    title: "Pull to Card's edge",
                },
                variant_param_3: {
                    type: "image_radio",
                    hidden: 0,
                    title: "Corner type",
                    values: [
                        {
                            _id: "corner",
                            title: "Corner",
                            img: __corner4,
                            height: 70,
                        },
                        {
                            _id: "pennon",
                            title: "Pennon",
                            img: __corner_pennon_4,
                            height: 70,
                        },
                    ],
                },
                variant_param_4: {
                    type: "slider",
                    hidden: 0,
                    title: "pennon heigth",
                    min: 30,
                    max: 120,
                    step_size: 1,
                    label_step_size: 5,
                    demand:
                    {
                        field: "variant_param_3",
                        value: ["pennon"],
                    },
                },
                
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },
            },
            {
                title: "Pennon",
                example: "Lorem ipsum",
                icon_opacity: 0.5,
                img: __pennon,
            },
            {
                title: "Dart",
                example: "Lorem ipsum",
                icon_opacity: 0.5,
                img: __pendant,
                variant_param_1: {
                    type: "number",
                    hidden: 1,
                    default: 80,
                    title: "Arrowhead width",
                },
                variant_param_2: {
                    type: "number",
                    hidden: 1,
                    default: 94,
                    title: "Pin end",
                },
                variant_param_3: {
                    type: "number",
                    hidden: 1,
                    default: 60,
                    title: "Arrowhead span",
                },
                
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },

            },
            {
                title: "brunch",
                example: "01",
                icon_opacity: 0.5,
                img: __brunch,
            },
            {
                title: "kernel",
                icon_opacity: 0.5,
                img: __kernel,
                example: "01",
                variant_param_1: {
                    type: "image_radio",
                    hidden: 1,
                    title: "Arabesques",
                    values:
                        [
                            {
                                _id: 1,
                                title: "simple line",
                                img: __simple_icon,
                                height: 90,
                            },
                            {
                                _id: 2,
                                title: "wave",
                                img: __wave_icon,
                                height: 90,
                            },
                            {
                                _id: 3,
                                title: "Laurel",
                                img: __laurel_icon,
                                height: 90,
                            },
                            {
                                _id: 4,
                                title: "serpantine",
                                img: __serpantine_icon,
                                height: 90,
                            },

                        ],
                },
                variant_param_2: {
                    type: "slider",
                    hidden: 1,
                    title: "Fatness",
                    min: 1,
                    max: 60,
                    step_size: 1,
                    label_step_size: 10,
                    demand:
                    {
                        field: "variant_param_1",
                        value: [3],
                    },
                },
                variant_param_3: { 
                    hidden: 1,
                    title: "Center color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    demand:
                    {
                        field: "variant_param_1",
                        value: [3],
                    },
                },
                
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },
            },
            {
                title: "Monolog",
                description: "Comix style bubble",
                icon_opacity: 0.5,
                example: "Lorem ipsum dolor sit amet, consectetur",
                variant_param_2: {
                    type: "slider",
                    hidden: 1,
                    min: 0,
                    max: 35,
                    step_size: 1,
                    label_step_size: 10,
                    title: "Bubble's tongue power",
                },
                variant_param_3: {
                    type: "slider",
                    hidden: 1,
                    min: 0,
                    max: 100,
                    step_size: 1,
                    label_step_size: 10,
                    title: "Bubble's tongue position",
                    default: 50
                },
                
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },
            },
            {
                title: "description",
                icon_opacity: 0.5,
                img: __description,
                example: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non luctus risus. Maecenas bibendum dapibus arcu ac vulputate. Etiam feugiat aliquam viverra.",
                
                variant_param_5: {
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Text object's style"
                },
            },
        ],
    }
}
export const card_matric_check = () => {
    return {
        type: "check",
        title: "Check label",
        icon: __check_label,
        variants: [
            {
                title: "plain",
                img: "",
                icon_opacity: 0.5,
                example: ["Lorem ipsum", "1"],
            }
        ]
    }
}
export const card_matrix_media = () => {
    return {
        type: "media",
        title: "Image",
        icon: __image,
        variants: [
            {
                title: "Full",
                description: "full width thumbnail",
                example: "/assets/img/landing/bilibin1.jpg",
                variant_param_4: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "Style",
                    hidden: 1,
                    title: "Image's SCC",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 1,
                    title: "Open full on click",
                },
                variant_param_7: {
                    type: "boolean",
                    hidden: 1,
                    title: "Enable bottom muar",
                },
                variant_param_8: { 
                    hidden: 1,
                    title: "Muar color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    demand:
                    {
                        field: "variant_param_7",
                        value: [1, true, "1"],
                    },
                },
            },
            {
                title: "Pennon",
                description: "outline centered  rounded pictogramm",
                example: "/assets/img/landing/bilibin1.jpg",
                variant_param_5: {
                    type: "image_radio",
                    hidden: 1,
                    title: "Mouse over reaction",
                    values:
                        [
                            {
                                _id: 1,
                                title: "None",
                                img: __none_over,
                                height: 90,
                            },
                            {
                                _id: 2,
                                title: "approximation",
                                img: __approximation_over,
                                height: 90,
                            },
                            {
                                _id: 3,
                                title: "approximation and rotation",
                                img: __approximation_rotation_over,
                                height: 90,
                            },
                        ],
                },
                variant_param_4: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "Style",
                    hidden: 1,
                    title: "Image's SCC",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 1,
                    title: "Open full on click",
                },
                variant_param_7: {
                    type: "boolean",
                    hidden: 1,
                    title: "Enable bottom muar",
                },
                variant_param_8: { 
                    hidden: 1,
                    title: "Muar color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    demand:
                    {
                        field: "variant_param_7",
                        value: [1, true, "1"],
                    },
                },
            },
            {
                title: "Crystall",
                description: "inline centered squared pictogramm",
                example: "/assets/img/landing/bilibin1.jpg",
                variant_param_1: {
                    type: "image_radio",
                    hidden: 1,
                    title: "Crop",
                    values:
                        [
                            {
                                _id: 1,
                                title: "Top left",
                                img: __crop_top_left,
                                height: 50,
                            },
                            {
                                _id: 2,
                                title: "Top right",
                                img: __crop_top_right,
                                height: 50,
                            },
                            {
                                _id: 3,
                                title: "Bottom left",
                                img: __crop_bottom_left,
                                height: 50,
                            },
                            {
                                _id: 4,
                                title: "Bottom right",
                                img: __crop_bottom_right,
                                height: 50,
                            },
                        ],
                },
                variant_param_2: {
                    type: "number",
                    hidden: 1,
                    title: "Crop size",
                },
                variant_param_4: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "Style",
                    hidden: 1,
                    title: "Image's SCC",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 1,
                    title: "Open full on click",
                },
                variant_param_7: {
                    type: "boolean",
                    hidden: 1,
                    title: "Enable bottom muar",
                },
                variant_param_8: { 
                    hidden: 1,
                    title: "Muar color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    demand:
                    {
                        field: "variant_param_7",
                        value: [1, true, "1"],
                    },
                },
            },
            {
                title: "Sausage",
                description: "around edges",
                example: "/assets/img/landing/bilibin1.jpg",
                variant_param_4: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "Style",
                    hidden: 1,
                    title: "Image's SCC",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 1,
                    title: "Open full on click",
                },
            },
            {
                title: "Monolog",
                description: "Comix style bubble",
                example: "/assets/img/landing/bilibin1.jpg",
                /*	*/
                variant_param_2: {
                    type: "slider",
                    hidden: 1,
                    min: 0,
                    max: 35,
                    step_size: 1,
                    label_step_size: 10,
                    title: "Bubble's tongue power",
                },
                variant_param_3: {
                    type: "slider",
                    hidden: 1,
                    min: 0,
                    max: 100,
                    step_size: 1,
                    label_step_size: 10,
                    title: "Bubble's tongue position",
                    default: 50
                },

                variant_param_4: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "Style",
                    hidden: 1,
                    title: "Image's SCC",
                },
                variant_param_5111: { /* ignored*/
                    type: "image_radio",
                    hidden: 1,
                    title: "Mouse over reaction",
                    values:
                        [
                            {
                                _id: 1,
                                title: "None",
                                img: __none_over,
                                height: 90,
                            },
                            {
                                _id: 2,
                                title: "approximation",
                                img: __approximation_over,
                                height: 90,
                            },
                            {
                                _id: 3,
                                title: "approximation and rotation",
                                img: __approximation_rotation_over,
                                height: 90,
                            },
                        ],
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 1,
                    title: "Open full on click",
                },
                variant_param_7: {
                    type: "boolean",
                    hidden: 1,
                    title: "Enable bottom muar",
                },
                variant_param_8: { 
                    hidden: 1,
                    title: "Muar color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    demand:
                    {
                        field: "variant_param_7",
                        value: [1, true, "1"],
                    },
                },
            },
            {
                title: "Mask",
                description: "Library mask",
                example: "/assets/img/landing/bilibin1.jpg",
                variant_param_1: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "mask_sources",
                    hidden: 0,
                    title: "Mask source",
                    kind: "mask_src",
                },
                variant_param_4: {
                    type: "landing_object",
                    visualization: "landing-object",
                    landing_object: "Style",
                    hidden: 1,
                    title: "Image's SCC",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 1,
                    title: "Open full on click",
                },
                variant_param_7: {
                    type: "boolean",
                    hidden: 1,
                    title: "Enable bottom muar",
                },
                variant_param_8: { 
                    hidden: 1,
                    title: "Muar color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    demand:
                    {
                        field: "variant_param_7",
                        value: [1, true, "1"],
                    },
                },
            },
        ],
    }
}
export const card_matrix_navlink = () => {
    return {
        type: "navlink",
        title: "Inner site link button",
        icon: __button,
        variants: [
            {
                title: "primary",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_1: {
                    hidden: 0,
                    title: "Background color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                },
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button?",
                }, 
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "secondary",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "active",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                },
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                },  
            },
            {
                title: "danger",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "success",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "warning",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                },
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                },  
            },
            {
                title: "link",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
        ],
    }
}
export const card_matrix_outerlink = () => {
    return {
        type: "outerlink",
        title: "Outer URL link button",
        icon: __button,
        variants: [
            {
                title: "primary",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_1: {
                    hidden: 0,
                    title: "Background color",
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                },
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button?",
                }, 
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "secondary",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "active",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                },
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                },  
            },
            {
                title: "danger",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "success",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
            {
                title: "warning",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                },
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                },  
            },
            {
                title: "link",
                example: ["Lorem ipsum", "Lorem ipsum"],
                variant_param_2: {
                    type: "radio",
                    hidden: 0,
                    title: "Horizontal align",
                    values:
                        [
                            {
                                _id: "justify-content-start",
                                title: "left"
                            },
                            {
                                _id: "justify-content-center",
                                title: "center"
                            },
                            {
                                _id: "justify-content-end",
                                title: "right"
                            },
                        ],
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Horizontal fill",
                },
                variant_param_4: {
                    type: "slider",
                    min:20,
                    max:100,
                    step_size:1,
                    label_step_size:10,
                    hidden: 0,
                    title: "Button height",
                },
                variant_param_5: {
                    type: "slider",
                    min: 0,
                    max: 180,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Border radius",
                },
                variant_param_6: {
                    type: "boolean",
                    hidden: 0,
                    title: "is borderer button&",
                },
                variant_param_7: {
                   
                    type: "landing_object",
                    landing_object: "Style",
                    visualization: "landing-object",
                    hidden: 0,
                    title: "Button's style"
                }, 
                variant_param_8: {
                    type: "icon",
                    hidden: 0,
                    title: "Left icon",
                }, 
                variant_param_9: {
                    type: "icon",
                    hidden: 0,
                    title: "Right icon",
                }, 
            },
        ],
    }
}
export const card_matrix_price = () => {
    return {
        type: "price",
        title: "Price",
        icon: __price,
        variants: [
            {
                title: "plain price label",
                example: ["11", "$"],
                variant_param_1: {
                    type: "radio",
                    values: [
                        {
                            _id: "bitcoin",
                            title: "bitcoin"
                        },
                        {
                            _id: "dollar",
                            title: "dollar"
                        },
                        {
                            _id: "euro",
                            title: "euro"
                        },
                        {
                            _id: "gg",
                            title: "gg"
                        },
                        {
                            _id: "hryvnia",
                            title: "hryvnia"
                        },
                        {
                            _id: "lira",
                            title: "lira"
                        },
                        {
                            _id: "money",
                            title: "money"
                        },
                        {
                            _id: "pound",
                            title: "pound"
                        },
                        {
                            _id: "ruble",
                            title: "ruble"
                        },
                        {
                            _id: "rupee",
                            title: "rupee"
                        },
                        {
                            _id: "shekel",
                            title: "shekel"
                        },
                        {
                            _id: "tenge",
                            title: "tenge"
                        },
                        {
                            _id: "won",
                            title: "won"
                        },
                        {
                            _id: "yen",
                            title: "yen"
                        }
                    ],
                    hidden: 0,
                    title: "Currency"
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "id",
                            title: "Uniq id"
                        },
                        {
                            _id: "title",
                            title: "Full single"
                        },
                        {
                            _id: "abbreviation",
                            title: "abbreviation"
                        },
                        {
                            _id: "icon",
                            title: "FontAwesome icon"
                        },
                    ],
                    hidden: 0,
                    title: "Currency visualisation type",
                },
                variant_param_3: {
                    type: "boolean", 
                    hidden: 0,
                    title: "old price",
                },
            },
            {
                title: "bold nominal and mini carrence",
                example: ["11", "$"],
                variant_param_1: {
                    type: "radio",
                    values: [
                        {
                            _id: "bitcoin",
                            title: "bitcoin"
                        },
                        {
                            _id: "dollar",
                            title: "dollar"
                        },
                        {
                            _id: "euro",
                            title: "euro"
                        },
                        {
                            _id: "gg",
                            title: "gg"
                        },
                        {
                            _id: "hryvnia",
                            title: "hryvnia"
                        },
                        {
                            _id: "lira",
                            title: "lira"
                        },
                        {
                            _id: "money",
                            title: "money"
                        },
                        {
                            _id: "pound",
                            title: "pound"
                        },
                        {
                            _id: "ruble",
                            title: "ruble"
                        },
                        {
                            _id: "rupee",
                            title: "rupee"
                        },
                        {
                            _id: "shekel",
                            title: "shekel"
                        },
                        {
                            _id: "tenge",
                            title: "tenge"
                        },
                        {
                            _id: "won",
                            title: "won"
                        },
                        {
                            _id: "yen",
                            title: "yen"
                        }
                    ],
                    hidden: 0,
                    title: "Currency"
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "id",
                            title: "Uniq id"
                        },
                        {
                            _id: "title",
                            title: "Full single"
                        },
                        {
                            _id: "abbreviation",
                            title: "abbreviation"
                        },
                        {
                            _id: "icon",
                            title: "FontAwesome icon"
                        },
                    ],
                    hidden: 0,
                    title: "Currency visualisation type",
                },
                variant_param_3: {
                    type: "boolean", 
                    hidden: 0,
                    title: "old price",
                },
            },
            {
                title: "large nominal and mini carrence",
                example: ["11", "$"],
                variant_param_1: {
                    type: "radio",
                    values: [
                        {
                            _id: "bitcoin",
                            title: "bitcoin"
                        },
                        {
                            _id: "dollar",
                            title: "dollar"
                        },
                        {
                            _id: "euro",
                            title: "euro"
                        },
                        {
                            _id: "gg",
                            title: "gg"
                        },
                        {
                            _id: "hryvnia",
                            title: "hryvnia"
                        },
                        {
                            _id: "lira",
                            title: "lira"
                        },
                        {
                            _id: "money",
                            title: "money"
                        },
                        {
                            _id: "pound",
                            title: "pound"
                        },
                        {
                            _id: "ruble",
                            title: "ruble"
                        },
                        {
                            _id: "rupee",
                            title: "rupee"
                        },
                        {
                            _id: "shekel",
                            title: "shekel"
                        },
                        {
                            _id: "tenge",
                            title: "tenge"
                        },
                        {
                            _id: "won",
                            title: "won"
                        },
                        {
                            _id: "yen",
                            title: "yen"
                        }
                    ],
                    hidden: 0,
                    title: "Currency"
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "id",
                            title: "Uniq id"
                        },
                        {
                            _id: "title",
                            title: "Full single"
                        },
                        {
                            _id: "abbreviation",
                            title: "abbreviation"
                        },
                        {
                            _id: "icon",
                            title: "FontAwesome icon"
                        },
                    ],
                    hidden: 0,
                    title: "Currency visualisation type",
                },
                variant_param_3: {
                    type: "boolean", 
                    hidden: 0,
                    title: "old price",
                },
            },
        ],
    }
}
export const card_matrix_personal_links = () => {
    return {
        type: "personal_links",
        title: "Personal links",
        icon: __personal_links,
        variants: [
            {
                title: "pictogramms",
                example: ["vk", "facebook"],
                variant_param_1: {
                    type: "boolean",
                    hidden: 0,
                    title: "is Vertical",
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "none",
                            title: "None"
                        },
                        {
                            _id: "up",
                            title: "up from bottom"
                        },
                        {
                            _id: "down",
                            title: "down from top"
                        },
                        {
                            _id: "left",
                            title: "right from left"
                        },
                        {
                            _id: "right",
                            title: "left from right"
                        },
                    ],
                    hidden: 0,
                    title: "slide from on hover",
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Plume effect",
                    demand:
                    {
                        field: "variant_param_2",
                        value: ["up", "down", "left", "right"],
                    },
                },
                variant_param_4: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 0,
                    title: "Button fill", 
                },
                variant_param_5: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "color",
                    hidden: 0,
                    title: "Button text color", 
                },
                variant_param_6: {                    
                    type: "slider",
                    hidden: 0,
                    title: "Pictogramm size",
                    min: 30,
                    max: 120,
                    step_size: 1,
                    label_step_size: 30,
                },
            },
            {
                title: "square large buttons",
                example: ["vk", "facebook"],
                variant_param_1: {
                    type: "boolean",
                    hidden: 0,
                    title: "is Vertical",
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "none",
                            title: "None"
                        },
                        {
                            _id: "up",
                            title: "up from bottom"
                        },
                        {
                            _id: "down",
                            title: "down from top"
                        },
                        {
                            _id: "left",
                            title: "right from left"
                        },
                        {
                            _id: "right",
                            title: "left from right"
                        },
                    ],
                    hidden: 0,
                    title: "slide from on hover",
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Plume effect",
                    demand:
                    {
                        field: "variant_param_2",
                        value: ["up", "down", "left", "right"],
                    },
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 0,
                    title: "Button fill", 
                },
                variant_param_5: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "color",
                    hidden: 0,
                    title: "Button text color", 
                },
                variant_param_6: {                    
                    type: "slider",
                    hidden: 0,
                    title: "Pictogramm size",
                    min: 20,
                    max: 120,
                    step_size: 1,
                    label_step_size: 20,
                },
            },
            {
                title: "square little buttons",
                example: ["vk", "facebook"],
                variant_param_1: {
                    type: "boolean",
                    hidden: 0,
                    title: "is Vertical",
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "none",
                            title: "None"
                        },
                        {
                            _id: "up",
                            title: "up from bottom"
                        },
                        {
                            _id: "down",
                            title: "down from top"
                        },
                        {
                            _id: "left",
                            title: "right from left"
                        },
                        {
                            _id: "right",
                            title: "left from right"
                        },
                    ],
                    hidden: 0,
                    title: "slide from on hover",
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Plume effect",
                    demand:
                    {
                        field: "variant_param_2",
                        value: ["up", "down", "left", "right"],
                    },
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 0,
                    title: "Button fill", 
                },
                variant_param_5: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "color",
                    hidden: 0,
                    title: "Button text color", 
                },
                variant_param_6: {                    
                    type: "slider",
                    hidden: 0,
                    title: "Pictogramm size",
                    min: 20,
                    max: 120,
                    step_size: 1,
                    label_step_size: 20,
                },
            },
            {
                title: "round small buttons",
                example: ["vk", "facebook"],
                variant_param_1: {
                    type: "boolean",
                    hidden: 0,
                    title: "is Vertical",
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "none",
                            title: "None"
                        },
                        {
                            _id: "up",
                            title: "up from bottom"
                        },
                        {
                            _id: "down",
                            title: "down from top"
                        },
                        {
                            _id: "left",
                            title: "right from left"
                        },
                        {
                            _id: "right",
                            title: "left from right"
                        },
                    ],
                    hidden: 0,
                    title: "slide from on hover",
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Plume effect",
                    demand:
                    {
                        field: "variant_param_2",
                        value: ["up", "down", "left", "right"],
                    },
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 0,
                    title: "Button fill", 
                },
                variant_param_5: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "color",
                    hidden: 0,
                    title: "Button text color", 
                },
                variant_param_6: {                    
                    type: "slider",
                    hidden: 0,
                    title: "Pictogramm size",
                    min: 20,
                    max: 120,
                    step_size: 1,
                    label_step_size: 20,
                },
            },
            {
                title: "round large buttons",
                example: ["vk", "facebook"],
                variant_param_1: {
                    type: "boolean",
                    hidden: 0,
                    title: "is Vertical",
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "none",
                            title: "None"
                        },
                        {
                            _id: "up",
                            title: "up from bottom"
                        },
                        {
                            _id: "down",
                            title: "down from top"
                        },
                        {
                            _id: "left",
                            title: "right from left"
                        },
                        {
                            _id: "right",
                            title: "left from right"
                        },
                    ],
                    hidden: 0,
                    title: "slide from on hover",
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Plume effect",
                    demand:
                    {
                        field: "variant_param_2",
                        value: ["up", "down", "left", "right"],
                    },
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 0,
                    title: "Button fill", 
                },
                variant_param_5: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "color",
                    hidden: 0,
                    title: "Button text color", 
                },
                variant_param_6: {                    
                    type: "slider",
                    hidden: 0,
                    title: "Pictogramm size",
                    min: 20,
                    max: 120,
                    step_size: 1,
                    label_step_size: 20,
                },
            },
            {
                title: "round fancy buttons",
                example: ["vk", "facebook"],
                variant_param_1: {
                    type: "boolean",
                    hidden: 0,
                    title: "is Vertical",
                },
                variant_param_2: {
                    type: "radio",
                    values: [
                        {
                            _id: "none",
                            title: "None"
                        },
                        {
                            _id: "up",
                            title: "up from bottom"
                        },
                        {
                            _id: "down",
                            title: "down from top"
                        },
                        {
                            _id: "left",
                            title: "right from left"
                        },
                        {
                            _id: "right",
                            title: "left from right"
                        },
                    ],
                    hidden: 0,
                    title: "slide from on hover",
                },
                variant_param_3: {
                    type: "boolean",
                    hidden: 0,
                    title: "Plume effect",
                    demand:
                    {
                        field: "variant_param_2",
                        value: ["up", "down", "left", "right"],
                    },
                },
                variant_param_4: {
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "background",
                    hidden: 0,
                    title: "Button fill", 
                },
                variant_param_5: {                    
                    type: "landing_object",
                    landing_object: "ExternalLandingColor",
                    visualization: "landing-object",
                    distination: "color",
                    hidden: 0,
                    title: "Button text color", 
                },
                variant_param_6: {                    
                    type: "slider",
                    hidden: 0,
                    title: "Pictogramm size",
                    min: 20,
                    max: 120,
                    step_size: 1,
                    label_step_size: 20,
                },
            },
        ],
    }
}
export const card_matrix_cf = () => {
    return {
        type: "cf",
        title: "Dialog with Contact Form",
        icon: __contact_form,
        variants: [
            {
                title: "pictogramms",
                example: "Contuct us",
            },
            {
                title: "square large buttons",
                example: "Contuct us",
            },
            {
                title: "square little buttons",
                example: "Contuct us",
            },
            {
                title: "round small buttons",
                example: "Contuct us",
            },
        ],
    }
}
export const card_matrix_divider = () => {
    return {
        type: "divider",
        title: "Divider",
        icon: __divider,
        variants: [
            {
                title: "Straight",
                variant_param_1: {
                    type: "slider",
                    min: 0,
                    max: 100,
                    step_size: 1,
                    label_step_size: 10,
                    hidden: 0,
                    title: "Width",
                },
            },
            {
                title: "Icon",
            },
            {
                title: "Arabesque",
            },
            {
                title: "Arabesque",
            },
        ],
    }
}
export const card_matrix_section = () => {
    return {
        type: "section",
        title: "Section",
        icon: __section,
        variant_param_1: {
            type: "radio",
            hidden: 0,
            title: "Direction",
            values:[
                {
                    _id:"vertical",
                    title:"Vertical"
                },
                {
                    _id:"horizontal",
                    title:"Horizontal"
                }
            ]
        } 
    }
}