import React from 'react'
import PropTypes from 'prop-types'

const CriteryRatingViewDisplay = props => {
    return <>
        <div className="footer-widget lead">{( props.rating || 0).toFixed(2)}</div>		
    </>
}

CriteryRatingViewDisplay.propTypes = {}

export default CriteryRatingViewDisplay