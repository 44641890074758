import React, { Component, } from "react"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import gql from "graphql-tag"
import { __ } from "react-pe-utilities"

class UsersLockLabelWidget extends Component {
	state = {
		col: 0,
	}

	componentDidMount() {
		 if ( this.props.data && this.props.data.data_type == "User") 
		{
			const query = gql`
				query getFullUserBlockedCount
				{
					getFullUserBlockedCount
				}
			`
			this.props.client.query({ query })
				.then((result) => {
					// console.log( result );
					this.setState({ col: result.data.getFullUserBlockedCount })
				})
		}
	}

	componentWillUnmount() {

	}

	render() {
		console.log(this.props);
		return true
			?
			true
				?
				<div className="menu-labla hint hint--left" data-hint={__("Is locked")}>
					{this.state.col}
				</div>
				:
				null
			:
			null
	}
}
export default compose(
	withApollo,
)(UsersLockLabelWidget)
