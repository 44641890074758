import React, { Component } from "react"  
import TeamMember from "./TeamMember"
import {LayoutIcon} from 'react-pe-useful'
import EditLabel from "./EditLabel" 
import { components } from "./data/components"

class Team extends Component {
  render() {
    const { columns, type, is_edit } = this.props
    const { team, class_name, style } = this.props.data
    console.log(columns)
    return team && Array.isArray(team)
      ?
      <div className={`${columns} landing-team ${class_name}`} style={style}>
      {
				team.map((e, i) => <TeamMember {...e} key={i} is_edit={is_edit} />)
			}
      </div>
      :	
      <div
        className={` landing-empty ${class_name}`}
        style={{ ...style, height: 300 }}
      >
        <LayoutIcon
          src={components()[this.props.type].icon}
          className=" layout-icon-giant "
        />
        <div className="lead text-white">
          { components()[this.props.type].title }
        </div>
        <EditLabel
          {...this.props}
          source={type}
          onEdit={this.props.onEdit}
          isBtn
        />
      </div>
  }
}
export default Team
