import React, { useState } from "react"
import { __ } from "react-pe-utilities/dist"
import Layouts from "react-pe-layouts"
import FieldInput from "react-pe-scalars/dist"
import ExtendsParamsScalar from "../views/editorCabinet/ExtendsParamsScalar"
import { Collapse } from "@blueprintjs/core"
import MenuChooseEngine from "./MenuChooseEngine"

const PageSettingWidget = props =>
{
    const [value, setValue]= useState(props.value)
    const onField = (value, field) =>
    {
        props.onChange(value, field)
        setValue(value)
    }
    switch(props.visualisationType)
    {
        case "pageType":            
            const allComponents = Object.keys(Layouts().views)
                .filter(view => {
                    return Layouts().views[view].is_cabinet
                })
                .map( view => {
                    // console.log(__( Layouts().views[view].description ))
                    return {
                        _id: view,
                        title: __( Layouts().views[view].title ),
                        commentary: __( Layouts().views[view].description ),
                    } 
                })
            return <FieldInput
                type="radio"
                field="component"
                title={__("Component")}
                vertical
                commentary={__("Choose one of preset component to leading contents")}
                value={props.value}
                values={allComponents}
                onChange={onField}
            />
        case "menu":
            // console.log(props)
            return <MenuChooseEngine 
                { ...props }
                value={ value }
                field="menu"
                onChange={ onField }
            /> 
        case "pageParams":
        default:
            const comp = Layouts().views[props.origin.component]
            return <Collapse isOpen={ typeof comp !== "undefined" }>
                {
                    Array.isArray( comp?.fields )
                        ?
                        comp?.fields.map(( field, i ) =>
                            {
                                const f = field.extend_params.title
                                return <ExtendsParamsScalar
                                    key={ i }
                                    type="extend_params"
                                    field="extend_params"
                                    title={ __(f ? f : "Extends params") }
                                    value={ props.value }
                                    origin={ props.origin }
                                    on={ onField }
                                    onChange={ onField }
                                    editable 
                                    vertical
                                    not_clear
                                />
                            })
                        
                        :
                        null
                }
                
            </Collapse>
    }
}
export default PageSettingWidget