import React from "react" 
import { useState, Component } from "react"
import { Slider } from "@blueprintjs/core" 
import WPFestSettings from "./utilities/WPFestSettings"
import Style from "style-it"


class ExpertSlider extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            rating : props.rating,
            id: props.id
        }
    }
    componentDidMount()
    {
        WPFestSettings.addCriteryComponent( this, this.props.category_id )
    }
    componentWillUpdate(nextProps)
    {
        if(nextProps.rating !== this.state.rating)
        {
            this.setState({ rating: nextProps.rating })
        }
    }
    changeRating = rating =>
    {
        this.setState({ rating })
        const summ = WPFestSettings.criteryComponents[this.props.category_id].reduce(
            (prev, curr, i) => {
                if(curr.state.rating >= 0)
                    return prev += curr.state.rating
            }, 
            0
        ) 
        const diff = summ >= 100 ? summ - 100 : 0
        const singleDiff = Math.ceil( diff / (WPFestSettings.criteryComponents[this.props.category_id].length - 1) )
        WPFestSettings.criteryComponents[this.props.category_id].forEach(c => {
            if(c.state.id !== this.state.id)
            {
                const r = rating === 100 ? 0 : c.state.rating - singleDiff
                if(r >= 0)
                {
                    c.setState({ rating: r }) 
                    c.props.onPersentage( r )
                }                
            }
        }) 
        this.props.onPersentage(rating)
    }
    releaseRating = rating =>
    {
        const summ = WPFestSettings.criteryComponents[this.props.category_id].reduce(
            (prev, curr, i) => {
                if(curr.state.rating >= 0)
                    return prev += curr.state.rating
            }, 
            0
        )
        //console.log( summ )
        const diff = summ >= 100 ? summ - 100 : 0
        const singleDiff = Math.ceil( diff / (WPFestSettings.criteryComponents[this.props.category_id].length - 1) )
        setTimeout(() => {
            WPFestSettings.criteryComponents[this.props.category_id].forEach(c => {
                if(c.state.id !== this.state.id)
                {
                    const r = rating === 100 ? 0 : c.state.rating - singleDiff
                    if(r >= 0)
                    {
                        c.setState({ rating: r })
                        c.props.onFinalPersentage( r )
                    }
                    
                }
            })
        }, 200)
        
        this.props.onFinalPersentage(rating)
    }
    render()
    {
        const {rating} = this.state
        return Style.it(
            `
            .bp3-slider-progress.bp3-intent-primary
            {
                background-color: ${ this.props.color }!important;
            }
            `,
             <div className="mr-4"> 
                <Slider
                    min={ 0 }
                    max={ 100 }
                    stepSize={ 1 }
                    labelStepSize={ 10 }
                    onChange={ this.changeRating }
                    onRelease={ this.releaseRating }
                    value={ Number(rating) || 0 }
                    //labelRenderer={ val  => `${val}%`}
                    vertical={ false }
                />
            </div>
        )
    }
    
}

export default ExpertSlider