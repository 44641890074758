import React, { Component } from "react"
import $ from "jquery"
import { withRouter } from "react-router"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import axios from "axios"
import {
	Button, ButtonGroup, Intent, Dialog, Icon, Callout,
} from "@blueprintjs/core"
import gql from "graphql-tag"
import WebFont from "webfontloader"
import { __ } from "react-pe-utilities"
import matrix from "./LandingState/data/matrix"
import SectionContainer from "./LandingState/SectionContainer"
import ToUp from "./LandingState/ToUp"

import { isRole } from "react-pe-utilities"
import {Loading} from 'react-pe-useful'
import LandingEditDialog from "./LandingState/edit/LandingEditDialog"
import PalettePresets from "./LandingState/data/PalettePresets"
import Section, { getDefault, getStyle } from "./LandingState/Section"
import DataContext from "./LandingState/DataContext"

import { AppToaster } from 'react-pe-useful'
import NewSectionDialog from "./LandingState/edit/NewSectionDialog"
import ChoosePaletteDialog from "./LandingState/edit/ChoosePaletteDialog" 
import {PEHelp} from "react-pe-useful" 
import PreBasicState from "./PreBasicState"
import {default as UUID} from "node-uuid";
import LandingView, { getLandingContainer } from "./LandingView"
import CurrentEditPanel from "./LandingState/edit/CurrentEditPanel"
import SectionHandler from "./LandingState/edit/SectionHandler"
import History from "./LandingState/edit/history/History"
import LefyEditPanel from "./LandingState/edit/LefyEditPanel"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB" 
import LandingFlow from "./LandingState/flow/LandingFlow" 
import BooPanel from "./LandingState/edit/BooPanel"

export let landingInstance = null
class LandingEdit extends Component
{
	constructor(props)
	{
		super(props)
		this.state={ 
			...this.basic_state_data(), 
			version: 0
		}
		landingInstance = this
	} 
	basic_state_data() 
	{
		const el = document.createElement("script")
		el.type = "text/javascript"
		el.src = "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes.min.js"
		el.async = true
		el.id = "landing"

		const stl = document.createElement("link")
		stl.type = "text/css"
		stl.src = "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes_birman.css"
		stl.async = true 

		return {
			loading: true,
			isDummy: true,
			is_edit: false,
			isHelpOpen: false,
			s: false,
			fixedChildren: [],
			newSectionType: "html"
		}
	}
	local = this.props.extend_params && this.props.extend_params.local;

	getId() {
		// console.log( this.props.extend_params );
		return this.props.extend_params && this.props.extend_params.id ? this.props.extend_params.id : "landing_page"
	}

	setID(lid) {
		this.loadLanding(lid)
	}

	localLoad(lid) {
		axios.get( "/assets/data/" + lid + ".json" )
		.then(
			(response) => {
				const parsed2 = response.data
				console.log( parsed2 );	
				DataContext.upd(parsed2, false)
				console.log( DataContext.data );
				// console.log( ["Open Sans", ...DataContext.data.landing.fonts.map(e => e.title )] )
				WebFont.load({
					google: {
						families: [
							"Open Sans", 
							...( DataContext.data.landing && DataContext.data.landing.fonts 
									?
									DataContext.data.landing.fonts.map((e) => e.title)
									:
									[]
								)
						],
					},
				})
				this.setState({ loading: false, is_edit: DataContext.data.sections ? DataContext.data.sections.length === 0 : false })
				if (!parsed2 || !parsed2.sections || parsed2.sections.length === 0) {
					this.onLandingClear()
				}
				this.scrollToElement();
			},
			error => console.log(error)
		)
	}

	serverLoad(lid) 
	{		 
		const query = gql`
			query
			{
				getPE_Landing( id : "${lid}" )
				{
					json
				}
			}
		`
		this.props.client.query({ query })
			.then((result) => {
				
				const { json } = result.data.getPE_Landing
				const replaced_json = json.replace(/'/gi, "\"")
				let replaced2_json = replaced_json.replace(/\n/gi, " ").replace(/\s/gi, " ").replace(/\t/gi, " ").replace(/~~/gi, "'")
				// console.log(replaced2_json)
				//replaced2_json = replaced2_json
				replaced2_json = replaced2_json
				replaced2_json = replaced2_json
				let version = 0
				let parsed
				try
				{
					parsed = replaced2_json ? JSON.parse(replaced2_json) : { sections: [] }
					version = parsed.version || 1
				}
				catch(error)
				{
					parsed = { sections: [] }
					console.log(error)
					console.log(replaced2_json)

				}
				parsed = {
					...parsed,
					sections: parsed.sections.filter( section => section && section.type )
				} 
				// console.log( parsed )
				this.draw(parsed)
				if( version > this.state.version )
				{
					// indexDB version older then server
					IndexDB.save( parsed, "id", lid, "peLanding", "peLanding", version )
					
				}
				else if( version === this.state.version)
				{
					// indexDB version equal to server. Drawing from mount is actual  
				}
				else
				{
					// indexDB-version younger then server
					console.log( lid, version, this.state.version )
					this.setServerUpdate( DataContext.data, true )
				} 
			})
	}
	draw = parsed =>
	{
		DataContext.upd(parsed)
		// console.log(parsed );
		if (parsed && parsed.landing) {
			if (parsed.landing.css) {
				$("head").append(`<style id='landing-css'>${parsed.landing.css}</style>`)
			}
			if (parsed.landing.is_hide_header) {
				$(".layout-header").hide()
			}
		} 
		if (!parsed.sections || parsed.sections.length === 0) {
			this.onLandingClear()
		} 
		const fonts = DataContext.data.landing.fonts
			? DataContext.data.landing.fonts.map((e) => e ? e.title : "Open Sans")
			: []
		WebFont.load({
			google: {
				families: ["Open Sans", ...fonts],
			},
		})
		this.scrollToElement()
		this.setState({
			loading: false, 
			is_edit: DataContext.data.sections.length === 0
		})
	}

	loadLanding(lid) 
	{
		this.local ? this.localLoad(lid) : this.serverLoad(lid)
		DataContext.view = this
	}
	componentDidMount() 
	{
		if(this.props.user?.roles.filter(role => role === "administrator" || role === "editor"))
		$("html").css({marginTop : 50, height:"calc(100% - 50px)"})
		window.pe_landing = { section: [] }
		// $(".layout-header").hide();
		document.body.addEventListener("mousemove", this.onMove)
		document.body.addEventListener("keydown", this.onKeyPress)
		document.body.addEventListener("keyup", this.onKeyUp)
		window.addEventListener("resize", this.updateWindowDimensions)
		this.updateWindowDimensions()
		const lid = this.getId()
		// console.log(lid)
		
		IndexDB.find( "peLanding", "peLanding", "id", lid )
			.then(dat => {
				if( dat )
				{
					this.draw(dat.data)
					this.setState({ version: dat.version })
				}
			})
		if(this.local)
		{ 
			this.setID( lid )
		}
		else
		{
			const query = gql`
				query
				{
					getPE_LandingID( id : "${lid}" ) 
				}
			`
			this.props.client.query({ query })
				.then((result) => 
				{
					const json = result.data.getPE_LandingID 
					this.setID(json)
				})
		}
		/**/ 
	}
	componentDidUpdate()
	{
		if(this.state.is_not_update)
		{
			window.onbeforeunload = function() {
				return "NO! NO! NO!";
			}
		}
		else
		{
			window.onbeforeunload = function() {
				 
			}
		}
	}

	componentWillMount() 
	{

	}

	componentWillUnmount() {
		$("html").css({marginTop : 0, height:"auto"})
		$(".layout-header").show()
		document.body.removeEventListener("mousemove", this.onMove)
		document.body.removeEventListener("keydown", this.onKeyPress)
		document.body.removeEventListener("keyup", this.onKeyUp)
		//window.removeEventListener("scroll", this.onscrollHandler)
		window.removeEventListener("resize", this.updateWindowDimensions)
		delete window.pe_landing
		document.body.style.width = "100%"
        document.getElementsByTagName( 'html' )[0].style.width = "100%"
		delete LandingView.pressKeyData
	}

	updateWindowDimensions = () => {
		if (Array.isArray(window.pe_landing.section) && window.pe_landing.section.length > 0) {
			window.pe_landing.section.forEach((e, i) => {
				// console.log( e.state );
				const { visible_lg, visible_sm, visible_ms, visible_xs, } = e.state
				const w = document.body.clientWidth

				const state = {
					section_width: w,
					section_height: document.body.clientHeight,
				}
				// const old = e.is_visible
				// hide all = show all
				const visible_all = !visible_lg && !visible_sm && !visible_ms && !visible_xs
				if (w > 1200) {
					e.is_visible = visible_lg || visible_all
				} else if (w > 740) {
					e.is_visible = visible_sm || visible_all
				} else if (w > 540) {
					e.is_visible = visible_ms || visible_all
				} else {
					e.is_visible = visible_xs || visible_all
				}
				// if( old !== e.is_visible )
				// console.log("state=",state)
				e.setState(state)
				e.updateWidth(w)
			})
		}
		this.setState({section_width : document.body.clientWidth})
	}

	onMove = (evt) => {
		window.mouseX = evt.offsetX
		window.mouseY = evt.offsetY
	}
	onKeyPress = evt =>
	{
		// console.log(evt)
		LandingView.pressKeyData = {
			altKey	: evt.altKey,
			code	: evt.code,
			ctrlKey	: evt.ctrlKey,
			shiftKey: evt.shiftKey,
			key		: evt.key,
			keyCode	: evt.keyCode,
			which	: evt.which
		}
	}
	onKeyUp = () =>
	{
		LandingView.pressKeyData = undefined
	}
	onFloatSelect = (floatId, floatObject) =>
	{
		// console.log( floatObject )
		if(LandingView.pressKeyData)
		{
			
		}
		else
		{
			DataContext.setFloatSelectID( floatId )
			this.setState({floatSelectID: floatId, floatSelect : floatObject})
		}
		
	}

	render()
	{
		if (this.state.loading) 
		{
			return (
				<div className="layout-state layout-center">
					<Loading />
				</div>
			)
		}
		//console.log(DataContext.data.sections)
		// console.log( this.state.entery, this.state.enteryCount )
		const containerClassName = DataContext.data.landing?.class_name
			?
			DataContext.data.landing?.class_name
			:
			""
		const containerStyle = DataContext.data.landing?.style
			?
			getStyle(DataContext.data.landing?.style)
			:
			{ }
		const landings = Array.isArray(DataContext.data.sections) && DataContext.data.sections.length > 0
			?
			DataContext.data.sections.filter((e, i) => {
				if(typeof this.state.entery === "undefined")
				{
					return true
				}					
				else
				{
					return i >= this.state.entery && i < this.state.entery + this.state.enteryCount
				}
			})
			.map((e, i) => 
			{
				return <SectionContainer key={i} data={e}>
					<Section
						isDummy={this.state.isDummy}
						{...e}
						section_id={e.id} 
						i={i} 
						section_width={ this.state.section_width }
						user={this.props.user}
						is_admin={this.is_admin()}
						is_edit={this.state.is_edit}
						level={0}
						onEdit={this.onEdit}
						onUp={this.onUp}
						onDn={this.onDn}
						onAdd={this.onAdd}
						onDouble={this.onDouble}
						onRnv={this.onRnv}
						onHide={this.onHide}
						onRemoveFloat={this.onRemoveFloat}
						onUpdateFloat={this.onUpdateFloat}
						onClone={this.onCloneFloat}
						onClipboardCopy={this.onClipboardCopy}
						onClipboardPaste={this.onClipboardPaste}
						onWaypointEnter={this.onWaypointEnter}
						onWaypointLeave={this.onWaypointLeave}
						CopyArchor ={this.CopyArchor }
						onFixedAdd={this.onFixedAdd}
						getHelp={this.getHelp}
						onEnterelyLink={ this.copyEnterelyLink }
						onEmbedCode={ this.copyEmbedCode }
						loginUser={this.loginUser}
						boobu
						onFloatSelect={this.onFloatSelect}
						floatSelect = {this.state.floatSelect }
					/>
				</SectionContainer> 
			})
			:
			<div className="p-5">
				<Callout className="p-5 text-center">
					{__("No landing data")}
					{
						this.state.is_edit
							? <>
								<div className="pt-4 d-flex">
									<ButtonGroup
										className="mx-auto drawer"
										vertical={true} fill={true}
										large={true}
									>
										<Button onClick={this.onChoosePresetsOpen} icon="snowflake">
											{__("Choose presets")}
										</Button>
										<Button onClick={this.onAdd} icon="plus">
											{__("Add first section")}
										</Button>
									</ButtonGroup>
									<ChoosePaletteDialog
										isOpen={this.state.isPaletteOpen}
										onEdit={this.onChoosePresets}
										onSet={this.onSetPresets}
										onClose={this.onChoosePresetsOpen}
										value={
											DataContext.data.landing && DataContext.data.landing.palette
												?
												DataContext.data.landing.palette
												:
												[]
										}
									/>
								</div>
							</>
							: null
					}
				</Callout>
			</div>
		let c
		let row
		if ( DataContext.data.landing?.is_blocked ) 
		{
			c = <>
				<div className="landing-container-left-field">
					<div
						style={{ backgroundImage: `url(${DataContext.data.landing.left_image})` }}
					/>
					<div
						style={{
							backgroundColor: DataContext.data.landing.left_color,
							opacity: DataContext.data.landing.left_color_opacity,
						}}
					/>
				</div>
				<LandingFlow
					containerClassName={containerClassName}
					containerStyle={containerStyle}					
					user={this.props.user}
				>
					{landings}	
				</LandingFlow> 
				<div className="landing-container-right-field">
					<div
						style={{ backgroundImage: `url(${DataContext.data.landing.right_image})` }}
					/>
					<div
						style={{
							backgroundColor: DataContext.data.landing.right_color,
							opacity: DataContext.data.landing.right_color_opacity,
						}}
					/>
				</div>
			</> 
			row = " flex-row "
		} 
		else 
		{ 
			c = <LandingFlow 
				containerClassName={`container-float ${ containerClassName }`}
				containerStyle={containerStyle}				
				user={this.props.user}
			>
				{landings}	
			</LandingFlow>
			row = " flex-column "
		}

		const toTop = DataContext.data && DataContext.data.landing && DataContext.data.landing.is_up_to_top_button
			? 
			<ToUp  
				up_to_top_size={DataContext.data.landing.up_to_top_size}
				up_to_top_padding={DataContext.data.landing.up_to_top_padding}
				up_to_top_background={DataContext.data.landing.up_to_top_background}
				up_to_top_position={DataContext.data.landing.up_to_top_position}
			/>
			: 
			null
		
		const fixedChildren = this.state.fixedChildren.map((child, ii) => ({ ...child, key: ii }))
		return (
			<div
				className={`landing-container layout-state p-0 ${row} ${this.state.is_edit ? "is_edit" : ""}` }
			>
				<div
					className="landing-cfIcon-conttainer"
					id="landing-main-float"
					children={[
						...fixedChildren,						
					]}
				/>
				{ toTop }
				{
					this.is_admin()
						?
						!this.state.is_edit
							?
							<div className="landind-edit-cont">
								<BooPanel id={this.getId()} {...this.props} />
								<div
									className="l-inline-edit-btn"
									onClick={this.onEditHandler}
									title={__("Start edit Landing")}
									style={{ position: "relative", top: "auto", right: 0 }}
								>
									<Icon icon="annotation" />
									<div className="ml-2 text-uppercase small">
										{__("Start edit Landing")}
									</div>
								</div>
							</div>
							:
							<>
								<div className="landind-edit-cont">
									<BooPanel  id={this.getId()} {...this.props} />
									<div className="d-flex align-items-center px-2">
										<LefyEditPanel
											undo={this.doUndo}
											redo={this.doRedo}
											setContainer_type={this.setContainer_type}
											setCarousel_flow={this.setCarousel_flow}
											setLanding_flow={this.setLanding_flow}
										/> 
									</div>
									<div className="d-flex h-100 w-100  align-items-center justify-content-end px-2">
										
									</div>
									<div className="d-flex h-100 w-100  align-items-center justify-content-end px-2">
										<CurrentEditPanel
											floatSelect={this.state.floatSelect}
											floatSelectID={this.state.floatSelectID}
											onFloatSelect={this.onFloatSelect}
										/>
									</div>
									<div className="d-flex h-100 align-items-center justify-content-end px-2">
										<div className="d-flex">
											<span className="landing-panel">
												<Button
													intent={Intent.NONE}
													onClick={this.onSettings}
													large={false}
													icon="cog"
													className="mr-1 hint hint--bottom"
													data-hint={__("Settings")}
													minimal
												>		 
												</Button>
												<SectionHandler

												/>
												<Button
													intent={Intent.NONE}
													onClick={this.onHelp}
													large={false}
													icon="help"
													className="mr-1 hint hint--bottom"
													data-hint={__("Help")} 
													minimal
												> 
												</Button>
												<div style={{ width: this.state.is_not_update ? 270 : 0 }} className="landing-upd-cont mr-1">
													<Button
														intent={Intent.SUCCESS}
														onClick={this.onUpdateHandler}
														large={false}
														icon="floppy-disk"
														title={__("Update Landing")}
														style={{ width: 270 }} 
													>
														{__("Update Landing")}
													</Button>
												</div>
												<Button
													intent={Intent.DANGER}
													onClick={this.onEditHandler}
													large={false}
													className="mr-1  hint hint--left" 
													data-hint={__("Finish edit Landing")} 
													icon="cross"
													minimal
												> 
												</Button>
											</span>
										</div>
									</div>
									
								</div>
								<Dialog
									isOpen={this.state.isClearOpen}
									onClose={this.onClearOpen}
									title={__("Clear Landing?")}
									className="little"
								>
									<div className="p-4 layout-centered">
										<div>
											<div className="pb-3">
												{__("Realy clear all content?")}
											</div>
											<ButtonGroup>
												<Button
													intent={Intent.NONE}
													onClick={this.onLandingClear}
													text={__("Yes, clear")}
												/>
												<Button
													intent={Intent.DANGER}
													icon="cross"
													onClick={this.onClearOpen}
												/>
											</ButtonGroup>
										</div>
									</div>
								</Dialog>
								<PEHelp
									isOpen={this.state.isHelpOpen}
									onClose={this.onHelp}
									url={this.state.help_url}
								/>
								<LandingEditDialog
									isOpen={this.state.isLandingEditOpen}
									onClose={this.onSettings}
									data={DataContext.data.landing}
									onEdit={this.onLandingEdit}
									onAdd={this.onAdd}
									onDownload={this.onDownload}
									onLoadChange={this.onLoadChange}
									onClearOpen={this.onClearOpen}
								/>
								<NewSectionDialog
									isOpen={this.state.isNewSectionOpen}
									onClose={this.onNewSection}
									onEdit={this.onAddStart}
								/>
							</>
						: 
						null
				}
				{ c }
				{
					typeof this.state.entery !== "undefined"
						?
						<div className="bg-dark p-2 d-flex justify-content-center ">
							<a 
								className="btn btn-primary" 
								href={`${ window.location.origin }${ window.location.pathname }`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{__( DataContext.data.landing?.title || window.location.origin + window.location.pathname)}
							</a>
						</div>
						:
						null
				}
				{
					DataContext.data.landing?.title
						?
						<div className="position-fixed bottom right hover">
							<a 
								className="small bg-dark text-light px-2 py-1 mx-2"
								href={`${ window.location.origin }${ window.location.pathname }`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{ __( DataContext.data.landing?.title ) }
							</a>
						</div>
						:
						null
				} 
			<History undo={this.doUndo}/>
			</div>
		)
	}
	loginUser = () =>
	{
		if(this.props.loginUser)
		{
			this.props.loginUser()
		}
	}
	getHelp = (help_url) =>
	{
		this.setState({ isHelpOpen: true, help_url:help_url })
		//LandingView.isLockScroll = true
	}
	onHelp = () => {
		this.setState({ isHelpOpen: !this.state.isHelpOpen })
		LandingView.isLockScroll = !this.state.isHelpOpen
	} 
	onLandingEdit = (data) => {
		DataContext.setLanding(data)
		this.setState({ isLandingEditOpen: false, is_not_update: true })
		$("#landing-css")
			.empty()
			.append(data.css)
	}

	onSettings = () => {
		//if (this.state.isLandingEditOpen)
		//	$("body").css({ overflowY: "auto" })
		this.setState({ isLandingEditOpen: !this.state.isLandingEditOpen })
		LandingView.isLockScroll = !this.state.isLandingEditOpen
	}
	onNewSection = data => {
		this.setState({ isNewSectionOpen: !this.state.isNewSectionOpen })
		LandingView.isLockScroll = false
	}

	onLoadChange = (evt) => {
		// console.log(evt.target.files);
		if (evt.target.files[0].type === "application/json") {
			const context = this
			const reader = new FileReader()
			reader.readAsText(evt.target.files[0])
			reader.onload = () => {
				const data = JSON.parse(reader.result)
				//console.log(data)
				if (
					typeof data.sections != "undefined"
						&& typeof data.maxSectionID != "undefined"
						&& typeof data.maxFloatID != "undefined"
						&& typeof data.landing != "undefined"
				) 
				{
					DataContext.upd( data )
					context.setState({ s: !context.state.s })
				} 
				else 
				{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("File is not Landing format"),
					})
				}
			}
		} 
		else 
		{
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				duration: 10000,
				message: __("Choose JSON file."),
			})
		}
	}

	is_admin() 
	{
		return true 
	}

	onEditHandler = () => 
	{
		DataContext.setFloatSelectID( undefined )
		this.setState({ is_edit: !this.state.is_edit, floatSelectID: undefined, floatSelect: undefined })		
		document.body.style.width = "100%"
        document.getElementsByTagName( 'html' )[0].style.width = "100%"
	}

	onLocalUpdate() 
	{
		// console.log( DataContext.data );
		const data = JSON.stringify(DataContext.data)
		const lid = this.getId()
		// console.log( data, lid);
		this.setState({ loading: true })
		//localStorage.setItem(`landing_${lid}`, data)
		this.setState({
			is_edit: !this.state.is_edit,
			loading: false,
			is_not_update: false,
		}) 
	}

	onServerUpdate() 
	{
		this.setServerUpdate( DataContext.data )
	}
	setServerUpdate( object, bg_mode=false )
	{
		let contextData = { ...object }
		contextData.sections = contextData.sections.map((section, i) => {
			let sec = {...section}
			delete sec.user
			delete sec.isDummy
			delete sec.i 
			delete sec.section_width 
			delete sec.is_admin 
			delete sec.is_edit 
			delete sec.level 
			delete sec.className 
			delete sec.children 
			Object.keys(sec).forEach(elem =>
				{
					if(typeof sec[elem] === "function")
					{
						delete sec[elem]
					}
				})
			return sec
		})		
		let version = this.state.version + 1
		contextData.version = version
		// console.log( contextData )
		let data
		try
		{
			data = JSON.stringify( contextData ).replace(/'/g, "~~").replace(/"/g, "'")
		}
		catch(error)
		{	
			console.log( contextData )
			console.log(error)
		}		
		if(!bg_mode)
		{
			this.setState({ loading: true })
		}		
		const lid = this.getId()
		const chagePE_Landing = gql`
			mutation chagePE_Landing
			{
				chagePE_Landing( id : "${lid}", input:"${data}")
			}
		`
		this.props.client.mutate({
			mutation: chagePE_Landing,
			errorPolicy: "all",
			update: (store, response) => {
				// console.log(response)
				this.setState({
					is_edit: !this.state.is_edit,
					loading: false,
					is_not_update: false,
				}) 
			}
		}) 
		
		setTimeout(() => {
			if( this.state.is_not_update )
			{
				this.setState({ 
					loading: false, 
				}) 
				AppToaster.show({
					intent: Intent.DANGER,
					icon: "tick",
					duration: 10000,
					message: __("No or low internet. Saved local"),
				})
			}		
		}, 4000)		
		IndexDB.save( contextData, "id", this.getId(), "peLanding", "peLanding", version )
	}
	scrollToElement = () => 
	{	
		if( this.props.location.hash ) 
		{ 
			const hash = this.props.location.hash.split("?")
			const anchor = hash[0]
			let getParams = {}
			const get = hash[1]
				?
				hash[1].split("&").map(e => e.split("=")).forEach(e => {
					getParams[e[0]] = typeof e[1] !== "undefined" ? e[1] : ""
				})
				:
				null 
			let archorMenu = -1
			let mySection = -1 
			DataContext.data.sections.forEach((section, i) => {
				if( DataContext.data.sections[ i ]?.type === "archor_menu" )
				{
					archorMenu = i
				}
				if(`#${section?.menu?.id}` === anchor )
				{
					mySection = i
				}
			})
			const shiftY = archorMenu >= 0 && mySection >= 0 && mySection > archorMenu
				?
				60
				: 
				0 
			DataContext.data.sections = DataContext.data.sections.map((section, i) => {
				return {
					...section,
					isDummy : i < mySection
				}
			})
			//const targ = document.getElementById( anchor.replace("#", "") ) 
			// if(!targ) return
			if( getParams.entery )
			{
				this.setState({ entery: mySection, enteryCount: parseInt( getParams.entery) || 1 })
			}
			else
			{
				const timer = setInterval(() => { 	
					// console.log( $( anchor ).offset().top - shiftY )
					if( $( anchor ).offset().top - shiftY < 1 )
					{
						clearInterval(timer)
					}
					getLandingContainer().scrollBy({
						behavior : "auto",
						top: $(anchor).offset().top - shiftY
					}) 
				}, 200 )
			}
		}
	}

	onUpdateHandler = () => {
		this.state.fixedChildren = []
		this.local ? this.onLocalUpdate() : this.onServerUpdate()
	}

	onEdit = (data) => {		
		DataContext.updateSection(data.id, data) 
		this.setState({ s: !this.state.s, is_not_update: true })
	}

	onUp = (data) => {
		//console.log("UP", data)
		const sections = [...DataContext.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data - 1, 0, sec)
		DataContext.upd({ ...DataContext.data, sections })
		this.setState({ s: !this.state.s, is_not_update: true })
	}

	onDn = (data) => {
		//console.log("DN", data)
		const sections = [...DataContext.data.sections]
		const sec = { ...sections[data] }
		sections.splice(data, 1)
		sections.splice(data + 1, 0, sec)
		DataContext.upd({ ...DataContext.data, sections })
		this.setState({ s: !this.state.s, is_not_update: true })
	}

	onRnv = (data) => {
		//console.log("RMV", data)
		const sections = [...DataContext.data.sections]
		sections.splice(data, 1)
		DataContext.upd({ ...DataContext.data, sections })
		this.setState({ s: !this.state.s, is_not_update: true })
	}
	onChoosePresetsOpen = () => {
		this.setState({ isPaletteOpen: !this.state.isPaletteOpen })
	}
	onChoosePresets = () => {

	}
	onSetPresets = presets => {
		//console.log(presets);
		const data = {
			landing: {
				...DataContext.data.landing,
				palette: presets
			}
		}
		//console.log(data)
		DataContext.upd(data)
		//console.log(DataContext.data.landing)
		this.setState({ isPaletteOpen: !this.state.isPaletteOpen })
	}
	onAdd = data => {
		//console.log(data)
		this.setState({ isNewSectionOpen: true, newSectionOrder: data })
		LandingView.isLockScroll = true
	}
	onAddStart = (data, type, variant) => {
		// console.log(data, type, variant)
		const sections = [...DataContext.data.sections || []]
		let order = typeof this.state.newSectionOrder === "number" 
				?
				this.state.newSectionOrder 
				: 
				typeof this.state.newSectionOrder === "string"
					?
					PerformancePaintTiming( this.state.newSectionOrder )
					:
					0
		let data1 = { data: {} }
		if (matrix()[type].default) {
			//console.log(DataContext.data.landing.palette[0].id)
			const ifPal = Array.isArray(DataContext.data.landing.palette) && DataContext.data.landing.palette[0]
			const defPal = PalettePresets()[0].id
			const pal = ifPal
				?
				DataContext.data.landing.palette[0].id
				:
				defPal
			//console.log(pal)
			//console.log(DataContext.data.sections, order)
			data1 = {
				data: { ...matrix()[type].default },
				composition: matrix()[type].default.composition,
				background: matrix()[type].default.background,
				current_template_id: DataContext.data.sections && DataContext.data.sections[order]
					?
					DataContext.data.sections[order].current_template_id
					:
					pal
			}
			delete data1.data.hidden
			delete data1.data.composition
			delete data1.data.background
		} 
		if( typeof variant !== "undefined" && matrix()[type].defaults.variants[variant] )
		{
			const ifPal = Array.isArray(DataContext.data.landing.palette) && DataContext.data.landing.palette[0]
			const defPal = PalettePresets()[0].id
			const pal = ifPal
				?
				DataContext.data.landing.palette[0].id
				:
				defPal
			console.log(matrix()[type].defaults?.variants[variant].data.palette)
			//console.log(DataContext.data.sections, order)
			data1 = {
				data: { ...matrix()[type].defaults?.variants[variant].data },
				composition: matrix()[type].defaults?.variants[variant].data.composition,
				title: matrix()[type].defaults?.variants[variant].data.title,
				descriptions: matrix()[type].defaults?.variants[variant].data.descriptions,
				background: matrix()[type].defaults?.variants[variant].data.background,
				palette: matrix()[type].defaults?.variants[variant].data.palette,
				current_template_id: matrix()[type].defaults?.variants[variant].data.current_template_id 
			}
			delete data1.data.hidden
			delete data1.data.composition
			delete data1.data.background
			delete data1.data.title
			delete data1.data.descriptions
			delete data1.data.current_template_id
			delete data1.data.palette
		}
		//console.log( order )
		const sec = getDefault(type, data1)
		//console.log("ADD", data1);
		sections.splice(order + 1, 0, sec)
		//console.log(sections)
		DataContext.upd({ ...DataContext.data, sections })
		this.setState({ isNewSectionOpen: false, is_not_update: true })
		LandingView.isLockScroll = false
	}

	onDouble = (data) => {
		const sections = [...DataContext.data.sections]
		//console.log("DOUBLE", data, sections[data])
		const newID = UUID.v4() //DataContext.getMaxSectionID(true)
		const sec = {
			...sections[data],
			section_id: newID,
			id: newID,
			menu: { ...sections[data].menu, id: `section-${newID}` },
		}
		delete sec.visible_lg
		delete sec.visible_sm
		delete sec.visible_sm
		delete sec.visible_ms
		delete sec.visible_xs
		sec.floats = sec.floats.map(float => {
			const floatId = UUID.v4()
			return {
				...float,
				id:floatId,
				float_id: floatId
			}
		})
		sections.splice(data + 1, 0, sec)
		//console.log(sections[data + 1])
		DataContext.upd({ ...DataContext.data, sections })
		this.setState({ s: !this.state.s, is_not_update: true })
	}
	doUndo = () =>
	{
		if(!this.state.is_edit)	return		
		DataContext.undo() 
		console.log( DataContext.history.length )
		this.setState({ is_not_update: !DataContext.isMayUndo() }) 
	}
	doRedo = () =>
	{
		if(!this.state.is_edit)	return	
		DataContext.redo() 
		this.setState({ s: !this.state.s }) 
	}
	setCarousel_flow = carousel_flow =>
	{
		DataContext.setLanding({
			...DataContext.data.landing,
			carousel_flow
		})
		this.setState({ s: !this.state.s, is_not_update: true })
	}
	setLanding_flow = landing_flow =>
	{
		DataContext.setLanding({
			...DataContext.data.landing,
			landing_flow
		})
		this.setState({ s: !this.state.s, is_not_update: true })
	}
	setContainer_type = container_type =>
	{
		DataContext.setLanding({
			...DataContext.data.landing,
			container_type
		})
		this.setState({ s: !this.state.s, is_not_update: true })
	}

	onHide = (id, is_hide) => {
		//console.log("HIDE", id, is_hide)
		DataContext.hideSection(id, is_hide)
		//console.log(DataContext.data)
		this.setState({ s: !this.state.s, is_not_update: true })
	}

	onClearOpen = () => {
		this.setState({ isClearOpen: !this.state.isClearOpen })
		LandingView.isLockScroll = this.state.isClearOpen
	}

	onLandingClear = () => {
		DataContext.clear()
		this.setState({
			loading: false,
			s: !this.state.s,
			isClearOpen: false,
			is_not_update: true,
			isLandingEditOpen: true
		})
	}

	onRemoveFloat = (float_id) => {
		DataContext.deleteFloatId(float_id)
		this.setState({ 
			s				: !this.state.s, 
			is_not_update	: true, 
			floatSelect		: undefined,
			floatSelectID	: undefined
		})
	}

	onUpdateFloat = (data, float_id, section_id) => {
		//console.log(data, float_id, section_id)
		DataContext.updateFloat(data, float_id, section_id)
		this.setState({ s: !this.state.s, is_not_update: true })
	}
	onCloneFloat = ( float_id, section_id ) => 
	{ 
		const float = DataContext.getFloatBySection( float_id, section_id )
		const section = DataContext.searchSectionById( DataContext.data.sections, section_id ) 
		const newId = DataContext.getMaxFloatID(true)
		const floats = [...section.floats ]
		floats.push({ ...float, float_id:newId, id:newId })
		section.floats = floats
		DataContext.updateSection( section_id, section )
		this.setState({ s: !this.state.s, is_not_update: true })
	}
	copyClipBoard = text =>
	{ 	
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='myInput'>${ text }</textarea></div>`)
		const copyText = document.getElementById("myInput")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#myInput").remove()
	}
	CopyArchor = i => 
	{ 
		const id = DataContext.data?.sections[ i ]?.menu?.id
		const text = `${ window.location.origin }${ window.location.pathname }#${ id }`		
		this.copyClipBoard(text) 
		AppToaster.show({
			intent: Intent.NONE,
			icon: "tick",
			//duration: 10000,
			message: __("Archor link copy to clipbord"),
		})
	}	
	copyEnterelyLink = (id, count) => 
	{  
		const text = `${ window.location.origin }${ window.location.pathname }#${ id }?entery=${count}`		
		this.copyClipBoard(text) 
		AppToaster.show({
			intent: Intent.NONE,
			icon: "tick",
			//duration: 10000,
			message: __("Entery link copy to clipbord"),
		})
	}	
	copyEmbedCode = (id, count) => 
	{  
		let text = `${ window.location.origin }${ window.location.pathname }/embed/${count}/#${ id }`
		text = `<iframe frameborder="0" width="100%" height="480" allowfullscreen src="${text}"></iframe>`		
		this.copyClipBoard(text) 
		AppToaster.show({
			intent: Intent.NONE,
			icon: "tick",
			//duration: 10000,
			message: __("Entery link copy to clipbord"),
		})
	}

	onClipboardCopy = (i, data) => 
	{
		//console.log( data.id) 
		//console.log(DataContext.getSectionJSON(data.id))
		
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='myInput'>${DataContext.getSectionJSON(data.id)}</textarea></div>`)
		const copyText = document.getElementById("myInput")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#myInput").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Section copy to clipbord"),
		})
	}

	onClipboardPaste = (i) => {
		navigator.clipboard.readText()
			.then((clipText) => {
				try 
				{
					DataContext.addHistory()
					DataContext.data.sections.splice(i, 0, DataContext.setSectionJSON(clipText))
					this.setState({ is_not_update: true })
				} 
				catch (e) 
				{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("Error read clipboard data"),
					})
				}
			})
	}

	onDownload = () => {
		// const downloadFile = async () =>
		// {
		const myData = DataContext.data 	// I am assuming that "this.state.myData"
		myData.sections = myData.sections.map((section, i) => {
			let sec = {...section}
			delete sec.user
			delete sec.isDummy
			delete sec.i 
			delete sec.section_width 
			delete sec.is_admin 
			delete sec.is_edit 
			delete sec.level 
			delete sec.className 
			delete sec.children 
			Object.keys(sec).forEach(elem =>
				{
					if(typeof sec[elem] === "function")
					{
						delete sec[elem]
					}
				})
			return sec
		})
		
		// is an object and I wrote it to file as
		// json
		const fileName = "file"
		const json = JSON.stringify(myData)
		const blob = new Blob([json], { type: "application/json" })
		const href = URL.createObjectURL(blob)
		const link = document.createElement("a")
		link.classList.add("lead")
		link.classList.add("bg-light")
		link.classList.add("p-5")
		link.href = href
		link.download = `${fileName}.json`
		document.body.appendChild(link)
		link.click()
		// document.body.removeChild(link);
		// }
	}

	onWaypointEnter = (section_id) => {
		// console.log("WaypointEnter", section_id)
	}

	onWaypointLeave = (section_id) => {
		// console.log("WaypointLeave", section_id);
	}

	onFixedAdd = (data) => {
		this.setState({ fixedChildren: [...this.state.fixedChildren, data] })
	}
}
export default compose(
	withRouter,
	withApollo,
)(LandingEdit)