import React, { Component } from "react"
import ReactDOM from "react-dom"

import { NavLink } from "react-router-dom"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router" 
import { profile } from "react-pe-layouts"
import { __ } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import { isCapability } from "react-pe-utilities"
import { login, template } from "react-pe-layouts"
import { initArea } from "react-pe-utilities"
import UserContext from "../layoutConfig/userContext";

const components = {}
function importAll(r) {
  // console.log(r)
  r.keys().forEach((key) => {
    const key1 = key.replace("./", "").split(".").slice(0, -1).join(".")
    components[key1] = r(key)
  })
}
importAll(require.context("widgets/", false, /\.js$/))

class LayoutUser extends Component {
	constructor(props)
	{
		super(props)
		this.state = { isOpen: this.props.isOpen, height: 0, current: this.props.current }
		this.myRef = React.createRef();
	}
	

	componentWillReceiveProps(nextProps) {
	  if (
	    nextProps.current !== this.state.current
			|| 	nextProps.isOpen !== this.state.isOpen
	  ) 
	  {
	    this.setState({
	      current: nextProps.current,
	      isOpen: nextProps.isOpen,
	      height: nextProps.isOpen ? 230 : 0,
	    })
	  }
	}

	componentDidMount() {
	  document.body.addEventListener("click", this.onMouseLeaveHandler)
	}

	componentWillUnmount() {
	  document.body.removeEventListener("click", this.onMouseLeaveHandler)
	}

	onMouseLeaveHandler = (e) => {
	  //const domNode = ReactDOM.findDOMNode(this)
	  const domNode = this.myRef.current;
	  if (!domNode || !domNode.contains(e.target)) {
	    this.setState({
	      isOpen: this.props.isOpen,
	      height: 0,
	    })
	  }
	}

	render() {
	  // console.log(this.props.user);
	  return (
		<>
			{
				initArea(
					"before-profile",
					{ ...this.props },
				)
			}
			{/*this.props.user ? this.logined() : this.unLogined()*/}
			<UserContext.Consumer>
			{(
				context => {
					//console.log( context )
					return context.user && Object.entries(context.user).length > 0 
						? 
						this.logined(context) 
						: 
						this.unLogined(context)
				} 	
				
			)}
            </UserContext.Consumer>
		</>
	  )
	}

	unLogined() {
	  // console.log(template());
	  return (
  		<>
   		 {
			initArea(
				"unlogin_panel",
				{ ...this.props },
				<NavLink className="icon-unlogined" to={login}>
					<div>
						{" "}
						{__("Enter")}
						{" "}
					</div>
				</NavLink>,
			)
		}
  		</>
	  )
	}

	logined( context ) 
	{
		const { avatar } = this.props;
		const { user } = context;//this.props
		//console.log( user )
		const profile_routing = profile()
		let profile_menu
		if (profile_routing.length > 0) 
		{
			profile_menu = profile_routing.map((e, i) => {
				const isRole = isCapability(e.capability, this.props.user)
				if (isRole) return ""
				let children
				if(Array.isArray(e.children) && e.children.length > 0)
				{
					children = <>
						<div className="lmenu-children" >

						</div>
					</>
				}
				else
				{

				}
				return <li className="lmenu" key={i}>
					{children}
					<NavLink
						className=""
						activeClassName="active"
						to={`/${e.route}`} 
					>
						<LayoutIcon
						isSVG
						src={e.icon}
						className="personal-menu__icon mr-3"
						/>
						{ __(e.title) }
					</NavLink>
				</li>
			})
		} 
		else 
		{
			profile_menu = <li className="lmenu">
				<NavLink
					className=""
					activeClassName="active"
					to="/profile"
				>
					<LayoutIcon
					isSVG
					src="/assets/img/user.svg"
					className="personal-menu__icon mr-3"
					/>
					{__("edit profile")}
				</NavLink>
			</li>
		}

	  	const login_panel = template().login_panel && Array.isArray(template().login_panel)
			? 
			template().login_panel.map((e, i) => {
			switch (e.component) {
				case "NavLink":
					//console.log(e)
					return (
						<NavLink
							key={i}
							className={`btn ${e.routing}`}
							to={`/${e.routing}`}
						>
							{__(e.title)}
						</NavLink>
					)
				default:
				const ElWidgetL = components[e.component].default
				return <ElWidgetL
					key={i} 
					{...e} 
					user={context.use} 
					logout={evt => this.logout(evt, context)} 
				/>
			}
			})
			:
			<div
				className={`icon-logined ${this.state.isOpen ? " active44" : ""}`}
				onClick={this.onToggle}
				ref={this.myRef}
			>
				<LayoutIcon
					isSVG={!(user.avatar && user.avatar.length > 0)}
					src={user.avatar ? user.avatar : "/assets/img/user1.svg"}
					style={user.avatar ? { backgroundImage: `url(${user.avatar})`, backgroundSize: "cover" } : {}}
					className="user-ava"
				/>

				<div className="user-name">
				{
					user ? user.display_name : " "
				}
				</div>
				<div className="chevron-down-light ml-3 mt-1 pointer" />
				<div className="logined-menu" style={{ height: this.state.height }} >
					<ul id="person_menu">
						{profile_menu}
						<li onClick={evt => this.logout(evt, context)} className="lmenu exit">
							<LayoutIcon
								isSVG
								src="/assets/img/logout.svg"
								className="personal-menu__icon mr-3"
							/>
							{__("logout")}
						</li>
					</ul>
				</div>
			</div>

	  return (
		<>
			{
				initArea(
					"login_panel",
					{ ...this.props },
					login_panel,
				)
			}
		</>
	  )
	}

	onToggle = (evt) => {
	  // console.log( document.getElementById("person_menu").clientHeight );
	  this.setState({
	    isOpen: !this.state.isOpen,
	    height: !this.state.isOpen ? document.getElementById("person_menu").clientHeight : 0,
	  })
	}

	logout = (evt, context) => 
	{
		//console.log(context);
        context.setUser({});
		localStorage.removeItem("token")
		this.props.refetchUser()
	}
}
export default compose(
  withApollo,
  withRouter,
)(LayoutUser)
