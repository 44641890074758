import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@blueprintjs/core'

const AddNewSectionButton = props => {
    if(!props.is_edit ) return null
    return (
        <div className="landing-add-section-cont">
            <div className="landing-add-section-btn" onClick={props.onAdd}>
                <Icon
                    icon="plus"
                />
            </div>
        </div>
    )
}

AddNewSectionButton.propTypes = {}

export default AddNewSectionButton