import React from "react"
import {Loading} from 'react-pe-useful'
import { Tag, Button, ButtonGroup, Callout, Intent } from "@blueprintjs/core";
import { __ } from "react-pe-utilities";
import { useEffect, useState } from "react";

const npmPluginUrl = ["http://registry.npmjs.com/-/v1/search?text="]
const gitlabPluginUrl = ['https://gitlab.com/api/v4/projects?search=']
const srch = "flight"//"protopia-ecosystem-plugin""postcss-plugin"//

export default props => {
    const [isLoading, setIsLoading] = useState(false);
    const [npm, setNpm] = useState([]);
    const [fullPages, setFullPages] = useState(0);
    const [offset, setOffset] = useState(0);
    const [size, setSize] = useState(5);
    useEffect(() => {
        search()
    }, [props.src])
    useEffect(() => {
        setIsLoading(true)
        search()
    }, [])
    const search = () => {
        try {
            switch (props.src) {
                case "installed":
                    break;
                case "npm":
                    fetch(npmPluginUrl[0] + srch)
                        .then(response => response.json())
                        .then(
                            res => {
                                setFullPages(res.objects.length)
                                fetch(npmPluginUrl[0] + srch + `&size=${size}&offset=${offset}`)
                                    .then(response => response.json())
                                    .then(
                                        res => {
                                            setIsLoading(false)
                                            setNpm(res.objects.map(plug =>
                                                {
                                                    return {...plug.package}
                                                }))
                                        },
                                        err => {
                                            console.error(err)
                                            setIsLoading(false)
                                        }
                                    )
                            },
                            err => {
                                console.error(err)
                            }
                        )
                    break;
                case "gitlab":
                default:
                    fetch(gitlabPluginUrl[0] + srch, { method: 'GET' })
                        .then(response => response.json())
                        .then(
                            res => {
                                setFullPages(res.length)
                                fetch(gitlabPluginUrl[0] + srch, { method: 'GET' })
                                    .then(response => response.json())
                                    .then(
                                        res => {
                                            setIsLoading(false)
                                            setNpm(res.map(plug => {
                                                return {
                                                    ...plug,
                                                    keywords:[...plug.tag_list]  
                                                }
                                            }))
                                        },
                                        err => {
                                            console.error(err)
                                            setIsLoading(false)
                                        }
                                    )
                            },
                            err => {
                                console.error(err)
                            }
                        )
                    break;
            }
        }
        catch (error) {
            console.error('Ошибка:', error);
        }
    }
    if (isLoading) {
        return <div className="layout-state p-0">
            <Loading />
        </div>
    }

    const pluginList = npm.length > 0
        ?
        npm
            .filter(plugin => plugin.name !== "protopia-ecosystem")
            .map((plugin, i) => {
                const keywords = Array.isArray(plugin.keywords)
                    ?
                    plugin.keywords.map((kw, ii) => {
                        return <Tag key={ii} className="bg-secondary hover" minimal={false} intent={Intent.SUCCESS}>
                            {kw}
                        </Tag>
                    })
                    :
                    ""
                return <div className="p-3 d-flex" key={i}>
                    <div className="flex-column">
                        <div className="font-weight-bold ">
                            {plugin.name}
                        </div>
                        <div className="py-0 d-flex flex-wrap">
                            {plugin.description}
                        </div>
                        <div className="py-0 d-flex flex-wrap">
                            {keywords}
                        </div>
                    </div>
                    <div className="ml-auto pl-4">
                        <ButtonGroup vertical={true} minimal={true}>
                            <Button>
                                <span className=" text-nowrap">
                                    {__("See details")}
                                </span>
                            </Button>
                            <Button>
                                <span className=" text-nowrap">
                                    {__("Install")}
                                </span>
                            </Button>
                        </ButtonGroup>
                    </div>

                </div>
            })
        :
        <Callout>
            {__("No search plugin in this sources")}
        </Callout>


    return <>
        <div className="d-flex flex-column">
            {pluginList}
        </div>
        <div className="p-3">
            {__("Full: ") + fullPages}
        </div>
    </>
}