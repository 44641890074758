import { Button, ButtonGroup, Card, Collapse, Intent } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"

const YFCard = props =>
{
    // console.log( props.thread )
    const [thread] = useState( props.thread )
    const thID = thread.ID
    const changeTreadField = ( value, field ) =>
    {
        //console.log( props.thread, value, field )
        props.changeTreadField( props.thread.ID, value, field )
       
    } 
    const prepaireDeleteThread = threadID =>
    {
        props.prepaireDeleteThread( threadID )
    }
    return <Card className="p-4 mb-4" > 
        <FieldInput
            title={__("Thread name")}
            value={thread.post_title}
            field="post_title" 
            _id={thread.ID}
            onChange={ changeTreadField }
        />
        <FieldInput
            title={__("Yandex form admin URL")}
            type="url"
            value={thread.thread_url}
            field="thread_url" 
            _id={thread.ID}
            onChange={ changeTreadField }
        />
        <FieldInput
            title={__("Send e-mail to author after insert new Project?")}
            type="boolean"
            value={ thread.email_action }
            field="email_action"   
            _id={thread.ID}
            onChange={ changeTreadField}
        />
        <Collapse isOpen={thread.email_action}>
            <FieldInput
                title={__("E-mail text")}
                type="html"
                value={ thread.email_text }
                field="email_text"   
                _id={thread.ID}
                onChange={ changeTreadField}
            />
            <FieldInput
                title={__("Qestion on form interpreted as the recipient's mailing address")}
                type="string"
                value={ thread.yandex_form_email }
                field="yandex_form_email"   
                _id={thread.ID}
                onChange={ changeTreadField}
            />
        </Collapse>
        <FieldInput
            title={__("Enabled thread?")}
            commentary={__("If this switch is enabled, each response from Yandex Forms results in the automatic creation of a new Project.")}
            type="boolean"
            value={!thread.disabled}
            field="disabled"   
            _id={thread.ID}
            onChange={ ( value, field ) => changeTreadField( !value, field ) }
        />
        <ButtonGroup minimal>
            <Button intent={Intent.DANGER} onClick={() => prepaireDeleteThread(thID) } icon="minus"/>
        </ButtonGroup>
    </Card>
}

export default YFCard