import React from "react" 
import chroma from "chroma-js" 
import { getPlainColor } from "../data/getColor"

const CardFieldCheckLabel = props => {
    const bgColor = props.bgColor === "transparent" ? "#121111" : props.bgColor
    let st = {
        //color: props.palette.card.color,
        ...props.style,
        minHeight: props.cardFieldsData.height,
    }
    
    let datas
    try {
        datas = JSON.parse(props.e.field.replaceAll("!~!~", '"'))
        datas = Array.isArray(datas) ? datas : []
    }
    catch (e) {
        datas = []
    }

    let successColor = Math.abs(chroma(getPlainColor(bgColor )).get('hsl.h') - chroma("#28a745").get('hsl.h')) > 70
        ?
        "#28a745"
        :
        bgColor && chroma(getPlainColor(bgColor)).get('hsl.l') < .5
            ?
            "#FFF"
            :
            "#111"
    let dangerColor = "#dc3545"
    return <>
        < div
            className={" checklabel " + props.cardFieldsData.variant}
            style={st}
        >
            {
                datas.map((dt, index) => {
                    return <div className="single-feature" key={index}>
                        <div className="check-sign">
                            {
                                dt.icon
                                    ?
                                    <i className="fas fa-check" style={{ color: successColor }} />
                                    :
                                    <i className="fas fa-times" style={{ color: dangerColor }} />
                            }
                        </div>
                        <div className="check-label">
                            {dt.label}
                        </div>
                    </div>
                })
            }

        </div>
    </>
}
export default CardFieldCheckLabel;