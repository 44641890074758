import React, { useEffect, useState } from "react"
import $ from "jquery"
import Style from "style-it"
import {getLandingContainer} from "../LandingView"
import { useLocation } from 'react-router-dom';

const ToUpData = [
    {

        url : "../img/minimal/up-arrow.svg"
    }
]
const ToUp = props =>
{
    let location = useLocation(); 
    const usl = () =>
    {   
        return getLandingContainer()?.scrollTop > window.screen.height / 2
    }
    const _onScroll = () => {
        setVisible( usl() )
    }
    const [visible, setVisible] = useState( usl() )
    useEffect(() =>
    { 
        getLandingContainer().addEventListener( 'scroll', _onScroll ); 
        return () => getLandingContainer().removeEventListener( 'scroll', _onScroll ); 
    }, [])
    let top     = "auto"
    let bottom  = "auto"
    let left    = "auto"
    let right   = "auto"
    let _switch = (props.up_to_top_size ? props.up_to_top_size : 70)
        + 
        ( props.up_to_top_padding ? props.up_to_top_padding : 0 )
    let to_hide = `${_switch}px`
    switch( props.up_to_top_position )
    {
        case "top-left":
            top     = props.up_to_top_padding  + "px"
            bottom  = "auto"
            left    = props.up_to_top_padding  + "px"
            right   = "auto"
            to_hide = `-${_switch}px`
            break
        case "top-right":            
            top     = props.up_to_top_padding  + "px"
            bottom  = "auto"
            left    = "auto"
            right   = props.up_to_top_padding  + "px"
            break
        case "top-center":                     
            top     = props.up_to_top_padding  + "px"
            bottom  = "auto"
            left    = "auto"
            right   = `calc(50% - ${props.up_to_top_size ? props.up_to_top_size/2 : 35}px)`
            break
        case "center-left":                     
            top     = `calc(50% - ${props.up_to_top_size ? props.up_to_top_size/2 : 35}px)`
            bottom  = "auto" 
            left    = props.up_to_top_padding  + "px"
            right   = "auto"
            to_hide = `-${_switch}px`
            break
        case "center-right":                   
            top     = `calc(50% - ${props.up_to_top_size ? props.up_to_top_size/2 : 35}px)`
            bottom  = "auto" 
            left    = "auto"
            right   = props.up_to_top_padding  + "px"
            break
        case "bottom-left":
            top     = "auto"
            bottom  = props.up_to_top_padding  + "px"
            left    = props.up_to_top_padding  + "px"
            right   = "auto"
            to_hide = `-${_switch}px`
            break
        case "bottom-center":
            top     = "auto"
            bottom  = props.up_to_top_padding  + "px"
            left    = `calc(50% - ${props.up_to_top_size ? props.up_to_top_size/2 : 35}px)`
            right   = "auto"
            break
        case "bottom-right":
        default:
            top     = "auto"
            bottom  = props.up_to_top_padding  + "px"
            left    = "auto"
            right   = props.up_to_top_padding  + "px"
            break
    }
    return  <>
        {
            Style.it(
                `
                .landing-to-up
                {
                    position: fixed;
                    top: ${top};
                    bottom: ${bottom};
                    left: ${left};
                    right: ${right};
                    z-index: 200;
                    width: ${props.up_to_top_size ? props.up_to_top_size : 70}px;
                    height:${props.up_to_top_size ? props.up_to_top_size : 70}px;
                    background-color:${props.up_to_top_background};
                    background-image:url(/assets/img/minimal/up-arrow.svg);
                }
                .show-to-top
                {
                    transform: translateX(0px) translateY(0px);
                }
                .hide-to-top
                {
                    transform: translateX(${to_hide}) translateY(0px);
                }
                `,
                <div
                    className= { "landing-to-up "  + (visible ? "show-to-top" : "hide-to-top")} 
                    key={"to-top"}
                    onClick={
                        () => {
                            window.history.replaceState(undefined, undefined, `${location.pathname}`)
                            getLandingContainer().scrollTo({
                                behavior: 'smooth',
                                top: 0,
                            })
                        }
                    }
                >

                </div>
            )
        }
        </>
}
export default ToUp