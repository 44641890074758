import { Button, ButtonGroup, Dialog, Intent } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"
import { AppToaster } from 'react-pe-useful'
import { __ } from "react-pe-utilities"
import DataContext from "../../DataContext"
import InputForm from "../InputForm" 
import $ from "jquery"

const CardTemplateEditor = props => {
    const [landing, setLanding] = useState({ ...DataContext.data.landing })
    const [title, onTitle] = useState(props.title && props.title.text
        ?
        props.title.text
        :
        null
    )
    const [preTitle, onPreTitle] = useState(title)
    const [isOpen, onOpen] = useState(false)

    const setTitle = () => {
        onTitle(preTitle);
        onOpen(null);
        let cardData = { ...props.data }
        //delete cardData.cards
        cardData.title = preTitle
        if (!Array.isArray(DataContext.data.landing.card_templates))
            DataContext.data.landing.card_templates = [];
        DataContext.data.landing.card_templates.unshift(cardData);
        setLanding({ ...DataContext.data.landing })
        props.onEdit() 
    }

    const onCopy = () => {
        onOpen(true)
    }

    const onClearAll = () => {
        DataContext.data.landing.card_templates = [];
        setLanding({ ...DataContext.data.landing })
        props.onEdit()
    }
    const on = (val, field, dopol) => {
        console.log(val, field, dopol)

    }
    const onApply = (val) => {
        props.onApply(val)
    }
    const onTry = (val) => {
        props.onTry(val)
    }
    const onSingleRemove = val => {
        DataContext.data.landing.card_templates = DataContext.data.landing.card_templates.filter((e, i) => {
            return val !== i
        })
        setLanding({ ...DataContext.data.landing })
        props.onEdit()
    }


    const onClipboardCopy = evt => {
        const data = DataContext.data.landing.card_templates
        $("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='myInput'>${JSON.stringify(data)}</textarea></div>`)
        const copyText = document.getElementById("myInput")
        copyText.select()
        copyText.setSelectionRange(0, 99999999999999999999)
        document.execCommand("copy")
        $("#myInput").remove()
        AppToaster.show({
            intent: Intent.SUCCESS,
            icon: "tick",
            duration: 10000,
            message: __("Copy to clipbord"),
        })
    }

    const onClipboardPaste = evt => {
        navigator.clipboard.readText()
            .then((clipText) => {
                try {
                    const temp = DataContext.data.landing.card_templates || []
                    DataContext.data.landing.card_templates = JSON.parse(clipText).concat(temp)
                    setLanding({ ...DataContext.data.landing })
                    props.onEdit()
                } catch (e) {
                    AppToaster.show({
                        intent: Intent.DANGER,
                        icon: "tick",
                        message: __("Error read clipboard data"),
                    })
                }
            })
    }

    // console.log( props )
    return <div className="p-3">
        <ButtonGroup className="d-flex flex-wrap">
            <Button
                intent={Intent.NONE}
                minimal
                large
                className="m-0 w-50"
                onClick={onCopy}
            >
                {__("Copy current card template to local library")}
            </Button>
            <Button
                large
                minimal
                data-hint={__("Copy library to clipboard")}
                onClick={onClipboardCopy}
                className="hint hint--bottom"
            >
                <i className="fas fa-file-import" />
            </Button>
            <Button
                large
                minimal
                data-hint={__("Paste library from clipboard")}
                onClick={onClipboardPaste}
                className="hint hint--bottom "
            >
                <i className="fas fa-file-export" />
            </Button>
            <Button
                data-hint={__("Clear local library")}
                minimal
                large
                className="hint hint--bottom"
                onClick={onClearAll}
                icon="cross"
            />
        </ButtonGroup>
        
        <InputForm
            data={landing}
            source="landing"
            include_tab={["Card templates"]}
            id={props.section_id}
            vertical={true}
            palette={props.palette}
            {...props.data}
            on={on}
            onApply={onApply}
            onTry={onTry}
            onSingleRemove={onSingleRemove}
            onUpdate={props.onUpdate}
        />
        <Dialog
            isOpen={isOpen}
            onClose={() => onOpen(true)}
            title={__("Set new template title")}
        >
            <div className="p-5">
                <input
                    type="text"
                    value={preTitle}
                    onChange={evt => onPreTitle(evt.currentTarget.value)}
                    className="form-control input dark my-4"
                />
                <ButtonGroup>
                    <Button
                        intent={Intent.DANGER}
                        onClick={setTitle}
                    >
                        {__("Set title")}
                    </Button>
                    <Button
                        intent={Intent.NONE}
                        onClick={() => onOpen(false)}
                    >
                        {__("Close")}
                    </Button>
                </ButtonGroup>
            </div>
        </Dialog>
    </div>
}
export default CardTemplateEditor