import React, { Component, Suspense } from "react"
//import Select from "react-select" // https://github.com/JedWatson/react-select
import { __ } from "react-pe-utilities" 
import {Loading} from 'react-pe-useful'
import GoogleFontSingle from "./GoogleFontSingle"

import Select from "react-select"

class GoogleFonts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      value: Array.isArray(props.value) ? props.value : [],
      languages: ["cyrillic"],
    }
  }

    onChange = (data, i) => {
      const value = [...this.state.value]
      value[i] = {
        title: data,
      }
      this.setState({ value })
      setTimeout(() => {
        if (this.props.on) {
          this.props.on(value)
        }
      }, 100)
    }

    handleChange = (newValue, actionMeta) => {
      const sel = newValue || []
      const selected = sel.map((e) => {
        let value = {}
        value = e.value
        return value
      })
      this.setState({ languages: selected })
      // this.on( selected );
    }

    render() {
      const { value, languages } = this.state
      const variants = [
        {
          _id: "latin",
          title: "latin",
        },
        {
          _id: "latin-ext",
          title: "latin-ext",
        },
        {
          _id: "cyrillic",
          title: "cyrillic",
        },
        {
          _id: "cyrillic-ext",
          title: "cyrillic-ext",
        },
        {
          _id: "sinhala",
          title: "sinhala",
        },
        {
          _id: "greek",
          title: "greek",
        },
        {
          _id: "greek-ext",
          title: "greek-ext",
        },
        {
          _id: "kannada",
          title: "kannada",
        },
        {
          _id: "telugu",
          title: "telugu",
        },
        {
          _id: "vietnamese",
          titleid: "vietnamese",
        },
        {
          _id: "hebrew",
          title: "hebrew",
        },
        {
          _id: "arabic",
          title: "arabic",
        },
        {
          _id: "devanagari",
          title: "devanagari",
        },

      ].map((e, i) => ({ value: e._id, label: e.title }))
      const select_languages = Array.isArray(languages)
        ? 			languages.map((e, i) => ({ value: e, label: e }))
        : 			[]

      return (
        <div className="w-100 py-3 ">
          {
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((e, i) => (
              <GoogleFontSingle
                key={i}
                value={value[i]}
                i={i}
                onChange={(val) => this.onChange(val, i)}
                languages={languages}
              />
            ))
        }
          <div className="py-2"> 
              <Select
                value={select_languages}
                isMulti
                isSearchable
                onChange={this.handleChange}
                options={variants}
                placeholder={__("Select language for fltered Fonts")}
                className="basic-multi-select w-100"
                classNamePrefix="select"
              /> 
          </div>
        </div>
      )
    }
}
export default GoogleFonts
