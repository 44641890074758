import React, { Component } from "react"
import {
	Button, Intent, Dialog, Tag, Slider, MultiSlider, ButtonGroup, Callout, Icon,
} from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import matrix from "../views/LandingState/data/matrix"
import Columns from "./landingObject/Columns"
import Padding from "./landingObject/Padding"
import Margin from "./landingObject/Margin"
import Border from "./landingObject/Border"
import Matchings from "./landingObject/Matchings"
import SVGLabrary from "./landingObject/SVGLabrary"
import SectionButton from "./landingObject/SectionButton"
import PaletteForm from "./landingObject/PaletteForm"
import ContanctFormFieldButton from "./landingObject/ContanctFormFieldButton"
import CardSingleFieldType from "./landingObject/CardSingleFieldType"
import YandexMapPlaceBottom from "./landingObject/YandexMapPlaceBottom"
import CardButton from "./landingObject/CardButton"
import CardField from "./landingObject/CardField"
import GoogleFonts from "./landingObject/GoogleFonts"
//import CardSingleAllFields from "./landingObject/CardSingleAllFields"
import CardSingleField from "./landingObject/CardSingleField"
import VideoButton from "./landingObject/VideoButton"
import PlaceTypeButton from "./landingObject/PlaceTypeButton"
import Composition from "./landingObject/Composition"
import LasyLoadType from "./landingObject/LasyLoadType"
import TextFormatForm from "./landingObject/TextFormatForm"
import Style from "./landingObject/Style"
import Object_Position from "./landingObject/Object_Position"
import getVisibleValue from "../views/LandingState/data/getVisibleValue"
import { getTypeSelector } from "react-pe-layouts"
import TypeDialog from "../views/LandingState/edit/TypeDialog"
import InputForm from "../views/LandingState/edit/InputForm"
import DataContext from "../views/LandingState/DataContext"
import { getDefault } from "../views/LandingState/Section"
import CSSColor from "./utilities/CSSColor"
import LandingParameters from "./landingObject/LandingParameters"
import CardTemplateLib from "./landingObject/CardTemplatesLib"
import ExternalLandingColor from "./landingObject/ExternalLandingColor"
import CardFieldMetaphorEdit from "./landingObject/CardFieldMetaphorEdit"
import NewSectionDialog from "../views/LandingState/edit/NewSectionDialog" 
import { AppToaster } from 'react-pe-useful' 
import {addEmpty, SubMenu} from "react-pe-scalars"
import $ from "jquery"
import AlignPosition from "./landingObject/AlignPosition"
import MatchingCard from "./landingObject/matching/MatchingCard"
import DataTypeFieldSelector from "modules/pe-admin-module/views/scalars/DataTypeFieldSelector"
import VerticalList from "./landingObject/dnd/VerticalList"
import {default as UUID} from "node-uuid";
import getUniqGrids from "../views/core/getUniqGrids"
import CanvasSize from "./landingObject/CanvasSize"

class LandingObject extends Component {
	state = {
		...this.props,
		isDialog: false,
		object: {},
	}
	componentDidUpdate(prevProps, prevState)
	{
		if(this.props.value !== prevState.value)
		{
			//console.log( "prevState all", prevState )
			//console.log( "prevState", prevState.value )
			//console.log( "props.value", this.props.value )
			this.setState({value: this.props.value})
		}
	}
	render() {
		// console.log( this.props );
		const { field, title, description } = this.props
		
		const col1 = title || description 
			? this.props.vertical
				? 
				"col-12 layout-label-vert"
				: 
				"col-12 layout-label-vert" // "col-md-3  layout-label"
			: " hidden "
		const col2 = title || description
			? this.props.vertical
				? 
				"col-12 layout-data-vert"
				: 
				"col-12 layout-data-vert" // "col-md-9 layout-data"
			: " col-12 layout-data-vert "

		const o = this.props.type === "landing_object"
			? 
			<>
				<div className="row dat strob01" row_data={field}>
					<div className={col1}>
						{__(this.props.title)}
					</div>
					<div className={col2}>
						{this.formInput()}
						<div className="position-relative w-30px">
							<SubMenu
								sub_menus={this.props.sub_menus}
								on={this.onSubMenu}
							/>
						</div>            
						{
							this.props.editable && !this.props.not_clear
								? 
								<Button
									className="right"
									icon="cross"
									minimal
									onClick={this.onClear}
								/>
								:
								null
						}
					</div>
				</div>
				<NewSectionDialog
					title={__("Add new sub Section")}
					isOpen={this.state.isNewSectionOpen}
					onClose={this.onNewSectionOpen}
					onEdit={this.onAddSection}
				/>
			</>
			: 
			null
		return (
			<>
				{o}
				<Dialog
					isOpen={this.state.isDialog}
					onClose={this.onDialog}
					title={__("Parameters")}
					className="little2"
				>
					{this.dialogContent()}
				</Dialog>
			</>
		)
	}

	formInput() { 
		const { value } = this.state
		let groups, btns = "", after = ""
		// console.log( value, this.props );
		// console.log( this.props );
		switch (this.props.kind) {
			case "array":
				switch (this.props.landing_object) { 
					case "CardField":
						//console.log("CardField", value)
						const __fields = (Array.isArray(value) ? value : []).map(item =>
						{
							const itemId = item.id ? item.id : `cardfield-${UUID.v4()}`
							const itemGridId = item.grid_id ? item.grid_id : `cardfieldGrid-${UUID.v4()}`
							return { ...item, id:itemId, grid_id: itemGridId }
						})
						//console.log("LandingObject:",__fields)
						const orientation = this.props.origin.data.orientation
						return <div className="d-flex w-100 flex-column" style={{ maxWidth: 550, minWidth:420 }}>
							<VerticalList
								fields={ __fields }
								origin={ this.state.origin }
								onCFBClose={this.onCFBClose}
								onCFBClone={this.onCFBClone}
								onCFForm={this.onCFForm}
								on={(val) => {
									//console.log("VerticalList", val)
									this.on( val, this.state.field )
								}}
								selectField={this.props.selectField}
								onSelectField={this.props.onSelectField}
							/>
							<div className="w-100">
								<div className={`${btnContClasses} mt-1`}> 
									<Button
										icon="plus"
										large
										className=" mr-1 hint hint--top "
										data-hint={__("Add card field")}
										onClick={this.onAddArray}
									/>
									<Button
										className=" hint hint--top "
										large
										data-hint={__(`${orientation} orientation`)}
										onClick={this.onCardOrientation}
									>
										<Icon icon={<i className={`fas fa-grip-${orientation}`} />} />
									</Button>
								</div> 								
							</div>
						</div>
					/*
					// Новая, линейная форма отображения
					case "section": 
					*/
					case "matching_label":
					case "Matching":
						return <Matchings
							{...this.state}
							on={(val) => {
								//console.log("Matching")
								this.on(this.state.field, val)
							}}
						/>				
					case "google_font":
						return (
							<GoogleFonts
								{...this.state}
								on={(val) => {
									//console.log("GoogleFonts")
									this.on(this.state.field, val)
								}}
							/>
						)
					case "__palette":

						return (
							<PaletteForm
								value={value}
								on={this.onPaletteForm}
							/>
						)
					case "ColumnsProportia":
						// console.log( Array.isArray( value ) );
						// console.log(this.state);
						let _value = value
						if (!Array.isArray(_value)) 
						{
							_value = []
							const columnsC = this.state.origin && this.state.origin.composition
								? 
								this.state.origin.composition.columns
								:
								1
							for (let __i = 0; __i < columnsC; __i++) {
								_value.push(100 / columnsC)
							}
						}
						let pr = 0
						let fields = []
						let handles = _value.map(
							(e, i) => {
								if (i === _value.length - 1) return null
								const m = (
									<MultiSlider.Handle
										key={i}
										value={pr + e}
										type="end2"
										intentAfter={Intent.PRIMARY}
									/>
								)
								pr += e
								fields.push(
									<input
										type="number"
										key={i}
										className="input dark m-1 py-2 w_45"
										value={parseInt(e)}
										onChange={this.onMultiInput}
									/>,
								)
								return m
							},
						)
						return (
							<div className="w-100 d-flex">
								<MultiSlider
									min={0}
									max={100}
									stepSize={1}
									labelStepSize={10}
									onChange={this.onMulti}
									vertical={false}
								>
									<MultiSlider.Handle value={0} type="start" intentAfter={Intent.DANGER} />
									{handles}
									<MultiSlider.Handle value={100} type="end" intentAfter={Intent.PRIMARY} />
								</MultiSlider> 
								<Button 
									icon={"cross"} 
									className="ml-3" 
									minimal 
									onClick={ () => this.onMulti([])}
								/>
							</div>
						)
					case "CardGridProportia":
						// console.log( Array.isArray( value ) );
						// console.log(this.state);
						_value = value
						if (!Array.isArray(_value)) 
						{ 
							_value = []							
							const columnsC = getUniqGrids(this.state.origin.data.fields).length
							for (let __i = 0; __i < columnsC; __i++) {
								_value.push(100 / columnsC)
							}
						}
						pr = 0
						fields = []
						handles = _value.map(
							(e, i) => {
								if (i === _value.length - 1) return null
								const m = (
									<MultiSlider.Handle
										key={i}
										value={pr + e}
										type="end2"
										intentAfter={Intent.PRIMARY}
									/>
								)
								pr += e
								fields.push(
									<input
										type="number"
										key={i}
										className="input dark m-1 py-2 w_45"
										value={parseInt(e)}
										onChange={this.onMultiInput}
									/>,
								)
								return m
							},
						)
						return (
							<div className="w-100 d-flex">
								<MultiSlider
									min={0}
									max={100}
									stepSize={1}
									labelStepSize={10}
									onChange={this.onMulti}
									vertical={false}
								>
									<MultiSlider.Handle value={0} type="start" intentAfter={Intent.DANGER} />
									{handles}
									<MultiSlider.Handle value={100} type="end" intentAfter={Intent.PRIMARY} />
								</MultiSlider> 
								<Button 
									icon={"cross"} 
									className="ml-3" 
									minimal 
									onClick={ () => this.onMulti([])}
								/>
							</div>
						)
					case "ProportiaInt":
						return (
							<div className="w-100">
								<div className="d-flex justify-content-between">
									<Tag>
										{" "}
										{__("Title")}
										{" "}
									</Tag>
									<Tag>
										{" "}
										{__("Content")}
										{" "}
									</Tag>
								</div>
								<Slider
									min={0}
									max={100}
									stepSize={1}
									labelStepSize={20}
									onChange={this.onSlider}
									value={Array.isArray(value) ? value[0] : value}
									vertical={false}
								/>
							</div>
						)
					case "card_template":
						return <div className="p-4 w-100">
							<CardTemplateLib
								{...this.props}
								value={value}
								on={(val) => {									
									//console.log("card_template")
									this.on(this.state.field, val)
								}}
								onApply={this.props.onApply}
								onTry={this.props.onTry}
								onSingleRemove={this.props.onSingleRemove}
								onUpdate={this.props.onUpdate}
							/>
						</div>
					default:
						let btnContClasses = "d-flex flex-wrap w-100 my-3"
						btns = Array.isArray(value)
							? 
							value.map((e, i) => {
								let label
								if (this.state.visibled_value) {
									const tt = getVisibleValue(this.state, this.state.landing_object )
									// console.log(e, this.state, tt);
									label = tt && tt.length > 0 ? tt : i
								} else {
									label = i
								}
								switch (this.props.landing_object) {
									case "section":
										return (
											<div key={i} className="p-0 d-flex flex-wrap">
												
												{
													i === 0 // || i == value.length - 1
														? null
														: (
															<Button
																i={i}
																eid={e.id}
																onClick={this.onSwitchButton}
																className="btn-switch"
															>
																<i className="fas fa-exchange-alt" />
															</Button>
														)
												}
												<SectionButton
													key={i}
													eid={e.id}
													object={e}
													onClick={
														(object) => this.onSubDialog({
															...object,
															landing_type: this.props.landing_object,
														})
													}
												/>
											</div>
										)
									case "ContanctFormField":
										//console.log(this.state);
										return (
											<div key={i} className="w-50 ">
												{
													i === 0 // || i == value.length - 1
														? null
														: (
															<Button
																i={i}
																eid={e.id}
																onClick={this.onSwitchButton}
																className="btn-switch"
															>
																<i className="fas fa-exchange-alt" />
															</Button>
														)
												}
												<ContanctFormFieldButton
													key={i}
													i={i}
													object={e}
													onClose={this.onCFBClose}
													onChange={this.onCFForm}
												/>
											</div>
										)
									case "Card":
										// console.log( this.props.origin.data.fields );
										console.log(e, i);
										fields = Array.isArray(e.fields) ? e.fields : []
										return (
											<div key={i} className="p-0 d-flex flex-wrap"> 
												{
													i === 0 // || i === value.length - 1
														? 
														null
														:
														<Button
															i={i}
															eid={e.id}
															onClick={this.onSwitchButton}
															className="btn-switch"
														>
															<i className="fas fa-exchange-alt" />
														</Button>
												}
												<CardButton
													key={i}
													i={i}
													object={{
														...e,
														card_fields: this.props.origin.data.fields,
														fields: fields.map((fld, index) => ({
															...fld,
															card_fields: this.props.origin.data.fields,
														})),

													}}
													onClick={
														(object) => this.onCardDialog({
															object,
															landing_type: this.props.landing_object,
															i,
														})
													}
												/>
											</div>
										)
									case "CardField":
										//console.log(this.state)
										after = <div className="m-4 d-flex">
											<div className="ml-auto">
												<ButtonGroup minimal>
													<Button 
														minimal 
														className="hint hint--top" 
														data-hint={__("Copy cards data")}
														onClick={this.onClipboardCardFieldCopy}
													>
														<i className="fas fa-file-import mr-2" />														
													</Button>
													<Button 
														minimal 
														className="hint hint--top" 
														data-hint={__("Paste all Cards")}
														onClick={this.onClipboardCardFieldPaste}
													>
														<i className="fas fa-file-export mr-2" /> 														
													</Button>
													<Button 
														minimal 
														className="hint hint--top" 
														data-hint={__("Paste constructor only")}
														onClick={this.onClipboardConstructordPaste}
													>
														<i className="fas fa-file-export mr-2" /> 														
													</Button>
												</ButtonGroup>
											</div>
										</div>
										return (
											<div key={i} className="p-0 d-flex flex-wrap position-relative w-100">
												{
													i === 0 // || i === value.length - 1
														? null
														: (
															<Button
																i={i}
																key={`btn${i}`}
																eid={e.id}
																onClick={this.onCardFieldSwitchButton}
																className="btn-card-fields-switch"
															>
																<i className="fas fa-exchange-alt" />
															</Button>
														)
												}
												<CardField
													origin={this.state.origin}
													key={i}
													i={i}
													object={e}
													onClose={this.onCFBClose}
													onClone={this.onCFBClone}
													onChange={this.onCFForm}
												/>
											</div>
										)
									case "video":
										return <div key={i} className="p-0 position-relative">
											{
												i === 0
													? 
													null
													:
													<Button
														i={i}
														key={`btn${i}`}
														eid={e.id}
														onClick={this.onSwitchButton}
														className="bp3-button btn-switch"
													>
														<i className="fas fa-exchange-alt" />
													</Button>
											}
											<VideoButton
												key={i}
												i={i}
												eid={e.id}
												object={e}
												onClick={
													(object) => this.onSubDialog({
														object,
														landing_type: this.props.landing_object,
														i,
													})
												}
											/>
										</div>
									case "place_type":
										//console.log(e)
										return (
											<PlaceTypeButton
												key={i}
												i={i}
												eid={e._id}
												object={e}
												onClick={
													(object) => this.onSubDialog({
														object,
														landing_type: this.props.landing_object,
														i,
													})
												}
											/>
										)
									case "YandexMapPlace":
										return (
											<YandexMapPlaceBottom
												key={i}
												i={i}
												eid={e._id}
												object={e}
												onClick={
													(object) => this.onSubDialog({
														object,
														landing_type: this.props.landing_object,
														i,
													})
												}
											/>
										)
									case "CardSingleField":
										btnContClasses = " d-flex flex-column w-100 my-3"
										// console.log( this.props.origin  )
										after = <div className="m-4 d-flex">
											<div className="ml-auto">
												<ButtonGroup minimal>
													<Button 
														minimal 
														className="hint hint--top" 
														data-hint={__("Copy Card content")} 
														onClick={this.onClipboardCardContentCopy}
													>
														<i className="fas fa-file-import mr-2" />
														{__("Copy Card content")}
													</Button>
													<Button 
														minimal 
														className="hint hint--top" 
														data-hint={__("Paste Card content")} 
														onClick={this.onClipboardCardContentPaste}
													>
														<i className="fas fa-file-export mr-2" /> 
														{__("Paste Card content")}
													</Button>
												</ButtonGroup>
											</div>
										</div>
										return (
											<div key={i} className="p-0 d-flex flex-wrap">
												{
													i === 0
														? null
														: (
															<Button
																i={i}
																eid={e.id}
																onClick={this.onSwitchButton}
																className="btn-switch btn-switch-vert"
															>
																<i className="fas fa-arrows-alt-v" />
															</Button>
														)
												}
												<CardSingleField
													key={i}
													object={{
														...e,
														i,
														card_fields: this.props.origin.card_fields,
													}}
													on={(data, field, dopol) => {
														//console.log(data, field, dopol)
														//this.on(data, field, dopol)
													}}
													onClick={
														(object) => {
															//console.log( this.props.landing_object )
															this.onSubDialog({
																object,
																landing_type: this.props.landing_object,
																i,
															})
														}
													}
												/>
											</div>
										)
									case "MotivatonElement":
									default:
										return (
											<div key={i} className="p-0 d-flex flex-wrap">
												{
													i === 0 // || i === value.length - 1
														? null
														: (
															<Button
																i={i}
																eid={e.id}
																onClick={this.onSwitchButton}
																className="btn-switch"
															>
																<i className="fas fa-exchange-alt" />
															</Button>
														)
												}
												<Button
													i={i}
													eid={e.id}
													onClick={
														(evt) => this.onSubDialog({
															...e,
															landing_type: this.props.landing_object,
															i: parseInt(evt.currentTarget.getAttribute("i")),
														})
													}
													className="edit-button square2"
												> 
													{label}
												</Button>
											</div>
										)
								}
							})
							:
							null
						return <div className="w-100">
							<div className={btnContClasses}>
								{btns}
								<Button
									icon="plus"
									className="  "
									onClick={this.onAddArray}
								/>
							</div>
							{after} 
						</div>
				}
			case "mask_src":
				return <SVGLabrary {...this.state} on={this.on} />
			default:
				switch (this.props.landing_object) 
				{
					case "canvas_size":
						return (
							<CanvasSize
								{...this.state}
								on={(val) => {																		
									console.log( val )
									this.on(this.state.field, val)
								}}
							/>
						)	
					case "align_position":
						return (
							<AlignPosition
								{...this.state}
								on={(val) => {																		
									//console.log("AlignPosition")
									this.on(this.state.field, val)
								}}
							/>
						)	
					case "DataTypeField":
						// console.log( this.props )
						// console.log( this.state.object.data_type_field )
						return <DataTypeFieldSelector
							origin={this.props.origin}
							data_type={this.props.origin.origin.data.data_type }
							value={this.state.value}
							on={(value, field) => {													
								//console.log("AlignPosition", value, field)
								this.onDataTypeField(value, field)
							}}
						/>			
					case "CardFieldMetaphors":
						return <div className="py-4 w-100">
							<CardFieldMetaphorEdit
								{...this.props} 
								value={value}
								on={(val) => {												
									//console.log("AlignPosition" )
									this.onCardMetaphore(this.state.field, val)
								}} 
							/>
						</div>
					case "ExternalLandingColor":
						// console.log(this.props );
						return <ExternalLandingColor
							{...this.state}
							origin={this.props.origin}
						/>
					case "LandingParameters":
						return <LandingParameters
							onAdd={this.props.onAdd}
							onDownload={this.props.onDownload}
							onLoadChange={this.props.onLoadChange}
							onClearOpen={this.props.onClearOpen}
						/>
					case "css_color":
						return (
							<CSSColor
								{...this.state}
								on={this.on}
							/>
						)
					case "text_format":
						return (
							<TextFormatForm
								{...this.state}
								example={__(this.props.example)}
								on={(val, field) => {												
									//console.log("AlignPosition", val, field)
									this.on(val, field)
								}}
							/>
						)
					case "Object_Position":
						return (
							<Object_Position
								{...this.state}
								example={__(this.props.example)}
								on={this.on}
							/>
						)
					// 	=====================================================
					//	поле редактирования параметров единичной секции Карточки (Card).
					// 	Вызывается - в CardSingleField
					//	Параметр - значение секции (типы, варианты и данные)
					// 	=====================================================
					case "CardSingleFieldType":
						//console.log( this.state )
						return <CardSingleFieldType {...this.state} on={(value, field) => {							
								//console.log("CardSingleFieldType", value, field)
								this.on(value, field)
							}
						} />
					case "place_type":
						groups = Array.isArray(DataContext.data.landing.place_type)
							? (
								<div className="my-1">
									{
										DataContext.data.landing.place_type.map((e, i) => (
											<div className="my-1">
												<label className="_check_" key={i}>
													<input
														type="radio"
														value={e._id}
														onChange={(evt) => this.onPlaceType(evt, this.state.field)}
														name={`place_type${this.state.field}`}
														checked={e._id === value}
													/>
													{e.title}
												</label>
											</div>
										))
									}
								</div>
							)
							: (
								<Callout
									title={__("There are no one Video Group")}
								>
									{__("Go to Landing global settings to charter «Globals»")}
								</Callout>
							)
						return (
							<div className="dd-video-group w-100">
								{groups}
							</div>
						)

					case "video_group":
						groups = Array.isArray(DataContext.data.landing.video_group)
							? (
								<select
									onChange={(evt) => this.onVideoGroup(evt, this.state.field)}
									className="form-control my-2"
									value={this.state.value || "-1"}
								>
									<option value=""> </option>
									{
										DataContext.data.landing.video_group.map((e, i) => (
											<option
												value={e.unique}
												key={i}
											>
												{e.title}
											</option>
										))
									}
								</select>
							)
							: (
								<Callout
									title={__("There are no one Video Group")}
								>
									{__("Go to Landing global settings to charter «Globals»")}
								</Callout>
							)
						return (
							<div className="dd-video-group w-100">
								{groups}
							</div>
						)
						
					case "Coordinate":
						// console.log( this.state.field,  this.state );
						let dst
						switch (this.state.field) {
							case "x":
								dst = (
									<div>
										<label className="_check_ mr-2 w_80 " htmlFor={`dst_x1_${this.state.id}`}>
											<input
												type="radio"
												name={`dst_x_${this.state.id}`}
												id={`dst_x1_${this.state.id}`}
												checked={this.state.value.dst === "L"}
												value="L"
												onClick={(evt) => this.onCoordinate(evt, "dst")}
												onChange={(evt) => this.onCoordinate(evt, "dst")}
											/>
											{__("from left")}
										</label>
										<label className="_check_ mr-2 w_80" htmlFor={`dst_x2_${this.state.id}`}>
											<input
												type="radio"
												name={`dst_x_${this.state.id}`}
												id={`dst_x2_${this.state.id}`}
												checked={this.state.value.dst === "R"}
												value="R"
												onClick={(evt) => this.onCoordinate(evt, "dst")}
												onChange={(evt) => this.onCoordinate(evt, "dst")}
											/>
											{__("from right")}
										</label>
									</div>
								)
								break
							case "y":
								dst = (
									<div>
										<label className="_check_ mr-2 w_80" htmlFor={`dst_yt_${this.state.id}`}>
											<input
												type="radio"
												name={`dst_y_${this.state.id}`}
												id={`dst_yt_${this.state.id}`}
												className=""
												checked={this.state.value.dst === "T"}
												value="T"
												onClick={(evt) => this.onCoordinate(evt, "dst")}
												onChange={(evt) => this.onCoordinate(evt, "dst")}
											/>
											{__("from top")}
										</label>
										<label className="_check_ mr-2 w_80" htmlFor={`dst_yb_${this.state.id}`}>
											<input
												type="radio"
												name={`dst_y_${this.state.id}`}
												id={`dst_yb_${this.state.id}`}
												className=""
												checked={this.state.value.dst === "B"}
												value="B"
												onClick={(evt) => this.onCoordinate(evt, "dst")}
												onChange={(evt) => this.onCoordinate(evt, "dst")}
											/>
											{__("from bottom")}
										</label>
									</div>
								)
								break
							default:

								break
						}
						return (
							<div className="d-flex align-items-center w-100">
								<input
									type="number"
									step=".2"
									value={this.state.value?.value}
									field="value"
									className="input dark m-1 text-right w-100 flex-grow-100"
									onChange={(evt) => this.onCoordinate(evt, "value")}
								/>
								{/* 
								<select
									className="input dark m-1 mr-2"
									value={this.state.value?.ei || "-1"}
									onChange={(evt) => this.onCoordinate(evt, "ei")}
								>
									<option value="px">px</option>
									<option value="%">%</option>
								</select>
								{dst} 
								*/}
							</div>
						)
					case "ColumnsCount":
						return <Columns {...this.state} on={this.onColumnCount} />
					case "Padding":
						return <Padding {...this.state} on={this.on} />
					case "Margin":
						return <Margin {...this.state} on={this.on} />
					case "Border":
						return <Border {...this.state} on={this.on} />
					case "Style":
						// console.log( this.state );
						return <Style {...this.state} on={this.onStyle} />
					case "SchemaType":
						return getTypeSelector({
							onChange: (evt) => this.on(this.props.field, evt.currentTarget.value),
							className: " input dark ",
							selected: this.state.value,
						})
					case "Composition":
						return (
							<Composition
								{...this.state}
								on={this.onComposition}
							/>
						)
					case "lasy_load_type":
						return (
							<LasyLoadType
								{...this.state}
								on={this.on}
							/>
						)
					case "Columns":
						return getTypeSelector({
							onChange: (evt) => this.on(this.props.field, evt.currentTarget.value),
							className: " input dark ",
							selected: this.state.value,
						})
					/*
						  case "contact_form":
							  console.log(this.state, value);
							  return "contact_form";
						  */
					/*	
					case "ContentData":
						return <div className="pb-5">
							<MatchingCard
								i={ 0 }
								data={ this.state.origin.data }
								default_title={ '' }
								visible_value={ '' }
								landing_object={ this.state.origin.type } 
								isOpen={true}	
								isSingle={true}
								onEditCard={ value => this.on( this.props.field, value ) }
							/>
						</div>
					*/
					case "Section":
					default:
						let label
						//console.log(this.state, value);
						if (this.state.visibled_value) 
						{
							const tt = getVisibleValue(this.state, this.state.visibled_value)
							label = tt && tt.length > 0 
								? 
								tt 
								: 
								this.props.landing_object
						} 
						else 
						{
							label = this.props.landing_object
						}
						if(label.length > 30)
						{
							label = label.substring(0, 30) + "..."
						}
						return (
							<div
								className="square2 bg-danger text-light mr-1 my-1 btn-item"
								onClick={
									(evt) => this.onSubDialog({
										...value,
										landing_type: this.props.landing_object,
										i: 0,
									})
								}
							>
								{label}
							</div>
						)
						//console.log( value ) 
						/*
						// Новый линейный интерфейс. TODO
						return <MatchingCard
							i={ 0 }
							data={ value }
							default_title={ this.props.landing_object }
							visible_value={ matrix()[ this.props.landing_object ]?.visible_value?.value }
							landing_object={ this.props.landing_object } 
							isOpen={ true }	
							isSingle={ true }
						/>
						*/
				}
		}
	}

	dialogContent = () => {
		let value
		switch (this.state.object.landing_type) {
			case "section":
				return (
					<>
					<TypeDialog
						{...this.state.object}
						onChange={this.onSection}
						onRnv={this.onRnv}
						onClose={this.onDialog}
						onClipboardPaste={this.onClipboardPaste}
						onClipboardCopy={this.onClipboardCopy}
					/>
					</>
				)
			case "Card":
				value = typeof this.state.value != "undefined" ? this.state.value : []
				//console.log( value )
				//console.log( {...value[this.state.object.i]} )
				//console.log( this.state.object )
				return <>
					<div className="py-4 dialog-content overflow-y-auto">
						<InputForm
							{...value[this.state.object.i]}
							source={this.state.object.landing_type}
							id={this.state.object.i}
							data={value[this.state.object.i]}
							card_fields={this.state.object.object.card_fields}
							sourceType={this.state.object.landing_type}
							on={(val, field) => {												
								//console.log("onDialogEdit", val, field)
								this.onDialogEdit(val, field)
							}}
							onChange={(val, field) => {												
								//console.log("onDialogEdit", val, field)
								this.onDialogEdit(val, field)
							}}
						/>
					</div>
					<div className="d-flex justify-content-center">
						<ButtonGroup className="p-0" fill={true} large={true}>
							<Button
								icon="cog"
								intent={Intent.SUCCESS}
								onClick={this.onDialogUpdate}
								className="flex-grow-100"
							>
								{__("Edit")}
							</Button>
							<Button
								icon="cross"
								intent={Intent.DANGER}
								onClick={this.onRemoveElement}
								className="flex-grow-1"
							>
								{__("Delete")}
							</Button>
						</ButtonGroup>
					</div>
				</>
			case "TestanomialMember":
			case "MotivatonElement":
			default:
				if(Array.isArray(this.state.value) && typeof this.state.value !== "undefined")
				{
					value = this.state.value
					// console.log( this.state )
					// console.log( this.state.object, value, typeof this.state.value )
					return <>
						<div className="p-4 dialog-content overflow-y-auto">
							<InputForm
								{...value[this.state.object.i]}
								source={this.state.object.landing_type}
								id={this.state.object.i}
								data={Array.isArray(value) ? value[this.state.object.i] : value}
								sourceType={this.state.object.landing_type}
								object={this.state.object.object}
								on={(val, field) => {												
									//console.log("onDialogEdit", val, field)
									this.onDialogEdit(val, field)
								}}
								onClipboardCopy={this.onClipboardCopy}
							/>
						</div>
						<div className="d-flex justify-content-center">
							<ButtonGroup className="p-2">
								<Button
									icon="cog"
									intent={Intent.NONE}
									onClick={this.onDialogUpdate}
								>
									{__("Edit")}
								</Button>
								<Button
									icon="cross"
									intent={Intent.DANGER}
									onClick={this.onRemoveElement}
								>
									{__("Delete")}
								</Button>

							</ButtonGroup>
						</div>
					</>
				}
				else
				{
					value = this.state.value
					//console.log( this.state.object, value, typeof this.state.value )
					return <>
						<div className="p-4 dialog-content overflow-y-auto">
							<InputForm
								{...value }
								source={this.state.object.landing_type}
								id={this.state.object.i}
								data={value}
								sourceType={this.state.object.landing_type}
								object={this.state.object.object}
								on={(val, field) => {												
									//console.log("onDialogEdit", val, field)
									this.onDialogEdit(val, field)
								}}
							/>
						</div>
						<div className="d-flex justify-content-center">
							<ButtonGroup className="p-2">
								<Button
									icon="cog"
									intent={Intent.NONE}
									onClick={this.onDialogUpdate}
								>
									{__("Edit")}
								</Button>
							</ButtonGroup>
						</div>
					</>
				}
				
		}
	}
	onEditMatchingCard = ( value, field ) =>
	{

	}
	onDialogEdit = (value, field) => {
		// console.log(value, field)
		let v
		if (Array.isArray(this.state.value)) 
		{
			v = [...this.state.value]
			const object = v[this.state.object.i]
			object[field] = value
			v[this.state.object.i] = { ...v[this.state.object.i], field: value }
			//console.log( value, field, v );
			this.setState({ value: v }) 
			// this.props.on( v, field )
			// setTimeout(() => console.log( this.state.value ), 500)
		} 
		else 
		{
			v = { ...this.state.value }
			v[field] = value
			this.setState({ value: v })
		}
	}

	onDialogUpdate = (evt) => {
		//console.log("onDialogUpdate", this.state.field, this.state.value)
		this.on(this.state.field, this.state.value)
	}

	onRemoveElement = (evt) => {
		//console.log("onRemoveElement")
		const value = [...this.state.value]
		value.splice(this.state.object.i, 1)
		this.setState({ value })
		this.on(this.state.field, value)
	}

	onAddArray = (evt) => {
		// console.log( this.state.value,  this.state.field, this.state.landing_object, this.state );
		const mt = matrix()[this.state.landing_object]
		const dflt = mt.default ? mt.default : {}
		let obj = {}
		Object.keys(mt)
			.filter((e) => !mt[e].hidden)
			.forEach((e, i) => {
				// console.log( e, mt[e] );
				let el
				switch (mt[e].type) {
					case "int":
					case "number":
					case "slider":
						el = dflt[e] ? dflt[e] : 0
						break
					case "boolean":
						el = dflt[e] ? dflt[e] : false
						break
					case "geo":
					case "array":
						el = dflt[e] ? dflt[e] : []
						break
					case "checkbox":
					case "string":
					case "media":
					case "color":
					case "url":
					case "text":
					case "date":
						el = typeof dflt[e] !== "undefined" ? dflt[e] : ""
						if (e === "id" && this.state.landing_object === "section") {
							el = DataContext.getMaxSectionID(true)
						}
						break
					case "radio":
					

						break
					default:
					case "landing_object":
						const mtt = matrix()[mt[e].landing_object]
						el = dflt[e] ? dflt[e] : {}
						// console.log( mtt, e, el, mt[e] );
						if (!mtt || dflt[e]) break
						Object.keys(mtt)
							.filter((ee) => !mtt[ee].hidden)
							.forEach((ee, i) => {
								// console.log( ee, mtt[ee] );
								let ell
								switch (mtt[ee].type) {
									case "int":
									case "number":
									case "slider":
										ell = 0
										break
									case "boolean":
										ell = false
										break
									case "checkbox":
									case "array":
									case "string":
									case "media":
									case "url":
									case "text":
									case "date":
										ell = ""
										break
									case "color":
										ell = null
										break
									case "radio":
									

										break
									default:
									case "landing_object":
										// console.log( ee, mtt[ee].landing_object, matrix()[ mtt[ee].landing_object ] );
										ell = mtt[ee].kind === "array"
											? []
											: {}
										break
								}
								el[ee] = ell
							})

						break
				}
				obj[e] = el
			})
		//console.log( this.state.landing_object )
		const value = Array.isArray(this.state.value)
			? this.state.value
			: []
		if (this.state.landing_object === "Card" && value.length > 0) 
		{
			obj = value[value.length - 1]
		}
		if (this.state.landing_object === "section") {
			//obj = getDefault()
			//obj.id = DataContext.getMaxSectionID(true)
			//console.log("onAddArray section")
			this.onNewSectionOpen()
			return
		}
		if (this.state.landing_object === "CardField") {
			obj.type = "string"
			//console.log(obj)
		}
		value.push({ ...obj })
		console.log( value );
		this.setState({ value })
		console.log( "onAdd Array" );
		this.on(this.state.field, value)
	}

	onMultiInput = (evt) => {

	}

	onMulti = (data) => {
		// console.log( data );
		const value = []; 
		let vv = 0
		data.forEach((e, i) => {
			if (e !== 0) {
				const v = e - vv
				vv += v
				value.push(v)
			}
		})
		// value.push(100 - vv);
		//console.log( this.state.field, data, vv, value );
		this.setState({ value })
		//console.log("onMulti")
		this.on(this.state.field, value)
	}

	onColumnCount = (data, field ) => {
		const proportia = Array(field).fill(1)
			.map( el => 100 / field)
		// console.log(data, field ); 
		// console.log( "inner_proportia", proportia ); 
		// console.log( this.props.origin );
		this.props.on( field, data )
		this.props.on( proportia, "inner_proportia" )
		this.setState({ isDialog: false })
	}
	onCardOrientation = evt =>
	{
		const or = this.props.origin.data.orientation 
		this.props.onSubMenu(
			or === "vertical" ? "horizontal" : "vertical", 
			"orientation" 
		) 
	}
	onSwitchButton = (evt) => {
		const i = parseInt(evt.currentTarget.getAttribute("i"))
		const value = Array.isArray(this.state.value)
			? this.state.value
			: []
		const next = value.splice(i - 1, 1)
		value.splice(i, 0, next[0])
		this.setState({ value })
		//console.log("onSwitchButton")
		this.on(this.state.field, value)
	}

	onCardFieldSwitchButton = (evt) => {
		const i = parseInt(evt.currentTarget.getAttribute("i"))
		//
		const section = DataContext.getSection(this.props.section_id)
		const dopol = section.data.cards.map((e, ii) => {
			// console.log( e.fields );
			const card = Array.isArray(e.fields)
				? [...e.fields]
				: []
			const _next = card.splice(i - 1, 1)
			card.splice(i, 0, _next[0])
			// console.log(card)
			return { ...e, fields: card }
		})
		//
		const value = Array.isArray(this.state.value)
			? this.state.value
			: []
		const next = value.splice(i - 1, 1)
		value.splice(i, 0, next[0])
		this.setState({ value }) 
		//console.log("onCardFieldSwitchButton")
		this.on(this.state.field, value, dopol)
	}

	onCardMetaphore = (data, field, dopol) => {
		//console.log(data, field, dopol);
		this.props.on(field, data, dopol)
		this.setState({ isDialog: false })
	}
	on = (data, field, dopol) => {
		console.log("on", data, field);
		this.props.on(field, data, dopol) 
		this.setState({ isDialog: false, value: field })
	}

	onDialog = (evt) => {
		this.setState({ isDialog: !this.state.isDialog })
	}

	onCFForm = (data, i) => {
		const value = [...this.state.value]
		value[i] = data
		this.setState({ value })
		//console.log(value)
		//console.log("onCFForm")
		this.on(this.state.field, value)
	}

	onCFBClose = (eid) => {
		const value = [...this.state.value]
		// console.log( eid, value );
		value.splice(eid, 1)
		// this.on( value, this.state.field );
		// console.log( "onCFBClose", value );
		this.setState({ value })
		this.props.on(value, this.state.field)
	}
	onCFBClone = eid =>
	{
		const value = [...this.state.value]
		//console.log( eid, value );
		value.splice( eid, 0, { ...value[ eid ], grid_id: UUID.v4() } )
		// console.log( eid, value );
		// this.on( value, this.state.field );
		// console.log( "onCFBClose", value );
		this.setState({ value })
		this.props.on(value, this.state.field)
	}

	onSubDialog = (object, type, i) => {
		//console.log(object)
		this.setState({ object, isDialog: true, current_element: i })
	}

	onCardDialog = (object, type, i) => {
		// console.log( object );
		this.setState({ object, isDialog: true, current_element: i })
	}
	onClipboardCopy = data =>
	{
		if(this.props.onClipboardCopy)
		{
			//console.log("LandingObject.onClipboardCopy", data)
			this.props.onClipboardCopy(data)
		}
	}
	onClipboardPaste = data =>
	{
		if(this.props.onClipboardPaste)
		{
			console.log("LandingObject.onClipboardPaste")
			this.props.onClipboardPaste(data)
		}
	}
	onSection = (type, data) => {
		//console.log( "on Section Edit", type, data );
		let value = [...this.state.value]
		// console.log( value );
		value = Array.isArray(value) ? value : []
		value.forEach((e, i) => {
			//console.log( e.id, data.id );
			if (e.id === data.id) {
				const dt = { ...data, type }
				delete dt.current_type
				delete dt.is_change_type_enbl
				delete dt.navbarTabId
				delete dt.tab
				delete dt.onChange
				value[i] = dt
			}
		})
		if (value.length === 0) {
			value[0] = { ...data, type }
		}
		//console.log("on Section Edit", value)
		this.props.on(value)
		this.setState({ isDialog: false })
	}

	onRnv = (id) => {
		let value = [...this.state.value]
		// console.log( value );
		value = Array.isArray(value) ? value : []
		value.forEach((e, i) => {
			// console.log( e.id, data.id );
			if (e.id === id) {
				value.splice(i, 1)
			}
		})
		//console.log("on Section Delete", value)
		this.props.on(value)
		this.setState({ isDialog: false })
	}

	onStyle = (val) => {
		this.setState({ value: val })
		this.props.on(val, this.state.field)
	}

	onCoordinate = (evt, field) => {
		const value = { ...this.state.value }
		value[field] = evt.currentTarget.value
		// console.log(value);
		this.setState({ value })
		this.props.on(value)
	}

	onComposition = (value, field) => {
		// let value = { ...this.state.value };
		// value[field] = evt.currentTarget.value;
		//console.log(value, field)
		// this.setState( { value });
		// this.props.on(value);
	}

	onPlaceType = (evt, field) => {
		this.setState({ value: evt.currentTarget.value })
		this.props.on(evt.currentTarget.value, field)
	}

	onVideoGroup = (evt, field) => {
		this.props.on(evt.currentTarget.value, field)
	}

	onPaletteForm = (value) => {
		this.props.on(value, this.props.field)
	} 
	onNewSectionOpen = () =>
	{
		this.setState({ isNewSectionOpen : !this.state.isNewSectionOpen })
	}
	
	onClear = (evt) => {
		const default_value = matrix()[this.props.landing_object] 
			&& matrix()[this.props.landing_object].default_null
			?
			matrix()[this.props.landing_object]?.default_null?.value
			:
			null
		console.log( default_value ) 
		this.setState({ value: default_value })
		//console.log("onClear")
		this.on(this.props.field, default_value)
	  }
	onAddSection = (data, type) =>
	{
		const value = Array.isArray(this.state.value)
			? this.state.value
			: []
		let sec 
		if (matrix()[type].default) 
		{
			const data1 = {
				data: { ...matrix()[type].default },
				composition: {columns:1, is_blocked: 0, is_expand: 0, is_vertical_center: 0 }
			}
			delete data1.data.hidden
			delete data1.data.composition 
			sec = getDefault(type, data1)
		}
		else
		{
			sec = getDefault(
				type, 
				{ 
					composition:
					{
						columns:1, 
						is_blocked: 0, 
						is_expand: 0, 
						is_vertical_center: 0 
					}, 
					data:{}
				}
			)
		}
		value.push({ ...sec })
		this.setState({ value, isNewSectionOpen:false })
		//console.log("onAddSection")
		this.on(this.state.field, value)
	}
	onClipboardCardFieldCopy = () =>
	{
		const data= {
			data:{
				back_image_vertical: this.state.origin.data.back_image_vertical,
				box_shadow: [...this.state.origin.data.box_shadow],
				card_inner_style:  {...this.state.origin.data.card_inner_style},
				cards: [...this.state.origin.data.cards],
				cliping: this.state.origin.data.cliping,
				color: this.state.origin.data.color,
				decoration: this.state.origin.data.decoration, 
				fields: [...this.state.origin.data.fields],
				is_contrast_muar: this.state.origin.data.is_contrast_muar,
				padding: this.state.origin.data.padding,
				style: {...this.state.origin.data.style},
				tension: this.state.origin.data.tension,
				title: this.state.origin.data.title ,
				vertical_align: this.state.origin.data.vertical_align
			},
			current_template_id: this.state.origin.current_template_id
			 
		}
		//console.log(data)
		const copy = JSON.stringify(data)
		//console.log(copy) 
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='StyleClipboard'>${copy}</textarea></div>`)
		const copyText = document.getElementById("StyleClipboard")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#StyleClipboard").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Section copy to clipbord"),
		})
	}
	onClipboardConstructordPaste = () =>
	{
		navigator.clipboard.readText()
			.then((clipText) => {
				try 
				{
					const clip = JSON.parse(clipText)
					//console.log(clip, this.props.onClipboardPaste)
					this.setState({ value: clip.data.fields })
					//console.log("onClipboardConstructordPaste")
					this.on(this.state.field, clip.data.fields)
					// if(this.props.onClipboardPaste)
					// {
					// 	this.props.onClipboardPaste(clip)
					// }
				} 
				catch (e) 
				{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("Error read clipboard data"),
					})
				}
			})
	}
	onClipboardCardFieldPaste = () =>
	{
		navigator.clipboard.readText()
			.then((clipText) => {
				try 
				{
					const clip = JSON.parse(clipText)
					//console.log(clip, this.props.onClipboardPaste)
					this.setState({ value: clip.data.fields })
					//console.log("onClipboardCardFieldPaste")
					this.on(this.state.field, clip.data.fields)
					if(this.props.onClipboardPaste)
					{
						this.props.onClipboardPaste(clip)
					}
				} 
				catch (e) 
				{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("Error read clipboard data"),
					})
				}
			})
	}
	onClipboardCardContentCopy = () =>
	{ 		
		// const value = Array.isArray(this.state.value)
		// 	? this.state.value
		// 	: []
		//console.log( this.state.origin  )  
		const copy = JSON.stringify( this.state.origin  )
		//console.log(copy) 
		
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='StyleClipboard'>${copy}</textarea></div>`)
		const copyText = document.getElementById("StyleClipboard")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#StyleClipboard").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Card content copy to clipbord"),
		})
	}
	onClipboardCardContentPaste = () =>
	{
		navigator.clipboard.readText()
			.then((clipText) => {
				try 
				{
					const clip = JSON.parse(clipText)
					//console.log( clip ) 
					this.setState({ value: clip.fields })
					//console.log("onClipboardCardContentPaste")
					this.on(this.state.field, clip.fields)
					// 
					// if(this.props.onClipboardPaste)
					// {
					// 	this.props.onClipboardPaste(clip)
					// }
				} 
				catch (e) 
				{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("Error read clipboard data"),
					})
				}
			})
	}
	onSubMenu = (val, field, title, prefix) =>
	{
		//console.log(val, field, title, prefix)
		let state = {...this.state}
		state[field] = val
		//console.log(state)
		this.setState(state)
		//console.log("onSubMenu")
		this.on( field, val)
	}
	onDataTypeField = (value, field) =>
	{
		//console.log(value, field, this.state )

		let state = {...this.state}
		state[field] = value
		//console.log("onDataTypeField")
		//this.setState(state)
		this.on( field, value, )


		// const data_type_field = value
		// let obj = { ...this.state.object}
		// obj[field] = value
		// const object = { object: obj }
		// this.setState(object) 
		// this.onChange(object)
	}
}
export default LandingObject

export function getStyle(style) {

}
