import React, { Component, Suspense } from "react"
import {
	Button, Intent, ButtonGroup,
} from "@blueprintjs/core"
import $ from "jquery"
//import { AutoSuggest } from "react-autosuggestions"
//import Select from "react-select" // https://github.com/JedWatson/react-select
import { __ } from "react-pe-utilities" 
import matrix from "../../views/LandingState/data/matrix"
import { AppToaster } from 'react-pe-useful'
import css_type from "../../views/LandingState/data/css_type"
import CSSColor from "../utilities/CSSColor"
import CSSSize from "../utilities/CSSSize"
import Select from "react-select"
import DataContext from "../../views/LandingState/DataContext"
import {Loading} from 'react-pe-useful' 


class ExternalStyle extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: this.props.value
				&& Array.isArray(Object.entries(this.props.value))
				&& Object.entries(this.props.value).length > 0
				? Object.entries(this.props.value).map((e, i) => ({
					field: e[0],
					value: e[1],
				}))
				: [],
			typeInput: props.value
				&& Array.isArray(props.value)
				&& props.value.length > 0
				? props.value.map((e) => "object")
				: [],
		}
	}
	componentWillMount()
	{
		if(!document.getElementById("autocomplete-sdk"))
		{
			const el = document.createElement("script")
			el.context = this
			el.type = "text/javascript"
			el.src = "/assets/js/autocomplete.js"
			el.async = true
			el.id = "autocomplete-sdk"
			el.onload = function () { 
				//console.log("autocomplete is loaded.")
				el.context.setState({isLoad: true}) 
			}
			document.getElementsByTagName("head")[0].appendChild(el)
		}
		else
		{
			this.setState({ isLoad: true })
		}
	}
	ac
	componentDidUpdate()
	{
		// console.log( this.state.isLoad ) 
		// //if(!this.state.isLoad) return
		// //if(!this.ac && this.state.isLoad)
		// {
		// 	try
		// 	{
		// 		this.ac = new Autocomplete(document.getElementById('myAutocomplete'), {
		// 			data: this.datasrc,
		// 			highlightTyped: true,
      	// 			highlightClass: 'text-primary'
		// 		});
		// 	}
		// 	catch(e){}
			
		// }
	}


	datasrc = () => [
		{label: 'Option 1', value: 'opt1'},
		{label: 'Option 2', value: 'opt2'},
		{label: 'Option 3', value: 'opt3'},
		// ...
	]

	render() {
		// if(!this.state.isLoad)
		// 	return <Loading />
		// console.log(this.state);
		const value = this.state.value.map((e, i) => {
			const curr = this.state.typeInput[i]
			return (
				<div className="row mb-2" key={i}>
					<div className="col-md-10 p-0">
						<input
							className="form-control input small text-danger title opacity_75 "
							type="text"
							value={e.field}
							placeholder="attribute name"
							i={i}
							id="myAutocomplete"
							onChange={this.onField}
						/>
					</div>
					<div className="col-md-10 col-9 px-0">
						<div className="d-flex w-100">
							{
								this.getInput(e, i)
							}
						</div>
					</div>
					<div className="col-md-2 col-12 px-0 d-flex">
						<div className=" hint hint--top" data-hint={__(curr != "string" ? "Expert mode" : "Default mode")}>
							<Button
								minimal
								onClick={(evt) => this.onInputType(i, evt)}
								icon={curr != "string" ? "group-objects" : "new-text-box"}
								className={!curr || curr == "string" || curr == "object" ? "h-100 " : "h-100 opacity_5"}
							/>
						</div>
						{
							//
							// Object.keys(matrix().__palette).filter((ee) => matrix().__palette[ee].type == this.getSCCStyle(e).type && !ee.hidden).length > 0
							// 	? (
							// 		<div className=" hint hint--top" data-hint={__("From template")}>
							// 			<Button
							// 				minimal
							// 				onClick={(evt) => this.onTemplate(i, evt)}
							// 				icon={<i className="fas fa-th-list" />}
							// 				className={curr == "template" ? "h-100 " : "h-100  opacity_25"}
							// 			/>
							// 		</div>
							// 	)
							// 	: <div style={{ minWidth: 34 }} />
						}

						<Button
							minimal
							onClick={this.onRemove}
							icon="minus"
							className=" hint hint--top"
							data-hint={__("Remove")}
						/>
					</div>

				</div>
			)
		})

		return (
			<div className="p-0  ">
				<div className="p-3">
					{value}
					<Button
						className=" position-absolute right"
						icon="cross"
						minimal
						onClick={this.onClear}
					/>
				</div>
				<div className="p-2 d-flex">
					<Button icon="plus" onClick={this.onAdd} minimal className="add-attr-btn">
						{__("add attribute")}
					</Button>
					<div className="descr ml-2 ">
						{__("Attrubute names automatically put by JavaScript-style. ForExample: 'borderRadius' not 'border-radius'")}
					</div>
					<ButtonGroup>
						<Button minimal className="hint hint--top" data-hint={__("copy style")} onClick={this.onClipboardCopy}>
							<i className="fas fa-file-import" />
						</Button>
						<Button minimal className="hint hint--top" data-hint={__("paste style")} onClick={this.onClipboardPaste}>
							<i className="fas fa-file-export" />
						</Button>
					</ButtonGroup>
				</div>
			</div>
		)
	}
	onClear = () => {
		this.setState({ value: [] })
		this.props.on([])
	}

	onInputType = (i) => {
		const typeInput = [...this.state.typeInput]
		typeInput[i] = typeInput[i] != "string" ? "string" : "object"
		this.setState({ typeInput })
	}

	onTemplate = (i) => {
		const typeInput = [...this.state.typeInput]
		typeInput[i] = "template"
		this.setState({ typeInput })
	}

	onMenu = (type, i, evt) => {
		const typeInput = [...this.state.typeInput]
		typeInput[i] = type
		this.setState({ typeInput })
	}

	// !isNaN( parseFloat( e.value ) )

	getInput = (e, i) => {
		const { typeInput } = this.state
		let res
		const css = this.getSCCStyle(e)
		// console.log( css );
		switch (typeInput[i]) {
			case "string":
				return <>
					<input
						type="text"
						className="form-control input dark small"
						value={e.value}
						placeholder="value"
						i={i}
						onChange={this.onValue}
					/>
					<Button
						className=" "
						icon="cross"
						minimal
						i={i}
						onClick={this.onValue}
					/>
				</>
			case "template":
				const template = this.getCurrentTemplate()
				const options = Object.keys(matrix().__palette)
					.filter((ee) => matrix().__palette[ee].type == css.type && !ee.hidden)
					.map((ee, ii) => <option value={ee} key={ii}>{matrix().__palette[ee].title}</option>)
				return <>
					<select
						className="form-control input dark"
						defaultValue={-1}
					>
						<option value={-1}>--</option>
						{options}
					</select>
					<Button
						className=" "
						icon="cross"
						minimal
						i={i}
						onClick={this.onValue}
					/>
				</>
				break
			case "object":
			default:
				switch (css.type) {
					case "color":
						return (
							<CSSColor
								{...e}
								on={(value) => this.on(value, i)}
								palette={this.props.palette}
								distination={css.distination}
							/>
						)
					case "size":
						return !isNaN(parseFloat(e.value))
							?
							<>
								<div className="d-flex w-100">
									<CSSSize
										{...e}
										on={(value) => this.on(value, i)}
										palette={this.props.palette}
									/>
								</div>
								<Button
									className=" "
									icon="cross"
									minimal
									i={i}
									value={"0px"}
									onClick={this.onValue}
								/>
							</>
							:
							<>
								<input
									type="text"
									className="form-control input dark small"
									value={e.value}
									placeholder="value"
									i={i}
									onChange={this.onValue}
								/>
								<Button
									className=" "
									icon="cross"
									minimal
									i={i}
									onClick={this.onValue}
								/>
							</>
					case "select":
						const options = (Array.isArray(css.values) ? css.values : []).map((e, i) => ({ value: e, label: e }))
						return <>
							<Suspense fallback={<Loading/>}>
								<Select
									{...e}
									options={options}
									value={e.value.value ? e.value : { value: e.value, label: e.value }}
									isMulti={false}
									isSearchable
									onChange={(value) => this.onSelect(value, i)}
									className="basic-multi-select w-100"
									classNamePrefix="select"
								/>
							</Suspense>
							<Button
								className=" "
								icon="cross"
								minimal
								i={i}
								onClick={this.onValue}
							/>
						</>
					case "int":
						return <>
							<input
								className="form-control input dark small"
								type="number"
								value={e.value}
								placeholder="value"
								i={i}
								onChange={this.onValue}
							/>
							<Button
								className=" "
								icon="cross"
								minimal
								i={i}
								onClick={this.onValue}
							/>
						</>
					case "string":
					default:
						return <>
							<input
								className="form-control input dark small"
								type="text"
								value={e.value}
								placeholder="value"
								i={i}
								onChange={this.onValue}
							/>
							<Button
								className=" "
								icon="cross"
								minimal
								i={i}
								onClick={this.onValue}
							/>
						</>
				}
		}

		return res
	}

	getSCCStyle(e) {
		let css = css_type().filter((field) => field.field == e.field)[0]
		css = css || css_type()[0]
		return css
	}

	getCurrentTemplate() {
		let template = DataContext.data.landing.palette
			? DataContext.data.landing.palette.filter((ee) => ee.id == DataContext.getSection(this.props.id).current_template_id)
			: {}
		template = template || (DataContext.data.landing.palette[0]
			? DataContext.landing.palette[0]
			: {})
		return template
	}

	onClipboardCopy = () => {
		const copy = JSON.stringify(this.state.value)
		console.log(copy)
		console.log(this.state.value)
		$("body").append(`<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='StyleClipboard'>${copy}</textarea></div>`)
		const copyText = document.getElementById("StyleClipboard")
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
		$("#StyleClipboard").remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			duration: 10000,
			message: __("Section copy to clipbord"),
		})
	}

	onClipboardPaste = () => {
		navigator.clipboard.readText()
			.then((clipText) => {
				try {
					console.log(clipText)
					const clip = JSON.parse(clipText)
					this.setState({ value: JSON.parse(clipText) })
					this.props.on(JSON.parse(clipText))
				} catch (e) {
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						duration: 10000,
						message: __("Error read clipboard data"),
					})
				}
			})
	}

	onField = (evt) => {
		evt.preventDefault()
		const i = parseInt(evt.target.getAttribute("i"))
		const value = [...this.state.value]
		value[i].field = evt.currentTarget.value
		const v = {}
		this.setState({ value })
		value.forEach((e) => {
			v[e.field] = e.value
		})
		this.props.on(v)
	}

	on = (val, i) => {
		const value = [...this.state.value]
		value[i].value = val
		this.setState({ value })
		const v = {}
		value.forEach((e) => {
			if (e.field && e.value) v[e.field] = e.value
		})
		this.props.on(v)
	}

	onSelect = (val, i) => {
		const value = [...this.state.value]
		value[i].value = val.value
		this.setState({ value })
		const v = {}
		value.forEach((e) => {
			if (e.field && e.value) v[e.field] = e.value
		})
		console.log(v)
		this.props.on(v)
	}

	onValue = (evt) => {
		const i = parseInt(evt.currentTarget.getAttribute("i"))
		const value = [...this.state.value]
		value[i].value = evt ? evt.currentTarget.value : null
		this.setState({ value })
		const v = {}
		value.forEach((e) => {
			if (e.field && e.value) v[e.field] = e.value
		})
		this.props.on(v)
	}

	onAdd = () => {
		const value = [...this.state.value]
		value.push({ field: "", value: "" })
		this.setState({ value })
		this.props.on(value)
	}

	onRemove = (evt) => {
		const i = parseInt(evt.currentTarget.getAttribute("i"))
		const value = [...this.state.value]
		value.splice(i, 1)
		this.setState({ value })
		this.props.on(value)
	}

	highlightText(text, query) {
		let lastIndex = 0
		const words = query
			.split(/\s+/)
			.filter((word) => word.length > 0)
			.map(this.escapeRegExpChars)
		if (words.length === 0) {
			return [text]
		}
		const regexp = new RegExp(words.join("|"), "gi")
		//const tokens: React.ReactNode[] = []
		const tokens = []
		while (true) {
			const match = regexp.exec(text)
			if (!match) {
				break
			}
			const { length } = match[0]
			const before = text.slice(lastIndex, regexp.lastIndex - length)
			if (before.length > 0) {
				tokens.push(before)
			}
			lastIndex = regexp.lastIndex
			tokens.push(<strong key={lastIndex}>{match[0]}</strong>)
		}
		const rest = text.slice(lastIndex)
		if (rest.length > 0) {
			tokens.push(rest)
		}
		return tokens
	}

	escapeRegExpChars(text) {
		return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
	}
}
export default ExternalStyle
