import { Button, Icon, Intent } from "@blueprintjs/core"
import React, { useState } from "react"
import ReactDOM from 'react-dom' 
import { __ } from "react-pe-utilities/dist"

const Panel = props =>
{
   const [ mode, changeMode ] = useState("closed")
   const onMode = () => {  
        document.html.style.marginTop = mode === "closed" ? "50px" : "0px"
        changeMode( mode === "open" ? "closed" : "open" )
        //if(mode === "open")
        {
            props.onEditHandler()
        }
   }
   const getPanel = () =>
    {
        return <div className={`grid-layout-panel ${mode} `}>
            PANEL
            <div className="d-flex">
                <div className="d-flex px-4">
                    <Button
                        icon={<i className="fas fa-cut" />}
                        onClick={props.onStartRaze}
                    >

                    </Button>
                </div>
                <div className="d-flex">
                    <Button
                        intent={Intent.NONE}
                        onClick={props.onSettings}
                        large={false}
                        icon="cog"
                        className="mr-1"
                        title={__("Landing settings")}
                    />
                    <Button
                        intent={Intent.NONE}
                        onClick={props.onHelp}
                        large={false}
                        icon="help"
                        className="mr-1"
                        title={__("Help")}
                    />
                    <div style={{ width: props.is_not_update ? 270 : 0 }} className="landing-upd-cont mr-1">
                        <Button
                            intent={Intent.SUCCESS}
                            onClick={props.onUpdateHandler}
                            large={false}
                            icon="floppy-disk"
                            title={__("Update Landing")}
                            style={{ width: 270 }}
                        >
                            {__("Update Landing")}
                        </Button>
                    </div>
                    
                </div>
            </div>
            <div
                onClick={ onMode }
                className="mode-bottom"
            >
                <Icon icon={mode !== "open" ? "caret-down" : "caret-up"} />
            </div>
        </div>
    }
    return ReactDOM.createPortal(
        getPanel(),
        document.body
    )

}

export default Panel