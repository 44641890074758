import { Callout, Dialog, Icon } from "@blueprintjs/core"
import React from "react"
import { __ } from "react-pe-utilities";

const Outer = (props) => {
    return <Dialog
        className="landing-outer-container"
        isOpen={props.isOuterOpen}
    >
        {
            props.outerURL
                ?
                <iframe
                    src={props.outerURL}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none"
                    }}
                >
                </iframe>
                :
                <Callout className=" p-5 text-center h-100 d-flex align-items-center justify-content-center title">
                    {__("No url exists")}
                </Callout>
        }
        <div className="landing-outer-close" onClick={props.onOpen}>
            <Icon icon="cross" />
        </div>
    </Dialog>
}
export default Outer;