import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import _fetch from "../.."
import WPFestSettings from "../../utilities/WPFestSettings"

export default props =>
{ 
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState(
        `<div class="title my-3">
            Что теперь необходимо сделать?
        </div>
        <ol> 
            <li>
                <p>Проверьте все вкладки «Этапы».</p>
                <p>
                    Если Вы видите предупреждение «ДЛЯ ПРОДОЛЖЕНИЯ РАБОТЫ НА ЭТОМ ЭТАПЕ АВТОРУ НЕОБХОДИМО ЗАРЕГИСТРИРОВАТЬ ВЛОЖЕНИЯ», то значит необходимо нажать кнопку «Добавить новое вложение типа Ссылка» и загрузить (или указать) нужные данные Вашего проекта (за подробностями обратитесь к ведущему Вашего трека). 
                </p>
            </li>
            <li>
                <p>Оцените Ваш проект во вкладках с зелёными ярлыками (этапах самооценки). До конца фестиваля Вашу оценку можно свободно изменить.</p>
                <p>
                    Средний бал, который получит Ваш проект вычисляется по определённой формуле из значений всех оценок, выставленных Вами и сторонними экспертами.
                </p>
            </li>
        </ol>`
    )
    const params = useParams()
    useEffect(() => {
        console.log( params.festid ) 
        _fetch(
			"get_site_url",
			{ 
                id: params.festid,
            },
			WPFestSettings.serviceURL,
			"", 
			"wpfa", 
		  )
          .then(data =>
            {
                if(data.url)
                { 
                    _fetch(
                        "get_fest_command",
                        { festId: params.festid, command: "get_new_project_message" },
                        WPFestSettings.serviceURL,
                        WPFestSettings.getToken( data.url ),
                        "wpfa",
                    )
                    .then(fData => {
                        console.log(fData.data )
                        setLoading(false)
                        if( fData.data.new_project_message )
                        {
                            setMessage( fData.data.new_project_message )
                        }
                    })
                }
            })  
        
    }, [])
    if(loading)
    {
        return null
    }
    return <>
        <div className="advance">
            <p className="display-6">
                Ваш проект <span className="title">«{props.project.title}»</span> зарегистрирован.
            </p>
            <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
     
    </>
}