import React from "react"
import Style from "style-it"

const SectionGrid = props => {
    const getProportia = () =>
    {
        if( Array.isArray( props.composition?.inner_proportia ) )
        {
            //props.composition?.inner_proportia.unshift(0)
            return  props.composition?.inner_proportia
        }
        else
        {
            const pr = Array( props.composition?.columns).fill(1).map( e => 100 / (props.composition?.columns | 1 ) )
            // console.log( pr ) 
            return pr 
        }
            
    }
    let xx = 0;
    const composition = props.composition
        ?
        Array(props.composition?.columns).fill(1)
            .map((e, i) => {
                xx +=  getProportia()[ i ]
                return i === 900000 
                    ? 
                    null 
                    :
                    <div
                        key={i}
                        className="landing-section-grid-left"
                        style={{
                            position: "absolute",
                            left: `${ xx }%`
                        }}
                    />
            })
        :
        null;
    const isBool = props.composition?.is_blocked === 1 ||
        props.composition?.is_blocked === "1" ||
        props.composition?.is_blocked === true ||
        !props.composition?.is_blocked
    const isBool2 = props.composition?.is_blocked === 2 ||
        props.composition?.is_blocked === "2"  ||
        !props.composition?.is_blocked
    return props.level === 0
    ?
    Style.it(
        `
        .landing-section-grid
        {
            border-color: ${props.revertColor}55;
        }
        .landing-section-grid-vertical
        {
            border-left-color: ${props.revertColor}55;
            border-right-color: ${props.revertColor}55;
        }
        .landing-section-grid-left
        {
            border-left-color: ${props.revertColor}55;
            border-right-color: ${props.revertColor}55;
        }
        `,
        <div className="position-absolute w-100 h-100 untouchble">
            <div className="landing-section-grid">
                {
                    isBool 
                        ?
                        <div className="container mx-auto">
                            <div className="landing-section-grid-vertical w-100 h-100">
                                {isBool ? composition : null}
                            </div>
                            {!isBool ? composition : null}
                        </div>
                        :
                        null
                }
                {
                    isBool2
                        ? 
                        <div className="position-absolute w-100 h-100">
                            <div className="short-container mx-auto h-100">
                                <div className="landing-section-grid-vertical  w-100 h-100">
                                    {isBool2 ? composition : null}
                                </div> 
                            {!isBool2 ? composition : null}
                            </div>
                        </div>
                        :
                        null
                }               
            </div>
        </div>
    )
    :
    null
}
export default SectionGrid