import React, { useEffect } from 'react' //refcp
import { useRanger } from "react-ranger";
import PropTypes from 'prop-types'

const CardFieldProportiaHandler = props => 
{
    const [values, setValues] = React.useState( props.proportia )
    useEffect(() => {
      setValues( props.proportia)
    }, [props.proportia])
    
    const on = vals =>
    {
        setValues(vals)
        props.on(vals)
    }
    const { getTrackProps, ticks, segments, handles } = useRanger({
        min: 0,
        max: 100,
        stepSize: 1,
        values,
        onChange: on
    });
    return props.orientation === "horizontal"
        ?
        <div className='card-field-proportia-container'>
            <div
                {...getTrackProps({
                style: {
                    height: '24px',
                    background: '#DDDDDD00',
                    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.6)',
                    borderRadius: '2px',
                },
                })}
            >
                {handles.map(({ value, active, getHandleProps }) => (
                    <div
                        {...getHandleProps({
                        style: {
                            width: '32px',
                            height: '24px',
                            background: '#111',
                            color:"#EEE",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            clipPath: "polygon(50% 0, 100% 15%, 100% 100%, 0 100%, 0 15%)",
                            fontSize:"9px",
                            paddingTop:"5px"
                        },
                        })}
                    >
                        <span className='title'>{value}</span><span className='small'>%</span>
                    </div>
                ))}
            </div>
        </div>
        :
        null
}

CardFieldProportiaHandler.propTypes = {}

export default CardFieldProportiaHandler
