import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import {default as UUID} from "node-uuid";
import VerticalList from 'modules/pe-landing-module/widgets/landingObject/dnd/VerticalList';
import { useEffect } from 'react';
import { __ } from 'react-pe-utilities/dist';
import { Button, Icon } from '@blueprintjs/core';
import CardFieldProportiaHandler from './CardFieldProportiaHandler' 
import getUniqGrids from 'modules/pe-landing-module/views/core/getUniqGrids';

export const getProportias = (proportias, fields, orientation) =>
{
    if( orientation !== "horizontal")   return []
    let arr = [0, 50, 100]
    if( !Array.isArray( proportias ) && Array.isArray(fields))
    {
        const columnC = getUniqGrids( fields )
        arr =[ ...columnC.map((e, i ) => Math.floor(100 / columnC.length * i)), 100 ]
    }
    else if(Array.isArray( proportias ) )
    {
        arr = proportias
    }
    if(arr[ arr.length -1 ] === 100 )   arr.pop()
    if(arr[ 0 ] === 0 )                 arr.shift() 
    return arr
}
const CardFieldsDnDEngine = props => {
    const [value, setValue] = useState(props.value.fields)
    const [orientation, setOrientation] = useState( props.value.orientation )
    const [proportia,   setProportia]   = useState( 
        getProportias(
            props.value.horizontal_grid_proportia, 
            props.value.fields, 
            props.value.orientation 
        ) 
    )
    
    useEffect(() => { 
        setValue(props.value.fields)
    }, [props.value])

    useEffect(() => { 
        setProportia(getProportias(
            props.value.horizontal_grid_proportia, 
            props.value.fields, 
            props.value.orientation 
        )) 
    }, [ props.value ])

    const btnContClasses = "d-flex flex-wrap w-100 my-3"
    
    // console.log( "CardFieldsDnDEngine", proportia )
    
    const __fields = (Array.isArray(value) ? value : []).map(item =>
    {
        const itemId = item.id ? item.id : `cardfield-${UUID.v4()}`
        const itemGridId = item.grid_id ? item.grid_id : `cardfieldGrid-${UUID.v4()}`
        return { ...item, id:itemId, grid_id: itemGridId }
    }) 
    const onCFBClose = (eid) => {
		const val = [...value] 
		val.splice(eid, 1)
		setValue(val)
		props.on(val, "fields" )
	}
    const onCFForm = (data, i) => {
		const val = [...value]
		val[i] = data
		setValue(val)
		on( val, "fields" )
	}
	const onCFBClone = eid =>
	{
		const val = [...value]
		val.splice( eid, 0, { ...val[ eid ], grid_id: UUID.v4() } ) 
		setValue(val)
		props.on( val, "fields" )
	}
    const onAddArray = () =>
    {
        const val = [...value]
        const i = val.length - 1
		val.splice( i, 0, { ...val[ i ], grid_id: UUID.v4(), id: "cardfield-" + UUID.v4() } ) 
		console.log(val)
		setValue(val)
		props.on( val, "fields" )
    }
    const onCardOrientation = () =>
	{
        const or = orientation === "vertical" ? "horizontal" : "vertical"
		setOrientation( or )
        props.on( or, "orientation" )
	}
    const onProportia = vals =>
	{
        props.on( vals, "horizontal_grid_proportia" )
	}
    
    const on =( val, fieldType ) =>
    {
        setValue(val)
        props.on( val, fieldType )
    }
    return <div className="d-flex w-100 flex-column" style={{ maxWidth: 550, minWidth:420 }}>
        <VerticalList
            fields={ __fields }
            origin={{ data: {...props.value, orientation} }}
            onCFBClose={ onCFBClose }
            onCFBClone={ onCFBClone }
            onCFForm={ onCFForm }
            on={(val) => {
                console.log("VerticalList", val)
                on( val, "fields" )
            }}
            selectField={ props.selectField }
            onSelectField={ props.onSelectField }
        />
        <CardFieldProportiaHandler
            orientation={orientation}
            proportia={proportia}
            on={onProportia}
        />
        <div className="w-100">
            <div className={`${btnContClasses} mt-1`}> 
                <Button
                    icon="plus"
                    large
                    className=" mr-1 hint hint--top "
                    data-hint={__("Add card field")}
                    onClick={ onAddArray }
                />
                <Button
                    className=" hint hint--top "
                    large
                    data-hint={__(`${orientation} orientation`)}
                    onClick={ onCardOrientation }
                >
                    <Icon icon={<i className={`fas fa-grip-${orientation}`} />} />
                </Button>
            </div> 								
        </div>
        {/*  */}
    </div>
}

CardFieldsDnDEngine.propTypes = {}

export default CardFieldsDnDEngine