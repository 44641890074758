import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import React, { Component } from "react"
import { __ } from "react-pe-utilities"  

class MenuElementBtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: this.props.current,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.current !== this.state.current) this.setState({ current: nextProps.current })
  }

  render() {
    const e = this.props
    const {current} = this.state;
    const children = Array.isArray(this.props.children) && this.props.children.length > 0 && !this.props.isHideChildren
      ?				
      this.props.children.map((ee, ii) => (
        <MenuElementBtn
          {...ee}
          i={ii}
          key={ii}          
          route_prefix={this.props.route_prefix + " / " + this.getDistinationRoute()}
          level={this.props.level + 1}
          current={ current}
          onCurBtn={this.props.onCurBtn}
          onUp={(index) => this.onMenuElementUp(index)}
          onDown={(index) => this.onMenuElementDown(index)}
        />
      ))
      :			
      null
    const className = this.getDistinationRoute() ===  current ? " active " : ""
    return (
      <div className="d-flex flex-column">
        <div className="d-flex position-relative">
          <div
            onClick={this.onClick}
            className={`${className} menu-elem level_${this.props.level} flex-grow-100 pr-5`}
          >
            <div>
              {__(e.title)}
            </div>
            <div className="small opacity_75">
              {  this.props.route_prefix + " / " +  this.getDistinationRoute() }
            </div>
          </div>
          {
            this.props.level === 0
              ?
              <div className="position-absolute right h-100 ">
                <ButtonGroup vertical>
                  <Button 
                    icon="chevron-up" 
                    small 
                    intent={Intent.DANGER} 
                    className="h-100 bg-transparent box-shadow-none mb-auto" 
                    onClick={this.props.onUp}
                    disabled={this.props.i === 0}
                  />
                  <Button 
                    icon="chevron-down" 
                    small 
                    intent={Intent.DANGER} 
                    className="h-100 bg-transparent box-shadow-none" 
                    onClick={this.props.onDown}
                    disabled={this.props.i === this.props.length - 1}
                  />
                </ButtonGroup>
              </div>
              :
              null
          }
          
        </div>
       
        {children}
      </div>
    )
  }

	onClick = (evt) => {
    //console.log(this.props.route)
	  this.props.onCurBtn(this.getDistinationRoute(), this.props.level)
	}

  getDistinationRoute = () =>
	{
		if(this.props.route)
		{
		  return this.props.route;
		}
		if(this.props.target_id)
		{
		  return this.props.target_id
		}
		return "/"
	}
	onMenuElementUp = order =>
	{ 
    let items = [...this.state.items];
    const item = {...items[order]};
    items.splice(order, 1);
    items.splice(order - 1, 0, item); 
    this.setState({items});
    if(this.props.onItems) this.props.onItems(items);
	}
	 onMenuElementDown = order =>
	{
    let items = [...this.state.items];
    const item = {...items[order]};
    items.splice(order, 1);
    items.splice(order + 1, 0, item); 
    this.setState({items});
    if(this.props.onItems) this.props.onItems(items);
	}
}
export default MenuElementBtn
