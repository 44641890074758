import { Button, Intent } from "@blueprintjs/core"
import React, { Component } from "react"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"
import SourceForm from "./SourceForm"

export default class AddListForm extends Component
{
    onFilesParams = ( value, field, dopol, dopol1, media_data ) =>
    {
        if( this.props.onFilesParams )
        {
            this.props.onFilesParams( value, field, dopol, dopol1, media_data )
        }
    }
    onListDataUpdate = evt =>
    {
        if( this.props.onListDataUpdate )
        {
            this.props.onListDataUpdate( evt )
        }
            
    }
    render()
    {
        const {field, fileTitle, fileListSrc, isAutoLogo, template, form, isEmptyLogo} = this.props
        return <>
            <div className='px-4 pt-4'>                        
                <FieldInput
                    type="radio"
                    value={ fileListSrc ? fileListSrc : "file"}
                    values= {[
                        { _id: "file",      title: "Upload text file"},
                        { _id: "sheets",    title: "Google Sheets"},
                        { _id: "list",      title: "Write list"},
                    ]}
                    title={ __("Source of QR-codes data") }
                    onChange={ value => this.onFilesParams(
                        value, 
                        "fileListSrc" 
                    )}
                />  
                <SourceForm
                    { ...this.props } 
                /> 
                <FieldInput
                    type="boolean"
                    value={ isAutoLogo ? isAutoLogo : false }
                    title={ __("Autoload thumbnails") }
                    _id="isAutoLogo"
                    ID="isAutoLogo"
                    description={ __("Automatically set logo if text is SC-link") }
                    onChange={ value => this.onFilesParams( value, "isAutoLogo" ) }
                />
                {
                   isAutoLogo
                        ?
                        <>
                            <FieldInput
                                type="radio"
                                value={ template ? template : "black" }
                                title={ __("Logo style") }
                                values={[
                                    "black",
                                    "white",
                                    "color",
                                    "empty"
                                ]}
                                id="template"
                                onChange={(value ) => this.onFilesParams(
                                        value, 
                                        "template",
                                    )
                                }
                            />
                            <FieldInput
                                type="radio"
                                value={ form ? form : "squared" }
                                title={ __("Logo form") }
                                values={[
                                    "squared",
                                    "rounded",
                                ]}
                                id="form"
                                onChange={(value ) => this.onFilesParams(
                                        value, 
                                        "form", 
                                    )
                                }
                            />
                            <FieldInput
                                type="boolean"
                                value={ isEmptyLogo ? isEmptyLogo : false }
                                title={ __("Set empty if link no social") } 
                                _id="isEmptyLogo"
                                ID="isEmptyLogo"
                                onChange={(value, dopol, dopol1, dopol2) => this.onFilesParams(
                                        value, 
                                        "isEmptyLogo" 
                                    )
                                }
                            />
                        </>
                        :
                        null
                }
            </div>
            <div className="bp3-dialog-footer p-4 m-0">
                <Button intent={Intent.SUCCESS} onClick={this.onListDataUpdate}>
                    {__("Add list")}
                </Button>
            </div>
        </>
    }
}