import { Button, Popover, Position } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"

import { __ } from "react-pe-utilities"
import ProjectFieldSelector from "../views/SingleSite/cabinet/single/forms/ProjectFieldSelector"
import SubMenu from "./YandexFormsFields/Submenu"

const YandexFormsFieldsWidget = props =>
{
    let checkTimeout = "";
    const { field, title, description, commentary } = props
    const[value, setValue] = useState(Array.isArray(props.value) ? props.value : [])
    const[form_fields, setForm_fields] = useState(props.form_fields)
    const[isChecked, setChecked] = useState( false )
    useEffect(() =>
    {
        setForm_fields(props.form_fields)
    }, [props.form_fields])
    const col1 = title || description
        ? 
        props.vertical
            ? 
            "col-12 layout-label-vert"
            : 
            "col-md-3  layout-label"
        : 
        " hidden "
    const col2 = title || description
        ? 
        props.vertical
            ? 
            "col-12 layout-data-vert"
            : 
            "col-md-9 layout-data"
        : 
        " col-12 layout-data-vert "
    const isDesabled = () =>
    {
        return "DISABLED"
    }
    const onSubMenu = ( val, field ) => 
    {
        if (!props.onSubMenu) return 
        props.onSubMenu(val, field)
    }
    const onChangeField = (evt, i) =>
    {
        const val = evt.currentTarget.value
        const values = [...value]
        values[i] = val
        setChecked(true)
        checkTimeout = setTimeout( () => setChecked(false), 4000 )
        setValue( values )
        onUpdate( values )
    }
    const onClearField = i =>
    { 
        const values = [...value]
        values[i] = ""
        setChecked(false)
        setValue( values )
        onUpdate( values )
    }
    const onUpdate = (value) =>
    {
        if(props.on)
            props.on(value)

    }
    const getInputForm = ( i ) =>
    {
        switch( form_fields.type )
        {
            case "number":
                return <div key={i} className="d-flex align-items-center w-100">
                    <i className="fas fa-hand-peace" />
                    <input
                        key={i}
                        type="text"
                        value={ value[i] ? value[i] : "" }
                        onChange={ evt => onChangeField( evt, i ) }
                        className="form-control input dark flex-grow-100"
                    />
                    <ProjectFieldSelector currentField={""} />
                </div>
            case "url":
                return <div key={i} className="d-flex align-items-center w-100">
                    <i className="fas fa-desktop" />
                    <input 
                        type="text"
                        value={ value[i] ? value[i] : "" }
                        onChange={ evt => onChangeField( evt, i ) }
                        className="form-control input dark flex-grow-100"
                    />
                    <ProjectFieldSelector currentField={""} />
                </div>
            case "string":
            default:
                return <div key={i} className="d-flex align-items-center w-100">
                <i className="fas fa-pen-nib" />
                <input 
                    type="text"
                    value={ value[i] ? value[i] : "" }
                    onChange={ evt => onChangeField( evt, i ) }
                    className="form-control input dark flex-grow-100"
                />
                <ProjectFieldSelector currentField={""} />
            </div>

        }
            
    }
    const checked = isChecked ? <i className="fas fa-check text-success mx-2" /> : null 
    const onChangeSubMenu = (value, field) =>
    {
        props.onSubmenu(value, field)
    }
    const isEnabled = () =>
    {
        const fields = Array(form_fields.count || 0).fill(1).map((field, i) =>
        {
            return <div className="d-flex align-items-center " key={i}>
                {getInputForm( i )}  
                {checked}
                <Button
                    className=" "
                    icon="cross"
                    minimal
                    onClick={ () => onClearField(i)}
                />   
            </div>
        })
        return <div className="w-100">
            {fields}
            <SubMenu 
                onChange={ (val, fld) => onChangeSubMenu( val, fld ) }
                form_fields={form_fields}
                original={props.original} 
            />           
        </div>
    }
    const descr = description
        ?
        <Popover
            position={Position.RIGHT}
            interactionKind="hover"
            content={<div className="p-4 large-square" dangerouslySetInnerHTML={{ __html: __(description) }} />}
        >
            <Button minimal icon="help" />
        </Popover>
        : 
        null
    const o = props.type === "yandexFormsFields"
        ? 
        <>
            <div className="row dat strob01" row_data={field}>
                <div className={col1}>
                    {__(props.title)}
                    {descr}
                </div>
                <div className={col2} style={{ position: "relative" }}>                   
                    <div className="d-flex flex-grow-100">
                        {
                            props.prefix ? <span className="prefix">{ props.prefix }</span> : null
                        }
                        {
                            props.editable ? isEnabled() : isDesabled()
                        }
                        {
                            props.postfix ? <span className="postfix">{ props.postfix }</span> : null
                        }
                    </div> 
                </div>
                {
                    commentary
                        ?
                        <div className="scalar-commentary px-3 col-md-9 offset-md-3" dangerouslySetInnerHTML={{ __html: __(commentary) }} />
                        :
                        null
                }
            </div>
        </>
        : 
        null
    return <div>
        {o}
    </div>
}
export default YandexFormsFieldsWidget