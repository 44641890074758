import React from "react" 
import Style from "style-it"
import SectionContent from "../LandingState/SectionContent"
import "./assets/css/style.css"
import png from "./assets/img/logo.png"
import png2 from "./assets/img/logo2.png"
import { getFontNameByID } from "../LandingState/data/PalettePresets"
import { Link } from "react-router-dom"
import { getColor } from "../LandingState/data/getColor"

class KraevedTitle extends SectionContent {
    is() {
        return this.props.data.number
    }
    getState() {
        return {
            isMobyle: this.props.section_width < 720
        }
    }
    componentDidMount() {
        this.setState({ isMobyle: document.body.clientWidth < 720 })
    }
    updateWidth(width) {
        if ((width < 720) !== this.state.isMobyle)
            this.setState({ isMobyle: width < 720 })
    }
    renderContent(style) {
        return this.state.isMobyle
            ?
            this.renderMobyle(style)
            :
            this.renderScreen(style)
    }
    renderScreen(style) {
        const { palette } = this.props
        const { year, number, color1, color2, color3, title, description, image, class_name } = this.props.data
        const clr1 = getColor(color1, palette)
        const clr2 = getColor(color2, palette)
        const clr3 = getColor(color3, palette)
        return (
            <div
                className={`landing-kraeved-title ${class_name}`}
                style={{
                    color: palette.main_text_color,
                    ...style
                }}
            >
                <div
                    className="landing-kraeved-img"
                    style={{
                        backgroundImage: `url(${image})`,
                        top: 25,
                        left: 865,
                        height: 180,
                        width: "calc(100% - 856px)",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                />
                <svg
                    version="1.1"
                    id="Слой_1"
                    x="0px"
                    y="0px"
                    width="2860"
                    height="250"
                    viewBox="0 0 1860 250"
                    style={{ position: "absolute", zIndex: 2 }}
                >
                    <g id="bg">
                        <image width="5" height="250" xlinkHref={png2} transform="matrix(500 0 0 1 30 0)">
                        </image>
                    </g>
                </svg>

                <svg
                    version="1.1"
                    id="Слой_1"
                    x="0px"
                    y="0px"
                    width="1860"
                    height="250"
                    viewBox="0 0 1860 250"
                    style={{ position: "absolute", zIndex: 3 }}
                >
                    <g id="bg">
                        <image width="5" height="250" xlinkHref={png2} transform="matrix(150 0 0 1 930 0)">
                        </image>
                    </g>
                    <g id="color4">
                        <image width="930" height="250" xlinkHref={png} transform="matrix(1 0 0 1 0 -1)">
                        </image>
                    </g>
                    <g id="color3">
                        <path fill={clr3} className="st0" d="M720.8,39.7l-36.3,21c0,0-1.6,5,0.6,21.6c2.3,17.6,3.4,19.9,4.5,25.6c1.3,6.3,3.4,13.7,3.4,14C719.8,88.5,721.6,94.2,720.8,39.7z" />
                        <path fill={clr3} className="st0" d="M121,158.1l-11.3,1.5l-4.8-36.3l24.3-3.2l0.6,4.5l-1.2,4.8l-11.2,1.5L121,158.1z" />
                        <path fill={clr3} className="st0" d="M141.1,155.5c-2.7,0.3-4.8-1.3-5.2-3.9l-3.5-27.3c-0.3-2.7,1.3-4.8,4-5.1l18.1-2.4c2.7-0.3,4.8,1.3,5.2,3.9
	                        l3.5,27.3c0.3,2.6-1.3,4.8-4,5.1L141.1,155.5z M144.3,127.4l2.4,18.1l4.5-0.6l-2.4-18.1L144.3,127.4z"/>
                        <path fill={clr3} className="st0" d="M167.9,152l-4.8-36.3l22.7-3c2.7-0.3,4.8,1.3,5.2,3.9l1.1,8.4c0.3,2.7-1.6,4.6-4.1,4.9l0.1,1
				            c2.5-0.3,4.9,1.1,5.2,3.8l1.2,9.2c0.3,2.6-1.3,4.8-4,5.1L167.9,152z M180.2,122.7l-4.5,0.6l0.5,4.1l4.5-0.6L180.2,122.7z
				            M178,141.4l4.5-0.6l-0.6-5l-4.5,0.6L178,141.4z"/>
                        <path fill={clr3} className="st0" d="M203.3,147.3c-2.7,0.3-4.8-1.3-5.2-3.9l-3.5-27.3c-0.3-2.7,1.3-4.8,4-5.1l18.1-2.4c2.7-0.3,4.8,1.3,5.2,3.9
				            l3.5,27.3c0.3,2.6-1.3,4.8-4,5.1L203.3,147.3z M206.5,119.3l2.4,18.1l4.5-0.6l-2.4-18.1L206.5,119.3z"/>
                        <path fill={clr3} className="st0" d="M239.9,131l1.5,11.3l-11.3,1.5l-4.8-36.3l22.7-3c2.7-0.3,4.8,1.3,5.2,3.9l2.1,15.9c0.3,2.7-1.3,4.8-4,5.2
				            L239.9,131z M238.8,122l4.5-0.6l-0.9-6.8l-4.5,0.6L238.8,122z"/>
                        <path fill={clr3} className="st0" d="M273.3,114.7l-1.1-8.6c-0.3-2.6,1.3-4.8,3.9-5.2l17.7-2.3c2.7-0.3,4.8,1.3,5.2,4l3.5,27.2
				            c0.3,2.7-1.3,4.8-4,5.2l-17.6,2.3c-2.7,0.3-4.8-1.3-5.2-4l-1.2-9.5l-4.1,0.5l1.9,14.1l-11.3,1.5l-4.8-36.3l11.3-1.5l1.7,13.2
				            L273.3,114.7z M284.1,109.1l2.4,18.1l4.1-0.5l-2.4-18.1L284.1,109.1z"/>
                        <path fill={clr3} className="st0" d="M381.6,87l9.2,31.1l0.6,4.5l-11.3,1.5l-1.6-7.2l-5,0.6l0.2,7.4l-11.3,1.5l-0.6-4.5l0.9-32.4L381.6,87z
				            M376.5,108L374,97.4l-1.2,0.2l0.3,10.9L376.5,108z"/>
                        <path fill={clr3} className="st0" d="M406,83.9l10.8-1.4l0.6,4.5l-2.6,13.1l7,14.1l0.6,4.5l-11.3,1.5l-4.9-13.7l-2,0.3l1.9,14.1l-11.3,1.5
				            l-4.8-36.3l11.3-1.5l1.7,13.2l2-0.3L406,83.9z"/>
                        <g>
                            <path fill={clr3} className="st0" d="M711,192.2c-9.9-15.8-19.4-33.7-26.9-53.9l-419.3,53.9H711z" />
                            <path fill={clr3} className="st0" d="M795.4,192.2h67l-9.7-75.6l-24.4,3.1C820.3,147.8,808.2,171.8,795.4,192.2z" />
                        </g>
                    </g>
                    <g id="color2">
                        <path fill={clr2} className="st_0" d="M786,39.7l36.3,21c0,0,1.6,5-0.6,21.6c-2.3,17.6-3.4,19.9-4.5,25.6c-1.3,6.3-3.4,13.7-3.4,14
				            C787,88.5,785.2,94.2,786,39.7z"/>
                        <polygon fill={clr2} className="st_0" points="336.3,39.8 298.6,39.8 303.4,76.6 340.4,71.8 		" />
                        <rect fill={clr2} className="st_0" x="308.1" y="88.7" transform="matrix(0.9918 -0.1276 0.1276 0.9918 -11.5229 43.5076)" width="51.5" height="45.9" />
                        <polygon fill={clr2} className="st_0" points="0,177.7 99.7,164.9 93.8,119.4 0,131.5 		" />
                    </g>
                    <g id="color1">
                        <path fill={clr1} className="st1" d="M848.3,83.2l-12.9,1.7c-0.9,6.8-2,13.4-3.4,19.8l18.8-2.4L848.3,83.2z" />
                        <path fill={clr1} className="st1" d="M225.8,39.8h-12.5c-3.8,10.4-13.7,17.8-25.4,17.8s-21.7-7.4-25.4-17.8h-11.8c-3.8,10.3-13.7,17.6-25.3,17.6
				            s-21.5-7.3-25.3-17.6H89.3c-3.8,10.4-13.7,17.8-25.4,17.8s-21.7-7.4-25.4-17.8H0v75.7L253.7,83l-3.3-25.3
				            C239.1,57.4,229.5,50.1,225.8,39.8z"/>
                        <path fill={clr1} className="st1" d="M674.7,105.5L0,192.3v19.4l679.5-87.4C677.7,118.2,676.1,112,674.7,105.5z" />
                    </g>

                    <g id="color5">
                        <path className="st2" d="M344,92l10.8-1.4l0.6,4.5l-2.6,13.1l7,14.1l0.6,4.5l-11.3,1.5l-4.9-13.7l-2,0.3L344,129l-11.3,1.5l-4.8-36.3
				            l11.3-1.5l1.7,13.2l2-0.3L344,92z"/>
                        <path className="st3" d="M112.5,192.4l-1.4-10.8l8.1-1l1.4,10.8l-3.4,0.4l-1-8.1l-1.3,0.2l1,8.1L112.5,192.4z" />
                        <path className="st2" d="M126.4,187.2l0.4,3.4l-3.4,0.4l-1.4-10.8l6.7-0.9c0.8-0.1,1.5,0.4,1.5,1.2l0.6,4.8c0.1,0.8-0.4,1.5-1.2,1.5
				            L126.4,187.2z M126.1,184.5l1.3-0.2l-0.2-2l-1.3,0.2L126.1,184.5z"/>
                        <path className="st2" d="M135.6,189.5c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1c-0.1-0.8,0.4-1.4,1.2-1.5l5.4-0.7c0.8-0.1,1.5,0.4,1.5,1.2
				            l1,8.1c0.1,0.8-0.4,1.4-1.2,1.5L135.6,189.5z M136.6,181.1l0.7,5.4l1.3-0.2l-0.7-5.4L136.6,181.1z"/>
                        <path className="st2" d="M145.1,188.2l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4l0.2,1.2l3.3-0.4l0.3,2.7l-3.3,0.4l0.2,1.5l3.9-0.5
				            l0.3,2.7L145.1,188.2z"/>
                        <path className="st2" d="M158.3,175.6l3.2-0.4l0.2,1.3l-0.8,3.9l2.1,4.2l0.2,1.3l-3.4,0.4l-1.5-4.1l-0.6,0.1l0.5,4.2l-3.4,0.4
				            l-1.5-10.8l3.4-0.4l0.5,4l0.6-0.1L158.3,175.6z"/>
                        <path className="st2" d="M164.1,177.6l-0.3-2.7l7.6-1l0.2,1.3l-0.3,1.5l-1.6,0.2l1,8.1l-3.4,0.4l-1-8.1L164.1,177.6z" />
                        <path className="st2" d="M178.9,176.9l-0.5-4l3.4-0.4l1.4,10.8l-3.4,0.4l-0.5-4.2l-1.3,0.2l0.5,4.2l-3.4,0.4l-1.4-10.8l3.4-0.4l0.5,4
				            L178.9,176.9z"/>
                        <path className="st2" d="M187.3,182.8c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1c-0.1-0.8,0.4-1.4,1.2-1.5l5.4-0.7c0.8-0.1,1.5,0.4,1.5,1.2
				            l1,8.1c0.1,0.8-0.4,1.4-1.2,1.5L187.3,182.8z M188.3,174.5l0.7,5.4l1.3-0.2l-0.7-5.4L188.3,174.5z"/>
                        <path className="st2" d="M203.4,174.2l0.3,2.2l-7.7,1l-0.3-2.2L203.4,174.2z" />
                        <path className="st2" d="M210,168.9l3.4-0.4l1.4,10.8l-3.4,0.4l-0.2-1.3l0.1-3.5l-0.3,0.1l-1.1,4l0.1,1.1l-3.4,0.4l-1.4-10.8l3.4-0.4
				            l0.2,1.3l-0.1,3.5l0.3-0.1l1.1-4L210,168.9z"/>
                        <path className="st2" d="M223.4,167.2l0.2,1.3l-0.3,1.5l-3.3,0.4l0.7,5.4l3.8-0.5l0.3,2.7l-5.9,0.8c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1
				            c-0.1-0.8,0.4-1.4,1.2-1.5L223.4,167.2z"/>
                        <path className="st2" d="M233.1,166l0.2,1.3l-0.3,1.5l-3.3,0.4l0.7,5.4l3.8-0.5l0.3,2.7l-5.9,0.8c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1
				            c-0.1-0.8,0.4-1.4,1.2-1.5L233.1,166z"/>
                        <path className="st2" d="M241.7,175.9l-1-8.1l-0.5,0.1l-0.2,8.3l-3.4,0.5l-0.2-1.3l0.8-9.8l6.5-0.8l1.4,10.8L241.7,175.9z" />
                        <path className="st2" d="M247.8,175.1l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4l0.2,1.2l3.3-0.4l0.3,2.7l-3.3,0.4l0.2,1.5l3.9-0.5
				            l0.3,2.7L247.8,175.1z"/>
                        <path className="st2" d="M256.7,171.2l1-0.1l0.6-8.3l6.5-0.8l1,8.1l0.9-0.1l0.3,2.7l-10.1,1.3L256.7,171.2z M261.9,165l-0.5,0.1
				            l-0.1,5.5l1.3-0.2L261.9,165z"/>
                        <path className="st2" d="M270.9,172.1c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1c-0.1-0.8,0.4-1.4,1.2-1.5l5.4-0.7c0.8-0.1,1.5,0.4,1.5,1.2
				            l1,8.1c0.1,0.8-0.4,1.4-1.2,1.5L270.9,172.1z M271.9,163.8l0.7,5.4l1.3-0.2l-0.7-5.4L271.9,163.8z"/>
                        <path className="st2" d="M280.4,170.9l-1.4-10.8l6.7-0.9c0.8-0.1,1.5,0.4,1.5,1.2l0.3,2.5c0.1,0.8-0.5,1.4-1.2,1.5l0.1,0.3
				            c0.8-0.1,1.5,0.3,1.6,1.2l0.3,2.7c0.1,0.8-0.4,1.4-1.2,1.5L280.4,170.9z M284.1,162.2l-1.3,0.2l0.2,1.2l1.3-0.2L284.1,162.2z
				            M283.4,167.8l1.3-0.2l-0.2-1.5l-1.3,0.2L283.4,167.8z"/>
                        <path className="st2" d="M296.6,157.8l2.7,9.3l0.2,1.3l-3.4,0.4l-0.5-2.2l-1.5,0.2l0.1,2.2l-3.4,0.4l-0.2-1.3l0.3-9.7L296.6,157.8z
				            M295,164.1l-0.7-3.2l-0.3,0.1l0.1,3.3L295,164.1z"/>
                        <path className="st2" d="M299.9,160.2l-0.3-2.7l7.6-1l0.2,1.3l-0.3,1.5l-1.6,0.2l1,8.1l-3.4,0.4l-1-8.1L299.9,160.2z" />
                        <path className="st2" d="M310.8,167l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4l0.2,1.2l3.3-0.4l0.3,2.7l-3.3,0.4l0.2,1.5l3.9-0.5
				            l0.3,2.7L310.8,167z"/>
                        <path className="st2" d="M325.2,165.2l-1-8.1l-0.5,0.1l-0.2,8.3l-3.4,0.5l-0.2-1.3l0.8-9.8l6.5-0.8l1.4,10.8L325.2,165.2z" />
                        <path className="st2" d="M338.8,157.3l0.6,4.7c0.1,0.8-0.4,1.5-1.2,1.5l-6.8,0.9l-1.4-10.8l3.4-0.4l0.4,3.4l3.4-0.4
				            C338,156,338.6,156.4,338.8,157.3z M335.8,161l-0.2-2l-1.3,0.2l0.2,2L335.8,161z"/>
                        <path className="st2" d="M348,151.2l0.2,1.3l-0.3,1.5l-3.3,0.4l0.7,5.4l3.8-0.5l0.3,2.7l-5.9,0.8c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1
				            c-0.1-0.8,0.4-1.4,1.2-1.5L348,151.2z"/>
                        <path className="st2" d="M355.3,150.3l3.2-0.4l0.2,1.3l-0.8,3.9l2.1,4.2l0.2,1.3l-3.4,0.4l-1.5-4.1l-0.6,0.1l0.5,4.2l-3.4,0.4
				            l-1.4-10.8l3.4-0.4l0.5,4l0.6-0.1L355.3,150.3z"/>
                        <path className="st2" d="M368,148.7l2.7,9.3l0.2,1.3l-3.4,0.4l-0.5-2.2l-1.5,0.2l0.1,2.2l-3.4,0.4l-0.2-1.3l0.3-9.7L368,148.7z
				            M366.5,154.9l-0.7-3.2l-0.3,0.1l0.1,3.3L366.5,154.9z"/>
                        <path className="st2" d="M381.4,158l-3.4,0.4l-0.4-3.4l-0.7,0.1l-0.5,3.5l-3.3,0.4l-0.2-1.3l0.7-2.8c-0.5-0.1-1-0.6-1-1.1l-0.5-4.2
				            c-0.1-0.8,0.4-1.5,1.2-1.5l6.7-0.9L381.4,158z M375.9,152.5l1.3-0.2l-0.2-2l-1.3,0.2L375.9,152.5z"/>
                        <path className="st4" d="M393.3,156.4l-1.4-10.8l8.1-1l1.4,10.8l-3.4,0.4l-1-8.1l-1.3,0.2l1,8.1L393.3,156.4z" />
                        <path className="st2" d="M404.3,155.1l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4l0.2,1.2l3.3-0.4l0.3,2.7l-3.3,0.4l0.2,1.5l3.9-0.5
				            l0.3,2.7L404.3,155.1z"/>
                        <path className="st2" d="M413.2,151.2l1-0.1l0.6-8.3l6.5-0.8l1,8.1l0.9-0.1l0.3,2.7l-10.1,1.3L413.2,151.2z M418.2,145l-0.5,0.1
				            l-0.1,5.5l1.3-0.2L418.2,145z"/>
                        <path className="st2" d="M431.3,140.6l2.7,9.3l0.2,1.3l-3.4,0.4l-0.5-2.2l-1.5,0.2l0.1,2.2l-3.4,0.4l-0.2-1.3l0.3-9.7L431.3,140.6z
				            M429.8,146.8l-0.7-3.2l-0.3,0.1l0.1,3.3L429.8,146.8z"/>
                        <path className="st2" d="M440.1,150.5l-3.4,0.4l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4L440.1,150.5z" />
                        <path className="st2" d="M447.6,149.5c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1c-0.1-0.8,0.4-1.4,1.2-1.5l5.4-0.7c0.8-0.1,1.5,0.4,1.5,1.2
				            l1,8.1c0.1,0.8-0.4,1.4-1.2,1.5L447.6,149.5z M448.6,141.1l0.7,5.4l1.3-0.2l-0.7-5.4L448.6,141.1z"/>
                        <path className="st2" d="M460.6,147.8l-3.4,0.4l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4L460.6,147.8z" />
                        <path className="st2" d="M470.1,135.6l3.4-0.4l1.4,10.8l-3.4,0.4l-0.2-1.3l0.1-3.5l-0.3,0.1l-1.1,4l0.1,1.1l-3.4,0.4l-1.4-10.8
				            l3.4-0.4l0.2,1.3l-0.1,3.5l0.3-0.1l1.1-4L470.1,135.6z"/>
                        <path className="st2" d="M476.9,140.6l-0.8-5.8l3.4-0.4l0.6,4.5l1.3-0.2l-0.6-4.5l3.4-0.4l1.4,10.8l-3.4,0.4l-0.5-3.7l-3.4,0.4
				            C477.6,141.9,477,141.4,476.9,140.6z"/>
                        <path className="st2" d="M488.4,144.2l-1.4-10.8l7.3-0.9l0.2,1.3l-0.3,1.5l-3.3,0.4l0.2,1.2l3.3-0.4l0.3,2.7l-3.3,0.4l0.2,1.5l3.9-0.5
				            l0.3,2.7L488.4,144.2z"/>
                        <path className="st2" d="M503.9,131.2l0.2,1.3l-0.3,1.5l-3.3,0.4l0.7,5.4l3.8-0.5l0.3,2.7l-5.9,0.8c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1
				            c-0.1-0.8,0.4-1.4,1.2-1.5L503.9,131.2z"/>
                        <path className="st2" d="M511.3,130.3l3.2-0.4l0.2,1.3l-0.8,3.9l2.1,4.2l0.2,1.3l-3.4,0.4l-1.5-4.1l-0.6,0.1l0.5,4.2l-3.4,0.4
				            l-1.4-10.8l3.4-0.4l0.5,4l0.6-0.1L511.3,130.3z"/>
                        <path className="st2" d="M524.1,128.7l2.7,9.3l0.2,1.3l-3.4,0.4l-0.5-2.2l-1.5,0.2l0.1,2.2l-3.4,0.4l-0.2-1.3l0.3-9.7L524.1,128.7z
				            M522.5,134.9l-0.7-3.2l-0.3,0.1l0.1,3.3L522.5,134.9z"/>
                        <path className="st2" d="M537.4,138l-3.4,0.4l-0.4-3.4l-0.7,0.1l-0.5,3.5l-3.3,0.4l-0.2-1.3l0.7-2.8c-0.5-0.1-1-0.6-1-1.1l-0.5-4.2
				            c-0.1-0.8,0.4-1.5,1.2-1.5l6.7-0.9L537.4,138z M531.9,132.5l1.3-0.2l-0.2-2l-1.3,0.2L531.9,132.5z"/>
                        <path className="st4" d="M554,135.9l-1-8.1l-0.5,0.1l-0.2,8.3l-3.4,0.5l-0.2-1.3l0.8-9.8l6.5-0.8l1.4,10.8L554,135.9z" />
                        <path className="st2" d="M565.6,123.4l2.7,9.3l0.2,1.3l-3.4,0.4l-0.5-2.2l-1.5,0.2l0.1,2.2l-3.4,0.4l-0.2-1.3l0.3-9.7L565.6,123.4z
				            M564,129.6l-0.7-3.2l-0.3,0.1l0.1,3.3L564,129.6z"/>
                        <path className="st2" d="M578.4,127.1l0.5,4.2c0.1,0.8-0.4,1.5-1.2,1.5l-6.7,0.9l-1.4-10.8l8-1l0.2,1.3l-0.3,1.5l-4.1,0.5l0.2,1.2
				            l3.4-0.4C577.6,125.8,578.3,126.3,578.4,127.1z M575.4,130.3l-0.2-1.5l-1.3,0.2l0.2,1.5L575.4,130.3z"/>
                        <path className="st2" d="M583.1,132.1c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1c-0.1-0.8,0.4-1.4,1.2-1.5l5.4-0.7c0.8-0.1,1.5,0.4,1.5,1.2
				            l1,8.1c0.1,0.8-0.4,1.4-1.2,1.5L583.1,132.1z M584.1,123.7l0.7,5.4l1.3-0.2l-0.7-5.4L584.1,123.7z"/>
                        <path className="st2" d="M595.6,127.1l0.4,3.4l-3.4,0.4l-1.4-10.8l6.7-0.9c0.8-0.1,1.5,0.4,1.5,1.2l0.6,4.8c0.1,0.8-0.4,1.5-1.2,1.5
				            L595.6,127.1z M595.3,124.4l1.3-0.2l-0.2-2l-1.3,0.2L595.3,124.4z"/>
                        <path className="st2" d="M608.4,117.9l2.7,9.3l0.2,1.3l-3.4,0.4l-0.5-2.2l-1.5,0.2l0.1,2.2l-3.4,0.4l-0.2-1.3l0.3-9.7L608.4,117.9z
				            M606.8,124.1l-0.7-3.2l-0.3,0.1l0.1,3.3L606.8,124.1z"/>
                        <path className="st2" d="M611.6,120.2l-0.3-2.7l7.6-1l0.2,1.3l-0.3,1.5l-1.6,0.2l1,8.1l-3.4,0.4l-1-8.1L611.6,120.2z" />
                        <path className="st2" d="M624,126.9c-0.8,0.1-1.5-0.4-1.5-1.2l-1-8.1c-0.1-0.8,0.4-1.4,1.2-1.5l5.4-0.7c0.8-0.1,1.5,0.4,1.5,1.2l1,8.1
				            c0.1,0.8-0.4,1.4-1.2,1.5L624,126.9z M625,118.5l0.7,5.4l1.3-0.2l-0.7-5.4L625,118.5z"/>
                        <path className="st2" d="M636.5,121.9l0.4,3.4l-3.4,0.4l-1.4-10.8l6.7-0.9c0.8-0.1,1.5,0.4,1.5,1.2l0.6,4.8c0.1,0.8-0.4,1.5-1.2,1.5
				            L636.5,121.9z M636.1,119.1l1.3-0.2l-0.2-2l-1.3,0.2L636.1,119.1z"/>
                        <path className="st2" d="M647.6,112.9l3.4-0.4l1.4,10.8l-3.4,0.4l-0.2-1.3l0.1-3.5l-0.3,0.1l-1.1,4l0.1,1.1l-3.4,0.4l-1.4-10.8
				            l3.4-0.4l0.2,1.3l-0.1,3.5l0.3-0.1l1.1-4L647.6,112.9z"/>
                        <path className="st2" d="M663.1,121.9l-3.4,0.4l-0.4-3.4l-0.7,0.1l-0.5,3.5l-3.3,0.4l-0.2-1.3l0.7-2.8c-0.5-0.1-1-0.6-1-1.1l-0.5-4.2
				            c-0.1-0.8,0.4-1.5,1.2-1.5l6.7-0.9L663.1,121.9z M657.6,116.3l1.3-0.2l-0.2-2l-1.3,0.2L657.6,116.3z"/>
                        <text transform="matrix(1 0 0 1 519.4482 182.3994)" className="st2 st6 st7">топос-краеведение</text>
                    </g>
                </svg>
                <div>
                    {
                        Style.it(
                            `
                            .landing-kraeved-title-title
                            {
                               color:${palette.main_text_color};
                            }
                            `,
                            <div className="landing-kraeved-title-title">
                                <span dangerouslySetInnerHTML={{ __html: title }} />
                            </div>
                        )
                    }
                    {
                        Style.it(
                            `
                            .landing-kraeved-title-descr
                            {
                               color:${palette.main_text_color};
                            }
                            `,
                            <div className="landing-kraeved-title-descr">
                                <span dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        )
                    }
                </div>
                <div>
                    {
                        Style.it(
                            `
                            .landing-kraeved-title-number
                            {
                               color:${clr1};
                               font-family:${getFontNameByID(palette.h1.fontFamilyID)};
                            }
                            `,
                            <div className="landing-kraeved-title-number">
                                <span dangerouslySetInnerHTML={{ __html: number }} />
                            </div>
                        )
                    }
                    {
                        Style.it(
                            `
                            .landing-kraeved-title-year
                            { 
                               color:${clr3};
                            }
                            `,
                            <div className="landing-kraeved-title-year">
                                <span dangerouslySetInnerHTML={{ __html: year }} />
                            </div>
                        )
                    }
                </div>
                <div>
                    {
                        this.props.data.button_label && this.props.data.button_route
                            ?
                            Style.it(
                                `
                            .kraeved-button 
                            {
                                position: absolute;
                                bottom: 25px;
                                right: 10px;
                                z-index: 10; 
                            }
                            .kraeved-button > a
                            { 
                                padding: 4px 20px;
                                color: #FFFFFF!important;
                                opacity:.75;
                            }
                            .kraeved-button > a:hover
                            {  
                                opacity:1;
                            }
                            `,
                                <div className="kraeved-button">
                                    <Link
                                        to={this.props.data.button_route}
                                    >
                                        {this.props.data.button_label}
                                    </Link>
                                </div>
                            )
                            :
                            null
                    }
                </div>
            </div>
        )
    }
    renderMobyle(style) {

        const { palette } = this.props
        const { year, number, color1, color2, color3, title, description } = this.props.data
        const clr1 = getColor(color1, palette)
        const clr2 = getColor(color2, palette)
        const clr3 = getColor(color3, palette)
        return <div>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 2000 600"
                style={{ marginTop: 0 }}
            >
                <g id="light">
                    <rect y="6.6" fill="#FFF" width="1991.9" height="593.4" />
                </g>
                <g id="color2">
                    <rect y="424.1" fill={clr1} width="1060.8" height="175.9" />
                    <path fill={clr1} d="M805.3,63.3h37.3V79L828,122.6l17.6,51.1v15.7h-39.3l-10.6-48.8h-7v48.8h-39.4V63.3h39.4v45.7h7L805.3,63.3z"
                    />
                </g>
                <g id="color1">
                    <path fill={clr2} d="M73.6,189.4H34.2V63.3h84.3V79l-6.1,15.8H73.6V189.4z" />
                    <path fill={clr2} d="M143.3,189.4c-9.4,0-15.8-6.5-15.8-15.7V79c0-9.2,6.5-15.7,15.8-15.7h62.8c9.4,0,15.8,6.5,15.8,15.7v94.7
		c0,9.2-6.5,15.7-15.8,15.7H143.3z M166.9,95v62.8h15.7V95H166.9z"/>
                    <path fill={clr2} d="M236.4,189.4V63.3h78.7c9.4,0,15.8,6.5,15.8,15.7v29c0,9.2-7.6,15.1-16.2,15.1v3.4c8.6,0,16.2,5.9,16.2,15.3
		v31.9c0,9.2-6.5,15.7-15.8,15.7H236.4z M291.5,95h-15.7v14h15.7V95z M275.9,157.9h15.7v-17.3h-15.7V157.9z"/>
                    <path fill={clr2} d="M359.4,189.4c-9.4,0-15.8-6.5-15.8-15.7V79c0-9.2,6.5-15.7,15.8-15.7h62.8c9.4,0,15.8,6.5,15.8,15.7v94.7
		c0,9.2-6.5,15.7-15.8,15.7H359.4z M383,95v62.8h15.7V95H383z"/>
                    <path fill={clr2} d="M492,150.1v39.3h-39.4V63.3h78.7c9.4,0,15.8,6.5,15.8,15.7v55.3c0,9.2-6.5,15.8-15.8,15.8H492z M492,118.6
		h15.7V95H492V118.6z"/>
                    <path fill={clr2} d="M613.2,109.1V79.2c0-9.2,6.5-15.8,15.7-15.8h61.4c9.4,0,15.8,6.7,15.8,15.8v94.4c0,9.2-6.5,15.8-15.8,15.8H629
		c-9.4,0-15.8-6.7-15.8-15.8v-33h-14.2v48.8h-39.3V63.3h39.3v45.7H613.2z M652.6,94.8v63h14v-63H652.6z"/>
                    <path fill={clr2} d="M936,63.3l17.6,110.4v15.7h-39.4l-2.3-25.4h-17.3l-2.5,25.4h-39.3v-15.7l17.6-110.4H936z M909,132.5L905.4,95
		h-4.3l-3.6,37.5H909z"/>
                    <path fill={clr2} d="M1020.5,63.3h37.3V79l-14.6,43.6l17.6,51.1v15.7h-39.3l-10.6-48.8h-7v48.8h-39.4V63.3h39.4v45.7h7L1020.5,63.3
		z"/>
                </g>
                <g id="dark">
                    <g>
                        <rect fill="#4B485A" width="1059.5" height="50.8" />
                    </g>
                    <g>
                        <rect y="409.3" fill="#4B485A" width="1060.8" height="50.8" />
                    </g>
                    <path fill="#4B485A" d="M124.6,213.9h52v21.9l-20.4,60.8l24.6,71.4v21.9h-54.8l-14.8-68.1h-9.8v68.1h-55V213.9h55v63.8h9.8
		L124.6,213.9z"/>
                    <path fill="#4B485A" d="M251,335.1v54.8h-55V213.9h109.8c13.1,0,22.1,9,22.1,21.9V313c0,12.8-9,22.1-22.1,22.1H251z M251,291.1h21.9
		v-32.9H251V291.1z"/>
                    <path fill="#4B485A" d="M449,213.9L473.7,368v21.9h-55l-3.3-35.4h-24.1l-3.5,35.4h-54.8V368l24.6-154.1H449z M411.3,310.5l-5-52.3h-6
		l-5,52.3H411.3z"/>
                    <path fill="#4B485A" d="M488.8,389.9V213.9h117.6v21.9l-8.8,22.4h-54v19.6h54v44h-54v24.1h62.8v44H488.8z" />
                    <path fill="#4B485A" d="M621.5,389.9V213.9h109.8c13.1,0,22.1,9,22.1,21.9v40.5c0,12.8-10.6,21.1-22.6,21.1v4.8
		c12.1,0,22.6,8.3,22.6,21.4V368c0,12.8-9,21.9-22.1,21.9H621.5z M698.4,258.2h-21.9v19.6h21.9V258.2z M676.5,345.9h21.9v-24.1
		h-21.9V345.9z"/>
                    <path fill="#4B485A" d="M771,389.9V213.9h117.6v21.9l-8.8,22.4h-54v19.6h54v44h-54v24.1h62.8v44H771z" />
                    <path fill="#4B485A" d="M896.2,345.9h16.3l26.9-131.9h105.6v131.9h14.6v44H896.2V345.9z M989.9,257.9h-7.8l-13.6,88h21.4V257.9z" />
                    <path fill="#E3E032" d="M52.1,511.9v-39.4h29.5v39.4H69.4v-29.5h-4.9v29.5H52.1z" />
                    <path fill="#D2E6F8" d="M104.1,499.6v12.3H91.8v-39.4h24.6c2.9,0,4.9,2,4.9,4.9v17.3c0,2.9-2,4.9-4.9,4.9H104.1z M104.1,489.8h4.9
		v-7.4h-4.9V489.8z"/>
                    <path fill="#D2E6F8" d="M135.8,511.9c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9V507
		c0,2.9-2,4.9-4.9,4.9H135.8z M143.2,482.4V502h4.9v-19.6H143.2z"/>
                    <path fill="#D2E6F8" d="M170.5,511.9v-39.4h26.3v4.9l-2,5h-12.1v4.4h12.1v9.8h-12.1v5.4h14.1v9.8H170.5z" />
                    <path fill="#D2E6F8" d="M223.3,472.5H235v4.9l-4.6,13.6l5.5,16v4.9h-12.3l-3.3-15.2h-2.2v15.2h-12.3v-39.4h12.3v14.3h2.2L223.3,472.5z
		"/>
                    <path fill="#D2E6F8" d="M243.2,482.4v-9.9h27.6v4.9l-2,5h-5.9v29.5h-12.3v-29.5H243.2z" />
                    <path fill="#D2E6F8" d="M296.4,486.8v-14.3h12.3v39.4h-12.3v-15.2h-4.9v15.2h-12.3v-39.4h12.3v14.3H296.4z" />
                    <path fill="#D2E6F8" d="M323.7,511.9c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9V507
		c0,2.9-2,4.9-4.9,4.9H323.7z M331.1,482.4V502h4.9v-19.6H331.1z"/>
                    <path fill="#D2E6F8" d="M385.1,488.3v7.9h-27.8v-7.9H385.1z" />
                    <path fill="#F5EB79" d="M411.3,472.5h12.3v39.4h-12.3V507l1.9-12.6h-1l-5.8,13.6v3.9h-12.3v-39.4h12.3v4.9l-1.8,12.6h1l5.8-13.6V472.5
		z"/>
                    <path fill="#D2E6F8" d="M460,472.5v4.9l-1.9,5H446V502h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H460z" />
                    <path fill="#D2E6F8" d="M495.3,472.5v4.9l-1.9,5h-12.1V502h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H495.3z" />
                    <path fill="#D2E6F8" d="M521.3,511.9v-29.5h-1.7l-4.6,29.5h-12.4V507l7.4-34.5h23.6v39.4H521.3z" />
                    <path fill="#D2E6F8" d="M543.7,511.9v-39.4H570v4.9l-2,5H556v4.4h12.1v9.8H556v5.4H570v9.8H543.7z" />
                    <path fill="#D2E6F8" d="M577.3,502h3.7l6-29.5h23.6V502h3.3v9.8h-36.6V502z M598.3,482.4h-1.7l-3,19.7h4.8V482.4z" />
                    <path fill="#D2E6F8" d="M627.3,511.9c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9V507
		c0,2.9-2,4.9-4.9,4.9H627.3z M634.6,482.4V502h4.9v-19.6H634.6z"/>
                    <path fill="#D2E6F8" d="M662,511.9v-39.4h24.6c2.9,0,4.9,2,4.9,4.9v9.1c0,2.9-2.4,4.7-5.1,4.7v1.1c2.7,0,5.1,1.9,5.1,4.8v10
		c0,2.9-2,4.9-4.9,4.9H662z M679.2,482.4h-4.9v4.4h4.9V482.4z M674.3,502h4.9v-5.4h-4.9V502z"/>
                    <path fill="#D2E6F8" d="M725.9,472.5l5.5,34.5v4.9h-12.3l-0.7-7.9H713l-0.8,7.9h-12.3V507l5.5-34.5H725.9z M717.5,494.1l-1.1-11.7H715
		l-1.1,11.7H717.5z"/>
                    <path fill="#D2E6F8" d="M736.5,482.4v-9.9H764v4.9l-2,5h-5.9v29.5h-12.3v-29.5H736.5z" />
                    <path fill="#D2E6F8" d="M772.5,511.9v-39.4h26.3v4.9l-2,5h-12.1v4.4h12.1v9.8h-12.1v5.4h14.1v9.8H772.5z" />
                    <path fill="#D2E6F8" d="M824.8,511.9v-29.5h-1.7l-4.6,29.5h-12.4V507l7.4-34.5h23.6v39.4H824.8z" />
                    <path fill="#D2E6F8" d="M876.7,489.7v17.2c0,2.9-2,4.9-4.9,4.9h-24.6v-39.4h12.3v12.3h12.3C874.7,484.8,876.7,486.9,876.7,489.7z
		 M864.4,502v-7.4h-4.9v7.4H864.4z"/>
                    <path fill="#D2E6F8" d="M912.6,472.5v4.9l-1.9,5h-12.1V502h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H912.6z" />
                    <path fill="#D2E6F8" d="M939.1,472.5h11.6v4.9l-4.6,13.6l5.5,16v4.9h-12.3l-3.3-15.2h-2.2v15.2h-12.3v-39.4h12.3v14.3h2.2L939.1,472.5
		z"/>
                    <path fill="#D2E6F8" d="M985.5,472.5L991,507v4.9h-12.3l-0.7-7.9h-5.4l-0.8,7.9h-12.3V507l5.5-34.5H985.5z M977.1,494.1l-1.1-11.7
		h-1.3l-1.1,11.7H977.1z"/>
                    <path fill="#D2E6F8" d="M1029,511.9h-12.3v-12.3h-2.5l-3.3,12.3h-12V507l3.8-9.7c-1.9-0.5-3.3-2.6-3.3-4.5v-15.4c0-2.9,2-4.9,4.9-4.9
		h24.6V511.9z M1011.8,489.7h4.9v-7.4h-4.9V489.7z"/>
                    <path fill="#F5EB79" d="M55,574.2v-39.4h29.5v39.4H72.2v-29.5h-4.9v29.5H55z" />
                    <path fill="#D2E6F8" d="M94.6,574.2v-39.4H121v4.9l-2,5h-12.1v4.4H119v9.8h-12.1v5.4H121v9.8H94.6z" />
                    <path fill="#D2E6F8" d="M128.3,564.4h3.7l6-29.5h23.6v29.5h3.3v9.8h-36.6V564.4z M149.3,544.7h-1.7l-3,19.7h4.8V544.7z" />
                    <path fill="#D2E6F8" d="M198.1,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9h-5.4l-0.8,7.9h-12.3v-4.9l5.5-34.5H198.1z M189.7,556.4l-1.1-11.7
		h-1.4l-1.1,11.7H189.7z"/>
                    <path fill="#D2E6F8" d="M224.9,574.2h-12.3v-39.4h26.3v4.9l-1.9,4.9h-12.1V574.2z" />
                    <path fill="#D2E6F8" d="M252.3,574.2c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9v29.6
		c0,2.9-2,4.9-4.9,4.9H252.3z M259.7,544.7v19.6h4.9v-19.6H259.7z"/>
                    <path fill="#D2E6F8" d="M299.3,574.2H287v-39.4h26.3v4.9l-1.9,4.9h-12.1V574.2z" />
                    <path fill="#D2E6F8" d="M339,534.8h12.3v39.4H339v-4.9l1.9-12.6h-1l-5.8,13.6v3.9h-12.3v-39.4H334v4.9l-1.8,12.6h1l5.8-13.6V534.8z" />
                    <path fill="#D2E6F8" d="M360.8,556v-21.1H373v16.3h4.9v-16.3h12.3v39.4H378v-13.3h-12.3C362.8,560.9,360.8,558.9,360.8,556z" />
                    <path fill="#D2E6F8" d="M400.4,574.2v-39.4h26.3v4.9l-2,5h-12.1v4.4h12.1v9.8h-12.1v5.4h14.1v9.8H400.4z" />
                    <path fill="#D2E6F8" d="M462,534.8v4.9l-1.9,5H448v19.6h14v9.8h-21.4c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9H462z" />
                    <path fill="#D2E6F8" d="M488.5,534.8h11.6v4.9l-4.6,13.6l5.5,16v4.9h-12.3l-3.3-15.2h-2.2v15.2H471v-39.4h12.3v14.3h2.2L488.5,534.8z"
                    />
                    <path fill="#D2E6F8" d="M534.9,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9H522l-0.8,7.9H509v-4.9l5.5-34.5H534.9z M526.5,556.4l-1.1-11.7H524
		l-1.1,11.7H526.5z"/>
                    <path fill="#D2E6F8" d="M578.4,574.2h-12.3v-12.3h-2.5l-3.3,12.3h-12v-4.9l3.8-9.7c-1.9-0.5-3.3-2.6-3.3-4.5v-15.4
		c0-2.9,2-4.9,4.9-4.9h24.6V574.2z M561.2,552.1h4.9v-7.4h-4.9V552.1z"/>
                    <path fill="#F5EB79" d="M638.7,574.2v-29.5H637l-4.6,29.5H620v-4.9l7.4-34.5H651v39.4H638.7z" />
                    <path fill="#D2E6F8" d="M686,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9H673l-0.8,7.9H660v-4.9l5.5-34.5H686z M677.5,556.4l-1.1-11.7h-1.4
		l-1.1,11.7H677.5z"/>
                    <path fill="#D2E6F8" d="M730,554v15.2c0,2.9-2,4.9-4.9,4.9h-24.6v-39.4h29.2v4.9l-2,4.9h-15v4.4h12.3C727.9,549.1,730,551.2,730,554z
		 M717.7,564.4V559h-4.9v5.4H717.7z"/>
                    <path fill="#D2E6F8" d="M744.5,574.2c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9v29.6
		c0,2.9-2,4.9-4.9,4.9H744.5z M751.8,544.7v19.6h4.9v-19.6H751.8z"/>
                    <path fill="#D2E6F8" d="M791.5,562v12.3h-12.3v-39.4h24.6c2.9,0,4.9,2,4.9,4.9V557c0,2.9-2,4.9-4.9,4.9H791.5z M791.5,552.1h4.9v-7.4
		h-4.9V552.1z"/>
                    <path fill="#D2E6F8" d="M841.4,534.8l5.5,34.5v4.9h-12.3l-0.7-7.9h-5.4l-0.8,7.9h-12.3v-4.9l5.5-34.5H841.4z M833,556.4l-1.1-11.7
		h-1.4l-1.1,11.7H833z"/>
                    <path fill="#D2E6F8" d="M852,544.7v-9.9h27.6v4.9l-2,5h-5.9v29.5h-12.3v-29.5H852z" />
                    <path fill="#D2E6F8" d="M892.9,574.2c-2.9,0-4.9-2-4.9-4.9v-29.6c0-2.9,2-4.9,4.9-4.9h19.6c2.9,0,4.9,2,4.9,4.9v29.6
		c0,2.9-2,4.9-4.9,4.9H892.9z M900.3,544.7v19.6h4.9v-19.6H900.3z"/>
                    <path fill="#D2E6F8" d="M939.9,562v12.3h-12.3v-39.4h24.6c2.9,0,4.9,2,4.9,4.9V557c0,2.9-2,4.9-4.9,4.9H939.9z M939.9,552.1h4.9v-7.4
		h-4.9V552.1z"/>
                    <path fill="#D2E6F8" d="M983.9,534.8h12.3v39.4h-12.3v-4.9l1.9-12.6h-1l-5.8,13.6v3.9h-12.3v-39.4h12.3v4.9l-1.8,12.6h1l5.8-13.6
		V534.8z"/>
                    <path fill="#D2E6F8" d="M1035.2,574.2h-12.3v-12.3h-2.5l-3.3,12.3h-12v-4.9l3.8-9.7c-1.9-0.5-3.3-2.6-3.3-4.5v-15.4
		c0-2.9,2-4.9,4.9-4.9h24.6V574.2z M1018,552.1h4.9v-7.4h-4.9V552.1z"/>
                </g>
            </svg>
            <div>
                {
                    Style.it(
                        `
                            .landing-kraeved-title-title
                            {
                               color:${palette.main_text_color};
                               position:relative;
                               top:0;
                               left:0;
                               text-align:center;
                               padding:0 10px;
                               font-size:12px;
                            }
                            `,
                        <div className="landing-kraeved-title-title">
                            <span dangerouslySetInnerHTML={{ __html: title }} />
                        </div>
                    )
                }
                {
                    Style.it(
                        `
                            .landing-kraeved-title-descr
                            {
                               color:${palette.main_text_color};
                               position:relative;
                               top:0;
                               left:0;
                               text-align:center;
                               padding:0 10px;
                            }
                            `,
                        <div className="landing-kraeved-title-descr">
                            <span dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                    )
                }
            </div>

            <div>
                {
                    Style.it(
                        `
                            .landing-kraeved-title-number
                            {
                                color: #111;
                                left: auto;
                                top: -12px;
                                right: 10px;
                                width: 40%;
                                text-align: center;
                                color:${clr2};
                               font-family:${getFontNameByID(palette.h1.fontFamilyID)};
                            }
                            `,
                        <div className="landing-kraeved-title-number">
                            <span dangerouslySetInnerHTML={{ __html: number }} />
                        </div>
                    )
                }
                {
                    Style.it(
                        `
                            .landing-kraeved-title-year
                            { 
                               color:#111;
                               left: auto;
                               right: 10px;
                               width: 40%;
                               text-align: center;
                               top: 60px;
                               color:${clr3};
                            }
                            `,
                        <div className="landing-kraeved-title-year">
                            <span dangerouslySetInnerHTML={{ __html: year }} />
                        </div>
                    )
                }
            </div>
            <div>
                {
                    this.props.data.button_label && this.props.data.button_route
                        ?
                        Style.it(
                            `
                        .kraeved-button 
                        {
                            position: absolute;
                            bottom: 65px;
                            right: 10px;
                            z-index: 10;
                        }
                        .kraeved-button > a
                        { 
                            padding: 4px 20px; 
                            opacity:.75;
                        }
                        .kraeved-button > a:hover
                        {  
                            opacity:1;
                        }
                        `,
                            <div className="kraeved-button">
                                <Link
                                    to={this.props.data.button_route}
                                >
                                    {this.props.data.button_label}
                                </Link>
                            </div>
                        )
                        :
                        null
                }
            </div>
        </div>
    }
}
export default KraevedTitle
