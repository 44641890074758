import React, { Component, Suspense } from "react"
import { Button } from "@blueprintjs/core"
import $ from "jquery"
import { __ } from "react-pe-utilities" 
import ContactFormVariant from "./ContactFormVariant"
import {LayoutIcon} from 'react-pe-useful'
import {Loading} from 'react-pe-useful'

import __string from "../../assets/img/landing/string.svg"
import __email from "../../assets/img/landing/email.svg"
import __phone from "../../assets/img/landing/phone.svg"
import __image from "../../assets/img/landing/picture.svg"
import __time from "../../assets/img/landing/time.svg"
import __calendar from "../../assets/img/landing/calendar.svg"
import __list from "../../assets/img/landing/list.svg"
import Select from "react-select" 

class ContanctFormFieldButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      object: this.props.object,
    }
  }

  render() {
    //console.log(this.props)
    //console.log(this.props.object)
    const style = { margin: 0 }

    return (
      <div
        className=" btn-item p-0  h-100"
        style={style}
      >
        <div className=" layout-centered flex-column card p-4 m-0 h-100">
          <div className="title hidden">
            {this.props.i}
          </div>
          <div className=" hidden ">
            {__("Put form label")} 
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={this.props.object.label}
              onChange={this.onLabel}
              className="form-control input dark"
              placeholder={__("label")}
            />
          </div>
          <div className=" hidden ">
            {__("Select input type")}
          </div>
          <div className="mb-4">
            {this.getSwitcher()}
          </div>
          {
            this.props.object.type == "radio"
              ? (
                <>
                  <div>
                    {__("Set choosed variants")}
                  </div>
                  <div className="mb-3">
                    {this.variantForm()}
                  </div>
                </>
              )
              : null
          }
          {
            this.props.object.type == "text"
              ? (
                <> 
                  <div className="mb-3">
                    <input 
                      type="number"  
                      value={this.props.object.rows}
                      onChange={this.onRowCount}
                      className="form-control input dark"
                      placeholder={__("row count")}
                    />
                  </div>
                </>
              )
              : null
          }
          <div className=" hidden ">
            {__("Put form description")}
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={this.props.object.description}
              onChange={this.onDescription}
              className="form-control input dark"
              placeholder={__("description")}
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={this.props.object.placeholder}
              onChange={this.onPlaceholder}
              className="form-control input dark"
              placeholder={__("placeholder")}
            />
          </div>
          <div className="mb-4">
            <input
              type="checkbox"
              value={1}
              onChange={this.onIsReq}
              checked={this.props.object.is_required}
              className="checkbox"
              id={`is_required_${this.props.i}`}
              placeholder={__("description")}
            />
            <label htmlFor={`is_required_${this.props.i}`}>
              {__("Is Required")}
            </label>
          </div>
          <button className="close top-right m-2" aria-label="Close" onClick={this.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    )
  }


  switchLabel = data =>
  {
    const dat = data ? data : formTypes()[0]
    return {
      value: dat.type,
      label: <div className="w-100 d-flex align-items-center">
        <LayoutIcon
          src={dat.icon}
          className="layout-icon-minimal mr-3 dark"
        />
        <div className=" font-weight-bold ">
          {__(dat.title)}
        </div>
      </div>
    }
  }
  getSwitcher = () => 
  {
    //console.log(this.props.object)
    const val = this.switchLabel(formTypes().filter(e => e.type == this.props.object.type)[0])
    const menuDSelect = formTypes().map((e, i) => {
      return this.switchLabel(e)
    })
    const customStyles = {
      option: base => ({
        ...base,
        minHeight: 35,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }),
      valueContainer: base => ({
        ...base,
        minHeight: 60,
        fontSize: '.8rem',
        whiteSpace: 'unset',
        backgroundColor: '#ced0d2',
        border: 'none',
        borderRadius: 0,
      }),
      singleValue: base => ({
        ...base,
        whiteSpace: 'unset',
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        backgroundColor: '#ced0d2',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#444',
      }),
    }
    return <Suspense fallback={<Loading />}>
      <Select
        value={val}
        isMulti={false}
        isSearchable
        options={menuDSelect}
        styles={customStyles}
        className='basic-multi-select '
        classNamePrefix='select-route-'
        onChange={this.handleCurrentMenu}
      />
    </Suspense>
  }

  variantForm = () => 
  {
    const data = Array.isArray(this.props.object.data) ? this.props.object.data : []
    const vForms = data.map((e, i) => (
      <ContactFormVariant
        key={i}
        i={i}
        on={this.onChangeData}
        {...e}
      />
    ))

    return (
      <div>
        {vForms}
        <Button icon="plus" onClick={this.onAddVariant} />
      </div>
    )
  }
  
  handleCurrentMenu = data => {
    let type = formTypes().filter(e => e.type === data.value)[0]
    if(!type)
    type = formTypes()[0]
    const object = { ...this.props.object, type: type.type } 
    console.log(object)
    this.onChange(object)
  }
  onRowCount = (evt) => 
  {
    const object = { ...this.props.object, rows: evt.currentTarget.value } 
    this.onChange(object)
  }
  onLabel = (evt) => 
  {
    const object = { ...this.props.object, label: evt.currentTarget.value } 
    this.onChange(object)
  }

  onDescription = (evt) => {
    const object = { ...this.props.object, description: evt.currentTarget.value }  
    this.onChange(object)
  } 
  onPlaceholder = (evt) => {
    const object = { ...this.props.object, placeholder: evt.currentTarget.value }  
    this.onChange(object)
  } 

  onAddVariant = () => {
    const data = Array.isArray(this.props.object.data) ? this.props.object.data : []
    const object =   {
        ...this.props.object,
        data: [
          ...data,
          {
            label: "new variant",
            value: "",
          },
        ],
      }
    this.onChange(object)
  }

  onIsReq = (evt) => {
    const is_required = $(evt.currentTarget).is(":checked") ? 1 : 0
    const object = { ...this.props.object, is_required } 
    this.onChange(object)
  }

  onChangeData = (field, value, i) => {
    const object = { ...this.props.object }
    if (!Array.isArray(object.data)) object.data = []
    if (!object.data[i]) object.data[i] = {}
    object.data[i][field] = value
    console.log(object) 
    this.onChange(object)
  }

  onChange = (object) => {
    console.log( object)
    this.setState(object)
    this.props.onChange(object, this.props.i)
  }

  onClose = () => {
    this.props.onClose(this.props.i)
  }
}
export default ContanctFormFieldButton

export function formTypes() {
  return [
    {
      type: "string",
      title: "String",
      icon: __string,
    },
    {
      type: "text",
      title: "Text",
      icon: __string,
    },
    {
      type: "email",
      title: "email",
      icon: __email,
    },
    {
      type: "phone",
      title: "phone",
      icon: __phone,
    },
    {
      type: "file_loader",
      title: "File loader",
      icon: __image,
    },
    {
      type: "time",
      title: "Time",
      icon: __time,
    },
    {
      type: "calendar",
      title: "Calendar",
      icon: __calendar,
    },
    {
      type: "radio",
      title: "Choosing one variant",
      icon: __list,
    },
  ]
}
