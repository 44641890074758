import React, { Component } from "react"
import $ from "jquery"
import { withRouter } from "react-router"
import { withApollo } from "react-apollo"
import { compose } from "recompose"
import { Dialog } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import {LayoutIcon} from 'react-pe-useful'
import EditLabel from "./EditLabel" 
import DataContext from "./DataContext"
import PlaceDialogContent from "./yandexMap/PlaceDialogContent"

import { getQueryArgs, getQueryName, queryCollection } from "react-pe-layouts"
import { initArea } from  "react-pe-utilities" 
import { components } from "./data/components"

class YandexMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideMarker: false,
      places: this.props.data.places,
      position: this.props.data.position ? this.props.data.position : [55.76, 37.64, 10],
    }
    const el = document.createElement("script")
    el.context = this
    el.type = "text/javascript"
    el.src = `https://api-maps.yandex.ru/2.1/?load=package.full&lang=ru_RU&apikey=${this.props.data.yandex_map_api_key}`
    el.async = true
    el.id = "ymap-jssdk"
    el.onload = function () {
      window.ymaps.ready(() => {
        el.context.init_map()
      })
    }
    document.getElementsByTagName("head")[0].appendChild(el)
    window.mapState = this// .props.context;
    window.yandex_map_state = this
    window.mapData = {}
    window.getPlaceLink = function (n) {
      window.mapState.getPlaceLink(n)
    }
    window.getPlaceDialog = function (n, i) {
      window.mapState.getPlaceDialog(n, i)
    }
    window.getClusterDialog = function (data) {
      window.mapState.getClusterDialog(data)
    }
  }

  componentDidMount() {
    if (this.props.data.src_type !== "server" || !this.props.data.data_type) return
    const query_name = getQueryName(this.props.data.data_type)
    const query_args = getQueryArgs(this.props.data.data_type)
    const query = queryCollection(this.props.data.data_type, query_name, query_args)
    this.props.client.query({
      query,
    })
      .then((result) => {
        const res = result.data.getPEVideoPoints.map((e) => ({ ...e, title: e.post_title }))
        // console.log( this.props.data.places );
        // console.log( res );
        this.setState({ places: res })
      })
  }

  componentWillUnmount() {
    delete window.getPlaceLink
    delete window.getPlaceDialog
    delete window.yandex_map_state
    delete window.mapState
  }

  componentWillReceiveProps(nextProps) {
    const newState = {}; let
      boo
    if (nextProps.places && nextProps.places !== this.props.data.places) {
      newState.places = nextProps.places
      boo = true
    }
    if (boo) {
      this.setState(newState, function () {
        $("#map").empty()
        this.loaded = false
        this.init_map()
      })
    }
    /**/
  }

  render() {
    const { is_expand, expand_persentage } = this.props.composition
    const stl = { ...this.props.data.style }
    let height = this.props.data.height ? this.props.data.height : 400
    if (is_expand) {
      const persentage = expand_persentage || 100
      height = document.body.clientHeight * persentage / 100
      stl.minHeight = height
    }
    return this.props.data.position
      ? (
        <div
          className={`landing-yandex-map ${this.props.data.class_name}`}
          style={stl}
        >
          <div
            className="map "
            id="map"
            style={{ height }}
          />
          <div>
            {
              initArea(
                "after-yandex-map",
                { ...this.props, data: { ...this.state } },
              )
            }
          </div>
          <Dialog
            className="little p-0"
            isOpen={this.state.isClusterOpen}
            onClose={this.toggleDialogCluster}
          >
            <div className="p-0 cluster-dialog">
              <ul className="list-group">
                {
                  Array.isArray(this.state.cluster)
                    ? this.state.cluster.map((e, i) => {
                      // console.log(e);
                      let sap
                      switch (e.data_type) {
                        case "place":
                          sap = "getPlaceDialog"
                          break
                        case "event":
                          sap = "getEventDialog"
                          break
                        case "team":
                        default:
                          sap = "getTeamDialog"
                          break
                      }
                      return (
                        <li
                          className="list-group-item pointer"
                          onClick={() => { this.onChoose(this.state.cluster[i]) }}
                          key={i}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: this.state.places[e.data_i].title }}
                          />
                        </li>
                      )
                    })
                    : null
                }
              </ul>
            </div>
          </Dialog>
          <Dialog
            icon="map-marker"
            isOpen={this.state.isPlaceOpen}
            onClose={this.togglePlaceCluster}
            title={<div>{__(this.state.placeTitle)}</div>}
            className="p-0"
          >
            <div className="py-0 px-0 ">
              <PlaceDialogContent
                action_type={this.state.action_type}
                action_youtube_id={this.state.action_youtube_id}
                action_simple_text_dialog={this.state.action_simple_text_dialog}
                width={640}
                height={480}
              />
            </div>
          </Dialog>
        </div>
      )
      : (
        <div
          className={` landing-empty ${this.state.class_name}`}
          style={{ ...this.props.data.style, height: 300 }}
        >
          <LayoutIcon
            src={components()[this.props.type].icon}
            className=" layout-icon-giant "
          />
          <div className="lead text-white">
            {components()[this.props.type].title}
          </div>
          <EditLabel
            {...this.props}
            source={this.props.type}
            onEdit={this.props.onEdit}
            isBtn
          />
        </div>
      )
  }

  init_map() {
    if (this.loaded) return
    this.loaded = true
    if (!document.getElementById("map")) return
    // console.log( this.state.places );
    this.myMap = new window.ymaps.Map(
      "map",
      {
        center: this.state.position,
        zoom: this.state.position[2] ? this.state.position[2] : 12,
        controls: ["zoomControl"],
        margin: 20,
      },
      {
        searchControlProvider: "yandex#search",
      },
    )
    this.myMap.behaviors.disable("scrollZoom")

    const places = this.state.places || []

    const customItemContentLayout2 = window.ymaps.templateLayoutFactory.createClass(
      // Флаг "raw" означает, что данные вставляют "как есть" без экранирования html.
      "<div  class=\"ballon_header lead text-center font-weight-light\">{{ properties.balloonContentHeader|raw }}</div>"
      + "<div class=\"ballon_body text-center\">{{ properties.balloonContentBody|raw }}</div>",
    )

    this.clusterer = new window.ymaps.Clusterer(
      {
        /// / Зададим макет метки кластера.
        // clusterIconLayout: window.ymaps.templateLayoutFactory.createClass(
        //   "<div class=\"clusterIcon\"><div class=\"place_flag\"></div>{{ properties.geoObjects.length }}</div>",
        // ),
        preset: "islands#invertedVioletClusterIcons",
        // groupByCoordinates: true,
        clusterDisableClickZoom: true,
        clusterOpenBalloonOnClick: false,
        clusterBalloonContentLayout: "cluster#balloonCarousel",
        clusterBalloonItemContentLayout: customItemContentLayout2,
        clusterBalloonContentLayoutWidth: 230,
        clusterBalloonContentLayoutHeight: 110,
        // Макет метки кластера pieChart.
        clusterIconLayout: "default#pieChart",
        // Радиус диаграммы в пикселях.
        clusterIconPieChartRadius: 35,
        // Радиус центральной части макета.
        clusterIconPieChartCoreRadius: 15,
        // Ширина линий-разделителей секторов и внешней обводки диаграммы.
        clusterIconPieChartStrokeWidth: 3,
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
        gridSize: 128,
      },
      {
        gridSize: 128,
      },
    )

    places.forEach((el, i) => {
      const dataType = Array.isArray(DataContext.data.landing.place_type) 
        ?
        DataContext.data.landing.place_type.filter((e) => e._id === el.type)[0]
        :
        {}
      // console.log( el );
      // console.log( DataContext.data.landing.place_type );
      // console.log( dataType );
      // Создаём макет содержимого.
      const type = dataType || {}
      const width = type.width ? type.width : 26
      const height = type.height ? type.height : 30
      const offset_x = type.offset_x ? type.offset_x : -13
      const offset_y = type.offset_y ? type.offset_y : -30
      const pfill = type.color
      let MyIconContentLayout
      let iconImageHref
      let iconContent
      switch (type.type) {
        case "oval":
          iconImageHref = "/assets/img/circle.svg"
          MyIconContentLayout = window.ymaps.templateLayoutFactory.createClass(
            `<div class="landing-yandex-map-marker-oval" style="width:${width + 14}px; height:${height + 14}px; background-color:${pfill};">$[properties.iconContent]</div>`,
          )
          iconContent = `<div style='background-image:url(${type.icon}); width:${width - 10}px; height:${height - 10}px; background-position:center; background-repeat:no-repeat; background-size:contain; fill:${pfill};'></div>`
          break
        default:
          break
      }

      const place = new window.ymaps.Placemark(
        el.geo_point && Array.isArray(el.geo_point)
          ? [el.geo_point[0], el.geo_point[1]]
          : [0, 0],
        {
          data_id: el._id,
          data_i: i,
          data_type: "place",
          hintContent: `${__(type.title)} ${el.title}`,
          balloonContentHeader: "getPlaceHeader(el)",
          iconContent,
        },
        {
          // Необходимо указать данный тип макета.
          iconLayout: "default#imageWithContent",
          // Своё изображение иконки метки.
          iconImageHref,
          // Размеры метки.
          iconImageSize: [width, height],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [offset_x, offset_y],
          iconImageFill: "#FFFFFF",
          // Смещение слоя с содержимым относительно слоя с картинкой.
          iconContentOffset: [0, 0],
          // Макет содержимого.
          iconContentLayout: MyIconContentLayout,

          hasBalloon: false,
          hasHint: true,
          // preset: 'islands#governmentCircleIcon',
          iconColor: pfill,
        },
      )
      // console.log(i);
      place.events.add("click", (evt) => window.getPlaceDialog(el, i))
      this.clusterer.add(place)
    })
    this.myMap.geoObjects.add(this.clusterer)
    this.clusterer.events.add("click", (evt) => window.getClusterDialog(
      evt.get("target").properties._data.geoObjects,
      __("Cluster"),
    ))

    if (this.props.onInit) this.props.onInit(this.myMap)
    /*

    */
  }

  getClusterDialog(geoObjects, clusterTitle = "Cluster") {
    const cluster = Array.isArray(geoObjects)
      ? geoObjects.map((e, i) => ({ ...e.properties._data }))
      : null
    // console.log( cluster );
    this.setState({
      isClusterOpen: (cluster ? !this.state.isClusterOpen : false),
      clusterTitle,
      cluster,
    })
  }

  toggleDialogCluster = (evt) => {
    this.setState({ isClusterOpen: !this.state.isClusterOpen })
  }

  getPlaceDialog = (data, i) => {
    // console.log(data, i);
    this.onChoose({ ...data, data_i: i })
  }

  onChoose = (data) => {
    // console.log( data );
    this.setState({
      isPlaceOpen: true,
      placeTitle: this.state.places[data.data_i].title,
      action_type: this.state.places[data.data_i].action_type,
      action_youtube_id: this.state.places[data.data_i].action_youtube_id,
      action_simple_text_dialog: this.state.places[data.data_i].action_simple_text_dialog,
    })
  }

  togglePlaceCluster = (evt) => {
    this.setState({ isPlaceOpen: !this.state.isPlaceOpen })
  }
}
export default compose(
  withApollo,
  withRouter,
)(YandexMap)
