import React, { useEffect, useState } from "react"
import * as QRCode from 'easyqrcodejs';
import {getQRDefaultData} from "../views/QRCodeAdminView"

const QRCodeSign = props =>
{
    let qrcode = React.createRef();  
    let QRCodeObject
    const [logo, setLogo] = useState(props.options.logo)
    
    useEffect(() =>
    { 
        //console.log(props.options )
        if(props.options.logo)
        {
            toDataUrl( props.options.logo, ( result ) => {
                //console.log(result)
                setLogo( result )
                createQRCode()
            })
        }
        else
        {
             createQRCode()
        }           
    }, [ props ]  )

    const createQRCode = () => {
        // Options
        var options = {
            ...getQRDefaultData(),
            text: "https://github.com/ushelp/EasyQRCodeJS",
            ...props.options,
            logo: logo,
            thumbnail:logo
        }
        if( !qrcode.current ) return
        // console.log( options, qrcode.current )
        // clear
        if( qrcode.current.firstElementChild)
        {
            qrcode.current.firstElementChild.remove()
        }
        // Create new QRCode Object
        QRCodeObject = new QRCode( qrcode.current, options); 
    }
    
    const toDataUrl = (url, callback) => {
        var xhr = new XMLHttpRequest()
        xhr.onload = function() {
            var reader = new FileReader()
            reader.onloadend = function() {
                callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
        };
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    }
 

    return <> 
        <div
            id={props.id} 
            ref={qrcode} 
            className="pe-qr-cont"
            name={
                props.options.post_title 
                    ? 
                    props.options.post_title.replace( /[//, :,?,=,.]/g, "_" )  
                    : 
                    props.options.text.replace( /[//, :,?,=,.]/g, "_" ) 
            } 
            style={{width:props.options.width, height:props.options.height }}
        /> 
    </>

}
export default QRCodeSign;
