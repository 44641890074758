import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { __ } from "react-pe-utilities"
import { get, exec_route } from "react-pe-layouts"

class FreeMenu extends Component {
  render() {
    const menuData = exec_route(get(this.props.menu_id))
    if (!this.props.menu_id) {
      return (
        <div className="alert alert-danger">
          Empty free menu data in sector «routes» in layouts
        </div>
      )
    }
    if (!menuData) {
      return (
        <div className="alert alert-danger">
          No contents in sector «
          {this.props.menu_id}
          » in layouts
        </div>
      )
    }
    const menu = menuData.map((e, i) => (
      <NavLink
        to={e.route}
        className="free-menu-item"
        activeClassName="active"
        key={i}
      >
        <span>{__(e.title)}</span>
      </NavLink>
    ))
    console.log(this.props)
    return (
      <div className="free-menu-container">
        {menu}
      </div>
    )
  }
}
export default FreeMenu
