import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { __ } from "react-pe-utilities/dist"
import { Link } from "react-router-dom" 
import _fetch from "."
import { DEFAULT_WPFEST_ADMIN_URI } from "../TutorWPFest"
import WPFestSettings from "./utilities/WPFestSettings"

const MenuFest = props =>
{
    const [sites, setSites] = useState([])
    const [indexedSites, setIndexedSites] = useState([])
    const [pattern_sites, setPattern_sites] = useState([])
    useEffect(() => {
        IndexDB.recover("peFestPattern", "peFestPattern", "id")
            .then(res => 
                {
                    //console.log( res )
                    const psites = res.map( site => {
                        return site.data
                    })
                    setPattern_sites( psites )
                })
        IndexDB.recover("peFest", "peFest", "id")
            .then(res =>
                { 
                    const sites = res.map( site => {
                        return site.data
                    })
                    setSites( sites )
                    setIndexedSites(sites)
                })
        _fetch(
            "get_open_sites",
            { offset:0, number:-1 },
            WPFestSettings.serviceUrl || DEFAULT_WPFEST_ADMIN_URI ,
            "", 
          )
            .then((data) => {
                //console.log( data.pattern_sites )
                const _sites = data.sites || []
                const patterns = data.pattern_sites || []
                setSites(_sites )
                setPattern_sites(patterns )
                _sites.forEach(site => {
                    const bSite = indexedSites.filter(bs => parseInt(bs.blog_id) === parseInt(site.blog_id) )[0] || {}
                    const s = {...bSite, ...site, id: site.blog_id} 
                    // console.log( bSite )
                    // console.log( s )
                    IndexDB.save(
                        s, 
                        "id", 
                        s.id, 
                        "peFest", 
                        "peFest", 
                        1 
                    )
                })
                patterns.forEach(site => {
                    const s = {...site } 
                    // console.log(s)
                    IndexDB.save(
                        s, 
                        "id", 
                        s.id, 
                        "peFestPattern", 
                        "peFestPattern", 
                        1 
                    )
                })
                
            })
    },[])
    const list = sites
        .filter(site => pattern_sites.filter(p => p.domain_id == site.blog_id).length === 0)
            .map((site, i) => {
                const rt = props.route === "/" || props.route === "//"
                    ?
                    ""
                    :
                    props.route
                // console.log( rt )
                return <li className="list-group-item p-0" key={i} >
                    <Link 
                        className="btn btn-light btn-block"
                        to={ rt + "/" + site.blog_id }
                    >
                        <div className="h-100 w-100 d-flex">
                            <div className="fest-id">
                                {site.blog_id}
                            </div>
                            <div className="h-100 w-100 flex-grow-100">
                                { site.name }
                            </div>
                        </div>
                        
                    </Link>
                
                </li>
            })
    return <div className="flex-centered h-100">
        <div className="card p-0 w-100 " style={{maxWidth:400}}>
            <div className="card-body p-0">
                <div className="card-title lead p-3 text-center">
                    {__("Select your festival")}
                </div>
                <ul className="list-group list-group-flush">
                    {list}
                </ul>
            </div>
        </div>
    </div>
}

export default MenuFest 
