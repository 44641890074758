import { Button, ButtonGroup, Dialog, DialogStep, MultistepDialog, Position } from "@blueprintjs/core"
import React, { useState } from "react"
import Layouts from "react-pe-layouts"
import FieldInput from "react-pe-scalars/dist"
import { __ } from "react-pe-utilities/dist"
import EditPageDialog from "./EditPageDialog"

const CurrentFolderMenu = props =>
{
    const [data, setData] = useState({ folder: props.currentFolderId, is_cabinet:true })
    const [isEditOpen, setEditOpen] = useState(false) 
    const onField = (value, field) =>
    {
        console.log( value, field )
        let _data = { ...data }
        _data[ field ] = value
        if(field === "component")
        {
            const _module = Layouts().views[ value ]?.module 
            _data.module = _module
            //setModule(Layouts().modules[_module])
        }
        setData( _data )
        
    }
    const onEditOpen = () =>
    {
        setEditOpen( !isEditOpen )
    }
    const onSaveRote = () =>
    {
        props.onAddRoute( data )
        setEditOpen( false )
    }
    const onPageTypeField = value =>
    { 
        let _data = {...data}
        delete _data.component
        delete _data.html
        _data[value] = ""
        setData( _data )
    }
    return <> 
        <ButtonGroup className="d-flex"  large >
            {
                props.currentFolderId > 0
                    ?
                    <Button
                        icon="arrow-up"
                        minimal
                        onClick={() => props.onSelect(-1)}
                    >
                        {__("to root")}
                    </Button>
                    :
                    null
            }
            
            <Button
                icon="add-to-artifact"
                minimal
                onClick={ onEditOpen }
            >
                {__("Add page")}
            </Button>
        </ButtonGroup>  
        <EditPageDialog
             data={data} 
             onEditOpen={onEditOpen}
             onField={onField}
             onPageTypeField={onPageTypeField}
             onSaveRote={onSaveRote}
             isEditOpen={isEditOpen}
             setEditOpen={setEditOpen}
             jsonData={`{folder:${props.currentFolderId}}`}
             dialogTitle="New page settings"
             saveButtonTitle="Insert new Page"
        />
    </>
}

export default CurrentFolderMenu