import React, { Component, Suspense } from "react"
//import Select from "react-select" // https://github.com/JedWatson/react-select 
import { Collapse, Intent, Button, ButtonGroup, Popover, Switch,  Position, Callout } from "@blueprintjs/core"
import { __ } from "react-pe-utilities"
import FieldInput from "react-pe-scalars"
import { roles } from "react-pe-layouts"
import { Loading } from "react-pe-useful" 
import Layouts, { schema } from "react-pe-layouts"
import { concatRouting } from "react-pe-layouts"
import { AppToaster } from 'react-pe-useful' 
import { compose } from "recompose"
import { withRouter } from "react-router"
import { importAll } from 'react-pe-utilities'
import $ from "jquery"
import Select from "react-select" 

const components = {}
const mdViews = [], mdDscr = {}
importAll(require.context("states/", false, /\.js$/), [], components)

// Собираем все компоненты из папки modules (которые указаны в layouts.json, раздел modules)
// для последующей подстановки в роутинги
const mdls = Layouts().modules
// console.log( mdls );
Object.keys(mdls).forEach((module) => {
  Object.keys(mdls[module].views).forEach((view) => {
    mdViews.push(view)
    // console.log(mdls[module].views[view])
    mdDscr[view] = mdls[module].views[view]
  })
})
// console.log(mdDscr)
importAll(require.context("../../../../modules/", true, /\.js$/), mdViews, components)
// console.log(components) 

class MenuElementForm extends Component {
  constructor(props) {
    super(props)
    //console.log(this.props.target_id, typeof this.props.target_id)
    this.state = {
      ...this.props,
      childName: "",
      is_target: typeof this.props.target_id != "undefined" ? 1 : 2,
      module_filter: Object.keys(mdls)
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.data_type);
    this.setState({
      component: null,
      data_type: null,
      single_data_type: null,
      singled_data_type: null,
      feed_data_type: null,
      feed_data_type_parent_id: null,
      feed_containerClassName: null,
      html_source: null,
      html: null,
      redirect: null,
      target_id: -1,
      children: null,
      icon:null,
      help_url: "", 
      external_settings:null, 
      folder:null, 
      is_target: typeof nextProps.target_id !== "undefined" ? 1 : 2,
      ...nextProps,
      //title: nextProps.title,
      //capability: nextProps.capability,
    })
  }

  render() 
  {
    //console.log(this.state)
    // console.log(this.state.capability)
    let contentType
    if (this.state.component)
      contentType = "component"
    else if (this.state.singled_data_type)
      contentType = "singled_data_type"
    else if (this.state.feed_data_type)
      contentType = "feed_data_type"
    else if (this.state.data_type)
      contentType = "data_type"
    else if (this.state.html_source)
      contentType = "html_source"
    else if (this.state.redirect)
      contentType = "redirect"
    else if (this.state.html)
      contentType = "html"
    //console.log(this.state.curBtnPath)
    const { is_target } = this.state
    const chreateChild = is_target !== 2 || (Array.isArray( this.state.curBtnPath ) && this.state.curBtnPath.length > 1 )
      ? 
      null
      :
      (
        <Popover
          isOpen={this.state.isCreateOpen}
          content={(
            <div className="square">
              <div>
                <small>
                  {__("Set child's title")}
                </small>
                <input
                  type="text"
                  value={this.state.childName}
                  onChange={(evt) => this.setState({ childName: evt.currentTarget.value })}
                  className="form-control mt-2 mb-4"
                  placeholder={__("Title")}
                />
                <ButtonGroup>
                  <Button intent={Intent.SUCCESS} onClick={this.onCreateChild}>
                    {__("Insert child")}
                  </Button>
                  <Button intent={Intent.DANGER} onClick={this.onCreateToggle}>
                    {__("Cancel")}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
        >
          <Button onClick={this.onCreateToggle} fill className="h-40px">
            {__("Create Child")}
          </Button>
        </Popover>
      )

    const prefixRoute = Array.isArray( this.state.curBtnPath ) 
            ?
            this.state.curBtnPath.join("/")
            :
            ""
    return typeof this.state.title != "undefined"
      ? (
        <div className="w-100"> 
          <FieldInput
            title={__("Icon")}
            type="icon"
            field="icon"
            editable
            value={this.state.icon}
            onChange={this.onIcon}
          />
          <FieldInput
            title={__("Title")}
            type="string"
            field="Title"
            editable
            value={this.state.title}
            onChange={this.onTitle}
          />
          <FieldInput
            title={__("Creation Type")}
            type="radio"
            field="createtype"
            editable
            value={is_target}
            values={[{ _id: 2, title: "create in palce" }, { _id: 1, title: "coping from exist" }]}
            _id="createtype"
            onChange={this.onCrT}
          />
          <div className={this.state.is_target == 2 ? "hidden" : " row"}>
            <div className="col-md-3  layout-label">
              {__("Copy from URL")}
            </div>
            <div className="col-md-9 layout-data">
              {this.getRouteSelect()}
            </div>
          </div>
          <FieldInput
            title={__("Description")}
            type="string"
            field="description"
            editable
            value={this.state.description}
            onChange={this.onDescr}
          />
          <div className={this.state.is_target == 1 ? "hidden" : ""}>
            <FieldInput
              title={__("Route")}
              type="route"
              field="URL"
              editable
              prefix={prefixRoute + "/"}
              value={this.state.route}
              onChange={this.onRoute}
            />
            <FieldInput
              title={__("Content type")}
              type="radio"
              field="contenttype"
              editable
              value={contentType}
              values={[
                { _id: "component", title: "page component" },
                { _id: "singled_data_type", title: "Single page of data type" },
                { _id: "feed_data_type", title: "Feed of data type" },
                { _id: "data_type", title: "page data_type" },
                { _id: "html_source", title: "page html_source" },
                { _id: "html", title: "page html" },
                { _id: "redirect", title: "Inner site URL" },
              ]}
              _id="contenttype"
              onChange={this.onCT}
            />
            
            <Collapse
              isOpen={contentType == "component"}
              className={contentType == "component" ? "collapse-selected " : ""}

            >
              <div className="row w-100" style={{  }}>
                <div className="col-md-3  layout-label">
                  {__("Choose Ready View")}
                </div>
                <div className="col-md-9 layout-data position-relative pr-5 flex-column ">
                  {this.getComponentSelect()}
                  <div className="my-3">
                    {this.getComponentParams()}
                  </div>
                </div>
              </div>
            </Collapse>
            <Collapse isOpen={contentType == "data_type"}>
              <div className="row w-100">
                <div className="col-md-3  layout-label">
                  {__("Choose Data Type")}
                </div>
                <div className="col-md-9 layout-data">
                  {this.getSchemaSelect()}
                </div>
              </div>
            </Collapse>
            <Collapse isOpen={contentType == "redirect"}> 
                <div className="row w-100">
                  <div className="col-md-3  layout-label">
                    {__("Inner site URL redirect")}
                  </div>
                  <div className="col-md-9 layout-data flex-column">
                    <input 
                      className="form-control input dark"
                      value={this.state.redirect || ""}
                      onChange={ evt => this.onRedirect(evt.currentTarget.value)}
                    />
                    <div className="scalar-commentary">
                      {__("Be sure to include a slash (/) at the beginning")}
                    </div>
                  </div>
                </div>
            </Collapse>
            <Collapse isOpen={contentType == "singled_data_type"}>
              <div className="card p-2">
                <div className="row w-100">
                  <div className="col-md-3  layout-label">
                    {__("Data Type")}
                  </div>
                  <div className="col-md-9 layout-data">
                    {this.getSchemaSingleDataTypeSelect()}
                  </div>
                </div>
                <div className="row w-100 px-3">
                  <div className="col-md-3  layout-label">
                    {__("Choose fields for show")}
                  </div>
                  <div className="col-md-9 layout-data">
                    {this.getExternalSettingsModifier()}
                  </div>
                </div>
                <div className="row w-100 px-3">
                  <div className="col-md-3  layout-label">
                    {__("Show associated Data Type feed")}
                  </div>
                  <div className="col-md-9 layout-data">
                    {this.getTagFeedModifier()}
                  </div>
                </div>
              </div>
            </Collapse>
            <Collapse isOpen={contentType == "feed_data_type"}>
              <div className="row w-100 py-3">
                <div className="col-md-3  layout-label">
                  {__("Data Type to feed")}
                </div>
                <div className="col-md-9 layout-data ">
                  {this.getSchemaFeedDataTypeSelect()}
                </div>
                <div className=" w-100 hidden ">
                  <FieldInput
                    title={__("Choose parent ID")}
                    commentary={__("Specify an ID for filtering (0 for root) or leave blank to load all items")}
                    type="string" 
                    editable
                    value={this.state.feed_data_type_parent_id}
                    onChange={this.onFeedDataTypeParentID}
                  />
                </div>
              </div>
              <div className=" w-100  pb-4 ">
                <FieldInput
                  title={__("Width of container")}
                  commentary={__("Width of feed's elements")}
                  type="radio" 
                  values ={[
                    {
                      _id :" small-container ",
                      title : "Very narrow "
                    },
                    {
                      _id :" short-container ",
                      title : "Narrow "
                    },
                    {
                      _id :" container ",
                      title : "Normal"
                    },
                    {
                      _id :" container-fluid ",
                      title : "Full filling"
                    }
                  ]}
                  editable
                  value={this.state.feed_containerClassName}
                  onChange={this.onFeedDataTypeContainerClassName}
                />
              </div>
            </Collapse>
            <Collapse isOpen={contentType == "html_source"}>
                <FieldInput
                  title={__("Insert URL")}
                  type="url"
                  field="html_source"
                  editable
                  value={this.state.html_source}
                  onChange={this.onHTML_source}
                />
            </Collapse>
            <Collapse isOpen={contentType == "html"}>
              <FieldInput
                title={__("Insert HTML")}
                type="text"
                field="html"
                editable
                value={this.state.html || ""}
                onChange={this.onHTML}
              />
            </Collapse>
            <FieldInput
              title={__("Hidden for unlogged")}
              type="radio"
              field="islogged"
              editable
              value={ this.state.islogged }
              values={[{ _id: 1, title: "Yes" }, { _id: 0, title: "No" }]}
              component="PlaceType"
              _id="islogged"
              onChange={this.onIsLogged}
            />
            <FieldInput
              title={__("Exlusive access by role")}
              type="checkbox"
              field="capability"
              editable
              value={this.state.capability || []}
              values={roles()}
              _id="contenttype"
              onChange={this.onRoles}
            />
            <FieldInput
              title={__("Is show left menu in Route")}
              type="radio"
              field="is_left"
              editable
              value={this.state.is_left}
              values={[{ _id: 1, title: "Yes" }, { _id: 0, title: "No" }]}
              component="PlaceType"
              _id="contenttype"
              onChange={this.onIsLeft}
            />

            <FieldInput
              title={__("Help source")}
              type="url"
              field="URL"
              editable
              prefix={ "" }
              value={this.state.help_url}
              onChange={this.onHelpURL}
            />
          </div>
          <div className="row w-100 mt-2">
            <div className="col-md-3  layout-label" />
            <div className="col-md-9 layout-data">
              <ButtonGroup fill>
                <Popover
                  isOpen={this.state.isRemoveOpen}
                  content={(
                    <div className="square">
                      <div>
                        <small>
                          {__("Are you shure?")}
                        </small>
                        <ButtonGroup>
                          <Button intent={Intent.SUCCESS} onClick={this.onRemove}>
                            {__("Remove")}
                          </Button>
                          <Button intent={Intent.DANGER} onClick={this.onRemoveToggle}>
                            {__("Cancel")}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  )}
                >
                  <Button intent={Intent.DANGER} onClick={this.onRemoveToggle} fill className="h-40px">
                    {__("Remove")}
                  </Button>
                </Popover>
                {chreateChild}
                <Button intent={Intent.NONE} onClick={this.onGoTo} className="h-40px">
                  {__("Go to")}
                </Button>

                {/*                 
                <Button
                  minimal
                  className='w-20px hint hint--top text-secondary'
                  data-hint={__('copy route')}
                  onClick={this.onClipboardCopy}
                >
                  <i className='fas fa-file-import' />
                </Button>
                <Button
                  minimal
                  className='w-20px hint hint--top text-secondary'
                  data-hint={__('paste route')}
                  onClick={this.onClipboardPaste}
                >
                  <i className='fas fa-file-export' />
                </Button> 
                */}

              </ButtonGroup>
            </div>
          </div>
        </div>
      )
      : 
      <div className="w-100 d-flex">
        <div className="alert alert-secondary flex-grow-100 m-0">
          {__("No elements exist")}
        </div>
        <div>
          <Button minimal intent={Intent.SUCCESS} onClick={this.onRemove} className="h-100 text-center">
            {__("Clear out")}
          </Button>
        </div>
      </div>
  }
  onClipboardCopy = () => {
    const copy = JSON.stringify(this.state)
    console.log(copy)
    console.log(this.state)
    $('body').append(
      `<div style='position:absolute; z-index:-100; width:100%; top:0; left:0;'><textarea style='width:100%;' id='SiteMapClipboard'>${copy}</textarea></div>`
    )
    const copyText = document.getElementById('SiteMapClipboard')
    copyText.select()
    copyText.setSelectionRange(0, 99999999999999999999)
    document.execCommand('copy')
    $('#SiteMapClipboard').remove()
    AppToaster.show({
      intent: Intent.SUCCESS,
      icon: 'tick',
      duration: 10000,
      message: __('Site map copy to clipbord'),
    })
  }
  onClipboardPaste = () => {
    navigator.clipboard.readText().then(clipText => {
      try { 
        const menuDatas = JSON.parse(clipText)
        this.setState( menuDatas)
      } catch (e) {
        AppToaster.show({
          intent: Intent.DANGER,
          icon: 'tick',
          duration: 10000,
          message: __('Error read clipboard data'),
        })
      }
    })
  }
  
  getDistinationRoute = () =>
  {
    if(this.state.target_id && this.state.target_id != "-1")
    {
      return this.state.target_id
    }
    if(this.state.route)
    {
      return this.state.route;
    }
    return "/"
  }
  getPreRoute = () =>
  {
    return Array.isArray( this.state.curBtnPath ) && this.state.curBtnPath.length > 0
      ?
      "/" + this.state.curBtnPath.join("/")
      :
      ""
  }
  getFullDistinationRoute = () =>
  {
    const prefixRoute = this.getPreRoute()
    return prefixRoute + "/" + this.getDistinationRoute()
  }

  onRemove = () => {
    this.props.deleteRoute(this.state.route, this.state.route)
    this.setState({ isRemoveOpen: false })
  }

  onRemoveToggle = () => {
    this.setState({ isRemoveOpen: !this.state.isRemoveOpen })
  }
  onGoTo = () => this.props.history.push( this.getFullDistinationRoute() )

  getRouteSelect = () =>
  {
    const target_id = typeof this.state.target_id !== "undefined" ? this.state.target_id : "-1" 
    return (
      <select 
        className="form-control" 
        value={target_id} 
        onChange={this.onSetFrom}
      >
        {
          concatRouting()
            .filter((e) => e.route || e.route == "")
            .map((e, i) => { 
              //const selected = e.route === target_id
              //console.log(typeof e.route, e.route, target_id)
              return (
                <option key={i} value={ e.route ? e.route : "" } >
                  {e.route}
                </option>
              )
            })
        }
      </select>
    )
  }

  onSetFrom = evt =>
  {

  }

  getComponentSelect() 
  {
    const { module_filter } = this.state
    const options = []
    for (const key in components) {
      //console.log(module_filter, key )
      const curMdDscr = mdDscr[key] ? mdDscr[key] : {}
      if (
        !curMdDscr.admin_hidden &&
        module_filter.filter(module_id => {
          //console.log(module_id, mdls[module_id].views)
          return Object.keys(mdls[module_id].views).filter(view => view === key).length > 0
        }).length > 0
      ) {
        const label = <>
          <div className="font-weight-bold">
            {__(curMdDscr.title)}
          </div>
          <div className="small">
            {__(curMdDscr.description)}
          </div>
        </>
        options.push({ value: key, label })
      }
    }
    const val = mdDscr[this.state.component]
      ?
      {
        value: this.state.component,
        label: <>
          <div className="font-weight-bold">
            {__(mdDscr[this.state.component].title)}
          </div>
          <div className="small">
            {__(mdDscr[this.state.component].description)}
          </div>
        </>
      }
      :
      null
    const customStyles = {
      option: base => ({
        ...base,
        minHeight: 45,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }),
      valueContainer: base => ({
        ...base,
        minHeight: 55,
        fontSize: "1.0rem",
        whiteSpace: "unset",
        backgroundColor: "#e6e6e6",
        border: "none",
        borderRadius: 0
      }),
      singleValue: base => ({
        ...base,
        whiteSpace: "unset"
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        backgroundColor: "#e6e6e6",
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "#444"
      }),
    }
    const switches = Object.keys(mdls).map((module, i) => {
      return <Switch
        key={i}
        label={__(mdls[module].title)}
        checked={module_filter.filter(e => e == module).length > 0}
        onChange={evt => this.onFilterModules(evt, module)}
      />
    })
    return <div className="d-flex w-100 h-100">
      <Suspense fallback={<Loading />}>
        <Select
          value={val}
          isMulti={false}
          isSearchable
          onChange={this.handleChange}
          options={options}
          placeholder={__("Select...")}
          className="basic-multi-select "
          classNamePrefix="select"
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#EEE',
              primary: 'black'
            }
          })}
        />
      </Suspense>
      <Popover
        className="h-100"
        position={Position.RIGHT}
        usePortal={true}
        content={
          <div className="p-4">
            <div className="title pb-2">
              {__("Filter by modules")}
            </div>
            {switches}
          </div>
        }
      >
        <Button
          minimal
          icon={<i className="fas fa-ellipsis-v" />}
          style={{ height: 55 }}
        />
      </Popover>


    </div>
  }
  getComponentParams = () =>
  {
    // console.log( mdDscr[ this.state.component ] )
    if( !mdDscr[ this.state.component ] || !Array.isArray(mdDscr[ this.state.component ].fields) ) return null
    const fld = mdDscr[ this.state.component ].fields.map((field, i) =>
      {
        //console.log( Object.entries(field)[0][1] )
        const fields = Object.entries(field)[0][1];
        const pname = Object.entries(field)[0][0]
        // console.log( this.state[pname] )
        let fieldData = ""
        switch( fields.type )
        {
          case "object":
            
            fieldData =  Object.keys( fields.fields ).map((field1, ii) =>
            {
              return <div key={ii} className="w-100">
                  <FieldInput
                    {...fields.fields[field1]}
                    title={__(fields.fields[field1].title)}
                    type={fields.fields[field1].type}
                    commentary={fields.fields[field1].commentary}
                    default={fields.fields[field1].default}
                    editable
                    value={this.state[pname] ? this.state[pname][field1]: null }
                    values={ fields.fields[field1].values }
                    onChange={value => this.onComponentParams(pname, field1, field, value)}
                  />
              </div>
            })
            break  
          default:
            fieldData = fields.type
        }
        return <div className="row" key={i}>
          <div className="col-12 title">
            { __(fields.title) }:
          </div>
          <div className="col-12">
            { fieldData }
          </div>
        </div>
      })
    return <div className="p-2">
      {fld}
    </div>
  }
  onComponentParams = (pname, field1, field, value) =>
  {
    let state = {...this.state};
    if(! state[pname])  state[pname] = {}
    state[pname][field1] = value
    //this.setState(state)
    this.onChangeField({ title: pname, field: state[pname] })
    console.log (pname, field1, field, value);
  }
  onFilterModules = (evt, module) => {
    let module_filter = [...this.state.module_filter]
    if (this.state.module_filter.filter(e => e === module).length > 0) {
      module_filter = this.state.module_filter.filter(e => e !== module)
    }
    else {
      module_filter.push(module)
    }
    // console.log(this.state.module_filter.filter(e => e === module))
    // console.log(module_filter)
    this.setState({ module_filter: module_filter })
  }
  handleChange = value => {
    //console.log(value.value);
    //this.setState({ component: value.value })
    this.onChangeField({ title: "component", field: value.value })
  }

  getSchemaSelect() {
    const rr = []
    const xx = { ...schema() }
    for (const key in xx) {
      rr.push({ ...xx[key], data_type: key })
    }
    return <>
      <select 
        className="form-control" 
        value={ typeof this.state.data_type === "undefined" ? -1 : this.state.data_type } 
        onChange={this.onSchema}
      >
        {
          rr.map((e, i) => { 
            return (
              <option key={i} value={e.data_type}  >
                {__(e.name)}
              </option>
            )
          })
        }
      </select>
    </>
  }
  onSchema = evt =>
  {
      const data_type = evt.currentTarget.value 
      this.onChangeField({ title: "data_type", field: data_type })
  }

  getSchemaSingleDataTypeSelect() { 
    return (
      <select className="form-control input dark" value={this.state.singled_data_type} onChange={this.onSingled}>
        <option value="-1">--</option>
        {
          Object.keys(schema()).map((view, i) => { 
            return (
              <option key={i} value={view} >
                {__( schema()[view].name)}
              </option>
            )
          })
        }
      </select>
    )
  }
  getExternalSettingsModifier = () =>
  {    
    if(!schema()[this.state.singled_data_type]) 
    {
      return <Callout className="p-3 my-3">
        {__("Select Data type")}
      </Callout>
    }
    const values = this.state.external_settings && 
      this.state.external_settings.show_fields && 
      Array.isArray(this.state.external_settings.show_fields)
      ?
      this.state.external_settings.show_fields
      :
      []
    const options = Object.keys( schema()[this.state.singled_data_type].apollo_fields )
      .filter(e => !schema()[this.state.singled_data_type].apollo_fields[e].hidden ||
        e === "parent")
        .map((e, i) =>
        {
          return <div key={i} className="py-1">
            <label className="_check_">
              <input 
                type="checkbox" 
                value={e}
                checked={ values.filter(val => val == e).length > 0 } 
                onChange={ evt => this.handleExternalSettings( evt, e ) }
              />
              <span>
                {schema()[this.state.singled_data_type].apollo_fields[e].title} 
              </span>
            </label>
          </div> 
      })
    return <div className="py-2">
      {options}
    </div> 
  }
  handleExternalSettings = (evt, e) =>
  {
    let show_fields = this.state.external_settings && Array.isArray(this.state.external_settings.show_fields)
      ?
      [...this.state.external_settings.show_fields]
      : 
      [ ]
    if(show_fields.filter(es => es === e).length === 0 )
    {
      show_fields.push(e)
    }
    else
    {
      show_fields = show_fields.filter(es => es !== e)
    }
    // this.setState({ show_fields })
    this.onChangeField({ 
      title: "external_settings", 
      field: { 
        ...this.state.external_settings,
        show_fields 
      }
    })
  }
  getTagFeedModifier = () =>
  { 
    if(!schema()[this.state.singled_data_type]) 
    {
      return <Callout className="p-3 my-3">
        {__("Select Data type")}
      </Callout>
    }
    const values = this.state.external_settings && 
      this.state.external_settings.feeds && 
      Array.isArray(this.state.external_settings.feeds)
      ?
      this.state.external_settings.feeds
      :
      []
    const components = [ ]
    let k = 0;
    Object.keys(schema()).forEach((component ) =>
    {
      return Object.keys(schema()[component].apollo_fields)
      .filter(e => e !== "parent")
      .filter(e => {
       
        const foo = schema()[component].apollo_fields[e].component === this.state.singled_data_type
        if(foo)
        {
          components.push({
            component,
            field : e,
            name: [schema()[
              component].apollo_fields[e].title, 
              __(schema()[component].plural ? schema()[component].plural : schema()[component].name) 
            ],
            filter_type: schema()[component].apollo_fields[e].filter_type,
            title : <span>
              <b>{ __(schema()[component].apollo_fields[e].title)}</b> 
              {__(" in ")}  
              <b>{__(schema()[component].plural ? schema()[component].plural : schema()[component].name) }</b>
            </span>
          })
        }
        return foo
      }).length > 0
    })
    const options = components.map((c, i) => {        
        return <div key={i} className="py-1">
            <label className="_check_">
              <input 
                type="checkbox" 
                data_type={ c.component }
                data_field={ c.field }
                checked={ values.filter(v => v.component === c.component && v.field === c.field ).length > 0 } 
                onChange={ evt => this.handleTagFeed( 
                  evt, 
                  {
                    component:c.component, 
                    field: c.field, 
                    filter_type: c.filter_type, 
                    name: c.name
                  } ) }
              />
              <span>
                { c.title } 
              </span>
            </label>
          </div> 
      })


    return <div className="py-2">
      {options}
    </div> 
  }
  handleTagFeed = (evt, component ) =>
  {
    console.log( evt, component )
    let feeds = this.state.external_settings && Array.isArray(this.state.external_settings.feeds)
      ?
      [...this.state.external_settings.feeds]
      : 
      [ ]
    if(feeds.filter( es => es.component === component.component && es.field === component.field).length == 0 )
    {
      feeds.push(component)
    }
    else
    {
      feeds = feeds.filter( es => es.component !== component.component && es.field !== component.field )
    }
    // this.setState({ feeds })
    this.onChangeField({ 
      title: "external_settings", 
      field: { 
        ...this.state.external_settings,
        feeds 
      }
    })

  }


  getSchemaFeedDataTypeSelect() { 
    return (
      <select className="form-control input dark" value={this.state.feed_data_type} onChange={this.onFeedDataType}>
        <option value="-1">--</option>
        {
          Object.keys(schema()).map((view, i) => { 
            return (
              <option key={i} value={view} >
                {__( schema()[view].name)}
              </option>
            )
          })
        }
      </select>
    )
  }
  onFeedDataType = evt =>
  {
    const feed_data_type = evt.currentTarget.value;
    this.onChangeField({ title: "feed_data_type", field: feed_data_type }) 
  }
  onFeedDataTypeParentID = data =>
  {
    this.onChangeField({ title: "feed_data_type_parent_id", field: data }) 
  }
  onFeedDataTypeContainerClassName = data =>
  {
    this.onChangeField({ title: "feed_containerClassName", field: data }) 
  }
  
  onSingled = evt =>
  {
    const singled_data_type = evt.currentTarget.value
    //this.setState({singled_data_type})
    this.onChangeField({ title: "singled_data_type", field: singled_data_type }) 
  }

  onChangeField = (data) => {
    //this.props.onChangeField(data, this.state.route)
    console.log(data)
    this.props.onChangeField(data, this.getDistinationRoute())
  }

  onIcon = (evt) => this.onChangeField({ title: "icon", field: evt })

  onTitle = (evt) => this.onChangeField({ title: "title", field: evt })

  onRoute = (evt) => this.onChangeField({ title: "route", field: evt }) 

  onDescr = (evt) => this.onChangeField({ title: "description", field: evt }) 

  onHTML = (evt) => this.onChangeField({ title: "html", field: evt })

  onHTML_source = (evt) => this.onChangeField({ title: "html_source", field: evt })
  
  onRedirect = (evt) => this.onChangeField({ title: "redirect", field: evt })

  onIsLogged = ( val ) => {
    console.log( val )
    this.onChangeField({ title: "islogged", field: parseInt(val) })
  }
  

  onIsLeft = (evt) => this.onChangeField({ title: "is_left", field: parseInt(evt) })

  onCrT = (evt) => this.onChangeField({ title: "is_target", field: evt })

  onHelpURL = (evt) => this.onChangeField({ title: "help_url", field: evt })

  onRoles = (evt) => this.onChangeField({ title: "capability", field: evt })

  onCT = (evt) => {
    //console.log(evt);
    this.props.setContentType(evt, this.state.route)
  };

  onCreateToggle = () => {
    this.setState({ isCreateOpen: !this.state.isCreateOpen })
  }

  onCreateChild = () => {
    if (!this.state.childName) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: "tick",
        message: "Insert not empty title",
      })
      return
    }
    if(this.props.onAddChild)
    {
      this.props.onAddChild(this.state.childName, this.state.route)
    }
    this.setState({
      isCreateOpen: false,
      childName: "",
    })
  }
}
export default  compose(
	withRouter
)(MenuElementForm)
