import React from "react"
import { withApollo } from "react-apollo" 
import { compose } from "recompose"
import BasicState from "react-pe-basic-view" 
import Folder from "./editorCabinet/Folder"
import { AppToaster, LayoutIcon } from "react-pe-useful/dist"
import { initArea, __ } from "react-pe-utilities/dist"  
import { Button, Intent } from "@blueprintjs/core"
import Layouts, { getAllRoutes, help_url, updateLayouts } from "react-pe-layouts"
import Folders from "./editorCabinet/Folders"
import CurrentFolderMenu from "./editorCabinet/CurrentFolderMenu"
import gql from "graphql-tag"
import { Route, Switch, withRouter } from "react-router"


class EditorCabinet extends BasicState {
	basic_state_data() {  
		return { 
            isLeftClosed: window.innerWidth < 760,
            folders: Layouts().folders.folders,
            max_id: Layouts().folders.max_id,
            routing: Layouts().routing,
            currentFolderId: -1
        }
	} 
    changeFolders = folders =>
    {
        console.log( folders ) 
        this.setState({ folders: folders.folders, max_id: folders.max_id })        
        updateLayouts("folders", folders)
        this.onSaveFolders()
    }
    onSelect = folderId =>
    {
        let folders = [ ...this.state.folders ].map(folder => {
            return folder.id === folderId
                ? 
                {...folder, isSelect: true}
                :
                {...folder, isSelect: false}
        })
        //console.log("this.state.folders", this.state.folders)
        // console.log("folders", folders)
        this.setState({
            currentFolderId: folderId, 
            folders
        })
        this.props.history.push( "/" + this.props.route + "/folder/" + folderId )
        
    }
    onHide = folderId =>
    {
        let folders = [ ...this.state.folders ].map(folder => {
            return folder.id === folderId
                ? 
                { ...folder, is_hidden: !folder.is_hidden }
                :
                { ...folder }
        })
        this.setState({ folders })
        this.onSaveFolders()
    }
    onDelete = folders =>
    {
        this.setState({ folders })
        updateLayouts("folders", {...Layouts().folders, folders })
        this.onSaveFolders()
    }
    onAddRoute = data =>
    {
        let {routing} = this.state
        routing.extended_routes.push(data)
        this.setState({ routing, isUpdated: true })
        this.onSaveMenus()
    }
    onRemoveRoute = data =>
    { 
        let {routing} = this.state
        //console.log( this.state.routing )
        Object.keys(routing).forEach(menu => {
            routing[menu].forEach((route, i) => {
                const route1 = this.searchRoute( route, data, this.updateRoute, { changeData:data } ) 
                if(!route1)  return
                routing[menu].splice(i, 1)
            })
        })
        //console.log( routing )
        this.setState({ routing, isUpdated: true })
        this.onSaveMenus()
    }
    onChangeRoute = ( data, routeJson ) =>
    {
        console.log( data, routeJson )
        let {routing} = this.state
        //console.log( this.state.routing )
        Object.keys(routing).forEach(menu => {
            routing[menu].forEach((route, i) => {
                let routeData = {}
                try
                {
                    routeData = JSON.parse(routeJson)
                }
                catch(error)
                {
                    routeData = {}
                    console.error(`Error parse route data: ${routeJson}`)
                }
                const route1 = this.searchRoute( route, routeData, this.updateRoute, { changeData:data } ) 
                if(!route1)  return
                if(menu === route1.menu)
                    routing[menu].splice(i, 1, route1)
                else
                {
                    routing[menu].splice(i, 1)
                    if(Array.isArray(routing[route1.menu]))
                    {
                        routing[route1.menu].push(route1)
                    }
                }
                //console.log(route1)
                routing = { ...routing, menu: routing[menu] }
                //console.log( { ...routing, menu: routing[menu] } )
            })
        })
        console.log( routing )
        this.setState({ routing, isUpdated: true })
        this.onSaveMenus()
    }

    // @route - ссылка на данные искомого роута
    // @args - аргументы, переданные поисковым методом при нахождении искомого роута
    updateRoute = ( route, args ) =>
    {
        console.log(route, args.changeData)
        return {...route, ...args.changeData}
    }
    moveToFolder = ( routeData, folderId ) => 
    {
        let {routing} = this.state
        //console.log( this.state.routing )
        Object.keys(routing).forEach(menu => {
            routing[menu].forEach((route, i) => {
                const route1 = this.searchRoute( route, routeData, this.chengeFolder, { folderId } )
                if(!route1)  return
                routing[menu].splice(i, 1, route1)
                //console.log(route1)
                routing = { ...routing, menu: routing[menu] }
                //console.log( { ...routing, menu: routing[menu] } )
            })
        })
        this.setState({ routing, isUpdated: true })
        this.onSaveMenus()
    }
    chengeFolder( route, args )
    {
        return { ...route, folder: args.folderId }
    }
    searchRoute = ( parent, routeData, func, args ) => 
    {
        if(
                parent.route === routeData.route 
            &&  parent.title === routeData.title
            &&  parent.module === routeData.module
            &&  parent.icon === routeData.icon
            &&  parent.component === routeData.component
            &&  parent.data_type === routeData.data_type
            &&  JSON.stringify(parent.extend_params) === JSON.stringify(routeData.extend_params)         
            &&  parent.target_id === routeData.target_id         
            &&  parent.is_left === routeData.is_left         
        )
        {
            parent = func(parent, args)
            //console.log( parent )
            return parent
        }
        if(Array.isArray(parent.children))
        {
            parent.children.forEach( child => this.searchRoute( child, routeData, func, args ) )
        }
    }

    render()
    {
        //console.log( this.props )
        const leftClass = this.state.isLeftClosed
            ? "tutor-left-aside-2 menu-aside closed"
            : "tutor-left-aside-2 menu-aside"
        const mainClass = this.state.isLeftClosed
            ? "tutor-main-2 pr-0 opened"
            : "tutor-main-2 pr-0"
        const help = this.props.help_url
			?
			<span>
				<Button 
					icon="help" 
					minimal 
					onClick={ () => window.open(help_url() + this.props.help_url, '_blank') } 
				/>
			</span> 
			:
			null

        return <div className="layout-state p-0">
            <div className="tutor-row menu-row">
                <div className={leftClass}>
                    <div className="layout-state-head menu-header-22">
                        <LayoutIcon
                            isSVG
                            src={this.state.route.icon}
                            className="layout-state-logo "
                        />
                        <div className="layout-state-title">
                            { help }
                            <span dangerouslySetInnerHTML={{ __html: __(this.state.route.title) }} /> 
                        </div>
                    </div>
                    <div className="small mx-3 mb-3 " dangerouslySetInnerHTML={{ __html: this.props.description }} />
                    <Folders 
                        curRoute={ this.props.route }
                        folders={ this.state.folders } 
                        max_id={ this.state.max_id } 
                        level={0} 
                        changeFolders={this.changeFolders} 
                        onSelect={this.onSelect}
                        onHide={this.onHide}
                        onDelete={this.onDelete} 
                    />
                    {
                        initArea("admin-menu-left-aside",
                            { ...this.props })
                    }
                </div>
                <div className={mainClass}>
                    <div
                        className="clapan-left"
                        onClick={() => this.setState({ isLeftClosed: !this.state.isLeftClosed })}
                    >
                        <div className={`fas fa-caret-${!this.state.isLeftClosed ? "left" : "right"}`} />
                    </div>
                    <div className="menu-header-22 flex-centered">
                        <CurrentFolderMenu
                            currentFolderId={ this.state.currentFolderId } 
                            onSelect={ this.onSelect }
                            onAddRoute={ this.onAddRoute }
                        />
                    </div>
                    <div className=" p-4 ">
                        <Switch>
                            <Route
                                path={ "/" + this.props.route + "" }
                                exact
                                component={routeData => (
                                    <> 
                                        <Folder
                                            moveToFolder={this.moveToFolder}
                                            onChangeRoute={this.onChangeRoute}
                                            onRemoveRoute={this.onRemoveRoute}
                                        />    
                                    </>
                                    
                                )}
                            />  
                            <Route
                                path={ "/" + this.props.route + "/folder/:folderId" }
                                exact={false}
                                strict
                                component={routeData => (
                                    <> 
                                        <Folder 
                                            // currentFolderId={ routeData.match.params.folderId} 
                                            folder={ 
                                                this.state.folders
                                                    .filter(folder => { 
                                                        return routeData.match.params.folderId == folder.id
                                                    })[0] 
                                            }
                                            // folders={ this.state.folders }
                                            moveToFolder={this.moveToFolder}
                                            onChangeRoute={this.onChangeRoute}
                                            onRemoveRoute={this.onRemoveRoute}
                                        />    
                                    </>
                                    
                                )}
                            />                                    
                        </Switch>
                        
                       

                    </div>
                </div>
                <div className="tutor-right-aside-2">
                    {
                        initArea(
                            "admin-menu-right-aside",
                            { ...this.props },
                        )
                    }
                </div>
            </div>
        </div>
    }

    onSaveMenus = () => {
        const changeMenu = gql`
          mutation changeMenu($input: MenuInput) {
            changeMenu(input: $input)
          }
        `
        const json = { json: JSON.stringify(this.state.routing) }
        console.log(json)
        this.props.client.mutate({
          mutation: changeMenu,
          variables: { input: json },
          update: (store, { data: { changeMenu } }) => {
            this.setState({
              isUpdated: false,
            })
            AppToaster.show({
              intent: Intent.SUCCESS,
              icon: 'tick',
              message: 'Success saved menus',
            })
          },
        })
        // console.log(JSON.stringify(this.state.menuDatas))
      }
      onSaveFolders = () =>
      {
        setTimeout(() => {
            const changeRouteFolder = gql`
                mutation changeRouteFolder($input: RouteFolderInput) {
                    changeRouteFolder(input: $input)
                }
        `
            console.log(Layouts().folders, this.state.max_id)
            const json = { json: JSON.stringify({ ...Layouts().folders, max_id: this.state.max_id, folders: this.state.folders }) }
            console.log(json)
            this.props.client.mutate({
                mutation: changeRouteFolder,
                variables: { input: json },
                update: (store, { data: { changeRouteFolder } }) => {
                this.setState({
                    isUpdated: false,
                })
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: 'tick',
                    message: 'Success saved folders',
                })
                },
            }) 
        }, 300)
      }
}

export default  compose(
	withApollo, 
    withRouter
)(EditorCabinet)

export const getEditoCabinetRoute = () =>
{
    const routes = getAllRoutes().filter( route => route.component === "EditorCabinet" )
    return routes[0]

} 