import React from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import { compose } from "recompose"
import { withApollo, Mutation } from "react-apollo"
import gql from "graphql-tag"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities"

class VerifyUserState extends BasicState {
  myState = () => {
    const mutation = gql`
			mutation verifyUser($id: String $code: String) {
				verifyUser(id:$id, code:$code)
			}`
    console.log(this.props.match.params)
    return (
      <Mutation mutation={mutation}>
        {(verifyUser) => {
          if (!this.state.is_requested) {
            verifyUser({
              variables:
              {
                id: this.props.match.params.id,
                code: this.props.match.params.code,
              },
              update: (store, { data }) => {
                if (data.verifyUser) {
                  console.log(data)
                  const state = { is_verified: true }
                  this.setState(state)
                } else {

                }
                this.setState({ is_requested: true })
              },
            })
          }
          return this.state.is_verified ? this.success() : this.notsuccess()
        }}
      </Mutation>
    )
  }

  getRoute = () => "verify"

  success() {
    return (
      <div className="row text-center">
        <div className="col-12 my-4" />
        <div className="col-12 lead">
          {__("Адрес электронной почты успешно подтвержден. Пожалуйста, войдите под своим email и паролем.")}
          { }
        </div>
        <div className="col-12 my-4">
          <Link
            className="btn btn-danger btn-sm"
            to="/"
          >
            {__("Return to main page")}
          </Link>
        </div>
      </div>
    )
  }

  notsuccess() {
    return (
      <div className="row text-center">
        <div className="col-12 my-4" />
        <div className="col-12 lead">
          {__("Адрес электронной почты не подтвержден. Проверьте ссылку из письма.")}
          { }
        </div>
        <div className="col-12 my-4">
          <Link
            className="btn btn-danger btn-sm"
            to="/"
          >
            {__("Return to main page")}
          </Link>
        </div>
      </div>
    )
  }
}

export default compose(
  withApollo,
  withRouter,
)(VerifyUserState)
