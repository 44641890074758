import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import { Popover2 } from '@blueprintjs/popover2'

export const types = () =>
{
    return [
        {
            id: "url",
            title:"Link"
        },
        {
            id: "media",
            title:"Image"
        },
        {
            id: "youtube",
            title:"Youtube video"
        },
        // {
        //     id: "vk-video",
        //     title:"VK video"
        // },
        // {
        //     id: "upload",
        //     title:"Upload"
        // },
        // {
        //     id: "geo",
        //     title:"Geo"
        // },
        // {
        //     id: "email",
        //     title:"Email"
        // },
        // {
        //     id: "phone",
        //     title:"phone"
        // },
        {
            id: "string",
            title:"String"
        },
        // {
        //     id: "number",
        //     title:"Number"
        // }
    ]
}
const MailstoneAttachForm = props => {
    const [ current, setCurrent ] = useState( types()[ 0 ] )
    const onInsertNew = () =>
    { 
        props.onInsertNew( current.id )
    } 
    return (
        <div className='d-flex justify-content-center milestone-attach-cont'>
            <ButtonGroup minimal>
                <Button className=' opacity_5' onClick={onInsertNew}>
                    { __( "Insert new include by" ) } {__(current.title)}
                </Button>
                <Popover2 
                    content={
                        <div className="square3 bg-light overflow-y-auto-thin justify-content-start p-0">
                            <div className=' p-0'>
                            {
                                types().map(type => {
                                    return <Button 
                                        fill 
                                        minimal 
                                        onClick={ ( ) => setCurrent( type ) }
                                        key={type.id}
                                    >
                                        {__(type.title)}
                                    </Button>
                                })
                            }
                            </div>
                        </div>
                    }
                >
                    <Button className="pl-4 milestone-attach-change-btn">
                        {__("Change type")}
                    </Button>
                </Popover2>
            </ButtonGroup>
        </div>
    )
}

MailstoneAttachForm.propTypes = {}

export default MailstoneAttachForm