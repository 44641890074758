const ScreenSizeList = () => {
  return [
    {
      id: "lxl",
      title: "Full",
      icon: "fas fa-tv",
      size: 1400
    },
    {
      id: "xl",
      title: "Large",
      icon: "fas  fa-desktop",
      size: 1200
    },
    {
      id: "lg",
      title: "Tablet large",
      icon: "fas fa-tablet-alt fa-rotate-90",
      size: 960
    },
    {
      id: "md",
      title: "Tablet",
      icon: "fas fa-tablet-alt",
      size: 720
    },
    {
      id: "sm",
      title: "Mobile large",
      icon: "fas fa-mobile-alt fa-rotate-90",
      size: 576
    },
    {
      id: "mc",
      title: "Mobile",
      icon: "fas fa-mobile-alt",
      size: 480
    },
  ]
}
export const ScreenSizeObject = () =>
{
  let obj = {}
  ScreenSizeList().forEach(sl => {
    obj[sl.id] = sl
  })
  return obj
}
export const ScreenSizeShortObject = () =>
{
  let obj = {}
  ScreenSizeList().forEach(sl => {
    obj[sl.id] = sl.size - 2
  })
  return obj
}
export const getScreenSize = (dwidth) => 
{
  let p = "mc"
  if (dwidth >= 1400) 
  {
    p = "lxl"
  } 
  else if (dwidth >= 1200) 
  {
    p = "xl"
  } 
  else if (dwidth >= 960) 
  {
    p = "lg"
  } 
  else if (dwidth >= 720) 
  {
    p = "md"
  }
  else if (dwidth >= 576) 
  {
    p = "sm"
  }
  return p
}
export const getScreenSizeWidth = ( screenSizeId ) =>
{
  const screenSize = ScreenSizeList().filter(s => s.id == screenSizeId )[0]
  if(!screenSize) return document.body.clientWidth
  return screenSize.size
}
export default ScreenSizeList