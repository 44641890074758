import React, {Component} from "react"
import { withApollo } from "react-apollo"
import { compose } from "recompose"
import gql from "graphql-tag"
import { Intent, Button,  } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import { AppToaster } from 'react-pe-useful'
import { withRouter } from "react-router"  
  import {Loading} from 'react-pe-useful'
  import FieldInput from "react-pe-scalars"

class SpecialTab extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            loading: true,
        }
    }
    componentDidMount()
    {
        this.onRefresh()
    }

    onRefresh = () => {
		const query = gql`
            query getSpecial 
            {
                getSpecial 
                {
                    is_load_menus
                    is_load_settings
                    is_load_data_type
                }
            }
        `
		this.setState(
		  { loading: true },
		  () => this.props.client.query({ query })
                .then((result) => {
                    console.log(result.data.getSpecial )
                    try
                    {
                        let data = result.data.getSpecial 
                        data = typeof data === "object" ? data : {}
                        console.log( {...this.state.data, ...data} )
                        this.setState({
                            data : {...this.state.data, ...data},
                            loading: false,
                        })
                    }
                    catch(e)
                    {
                        this.setState({
                            data: {...this.state.data},
                            loading: false,
                        })  
                    }
                    
                }),
		)
	}
    onSave = (m_change, state, id) => {
		const mutation = gql`
            mutation changeSpecial($input : SpecialInput){
                changeSpecial(input: $input)
            }
        `
        const data = {
            is_load_menus : this.state.data.is_load_menus,
            is_load_settings : this.state.data.is_load_settings,
            is_load_data_type : this.state.data.is_load_data_type,
        }
        this.props.client.mutate({
			mutation: mutation,
			variables: { input: data },
			update: (store, { data: { changeSpecial } }) => {
				this.setState({ 
					isUpdated: false
				})
				AppToaster.show({
					intent: Intent.SUCCESS,
					icon: "tick",
					message: "Success saved Special options",
				})
			},
		});
	}
    onChangeData = (field, value) =>
    { 
        //console.log( value )
        let data = {}
        value.map(e => data[e] = true )
        //data[ value ] = !data[ value ]
        //console.log(data )
        this.setState({
            isUpdated:true,
            data
        }) 
    }
    render()
    {  
		const { loading, data } = this.state      
		if ( loading) {
            return <Loading /> 
          }
          //console.log( data, Object.keys(data) )
          return <div className="w-100"> 
            <FieldInput
                type="checkbox"
                title={__("is update data from server?")}
                value={ Object.keys(data).filter( e => data[e] === true ) } 
                values= {[
                    {_id:'is_load_data_type', title: __("Data type map") },
                    {_id:'is_load_menus',  title: __("Site Map, Views, Menus") },
                    {_id:'is_load_settings', title: __("Settings") },
                ]}
                onChange={ val => {
                    //console.log( val)
                    this.onChangeData("is_load_data_type", val) 
                }}
            />    
            <div className="row">
                <div className="col-md-9 offset-md-3">
                    <Button
                        onClick={this.onSave}
                    >
                        {__("Update")}
                    </Button>
                </div>
            </div>  
          </div>
    }
}
export default  compose(
	withRouter,
  	withApollo,
)(SpecialTab) 